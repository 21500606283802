<script>
import '../../style/widgets/foodicons.css'

import qs from 'qs'
import FastDom from 'fastdom'
import Moment from 'moment-timezone'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  mapGetters
} from 'vuex'

import TitanMenu from 'services/titan-menu.js'
import Utils from 'services/utils.js'
import OfflineCaches from 'services/offline-caches'
import Log from 'services/log.js'

import MenuItem from './titanmenu/Item.vue'
import Spinner from 'components/common/Spinner.vue'

const config = {
  // In vmin
  BASE_FONTSIZE: 2.5,

  // In seconds. Paginate to next menu if there's more than one valid menu in request
  PAGINATE_INTERVAL: 20,

  // In seconds. Display error message when requesting timeout
  TIMEOUT: 30,

  // In minutes. Interval to get fresh data from API
  REFRESH_INTERVAL: 5
}

export default {
  name: 'TitanMenuApp',

  components: {
    MenuItem,
    Spinner
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    showTextShadow: {
      type: Boolean,
      default: false
    },
    whiteText: {
      type: Boolean,
      default: false
    },
    fontClass: {
      type: String,
      default: ''
    },
    fontScale: {
      type: Number,
      default: 1.0
    }
  },

  data () {
    return {
      menus: [],
      validMenus: [],
      menuURL: undefined,

      // Actual width/height
      itemSize: {},

      apiKey: '',
      building: '',
      plan: '',
      columns: 3,
      nutritionalInfo: ['Calories', 'Total Fat', 'Sodium'],
      interval: +config.PAGINATE_INTERVAL,
      customMenu: '',
      showMenuName: true,
      showBuildingName: true,
      showAllergies: true,
      showTomorrow: false,
      showDate: false,
      textColor: '',

      currentIndex: -1,
      showPrime: false,
      primeMenu: undefined,
      baseMenu: undefined,

      loading: true,
      hasError: false,
      errorMessage: '',

      debounceTimer: undefined,
      timeoutTimer: undefined,
      updateTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'contentForURL'
    ]),

    baseFontSize () {
      const fontSize = Utils.baseFontSize(this.itemSize)
      if (fontSize) {
        return fontSize * (config.BASE_FONTSIZE || 1)
      }
    },

    fontSizeStyle () {
      if (!this.baseFontSize) { return }
      return {
        fontSize: `${this.baseFontSize}px`
      }
    },

    scaledFontSizeStyle () {
      if (!this.baseFontSize) { return }
      return {
        fontSize: `${this.fontScale || 1.0}em`
      }
    },

    showMessage () {
      return this.isEmpty || this.hasError
    },

    isEmpty () {
      return Boolean(!this.validMenus || !this.validMenus.length)
    },

    localKey () {
      let keyParts = ''
      if ((!this.building || !this.building.length || this.building === 'all') && (!this.plan || !this.plan.length || this.plan === 'all')) {
        const apiKey = this.apiKey || ''
        if (apiKey.length) {
          keyParts = `${apiKey.substr(0, 5)}-${apiKey.substr(-5)}`
        }
        return `titanmenu_all_all_${keyParts}`
      }
      return `titanmenu_${this.building || 'all'}_${this.plan || 'all'}`
    }
  },

  watch: {
    menuURL (newValue) {
      // Force show fresh data when menu URL changed
      this.currentIndex = -1

      if (newValue && newValue.length) {
        this.menus = this.contentForURL(newValue) || []
      } else {
        this.menus = []
      }
    },

    menus: {
      deep: true,
      handler (newVal, oldVal) {
        if (!newVal.length && !oldVal.length) { return }
        this.filterValidMenus()
      }
    },

    item: {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.timeoutTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.debounceCheckSize()
    this.render()

    clearInterval(this.updateTimer)
    this.updateTimer = setInterval(() => {
      this.getMenuData()
    }, config.REFRESH_INTERVAL * 60000)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearInterval(this.updateTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.timeoutTimer)
  },

  methods: {
    render () {
      if (!this.item.url && !this.item.location) { return }

      const params = qs.parse(decodeURIComponent(this.item.url || this.item.location))
      if (!params) { return }

      this.apiKey = (params.apiKey || '').replace('Basic ', '').trim()
      this.building = params.building || ''
      this.plan = params.plan || ''
      this.textColor = params.textColor || ''

      this.interval = +(params.interval || config.PAGINATE_INTERVAL)

      // [DEV-852] allowed value switched to [1,2,3] (from [1,3,5])
      this.columns = Math.min(3, +(params.columns || 3))

      this.nutritionalInfo = params.nutritionalInfo || []

      this.customMenu = params.customMenu || ''
      this.showMenuName = !(params.showMenuName === false || params.showMenuName === 'false')
      this.showBuildingName = !(params.showBuildingName === false || params.showBuildingName === 'false')
      this.showAllergies = !(params.showAllergies === false || params.showAllergies === 'false')
      this.showTomorrow = (params.showTomorrow === true || params.showTomorrow === 'true')
      this.showDate = (params.showDate === true || params.showDate === 'true')

      this.$nextTick(() => {
        this.initGlobalData()
        this.getMenuData()
      })
    },

    // NOTE: use "method" instead of "computed" to make sure the date is refreshed on every call
    menuDate () {
      const now = Moment()
      if (this.showTomorrow) {
        const weekdayToday = now.weekday()
        const nextDay = now.clone()

        // Today is Friday
        if (weekdayToday === 5) {
          nextDay.add(3, 'days')
        // Today is Saturday
        } else if (weekdayToday === 6) {
          nextDay.add(2, 'days')
        } else {
          nextDay.add(1, 'days')
        }
        return nextDay.format('M/D/YYYY')
      }
      return now.format('M/D/YYYY')
    },

    // Get All Buildings / Menus name
    initGlobalData () {
      if (!this.apiKey) { return }
      TitanMenu.getAllBuildings(this.apiKey).catch(err => {
        this.showCachedData(err)
      })
      TitanMenu.getAllMenus(this.apiKey).catch(err => {
        this.showCachedData(err)
      })
    },

    getMenuData () {
      if (!this.apiKey) { return }

      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        this.showCachedData()
      }, config.TIMEOUT * 1000)

      TitanMenu.getMenu(this.apiKey, this.building, this.plan, this.menuDate())
        .then(() => {
          // NOTE: Not using computed value for `menuURL` here
          // To prevent displaying stale data before fresh data comes
          this.menuURL = TitanMenu.genMenuUrl(this.building, this.plan, this.menuDate())
        })
        .catch(err => {
          this.showCachedData(err)
        })
    },

    filterValidMenus () {
      const menus = JSON.parse(JSON.stringify(this.menus || []))
      if (!menus || !Array.isArray(menus) || !menus.length) {
        this.validMenus = []
        this.loaded()
        return
      }
      const list = []
      menus.forEach(menu => {
        if (menu && menu.Recipes && menu.Recipes.length) {
          list.push(menu)
        }
      })
      this.validMenus = list

      clearTimeout(this.timeoutTimer)

      if (!list || !list.length) {
        this.hasError = false
        this.loaded()
        this.errorMessage = ''
        return
      }

      this.hasError = false
      this.loaded()
      this.errorMessage = ''
      OfflineCaches.set(this.localKey, list)

      if (this.currentIndex < 0) {
        this.showNext(true)
      }
    },

    showNext (init) {
      if (!this.validMenus || !this.validMenus.length) { return }
      if (this.validMenus.length === 1 && !init) { return }
      const nextIndex = (this.currentIndex + 1) % this.validMenus.length
      const nextMenu = JSON.parse(JSON.stringify(this.validMenus[nextIndex]))
      if (this.showPrime) {
        this.baseMenu = nextMenu
        this.showPrime = false
      } else {
        this.primeMenu = nextMenu
        this.showPrime = true
      }
      this.currentIndex = nextIndex
    },

    async showCachedData (err) {
      const errorMessage = (err && (err.message || err.toString())) || 'Connection Timeout'

      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)

        if (staleData && staleData.length) {
          this.validMenus = staleData
          this.loaded()
          this.hasError = false
          this.errorMessage = ''
          if (this.currentIndex < 0) {
            this.showNext(true)
          }
          Log.error('app', `TitanK12 Error: ${errorMessage}`, 'ERR_TITANK12STALE', null, true)
          Log.debug('app', 'Using stale TitanK12 menu data', 'DBG_TITANK12', null, true)
          return
        }
      }

      clearTimeout(this.timeoutTimer)

      this.errorMessage = errorMessage
      this.hasError = true
      this.loaded()
      Log.error('app', `TitanK12 Error: ${errorMessage}`, 'ERR_TITANK12', null, true)
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      const container = this.$el
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        const metrics = container.getBoundingClientRect()

        if (!metrics.width || !metrics.height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: metrics.width,
          h: metrics.height
        }
        FastDom.clear(measure)
      })
    },

    showNextMenu () {
      this.showNext()
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.titan-menu-app(:style="fontSizeStyle", :class="{'dark-text': !whiteText, 'show-text-shadow': showTextShadow}")
  .resize-sensor(ref="sensor")

  .loading-mask(v-if="loading")
    spinner(size="5em")

  template(v-if="!loading")

    template(v-if="!showMessage")
      transition(name="fade" mode="out-in" appear)
        menu-item(v-if="showPrime"
                  :style="scaledFontSizeStyle"
                  :menu="primeMenu"
                  :building-id="building"
                  :menu-id="plan"
                  :columns="columns"
                  :interval="interval"
                  :nutritional-info="nutritionalInfo"
                  :custom-menu="customMenu"
                  :show-menu-name="showMenuName"
                  :show-building-name="showBuildingName"
                  :show-allergies="showAllergies"
                  :show-date="showDate"
                  :dark-text="!whiteText"
                  :text-color="textColor"
                  :font-class="fontClass"
                  @show-next="showNextMenu")
      transition(name="fade" mode="out-in" appear)
        menu-item(v-if="!showPrime"
                  :style="scaledFontSizeStyle"
                  :menu="baseMenu"
                  :building-id="building"
                  :menu-id="plan"
                  :columns="columns"
                  :interval="interval"
                  :nutritional-info="nutritionalInfo"
                  :custom-menu="customMenu"
                  :show-menu-name="showMenuName"
                  :show-building-name="showBuildingName"
                  :show-allergies="showAllergies"
                  :show-date="showDate"
                  :dark-text="!whiteText"
                  :text-color="textColor"
                  :font-class="fontClass"
                  @show-next="showNextMenu")

    .messages(v-if="showMessage")
      template(v-if="hasError")
        p(v-if="errorMessage && errorMessage.length") {{ errorMessage }}
        p(v-else) Unable to Get Menu Data
      template(v-else-if="isEmpty")
        p This Menu Has No Recipes Or The Recipes Are All Empty
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.titan-menu-app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1
  color: #fff
  background: transparent

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  > .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 5
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .messages
    text-align: center
    z-index: 5

    p
      font-size: 1.5em
      line-height: 150%
      margin: 0
      padding: 0.3em 2em

  // DARK TEXT
  &.dark-text
    color: $appDarkTextColor

  // TEXT SHADOW
  &.show-text-shadow:not(.dark-text)
    appTextShadow()
    .fa-icon,
    .food-icon
      appIconShadow()

</style>
