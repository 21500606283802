<script>
import qs from 'qs'
import Moment from 'moment-timezone'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  mapGetters
} from 'vuex'

import ImageItem from 'components/items/ImageItem.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

import Log from 'services/log'
import OfflineCaches from 'services/offline-caches'
import RSS from 'services/rss'
import Utils from 'services/utils'
import OpenGraph from 'services/opengraph'
import Env from 'services/environment'

const config = {
  // In vmin
  BASE_FONTSIZE: 3,

  // Width / Height ratio. Switch to `wide` layout when threshold reached
  IS_WIDE: 4,

  // In ms. Interval to update from feed source
  // - 10 mins
  UPDATE_INTERVAL: 600000,

  // In ms. Feed fetching timeout
  FEED_TIMEOUT: 10000,

  // In ms. OpenGraph fetching timeout
  OPENGRAPH_TIMEOUT: 6000,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 1000
}

const BASE_CDN_PROXY_URL = `${Env.cdnURL()}/proxy`

// Override deprecated source feed (#186628394)
const OVERRIDE_SOURCES = new Map(Object.entries({
  'npr-feed': [
    {
      old: 'https://www.npr.org/rss/rss.php',
      new: 'https://feeds.npr.org/1001/rss.xml'
    }
  ],
  'seattle-times-feed': [
    {
      old: 'http://www.seattletimes.com/nation-world/world/feed',
      new: 'https://www.seattletimes.com/nation-world/world/feed/'
    }
  ]
}))

export default {
  name: 'RSSPage',

  components: { ImageItem, ResponsiveMarkupText },

  props: {
    active: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: true,
      hasRendered: false,

      // Indicator to prevent pending await
      destroyed: false,

      sourcefeed: '',
      noOlderThan: undefined,
      appType: undefined,
      interval: 10,
      updateInterval: +config.UPDATE_INTERVAL,
      playOnlyOne: false,
      zoomEffect: true,
      hAlign: 'left',

      resources: [],
      lastPlayFeedID: undefined,

      errorMessage: '',

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      theme: 'default',

      // Actual width/height
      itemSize: {},

      debounceTimer: undefined,
      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined,
      errorTimer: undefined,
      getFeedInterval: undefined
    }
  },

  computed: {
    ...mapGetters([
      'feedItemMetadataByURL',
      'activeToken'
    ]),

    baseFontSize () {
      const fontSize = Utils.baseFontSize(this.itemSize)
      return fontSize ? fontSize * config.BASE_FONTSIZE : undefined
    },

    fontSizeStyle () {
      if (!this.baseFontSize) { return }
      return {
        fontSize: `${this.baseFontSize}px`
      }
    },

    isPortrait () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.h > this.itemSize.w
      }
      return false
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE
      }
      return false
    },

    localKey () {
      if (!this.sourcefeed) { return }
      return `rss-app_${this.sourcefeed}`
    },

    validItems () {
      if (!this.resources || !this.resources.length) { return [] }
      return this.resources.filter(item => {
        // Display resources with valid title and publish time
        return (item.title || '').trim().length &&
               (item.pubDate || item.publishedParsed || item.published || '').trim().length
      })
    },

    feedLogo () {
      if (!this.appType) { return }
      const iconURL = RSS.rssFeedIcon(this.appType)
      return iconURL ? { backgroundImage: `url(${iconURL})` } : undefined
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    useNoMixedTheme () {
      return this.theme === 'nomixed'
    }
  },

  watch: {
    'item.url' () {
      this.render()
    },

    sourcefeed (newValue) {
      if (newValue && newValue.length) {
        this.hasRendered = false
        this.getFeed(true)
      }
    }
  },

  mounted () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
    clearTimeout(this.errorTimer)
    clearTimeout(this.debounceTimer)
    clearInterval(this.getFeedInterval)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.hasRendered = false
    this.destroyed = false

    OfflineCaches.get(`${this.localKey}_last-played`).then((value) => {
      this.lastPlayFeedID = value
    })

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    clearInterval(this.getFeedInterval)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
    clearTimeout(this.errorTimer)
    clearTimeout(this.debounceTimer)

    this.destroyed = true
  },

  methods: {
    setFeedInterval () {
      clearInterval(this.getFeedInterval)
      this.getFeedInterval = setInterval(() => {
        this.getFeed()
      }, this.updateInterval || config.UPDATE_INTERVAL)
    },

    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.appType = options.appType

      let sourcefeed = decodeURIComponent(options.sourcefeed) || ''
      // Fix Messed up parameter
      // https://app.bugsnag.com/telemetry/custom-apps/errors/5bcc08f6f9c4b900199495ea?filters[event.since][0]=30d&filters[error.status][0]=open
      if (sourcefeed.startsWith('sourcefeed=')) {
        sourcefeed = sourcefeed.replace('sourcefeed=', '')
      }
      this.sourcefeed = this.getOverrideSource(sourcefeed, this.appType)

      this.noOlderThan = options.noOlderThan

      this.playOnlyOne = (options.playOnlyOne === true || options.playOnlyOne === 'true')

      const pageDuration = this.item.page_duration || 0
      if (this.playOnlyOne) {
        this.interval = pageDuration
      } else {
        this.interval = Math.max(4, options.transitionInterval || 10)
      }

      // `options.refreshInterval` can be undefined
      this.updateInterval = (options.refreshInterval && options.refreshInterval > 0) ? (options.refreshInterval * 60000) : +config.UPDATE_INTERVAL
      if (this.updateInterval < 60000) {
        this.updateInterval = +config.UPDATE_INTERVAL
      }
      this.setFeedInterval()

      this.zoomEffect = !(options.zoomEffect === false || options.zoomEffect === 'false')
      this.hAlign = options.hAlign || 'left'
      this.theme = options.theme || 'default'
    },

    getOverrideSource (sourcefeed, appType) {
      if (!sourcefeed?.length || !appType?.length) { return sourcefeed }

      const override = OVERRIDE_SOURCES.get(appType)
      if (!override?.length) { return sourcefeed }

      for (const item of override) {
        if (item.old === sourcefeed) {
          return item.new
        }
      }
      return sourcefeed
    },

    getFeed (forceRefresh) {
      const configs = {
        timeout: config.FEED_TIMEOUT,
        noserviceworker: true,
        // In ms (For LRUCache)
        maxAge: this.updateInterval
      }

      if (this.noOlderThan) {
        configs.noolderthan = this.noOlderThan
      }
      RSS.getCacheFirst(
        // feedURL
        this.sourcefeed,
        {},
        configs
      ).then(data => {
        const items = data.items || []

        if (!items.length && forceRefresh) {
          // Fire error on app init
          this.loaded()
          this.resources = []
          this.errorMessage = 'No valid feed found with this url'
          return
        }

        this.resources = items
        if (items.length) {
          OfflineCaches.set(`${this.localKey}_items`, { items })
        }

        if (forceRefresh) {
          this.errorMessage = ''
          this.prepareNext(true)
        }
      }).catch(err => {
        this.errorHandler(err, forceRefresh)
      })
    },

    async prepareNext (isUpdate) {
      clearTimeout(this.showNextTimer)
      if (!this.validItems || !this.validItems.length) {
        this.errorMessage = 'No valid feed found with this url'
        this.loaded()
        return
      }

      // Force Reset
      if (isUpdate) {
        this.lastPlayFeedID = await OfflineCaches.get(`${this.localKey}_last-played`)
      }

      let currentIndex = -1
      if (this.lastPlayFeedID) {
        currentIndex = this.validItems.findIndex(this.findLastPlayItemIndex)
      }

      const nextIndex = (currentIndex + 1) % this.validItems.length

      const nextItem = JSON.parse(JSON.stringify(this.validItems[nextIndex]))

      if (this.validItems.length > 1 || !this.hasRendered) {
        let metadata
        try {
          metadata = await this.fetchMetaData(nextItem)
        } catch (err) {
          const orginalLink = this.getOrginalLink(nextItem)
          const errMsg = err.toString() || ''
          // Network Error
          // - temporally connection error, or
          // - access rejected by CORS header
          // - the source link is 404
          // - the source link don't have any metadata
          if (
            errMsg.toLowerCase().includes('network error') ||
            errMsg.toLowerCase().includes('timeout') ||
            errMsg.toLowerCase().includes('404')
          ) {
            Log.debug('app',
              `RSS app - Unable to parse metadata from feed source link "${this.sourcefeed}"`,
              'DBG_RSSPARSEMETADATA',
              {
                url: orginalLink,
                error: err
              },
              true
            )
          // Non-network-error, Notify BugSng
          } else {
            Log.warn('app', `RSS app feed metadata parsing error - ${errMsg} "${this.sourcefeed}"`, 'WAR_RSSPARSEERR', {
              url: orginalLink,
              error: err
            })
          }
        }

        if (metadata) {
          let hasError = false
          for (const key in metadata) {
            const value = metadata[key]

            if (!value) { continue }

            hasError = value.includes('404') || value.toLowerCase().includes('page not found')

            if (hasError) { break }
          }
          if (!hasError) {
            nextItem.opengraph = metadata
          }
        }
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      this.loaded()

      if (this.destroyed) {
        // Prevent fetching on the background when component already destroyed.
        // It happens when the `await this.fetchMetaData(nextItem)` didn't finished before Vue called "beforeDestroy()"
        return
      }

      // Is first init
      if (!this.hasRendered) {
        clearTimeout(this.showNextTimer)
        this.showNext(nextIndex)
      } else if (this.validItems.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext () {
      clearTimeout(this.showNextTimer)

      // Advance to next page when "One At A Time" is toggled ON
      if (this.hasRendered && this.playOnlyOne) {
        this.$emit('finished')
        return
      }

      const activeIndex = this.validItems.findIndex(this.findLastPlayItemIndex)

      // Store last played item key
      const nextItem = this.showPrime ? this.baseItem : this.primeItem
      if (nextItem) {
        const itemKey = this.getItemKey(nextItem)
        this.lastPlayFeedID = itemKey
        OfflineCaches.set(`${this.localKey}_last-played`, itemKey)
      }

      this.showPrime = !this.showPrime
      this.hasRendered = true

      if (!this.validItems.length) {
        return
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.validItems.length && activeIndex >= this.validItems.length - 1) {
        this.$emit('finished')
      }

      // Skip preparing next item when "One at a time" plus Playlist Page duration not set
      if (this.playOnlyOne && !this.interval) { return }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      // Add fallback to prevent set timeout <= 0
      }, Math.max((this.interval || 10) * 1000 - config.OPENGRAPH_TIMEOUT - config.PREPARE_TIME, (config.OPENGRAPH_TIMEOUT + config.PREPARE_TIME + 1000)))
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    },

    getOrginalLink (item) {
      // The item `link` is usually a shorthand or redirect link with FeedBurner or Google domain
      // Which will raise the CORS or network error
      // Need to grab the *real* link from hidden properties
      let feedLink = item.link || ''

      // Is iTunes feed. OpenGraph metadata not supported
      if (item.extensions && item.extensions.itunes) {
        return
      }

      // Grab original link from FeedBurner
      if (item.extensions && item.extensions.feedburner && item.extensions.feedburner.origLink && item.extensions.feedburner.origLink.length && item.extensions.feedburner.origLink[0] && item.extensions.feedburner.origLink[0].value) {
        feedLink = item.extensions.feedburner.origLink[0].value
      // Sometimes the original link lies in the `guid` field
      } else if (item.guid && item.guid.startsWith('http')) {
        // NOTE: Need some filters here because
        // when `guid` is shorter than `link`, or when `guid` is *http* and `link` is *https*,
        // then `guid` is probably an unuseful shortlink
        // - e.g: http://www.rawstory.com/category/world/feed
        if (item.guid.length >= feedLink.length && !(item.guid.startsWith('http://') && (item.link || '').startsWith('https://'))) {
          feedLink = item.guid
        }
      }
      return feedLink
    },

    fetchMetaData (item) {
      if (!item) { return Promise.resolve(null) }

      const feedLink = this.getOrginalLink(item)
      if (!feedLink) { return Promise.resolve(null) }

      const storedResult = this.feedItemMetadataByURL(feedLink)
      if (storedResult) {
        return Promise.resolve(storedResult)
      }

      return OpenGraph.fetch(feedLink, config.OPENGRAPH_TIMEOUT)
    },

    imageOptions (imgUrl) {
      if (!imgUrl) { return }
      return {
        url: `${BASE_CDN_PROXY_URL}?url=${encodeURIComponent(imgUrl)}&token=${this.activeToken}&noserviceworker=true`,
        animated_zoom_effect: this.zoomEffect,
        duration: this.interval
      }
    },

    getTitle (item) {
      if (!item) { return }
      if (item.opengraph && item.opengraph.title) {
        return item.opengraph.title
      }
      return item.title
    },

    getTime (item) {
      if (!item) { return }
      let pubDate
      if (item.opengraph && item.opengraph.date) {
        pubDate = item.opengraph.date
      } else {
        pubDate = (item.pubDate || item.publishedParsed || item.published)
      }
      // Some malformed RSS feed item does not have publish time
      // https://app.bugsnag.com/telemetry/player/errors/62ea51347a6a5900086ef6de
      if (!pubDate) {
        return
      }
      return Moment(pubDate).format('h:mm a') + '\n' + Moment(pubDate).format('MMM Do, YYYY')
    },

    getItemKey (item) {
      if (!item) { return }
      return (item.guid || item.link) || (item.pubDate || item.publishedParsed || item.published || '').replace(/\s/g, '').trim()
    },

    findLastPlayItemIndex (item, index) {
      return (item.guid === this.lastPlayFeedID) ||
             (item.link === this.lastPlayFeedID) ||
             (item.pubDate || item.publishedParsed || item.published || '').replace(/\s/g, '').trim() === this.lastPlayFeedID
    },

    formatedText (item) {
      if (!item) { return }

      let sanitizedText = ''

      if (item.opengraph && item.opengraph.description) {
        sanitizedText = this.contextualEscaping(item.opengraph.description)

        // Invalid OpenGraph description contains "$openGraphObjectMap.get" [#DEV-2784]
        if (sanitizedText.indexOf('openGraphObjectMap.get') === -1) {
          return sanitizedText
        }
      }

      // Fallback to item description
      sanitizedText = this.contextualEscaping(item.description)
      return sanitizedText
    },

    contextualEscaping (content) {
      return Utils.contextualEscaping(content)
    },

    async errorHandler (err, isForceRefresh) {
      // Skip displaying error messsage when regular feed updates (via setInterval) fails
      if (!isForceRefresh) { return }

      // Display stale data when there's any
      const staleData = await OfflineCaches.get(`${this.localKey}_items`)
      if (staleData && staleData.items && staleData.items.length) {
        this.resources = staleData.items
        this.errorMessage = ''
        this.prepareNext(true)
        return
      }

      // Force advance to next page when error occurs + no stale data found

      const errorMessage = err.message || err.toString()
      this.errorMessage = errorMessage
      Log.warn('app', `RSS Error - ${errorMessage} "${this.sourcefeed}"`, 'WAR_RSSERR', {url: this.sourcefeed, error: err})
      this.loaded()

      clearTimeout(this.errorTimer)
      this.errorTimer = setTimeout(() => {
        clearTimeout(this.errorTimer)
        this.$emit('finished')
      }, 5000)
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      if (!this.$el) { return }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        width = container.offsetWidth || container.clientWidth
        height = container.offsetHeight || container.clientHeight

        if (!width || !height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: width,
          h: height
        }

        FastDom.clear(measure)
      })
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.rss-page(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide, 'theme-nomixed': useNoMixedTheme}", :style="fontSizeStyle")
  .resize-sensor(ref="sensor")

  transition(name="fade" appear)
    .error-message(v-if="!loading && errorMessage")
      p RSS Error: {{ errorMessage }}
      p.url {{ sourcefeed }}

  template(v-if="!errorMessage")
    .feed-image-layer(:class="{'uninitialized': loading}")
      //- PRIME
      template(v-if="primeItem && primeItem.opengraph && primeItem.opengraph.image")
        transition(name="fade" appear mode="out-in")
          image-item(v-show="showPrime", :item="imageOptions(primeItem.opengraph.image)", :no-mixed-mode="useNoMixedTheme" stack="rss")
      //- BASE
      template(v-if="baseItem && baseItem.opengraph && baseItem.opengraph.image")
        transition(name="fade" appear mode="out-in")
          image-item(v-show="!showPrime", :item="imageOptions(baseItem.opengraph.image)", :no-mixed-mode="useNoMixedTheme" stack="rss")

    .feed-context-layer.app-context-block(:class="{'show-text-shadow': !useNoMixedTheme, 'hide-box-outline': useNoMixedTheme, 'uninitialized': !baseFontSize || loading}")
      .main-section.app-context-section.primary
        //- PRIME
        template(v-if="primeItem")
          transition(name="fade" appear :duration="1000" mode="out-in")
            .context-item-wrapper.prime(v-show="showPrime")
              .feed-title(:class="[horzClass]") {{ getTitle(primeItem) }}
              .feed-description
                responsive-markup-text(
                    :class="[horzClass]"
                    :text="formatedText(primeItem)"
                    :base-font-size="baseFontSize")
        //- BASE
        template(v-if="baseItem")
          transition(name="fade" appear :duration="1000" mode="out-in")
            .context-item-wrapper.base(v-show="!showPrime")
              .feed-title(:class="[horzClass]") {{ getTitle(baseItem) }}
              .feed-description
                responsive-markup-text(
                    :class="[horzClass]"
                    :text="formatedText(baseItem)"
                    :base-font-size="baseFontSize")

      .secondary-section.app-context-section.secondary
        //- INVISIBLE. For better height calculation
        .meta-infos.invisible
          .logo-block
          .date-time
            span {{ getTime(showPrime ? primeItem : baseItem) }}

        .meta-infos
          .logo-block(:class="{'logo-gray': feedLogo && useNoMixedTheme}", :style="feedLogo")
          .date-time
            //- INVISIBLE. For better width calculation
            span.invisible {{ getTime(showPrime ? primeItem : baseItem) }}

            //- PRIME
            transition(name="fade" appear :duration="1000" mode="out-in")
              .datetime-inner.prime(v-show="showPrime")
                span {{ getTime(primeItem) }}
            //- BASE
            transition(name="fade" appear :duration="1000" mode="out-in")
              .datetime-inner.base(v-show="!showPrime")
                span {{ getTime(baseItem) }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.rss-page
  color: white
  position: relative
  height: 100%
  width: 100%

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .loading
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 1

    padding: 0.8em 2em

  .error-message
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 2

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    font-size: 1.2em
    padding: 0 1em
    text-align: center

    .url
      opacity: 0.6
      font-size: 0.7em
      padding-top: 0.5em

  .feed-image-layer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 2

  .feed-context-layer
    position: absolute
    top: 3em
    bottom: 3em
    right: 1.5em
    left: auto
    width: 33%
    z-index: 5

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    .main-section
      flex: 1 1 0.00001px
      position: relative
      z-index: 0

      .context-item-wrapper
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1
        padding: 1em

        display: flex
        flex-flow: column nowrap
        justify-content: space-between
        align-items: stretch

        &.prime
          z-index: 2

      .feed-title
        font-size: 1.7em
        font-weight: 600
        line-height: 120%
        padding-bottom: 0.5em
        text-transform: capitalize
        contentBoxAlignments()

      .feed-description
        flex: 1 1 0.00001px
        position: relative

    .secondary-section
      position: relative
      z-index: 0

      .meta-infos
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1

        display: flex
        flex-flow: row nowrap
        justify-content: space-between
        align-items: center
        padding: 0.5em 1em
        overflow: hidden
        max-width: 100%

        &.invisible
          z-index: -1
          visibility: hidden
          opacity: 0
          position: relative

        .logo-block
          margin-right: 1.5em
          height: 2em
          flex: 1 1 0.00001px
          background-repeat: no-repeat
          background-position: left center
          background-size: contain

          &.logo-gray
            -webkit-filter: brightness(0.5)
            filter: brightness(0.5)

        .date-time
          position: relative
          line-height: 120%
          text-align: right
          white-space: pre

          .invisible
            z-index: -1
            visibility: hidden
            opacity: 0
            position: relative

          .datetime-inner
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            z-index: 1

            display: flex
            flex-flow: row nowrap
            justify-content: flex-end
            align-items: center
            text-align: right

            > span
              opacity: 0.7

            &.prime
              z-index: 2

  // =======================
  // PORTRAIT LAYOUT
  // =======================

  &.is-portrait
    .feed-context-layer
      top: auto
      bottom: 4em
      right: 3em
      left: 3em
      height: 35%
      width: auto

  // =======================
  // WIDE LAYOUT
  // =======================

  &.is-wide
    .feed-context-layer
      top: 1.5em
      bottom: 1.5em
      right: 1.5em
      left: auto
      width: 48%

  // =======================
  // NO-MIXED LAYOUT
  // =======================

  &.theme-nomixed
    background-color: white

    .main-section,
    .secondary-section
      color: black
      background-color: white !important

    .feed-context-layer
      padding: 1.5em 1em

    //
    // + LANDSCAPE
    //
    &.landscape
      .feed-image-layer
        right: 38%
      .feed-context-layer
        top: 0
        bottom: 0
        right: 0
        width: 38%

    //
    // + WIDE
    //
    &.is-wide
      .feed-image-layer
        right: 48%
      .feed-context-layer
        width: 48%
        padding: 1em

    //
    // + PORTRAIT
    //
    &.is-portrait
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: stretch

      .feed-image-layer
        position: relative
        top: 0
        left: 0
        bottom: auto
        width: 100%
        height: 0
        // Keep Image Ratio to 16:9
        padding-top: 56.25%
        .image-page
          top: 0
          left: 0

      .feed-context-layer
        flex: 1 1 0.00001px
        position: relative
        top: 0
        left: 0
        bottom: auto
        width: 100%
        padding: 2.5em 2em
</style>
