<script>
import Log from '../../services/log.js'

export default {
  name: 'Console',
  data () {
    return {
      items: [],
      paused: false
    }
  },

  mounted () {
    Log.on('created', this.handleNewLog)

    // No need to watch changes here. Use Object.freeze to save some MEM
    this.items = Object.freeze(Log.getLogs())
  },

  beforeDestroy () {
    Log.removeListener('created', this.handleNewLog)
  },

  methods: {
    handleNewLog (event) {
      // No need to watch changes here. Use Object.freeze to save some MEM
      this.items = Object.freeze(Log.getLogs())
      this.$nextTick(() => {
        this.scrollToLatestItem()
      })
    },

    scrollToLatestItem () {
      if (this.paused) {
        return
      }
      const fullHeight = this.$el.scrollHeight
      this.$el.scrollTop = fullHeight
    },

    onMouseOver () {
      if (!this.paused) {
        this.paused = true
      }
    },

    onMouseLeave () {
      this.paused = false
    }
  }
}
</script>

<template lang="pug">
section#consolelog(@mouseover="onMouseOver" @mouseleave="onMouseLeave")
  ul
    li(v-for="(item, index) in items", :class="item.severity")
      span.time {{item.time}}
      span.message {{item.message}}
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section#consolelog {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  scroll: auto;
  user-select: auto;
  padding-right: 1em;
  scrollBarDark();

  ul {
    margin: 0;
    padding: 1em 0;
    list-style: none;
  }

  li {
    margin: 0 0 0.7em 0;

    span.time {
      color: var(--gray);
      margin-right: 0.5em;
    }

    span.message {
      word-break: break-all;
    }
  }
}

.info {
  color: var(--brand-info);
}

.warn {
  color: var(--brand-warning);
}

.error {
  color: var(--brand-danger);
}

.debug {
  color: var(--gray-light);
}
</style>
