<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import BrowserStorage from 'services/browserstorage'

export default {
  name: 'Indicator',
  data () {
    return {
      isAPIUpdating: true,
      messageVisible: false,
      hideTimer: null,
      checkTimer: null
    }
  },

  computed: {
    ...mapState({
      apiStatus: state => state.api.apiStatus,
      hideIndicator: state => state.account.hideIndicator || false,
      storageDegraded: state => state.caches.storageDegraded || false,
      multipleEvictions: state => state.caches.multipleEvictions || false,
      storageFull: state => state.caches.storageFull || false,
      storageDegradedTs: state => state.caches.storageDegradedTs || -1,
      launcherWebsocketMode: state => state.api.launcherWebsocketMode || false
    }),
    ...mapGetters([
      'apiStreamStatus',
      'slowNetwork'
    ]),

    isAPIOffline () {
      return ['offline', 'disconnected', 'errored'].includes(this.apiStreamStatus) ||
        (this.launcherWebsocketMode && this.apiStatus === 'offline')
    },

    isLocalSocketError () {
      return this.launcherWebsocketMode && this.apiStatus === 'errored'
    },

    // local WS Error > offline > degraded > cache evictions > storage full > slow network
    activeIndicator () {
      if (this.hideIndicator) {
        return false
      }
      if (this.isLocalSocketError) {
        return 'local-socket-error'
      }
      if (this.isAPIOffline) {
        return 'offline'
      }
      if (this.isAPIUpdating) {
        return 'api-updating'
      }
      if (this.storageDegraded) {
        return 'degraded'
      }
      if (this.multipleEvictions) {
        return 'evictions'
      }
      if (this.storageFull) {
        return 'storage-full'
      }
      if (this.slowNetwork) {
        return 'slow-network'
      }
      return false
    },

    displayIcon () {
      if (!this.activeIndicator) { return '' }
      switch (this.activeIndicator) {
        case 'offline':
        case 'slow-network':
        case 'api-updating':
          return 'wifi'
        case 'degraded':
        case 'evictions':
        case 'storage-full':
          return 'save'
        case 'local-socket-error':
          return 'link-slash'
        default:
          return ''
      }
    },

    displayMessage () {
      if (!this.activeIndicator) { return '' }
      switch (this.activeIndicator) {
        case 'offline':
        case 'api-updating':
          if (this.launcherWebsocketMode && this.apiStatus === 'offline') {
            return this.apiStatus
          }
          return this.apiStreamStatus
        case 'slow-network':
          return 'Slow Network'
        case 'degraded':
          return 'Storage Degraded'
        case 'evictions':
          return 'Multiple Cache Evictions'
        case 'storage-full':
          return 'Storage Full'
        case 'local-socket-error':
          return 'Local Socket Error'
        default:
          return ''
      }
    },

    severityClass () {
      if (!this.activeIndicator) { return 'info' }
      switch (this.activeIndicator) {
        case 'offline':
        case 'degraded':
          return 'error'
        case 'evictions':
          return 'alert'
        case 'slow-network':
        case 'storage-full':
          return 'warn'
        case 'local-socket-error':
          return 'ls-error'
      }
      return 'info'
    }
  },

  watch: {
    apiStreamStatus () {
      this.isAPIUpdating = true
      this.setMsgVisible()
    },

    activeIndicator (newValue) {
      // Only respond when not `false`
      if (newValue) {
        this.setMsgVisible()
      }
    }
  },

  mounted () {
    clearTimeout(this.hideTimer)
    clearInterval(this.checkTimer)

    this.setMsgVisible()

    // Check every 60s instead of 1s, for better performance
    this.checkTimer = setInterval(() => {
      this.checkStorageStatus()
    }, 60000)
  },

  beforeDestroy () {
    clearTimeout(this.hideTimer)
    clearInterval(this.checkTimer)
  },

  methods: {
    debounceMsgHidden () {
      clearTimeout(this.hideTimer)
      this.hideTimer = setTimeout(() => {
        clearTimeout(this.hideTimer)
        this.messageVisible = false
        if (this.isAPIUpdating && this.apiStreamStatus === 'active') {
          this.isAPIUpdating = false
        }
      }, 8000)
    },

    setMsgVisible () {
      this.messageVisible = true
      this.debounceMsgHidden()
    },

    checkStorageStatus () {
      const oneDayAgo = Date.now() - 86400 * 1000
      if (this.storageDegraded) {
        if (this.storageDegradedTs > 0 && this.storageDegradedTs < oneDayAgo) {
          this.$store.commit('updateStorageDegraded', false)
        }
      }
      if (this.multipleEvictions) {
        const lastEviction = BrowserStorage.get('lastCacheEviction') || 0
        if (lastEviction > 0 && lastEviction < oneDayAgo) {
          this.$store.commit('updateMultipleEvictions', false)
        }
      }
    }
  }
}
</script>

<template lang="pug">
transition(name="bounce")
  section#state(v-if="activeIndicator", :class="severityClass")
    span.icon(v-if="displayIcon" @mouseover="setMsgVisible")
      fa(:icon="displayIcon" fixed-width)
    span.message(v-show="messageVisible") {{ displayMessage }}
</template>

<style lang="stylus">
section#state
  z-index: 2000
  display: flex
  align-items: center
  position: absolute
  left: 0
  bottom: 0
  padding: 10px
  margin: 10px
  height: 30px
  border-radius: 15px
  background-color: rgba(0, 0, 0, 0.5)
  cursor: pointer

  span.icon,
  span.message
    transition: color .5s

  span.icon
    font-size: 20px
    line-height: 20px
    height: 20px
    text-align: center

  span.message
    margin: 0 5px 0 5px
    font-size: 18px
    text-transform: uppercase
    font-weight: 600
    padding: 0

  &.info
    color: var(--brand-info)

  &.warn
    color: var(--brand-warning)

  &.alert
    color: darkorange

  &.error
    color: var(--brand-danger)

  &.debug
    color: var(--brand-info)

  &.ls-error
    color: darkorchid
</style>
