<script>
import qs from 'qs'
import FastDom from 'fastdom'
import { mapGetters } from 'vuex'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import Currency from 'services/currency'
import Utils from 'services/utils.js'
import Env from 'services/environment'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,
  BASE_CURRENCY_LIST_FONTSIZE: 6
}

export default {
  name: 'CurrencyItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      rates: {},
      loadingSymbols: true,
      loadingCurrencies: true,

      // Actual width/height
      itemSize: {},
      // [DEV-1806] show error message
      errorMsg: '',

      getCurrenciesTimer: undefined,
      debounceTimer: undefined,
      refreshInterval: undefined
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),

    isDevelopment () {
      return Env.isDevelopment()
    },

    params () {
      return qs.parse(decodeURIComponent(this.item.url || this.item.location))
    },

    base () {
      return this.params.base
    },

    symbols () {
      return this.params.symbols
    },

    baseFontSize () {
      const fontSize = Utils.baseFontSize(this.itemSize)
      return fontSize ? {fontSize: `${fontSize * config.BASE_FONTSIZE}px`} : undefined
    },

    isPortrait () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.h > this.itemSize.w
      }
      return false
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > 4
      }
      return false
    },

    wideRatio () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h
      }
      return 1
    },

    currencyListStyle () {
      if (this.isWide && this.wideRatio > 1) {
        return {fontSize: config.BASE_CURRENCY_LIST_FONTSIZE / this.wideRatio + 'em'}
      }
      return {}
    }

  },

  watch: {
    base () {
      this.debounceGetCurrencies()
    },

    symbols () {
      this.debounceGetCurrencies()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.getCurrenciesTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    this.debounceCheckSize()

    this.getSymbols()

    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.debounceGetCurrencies()
    }, 60 * 60 * 1000)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearTimeout(this.getCurrenciesTimer)
    clearInterval(this.refreshInterval)
  },

  methods: {
    getSymbols () {
      if (!this.activeToken) { return }
      Currency.getSymbols(this.activeToken).then(() => {
        this.loadingSymbols = false
        this.debounceGetCurrencies()
      }).catch((err) => {
        this.errorMsg = err.message || err.toString() || ''
        this.loaded()
      })
    },

    debounceGetCurrencies () {
      clearTimeout(this.getCurrenciesTimer)
      this.getCurrenciesTimer = setTimeout(() => {
        clearTimeout(this.getCurrenciesTimer)
        this.getCurrencies()
      }, 200)
    },

    getCurrencies () {
      if (!this.base || !this.symbols || !this.activeToken || this.loadingSymbols) {
        return
      }
      Currency
        .get(this.base, this.symbols, this.activeToken).then(rates => {
          this.errorMsg = ''
          this.rates = rates
          this.loaded()
        })
        // [DEV-1806] catch invalid currency and show message
        .catch(err => {
          this.errorMsg = err.message || err.toString() || ''
          this.loaded()
        })
    },

    getSymbolFullName (symbol) {
      return Currency.getSymbolFullName(symbol)
    },

    getFlagClassName (symbol) {
      return Currency.getFlagClassName(symbol)
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      const container = this.$el
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        const metrics = container.getBoundingClientRect()

        if (!metrics.width || !metrics.height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: metrics.width,
          h: metrics.height
        }
        FastDom.clear(measure)
      })
    },

    loaded () {
      if (this.loadingCurrencies) {
        this.loadingCurrencies = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.currency-item(:style="baseFontSize", :class="{'is-portrait': isPortrait, 'is-wide': isWide}")
  .resize-sensor(ref="sensor")

  .item-inner-wrapper
    h1.loading(v-if="loadingCurrencies") Loading...

    .title(v-if="!loadingCurrencies && !errorMsg")
      span.flag-icon(:class="[getFlagClassName(base), {'qa-src': isDevelopment}]")
      h1 1 {{ base }} ({{ getSymbolFullName(base) }})
    //- [DEV-1806] catch invalid currency and show message
    .error-message(v-else)
      h1 {{ errorMsg }}

    transition-group(v-if="!errorMsg" tag="div" name="list" class="currency-list" :style="currencyListStyle")
      .currency-block(v-for="(rate, symbol) in rates", :key="symbol")
        .currency
          .left
            .symbol
              span.flag-icon(:class="[getFlagClassName(symbol), {'qa-src': isDevelopment}]")
              p.symbol-sign {{ symbol }}
            p.name {{ getSymbolFullName(symbol) }}
          .right
            p.rate {{ rate }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'
@import '../../style/widgets/flag-icons.styl'

section.currency-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0.5em;
  position: relative;

  > .resize-sensor {
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

  .title, .error-message {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: -1em 0 1em 0;

    h1 {
      color: white;
      margin: 0 .5em;
      font-size: 1em;
      font-family: inherit;
    }

    .flag-icon {
      width: 1.3em;
      height: 1em;
      border-radius: 0.15em;
      margin-right: 0.1em;
    }
  }

  .loading {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.7em;
  }

  .item-inner-wrapper {
    align-self: stretch;

    .currency-list {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
    }
  }

  .currency-block {
    padding: 0;
    width: 50%;
  }

  .currency {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-darker);
    margin: 0.3em;
    padding: 0.6em;
    overflow: hidden;

    .left {
      display: flex;
      flex: 1 1 0.0001px;
      align-items: flex-start;
      flex-direction: column;
      overflow: hidden;

      .name {
        color: var(--gray);
        width: 100%;
        max-width: 100%;
        font-size: 0.7em;
        font-weight: normal;
        padding-top: 0.2em;
        margin: 0;
        ellipsis()
      }

      .symbol {
        display: flex;
        justify-content: center;
        align-items: center;

        .flag-icon {
          width: 1.2em;
          height: 0.9em;
          border-radius: 0.15em;
          margin-right: 0.2em;
        }

        p {
          color: white;
          font-size: 1em;
          line-height: 1em;
          margin: 0;
          margin-left: 0.1em;
        }

        .symbol-sign {
          font-weight: 600;
        }
      }
    }

    .right {
      margin-left: 0.8em;
      .rate {
        display: flex;
        justify-content: flex-end;
        color: white;
        font-size: 1em;
        line-height: 1em;
        margin: 0;
        font-weight: 600;
      }
    }
  }

  //
  // PORTRAIT LAYOUT
  //
  &.is-portrait {
    .item-inner-wrapper {
      .currency-list {
        flex-direction: column;
      }
      .currency-block {
        width: 100%;
      }
    }
  }

  //
  // EXTRA WIDE LAYOUT
  //
  &.is-wide {
    .item-inner-wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: flex-start;

      .title {
        width: auto;
        margin: 0 1em;
        text-align: left;
        align-items: flex-start;
      }

      .currency-list {
        font-size: 0.3em;
        flex: 1 1 0.00001px;
      }
    }
    .currency-block {
      width: 33.3333%;
    }
  }
}
</style>
