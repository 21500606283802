<script>
import {
  mapState, mapGetters
} from 'vuex'

import Console from '../common/Console.vue'
import Background from '../common/Background.vue'
import OverlayHeader from '../common/OverlayHeader.vue'
import Message from '../common/Message.vue'
import Version from '../common/Version.vue'

import Telemetry from 'services/telemetry.js'
import Environment from 'services/environment'
import Log from 'services/log.js'
import { EventBus } from 'services/eventbus.js'
import QRCode from 'qrcode'

export default {
  name: 'Pairing',
  components: {
    Message,
    Background,
    OverlayHeader,
    Version,
    Console
  },
  data () {
    return {
      pairing: false,
      reserving: false,
      codeA: '',
      codeB: '',
      restartTimeout: undefined,
      chromeAPI: Environment.chromeAPI,
      androidAPI: Environment.androidAPI,
      touch: Environment.touch
    }
  },

  computed: {
    ...mapState({
      debugMode: s => s.playbackmode.debugMode
    }),
    ...mapGetters({
      claimed: 'claimed',
      authenticationToken: 'authenticationToken',
      hasSavedPairingCode: 'hasSavedPairingCode',
      pairingCode: 'pairingCode',
      reservationExpiresInSeconds: 'reservationExpiresInSeconds',
      apiAutomaticallyReconnect: 'apiAutomaticallyReconnect',
      whiteLabelledName: 'whitelabel/resellerName',
      deviceSerialNumber: 'serialNumber',
      deviceModel: 'model'
    }),

    productName () {
      if (this.whiteLabelledName) {
        return this.whiteLabelledName
      }
      return 'TelemetryTV'
    },

    qrText () {
      return `${Environment.userAppURL()}/mobile/pair-qr-code/${this.pairingCode}`
    }
  },

  mounted () {
    clearTimeout(this.restartTimeout)

    EventBus.$on('localstorage-quota-exceeded', this.localStorageExceeded)
    // NOTE: Move storage access denied error catcher to "app.vue" (DEV-4305)

    if (Environment.hasCriticalStorageError()) {
      this.storageDenied()
      return
    }

    // Fix event racing due to component mounted sequence [#DEV-4466]
    this.$nextTick(() => {
      // Redirect to starting page when current device is already claimed [DEV-2978]
      if (this.claimed || this.authenticationToken) {
        Log.debug('player', 'Player is already claimed', 'DBG_ALREADYCLAIMED', null, true)
        this.$router.push({
          name: 'starting'
        })
        return
      }

      if (!this.apiAutomaticallyReconnect) {
        this.$store.commit('enableApiAutomaticReconnect')
      }

      if (this.hasSavedPairingCode) {
        this.codeA = this.pairingCode.substr(0, 3)
        this.codeB = this.pairingCode.substr(3, 3)
        this.reserving = false
        this.pairing = true
        this.generateQRCode()
        this.reservationExpireHandler()
        Telemetry.sendListenCommands('pairing')
          .catch((err) => {
            return this.errorHandler({
              params: {
                error: 'api-error'
              },
              query: {
                text: `listen: ${err.message}`
              }
            })
          })
      } else {
        this.reserving = true
        // Re-enable auto-connection before requesting for a new Pairing code
        this.$store.commit('enableApiAutomaticReconnect')
        Telemetry.send({method: 'RESERVE_DEVICE', params: {
          locked: Environment.locked,
          device_serial_number: this.deviceSerialNumber,
          device_model: this.deviceModel
        }})
          .then((result) => {
            this.$store.commit('setPairingInfo', result)
            this.reserving = false
            this.pairing = true
            this.codeA = result.code.substr(0, 3)
            this.codeB = result.code.substr(3, 3)
            this.reservationExpireHandler()
            Telemetry.sendListenCommands('pairing')
              .catch((err) => {
                return this.errorHandler({
                  params: {
                    error: 'api-error'
                  },
                  query: {
                    text: `listen: ${err.message}`
                  }
                })
              })
          })
          .catch((err) => {
            Log.error('player', `Error reserving device - ${err.message || err.toString()}`, 'ERR_RESERVEDEVICE', err)
            this.errorHandler({
              params: {
                error: 'api-error'
              },
              query: {
                text: `reserve_device: ${err.message}`
              }
            })
          })
      }
    })
  },

  beforeDestroy () {
    clearTimeout(this.restartTimeout)

    EventBus.$off('localstorage-quota-exceeded', this.localStorageExceeded)
  },

  watch: {
    pairingCode () {
      this.generateQRCode()
    }
  },

  methods: {
    errorHandler (payload) {
      const params = (payload && payload.params) || {}
      const query = (payload && payload.query) || {}
      return this.$router.push({
        name: 'errored',
        params,
        query
      })
    },

    reservationExpireHandler () {
      const expIn = this.reservationExpiresInSeconds
      if (expIn > 0) {
        clearTimeout(this.restartTimeout)
        this.restartTimeout = setTimeout(() => {
          clearTimeout(this.restartTimeout)
          if (Environment.isVueElectronPlayer()) {
            Environment.restart()
            return
          }
          this.$router.push({ name: 'restarting' })
        }, expIn * 1000)
      }
    },

    localStorageExceeded () {
      // NOTE: Only redirect to "localstorage-quota-exceeded" on this Pairing screen
      // Keep the paired/provisioned devices intact.
      this.$router.push({
        name: 'errored',
        params: { error: 'localstorage-quota-exceeded' },
        query: { restart: 86400 }
      })
    },

    storageDenied () {
      this.$router.push({
        name: 'errored',
        params: { error: 'storage-denied' },
        query: { restart: 86400 }
      })
    },

    generateQRCode() {
      QRCode.toCanvas(this.$refs.qrCanvas, this.qrText, {
        width: 200,
        margin: 0,
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
      })
    }
  }
}
</script>

<template lang="pug">
main.paring-page
  background
  message(v-if="reserving", :text="$t('pair.reservingText')")
  transition(name="fade")
    section.pairing(v-show="pairing")
      h1 {{ $t('pair.title') }}
      .pairing-code-container
        .code {{codeA}}&nbsp{{codeB}}
        .qr-code
          canvas.qr-canvas(ref="qrCanvas")
      span {{ $t('pair.enterThisCode', { productName }) }}
      .pairing-warning(v-if="chromeAPI")
        p(v-if="chromeAPI" v-html="$t('pair.chromeOSHint')")
      .console-container(v-if="debugMode")
        console
      .cog(v-if="touch")
        fa(icon="cog")
  overlay-header(:title="$t('pair.headerTitle')" icon="star")
  version
</template>

<style lang="stylus">
main.paring-page
  section.pairing
    font-size: 3vmin
    position: absolute
    top: 5vmin
    left: 5vmin
    right: 5vmin
    bottom: 5vmin
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    h1
      color: var(--gray-light)
      text-align: center
      font-size: 1.2em
      font-weight: 600
      text-shadow: 0.1vmin 0.1vmin 0.2vmin rgba(0, 0, 0, 0.7)
      margin: 0
      text-transform: uppercase

    span
      padding: 1em
      font-size: 0.37em
      color: var(--gray-light)


    .pairing-code-container
      display: flex
      flex-direction: row
      align-items: center
      background: white
      border-radius: 1.5rem
      padding: 1.5rem 0 1.5rem 4rem

      .qr-code
        display: flex
        padding-right: 1.25rem
        canvas.qr-canvas
          width: 8vmin !important
          height: 8vmin !important

    .pairing-warning
      margin-top: 2vmin
      max-width: 50em
      background-color: var(--brand-danger)
      font-size: 0.5em
      padding: 0.2em
      border: 0.2vmin solid white
      border-radius: 1em
      box-shadow: 0.1vmin 0.1vmin 0.2vmin rgba(0, 0, 0, 0.7)
      p
        margin: 0.5em
        padding: 0em

    .console-container
      height: 100%
      width: 100%
      position: relative
      display: flex
      justify-content: space-around
      padding: 2em

    .code
      display: flex
      padding-right: 4rem
      color: var(--gray-dark)
      line-height: 6vmin
      font-weight: 800
      margin: 0
      font-size: 2.25em
      text-transform: uppercase
      font-family: var(--font-primary)
    .cog
      position: absolute
      right: 0
      bottom: 0
</style>
