<script>
import qs from 'qs'

import MessageBanner from 'components/common/MessageBanner.vue'
import Item from './Item.vue'
import Override from 'components/player/Override.vue'

import BrowserStorage from 'services/browserstorage.js'
import Environment from 'services/environment'
import Log from 'services/log.js'

export default {
  name: 'ItemPreview',
  components: {
    MessageBanner,
    Item,
    Override
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    itemType () {
      return this.$route.params.itemtype
    },
    // Indicator of Override Preview from js_user
    isOverride () {
      return Boolean((this.$route.query && (this.$route.query.isOverride === true || this.$route.query.isOverride === 'true')))
    },
    item () {
      if (!this.itemType) { return }

      const item = {
        type: this.itemType
      }
      const queries = this.$route.query || {}

      if (this.isOverride) {
        item.message = queries.message
      } else if (this.itemType === 'slides') {
        item.duration = queries.duration || 10
      } else if (
        [
          'url',
          'g-sheets',
          'g-docs',
          'g-calendar'
        ].includes(this.itemType)
      ) {
        item.url = queries.url
      } else {
        // "qs.stringify" will add encodeURIComponent automatically
        item.url = qs.stringify(queries)
      }
      return item
    }
  },

  beforeCreate () {
    BrowserStorage.useSessionStorage()
    Environment.previewMode = true
  },

  async created () {
    const urlParams = new URLSearchParams(window.location.search)

    const region = urlParams.get('region')
    if (region) {
      Environment.overrideRegion = region
    }

    const language = urlParams.get('lang')
    if (language) {
      this.$store.commit('setOverrideLanguage', language)
    }
    const token = urlParams.get('session_token') || urlParams.get('user_token')
    if (token) {
      // Set 'await' to make sure AUTH finished before any `/proxy` requests
      this.$store.commit('setAuthenticationToken', token)
      await this.$store.dispatch('startAPI').then(() => {
        this.loading = false
      }).catch(err => {
        Log.error('ws', `Error starting up - ${err.message || err.code || err.toString()}`, 'ERR_UNABLESTARTAPI', err)
      })
    } else {
      this.loading = false
    }
  }
}
</script>

<template lang="pug">
section.page-item-preview
  message-banner
  template(v-if="item && !loading")
    override(v-if="isOverride", :override="item")
    item(v-else :item="item" item-preview-mode)
</template>

<style lang="stylus">
section.page-item-preview
  height: 100vh
  width: 100vw
  position: relative

  > .page-item
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
</style>
