<script>
import OverrideResizeMixin from './OverrideResizeMixin.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

import CommonStyling from 'services/common-styling.js'

export default {
  name: 'OverrideMessageItem',
  components: {
    ResponsiveMarkupText
  },

  mixins: [OverrideResizeMixin],

  props: {
    message: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: ''
    }
  },
  computed: {
    fontClass () {
      if (!this.font) { return '' }
      return CommonStyling.getfontClass(this.font)
    }
  },
  watch: {
    fontClass (newValue) {
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    }
  }
}
</script>

<template lang="pug">
.override-message-item(:style="fontSizeStyle")
  .resize-sensor(ref="sensor")
  .message-content
    responsive-markup-text(
      :margin-vert="0"
      :text="message"
      :base-font-size="baseFontSize"
      :class="fontClass"
      :font-class="fontClass")
</template>

<style lang="stylus">
.override-message-item
  z-index: 52
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .message-content
    font-family: var(--font-secondary)

    position: absolute
    left: 2em
    right: 2em
    bottom: 2em
    top: 2em
</style>
