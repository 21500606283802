<script>
import {
  mapState
} from 'vuex'
import Environment from 'services/environment'
import AppControl from 'services/appcontrol.js'
import Log from 'services/log.js'
import Caches from 'services/caches.js'
import ContentCache from 'services/contentCache.js'
import ElectronAPI from 'platform/electronapi.js'
import { EventBus } from 'services/eventbus'

export default {
  name: 'TitleBar',
  data () {
    return {
      cacheUsed: '',
      cacheQuota: '',

      pendingForPwd: false,
      pendingAction: '',

      assetsTimer: undefined,
      updateTimer: undefined,

      hasNetworkSetup: Environment.electronAPI && Environment.hasNetworkSetup
    }
  },

  computed: {
    ...mapState({
      device: state => state.device.device,
      account: state => state.account.account
    }),

    supportPwd () {
      return (Environment.isVueElectronPlayer() || Environment.isNeoElectronPlayer()) &&
             Boolean(window.supportAccessPassword)
    }
  },

  mounted () {
    EventBus.$on('password-passed', this.proceedAction)
    EventBus.$on('password-cancelled', this.pwdCancelled)

    this.$nextTick(() => {
      this.readEstimateCache()
    })

    // Update cache usage every minute
    clearInterval(this.updateTimer)
    this.updateTimer = setInterval(() => {
      this.readEstimateCache()
    }, 60000)
  },

  beforeDestroy () {
    clearTimeout(this.assetsTimer)
    clearInterval(this.updateTimer)

    EventBus.$off('password-passed', this.proceedAction)
    EventBus.$off('password-cancelled', this.pwdCancelled)
  },

  methods: {
    restart (evt, verified = false) {
      if (!verified && this.supportPwd) {
        this.checkForPwd('restart')
        return
      }
      AppControl.emit('system-key-command', {
        command: 'restart'
      })
    },
    reset (evt, verified = false) {
      if (!verified && this.supportPwd) {
        this.checkForPwd('reset')
        return
      }
      AppControl.emit('system-key-command', {
        command: 'reset'
      })
    },
    maximize (evt, verified = false) {
      if (!verified && this.supportPwd) {
        this.checkForPwd('maximize')
        return
      }
      AppControl.emit('system-key-command', {
        command: 'fullscreen'
      })
    },
    rotateScreen (anticlockwise) {
      this.$store.dispatch('rotate90Deg', anticlockwise)
    },

    goToNetworkSetup (evt, verified = false) {
      if (!verified && this.supportPwd) {
        this.checkForPwd('toSetup')
        return
      }
      ElectronAPI.goToNetworkSetup()
    },

    clearCache (evt, verified = false) {
      if (!verified && this.supportPwd) {
        this.checkForPwd('clearCache')
        return
      }

      Log.warn('player', 'Manually Clear All Caches', 'WAR_MANUALCLEARCACHE')

      // LRU Cache
      Caches.clearAll()
      ContentCache.clear()

      const query = { message: this.$t('titleBar.clearCacheMsg'), timer: 5 }

      // Environment (containing apps)
      Environment.clearCache(query)
      // Electron Vue app will do restart after clearCache on it's own
      if (Environment.isVueElectronPlayer()) { return }

      // Force restart device after manually clear all cache
      // - For non-Electron apps
      this.$router.push({
        name: 'restarting',
        query
      })
    },

    parsedBytes (bytes, hideUnit) {
      let result
      if (bytes >= 10e8) {
        result = (bytes / 10e8).toFixed(1)
        return hideUnit ? `${result}` : `${result}GB`
      }
      result = Math.trunc(bytes / 10e5)
      return hideUnit ? `${result}` : `${result}MB`
    },

    readEstimateCache () {
      if ('storage' in navigator && 'estimate' in navigator.storage) {
        navigator.storage.estimate().then(({
          usage,
          quota
        }) => {
          this.cacheUsed = this.parsedBytes(usage)
          this.cacheQuota = this.parsedBytes(quota)
        }).catch(error => {
          console.warn('Loading storage estimate failed:')
          console.log(error.stack)
        })
      } else {
        console.warn('navigator.storage.estimate API unavailable')
      }
    },

    // DEV-3238

    checkForPwd (action = '') {
      if (!action || !action.length) { return }

      if (!this.pendingForPwd) {
        this.pendingForPwd = true
        this.pendingAction = action
        ElectronAPI.checkAccessPwd()
      }
    },

    proceedAction () {
      if (!this.pendingForPwd || !this.pendingAction) {
        return
      }
      switch (this.pendingAction) {
        case 'clearCache':
          this.clearCache(null, true)
          break
        case 'toSetup':
          this.goToNetworkSetup(null, true)
          break
        case 'reset':
          this.reset(null, true)
          break
        case 'restart':
          this.restart(null, true)
          break
        case 'maximize':
          this.maximize(null, true)
          break
      }
      this.pendingForPwd = false
      this.pendingAction = ''
    },

    pwdCancelled () {
      this.pendingForPwd = false
      this.pendingAction = ''
    }
  }
}
</script>

<template lang="pug">
section.player-title-bar
  .left(v-if="device !== null && account !== null")
    span.name {{device.name}}
    span(v-if="device.name && account.name") @
    span.accountname {{account.name}}

  .right
    .cache-used(v-if="cacheQuota") Quota Used: {{cacheUsed}} / {{cacheQuota}}
    //- Buttons
    .func-button(v-if="hasNetworkSetup", :data-title="$t('titleBar.buttons.networkSetup')" @click="goToNetworkSetup")
      fa.fa-icon(icon="cog")
    .func-button(:data-title="$t('titleBar.buttons.clearCache')" @click="clearCache")
      fa.fa-icon(icon="minus-circle")
    .func-button(:data-title="$t('titleBar.buttons.resetDevice')" @click="reset")
      fa.fa-icon(icon="trash")
    .func-button(:data-title="$t('titleBar.buttons.maximize')" @click="maximize")
      fa.fa-icon(icon="expand-arrows-alt")
    .func-button(:data-title="$t('titleBar.buttons.rotateToLeft')" @click="rotateScreen(true)")
      fa.fa-icon(icon="undo")
    .func-button(:data-title="$t('titleBar.buttons.rotateToRight')" @click="rotateScreen(false)")
      fa.fa-icon(icon="undo" flip="horizontal")
    .func-button(:data-title="$t('titleBar.buttons.restartDevice')" @click="restart")
      fa.fa-icon(icon="power-off")

</template>

<style lang="stylus">
section.player-title-bar
  display: flex
  font-size: 18px
  padding: 7px 10px 0 10px
  justify-content: space-between
  align-items: center

  .left
    color: var(--gray-light)

  .right
    flex: 1 1 0.00001px
    color: var(--gray-light)
    display: flex
    flex-flow: row nowrap
    justify-content: flex-end
    align-items: center

    .cache-used
      font-size: 0.7em
      opacity: 0.6

    .func-button
      margin-left: 20px
      color: #fff
      cursor: pointer
      position: relative

      .fa-icon
        transition: opacity .3s

      &:after
        visibility: hidden
        opacity: 0
        content: attr(data-title)
        position: absolute
        font-size: 0.7em
        bottom: -120%
        left: 50%
        transform: translate3d(-50%, 0, 0)
        background: black
        color: white
        padding: 0.1em 0.5em
        border-radius: 4px
        white-space: nowrap
        transition: opacity 0.3s

      &:hover
        .fa-icon
          opacity: 0.7

        &:after
          visibility: visible
          opacity: 1

      &:last-child
        &:after
          // Align right
          transform: translate3d(-80%, 0, 0)
</style>
