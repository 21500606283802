<script>
import qs from 'qs'
import { mapGetters } from 'vuex'

import OneDrive from 'services/onedrive.js'
import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'

import MediaZone from 'components/items/MediaZone.vue'

const config = {
  // In second. Fallback interval
  MIN_INTERVAL: 1,

  // In second. For list cache max age
  MIN_REFRESH_INTERVAL: 60,

  // In ms. Force advance to next page when no valid (shared) media found in the target folder
  FORCE_TO_NEXT_PAGE_AFTER: 5000,

  // Set max allowed playable size [DEV-2841]
  MAX_ALLOWED_SIZE: 20 * (1 << 20)
}

export default {
  name: 'DriveFolder',
  components: {
    MediaZone
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      folderID: null,
      driveID: null,
      userID: null,
      refreshInterval: 60,

      loading: true,

      zoneFinishedWhilePaused: false,

      zoneOptions: {
        animated_zoom_effect: false,
        muted: false,
        keep_looping: false,
        tap_interaction: false,
        interval: 0,
        transition: '',
        image_fit: '',
        image_position: '',
        image_repeat: ''
      },
      zoneItem: {},
      urlList: [],

      advanceTimer: undefined,
      refetchTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'isPaused'
    ]),

    gridZoneName () {
      return (this.item && this.item.grid_area) || 'main'
    },

    isEmpty () {
      return !this.urlList || !this.urlList.length
    },

    hasError () {
      return Boolean(this.errorMessage && this.errorMessage.length)
    },

    driveType () {
      return this.item.type === 'googledrive_folder' ? 'GoogleDrive' : 'OneDrive'
    },

    localKey () {
      if (!this.folderID) { return false }
      if (this.driveType === 'GoogleDrive') {
        return `ttvGoogleDriveFolder_${this.folderID}`
      }
      return `ttvOneDriveFolder_${this.folderID}`
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        // Reset flag state on change
        this.zoneFinishedWhilePaused = false
        this.render()
      }
    },

    isPaused (isTrue) {
      // Resume playback
      if (!isTrue && this.zoneFinishedWhilePaused) {
        this.zoneFinishedWhilePaused = false
        this.zoneFinished()
      }
    }
  },

  mounted () {
    clearTimeout(this.advanceTimer)
    clearTimeout(this.refetchTimer)

    // Show stale data first to speed up zone display
    this.getStaleData(true)

    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.advanceTimer)
    clearTimeout(this.refetchTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.folderID = options.folderId || ''
      this.driveID = options.driveId || ''
      this.userID = options.userId || ''
      this.refreshInterval = Math.max(config.MIN_REFRESH_INTERVAL, +options.refreshInterval || 0)

      this.zoneOptions = {
        animated_zoom_effect: options.animated_zoom_effect === 'true',
        muted: options.muted === 'true',
        keep_looping: options.keep_looping === 'true',
        tap_interaction: options.tap_interaction === 'true',

        interval: Math.max(config.MIN_INTERVAL, +(options.interval || 0)),

        image_fit: options.image_fit || '',
        image_position: options.image_position || '',
        image_repeat: options.image_repeat || '',
        transition: options.transition || ''
      }

      this.fetchFolder()
    },

    fetchOneDriveFolder () {
      OneDrive.getFolderItems(this.folderID, this.userID, this.refreshInterval).then(folderItems => {
        const mediaList = folderItems.filter(fItem => {
          return !fItem.folder && fItem.file && (fItem.image || fItem.video) && (+fItem.size && +fItem.size <= config.MAX_ALLOWED_SIZE)
        })

        const urlList = []
        if (mediaList && mediaList.length) {
          mediaList.sort((a, b) => {
            // For items with the same name, compare updated time
            if ((a.name || '') === (b.name || '')) {
              if (a.lastModifiedDateTime < b.lastModifiedDateTime) { return -1 }
              if (a.lastModifiedDateTime > b.lastModifiedDateTime) { return 1 }
              return 0
            } else {
              return (a.name || '').localeCompare(b.name || '')
            }
          })

          mediaList.forEach(media => {
            const proxyUrl = OneDrive.fileProxyURL(media.id, this.userID)
            if (!proxyUrl) { return }
            if (media.image) {
              urlList.push(encodeURIComponent(`${proxyUrl}&onedrive=true&is_image=true`))
            } else if (media.video) {
              urlList.push(encodeURIComponent(`${proxyUrl}&onedrive=true&is_video=true`))
            }
          })
        }

        const zoneItem = JSON.parse(JSON.stringify(this.zoneOptions || {}))
        zoneItem.items = urlList

        this.zoneItem = {
          url: qs.stringify(zoneItem),
          grid_area: this.gridZoneName + ''
        }
        this.urlList = urlList
        this.errorMessage = ''
        this.loading = false

        if (this.localKey) {
          OfflineCaches.set(this.localKey, zoneItem)
        }

        if (!urlList.length) {
          Log.warn('media', `No valid images nor videos found in this OneDrive folder (${this.folderID})`, 'WAR_NOVALIDCONTENTONEDRI')
          this.forceAdvance()
        }
      }).catch(err => {
        const errorMessage = err.message || err.toString()
        Log.warn('media', `OneDrive Folder error: ${errorMessage}`, 'WAR_ONEDRIVEFOLDERERR', err)
        this.errorMessage = `OneDrive Folder Error - ${errorMessage}`
        this.forceAdvance()
        this.getStaleData()
      })
    },

    fetchFolder () {
      if (!this.folderID) { return }
      
      this.fetchOneDriveFolder()
    },

    async getStaleData (init) {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData) {
          // Skip force advance when stale data is found
          clearTimeout(this.advanceTimer)

          this.zoneItem = { url: qs.stringify(staleData) }
          this.urlList = staleData.items || []
          this.errorMessage = ''
          this.loading = false

          if (!this.urlList.length) {
            this.forceAdvance()
          }
          return
        }
      }
      if (!init) {
        this.loading = false
      }
    },

    forceAdvance () {
      clearTimeout(this.advanceTimer)
      this.advanceTimer = setTimeout(() => {
        clearTimeout(this.advanceTimer)
        this.finished(true)
      }, config.FORCE_TO_NEXT_PAGE_AFTER)
    },

    zoneFinished () {
      // Playlist is paused
      if (this.isPaused) {
        this.zoneFinishedWhilePaused = true
        return
      }

      this.finished()

      // Re-fetch folder contents when finish playing the current list
      clearTimeout(this.refetchTimer)
      this.refetchTimer = setTimeout(() => {
        clearTimeout(this.refetchTimer)
        this.fetchFolder()
      }, 1000)
    },

    finished (forceAdvance = false) {
      if (forceAdvance) {
        this.$emit('finished', true)
      } else if (!this.zoneOptions.keep_looping) {
        this.$emit('finished')
      }
    },

    loaded () {
      this.$emit('loaded')
    },

    sendDuration (duration) {
      this.$emit('duration', duration)
    }
  }
}
</script>

<template lang="pug">
section.drive-folder
  media-zone(v-if="!loading && !isEmpty"
             :item="zoneItem"
             :active="active"
             is-drive-folder
             :drive-type="item.type"
             @finished="zoneFinished"
             @loaded="loaded"
             @duration="sendDuration")

  //- No media found
  transition(name="fade" appear)
    .messages(v-if="!loading && isEmpty && hasError")
      p {{ errorMessage }}
    .messages(v-else-if="!loading && isEmpty")
      p No valid images nor videos found in this drive folder
</template>

<style lang="stylus">
section.drive-folder
  width: 100%
  height: 100%
  position: absolute

  .media-zone
    z-index: 5

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 4vmin
</style>
