<script>
import AnimateValue from './AnimateValue.vue'

import NumberHelper from 'services/number-helper.js'

export default {
  name: 'NumberValue',
  components: { AnimateValue },

  props: {
    numberData: {required: true, type: Object},
    unitFontScale: { type: Number, default: 1.0 },
    unitColor: {required: false, type: String},
    isStatic: {type: Boolean, default: false}
  },
  data () {
    return {
      currentValue: 0
    }
  },

  computed: {
    normalizedNumber () {
      const val = JSON.parse(JSON.stringify(this.numberData))
      val.value = +val.value
      return val
    },

    unitStyle () {
      const style = {
        fontSize: `${(+this.unitFontScale || 1.0) * 0.6}em`
      }
      if (this.unitColor) {
        style.color = this.unitColor
      } else {
        style.opacity = 0.4
      }
      return style
    },

    targetValue () {
      return this.normalizedNumber.value
    },

    displayValue () {
      let value
      if (this.isStatic) {
        value = this.targetValue
      } else {
        value = this.currentValue
      }

      const numberData = this.normalizedNumber
      if (NumberHelper.isNumber(value)) {
        const formatted = NumberHelper.formatter(value, numberData)
        // Separate abbrivation [APP-2954]
        if (formatted.length && formatted.match(/\D+$/)) {
          const match = formatted.match(/\D+$/)
          if (match && match.length === 1) {
            return `${formatted.substr(0, match.index)}<abbr class="abbrivate">${match[0]}</abbr>`
          }
        }
        return NumberHelper.formatter(value, numberData)
      } else {
        return ''
      }
    },

    formattedPrefix () {
      const valueType = this.normalizedNumber.type || this.normalizedNumber.value_type
      const formattedValueType = NumberHelper.valueType(valueType)
      return formattedValueType[0] || ''
    },

    formattedSuffix () {
      const valueType = this.normalizedNumber.type || this.normalizedNumber.value_type
      const formattedValueType = NumberHelper.valueType(valueType)
      return formattedValueType[1] || ''
    },

    isValidNumber () {
      return NumberHelper.isNumber(this.currentValue)
    }
  },

  watch: {
    targetValue (newValue, oldValue) {
      if (newValue > oldValue) {
        this.$emit('value-changed', 'up')
      } else {
        this.$emit('value-changed', 'down')
      }
      this.checkValue(newValue)
    },

    thresholdReached (isReached) {
      if (isReached) {
        this.$emit('threshold-reached')
      } else {
        this.$emit('threshold-reset')
      }
    }
  },

  mounted () {
    this.checkValue(this.targetValue)
  },

  methods: {
    updateCurrentValue (currentValue) {
      this.currentValue = currentValue
    },

    checkValue (newValue) {
      if (!this.alertType || this.alertType === 'none') { return }

      switch (this.alertType) {
        case 'changed':
          this.$emit('threshold-reached')
          break
        case 'equals':
          if (newValue === this.alertThreshold) {
            this.thresholdReached = true
          } else {
            this.thresholdReached = false
          }
          break
        case 'less_than':
          if (!NumberHelper.isNumber(this.alertThreshold)) { return }

          if (this.thresholdReached) {
            if (newValue >= this.alertThreshold) {
              this.thresholdReached = false
            }
          } else {
            if (newValue < this.alertThreshold) {
              this.thresholdReached = true
            }
          }
          break
        case 'greater_than':
          if (!NumberHelper.isNumber(this.alertThreshold)) { return }

          if (this.thresholdReached) {
            if (newValue <= this.alertThreshold) {
              this.thresholdReached = false
            }
          } else {
            if (newValue > this.alertThreshold) {
              this.thresholdReached = true
            }
          }
          break
      }
    }
  }
}
</script>

<template lang="pug">
.number-value(v-show="isValidNumber")
  span.prefix(v-text="formattedPrefix" :style="unitStyle")
  span.digit(v-html="displayValue")
  span.suffix(v-text="formattedSuffix" :style="unitStyle")
  animate-value(v-if="!isStatic"
                :target-value="targetValue"
                @update="updateCurrentValue")
</template>

<style lang="stylus">

.number-value
  .prefix,
  .suffix
    &:empty
      display: none

  .prefix
    padding-right: 0.05em

  .suffix
    padding-left: 0.05em

  .prefix,
  .digit,
  .suffix
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  .digit
    abbr
      font-size: $smallerFont
      opacity: 0.4
      text-transform: uppercase
</style>
