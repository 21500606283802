<script>
import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import Telemetry from 'services/telemetry.js'
import OfflineCaches from 'services/offline-caches'
import Log from 'services/log.js'
import Pages from 'services/pages'
import Webapp from 'services/webapp'

export default {
  name: 'NavigationButton',

  props: {
    item: {
      type: Object,
      default: () => { return {} }
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    timeoutAfter: {
      type: Number,
      default: -1
    },
    rounded: {
      type: Boolean,
      default: false
    },
    buttonSize: {
      type: String,
      default: ''
    },
    fixedBtnWidth: {
      type: Number,
      default: 0
    },
    overlayMargin: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters([
      'account'
    ]),

    isValidItem () {
      if (!this.targetType) { return false }
      if (['media', 'app', 'folder', 'webapp'].includes(this.item.targetType)) {
        return Boolean(this.item.targetId && this.item.targetId.length)
      }
      return false
    },

    useImage () {
      return Boolean(this.item && this.item.useImage === 'true')
    },

    bgStyle () {
      if (this.useImage) { return }
      if (!this.item || !this.item.bgColor) { return }
      return {
        backgroundColor: this.item.bgColor
      }
    },

    textStyle () {
      if (!this.item || !this.item.textColor) { return }
      return {
        color: this.item.textColor
      }
    },

    targetType () {
      return this.item && this.item.targetType
    },

    btnSizeClass () {
      if (!this.buttonSize || !this.buttonSize.length) { return }
      return `btn-size-${this.buttonSize}`
    },

    btnWidthStyle () {
      if (!this.useImage && this.fixedBtnWidth && this.fixedBtnWidth > 0) {
        return {
          // Use `ch` (character) unit. Which would be better than `em` in most cases
          // > https://caniuse.com/#feat=ch-unit
          width: `${this.fixedBtnWidth}ch`
        }
      }
      return null
    },

    localKey () {
      if (!this.item || !this.targetType || !this.item.targetId) { return }
      return `button_${this.targetType}_${this.item.targetId}`
    }
  },

  methods: {
    getTargetContent () {
      if (!this.isValidItem) { return }

      this.$store.dispatch('startLoadingContent')
      if (this.targetType === 'app') {
        const path = `/apps/${this.item.targetId}`
        Telemetry.send({
          method: 'GET',
          params: {
            service: 'apps',
            path
          }
        }).then(response => {
          const data = response.body
          this.showTargetItem(data)
          this.storeData(data)
        }).catch(err => {
          this.getStaleData(err)
        })
      } else if (this.targetType === 'media') {
        const path = `/contents/${this.item.targetId}`
        Telemetry.send({
          method: 'GET',
          params: {
            service: 'content',
            path
          }
        }).then(response => {
          const data = response.body
          this.showTargetItem(data)
          this.storeData(data)
        }).catch(err => {
          this.getStaleData(err)
        })
      } else if (this.targetType === 'folder') {
        this.$store.dispatch('getMediaFolderTreeById', this.item.targetId).then(folder => {
          if (folder && folder.id) {
            const data = {
              id: this.item.targetId,
              type: 'folder',
              label: folder.name
            }
            this.showTargetItem(data)
            this.storeData(data)
          }
        }).catch(err => {
          this.getStaleData(err)
        })
      } else if (this.targetType === 'webapp') {
        Webapp.get(this.item.targetId).then(webapp => {
          this.showTargetItem(webapp)
          this.storeData(webapp)
        }).catch(err => {
          this.getStaleData(err)
        })
      }
    },

    showTargetItem (data) {
      if (!data || !this.targetType || !data.id) { return }

      let needsTimeout = false
      let autoTimeout = 0
      if (this.autoClose && this.timeoutAfter > 0) {
        needsTimeout = true
        autoTimeout = +this.timeoutAfter
      }

      let overlayContent
      if (this.targetType === 'app') {
        overlayContent = {
          id: data.id,
          targetType: this.targetType + '',
          overlayMargin: this.overlayMargin,
          type: data.type,
          url: data.arguments,
          label: data.label
        }
      } else if (this.targetType === 'media') {
        overlayContent = {
          id: data.id,
          targetType: this.targetType + '',
          overlayMargin: this.overlayMargin,
          type: data.content_type,
          label: data.name,
          // Full content data from the API (id, public_url(s), content_type,...)
          data
        }
      } else if (this.targetType === 'folder') {
        const queryOpts = {
          folder_id: data.id,
          sub_folders: this.item.subFolders,
          match_device_tag: this.item.matchDeviceTag
        }
        overlayContent = {
          id: data.id,
          targetType: this.targetType + '',
          overlayMargin: this.overlayMargin,
          type: data.type,
          label: data.label,
          // For API query
          url: qs.stringify(queryOpts),
          // Other folder options
          folderOptions: {
            thumbnailSize: this.item.thumbnailSize || 1.0,
            folderBg: this.item.folderBg || '',
            showSubfolders: this.item.subFolders === 'true'
          }
        }
      } else if (this.targetType === 'webapp') {
        overlayContent = {
          id: data.id,
          targetType: this.targetType + '',
          overlayMargin: this.overlayMargin,
          type: data.source || 'simple',
          label: data.name,
          // Full webapp data from the API (id, source, filesync,...)
          data
        }
      }

      if (!overlayContent) { return }

      if (needsTimeout) {
        overlayContent.timeout = autoTimeout
      }

      Pages.sendKioskReport('kiosk_user_opened', { name: overlayContent.label })

      this.$store.dispatch('showContentWithOverlay', overlayContent)
    },

    storeData (targetData) {
      if (this.localKey) {
        OfflineCaches.set(this.localKey, targetData)
      }
    },

    async getStaleData (err) {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData) {
          this.showTargetItem(staleData)
          return
        }
      }
      Log.warn('app', 'Navigation app: Unable to get target content', 'WAR_NAVGETTARGETERR', err)
      this.$store.dispatch('showErrorMessage', err)
    }
  }
}
</script>

<template lang="pug">
button.ttv-interactive-button(:class="[btnSizeClass, {'rounded': !useImage && rounded, 'fixed-btn-width': fixedBtnWidth && fixedBtnWidth > 0, 'use-image': useImage}]"
      :style="[textStyle, bgStyle, btnWidthStyle]"
      @mousedown.stop="getTargetContent"
      @touchstart.stop="")
  template(v-if="useImage")
    img(v-if="item.buttonImage && item.buttonImage.length" :src="item.buttonImage")
  template(v-else) {{ item.text }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

button.ttv-interactive-button
  display: inline-block
  font-size: inherit
  font-family: inherit
  position: relative
  padding: 0.5em 1em 0.45em 1em
  border: 0
  outline: 0
  line-height: 120%
  cursor: pointer

  // Clip button text when there's not enough horizontal space
  max-width: 100% !important
  ellipsis()

  // Preserve pointer event on <button>
  pointer-events: initial

  // Remove the highlight blue mask on tap
  -webkit-tap-highlight-color: transparent

  &.rounded
    border-radius: 10em

  &.fixed-btn-width
    box-sizing: content-box
    max-width: calc(100% - 2em) !important

  &.use-image
    padding: 0
    z-index: 0
    background: transparent
    img
      display: block
      border: 0
      outline: 0
      position: relative
      z-index: 1

  &:after
    content: ''
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    background: -black(0.2)
    opacity: 0
    transition: opacity .3s

  &:active
    &:after
      opacity: 1

  // ==============
  // Button Size
  // ==============

  &.btn-size-medium
    font-size: 1.5em

  &.btn-size-large
    font-size: 2em
</style>
