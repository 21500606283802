<script>
import VideoItem from 'components/items/VideoItem.vue'
import ImageItem from 'components/items/ImageItem.vue'

import Utils from 'services/utils.js'

const config = {
  DEFAULT_COLOR: '#323546'
}

export default {
  name: 'AppBackground',

  components: { VideoItem, ImageItem },

  props: {
    type: {
      type: String,
      default: 'color'
    },

    bgMedia: {
      type: String,
      default: ''
    },

    stockMedia: {
      type: String,
      default: ''
    },

    unsplashImage: {
      type: String,
      default: ''
    },

    bgColor: {
      type: String,
      default: ''
    },

    gradientOverlay: {
      type: Boolean,
      default: false
    },

    zoomEffect: {
      type: Boolean,
      default: false
    },

    duration: {
      type: Number,
      default: 0
    },

    darkOverlay: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mediaFile () {
      if (this.type === 'color') { return }
      if (this.type === 'custom') {
        return this.bgMedia
      }
      if (this.type === 'unsplash') {
        return this.unsplashImage
      }
      return this.stockMedia
    },

    hasMedia () {
      if (this.type === 'color') { return false }
      return Boolean(this.mediaFile && this.mediaFile.length)
    },

    hasVideo () {
      if (!this.hasMedia) { return false }
      return Utils.isVideoURL(this.mediaFile)
    },

    hasImage () {
      if (!this.hasMedia) { return false }
      if (this.type === 'unsplash') {
        return true
      }
      return Utils.isImageURL(this.mediaFile)
    },

    imageOptions () {
      return {
        url: this.mediaFile,
        animated_zoom_effect: this.zoomEffect,
        duration: this.duration
      }
    },

    isDefaultColor () {
      return this.bgColor === config.DEFAULT_COLOR || !this.bgColor
    },

    backgroundColor () {
      if (this.type !== 'color') { return }
      return {
        backgroundColor: this.bgColor || config.DEFAULT_COLOR
      }
    }
  }
}
</script>

<template lang="pug">
.app-generic-background
  .bgcolor-layer(v-if="type === 'color'", :style="backgroundColor", :class="{'show-overlay': gradientOverlay, 'is-default': isDefaultColor}")
  .bgvideo-layer(v-else-if="hasVideo", :class="{'dark-overlayed': darkOverlay}")
    video-item(:item="{url: mediaFile, muted: true }" mode="fill")
  .bgimage-layer(v-else-if="hasImage", :class="{'dark-overlayed': darkOverlay}")
    image-item(:item="imageOptions")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

.app-generic-background
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .bgcolor-layer,
  .bgimage-layer,
  .bgvideo-layer
    position: absolute
    z-index: 0
    top: 0
    left: 0
    right: 0
    bottom: 0

  .bgcolor-layer
    &.show-overlay
      &:before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 1
        background-image: linear-gradient(180deg, -white(0.1) 0%, -black(0.1) 100%)
      &.is-default
        &:before
          background-image: linear-gradient(180deg, #3B3E53 0%, #292B39 100%)

  .bgimage-layer
    background-position: 50% 50%
    background-size: cover
    background-repeat: no-repeat

  .bgvideo-layer
    .t-video
      position: relative
      top: 0
      height: 100%
      width: 100%
      z-index: 1

  .bgimage-layer,
  .bgvideo-layer
    &.dark-overlayed
      &:before
        content: ''
        position: absolute
        z-index: 2
        top: 0
        bottom: 0
        right: 0
        left: 0
        background: -black(0.6)
</style>
