<script>
import { mapGetters } from 'vuex'

import TVideo from 'components/common/TVideo.vue'
import BlobVideo from 'components/common/BlobVideo.vue'

import Log from 'services/log.js'
import Environment from 'services/environment'
import Utils from 'services/utils.js'
import { EventBus } from 'services/eventbus.js'

const SHOW_PLAY_BUTTON_DELAY = 20000 // In ms. Show play button if not playing after 20s.
const NO_TIME_UPDATE_MAX = 30000 // In ms. If video time not updated for 30s, it will go to next page.

// In ms. Force restart when video not playing for a while
// - Mainly for preview mode, or when there's only one valid page in the Playlist
const FORCE_RESTART_AFTER = 1000

// In ms. Restart (almost) immediately
// - Used for auto-loop, mainly for app or page background videos
const IMMEDIATE_RESTART_AFTER = 50

// In ms. Delay video replay to prevent flickering of the first frame when switching pages
const DELAY_RESTART_AFTER = 5000

export default {
  name: 'VideoItem',

  components: { TVideo, BlobVideo },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    // For embedded mode like being embedding in Twitter app
    minDuration: {
      type: Number,
      default: 0
    },
    // fit (default) | fill
    mode: {
      type: String,
      default: 'fit'
    },
    // Show video controls
    showControls: {
      type: Boolean,
      default: false
    },
    // Configure optional Cache stack
    // - "ads": Programmatic Ad (E.g., Place Exchanged)
    // - "playlistAd": Playlist Advertising Sources
    stack: {
      type: String,
      default: ''
    },
    // Indicator of embedding outside the Playlist Page,
    // E.g. viewing from Interactive Menu
    standalone: {
      type: Boolean,
      default: false
    },
    // Indicator of embedding in MediaZone (MediaFolder and DriveFolder included)
    isMediaZone: {
      type: Boolean,
      default: false
    },
    // Indicator of embedding in DriveFolder (GoogleDrive/OneDrive)
    isDriveFolder: {
      type: Boolean,
      default: false
    },
    // Skip auto-replay video when video ends
    // Default to `false` to enable auto playback loop for regular page or app background videos
    skipAutoReplay: {
      type: Boolean,
      default: false
    },
    // Delay video replay for a longer duration
    delayVideoReplay: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isPaused: false,
      isManuallyPaused: false,
      canPlay: false,

      endTimer: undefined,
      recheckTimer: undefined,
      delayPauseTimer: undefined,
      minDurationTimer: undefined,

      restartTimer: undefined,
      forceClearSrc: false,

      currentTime: 0,
      totalTime: -1,
      totalTimeReliable: false,
      lastTimeUpdate: 0,
      timeUpdateInterval: undefined,
      videoStallWarningSent: false,

      loading: true,
      loadedTimer: undefined,

      destroyed: false
    }
  },

  computed: {
    ...mapGetters([
      'deviceVideoSync',
      'showItemsList'
    ]),

    src () {
      const url = this.item.url || this.item.location || ''
      if (Utils.isProxiedUrl(url)) {
        return url
      }
      return encodeURI(decodeURI(url))
    },

    subtitles () {
      return this.item.subtitles || ''
    },

    videoSrc () {
      // NOTE: to prevent mutating $ref component's props like `this.$refs.video.href = $NEW_VALUE`
      return this.forceClearSrc ? '' : this.src
    },

    // The target URL of proxied url
    rawUrl () {
      if (Utils.isProxiedUrl(this.src)) {
        if (this.item.rawURL) {
          return this.item.rawURL
        } else {
          return Utils.getProxyTargetURL(this.src)
        }
      }
      return this.src
    },

    srcCleanUrl () {
      // URL without any `?token` etc info
      if (Utils.isDriveFolderUrl(this.src)) {
        return Utils.getUrlPath(this.src)
      }
      return this.src
    },

    srcFileName () {
      return Utils.trimedFilePath(this.rawUrl)
    },

    useBlobVideo () {
      return this.stack === 'ads' || this.stack === 'playlistAd'
    },

    currentPercent: {
      get () {
        if (this.totalTime <= 0 || this.currentTime <= 0) { return 0 }
        return ~~((this.currentTime / this.totalTime) * 1000) / 10
      },
      set (newPercent) {
        let newTime = 0
        if (newPercent > 0 && this.totalTime > 0) {
          newTime = this.totalTime * (newPercent / 100)
        }
        this.currentTime = newTime
        if (this.$refs && this.$refs.video && typeof this.$refs.video.setCurrentTime === 'function') {
          this.$refs.video.setCurrentTime(newTime)
        }
      }
    },

    displayTotalTime () {
      if (!this.showControls) { return }
      if (this.totalTime < 0) { return '--:--' }
      return Utils.formatTimeDisplay(this.totalTime)
    },

    displayCurrentTime () {
      if (!this.showControls) { return }
      if (this.currentTime <= 0) { return '00:00' }
      return Utils.formatTimeDisplay(this.currentTime)
    },

    // To mimic the video playback progress track
    rangeProgressBg () {
      if (!this.showControls) { return }
      return {
        background: `linear-gradient(to right, #fff ${this.currentPercent}%, rgba(255, 255, 255, 0.4) ${this.currentPercent}%)`
      }
    },

    pageDuration () {
      return this.item.duration || 0
    },

    pageMuted () {
      return Boolean(Environment.muteVideos || this.item.muted || false)
    },

    allowNonchunkedPlayback () {
      // Stop streaming for non-chunkable TTV Upload files [187328082]
      // Only allow bulk playback for GoogleDrive or OneDrive files
      return this.isDriveFolder
    },

    enableInteraction () {
      return Boolean(this.item && this.item.tapInteraction)
    }
  },

  watch: {
    rawUrl (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        // To trigger new "loaded" event for different video URL
        this.loading = true
        // Reset the warning flag
        this.videoStallWarningSent = false
      }
    }
  },

  mounted () {
    clearTimeout(this.endTimer)
    clearTimeout(this.delayPauseTimer)
    clearTimeout(this.minDurationTimer)
    clearTimeout(this.loadedTimer)
    clearTimeout(this.restartTimer)
    clearTimeout(this.recheckTimer)

    EventBus.$on('interact-menu-pause', this.menuAction)

    if (!this.$refs || !this.$refs.video) {
      Log.debug('media', 'Skipping Video Setup', 'DBG_SKIPVIDEOSETUP', null, true)
      return
    }

    this.recheckTimer = setTimeout(() => {
      clearTimeout(this.recheckTimer)
      const video = this.$refs && this.$refs.video
      if (video && video.isPaused()) {
        this.isPaused = true
      }
    }, SHOW_PLAY_BUTTON_DELAY)

    clearInterval(this.timeUpdateInterval)
    this.timeUpdateInterval = setInterval(() => {
      if (this.destroyed) {
        clearInterval(this.timeUpdateInterval)
        return
      }

      const video = this.$refs && this.$refs.video
      // Possibly fix for the "NS_ERROR_FAILURE" error on Firefox
      // > https://app.bugsnag.com/telemetry/player/errors/5d40baca94127d0019ce11df
      // > https://stackoverflow.com/questions/15146819/ns-error-failure-mozilla-browser
      const currentTime = +(video.currentTime()) || 0
      if (currentTime !== this.currentTime) {
        this.currentTime = currentTime
        this.lastTimeUpdate = Date.now()
        this.videoStallWarningSent = false
      }
      if (this.isManuallyPaused) {
        Log.warn('media', `Video is manually paused (${this.srcFileName})`, 'WAR_MANUALLYPAUSED', {url: this.src})
      }
      if (!this.isManuallyPaused && (this.lastTimeUpdate > 0) && (this.lastTimeUpdate < (Date.now() - NO_TIME_UPDATE_MAX))) {
        if (!this.videoStallWarningSent) {
          Log.warn('media', `Video time not updated after ${NO_TIME_UPDATE_MAX}ms, going to the next page (${this.srcFileName})`, 'WAR_VIDEOTIMENOTUPDATED', {url: this.src})
          this.endedHandler(true)
          this.videoStallWarningSent = true
        } else {
          Log.debug('media', `Video time not updated after ${NO_TIME_UPDATE_MAX}ms, going to the next page (${this.srcFileName})`, 'DBG_VIDEOTIMENOTUPDATED', {url: this.src})
        }
      }
    }, 5000)
  },

  beforeDestroy () {
    this.destroyed = true

    clearInterval(this.timeUpdateInterval)
    clearTimeout(this.endTimer)
    clearTimeout(this.recheckTimer)
    clearTimeout(this.delayPauseTimer)
    clearTimeout(this.minDurationTimer)
    clearTimeout(this.loadedTimer)
    clearTimeout(this.restartTimer)

    if (this.isMediaZone) {
      // Fallback record for manually navigation
      this.$emit('play-ended')
    }

    EventBus.$off('interact-menu-pause', this.menuAction)
  },

  methods: {
    playHandler () {
      // When src is cleared before destroy
      if (!this.src || !this.src.length) {
        return
      }

      clearTimeout(this.delayPauseTimer)
      clearTimeout(this.restartTimer)

      this.loaded()

      // In case the play button doesn't go away when the video first loaded > 20s
      if (this.isPaused) {
        this.isPaused = false
      }

      if (this.standalone) {
        this.$emit('is-paused', false)
      }

      this.setEndTimer(this.pageDuration || 0)
      this.setMinDurationTimer()
    },

    setEndTimer (duration) {
      clearTimeout(this.endTimer)
      this.endTimer = undefined
      if (duration > 0) {
        Log.debug('media', `Playing video ${this.srcFileName} for ${duration} seconds`, 'DBG_PLAYINGVIDEO', {url: this.srcCleanUrl})
        this.endTimer = setTimeout(() => {
          clearTimeout(this.endTimer)
          this.endTimer = undefined
          Log.debug('media', `Video playing has reached ${duration} seconds, going to the next page`, 'DBG_DURATIONEXCEED', {url: this.srcCleanUrl})
          this.$emit('play-ended')
          this.$emit('finished')
        }, duration * 1000 - 1000) // animation is 1 seconds
      } else {
        Log.debug('media', `Playing video ${this.srcFileName} for unknown duration`, 'DBG_UNKNOWNDURATION', {url: this.srcCleanUrl})
      }
    },

    videoEnded () {
      this.endedHandler()
    },

    endedHandler (forceReloadVideo = false) {
      clearTimeout(this.restartTimer)

      // When src is cleared before destroy
      if (!this.src || !this.src.length) {
        Log.debug('media', `endedHandler() called but it has no src, forceReloadVideo=${forceReloadVideo}`, 'DBG_NOSRCRELOAD')
        return
      }

      if (!this.endTimer && !this.minDurationTimer) {
        Log.debug('media', `Video playing has ended, forceReloadVideo=${forceReloadVideo}`, 'DBG_VIDEOPLAYENDED', {url: this.src})
        this.$emit('play-ended')
        this.$emit('finished')
      }

      if (!forceReloadVideo && this.skipAutoReplay) {
        Log.debug('media', 'Video does not need auto-replay, skip reloading', 'DBG_SKIPAUTOREPLAY', {url: this.src})
        return
      }

      // Component is destroying, skip reload
      if (!this.$refs?.video) { return }

      if (forceReloadVideo) {
        // [Vue Warn] for mutating `this.$refs.video.href = $NEW_VALUE`:
        // Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders.
        // Instead, use a data or computed property based on the prop's value. Prop being mutated: "href"
        this.forceClearSrc = true
      }

      const delay = forceReloadVideo
        ? FORCE_RESTART_AFTER
        : this.delayVideoReplay
          ? DELAY_RESTART_AFTER
          : IMMEDIATE_RESTART_AFTER

      // [DEV-2630] Use setTimeout instead of $nextTick
      // to prevent seeing a flash of the first frame before next video successfully loaded
      clearTimeout(this.restartTimer)
      this.restartTimer = setTimeout(() => {
        clearTimeout(this.restartTimer)
        if (this.forceClearSrc) {
          this.forceClearSrc = false
        }
        this.$nextTick(() => {
          this.restartPlayback()
        })
      }, delay)
    },

    restartPlayback () {
      // Reset status so that the "loaded()" can be triggered again and bubble to parent component later
      this.loading = true
      this.videoStallWarningSent = false
      if (this.useBlobVideo) {
        this.playPromiseHandler()
      } else {
        this.$refs?.video?.restartPlayback?.()
      }
    },

    playPromiseHandler () {
      // When src is cleared before destroy
      if (!this.src || !this.src.length) {
        Log.debug('media', 'playPromiseHandler() called but it has no src', 'DBG_NOSRCPLAY', null, true)
        return
      }

      const playPromise = this.$refs.video && this.$refs.video.play()

      if (
        playPromise &&
        typeof playPromise.then === 'function' &&
        typeof playPromise.catch === 'function'
      ) {
        Log.debug('media', `Calling video.play() for ${this.srcFileName}`, 'DBG_CALLVIDEOPLAY', {url: this.srcCleanUrl})

        playPromise
          .then(() => {
            // Automatic playback started. Do nothing here
            this.isPaused = false
            this.sendDuration()
            this.loaded()
          })
          .catch(error => {
            // AbortError: "The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22"
            // Happen during page switching. It's safe to ignore.
            if (error.name !== 'AbortError') {
              // Show info for non-AbortError only
              Log.info('media', `Video playPromise error "${error.message || error.toString() || ''}"`, 'INF_VIDEOPLAYERR', {url: this.srcCleanUrl, error})
              // Don't show play button if the video is aborted
              // To prevent flickering play button when switching or preparing the next video
              this.isPaused = true
            }
            this.loaded()
          })
      }
    },

    errorHandler (e) {
      if (this.src && this.src.length > 0) {
        if (e && e.target && e.target.error) {
          Log.debug('media', `Video Error Code: ${e.target.error.code}; Error Message: ${e.target.error.message || e.target.error.toString()}; Error: ${e.toString()}`, 'DBG_VIDEOERR', e)

          const errMsg = e.target.error.message || e.target.error.toString()

          // Media Error Code (1 - 4)
          // https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code
          switch (e.target.error.code) {
            case e.target.error.MEDIA_ERR_ABORTED:
              Log.warn('media', `Video Aborted (${this.srcFileName})`, 'WAR_VIDEOABORTED', {url: this.src, error: e})
              break
            case e.target.error.MEDIA_ERR_NETWORK:
              Log.error('media', `Video network error (${this.srcFileName})`, 'ERR_VIDEONETWORK', {url: this.srcCleanUrl, fullUrl: this.src, error: e}, true)
              break
            case e.target.error.MEDIA_ERR_DECODE:
              Log.debug('media', `Video decoding error (${this.srcFileName})`, 'DBG_VIDEODECODEERR', {url: this.srcCleanUrl, fullUrl: this.src, error: e})
              // Note @ Aug 26, 2019
              // Try to identfy different decode issues
              // > https://app.bugsnag.com/telemetry/player/errors/5d5e753541f7f00018f81181
              // > https://app.bugsnag.com/telemetry/player/errors/5d40baca94127d0019ce11df
              // > https://app.bugsnag.com/telemetry/player/errors/5e0137421882a100195b02c6
              // > https://app.bugsnag.com/telemetry/player/errors/5d40baca94127d0019ce11df
              //
              // Found the following errors so far:
              // - 'AUDIO_RENDERER_ERROR'
              // - 'NS_ERROR_OUT_OF_MEMORY'
              // - 'NS_ERROR_FAILURE'
              // - 'PIPELINE_ERROR_DECODE'
              // - 'NS_ERROR_DOM_MEDIA_FATAL_ERR'
              if (errMsg.indexOf('AUDIO_RENDERER_ERROR') >= 0) {
                Log.warn('media', `Video audio render error (${this.srcFileName})`, 'WAR_VIDEOAUDIORENDERERR', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
              } else if (errMsg.indexOf('NS_ERROR_OUT_OF_MEMORY') >= 0) {
                Log.error('media', `Cannot play video because device is out of memory (${this.srcFileName})`, 'ERR_DEVICEOUTOFMEMORY2', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                }, true)
                // Content encode + platform specific error. Usually seen on ChromeOS or Android.
                // Need to wait fixes from Chromium/Android. References:
                // - https://github.com/videojs/video.js/issues/6093
                // - https://github.com/google/shaka-player/issues/1394
              } else if (errMsg.indexOf('PIPELINE_ERROR_DECODE') >= 0) {
                Log.warn('media', `Cannot play video due to PIPELINE_ERROR_DECODE (${this.srcFileName})`, 'WAR_PIPELINEERRDECODE', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
                // Firefox specific error
                // Turns out they were caused by low device storage - "QuotaExceededError"
                // > https://app.bugsnag.com/telemetry/player/errors/600aa1e18622d900184a44dc
              } else if (errMsg.indexOf('NS_ERROR_FAILURE') >= 0) {
                Log.warn('media', `Video NS_ERROR_FAILURE error (${this.srcFileName})`, 'WAR_VIDEONSERRORFAIL', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
                // Firefox specific error
              } else if (errMsg.indexOf('NS_ERROR_DOM_MEDIA_FATAL_ERR') >= 0) {
                Log.warn('media', `Video NS_ERROR_DOM_MEDIA_FATAL_ERR error (${this.srcFileName})`, 'WAR_NSERRDOMMEDIAFATAL', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
              } else {
                Log.warn('media', `Video decoding error (${this.srcFileName})`, 'WAR_VIDEODECODEERR', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
              }
              break
            case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              if (errMsg.indexOf('MEDIA_ELEMENT_ERROR') >= 0) {
                Log.warn('media', `Video element error "${errMsg}" (${this.srcFileName})`, 'WAR_VIDEOELEMERR', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
              } else {
                Log.warn('media', `Video source not supported (${this.srcFileName})`, 'WAR_VIDEOSRCNOTSUPPORTED', {
                  url: this.srcCleanUrl,
                  fullUrl: this.src,
                  error: e.target.error
                })
              }
              break
            default:
              Log.error('media', `Video unknown error: ${e.target.error.message || e.target.error.toString()}`, 'ERR_VIDEOUNKNOWN', {
                url: this.srcCleanUrl,
                fullUrl: this.src,
                error: e.target.error
              })
              break
          }
        }
        this.$emit('error', e)
      }
    },

    pauseHandler () {
      if (this.standalone) {
        this.$emit('is-paused', true)
      }

      // Fallback for browser forced pausing due to the Chrome Autoplay policy etc
      // Log.info('media', `Video (${this.srcFileName}) playback is paused by browser`, {url: this.src, event: evt})
      clearTimeout(this.delayPauseTimer)
      this.delayPauseTimer = setTimeout(() => {
        clearTimeout(this.delayPauseTimer)
        if (this.$refs.video && this.$refs.video.isPaused()) {
          this.isPaused = true
        }
      }, SHOW_PLAY_BUTTON_DELAY)
    },

    canplayHandler () {
      if (!this.canPlay) {
        this.canPlay = true
        // If the video has been cached by the browser, loading video never hit ServiceWorker or ContentCache,
        // therefore we need to manually inform our cache detection system.
        this.$store.commit('addCachedContentURL', {url: this.src, cacheName: 'videos'})
      }

      if (!this.isManuallyPaused && this.$refs.video && this.$refs.video.isPaused()) {
        this.playPromiseHandler()
      }
    },

    timeUpdateHandler () {
      if (this.$refs && this.$refs.video) {
        this.currentTime = this.$refs.video.currentTime() || 0
      }
    },

    manuallyPlay () {
      if (this.canPlay) {
        this.isManuallyPaused = false
        this.playPromiseHandler()
      } else {
        Log.warn('media', 'manuallyPlay() is not executed becasue there\'s not enough video data', 'WAR_NOTENOUGHVIDEODATA', null, true)
      }
    },

    manuallyPause () {
      // When src is cleared before destroy
      if (!this.src || !this.src.length) {
        Log.debug('media', 'manuallyPause() called but it has no src', 'DBG_MANUALLYPAUSE', null, true)
        return
      }

      if (
        this.$refs.video &&
        this.$refs.video.pause &&
        typeof this.$refs.video.pause === 'function'
      ) {
        Log.debug('media', `Calling video.pause() for ${this.srcFileName}`, 'DBG_VIDEOPAUSE', {url: this.srcCleanUrl})
        this.$refs.video.pause()
        this.isPaused = true
        this.isManuallyPaused = true
        clearTimeout(this.endTimer)
      }
    },

    setMinDurationTimer () {
      if (!this.minDuration || this.minDuration <= 0) {
        clearTimeout(this.minDurationTimer)
        this.minDurationTimer = undefined
        return
      }

      clearTimeout(this.minDurationTimer)
      this.minDurationTimer = setTimeout(() => {
        // Min duration reached
        clearTimeout(this.minDurationTimer)
        this.minDurationTimer = undefined
      }, this.minDuration * 1000 - 1000) // animation is 1 seconds
    },

    sendDuration (payload) {
      // TVideo is possible to load only partial of the video data.
      // Hence, the <video>.duration no longer represent the actual video duration.
      // Need to listen update from the "duration" event from the TVideo component
      const duration = this.useBlobVideo
        ? this.$refs?.video?.duration?.()
        : payload || this.totalTime

      if (isFinite(duration) && !isNaN(duration) && duration > 0) {
        this.totalTime = duration
        this.$emit('duration', duration)
      }
    },

    handleTotalTimeDisplay (isReliable = false) {
      this.totalTimeReliable = isReliable
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        clearTimeout(this.loadedTimer)
        this.loadedTimer = setTimeout(() => {
          clearTimeout(this.loadedTimer)
          this.$emit('loaded')
        }, 2000)
      }
    },

    // User tap/click action
    manualAction () {
      if (!this.enableInteraction) { return }
      if (!this.isPaused) {
        this.manuallyPause()
      } else {
        this.manuallyPlay()
      }
    },

    // Interactive Menu related [DEV-3409]
    // When viewing interactive MediaFolder grid list
    // Pause the current playback video, if any
    menuAction (toPause = false) {
      if (!this.showItemsList || this.standalone) { return }
      if (toPause && !this.isPaused) {
        this.manuallyPause()
      } else if (!toPause && this.isPaused) {
        this.manuallyPlay()
      }
    }
  }
}
</script>

<template lang="pug">
section.video-page(@click.stop="manualAction")
  transition(name="fade", :duration="{enter: 500, leave: 0}" appear)
    .play-button(v-if="isPaused")
      fa.fa-icon(icon="play-circle" @click.stop="manuallyPlay")

  //- Video Controls
  .controllers-wrapper(v-if="showControls", :class="{show: isPaused}")
    .playback-btn
      fa.fa-icon(:icon="isPaused ? 'play' : 'pause'" fixed-width @click.stop="manualAction")
    .progress-track
      input.video-progress(ref="progressbar" type="range"
            min="0" max="100" step="0.00001"
            :style="rangeProgressBg"
            :disabled="!totalTimeReliable || totalTime <= 0"
            v-model="currentPercent"
            @click.stop="")
    .video-time {{ displayCurrentTime }}
      template(v-if="totalTimeReliable && totalTime > 0") &nbsp;/&nbsp;{{ displayTotalTime }}

  //- For Programmic Ads
  blob-video(v-if="useBlobVideo"
             ref="video"
             :href="videoSrc"
             :muted="pageMuted"
             :mode="mode"
             :stack="stack"
             :cache-key="item.cacheKey"
             @play="playHandler"
             @error="errorHandler"
             @pause="pauseHandler"
             @canplay="canplayHandler"
             @ended="videoEnded"
             @durationchange="sendDuration"
             autoplay)

  //- For the rest
  t-video(v-else
          ref="video"
          :href="videoSrc"
          :muted="pageMuted"
          :mode="mode"
          :subtitles="subtitles"
          :video-sync="deviceVideoSync ? deviceVideoSync : {}"
          :allow-nonchunked-playback="allowNonchunkedPlayback"
          :is-manually-paused="isManuallyPaused"
          :check-total-time-reliability="showControls"
          @play="playHandler"
          @error="errorHandler"
          @pause="pauseHandler"
          @canplay="canplayHandler"
          @ended="videoEnded"
          @duration="sendDuration"
          @timeupdate="timeUpdateHandler"
          @total-time-reliable="handleTotalTimeDisplay"
          autoplay)
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section.video-page
  width: 100%
  height: 100%
  position: relative

  .play-button
    color: #fff
    font-size: 8em
    position: absolute
    z-index: 1000
    top: 50%
    left: 50%
    transform-origin: 0 0
    transform: translate(-50%, -50%)
    cursor: pointer
    transition: opacity 0.3s
    animation-duration: 500ms
    -webkit-tap-highlight-color: transparent

    appIconShadow()

    &:hover,
    &:focus
      opacity: 0.7

    &:active
      opacity: 0.5

  .t-video,
  .b-video
    position: relative
    top: 0
    height: 100%
    width: 100%
    z-index: 1

  .controllers-wrapper
    position: absolute
    z-index: 1001
    padding: 1em
    left: 0
    right: 0
    bottom: 0
    background: linear-gradient(to top, -black(0.4) 30%, -black(0.1) 80%, transparent 100%)
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    align-content: center
    // Default hidden
    opacity: 0
    transition: opacity 0.1s
    // Force display when paused
    &.show
      opacity: 1

    .playback-btn
      margin-right: 2rem
      .fa-icon
        color: #fff
        font-size: 3rem
        cursor: pointer
        transition: opacity 0.15s
        &:active
          opacity: 0.7

    .progress-track
      flex: 1 1 0.00001px
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: center

    .video-time
      color: #fff
      min-width: 8rem
      font-size: 1.5rem
      margin-left: 2rem
      text-align: right

    //
    // Mimic Video Progress Bar
    //
    input.video-progress
      -webkit-appearance: none
      appearance: none
      outline: none
      width: 100%
      height: 1rem
      border-radius: 0.5rem
      margin: 0
      background: -white(0.4)
      cursor: pointer
      &:focus
        outline: 0
      &::-webkit-slider-thumb
        -webkit-appearance: none
        appearance: none
        background-color: #fff
        width: 2rem
        height: 2rem
        box-shadow: 0 0 0.5rem 0.15rem -black(0.35)
        border-radius: 50%
        border: 0
      &::-moz-range-thumb
        appearance: none
        background-color: #fff
        width: 2rem
        height: 2rem
        box-shadow: 0 0 0.5rem 0.15rem -black(0.35)
        border-radius: 50%
        border: 0
      &[disabled]
        cursor: not-allowed
        &::-webkit-slider-thumb
          display: none
        &::-moz-range-thumb
          display: none

  &:hover
    .controllers-wrapper
      opacity: 1
</style>
