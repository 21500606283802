<script>
import Logo from './Logo.vue'

export default {
  name: 'OverlayHeader',
  components: {
    Logo
  },

  props: {
    timer: {
      default: -1,
      type: Number
    },
    title: {
      default: '',
      type: String
    },
    icon: {
      default: '',
      type: [String, Array]
    },
    // Override with client's Custom Logo
    logo: {
      default: '',
      type: String
    }
  }
}
</script>

<template lang="pug">
section.overlay-header
  .topleft(v-if="timer < 0")
    fa(v-if="icon" :icon="icon")
    .title(v-if="!$slots.title") {{title}}
    slot(v-else name="title")
  .topleft(v-else)
    fa(:icon="icon")
    .timer {{ $t('overlayHeader.countdown', {timer}) }}
  .topright
    img(v-if="logo && logo.length", :src="logo")
    logo(v-else)
</template>

<style lang="stylus">
section.overlay-header
  position: absolute
  left: 0
  top: 0
  bottom: 0
  right: 0

  .topleft
    z-index: 100
    padding: 2vmin 0 0 2vmin
    position: absolute
    left: 0
    top: 0
    display: flex
    align-items: center

    svg.svg-inline--fa
      color: var(--gray-lightest)
      width: 2.5vmin
      height: 2.5vmin

    .title
      margin: 0 0 0 0.7em
      color: var(--gray-lightest)
      font-size: 2vmin
      text-transform: capitalize

    .timer
      margin: 0 0 0 0.7em
      color: var(--gray-lightest)
      font-size: 2vmin

  .topright
    z-index: 100
    position: absolute
    right: 0
    top: 0
    padding: 2vmin 2vmin 0 0

    img
      width: 20vmin
      height: auto
      border: 0
</style>
