<script>
import Spinner from '../common/Spinner.vue'

export default {
  name: 'CommonMessage',
  components: {
    Spinner
  },

  props: {
    type: {
      default: 'indeterminate',
      type: String
    },
    title: {
      default: '',
      type: String
    },
    text: {
      required: true,
      type: String
    },
    severity: {
      required: false,
      type: String,
      default: 'info'
    },
    description: {
      required: false,
      type: String,
      default: ''
    },
    descriptionHtml: {
      type: Boolean,
      default: false
    },
    hideSpinner: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // Use computed varaible to make sure we get the updated value
    displayTitle () {
      if (this.title && this.title.length) {
        return this.title
      }
      return this.$t('message.defaultTitle')
    }
  }
}
</script>

<template lang="pug">
transition(name="fade" appear)
  .common-message
    h2 {{ displayTitle }}
    h1(:class="severity") {{text}}
    p(v-if="description !== '' && !descriptionHtml") {{description}}
    p(v-if="description !== '' && descriptionHtml" v-html="description")
    spinner(v-else-if="!hideSpinner")
</template>

<style lang="stylus">
.common-message
  font-size: 3vmin
  position: absolute
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  align-content: center
  justify-content: center

  h1
    margin: 0.5em 0 0.5em 0
    text-align: center
    text-shadow: 0.1vmin 0.1vmin 0.2vmin rgba(0, 0, 0, 0.7)

  h2
    color: var(--gray-light)
    text-align: center
    font-size: 1.2em
    font-weight: 600
    text-shadow: 0.1vmin 0.1vmin 0.2vmin rgba(0, 0, 0, 0.7)

  p
    color: var(--gray-light)
    margin: 0 10% 0 10%
    text-align: center
    font-size: 1.2em
    font-weight: 600
    text-shadow: 0.1vmin 0.1vmin 0.2vmin rgba(0, 0, 0, 0.7)

    @media (min-width: 1920px)
      max-width: 50%

  .info
    color: var(--brand-info)

  .warn
    color: var(--brand-warning)

  .error
    color: var(--brand-danger)

  img
    height: 20vmin
    width: 20vmin
</style>
