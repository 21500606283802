<script>
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import MenuTextItem from './TextItem'

const config = {
  // In seconds. Paginate to next menu if there's more than one valid menu in request
  PAGINATE_INTERVAL: 10,

  // in seconds. Minimum paginate interval
  MIN_PAGE_INTERVAL: 1,

  // in ms
  SHOW_NEXT_DELAY: 500,

  // in ms
  ITEM_STAGGER: 0,

  // Add tollarence to fixes extra scrollHeight pixels in height calculation
  OFFSET_HEIGHT_DELTA: 1
}

export default {
  name: 'MenuAppCategory',
  components: {
    MenuTextItem
  },

  props: {
    category: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: ''
    },
    interval: {
      type: Number,
      default: 10
    },
    whiteText: {
      type: Boolean,
      default: false
    },
    onlyOne: {
      type: Boolean,
      default: false
    },
    isPortrait: {
      type: Boolean,
      default: false
    },
    // For Per column layout measurement
    forMeasure: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      list: [],

      currentFirstIndex: 0,
      currentLastIndex: 0,
      currentChunk: [],
      resizing: true,

      // Prevent Flickering
      isRendered: false,

      // For height calculation
      metricChunk: [],

      maxChunkLen: 0,

      debounceTimer: undefined,
      delayTimer: undefined,
      expireTimer: undefined,
      measureTimer: undefined
    }
  },

  computed: {
    itemsInCategory () {
      if (!this.items || !this.items.length ||
          !this.category || !this.category.items || !this.category.items.length
      ) {
        return []
      }

      const result = []
      this.category.items.forEach(itemID => {
        if (!itemID) { return }
        const targetItem = this.items.find(it => {
          if (it.id === itemID) {
            if ((this.isFeaturedCategory && it.featured === 'true') || (!this.isFeaturedCategory && it.featured !== 'true')) {
              return it
            }
          }
        })
        if (targetItem) {
          result.push(JSON.parse(JSON.stringify(targetItem)))
        }
      })
      return result
    },

    isFeaturedCategory () {
      return this.category && this.category.id === 'featured-category'
    },

    needsPaginate () {
      if (!this.list || !this.list.length || !this.currentChunk || !this.currentChunk) { return false }
      return this.currentChunk.length < this.list.length
    },

    listInterval () {
      if (this.forMeasure || !this.needsPaginate) { return }
      const appoxPageCount = Math.ceil(this.list.length / (this.maxChunkLen || 1))
      const perPageInterval = (this.interval || config.PAGINATE_INTERVAL) / (appoxPageCount || 1)
      return perPageInterval >= config.MIN_PAGE_INTERVAL ? perPageInterval : config.MIN_PAGE_INTERVAL
    },

    isUnnamedCategory () {
      return Boolean(!this.category.name || !(this.category.name || '').trim().length)
    }
  },

  watch: {
    itemsInCategory: {
      deep: true,
      handler () {
        this.debounceCheckSize()
      }
    },

    category: {
      deep: true,
      handler () {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)
  },

  methods: {
    render () {
      const items = JSON.parse(JSON.stringify(this.itemsInCategory || []))
      this.list = items

      if (!this.list.length) {
        this.$emit('end-of-list', 'empty')
      }

      if (this.resizing || this.forMeasure) { return }

      this.getNewChunk()
    },

    getNewChunk (startIndex) {
      if (this.resizing) { return }

      this.currentFirstIndex = startIndex || 0
      this.currentLastIndex = startIndex || 0
      this.metricChunk = []

      this.addSingleItem()
    },

    addSingleItem () {
      clearTimeout(this.measureTimer)

      if (!this.list || !this.list.length || this.resizing || !this.list[this.currentLastIndex]) { return }

      const item = JSON.parse(JSON.stringify(this.list[this.currentLastIndex]))
      this.metricChunk.push(item)

      clearTimeout(this.measureTimer)
      this.measureTimer = setTimeout(() => {
        clearTimeout(this.measureTimer)
        this.checkAvailableHeight()
      }, 50)
    },

    playNext () {
      clearTimeout(this.delayTimer)
      let remainItems = this.currentChunk.length
      this.currentChunk = []
      this.currentFirstIndex = this.currentLastIndex >= this.list.length - 1 ? 0 : this.currentLastIndex + 1

      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.getNewChunk(this.currentFirstIndex)
      }, (remainItems * config.ITEM_STAGGER) + config.SHOW_NEXT_DELAY)
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.resizing = true
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizing = false
        this.render()
      }, timeout || 200)
    },

    renderList () {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      const list = JSON.parse(JSON.stringify(this.list))

      // Skip animation when there's only one page
      if (this.metricChunk.length === list.length) {
        this.currentChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)
        this.$emit('end-of-list', 'no-paginate')
      } else {
        this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer)
          this.currentChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)

          this.expireTimer = setTimeout(() => {
            clearTimeout(this.expireTimer)
            // Reach the end of list
            if (this.currentLastIndex === this.list.length - 1) {
              this.$emit('end-of-list', 'end')
            }
            this.playNext()
          }, this.listInterval * 1000 - config.SHOW_NEXT_DELAY)
        }, 150)
      }
      this.isRendered = true
    },

    checkAvailableHeight () {
      if (!this.$refs || !this.$refs.measure || this.resizing || this.forMeasure) { return }

      let availableHeight
      let containerHeight
      let measureHeight

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!this.$refs || !this.$refs.measure) {
          FastDom.clear(measure)
          return
        }

        containerHeight = this.$refs.measure.offsetHeight
        measureHeight = this.$refs.measure.scrollHeight

        let mutate = FastDom.mutate(() => {
          availableHeight = (containerHeight + config.OFFSET_HEIGHT_DELTA) - measureHeight

          if (availableHeight >= 0 && this.currentLastIndex < this.list.length - 1) {
            this.currentLastIndex++
            this.addSingleItem()
          } else if (availableHeight < 0) {
            this.currentLastIndex--
            this.metricChunk.pop()
            this.maxChunkLen = Math.max(this.maxChunkLen, this.metricChunk.length)
            this.renderList()
          } else {
            this.maxChunkLen = Math.max(this.maxChunkLen, this.metricChunk.length)
            this.renderList()
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    }
  }
}
</script>

<template lang="pug">
.menu-app-category(:class="{'only-one': onlyOne, 'is-portrait': isPortrait, 'featured': category.id === 'featured-category', 'dark-border': !whiteText}")
  .category-name(v-if="!isUnnamedCategory"
                 :class="{'invisible': !isRendered}") {{ category.name }}

  .category-items-list(:class="{'unnamed-category': isUnnamedCategory}")
    .resize-sensor(ref="sensor")

    //- Real list
    transition-group(v-if="!forMeasure"
                     tag="div" class="category-list-inner visible-list"
                     name="page-fade"
                     :duration="150")
      menu-text-item(v-for="(item, index) in currentChunk", :key="item.id", :data-index="index"
                     :item="item"
                     :currency="currency")

    //- For height calculation
    .category-list-inner.measure(v-if="!forMeasure" ref="measure")
      menu-text-item(v-for="item in metricChunk"
                     :item="item"
                     :currency="currency")

    //- Placeholder
    .category-list-inner.invisible
      menu-text-item(v-for="item in list"
                     :item="item"
                     :currency="currency")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.menu-app-category
  position: relative
  overflow: hidden
  padding: 1em

  .category-name
    font-size: 1.8em
    font-weight: 600
    text-transform: uppercase
    margin: 0

    // Prevent flickering before recipe list fully rendered
    &.invisible
      opacity: 0
      visibility: hidden

  .category-items-list
    position: relative
    overflow: hidden

    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: 0
      bottom: 0
      left: 0
      right: 0

    .category-list-inner
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      overflow: hidden
      box-sizing: border-box
      
      .menu-app-text-item
        animation-delay: 0
        animation-duration: 150ms

      &.visible-list
        z-index: 1

      &.invisible,
      &.measure
        visibility: hidden
        opacity: 0

      &.invisible
        position: relative
        z-index: -3

      &.measure
        z-index: -2

  &.only-one
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    .category-items-list
      flex: 1 1 0.00001px

  &.only-one,
  &.is-portrait
    .category-items-list
      &.unnamed-category
        // To counteract the margin-top from the first TextItem
        margin-top: -1.2em

  //
  // Features category
  //
  &.featured
    outline: 0.2em solid -white(0.2)
    outline-offset: -0.2em

    &.dark-border
      outline: 0.2em solid -black(0.15)
</style>
