<script>
import {
  mapGetters
} from 'vuex'

import PageItem from 'components/items/Item.vue'
import OverrideAlertItem from 'components/items/override/OverrideAlertItem.vue'
import OverrideInfoItem from 'components/items/override/OverrideInfoItem.vue'
import OverrideMessageItem from 'components/items/override/OverrideMessageItem.vue'
import OverrideWebsite from 'components/items/override/OverrideWebsite.vue'
import OverrideWebImage from 'components/items/override/OverrideWebImage.vue'

import Log from 'services/log.js'
import Env from 'services/environment'

const RESOURCES_BASE = Env.resourcesURL()

const ALERT_DEFAULT_COLOR = '#2F3241'
const ALERT_DEFAULT_ICON = 'fas|exclamation-triangle'

const DEFAULT_TYPES = ['alert', 'info', 'message', 'web', 'web_image']

// In seconds
const MIN_DURATION = 4

export default {
  name: 'Override',
  components: {
    PageItem,
    OverrideAlertItem,
    OverrideInfoItem,
    OverrideMessageItem,
    OverrideWebsite,
    OverrideWebImage
  },

  props: {
    override: {
      default: null,
      type: Object
    }
  },

  data () {
    return {
      startTime: null,
      durationTimer: null
    }
  },

  computed: {
    ...mapGetters(['logoURL', 'logoType']),

    message () {
      const message = this.override.message
      const type = this.override.type
      let obj
      try {
        obj = JSON.parse(message)
        if (typeof obj !== 'object') {
          obj = {text: message}
        }
      } catch (e) {
        obj = {text: message}
      }
      obj.text = obj.text || ''

      if (type === 'alert' || type === 'info') {
        obj.icon = obj.icon || ALERT_DEFAULT_ICON
      }
      if (type === 'alert' || type === 'info' || type === 'message') {
        obj.color = obj.color || ALERT_DEFAULT_COLOR
        obj.font = obj.font || ''
      }

      return obj
    },

    icon () {
      return (this.message.icon || '').split('|')
    },

    bgColor () {
      if (!this.message || !this.message.color) { return }
      return {
        backgroundColor: this.message.color
      }
    },

    pageItem () {
      if (this.isDefaultType(this.override.type)) {
        return {}
      }
      const item = JSON.parse(JSON.stringify(this.override))
      item.url = this.override.message
      return item
    },

    customLogo () {
      if (this.logoURL && this.logoType === 'custom') {
        return this.logoURL
      }
    },

    manualOverrideDuration () {
      if (
        this.override?.scheduling?.scheduling === 'Manual' &&
        this.override?.scheduling?.show_for === 'duration' &&
        this.override?.scheduling?.duration > 0
      ) {
        return Math.max(this.override.scheduling.duration, MIN_DURATION) * 1000
      }
      return null
    }
  },

  created () {
    this.playAlertSound(this.override)
    this.startTime = Date.now()
    this.durationTimer = setInterval(() => {
      if (!this.manualOverrideDuration) { return }
      if (Date.now() - this.startTime >= this.manualOverrideDuration) {
        this.$store.commit('addOverrideShowed', this.override.id)
        clearInterval(this.durationTimer)
      }
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.durationTimer)
  },

  methods: {
    playAlertSound (m) {
      if (!m || !m.sounds || !m.sounds.length || m.sounds === 'mute' || (typeof m.sounds !== 'string')) { return }
      const sound = m.sounds === 'default' ? 'default_alert.mp3' : `${m.sounds}.mp3`
      const audio = new window.Audio(`${RESOURCES_BASE}/sounds/${sound}`)
      // NOTE @ Dec 13, 2017
      // Workaround for the `play() can only be initiated by a user gesture` error in Chrome v65
      audio.setAttribute('muted', 'muted')
      audio.play().then(() => {
        if (audio.muted) { audio.muted = false }
      }).catch(DOMException => {
        Log.debug('player', 'Override: Unable to play alert sounds.\nBrowser requires a user gesture to start audio playback', 'DBG_UNABLEPLAYSOUND3', null, true)
      }).catch(err => {
        Log.debug('player', 'Override: Alert sound playback error', 'DBG_UNABLEPLAYSOUND4', err, true)
      })
    },

    isDefaultType (type) {
      return DEFAULT_TYPES.includes(type)
    },

    pageItemFinished () {
      this.$store.commit('addOverrideShowed', this.override.id)
    }
  }
}
</script>

<template lang="pug">
section.override-screen
  template(v-if="isDefaultType(override.type)")
    override-alert-item(v-if="override.type === 'alert'" :icon="icon" :message="message.text" :font="message.font" :logo="customLogo" :style="bgColor")
    override-info-item(v-if="override.type === 'info'" :icon="icon" :message="message.text" :font="message.font" :logo="customLogo" :style="bgColor")
    override-message-item(v-if="override.type === 'message'" :message="message.text" :font="message.font" :style="bgColor")
    override-website(v-if="override.type === 'web'" :url="message.text")
    override-web-image(v-if="override.type === 'web_image'" :url="message.text")
  //- Apps / Boards / Media
  template(v-else)
    page-item(:item="pageItem" active item-preview-mode @finished="pageItemFinished" @single-item-finished="pageItemFinished")
</template>

<style lang="stylus">
section.override-screen
  // Make it higher than the PlaylistOverlays and OverlayContainer
  z-index: 5600

  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  > .page-item
    z-index: 51
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
</style>
