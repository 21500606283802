<script>
import Moment from 'moment-timezone'

export default {
  name: 'ClockItem',

  props: {
    item: {
      type: Object,
      default: () => { return {} }
    },
    show24hr: {
      type: Boolean,
      default: false
    },
    isPortrait: {
      type: Boolean,
      default: false
    },
    isTall: {
      type: Boolean,
      default: false
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isXWide: {
      type: Boolean,
      default: false
    },
    horzClass: {
      type: String,
      default: ''
    },
    vertClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      displayDate: '',
      displayTime: '',

      hasRendered: false,

      tickTimer: undefined
    }
  },

  computed: {
    displayLocation () {
      return (this.item && this.item.name) || ''
    },

    useDeviceClock () {
      return this.item.useDeviceClock === true || this.item.useDeviceClock === 'true'
    }
  },

  mounted () {
    this.updateDisplay()

    clearInterval(this.tickTimer)
    this.tickTimer = setInterval(() => {
      this.updateDisplay()
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.tickTimer)
  },

  methods: {
    updateDisplay () {
      // Skip when timezone id is not ready yet
      if (!this.item || (!this.useDeviceClock && !this.item.timezone)) { return }

      const now = Moment()
      const timeNow = this.useDeviceClock ? now : Moment.tz(now, this.item.timezone)

      this.displayDate = `${timeNow.format('dddd,')}\n${timeNow.format('MMMM Do')}`

      if (this.show24hr) {
        this.displayTime = timeNow.format('H:mm')
      } else {
        this.displayTime = timeNow.format('h:mm a')
      }

      if (!this.hasRendered) {
        this.hasRendered = true
      }
    }
  }
}
</script>

<template lang="pug">
section.clock-inner-wrapper(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide, 'x-wide': isXWide, 'is-tall': isTall}")
  .secondary-section.app-context-section.secondary(:class="[horzClass, vertClass, {'border-bottom': (!isPortrait && !isWide), 'border-top': isPortrait, 'border-left': isWide, 'uninitialized': !hasRendered}]")
    .date-block {{ displayDate }}
    .location-block(v-if="isPortrait || isWide") {{ displayLocation }}
  .main-section.app-context-section.primary(:class="[horzClass, vertClass, {'uninitialized': !hasRendered}]")
    .time-block(:class="{'show-24hr': show24hr}") {{ displayTime }}
    .location-block(v-if="!isPortrait && !isWide") {{ displayLocation }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

section.clock-inner-wrapper
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  display: flex
  flex-flow: column nowrap
  justify-content: space-between
  align-items: stretch
  overflow: hidden

  .secondary-section,
  .main-section
    padding: 1em 1.5em

  .location-block
    opacity: 0.7
    line-height: 110%
    padding-top: 0.3em

  .secondary-section
    .date-block
      font-size: 1.4em
      line-height: 120%

  .main-section
    flex: 1 1 0.00001px
    .time-block
      font-size: 3.6em
      font-weight: bold
      line-height: 120%
    .location-block
      font-size: 1.3em

  &.is-portrait,
  &.is-wide
    .secondary-section
      flex: 1 1 0.00001px
      order: 10
    .main-section
      flex: none
      justify-content: center

  &.is-portrait
    .secondary-section
      .date-block
        white-space: pre
      .location-block
        padding-top: 0.8em
      .date-block,
      .location-block
        font-size: 1.5em
    .main-section
      .time-block
        font-size: 3.5em

  &.is-tall
    .main-section,
    .secondary-section
      padding: 1.5em 0.8em
    .main-section
      .time-block
        font-size: 2.5em
    .secondary-section
      .date-block,
      .location-block
        font-size: 1.3em
        line-height: 140%

  &.is-wide
    flex-flow: row nowrap
    .main-section
      align-items: center
      .time-block
        font-size: 4em
        text-align: center
        // Prevent width jumpping between items
        // - 12H
        min-width: 4.2em
        // - 24H
        &.show-24hr
          min-width: 3em
    .secondary-section
      .date-block
        font-size: 2.2em
      .location-block
        padding-top: 0.3em
        font-size: 1.8em

  &.x-wide
    .main-section
      .time-block
        font-size: 2.8em
    .secondary-section
      .date-block
        font-size: 1.3em
      .location-block
        font-size: 1.1em
</style>
