<script>
import ImageItem from 'components/items/ImageItem.vue'
import VideoItem from 'components/items/VideoItem.vue'
import SlidesItem from 'components/items/SlidesItem.vue'

import qs from 'qs'
import {
  mapState,
  mapGetters
} from 'vuex'

import Utils from 'services/utils.js'
import Log from 'services/log.js'
import Env from 'services/environment'
import TouchEvents from 'services/touchevents.js'
import PlayTime from 'services/play-time.js'
import { EventBus } from 'services/eventbus.js'

const config = {
  // In second. Fallback interval
  DEFAULT_INTERVAL: 10,
  MIN_INTERVAL: 1,

  // In ms. Delay recording "item-started" to prevent event racing
  DELAY_LAST_PLAYED_RECORD: 2500,

  // In ms.
  DELAY_ITEM_REPORT: 1000,

  // In second. Force advance to next item if the current item is not loaded within this duration
  MAX_WAIT_TIME: 30
}

export default {
  name: 'MediaZone',
  components: {
    ImageItem,
    VideoItem,
    SlidesItem
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { return {} }
    },
    // Is being embedded in MediaFolder
    isMediaFolder: {
      type: Boolean,
      default: false
    },
    // Is being embedded in OneDrive/GoogleDrive Folder
    isDriveFolder: {
      type: Boolean,
      default: false
    },
    // For Play Time record
    driveType: {
      type: String,
      default: ''
    },
    // "One At A Time" is toggled on
    oneAtATime: {
      type: Boolean,
      default: false
    },
    folderTotalItems: {
      type: Number,
      default: 0
    },
    reportItemPlayback: {
      type: Boolean,
      default: false
    },
    // For AD contents feature
    readyForAd: {
      type: Boolean,
      default: false
    },
    isAdFolder: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      items: [],
      animatedZoomEffect: false,
      muted: false,
      keepLooping: false,
      tapInteraction: false,
      interval: 0,
      transition: '',
      imageFit: '',
      imagePos: '',
      imageRepeat: '',

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      currentIndex: -1,
      indices: [],

      startTime: -1,

      videoDurations: {},

      loading: true,
      destroyed: false,
      validMediaItems: [],
      currentPlaybackURL: '',

      prepareTriggeredWhilePaused: false,

      swipeHandler: undefined,

      prepareTimer: undefined,
      itemLoadTimer: undefined,
      itemStartTimer: undefined,
      itemReportTimer: undefined,
      validTimer: undefined
    }
  },

  computed: {
    ...mapState({
      screensaverIsOn: s => s.playbackmode.screensaverMode
    }),
    ...mapGetters([
      'isPaused',
      'currentPageName',
      'playlistSwipeEnabled',
      'pageItemTransition'
    ]),

    isFolderType () {
      return this.isMediaFolder || this.isDriveFolder
    },

    sanitizedInterval () {
      return Math.max(config.MIN_INTERVAL, (+this.interval || config.DEFAULT_INTERVAL))
    },

    intervalInMs () {
      return this.sanitizedInterval * 1000
    },

    playTimeSuffix () {
      if (this.isDriveFolder && this.driveType.length) {
        return `_${this.driveType}`
      }
      if (this.isMediaFolder) {
        return '_folder'
      }
      return ''
    },

    mediaItems () {
      if (!this.items) { return [] }

      const length = this.items.length || Object.keys(this.items).length || 0

      if (length === 0) { return [] }

      const result = []

      for (let idx = 0; idx < length; idx++) {
        const item = this.items[idx] || ''

        const [itemURL, itemParams] = item.split('?')

        let url = decodeURIComponent(itemURL)

        // Arguments includes:
        // - s: Subtitles for videos
        // - valid_to: Valid To timestamp (for Media Zone)
        // - valid_from: Valid From timestamp (for Media Zone)
        // - is_video: indicator for video item (for Drive Folder)
        // - is_image: indicator for image item (for Drive Folder)
        // - token: token for authentication
        const args = qs.parse(itemParams)

        if (args.token) {
          url = this.addTokenToURL(url, args.token)
        }

        const urls = itemURL.split(',')

        // Is Slides
        if (urls.length > 1) {
          const slidesItem = {
            type: 'slides',
            index: idx,
            url,
            duration: this.sanitizedInterval,
            options: args
          }
          if (this.transition) {
            slidesItem.transition = this.transition
          }
          result.push(slidesItem)
          continue
        }

        // Is Video
        if (Utils.isVideoURL(url) || args.is_video === 'true') {
          const videoItem = {
            type: 'video',
            index: idx,
            muted: this.muted,
            tapInteraction: this.tapInteraction,
            url,
            options: args
          }
          if (args.s && args.s.length > 0) {
            videoItem.subtitles = decodeURIComponent(args.s)
          }
          result.push(videoItem)

        // Is Image
        } else if (Utils.isImageURL(url) || args.is_image === 'true') {
          const imageItem = {
            type: 'image',
            index: idx,
            url,
            duration: this.sanitizedInterval,
            options: args
          }
          if (this.animatedZoomEffect) {
            imageItem.animated_zoom_effect = true
          }
          if (this.imageFit && this.imageFit.length) {
            imageItem.image_fit = this.imageFit
          }
          // New Media Zone properties, use camelCase instead of kebab_case
          if (this.imagePos && this.imagePos.length) {
            imageItem.imagePos = this.imagePos
          }
          if (this.imageRepeat && this.imageRepeat.length) {
            imageItem.imageRepeat = this.imageRepeat
          }
          result.push(imageItem)
        }
      }
      return result
    },

    videoItems () {
      if (!this.validMediaItems || !this.validMediaItems.length) { return [] }
      return this.validMediaItems.filter(item => {
        return item && item.type === 'video'
      })
    },

    imagesCount () {
      if (!this.validMediaItems || !this.validMediaItems.length) { return 0 }
      const imgs = this.validMediaItems.filter(item => {
        return item && item.type === 'image'
      })
      return imgs.length
    },

    // Total slides pages count
    slidesCount () {
      if (!this.validMediaItems || !this.validMediaItems.length) { return 0 }
      let count = 0
      this.validMediaItems.forEach(item => {
        if (item && item.type === 'slides') {
          count += (item.url || '').split(',').length
        }
      })
      return count
    },

    hasValidTimeItems () {
      // Folder type (Media Folder) already checked valid times in the parent Folder component
      if (this.isFolderType || !this.mediaItems || !this.mediaItems.length) { return false }
      return this.mediaItems.some(item => {
        return item && item.options && (item.options.valid_to || item.options.valid_from)
      })
    },

    validItemsKey () {
      if (!this.validMediaItems.length) { return '' }
      return this.validMediaItems.map(item => {
        return item.url
      }).join('_')
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    },

    // Use "out-in" transition mode [DEV-2236]
    useOutInMode () {
      return Boolean(this.transition === 'fadeOutIn')
    },

    transitionMode () {
      if (this.useOutInMode) {
        return 'out-in'
      }
      return null
    },

    hasOnlyOneItem () {
      return Boolean(this.validMediaItems && this.validMediaItems.length === 1) &&
             // Since DEV-4497, items.length === 1 no longer means the MediaFolder has only one item
             (!this.isMediaFolder || (this.isMediaFolder && this.folderTotalItems === 1))
    },

    needsZonePaginate () {
      return Boolean(this.validMediaItems && this.validMediaItems.length > 0 && !this.hasOnlyOneItem)
    },

    gridZoneName () {
      return (this.item && this.item.grid_area) || 'main'
    },

    noForceAdvance () {
      // "Auto-Advance to Next Page" (default to `true`) === keepLooping: false [DEV-2481]
      return this.keepLooping
    },

    // Get the images to fire the "finished" event with the defined interval when they fit certain Media Folder configurations [ENG-818]
    needsImageFinishedEvent () {
      return this.isMediaFolder && this.oneAtATime && !this.noForceAdvance
    },

    // If the zone has multiple items, it should start playback the next item instead of replay the current video.
    // In other words, only replay when there's only one valid item at the moment, or the zone is configured to play one item at a time.
    skipAutoReplay () {
      return !(this.oneAtATime || this.hasOnlyOneItem)
    },

    // Delay video replay when:
    // - One-at-a-Time is enabled, or has only one valid item
    // - Not in editor mode
    // - Auto-advance is enabled
    // Mainly fallback for edge cases of playlist with only one page
    delayVideoReplay () {
      return !Boolean(Env.pageEditMode) && !this.noForceAdvance && (this.oneAtATime || this.hasOnlyOneItem)
    }
  },

  watch: {
    'item.url' () {
      // Reset flag state on change
      clearTimeout(this.itemReportTimer)
      this.prepareTriggeredWhilePaused = false
      this.render()
    },

    isPaused (isTrue) {
      // Resume playback
      if (!isTrue && this.prepareTriggeredWhilePaused) {
        this.prepareTriggeredWhilePaused = false
        this.prepareNext()
      }
    },

    validItemsKey (newValue, oldValue) {
      if (this.isFolderType) { return }

      // No valid items found anymore
      if (!newValue && oldValue && this.mediaItems.length) {
        this.$emit('finished')

        const thisItem = JSON.parse(JSON.stringify((this.showPrime ? this.primeItem : this.baseItem) || {}))

        // Having "thisItem" means it's not the MediaZone's initial render
        if (thisItem && thisItem.type) {
          this.logPlayTime(thisItem.type)
          this.$emit('item-finished')
        }

        this.$nextTick(() => {
          this.primeItem = null
          this.baseItem = null
        })
      }

      // Valid list is not empty, but the current playback item is not in the list now
      if (this.currentPlaybackURL && newValue && newValue.indexOf(this.currentPlaybackURL) === -1) {
        this.prepareNext(true)
      }
    }
  },

  mounted () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.itemLoadTimer)
    clearTimeout(this.itemStartTimer)
    clearTimeout(this.itemReportTimer)

    EventBus.$on('interaction-go-to-prev', this.playlistGoToPrev)
    EventBus.$on('interaction-go-to-next', this.playlistGoToNext)

    this.render()

    if (this.swipeHandler) {
      this.swipeHandler.destroy()
    }
    this.swipeHandler = new TouchEvents(this.$el)

    this.swipeHandler.onSwipe('left', this.goToNext)
    this.swipeHandler.onSwipe('right', this.goToPrev)
    this.swipeHandler.onSwipe('up', this.goToNext)
    this.swipeHandler.onSwipe('down', this.goToPrev)

    clearInterval(this.validTimer)
    this.validTimer = setInterval(() => {
      if (this.destroyed) {
        // Prevent timer keep running in the background after component is destroyed
        clearInterval(this.validTimer)
        return
      }
      this.checkValidItems()
    }, 1000)
  },

  beforeDestroy () {
    this.destroyed = true

    clearTimeout(this.prepareTimer)
    clearTimeout(this.itemLoadTimer)
    clearTimeout(this.itemStartTimer)
    clearTimeout(this.itemReportTimer)
    clearInterval(this.validTimer)

    EventBus.$off('interaction-go-to-prev', this.playlistGoToPrev)
    EventBus.$off('interaction-go-to-next', this.playlistGoToNext)

    if (this.swipeHandler) {
      this.swipeHandler.destroy()
    }
    this.swipeHandler = undefined

    this.clearLargeData()
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }
      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 50 })

      // For regular media (TTV uploaded ones)
      this.items = options.items

      // For Media Folder since DEV-4497
      this.indices = options.indices || []

      this.animatedZoomEffect = options.animated_zoom_effect === 'true'
      this.muted = options.muted === 'true'
      this.tapInteraction = options.tap_interaction === 'true'
      this.keepLooping = options.keep_looping === 'true'

      // DEV-3417
      // Has only one media item in the zone (Not Drive or Media Folder)
      if (!this.isFolderType && this.items && Array.isArray(this.items) && this.items.length === 1) {
        const firstItem = this.items[0] || ''
        // Check if it's NOT slides type
        if (firstItem && firstItem.length && firstItem.indexOf(',') === -1) {
          const url = decodeURIComponent(firstItem)
          if (Utils.isImageURL(url)) {
            // Force set the "keep_looping" to true (do Not auto-advance)
            // when the only one zone item is an image
            this.keepLooping = true
          }
        }
      }

      this.interval = Math.max(config.MIN_INTERVAL, (+options.interval || config.DEFAULT_INTERVAL))

      this.imageFit = options.image_fit || ''
      this.imagePos = options.image_position || ''
      this.imageRepeat = options.image_repeat || ''
      this.transition = options.transition || ''

      this.$nextTick(() => {
        this.checkValidItems()

        this.$nextTick(() => {
          this.prepareNext(true)
        })
      })
    },

    prepareNext (forceReset = false, manualAction = false, eofListChecked = false) {
      if (!this.validMediaItems || !this.validMediaItems.length) { return }

      clearTimeout(this.prepareTimer)

      if (forceReset) {
        this.currentIndex = -1
      }

      if (!forceReset && (this.isPaused && !manualAction)) {
        this.prepareTriggeredWhilePaused = true
        this.startItemLoadedStatusChecking()
        return
      }

      const nextIndex = (this.currentIndex + 1) % this.validMediaItems.length
      const nextItem = JSON.parse(JSON.stringify(this.validMediaItems[nextIndex]))

      if (this.validMediaItems.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      this.showNext(nextIndex, eofListChecked)
    },

    preparePrevious (manualAction = false) {
      if (!this.validMediaItems || !this.validMediaItems.length) { return }

      if (this.isPaused && !manualAction) {
        return
      }

      const prevIndex = (this.currentIndex <= 0) ? this.validMediaItems.length - 1 : this.currentIndex - 1
      const prevItem = JSON.parse(JSON.stringify(this.validMediaItems[prevIndex]))

      if (this.validMediaItems.length > 1) {
        if (this.showPrime) {
          this.baseItem = prevItem
        } else {
          this.primeItem = prevItem
        }
      }

      this.showNext(prevIndex)
    },

    showNext (nextIndex, eofListChecked = false) {
      clearTimeout(this.prepareTimer)
      const activeIndex = +this.currentIndex

      let skipPaginate = false
      // Prevent duplicate "finished" event being send to the Folder component
      // When last played item is Video/Slides and EOF is checked in "itemsFinished"
      if (!eofListChecked) {
        // Call "finished" when needed in the `endOfListChecking`
        skipPaginate = this.endOfListChecking(activeIndex)
      }

      const thisItem = JSON.parse(JSON.stringify((this.showPrime ? this.primeItem : this.baseItem) || {}))
      const isShowingImage = (thisItem.type !== 'video' && thisItem.type !== 'slides')
      // Having "thisItem" means it's not the MediaZone's initial render
      if (thisItem && thisItem.type) {
        if (isShowingImage) {
          // Log play time for Images and marked as "item-finished"
          this.logPlayTime(thisItem.type)
          this.$emit('item-finished')
        } else if (!isShowingImage && !eofListChecked) {
          // The active item is not an Image, and this showNext paginate is not called by "itemFinished"
          // Send "item-finished" as fallback
          this.$emit('item-finished')
        }
      }

      // No need to play next item in the list
      if (this.readyForAd || skipPaginate) {
        this.startItemLoadedStatusChecking()

        // When active item is a Video/Slides
        if (!isShowingImage) {
          if (
            // Playlist is paused
            this.isPaused ||
            // The Media Folder is configured to play one item at a time
            this.oneAtATime ||
            // The current zone has only one item
            this.hasOnlyOneItem
          ) {
            // Keep the current item for a while to prevent flickering
            return
          }

          this.showPrime ? this.baseItem = null : this.primeItem = null
          this.showPrime = !this.showPrime
        }

        // When active item is image
        // It's safe to keep it on the screen
        return
      }

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (this.isMediaFolder && this.indices && this.indices.length) {
        this.debounceItemStart(+this.indices[nextIndex])
      }

      this.startItemLoadedStatusChecking()

      const nextItem = this.showPrime ? this.primeItem : this.baseItem
      this.currentPlaybackURL = nextItem?.url || ''

      if (
        nextItem &&
        (
          nextItem.type === 'video' ||
          nextItem.type === 'slides' ||
          (nextItem.type === 'image' && this.needsImageFinishedEvent)
        )
      ) {
        // Wait for the 'finished' event from: Video, Slides,
        // and Images under certain folder configurations [ENG-818]
        return
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, this.intervalInMs)
    },

    checkValidItems () {
      if (!this.hasValidTimeItems || this.isFolderType) {
        this.validMediaItems = this.mediaItems || []
        this.loading = false
        return
      }

      const now = ~~(Date.now() / 1000)
      const validItems = []
      this.mediaItems.forEach(item => {
        if (!item.options || (!item.options.valid_to && !item.options.valid_from)) {
          validItems.push(item)
          return
        }
        const validTo = +item.options.valid_to || 0
        const validFrom = +item.options.valid_from || 0
        if (
          (validFrom && validTo && now >= validFrom && now <= validTo) ||
          (!validTo && now >= validFrom) ||
          (!validFrom && now <= validTo)
        ) {
          validItems.push(item)
        }
      })

      this.validMediaItems = validItems
      this.loading = false
    },

    endOfListChecking (activeIndex = 0) {
      // When `true`, keep displaying the current item in the latter phase
      let skipPaginate = false

      // Reaches the end of the list
      if (this.validMediaItems.length && activeIndex >= this.validMediaItems.length - 1) {
        // Plain MediaZone (Not Drive or Media Folder)
        if (!this.isFolderType) {
          if (!this.noForceAdvance) {
            // For MediaZone, only sent "finish" event when "Auto-Advance" is set to `true`
            this.$emit('finished')
          }
          if (this.hasOnlyOneItem) {
            skipPaginate = true
          }

        // MediaFolder or DriveFolder
        } else {
          // Allow sending 'finished' event to MediaFolder and DriveFolder [#DEV-2509]
          // Parent Folder components will decide whether to pass the "finished" event to the page componnent.
          this.$emit('finished')

          // - One At A Time
          if (this.oneAtATime) {
            skipPaginate = true

          // - Regular playback
          } else {
            // -- Has only one item indeed
            if (this.hasOnlyOneItem) {
              skipPaginate = true
              // -- Is MediaFolder, has more than one item, but the current list is only partial (not the full list)
            } else if (this.validMediaItems.length > 1 && this.isMediaFolder && this.validMediaItems.length < this.folderTotalItems) {
              // Wait for parent Folder component to fetch for the new full list
              skipPaginate = true
            }
          }
        }
      }
      return skipPaginate
    },

    startItemLoadedStatusChecking () {
      clearTimeout(this.itemLoadTimer)
      this.itemLoadTimer = setTimeout(() => {
        clearTimeout(this.itemLoadTimer)
        this.forceToNextItem()
      }, config.MAX_WAIT_TIME * 1000)
    },

    itemLoaded () {
      clearTimeout(this.itemLoadTimer)
      this.startTime = Date.now()
      this.$emit('loaded')

      // Fallback for image in Media Folder with smooth playback across pages + one at a time
      if (this.reportItemPlayback) {
        this.debounceReportItemPlayback()
      }
    },

    // When Video or Slides ended
    itemFinished () {
      if (this.isAdFolder) {
        this.$emit('finished')
        return
      }

      // Call "finished" when needed in the `endOfListChecking`
      const skipPaginate = this.endOfListChecking(+this.currentIndex)

      this.$emit('item-finished')

      if (this.readyForAd || skipPaginate) {
        // Prevent flickering playback of the current Video/Slides item when waiting for the new list
        // However, when Playlist is paused or has only one item, keep the current Video/Slides item on screen
        if (this.isPaused || this.oneAtATime || this.hasOnlyOneItem) { return }
        this.showPrime ? this.baseItem = null : this.primeItem = null
        this.showPrime = !this.showPrime
        return
      }

      this.prepareNext(false, false, true)
    },

    itemErrored () {
      this.startTime = -1
      this.forceToNextItem(true)
    },

    imageFinished () {
      if (this.needsImageFinishedEvent) {
        this.itemFinished()
      }
    },

    forceToNextItem (isErrored) {
      let target = 'media zone'
      if (this.isDriveFolder) {
        target = 'drive folder'
      } else if (this.isMediaFolder) {
        target = 'media folder'
      }
      if (isErrored) {
        Log.warn('media', `Unable to play current ${target} content.\nForce to show next item now.`, 'WAR_UNABLEPLAYCURRENT')
      } else {
        Log.info('media', `The ${target} content is still not fully loaded after ${config.MAX_WAIT_TIME}s.\nForce to show next item now.`, 'INF_CONTENTSTILLNOTLOAD')
      }

      if (this.needsZonePaginate) {
        this.prepareNext()
      } else if (!this.noForceAdvance) {
        this.$emit('finished')
      }
    },

    sumDuration (duration, item) {
      if (!this.videoItems || !this.videoItems.length) { return }
      this.$set(this.videoDurations, item.index, duration)

      // Is the last found video item in this zone
      const lastVideoItem = this.videoItems[this.videoItems.length - 1]
      if (lastVideoItem && lastVideoItem.index === item.index && lastVideoItem.url === item.url) {
        let sum = this.sanitizedInterval * (this.imagesCount + this.slidesCount)
        for (const vItemIndex in this.videoDurations) {
          sum += this.videoDurations[vItemIndex]
        }
        this.sendDuration(sum)
      }
    },

    sendDuration (duration) {
      this.$emit('duration', duration)
    },

    goToNext () {
      if (!this.needsZonePaginate) {
        if (this.playlistSwipeEnabled && !this.screensaverIsOn) {
          // Playlist Swipe (DEV-1711)
          Log.debug('media', 'Swipe to next page', 'DBG_SWIPENEXTPAGE')
          EventBus.$emit('interaction-nextpage')
        } else {
          // Skip when there's only one item in the zone
          Log.debug('media', 'There\'s only one item in this zone', 'DBG_ONEITEMINZONE')
        }
        return
      }
      Log.debug('media', 'Swipe to show the next media item', 'DBG_SWIPENEXTITEM')
      this.prepareNext(false, true)
    },

    goToPrev () {
      if (!this.needsZonePaginate) {
        if (this.playlistSwipeEnabled && !this.screensaverIsOn) {
          // Playlist Swipe (DEV-1711)
          Log.debug('media', 'Swipe to previous page', 'DBG_SWIPEPREVPAGE')
          EventBus.$emit('interaction-previouspage')
        } else {
          // Skip when there's only one item in the zone
          Log.debug('media', 'There\'s only one item in this zone', 'DBG_ONEITEMINZONE2')
        }
        return
      }
      Log.debug('media', 'Swipe to show the previous media item', 'DBG_SWIPEPREVITEM')
      this.preparePrevious(true)
    },

    playlistGoToNext (targetZoneName) {
      if (targetZoneName && this.gridZoneName === targetZoneName) {
        this.goToNext()
      }
    },

    playlistGoToPrev (targetZoneName) {
      if (targetZoneName && this.gridZoneName === targetZoneName) {
        this.goToPrev()
      }
    },

    debounceItemStart (itemIndex) {
      if (this.reportItemPlayback) {
        this.debounceReportItemPlayback()
      }

      // Fallback for edge case when paginate `interval` is set to a small value  (E.g. the minimum value of `1` second in js_user)
      const delay = config.DELAY_LAST_PLAYED_RECORD > this.intervalInMs
        ? Math.max(this.intervalInMs - 250, 500)
        : +config.DELAY_LAST_PLAYED_RECORD

      // Add a debouncer to prevent event racing during parent MediaFolder's pagination or refetching
      clearTimeout(this.itemStartTimer)
      this.itemStartTimer = setTimeout(() => {
        clearTimeout(this.itemStartTimer)
        this.$emit('item-started', itemIndex)
      }, delay)
    },

    debounceReportItemPlayback () {
      if (!this.reportItemPlayback || !this.currentPlaybackURL) { return }

      // Fallback for edge case when paginate `interval` is set to a small value  (E.g. the minimum value of `1` second in js_user)
      const delay = config.DELAY_ITEM_REPORT > this.intervalInMs
        ? Math.max(this.intervalInMs - 250, 500)
        : +config.DELAY_ITEM_REPORT

      const url = this.currentPlaybackURL + ''

      clearTimeout(this.itemReportTimer)
      this.itemReportTimer = setTimeout(() => {
        clearTimeout(this.itemReportTimer)
        this.$emit('playing-item', url)
      }, delay)
    },

    // Called by parent component (FolderItem)
    // Mainly for Media Folder doing smooth playback across pages
    forceResendPlaybackItemReport () {
      if (!this.reportItemPlayback) { return }
      this.debounceReportItemPlayback()
    },

    logPlayTime (type = '') {
      if (!type || !type.length) { return }
      const reportType = `${type}${this.playTimeSuffix}`
      if (this.startTime > 0) {
        PlayTime.addLogByType(reportType, { startTime: +this.startTime })
        // Reset to prevent duplicate logs (E.g. looping video)
        this.startTime = Date.now()
      }
    },

    addTokenToURL (url, token) {
      if (!url || !url.length || !token || !token.length) { return }
      const urlParts = url.split('?')
      const urlParams = qs.parse(urlParts[1] || '')
      urlParams.token = token
      return `${urlParts[0]}?${qs.stringify(urlParams)}`
    },

    clearLargeData () {
      this.primeItem = undefined
      this.baseItem = undefined
      this.items = []
      this.indices = []
      this.validMediaItems = []
      this.videoDurations = {}
    }
  }
}
</script>

<template lang="pug">
section.media-zone
  //- NOTE: All components need to be put in the same <transition> and be keyed with a `key` property
  //- in order to make mode="out-in" worked
  //- > https://forum.vuejs.org/t/solved-vue-transitions-not-working/7614
  transition(:name="transitionName", :duration="500", :mode="transitionMode" appear)
    //- Prime
    .media-zone-item.prime(v-if="showPrime", :key="`${_uid}_prime`")
      template(v-if="primeItem")
        image-item(v-if="primeItem.type === 'image'"
                   :key="primeItem.url"
                   :item="primeItem"
                   :active="active"
                   :stack="isDriveFolder ? 'media' : null"
                   :needs-finished-event="needsImageFinishedEvent"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="imageFinished"
                   @play-ended="logPlayTime('image')"
                   @error="itemErrored")
        video-item(v-if="primeItem.type === 'video'"
                   :key="primeItem.url"
                   :item="primeItem"
                   :active="active"
                   :is-drive-folder="isDriveFolder"
                   :skip-auto-replay="skipAutoReplay"
                   :delay-video-replay="delayVideoReplay"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="itemFinished"
                   @duration="sumDuration($event, primeItem)"
                   @play-ended="logPlayTime('video')"
                   @error="itemErrored")
        slides-item(v-if="primeItem.type === 'slides'"
                   :key="primeItem.url"
                   :item="primeItem"
                   :active="active"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="itemFinished"
                   @play-ended="logPlayTime('slides')"
                   @error="itemErrored")
    //- Base
    .media-zone-item.base(v-else, :key="`${_uid}_base`")
      template(v-if="baseItem")
        image-item(v-if="baseItem.type === 'image'"
                   :key="baseItem.url"
                   :item="baseItem"
                   :active="active"
                   :stack="isDriveFolder ? 'media' : null"
                   :needs-finished-event="needsImageFinishedEvent"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="imageFinished"
                   @play-ended="logPlayTime('image')"
                   @error="itemErrored")
        video-item(v-if="baseItem.type === 'video'"
                   :key="baseItem.url"
                   :item="baseItem"
                   :active="active"
                   :is-drive-folder="isDriveFolder"
                   :skip-auto-replay="skipAutoReplay"
                   :delay-video-replay="delayVideoReplay"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="itemFinished"
                   @duration="sumDuration($event, baseItem)"
                   @play-ended="logPlayTime('video')"
                   @error="itemErrored")
        slides-item(v-if="baseItem.type === 'slides'"
                   :key="baseItem.url"
                   :item="baseItem"
                   :active="active"
                   is-media-zone
                   @loaded="itemLoaded"
                   @finished="itemFinished"
                   @play-ended="logPlayTime('slides')"
                   @error="itemErrored")

  transition(name="fade" appear)
    .messages(v-if="!loading && mediaItems.length && !validMediaItems.length")
      p All media items fall outside the valid time range
</template>

<style lang="stylus">
section.media-zone
  width: 100%
  height: 100%
  position: absolute

  .media-zone-item
    width: 100%
    height: 100%
    position: absolute
    z-index: 5

    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 4vmin
</style>
