<script>
import Moment from 'moment-timezone'

import FitText from 'components/widgets/FitText.vue'

export default {
  name: 'AmadeusItem',
  components: {
    FitText
  },

  props: {
    event: {
      type: Object,
      default: () => { return {} }
    },

    hideLocation: {
      type: Boolean,
      default: false
    },

    dateFormat: {
      type: String,
      default: ''
    },

    portraitLayout: {
      type: Boolean,
      default: false
    },

    colorLabelWidth: {
      type: String,
      default: ''
    },

    needsAnimation: {
      type: Boolean,
      default: false
    },

    hide: {
      type: Boolean,
      default: false
    },

    darkText: {
      type: Boolean,
      default: false
    },

    showTextShadow: {
      type: Boolean,
      default: false
    },

    hideBox: {
      type: Boolean,
      default: false
    },

    hideBoxOutline: {
      type: Boolean,
      default: false
    },

    skipFontScaling: {
      type: Boolean,
      default: false
    },

    fontClass: {
      type: String,
      default: ''
    },

    fontSizeScale: {
      type: String,
      default: ''
    },

    active: {
      type: Boolean,
      default: false
    },

    noNeedScale: {
      type: Boolean,
      default: false
    },

    horzClass: {
      type: String,
      default: ''
    },

    vertClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    allDay () {
      return Moment(this.event.StartDateTime, this.dateFormat).diff(Moment(this.event.EndDateTime, this.dateFormat), 'hours') == 24
    },
    multiDays () {
      return Moment(this.event.StartDateTime, this.dateFormat).diff(Moment(this.event.EndDateTime, this.dateFormat), 'days') > 0
    },
    multiAllDays () {
      return Boolean(this.allDay && this.multiDays)
    },
    displayDate () {
      if (!this.event || !this.event.StartDateTime) { return }
      const now = Moment()
      if (!this.multiAllDays && this.allDay) {
        return this.$t('_common.time.allDayUpperCase')
      }
      const startTime = Moment(this.event.StartDateTime)
      let date = startTime.format(this.dateFormat)
      // Event beyond this year
      if (startTime.format('YYYY') !== now.format('YYYY')) {
        date += this.multiAllDays ? ' ' : '\n'
        date += startTime.format('YYYY')
      }
      if (this.multiAllDays) {
        const endTime = Moment(this.event.EndDateTime)
        let endDate = `${endTime.format(this.dateFormat)}`
        // Event beyond this year
        if (endTime.format('YYYY') !== now.format('YYYY')) {
          endDate += this.multiDays ? ' ' : '\n'
          endDate += endTime.format('YYYY')
        }
        date += `\n${this.$t('_common.time.timeAtoTimeB')}\n${endDate}`
      }
      return date
    },
    hideDisplayDate () {
      if (!this.displayDate) { return true }
      if (!this.multiDays && !this.event.allDay && this.displayTime) { return true }
      return false
    },
    displayTime () {
      if (!this.event || !this.event.StartDateTime) { return }
      if (this.event.allDay) { return }
      const startTime = Moment(this.event.StartDateTime, 'YYYY-MM-DDTh:mm:ss')

      // DEV-1485
      const hourShortFormat = this.$t('_common.time.format_ha')
      const hourMinuteFormat = this.$t('_common.time.format_hmma')
      const hourMinute24hFormat = this.$t('_common.time.format_hmm')

      let time = ''
      if (this.event.format24h) {
        time += startTime.format(hourMinute24hFormat)
        if (this.event.EndDateTime) {
          const endTime = Moment(this.event.EndDateTime, 'YYYY-MM-DDTh:mm:ss')
          if (startTime.format('YYYY-MM-DD') !== endTime.format('YYYY-MM-DD')) {
            time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(`MMM Do ${hourMinute24hFormat}`)}`
          } else {
            time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(hourMinute24hFormat)}`
          }
        }
      } else {
        if (startTime.format('mm') === '00') {
        // Ignore on time '00'
          time += startTime.format(hourShortFormat)
        } else {
          time += startTime.format(hourMinuteFormat)
        }
        if (this.event.EndDateTime) {
          const endTime = Moment(this.event.EndDateTime, 'YYYY-MM-DDTh:mm:ss')

          if (startTime.format('YYYY-MM-DD') !== endTime.format('YYYY-MM-DD')) {
            if (endTime.format('mm') === '00') {
            // Ignore on time '00'
              time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(`${hourShortFormat}`)}`
            } else {
              time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(`${hourMinuteFormat}`)}`
            }
          } else {
            if (endTime.format('mm') === '00') {
            // Ignore on time '00'
              time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(hourShortFormat)}`
            } else {
              time += `\n${this.$t('_common.time.timeAtoTimeB')} ${endTime.format(hourMinuteFormat)}`
            }
          }
        }
      }
      return time
    },

    fontSizeStyle () {
      if (this.fontSizeScale !== '') {
        return (this.noNeedScale && this.active) ? { fontSize: `${Number(this.fontSizeScale) * 0.8}em` } : { fontSize: `${this.fontSizeScale}em` }
      }
    }
  }
}
</script>

<template lang="pug">
section.amadeus-item-block.app-context-block(:class="{'portrait-layout': portraitLayout, 'dark-text': darkText, 'show-text-shadow': showTextShadow, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline, 'no-border': hideBox}")
  .resize-sensor(ref="sensor")

  .time-block.app-context-section.secondary(:class="[horzClass, vertClass, {'has-time': displayTime, 'hide-date': hideDisplayDate}]")
    template(v-if="!needsAnimation")
      .date(v-if="!hideDisplayDate", :style="[fontSizeStyle]") {{ displayDate }}
      .time(v-if="displayTime", :style="[fontSizeStyle]") {{ displayTime }}
    template(v-else)
      transition(name="fade" mode="out-in" appear)
        .date(v-if="!hide && !hideDisplayDate", :style="[fontSizeStyle]") {{ displayDate }}
      transition(name="fade" mode="out-in" appear)
        .time(v-if="!hide && displayTime", :style="[fontSizeStyle]") {{ displayTime }}
  .infos-block.app-context-section.primary(:class="[horzClass, vertClass, {'has-location': !hideLocation && event.FunctionRoomName, 'title-as-block': skipFontScaling || horzClass || vertClass}]")
    //- No Animation
    template(v-if="!needsAnimation")
      .event-title(:class="[horzClass, vertClass]")
        fit-text(
          class="fit-text-event-title"
          :text="event.Name"
          :useHTML="true"
          :base-font-size="1"
          :font-size-scale="fontSizeScale"
          :line-height="1.2"
          :font-class="fontClass"
          :skip-scaling="skipFontScaling || fontSizeScale")
      .event-location(:class="[horzClass, vertClass]", :style="[fontSizeStyle]") {{ event.FunctionRoomName }}
      .event-booking-group(v-if="event.AccountName && event.AccountName.length > 0" :class="[horzClass, vertClass]", :style="[fontSizeStyle]") {{ event.AccountName }}

    //- Needs Animation
    template(v-else)
      transition(name="fade" mode="out-in" appear)
        .event-title(:class="[horzClass, vertClass]")
          fit-text(v-show="!hide"
            class="fit-text-event-title"
            :text="event.Name"
            :useHTML="true"
            :base-font-size="1"
            :font-size-scale="fontSizeScale"
            :line-height="1.2"
            :font-class="fontClass"
            :skip-scaling="skipFontScaling || fontSizeScale")
      transition(name="fade" mode="out-in" appear)
        .event-location(v-if="!hide && !hideLocation && event.location", :class="[horzClass, vertClass]", :style="[fontSizeStyle]") {{ event.FunctionRoomName }}
        .event-booking-group(v-if="event.AccountName && event.AccountName.length > 0" :class="[horzClass, vertClass]", :style="[fontSizeStyle]") {{ event.AccountName }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

section.amadeus-item-block
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: stretch
  position: relative
  overflow: hidden

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .calendar-color
    width: 0.4em
    position: relative
    .color-label
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0

  .time-block
    flex: 2 2 0.00001px
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: flex-end
    text-align: right
    padding: 0.5em 1em 0.5em 0.5em

    &.has-time:not(.hide-date)
      justify-content: space-between

    &.hide-date
      .time
        margin-top: 0

    &.horz-right
      text-align: right
      align-items: flex-end

    &.horz-left
      text-align: left
      align-items: flex-start

    .date
    .time
      white-space: pre

    .date
      font-size: 1em
      line-height: 130%

    .time
      opacity: 0.7
      margin-top: 1em
      line-height: 120%

  .infos-block
    flex: 3 3 0.00001px
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: flex-start
    padding: 0.5em 1em
    overflow: hidden
    &.vert-bottom
      display: flex
      flex-flow: column nowrap
      justify-content: flex-end !important
      .event-title
        .fit-text-box
          .text-box
            p
              margin: 0
      .event-location
        margin: 0
      .event-booking-group
        margin: 0
    &.vert-top
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start !important
      .event-title
        .fit-text-box
          .text-box
            p
              margin: 0
      .event-location
        margin: 0
      .event-booking-group
        margin: 0
    &.vert-middle
      display: flex
      flex-flow: column nowrap
      justify-content: center !important
      .event-title
        .fit-text-box
          .text-box
            p
              margin: 0
      .event-location
        margin: 0
      .event-booking-group
        margin: 0

    .event-title
      flex: 1 1 0.00001px
      align-self: stretch
      max-width: 100%
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      font-weight: 600
      contentBoxAlignments()

    .fit-text-wrapper
      flex: 1 1 0.00001px
      display: flex
      flex-flow: column nowrap
      justify-content: center
      align-items: flex-start
      text-align: left
      overflow: hidden

    .title-container
      flex: 1 1 0.00001px
      position: relative

    .event-location
      opacity: 0.7
      margin-top: 0.6em
      line-height: 120%

    .event-booking-group
      opacity: 0.7
      margin-top: 0.6em
      line-height: 120%

    &.has-location
      justify-content: space-between

      .fit-text-wrapper
        justify-content: flex-start

    &.horz-left
      text-align: left
    &.horz-right
      text-align: right
    &.horz-center
      text-align: center
    &.horz-justify
      text-align: center

    &.title-as-block
      .event-title,
      .fit-text-wrapper
        display: block
        flex: none
      &.vert-middle
        .text-box
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
      &.vert-top
        .text-box
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
      &.vert-bottom
        .text-box
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;

  //
  // PORTRAIT LAYOUT
  //
  &.portrait-layout
    flex-flow: column nowrap
    .time-block
      flex: none
      align-items: flex-start
      text-align: left
      padding: 1em
    .infos-block
      flex: 1 1 0.000001px
      padding: 1em

  .fit-text-event-title
    height: 100%

  // Make border (color) invisible when `hideBox: true`
  &.no-border:not(.hide-box-outline)
    border-color: transparent
</style>
