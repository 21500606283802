<script>
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

const config = {
  MEASURE_TEXT: 'Mgby1790',

  // In seconds. Fallback interval
  INTERVAL: 10,

  // In seconds. Minimum interval for text paginate
  MIN_PAGINATE_INTERVAL: 2
}

export default {
  name: 'CarouselText',

  props: {
    text: { type: String, default: '' },
    // Item transition interval set in the parent container (InfoBar.vue)
    interval: { type: Number, default: 10 }
  },

  data () {
    return {
      containerSize: {},
      textSize: {},

      measureText: config.MEASURE_TEXT + '',
      measureHeight: 0,

      translateY: 0,
      show: false,

      debounceTimer: undefined,
      startTimer: undefined,
      paginateTimer: undefined
    }
  },

  computed: {
    linesTotal () {
      if (!this.measureHeight || !this.textSize || !this.textSize.h) {
        return 1
      }
      return Math.max(1, Math.ceil(this.textSize.h / this.measureHeight))
    },

    // Max lines per page
    linesPerPage () {
      if (this.linesTotal <= 1) { return 1 }
      if (!this.measureHeight || !this.containerSize || !this.containerSize.h) {
        return 1
      }
      return Math.max(1, ~~(this.containerSize.h / this.measureHeight))
    },

    pageNum () {
      if (this.linesTotal <= 1) { return 1 }
      return Math.max(1, Math.ceil(this.linesTotal / this.linesPerPage))
    },

    restrictHeight () {
      if (!this.measureHeight) { return }

      // No paginate needed
      if (this.linesTotal <= this.linesPerPage) {
        return Math.ceil(this.measureHeight * this.linesTotal)
      }

      // Needs paginate
      return Math.ceil(this.measureHeight * this.linesPerPage)
    },

    restrictHeightStyle () {
      if (!this.restrictHeight) { return }
      return {
        height: `${this.restrictHeight}px`
      }
    },

    paginateInterval () {
      return Math.max(config.MIN_PAGINATE_INTERVAL, (this.interval || config.INTERVAL) / this.pageNum)
    },

    transformStyle () {
      return {
        transform: `translate3d(0, -${this.translateY}px, 0)`
      }
    }
  },

  watch: {
    text () {
      this.debounceCheckSize()
    },
    interval () {
      this.debounceCheckSize()
    },

    pageNum () {
      this.debounceStart()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.startTimer)
    clearTimeout(this.paginateTimer)
    if (this.$refs && this.$refs.sensor) {
      AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearTimeout(this.startTimer)
    clearTimeout(this.paginateTimer)
  },

  methods: {
    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      if (!this.$el || !this.$refs) { return }

      const container = this.$el
      const block = this.$refs.block
      const measureBlock = this.$refs.measure
      let width
      let height
      let blockWidth
      let blockHeight

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container || !block || !measureBlock) {
          FastDom.clear(measure)
          return
        }

        width = container.offsetWidth || container.clientWidth
        height = container.offsetHeight || container.clientHeight

        blockWidth = block.offsetWidth || block.clientWidth
        blockHeight = block.offsetHeight || block.clientHeight

        if (!width || !height) {
          FastDom.clear(measure)
          return
        }

        this.containerSize = {
          w: width,
          h: height
        }

        this.textSize = {
          w: blockWidth,
          h: blockHeight
        }

        this.measureHeight = measureBlock.offsetHeight || measureBlock.clientHeight

        FastDom.clear(measure)
        this.debounceStart()
      })
    },

    debounceStart () {
      clearTimeout(this.startTimer)
      this.startTimer = setTimeout(() => {
        clearTimeout(this.startTimer)
        this.start()
      }, 100)
    },

    start () {
      clearTimeout(this.paginateTimer)
      this.show = false
      this.translateY = 0

      this.$nextTick(() => {
        this.show = true
      })

      this.prepareNext()
    },

    showNext () {
      this.translateY += this.restrictHeight
      if (!this.show) {
        this.show = true
      }
      this.prepareNext()
    },

    prepareNext () {
      clearTimeout(this.paginateTimer)
      this.paginateTimer = setTimeout(() => {
        clearTimeout(this.paginateTimer)
        this.showNext()
      }, this.paginateInterval * 1000)
    }
  }
}
</script>

<template lang="pug">
.carousel-text-container
  .resize-sensor(ref="sensor")

  //- Invisible measure blocks
  .wysiwyg-block.measure(ref="measure" v-html="measureText")
  .wysiwyg-block.measure(ref="block" v-html="text || ''")

  .carousel-item(:style="restrictHeightStyle")
    .wysiwyg-block.main-context(v-html="text || ''", :class="{'loading': !show}", :style="transformStyle")
</template>

<style lang="stylus">
.carousel-text-container
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .measure
    visibility: hidden
    opacity: 0
    z-index: -1
    position: absolute
    top: 0
    left: 0

  .carousel-item
    position: relative
    overflow: hidden
    z-index: 1

    .main-context
      position: relative
      z-index: 1
      will-change: transform
      transition: transform .3s, opacity .1s
      transform-origin: 0 0

      &.loading
        opacity: 0
</style>
