<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import ProfileItem from './profile/Item'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 1500
}

export default {
  name: 'ProfilePage',
  components: {
    ProfileItem
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      items: [],
      interval: 10,
      transition: '',

      vAlign: 'middle',
      hAlign: 'left',

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      currentIndex: -1,

      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'pageItemTransition'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    validItems () {
      if (!this.items || !this.items.length) { return [] }
      return this.items.filter(item => {
        // Display profile with valid Name
        return (item.name || '').trim().length
      })
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)

    this.render()
    this.$nextTick(() => {
      this.debounceCheckSize()
    })

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.items = options.items || []

      // Fallback for single item format before DEV-814
      if (options.name && !this.items.length) {
        this.items.push({
          name: options.name || '',
          description: options.description || '',
          profileMedia: options.profileMedia
        })
      }

      this.interval = Math.max(4, options.interval || 10)
      this.transition = options.transition || ''

      this.vAlign = options.vAlign || 'middle'
      this.hAlign = options.hAlign || 'left'

      this.prepareNext(true)
    },

    hasValue (input) {
      return Boolean(input && (input || '').trim().length)
    },

    prepareNext (isUpdate) {
      clearTimeout(this.showNextTimer)

      if (!this.validItems || !this.validItems.length) { return }

      // Force Reset
      if (isUpdate) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.validItems.length
      const nextItem = JSON.parse(JSON.stringify(this.validItems[nextIndex]))

      if (this.validItems.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      // Is first init
      if (this.currentIndex < 0) {
        this.showNext(nextIndex)
      } else if (this.validItems.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext (nextIndex) {
      clearTimeout(this.prepareTimer)
      const activeIndex = +this.currentIndex

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (!this.validItems.length) {
        return
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.validItems.length && activeIndex >= this.validItems.length - 1) {
        this.$emit('finished')
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, (this.interval || 10) * 1000 - config.PREPARE_TIME)
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    }
  }
}
</script>

<template lang="pug">
section.profile-page(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait}"
                    :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .profile-container.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
                                       :style="boxMarginStyle")
    //- Prime
    transition(:name="transitionName", :duration="500" appear)
      profile-item.prime(v-show="showPrime"
        :name="primeItem && primeItem.name"
        :description="primeItem && primeItem.description"
        :profile-media="primeItem && primeItem.profileMedia"
        :base-font-size="baseFontSize"
        :is-portrait="isPortrait"
        :horz-class="horzClass"
        :vert-class="vertClass"
        :font-class="fontClass"
      )

    //- Base
    transition(:name="transitionName", :duration="500" appear)
      profile-item.base(v-show="!showPrime"
        :name="baseItem && baseItem.name"
        :description="baseItem && baseItem.description"
        :profile-media="baseItem && baseItem.profileMedia"
        :base-font-size="baseFontSize"
        :is-portrait="isPortrait"
        :horz-class="horzClass"
        :vert-class="vertClass"
        :font-class="fontClass"
      )
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.profile-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .profile-container
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    z-index: 0
  
  .profile-item-block
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
