<script>
// MIXINS
import MenuItemMixins from './MenuItemMixins'

export default {
  name: 'MenuAppTextItem',

  mixins: [ MenuItemMixins ],

  props: {
    item: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: ''
    }
  }
}
</script>

<template lang="pug">
.menu-app-text-item
  .main-info
    .item-name {{ item.name }}
    .item-calories(v-if="showCalories") {{ item.calories }}cal
    .item-price(v-if="prices.length")
      .price-block(v-for="(itemPrice, pIndex) in prices", :key="pIndex"
                   :class="{'show-label': showPriceLabel}")
        .price-value {{ displayPrice(itemPrice) }}
        .price-label(v-if="showPriceLabel && itemPrice.label") {{ itemPrice.label }}
  .description(v-if="item.description && item.description.length"
               :class="{'show-label': showPriceLabel}") {{ item.description }}
</template>

<style lang="stylus">
.menu-app-text-item
  margin-top: 1.2em
  overflow: hidden

  .main-info
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: flex-start

    .item-name
      flex: 1 1 0.00001px
      font-size: 1.5em
      line-height: 130%

    .item-calories
      font-size: 1.5em
      line-height: 130%
      margin-left: 0.8em
      margin-right: 0.2em
      opacity: 0.6

    .item-price
      display: flex
      flex-flow: row nowrap
      justify-content: flex-end
      align-items: center
      white-space: nowrap

      .price-block
        display: flex
        flex-flow: column nowrap
        justify-content: flex-start
        align-items: center
        text-align: center
        margin-left: 0.8em
        opacity: 0.6

        .price-value
          font-size: 1.5em
          line-height: 130%

        .price-label
          font-size: 0.85em
          line-height: 110%
          padding-bottom: 0.1em

  .description
    opacity: 0.6
    font-size: 1.2em
    line-height: 120%
    padding: 0.3em 4em 0.1em 0

    &.show-label
      margin-top: -0.75em
      padding-right: 8em
</style>
