<script>
import { mapGetters } from 'vuex'

import { EventBus } from 'services/eventbus.js'

export default {
  name: 'InteractiveMenuPinCode',

  data () {
    return {
      code: '',

      codeErrored: false,
      errorTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'deviceMenuPinCode'
    ]),

    pinCodeLen () {
      return (this.deviceMenuPinCode || '').length
    },

    inputLen () {
      return (this.code || '').length
    }
  },

  beforeDestroy () {
    clearTimeout(this.errorTimer)
  },

  methods: {
    closeCodePanel () {
      this.$store.commit('updateShowPinCode', false)
      this.code = ''
      this.codeErrored = false
    },

    input (value) {
      EventBus.$emit('user-interacted')

      if (this.inputLen >= this.pinCodeLen) { return }
      this.code += `${value}`

      if (this.inputLen === this.pinCodeLen) {
        const isValid = this.verifyInput()
        if (isValid) {
          EventBus.$emit('menu-pin-code-verified')
          this.closeCodePanel()
        } else {
          this.codeErroredHint()
        }
      }
    },

    cancel () {
      this.closeCodePanel()
      this.code = ''
    },

    backspace () {
      EventBus.$emit('user-interacted')

      if (!this.code.length) { return }
      this.code = this.code.substr(0, this.code.length - 1)
    },

    verifyInput () {
      return Boolean(this.code.length && this.code === this.deviceMenuPinCode)
    },

    codeErroredHint () {
      clearTimeout(this.errorTimer)

      this.codeErrored = true

      this.errorTimer = setTimeout(() => {
        clearTimeout(this.errorTimer)
        this.code = ''
        this.codeErrored = false
      }, 1000)
    }
  }
}
</script>

<template lang="pug">
section.interactive-menu-pin-code
  h3.pin-code-title Enter PIN Code

  .pin-code-indicator
    .code-slot(v-for="n in pinCodeLen", :key="n"
               :class="{filled: n <= inputLen, errored: codeErrored}")
      fa.fa-icon(icon="star-of-life" fixed-width)

  .code-buttons-container
    .row
      button.key-button(@click="input(1)", :disabled="codeErrored") 1
      button.key-button(@click="input(2)", :disabled="codeErrored") 2
      button.key-button(@click="input(3)", :disabled="codeErrored") 3
    .row
      button.key-button(@click="input(4)", :disabled="codeErrored") 4
      button.key-button(@click="input(5)", :disabled="codeErrored") 5
      button.key-button(@click="input(6)", :disabled="codeErrored") 6
    .row
      button.key-button(@click="input(7)", :disabled="codeErrored") 7
      button.key-button(@click="input(8)", :disabled="codeErrored") 8
      button.key-button(@click="input(9)", :disabled="codeErrored") 9
    .row
      button.key-button.secondary(@click="cancel", :disabled="codeErrored")
        fa.fa-icon(icon="times" fixed-width)
      button.key-button(@click="input(0)", :disabled="codeErrored") 0
      button.key-button.secondary(@click="backspace", :disabled="codeErrored")
        fa.fa-icon(icon="backspace" fixed-width)

</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section.interactive-menu-pin-code
  font-size: 2vmin

  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 9001
  overflow: hidden

  background: -black(0.85)
  color: var(--gray-light)

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center

  .pin-code-title
    color: #fff
    font-size: 2.2em
    margin: 0

  .pin-code-indicator
    padding: 1em 0 2em 0
    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: stretch

    .code-slot
      border-bottom: 3px solid #ddd
      margin: 0 0.8em
      padding: 0.4em 0.7em
      text-align: center
      transition: border-color .1s

      .fa-icon
        font-size: 1.5em
        opacity: 0
        transition: opacity .1s, color .1s

      &.filled
        .fa-icon
          opacity: 1

      &.errored
        border-color: var(--brand-danger)
        color: var(--brand-danger)

  button.key-button
    display: inline-block
    outline: 0
    line-height: 120%
    cursor: pointer

    // Preserve pointer event on <button>
    pointer-events: initial
    // Remove the highlight blue mask on tap
    -webkit-tap-highlight-color: transparent

    font-size: 2em

    color: #fff
    border: 2px solid #fff
    background: -white(0.15)
    width: 4em
    padding: 0.3em 0
    margin: 0 0.25em
    text-align: center
    border-radius: 0.3em

    transition: background-color .2s

    &:disabled
      opacity: 0.5
      cursor: default

    &.secondary
      border-color: transparent
      background: -white(0.1)

    &:active:not(:disabled)
      background: -white(0.35)

  .code-buttons-container
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center

    .row
      display: flex
      flex-flow: row nowrap
      justify-content: center
      align-items: stretch
      margin: 0.5em 0

</style>
