<script>
// MIXIN
import WeatherMixins from './WeatherMixins.vue'

import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'
import Weather from 'services/weather/index.js'

import WeatherIcon from './WeatherIcon.vue'
import Clock from 'components/widgets/Clock.vue'

export default {
  name: 'WeatherDefault',

  components: { Clock, WeatherIcon },

  // MIXIN
  // All common functions were moved there
  mixins: [WeatherMixins],

  // Inject config from ancestor component `WeatherItem.vue`
  inject: ['config'],

  props: {
    // Trigger delayed transitions
    show: { type: Boolean, default: false },

    mdyFormat: { type: Boolean, default: false },
    location: { type: String, required: true },
    locationDisplayName: { type: String, default: '' },
    showHourly: { type: Boolean, default: false },
    showDaily: { type: Boolean, default: false },
    show24hr: { type: Boolean, default: false },

    whiteText: { type: Boolean, default: false },
    showTextShadow: { type: Boolean, default: false },
    hideBox: { type: Boolean, default: false },
    hideBoxOutline: { type: Boolean, default: false },

    isPortrait: { type: Boolean, default: false },
    isWide: { type: Boolean, default: false },
    isMedium: { type: Boolean, default: false },
    isNarrow: { type: Boolean, default: false },
    isTall: { type: Boolean, default: false },

    temperatureUnit: { type: String, default: '' },
    fontClass: { type: String, default: '' },

    baseFontSize: {
      type: [Number, Object],
      default: undefined
    },

    fontScale: { type: Number, default: 1.0 },
    titleFontScale: { type: Number, default: 1.0 }
  },

  data () {
    return {
      refreshInterval: undefined
    }
  },

  computed: {
    iconTheme () {
      return this.whiteText ? 'dark' : 'light'
    },

    noForecasts () {
      return !this.showDaily && !this.showHourly
    },

    showAllForecasts () {
      return this.showDaily && this.showHourly
    },

    scaledFontSizeStyle () {
      if (!this.baseFontSize) { return }
      return {
        fontSize: `${this.fontScale || 1.0}em`
      }
    },

    scaledTitleFontSizeStyle () {
      if (!this.baseFontSize) { return }
      return {
        fontSize: `${this.titleFontScale || 1.0}em`
      }
    },

    locationText () {
      if (!this.weather) { 
        return ''
      } else if (this.weather.state) {
        return `${this.weather.location}, ${this.weather.state}, ${this.weather.country}`
      }
      return `${this.weather.location}, ${this.weather.country}`
    }
  },

  watch: {
    // [DEV-1743] add display unit options
    temperatureUnit () {
      this.updateWeather()
    },

    location (val) {
      if (!val) { return }
      this.updateWeather()
    },

    showHourly (toShow) {
      if (toShow) {
        this.updateWeather()
      }
    },

    showDaily (toShow) {
      if (toShow) {
        this.updateWeather()
      }
    },

    // [DEV-1869] Weather Localization
    localeLangKey () {
      this.updateWeather()
    }
  },

  mounted () {
    this.loading = true
    this.updateWeather()

    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.hasError = false
      this.loading = true
      this.updateWeather()
    }, this.config.REFRESH_INTERVAL * 1000)
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },

  methods: {
    updateWeather (useFahrenheit) {
      this.unit = useFahrenheit ? 'f' : 'c'

      const location = this.location
      if (!location) {
        if (this.show) {
          Log.warn('app', 'Weather app: Unable to determine location', 'WAR_WEATHERAPPNOLOC2')
        }
        this.loaded()
        return
      }

      const locationStr = location.replace(/(\s|,|')+/g, '-').toLowerCase()
      let strSuffix = ''
      if (this.showHourly) {
        strSuffix += '+hourly'
      }
      if (this.showDaily) {
        strSuffix += '+daily'
      }
      this.locationKey = `weather_page_${locationStr}${strSuffix}`

      const promises = [
        Weather.current(location, useFahrenheit, this.localeLangKey)
      ]

      // Make queries only when required
      if (this.showHourly) {
        promises.push(Weather.hourlyForecast(location, useFahrenheit, this.localeLangKey))
      }
      if (this.showDaily) {
        promises.push(Weather.dailyForecast(location, useFahrenheit, this.localeLangKey))
      }

      this.timeoutChecking()

      Promise.all(promises)
        .then(results => {
          clearTimeout(this.timeoutTimer)

          const currentWeather = results[0]

          // [DEV-1743] add display unit options
          const needsFahrenheit = this.needsFahrenheit(currentWeather)
          const isUseFahrenheit =
          (needsFahrenheit && this.unit !== 'f' && this.temperatureUnit !== 'c') ||
          (!needsFahrenheit && this.unit !== 'f' && this.temperatureUnit === 'f')
          if (isUseFahrenheit) {
            this.updateWeather(true)
            return
          }

          let forecastHourly
          let forecastWeather
          if (this.showHourly && this.showDaily) {
            forecastHourly = results[1]
            forecastWeather = results[2]
          } else if (this.showHourly) {
            forecastHourly = results[1]
          } else if (this.showDaily) {
            forecastWeather = results[1]
          }

          this.hasError = false
          this.skipReload = true
          this.errorMessage = ''

          const weather = currentWeather

          const units = {
            unit: this.unit
          }
          // AccuWeather contains units in the result
          if (currentWeather.units) {
            units.speed = currentWeather.units.wind
            units.temperature = currentWeather.units.temp
            units.pressure = currentWeather.units.pressure
          } else {
            units.speed = this.unit === 'f' ? 'mi/h' : 'm/s'
            units.temperature = this.unit === 'f' ? 'F' : 'C'
            units.pressure = 'hPa'
          }
          this.units = units

          if (currentWeather.timezone && currentWeather.timezone.Name) {
            // AccuWeather will return timezone name in data
            this.updateDateTimeInfo(currentWeather, this.mdyFormat)
          }

          // SHOW DAILY FORECAST
          if (this.showDaily) {
            const forecastData = []
            // - Start forcasting from tommorrow (0 = today)
            for (let idx = 1; idx <= this.config.DAYS_TO_SHOW; idx++) {
              const day = forecastWeather[idx]
              if (day) {
                forecastData.push(day)
              }
            }
            weather.forecast = forecastData
          }

          // SHOW HOURLY FORECAST
          if (this.showHourly) {
            const localTimeNow = this.getLocalTimestamp()
            const forecastHourlyData = []

            for (let j = 0; j < forecastHourly.length; j++) {
              const fc = forecastHourly[j]
              // Hide elapsed data
              if (fc.time > localTimeNow) {
                forecastHourlyData.push(fc)
              }
              if (forecastHourlyData.length >= 7) { break }
            }
            weather.forecastHourly = forecastHourlyData
          }

          this.weather = weather
          this.loaded()

          // Store weather data
          const weatherData = JSON.parse(JSON.stringify(this.weather))
          weatherData.timestamp = new Date().getTime()
          weatherData.src = 'aw'
          OfflineCaches.set(this.locationKey, weatherData)
        })
        .catch(err => {
          clearTimeout(this.timeoutTimer)
          this.handleError(err)
        })
    }
  }
}
</script>

<template lang="pug">
.default-weather-wrapper(:class="[fontClass, {'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-medium': isMedium, 'is-wide': isWide, 'is-narrow': isNarrow, 'is-tall': isTall}]")
  .weather-informations.app-context-block(:class="{'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !weather, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}")
    template(v-if="weather")

      //- LOCATION AND DATETIME
      .location-infos.app-context-section.secondary(:class="{'border-bottom': !isWide}" :style="scaledTitleFontSizeStyle")
        .location
          .city(v-if="locationDisplayName") {{ locationDisplayName }}
          .city(v-else) {{locationText}}

        .time-n-date
          .current-date
            .date(v-if="datetimeInfo") {{datetimeInfo}}
            clock(v-if="datetimeInfo", :timezone="timezone", :hours="show24hr ? '24H' : '12H'")

      //- MAIN SECTION
      .main-section.app-context-section.primary
        //- Current Weather
        .current-and-recent
          .current(:class="{'no-forecasts': noForecasts, 'all-forecasts': showAllForecasts}", :style="scaledFontSizeStyle")
            .temp-n-condition(:class="{'smaller-size': weatherTemp >= 100}")
              .temp
                transition(name="slideLeft" appear)
                 .inner.delay-500(v-if="show && weather && units") {{weatherTemp}}°{{units.temperature}}

              .current-condition
                transition(name="slideLeft" appear)
                  .inner.delay-500(v-if="show && weather && weather.description") {{weather.description}}

            .current-icon
              //- weather-icon.weather-icon(:code="weather.code", :daynight="weather.daynight", :theme="iconTheme" use-animated-sets)
              weather-icon.weather-icon(:code="weather.code", :daynight="weather.daynight", :theme="iconTheme" use-png-sets)

            .meta
              .item.wind
                transition(name="slideRight" appear)
                  .inner.delay-700(v-if="show &&weather && weather.wind")
                    .label-text {{ $t('pageItems.weather.wind') }}
                    .content {{windDirection(weather)}} {{windSpeed(weather)}}{{units.speed}}
              .item.humidity
                transition(name="slideRight" appear)
                  .inner.delay-900(v-if="show && weather && weather.time")
                    .label-text {{ $t('pageItems.weather.humidity') }}
                    .content {{ weather.humidity }}%
              .item.visibility
                transition(name="slideRight" appear)
                  .inner.delay-1100(v-if="show && weather && weather.pressure")
                    .label-text {{ $t('pageItems.weather.pressure') }}
                    .content {{ pressure(weather) }}{{units.pressure}}

      //- Hourly Forecase
      .forecast-3hrs.app-context-section(v-if="showHourly && weather.forecastHourly"
                    :class="{'primary': !isWide, 'secondary': isWide, 'border-left': isWide}", :style="scaledFontSizeStyle")
        .item(v-for="(item, index) in weather.forecastHourly", :key="index")
          transition(name="fadeDown" appear)
            .inner(v-if="show && weather && weather.forecastHourly", :class="delayAnimationClass(1200, index, 100)")
              .hour {{ getHour(item.time) }}
              //- weather-icon.weather-icon(:code="item.code", :daynight="item.daynight", :theme="iconTheme" use-animated-sets)
              weather-icon.weather-icon(:code="item.code", :daynight="item.daynight", :theme="iconTheme" use-png-sets)
              .temp {{item.temp}}°{{units.temperature}}

      //- DAILY FORECASE
      .daily-forecasts.app-context-section.secondary(v-if="showDaily && weather.forecast"
                      :class="{'border-top': !isWide, 'border-left': isWide}")
        .day(v-for="(day, index) in weather.forecast", :key="index")
          transition(name="fadeUpBig" appear)
            .inner(v-if="show && weather && weather.forecast", :class="delayAnimationClass(2000, index, 200)")
              .dow
                | {{ getWeekday(day.time) }}
              .temps
                | {{ parseTemp(day.temp.min) }} ~ {{ parseTemp(day.temp.max) }}°{{units.temperature}}
              //- weather-icon.weather-icon(:code="day.code", :theme="iconTheme" use-animated-sets)
              weather-icon.weather-icon(:code="day.code", :theme="iconTheme" use-png-sets)

  .messages
    .loading(v-if="!weather && loading") {{ $t('pageItems.weather.hintLoadingForecast') }} {{locationText}}
    .error-message(v-if="hasError && !loading" v-text="errorMessage")
    .retry-in(v-if="showRetryMessage && retryInSeconds") {{ $t('pageItems.weather.hintRetryIn', {retryInSeconds}) }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.default-weather-wrapper
  position: absolute
  z-index: 1
  left: 0
  right: 0
  bottom: 0
  top: 0

  //
  // MESSAGES
  //

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    font-size: 2em

    .error-message,
    .loading
      background-color: transparent !important

    .loading
      opacity: 0.7
      color: var(--gray)
      text-align: center
      padding: 3% 3% 0 0

    .error-message
      text-align: center
      padding: 20% 1em 0 1em
      font-size: 2.5em

    .retry-in
      text-align: center
      padding-top: 2em

  //
  // WEATHER INFOMATIONS
  //

  .weather-informations
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 2

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    .location-infos
      padding: 1.5em 2.5em
      .location
        display: flex
        flex: 1 1 0.00001px
        position: relative
        margin-bottom: 1em
        width: 100%

        .city
          font-size: 3em
          line-height: 120%
          font-weight: 600

     .time-n-date
        .current-date
          min-height: 1em
          display: flex
          flex-flow: row
          align-items: center
          font-size: 2em

        .date
          padding-right: 0.8em
          font-weight: 300

        .simple-clock
          font-size: inherit
          color: inherit
          align-items: flex-start
          .time-wrapper
            font-size: 1em
            font-weight: 300
            .apm
              font-weight: 300

    .main-section
      flex: 1 1 0.00001px
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: stretch
      padding: 1.5em 3.5em

      .current-and-recent
        flex: 1 1 0.00001px
        display: flex
        flex-flow: row nowrap
        justify-content: space-between
        align-items: center

      // CURRENT WEATHER

      .current
        flex: 1 1 0.00001px
        display: flex
        flex-flow: row nowrap
        justify-content: space-between
        align-items: center
        position: relative

        // Bump font-size when all forecasts are disabled
        &.no-forecasts
          .temp-n-condition,
          .meta
            font-size: 1.3em
          .current-icon
            font-size: 1.1em

        .wi
          display: inline-block
          font-size: 22em

        .current-icon
          // To make sure the weather icon is absolutely centered
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0

          display: flex
          flex-flow: row nowrap
          justify-content: center
          align-items: center
          text-align: center

        .temp-n-condition
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: flex-start
          max-width: 40%

          .temp
            font-size: 8em
            overflow: hidden

          &.smaller-size
            .temp
              font-size: 6em

          .current-condition
            font-size: 4.5em
            text-transform: capitalize
            overflow: hidden
            .inner
              line-height: 130%

        .meta
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: flex-end
          text-align: right
          max-width: 40%

          *
            line-height: 100%

          .item
            position: relative
            overflow: hidden
            margin-top: 1.5em
            white-space: nowrap

            &:first-child
              margin-top: 0

          .label-text
            text-transform: uppercase
            font-size: 1.2em
            opacity: 0.7
            padding-bottom: 0.2em

          .content
            font-size: 2em
            line-height: 130%

    // 3 HOURS FORECAST

    .forecast-3hrs
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: flex-end
      padding: 0 2em 1.5em 2em

      .item
        display: flex
        flex-flow: row nowrap
        align-items: center
        justify-content: center

        .inner
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: center
          text-align: center

        .hour
          font-size: 1.6em
          opacity: 0.7

        .temp
          font-size: 2em

        .weather-icon
          font-size: 4em
          margin: 0 0.5em

    // 5 DAYS FORECAST
    .daily-forecasts
      padding: 1.5em 3em
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: flex-start
      overflow: hidden

      .day
        display: flex
        flex-flow: row nowrap

        .inner
          flex: 1 1 0.00001px
          display: flex
          flex-flow: column nowrap
          justify-content: center
          align-items: center
          text-align: center

        .dow
          font-size: 1.8em
          line-height: 110%
          text-transform: uppercase
          opacity: 0.7
          margin-bottom: 0.1em
          font-weight: bold

        .temps
          font-size: 2em
          line-height: 110%
          margin-bottom: 0.05em

        .weather-icon
          font-size: 4em
          line-height: 130%

  // ==========
  // PORTRAIT
  // ==========
  &.is-portrait
    .weather-informations
      .main-section
        // CURRENT WEATHER
        .current
          flex-flow: column nowrap
          justify-content: center
          align-items: center
          text-align: center

          .current-icon
            position: relative

          .temp-n-condition
            align-self: stretch
            flex-flow: column nowrap
            justify-content: center
            align-items: center
            max-width: none

          .meta
            max-width: none
            flex-flow: row wrap
            align-items: center
            text-align: center
            .item
              margin: 0.5em 1.5em !important
            .label-text
              font-size: 3em
            .content
              font-size: 4em

      // 3 HOURS FORECAST
      .forecast-3hrs
        flex-flow: row wrap
        align-content: flex-end
        .item
          padding-bottom: 2em
          .hour
            font-size: 3.5em
          .weather-icon
            font-size: 7em
          .temp
            font-size: 4em

      // 5 DAYS FORECAST
      .daily-forecasts
        flex-flow: row wrap
        .day
          padding-top: 2em
          .dow
            font-size: 3em
            margin-bottom: 0.3em
          .temps
            font-size: 4em
          .weather-icon
            font-size: 10em

  // ==========
  // TALL
  // ==========
  &.is-tall
    .weather-informations
      .main-section
        // CURRENT WEATHER
        .current
          .meta
            flex-flow: column nowrap
            .item
              margin: 2em 0.5em !important

      // 3 HOURS FORECAST
      .forecast-3hrs
        .item
          flex: none
          width: 33%
          padding-bottom: 3.5em

      // 5 DAYS FORECAST
      .daily-forecasts
        .day
          flex: none
          width: 50%
          padding-top: 3.5em

  // ==========
  // WIDE
  // ==========
  &.is-wide
    .weather-informations
      flex-flow: row nowrap

      .location-infos
        width: 15%
        padding: 1em 1.5em
        display: flex
        flex-flow: column nowrap
        justify-content: center
        align-items: flex-start
        .location
          flex: none
          padding-bottom: 0.5em
        .time-n-date
          .current-date
            font-size: 2em

      .main-section
        flex-flow: row nowrap
        padding: 1em 2.5em

        // CURRENT WEATHER
        .current
          .wi
            font-size: 14.4em
          .temp
            font-size: 6.4em
          .current-icon
            position: relative
          .temp-n-condition
            &.smaller-size
              .temp
                font-size: 5.2em
          .current-condition
            font-size: 4em
          .meta
            .item
              margin-top: 0.8em
              &:first-child
                margin-top: 0
            .label-text
              font-size: 1.12em
            .content
              font-size: 1.6em

      // 3 HOURS FORECAST
      .forecast-3hrs
        // Bump font-size
        font-size: 130%
        width: 20%
        padding: 0.75em
        flex-flow: row wrap
        align-content: center
        justify-content: center
        .item
          width: 30%
          padding: 1.5em 0.5em
          font-size: 0.7em
          .weather-icon
            font-size: 6em

      // 5 DAYS FORECAST
      .daily-forecasts
        // Bump font-size
        font-size: 130%
        width: 20%
        padding: 0.75em
        flex-flow: row wrap
        justify-content: center
        align-items: center
        align-content: center

        .day
          width: 48%
          padding: 1.2em 0.5em
          font-size: 0.6em
          .weather-icon
            font-size: 8em

    // ==========
    // NARROW WIDE
    // ==========
    &.is-narrow
      .weather-informations
        .main-section
          // CURRENT WEATHER
          .current
            .temp-n-condition
              max-width: 45%
            .temp
              font-size: 8em
            .current-icon
              .weather-icon
                font-size: 20em
            .meta
              max-width: 45%
              .item
                margin-top: 2.5em
              .label-text
                font-size: 2.3em
              .content
                font-size: 3em

            // When NOT showing all forecase (hourly + daily)
            // Horizontal align contents
            &:not(.all-forecasts)
              .temp-n-condition
                font-size: 1.5em
                flex-flow: row nowrap
                align-items: center
                .current-condition
                  margin-left: 0.5em
              .temp
                font-size: 4em
                font-weight: 600
              .current-icon
                .weather-icon
                  font-size: 18em
              .meta
                flex-flow: row nowrap
                .item
                  margin-top: 0
                  margin-left: 4.5em
                .label-text
                  font-size: 3em
                .content
                  font-size: 3.8em

        // 3 HOURS FORECAST
        .forecast-3hrs
          font-size: 160%
          .item
            font-size: 1.2em
            .weather-icon
              font-size: 5em

        // 5 DAYS FORECAST
        .daily-forecasts
          font-size: 160%
          .day
            padding: 1.5em 0.5em
    &.is-narrow.is-wide
      .weather-informations
        .main-section
          // CURRENT WEATHER
          .current
            &:not(.all-forecasts)
              .meta
                .item
                  margin-left: 2.5em
        .daily-forecasts
          .day
            padding: 0
</style>
