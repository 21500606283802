<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import moment from 'moment'

import 'moment-timezone'
import FastDom from 'fastdom'

import {
  mapGetters
} from 'vuex'

import AmadeusItem from './amadeus/Item'

import Amadeus from 'services/amadeus.js'

import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'
import { isArray } from 'lodash'

const config = {

  // In vmin
  BASE_FONTSIZE: 5,

  // Wide Screen Width/Height ratio
  IS_WIDE: 3,

  // Default to Month/Day
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DDTh:mm:ss',
  // Optional Day/Month format
  DAY_MONTH_FORMAT: 'dddd Do MMMM',

  // In ms. Transition Duration for current active event
  TRANSITION_TIME: 1500,

  // Limit maximum valid items, to prevent overwhelm the DOM
  MAX_ITEMS_COUNT: 20,

  // Parse the next ${n}th occurance
  REPEAT_DAY_COUNT: 14,
  REPEAT_WEEK_COUNT: 4,
  REPEAT_MONTH_COUNT: 3,
  REPEAT_YEAR_COUNT: 2,

  // Default repeat count for MINUTELY | SECONDLY from iCal
  REPEAT_DEFAULT_COUNT: 10
}

// Need to enable flex nowrap when default column is selected
const DEFAULT_COLUMN_LIMIT = 3

export default {
  name: 'AmadeusPage',
  components: {
    AmadeusItem
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      title: '',
      events: [],
      validEvents: [],
      organizations: [],
      rooms: [],

      hideLocation: false,

      // Date Format
      dateFm: config.DEFAULT_DATE_FORMAT + '',
      // Use 24H Clock
      format24h: false,

      fixedFontSize: false,
      fontSizeScale: undefined,

      timeframe: 'any',
      timeframe_day: undefined,

      vAlign: 'middle',
      hAlign: 'left',

      // Actual width/height
      itemSize: {},

      renderingTimer: undefined,
      debounceTimer: undefined,
      debounceUpComing: undefined,
      tickTimer: undefined,
      paginationTimer: undefined,
      usePadding: true,
      wrapEvents: false,
      pagination: {
        enabled: false,
        first: true, // It used to determine whether all pages are shown
        interval: null,
        lastEventIndex: 0,
        currentPage: 1,
        pageCount: 1,
        maxPageCount: 1
      },
      loading: true,
      rendering: true,
      error: '',
      columnLimit: -1,
      boxVAlign: 'left',
      boxHAlign: 'top'
    }
  },

  computed: {
    ...mapGetters([
      'accountLocale',
      'contentForURL'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    isWideScreen () {
      if (!this.itemSize || !this.itemSize.h || !this.itemSize.w) { return false }
      return (this.itemSize.w / this.itemSize.h) >= config.IS_WIDE
    },

    upcomingEvents () {
      if (!this.validEvents || !this.validEvents.length || (!this.dontFeatureNext && this.validEvents.length === 1)) { return [] }
      return this.dontFeatureNext ? this.validEvents : this.validEvents.slice(1)
    },

    hasUpcomingEvents () {
      return Boolean(this.upcomingEvents && this.upcomingEvents.length)
    },

    useRestrictTime () {
      return this.timeframe === 'restrict'
    },

    restrictInDays () {
      if (this.useRestrictTime) {
        return Math.max(0, +this.timeframe_day || 0)
      }
    },

    noValidEvents () {
      return Boolean(!this.validEvents || !this.validEvents.length)
    },

    listIsEmpty () {
      return Boolean(this.noValidEvents || !this.validEventsByDate || this.validEventsByDate.length === 0)
    },

    validEventsByDate () {
      if (this.noValidEvents) { return [] }
      const dates = []
      const groupedList = []
      const now = moment()
      const dateTomorrow = now.clone().add(1, 'days').format('MM/DD/YYYY')
      const In7Days = now.clone().add(6, 'days')

      let eventList
      if (this.pagination.enabled) {
        if (this.validEvents.length > (this.pagination.lastEventIndex + 1)) {
          eventList = this.validEvents.slice(this.pagination.lastEventIndex)
        } else {
          eventList = this.validEvents
          const pagination = JSON.parse(JSON.stringify(this.pagination))
          pagination.lastEventIndex = 0
          pagination.pageCount = 1
          pagination.maxPageCount = 1
          this.pagination = pagination
        }
      } else {
        eventList = this.validEvents
      }

      eventList.forEach(event => {
        const eventDate = moment(event.StartDateTime, 'YYYY-MM-DD')
        let date = ''
        if (eventDate.isSameOrBefore(now, 'day')) {
          date = this.$t('_common.time.today')
        } else if (event.date === dateTomorrow) {
          date = this.$t('_common.time.tomorrow')
          // In 7 days. Show Day of Week
        } else if (eventDate.isSameOrBefore(In7Days, 'day')) {
          date = eventDate.format('dddd')
        } else {
          // e.g. TUESDAY MARCH 23rd
          date = eventDate.format(this.dateFm)
        }
        if (!dates.includes(date)) {
          dates.push(date)
          groupedList.push({
            date,
            items: []
          })
        }
        const gIndex = groupedList.findIndex(gp => gp.date === date)

        if (gIndex >= 0) {
          if (!groupedList[gIndex].items) {
            groupedList[gIndex].items = []
          }
          groupedList[gIndex].items.push(event)
        }
      })
      return groupedList
    },

    upcomingList () {
      if (!this.validEventsByDate || !this.validEventsByDate.length) { return [] }
     
      if (!this.wrapEvents && this.columnLimit > 0) {
        return this.validEventsByDate.slice(0, this.columnLimit)
      }
      return this.validEventsByDate
    },

    colorLabelWidth () {
      if (!this.isPortrait || !this.baseFontSize) { return }
      return `${this.baseFontSize * 0.16}px`
    },

    appID () {
      return this.item && this.item.ref_id
    },

    localKey () {
      if (this.appID && this.appID.length) {
        // Use the AppID as localStorage key
        return `ttvAmadeus_${this.appID}`
      }
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    boxHorzClass () {
      if (!this.boxHAlign || !this.boxHAlign.length) { return }
      return `box-horz-${this.boxHAlign}`
    },

    boxVertClass () {
      if (!this.boxVAlign || !this.boxVAlign.length) { return }
      return `box-vert-${this.boxVAlign}`
    },

    weHorzClass () {
      if (!this.wrapEvents) {
        return ''
      }
      if (!this.boxHAlign || !this.boxHAlign.length) { return }
      return `wrapped-events-box-horz-${this.boxHAlign}`
    },

    weVertClass () {
      if (!this.wrapEvents) {
        return ''
      }
      if (!this.boxVAlign || !this.boxVAlign.length) { return }
      return `wrapped-events-box-vert-${this.boxVAlign}`
    },

    defaultColLimitClass () {
      if (this.wrapEvents) {
        return 'wrapped-events'
      } else if (this.columnLimit !== DEFAULT_COLUMN_LIMIT) {
        return 'no-default-col'
      }
      return 'default-col'
    },

    bigFontCell () {
      if (this.wrapEvents) {
        return ''
      }
      switch (this.columnLimit) {
        case 1:
          return this.usePadding ? 'big-one-cell' : 'big-one-cell-without-padding'
        case 2:
          return this.usePadding ? 'big-two-cells' : 'big-two-cells-without-padding'
        default:
          return 'three-cells'
      }
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },
    upcomingList: {
      deep: true,
      handler () {
        this.debounceCheckUpcomingZone()
      }
    }
  },

  mounted () {
    clearTimeout(this.renderingTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceUpComing)
    clearInterval(this.paginationTimer)
    // For pagination
    this.$set(this.pagination, 'lastEventIndex', 0)

    // Show stale data first to speed up app display
    this.getStaleData(true)

    this.debounceCheckSize()
    this.debounceCheckUpcomingZone()
    this.render()

    clearInterval(this.tickTimer)
    this.tickTimer = setInterval(() => {
      this.checkValidEvents()
    }, 2000)
  },

  beforeDestroy () {
    clearTimeout(this.renderingTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceUpComing)
    clearInterval(this.tickTimer)
    clearInterval(this.paginationTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })
      this.title = options.title || ''

      this.hideLocation = (options.hideLocation === true || options.hideLocation === 'true')
      this.fixedFontSize = (options.fixedFontSize === true || options.fixedFontSize === 'true')
      this.fontSizeScale = options.fontScale || ''

      this.dateFm = options.dateFm || (config.DEFAULT_DATE_FORMAT + '')
      this.format24h = (options.format24h === true || options.format24h === 'true')

      this.timeframe = options.timeframe || 'any'
      this.timeframe_day = options.timeframe_day || ''

      this.hAlign = options.hAlign || ''
      this.vAlign = options.vAlign || ''

      this.organizations = options.organizations || []
      this.rooms = options.rooms || []
      if (options.columnLimit) {
        this.columnLimit = parseInt(options.columnLimit)
      }
      if (options.usePadding) {
        this.usePadding = options.usePadding === true || options.usePadding === 'true'
      } else {
        this.usePadding = false
      }
      if (options.wrapEvents) {
        this.wrapEvents = options.wrapEvents === true || options.wrapEvents === 'true'
      } else {
        this.wrapEvents = false
      }
      const pagination = JSON.parse(JSON.stringify(this.pagination))
      if (options.enablePagination) {
        pagination.enabled = options.enablePagination === true || options.enablePagination === 'true'
        pagination.lastEventIndex = 0
        pagination.currentPage = 1
        pagination.pageCount = 1
        pagination.maxPageCount = 1
        pagination.first = true
        if (options.paginationInterval) {
          pagination.interval = options.paginationInterval
        } else {
          pagination.interval = 15
        }
        clearInterval(this.paginationTimer)
        this.paginationTimer = undefined
        this.paginationTimer = setInterval(() => {
          this.changePage()
        }, pagination.interval * 1000)
      } else {
        pagination.enabled = false
        pagination.interval = null
        clearInterval(this.paginationTimer)
        this.paginationTimer = undefined
      }
      this.pagination = pagination
      this.boxHAlign = options.boxHAlign || ''
      this.boxVAlign = options.boxVAlign || ''
      
      this.fetchEvents(options)
    },

    changePage() {
      this.rendering = true
      if (this.$refs.upcomings && isArray(this.$refs.upcomings) && this.$refs.upcomings.length > 0) {
        let totalEventsInScreen = 0
        this.$refs.upcomings.forEach(list => {
            if (list.classList && list.classList.contains('invisible')) {
              return
            }
            for (let child of list.children) {
              if (child.className !== 'events-in-group') {
                continue
              }
              totalEventsInScreen += child.childElementCount
            }
        });
        const pagination = JSON.parse(JSON.stringify(this.pagination))
        pagination.lastEventIndex = pagination.lastEventIndex + totalEventsInScreen
        if ((pagination.lastEventIndex + 1) >= this.validEvents.length) {
          pagination.lastEventIndex = 0
          pagination.currentPage = 1
          pagination.pageCount = 1
          pagination.first = false
        } else if (pagination.pageCount < pagination.maxPageCount || pagination.first) {
          pagination.pageCount = pagination.pageCount + 1
          pagination.currentPage = pagination.currentPage + 1
          if (pagination.first) {
            pagination.maxPageCount = pagination.pageCount
          }
        } else {
          pagination.currentPage = pagination.currentPage + 1
        }
        this.pagination = pagination
      }
      clearTimeout(this.renderingTimer)
      this.renderingTimer = setTimeout(() => this.rendering = false, 300)
    },

    async fetchEvents (params) {
      this.rendering = true
      try {
        const token = await Amadeus.getToken(params)
        let events = await Amadeus.getEvents({
          'subscriptionKey': params.subscriptionKey,
          'accessToken': token.access_token,
          'locationId': params.locationId,
          'maximumEvents': params.maximumEvents
        })
        if (events && events.length > 0) {
          const now = moment()
          events = events.filter(event => {
            if (this.organizations.length > 0 && this.organizations.indexOf(event.AccountName) === -1) {
              return false
            }
            if (this.rooms.length > 0 && this.rooms.indexOf(event.FunctionRoomName) === -1) {
              return false
            }
            const eventDate = moment(event.StartDateTime)
            return eventDate.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') || now.diff(eventDate, 'days') <= 0
          })
          events.sort((a, b) => {
            if (moment(a.StartDateTime, 'YYYY-MM-DD').toString() === moment(b.StartDateTime, 'YYYY-MM-DD').toString()) {
              const isAAllDay = moment(a.StartDateTime).diff(moment(a.EndDateTime), 'hours') === 24
              const isBAllDay = moment(a.StartDateTime).diff(moment(a.EndDateTime), 'hours') === 24

              // non-all-day > all-day
              if (isAAllDay && !isBAllDay) { return -1 }
              if (isBAllDay && !isAAllDay) { return 1 }
            }
            const diff = moment(a.StartDateTime).diff(moment(b.StartDateTime), 'seconds')

            if (diff === 0) {
              return 0
            } else if (diff > 0) {
              return 1
            }
            return -1
          })
        }
        this.validEvents = events
        if (this.localKey) {
          OfflineCaches.set(this.localKey, this.validEvents)
        }

        this.loaded()
        clearTimeout(this.renderingTimer)
        this.renderingTimer = setTimeout(() => this.rendering = false, 300)
      } catch (e) {
        this.getStaleData()
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0) {
          this.error = e.response.data.errors[0]
        } else {
          this.error = e
        }
        Log.debug('app', `Amadeus events fetch error - ${this.error}`, 'DBG_AMADEUSFETCH', {})
        clearTimeout(this.renderingTimer)
        this.renderingTimer = setTimeout(() => this.rendering = false, 300)
      }
    },

    checkValidEvents () {
      if (!this.validEvents || this.validEvents.length === 0) { return }
      const now = moment()
      this.validEvents = this.validEvents.filter(event => {
        if (this.organizations.length > 0 && this.organizations.indexOf(event.AccountName) === -1) {
          return false
        }
        if (this.rooms.length > 0 && this.rooms.indexOf(event.FunctionRoomName) === -1) {
          return false
        }
        const eventDate = moment(event.StartDateTime)
        return eventDate.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') || now.diff(eventDate, 'days') <= 0
      }) || []
    },

    debounceCheckUpcomingZone (timeout) {
      clearTimeout(this.debounceUpComing)
      this.debounceUpComing = setTimeout(() => {
        clearTimeout(this.debounceUpComing)
        this.checkUpcomingZone()
      }, timeout || 200)
    },

    checkUpcomingZone () {
      if (!this.$refs || !this.$refs.upcomings || !this.$refs.upcomings.length) { return }

      const container = this.$el.querySelectorAll('.upcoming-events-zone')[0]
      if (!container) { return }
      this.$refs.upcomings.forEach(elm => {
        if (
          (!this.isWideScreen && !this.isPortrait && (elm.offsetLeft + elm.offsetWidth > container.offsetWidth)) ||
          (this.isPortrait && (elm.offsetTop + elm.offsetHeight > container.offsetTop + container.offsetHeight)) ||
          (this.isWideScreen && (elm.offsetLeft + elm.offsetWidth > container.offsetLeft + container.offsetWidth))
        ) {
          elm.classList.add('invisible')
        } else {
          elm.classList.remove('invisible')

          // In px. To adjust minor height calculation difference across browsers
          const delta = 2

          // Hide children block when there's not enough vertical space
          const itemBlocks = elm.querySelectorAll('.upcoming')
          if (itemBlocks && itemBlocks.length) {
            Array.prototype.forEach.call(itemBlocks, (block) => {
              if (block.offsetTop + block.offsetHeight > elm.offsetHeight + delta) {
                block.classList.add('invisible')
              } else {
                block.classList.remove('invisible')
              }
            })
          }
        }
      })
    },

    // Override Mixins `checkSize`
    checkSize () {
      if (!this.$el) { return }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        width = container.offsetWidth || container.clientWidth
        height = container.offsetHeight || container.clientHeight

        if (!width || !height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: width,
          h: height
        }

        this.debounceCheckUpcomingZone()

        FastDom.clear(measure)
      })
    },

    async getStaleData (init) {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData && staleData.length) {
          if (!this.validEvents.length) {
            this.validEvents = staleData
          }
          if (init) {
            this.loaded()
          }
          this.checkValidEvents()
        }
      }
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.amadeus-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWideScreen, 'dark-text': !whiteText, 'show-text-shadow': showTextShadow}]"
                      :style="[fontSizeStyle, zonePaddingsStyle]")
    .resize-sensor(ref="sensor")

    .events-inner-wrapper(v-if="!listIsEmpty")
      .events-container(:class="[fontClass, {'uninitialized': !baseFontSize}]"
                        :style="boxMarginStyle")
        .events-zone(:class="boxVertClass")
          .header-title(v-if="title && title.length", :class="[horzClass, vertClass, boxHorzClass, boxVertClass]") {{ title }}
          .upcoming-events-zone(:class="[boxHorzClass, defaultColLimitClass, weHorzClass, weVertClass]")
            .upcoming-list(ref="upcomings" v-for="group in upcomingList", :key="group.date" :class="[rendering ? 'hide-events' : '', bigFontCell]")
              .upcoming-date-header(:class="[horzClass, vertClass]") {{ group.date }}
              .events-in-group
                amadeus-item.upcoming(v-for="event in group.items", :key="event.Id",
                                    :event="event"
                                    :hide-location="hideLocation"
                                    :date-format="dateFm"
                                    :dark-text="!whiteText"
                                    :show-text-shadow="showTextShadow"
                                    :hide-box="hideBox"
                                    :hide-box-outline="hideBoxOutline"
                                    :color-label-width="colorLabelWidth"
                                    :font-size-scale="fontSizeScale"
                                    :horz-class="horzClass"
                                    :vert-class="vertClass"
                                    skip-font-scaling)
    .pagination(v-if="pagination.enabled && pagination.maxPageCount > 1")
      .pagination-inner
        .dot(v-for="page in pagination.maxPageCount" :key="page" :class="{'active': page === pagination.currentPage}")
    .messages(v-if="listIsEmpty", :class="[fontClass]")
      .header-title(v-if="!loading && !error && title && title.length") {{ title }}
      p(v-if="error") {{ error }}
      p(v-else-if="!loading && validEvents.length === 0") {{ $t('pageItems.events.hintNoUpcomings') }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

eventZoneAlignment(source)
  display: flex
  justify-content: flex-start
  align-items: flex-start
  align-content: flex-start

  // -- Horizontal Alignment
  if source
    &.horz-left
      justify-content: flex-start
    &.horz-right
      justify-content: flex-end
    &.horz-center
      justify-content: center
    &.horz-justify
      justify-content: center
  else
    &.box-horz-left
      justify-content: flex-start
    &.box-horz-right
      justify-content: flex-end
    &.box-horz-center
      justify-content: center    

  // -- Vertical Alignment
  &.box-vert-middle
    if source
      align-items: center
    else
      align-content: center
  &.box-vert-top
    if source
      align-items: flex-start
    else
      align-content: flex-start
  &.box-vert-bottom
    if source
      align-items: flex-end
    else
      align-content: flex-end

section.amadeus-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .messages
    text-align: center
    max-width: 90%
    overflow: hidden
    z-index: 5
    .header-title
      line-height: 130%
      font-weight: 600
      ellipsis()
    p
      font-size: 1.5em
      line-height: 150%
      margin: 0
      padding: 0.3em 2em

  .events-inner-wrapper
    position: relative
    flex: 1 1 0.00001px
    align-self: stretch
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .events-container
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 5

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch
    overflow: hidden

    .events-zone
      flex: 1
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch
      overflow: hidden

      &.box-vert-middle
        justify-content: center
      &.box-vert-top
        justify-content: flex-start
      &.box-vert-bottom
        justify-content: flex-end

      .header-title
        flex: none
        line-height: 130%
        font-weight: 600
        padding: 0 0 0.5em 0
        ellipsis()
        eventZoneAlignment(true)
        &.no-feature
          padding: 0
        &.horz-left
          text-align: left
        &.horz-right
          text-align: right
        &.horz-center
          text-align: center
        &.horz-justify
          text-align: center
      .active-event-zone
        flex: 1.2 1.2 0.0001px
        overflow: hidden
        display: flex
        flex-flow: column nowrap
        justify-content: flex-start
        align-items: stretch

        .active-event-header
          font-size: 0.8em
          padding-bottom: 0.2em
          text-transform: uppercase
          &.horz-left
            text-align: left
          &.horz-right
            text-align: right
          &.horz-center
            text-align: center
          &.horz-justify
            text-align: center

        .active-event-wrapper
          flex: 1 1 0.0001px
          position: relative

          .amadeus-item-block
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0

          // Adjust the location font-size in the featured block when "show upcoming events" is toggled on
          &:not(.no-upcomings)
            .amadeus-item-block
              .event-location
                font-size: 0.8em

              .time-block
                .time
                  font-size: 0.8em
                .date
                  font-size: 0.8em

      .upcoming-events-zone
        flex: 1 1 1
        flex-flow: row wrap
        eventZoneAlignment(false)
        overflow: hidden
        &.default-col
          flex-wrap: nowrap
        &.wrapped-events
          flex-flow: column wrap
        &.wrapped-events-box-vert-bottom
          justify-content: flex-end
        &.wrapped-events-box-vert-middle
          justify-content: center
        &.wrapped-events-box-vert-top
          justify-content: flex-start
        &.wrapped-events-box-horz-left
          align-content: flex-start
        &.wrapped-events-box-horz-right
          align-content: flex-end
        &.wrapped-events-box-horz-center
          align-content: center
        padding: 0 0 0 0
        margin-left: -0.3em

        .upcoming-list
          max-height: 100%
          overflow: hidden
          position: relative
          margin-left: 0.3em
          opacity: 1
          width: 32.5%

          &.big-one-cell
            flex: 0 1 50%

          &.big-one-cell-without-padding
            flex: 1 1 auto

          &.big-two-cells
            flex: 0 1 40%

          &.big-two-cells-without-padding
            flex: 1 1 40%

          &.three-cells
            flex: 1 1 1
          
          &.invisible
            opacity: 0

          // Hide the first upcoming header in portrait mode when
          // - Feature Next is on (default)
          // - The first date block in `upcomingList` has more then one events
          &.hide-header
            .upcoming-date-header
              display: none

          .upcoming-date-header
            font-size: 0.5em
            padding: 1em 0 0.2em 0
            text-transform: uppercase
            &.horz-left
              text-align: left
            &.horz-right
              text-align: right
            &.horz-center
              text-align: center
            &.horz-justify
              text-align: center

          .events-in-group
            // Shrink the entire field's font-sizing
            font-size: 0.4em

          .upcoming
            margin-top: 0.5em
            opacity: 1
            &.invisible
              opacity: 0

  .hide-events
    visibility: hidden

  .pagination
      display: block
      width: auto
      overflow: hidden
      height: 18px
      position: absolute
      bottom: 3%

  .pagination-inner
      display: flex
    .dot
      background: #ccc
      margin: 4px
      vertical-align: middle
      border-radius: 10px
      width: 10px
      height: 10px
      &.active
        width: 12px
        height: 12px
        background: #fff

  // Dark Text
  &.dark-text
    .messages,
    .active-event-header,
    .upcoming-date-header
      color: $appDarkTextColor

  // Drop Text Shadow When BG Media is used
  &.show-text-shadow:not(.dark-text)
    .messages,
    .active-event-header,
    .upcoming-date-header
      appTextShadow()

  //
  // PORTRAIT LAYOUT
  //
  &.is-portrait
    .events-container
      align-items: stretch
      .header-title
        padding: 0 0 0.5em 0
      .active-event-zone
        flex: 1 1 0.0001px
        .active-event-header
          // Make date header in the same size
          font-size: 0.8em
      .upcoming-events-zone
        padding-bottom: 3.25em
        margin: 0
        .upcoming-list
          width: 100%
          margin-left: 0.5em
          .upcoming-date-header
            // Make date header in the same size
            font-size: 0.8em

  //
  // WIDE SCREEN LAYOUT
  //
  &.is-wide
    .events-container
      flex-flow: row nowrap
      .active-event-zone
        flex: 1 1 0.0001px
        .active-event-wrapper
          &.no-upcomings
            width: 35%
      .upcoming-events-zone
        margin: 0
        .upcoming-list
          width: calc(33.3333333% - 0.5em)
          margin-left: 0.5em
</style>
