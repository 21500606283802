/* For Cross Fade Effects */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


/* PAGE ANIMATIONS */
/* TTV name wrapper for <pt-animations.css> */

/* fade / Default */

.page-fade-enter-active,
.page-fade-leave-active {
  animation-delay: 0s;
}

.page-fade-enter-active {
  animation: fadeIn 1s ease both;
}

.page-fade-leave-active {
  animation: fadeOut 1s ease both;
}

/* fadeLeft */

.page-fadeLeft-enter-active,
.page-fadeLeft-leave-active {
  animation-delay: 0s;
}

.page-fadeLeft-leave-active {
  animation: moveToLeftFade 1s ease both;
}

.page-fadeLeft-enter-active {
  animation: moveFromRightFade 1s ease both;
}

/* fadeRight */

.page-fadeRight-enter-active,
.page-fadeRight-leave-active {
  animation-delay: 0s;
}

.page-fadeRight-leave-active {
  animation: moveToRightFade 1s ease both;
}

.page-fadeRight-enter-active {
  animation: moveFromLeftFade 1s ease both;
}

/* fadeTop */

.page-fadeTop-enter-active,
.page-fadeTop-leave-active {
  animation-delay: 0s;
}

.page-fadeTop-leave-active {
  animation: moveToTopFade 1s ease both;
}

.page-fadeTop-enter-active {
  animation: moveFromBottomFade 1s ease both;
}

/* fadeBottom */

.page-fadeBottom-enter-active,
.page-fadeBottom-leave-active {
  animation-delay: 0s;
}

.page-fadeBottom-leave-active {
  animation: moveToBottomFade 1s ease both;
}

.page-fadeBottom-enter-active {
  animation: moveFromTopFade 1s ease both;
}

/* moveLeft */

.page-moveLeft-enter-active,
.page-moveLeft-leave-active {
  animation-delay: 0s;
}

.page-moveLeft-leave-active {
  animation: moveToLeft 1s ease both;
}

.page-moveLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* moveRight */

.page-moveRight-enter-active,
.page-moveRight-leave-active {
  animation-delay: 0s;
}

.page-moveRight-leave-active {
  animation: moveToRight 1s ease both;
}

.page-moveRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* moveTop */

.page-moveTop-enter-active,
.page-moveTop-leave-active {
  animation-delay: 0s;
}

.page-moveTop-leave-active {
  animation: moveToTop 1s ease both;
}

.page-moveTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* moveBottom */

.page-moveBottom-enter-active,
.page-moveBottom-leave-active {
  animation-delay: 0s;
}

.page-moveBottom-leave-active {
  animation: moveToBottom 1s ease both;
}

.page-moveBottom-enter-active {
  animation: moveFromTop 1s ease both;
}

/* easeLeft */

.page-easeLeft-enter-active,
.page-easeLeft-leave-active {
  animation-delay: 0s;
}

.page-easeLeft-leave-active {
  animation: moveToLeft 1s ease both;
  z-index: 9;
}

.page-easeLeft-enter-active {
  animation: moveFromRight .6s ease both;
}

/* easeRight */

.page-easeRight-enter-active,
.page-easeRight-leave-active {
  animation-delay: 0s;
}

.page-easeRight-leave-active {
  animation: moveToRight 1s ease both;
  z-index: 9;
}

.page-easeRight-enter-active {
  animation: moveFromLeft .6s ease both;
}

/* easeTop */

.page-easeTop-enter-active,
.page-easeTop-leave-active {
  animation-delay: 0s;
}

.page-easeTop-leave-active {
  animation: moveToTop 1s ease both;
  z-index: 9;
}

.page-easeTop-enter-active {
  animation: moveFromBottom .6s ease both;
}

/* easeBottom */

.page-easeBottom-enter-active,
.page-easeBottom-leave-active {
  animation-delay: 0s;
}

.page-easeBottom-leave-active {
  animation: moveToBottom 1s ease both;
  z-index: 9;
}

.page-easeBottom-enter-active {
  animation: moveFromTop .6s ease both;
}

/* scaleDown */

.page-scaleDown-enter-active,
.page-scaleDown-leave-active {
  animation-delay: 0s;
}

.page-scaleDown-leave-active {
  animation: scaleDownCenter 1s ease both;
  z-index: 9;
}

.page-scaleDown-enter-active {}

/* scaleDownToLeft */

.page-scaleDown-enter-active {}

/* scaleDownToLeft */

.page-scaleDownToLeft-enter-active,
.page-scaleDown-enter-active {}

/* scaleDownToLeft */

.page-scaleDown-enter-active {}

/* scaleDownToLeft */

.page-scaleDownToLeft-leave-active {
  animation-delay: 0s;
}

.page-scaleDownToLeft-leave-active {
  animation: scaleDown 1s ease both;
}

.page-scaleDownToLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* scaleDownToRight */

.page-scaleDownToRight-enter-active,
.page-scaleDownToRight-leave-active {
  animation-delay: 0s;
}

.page-scaleDownToRight-leave-active {
  animation: scaleDown 1s ease both;
}

.page-scaleDownToRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* scaleDownToTop */

.page-scaleDownToTop-enter-active,
.page-scaleDownToTop-leave-active {
  animation-delay: 0s;
}

.page-scaleDownToTop-leave-active {
  animation: scaleDown 1s ease both;
}

.page-scaleDownToTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* scaleDownToBottom */

.page-scaleDownToBottom-enter-active,
.page-scaleDownToBottom-leave-active {
  animation-delay: 0s;
}

.page-scaleDownToBottom-leave-active {
  animation: scaleDown 1s ease both;
}

.page-scaleDownToBottom-enter-active {
  animation: moveFromTop 1s ease both;
}

/* scaleUp */

.page-scaleUp-enter-active,
.page-scaleUp-leave-active {
  animation-delay: 0s;
}

.page-scaleUp-leave-active {
  animation: scaleDownUp 1s ease both;
  z-index: 9;
}

.page-scaleUp-enter-active {}

/* scaleUpToLeft */

.page-scaleUp-enter-active {}

/* scaleUpToLeft */

.page-scaleUpToLeft-enter-active,
.page-scaleUp-enter-active {}

/* scaleUpToLeft */

.page-scaleUp-enter-active {}

/* scaleUpToLeft */

.page-scaleUpToLeft-leave-active {
  animation-delay: 0s;
}

.page-scaleUpToLeft-leave-active {
  animation: scaleDownUp 1s ease both;
  z-index: 9;
}

.page-scaleUpToLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* scaleUpToRight */

.page-scaleUpToRight-enter-active,
.page-scaleUpToRight-leave-active {
  animation-delay: 0s;
}

.page-scaleUpToRight-leave-active {
  animation: scaleDownUp 1s ease both;
  z-index: 9;
}

.page-scaleUpToRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* scaleUpToTop */

.page-scaleUpToTop-enter-active,
.page-scaleUpToTop-leave-active {
  animation-delay: 0s;
}

.page-scaleUpToTop-leave-active {
  animation: scaleDownUp 1s ease both;
  z-index: 9;
}

.page-scaleUpToTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* scaleUpToBottom */

.page-scaleUpToBottom-enter-active,
.page-scaleUpToBottom-leave-active {
  animation-delay: 0s;
}

.page-scaleUpToBottom-leave-active {
  animation: scaleDownUp 1s ease both;
  z-index: 9;
}

.page-scaleUpToBottom-enter-active {
  animation: moveFromTop 1s ease both;
}

/* glueLeft */

.page-glueLeft-enter-active,
.page-glueLeft-leave-active {
  animation-delay: 0s;
}

.page-glueLeft-leave-active {
  transform-origin: 0 50%;
  animation: rotateRightSideFirst .8s both ease-in;
}

.page-glueLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* glueRight */

.page-glueRight-enter-active,
.page-glueRight-leave-active {
  animation-delay: 0s;
}

.page-glueRight-leave-active {
  transform-origin: 100% 50%;
  animation: rotateLeftSideFirst .8s both ease-in;
}

.page-glueRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* glueTop */

.page-glueTop-enter-active,
.page-glueTop-leave-active {
  animation-delay: 0s;
}

.page-glueTop-leave-active {
  transform-origin: 50% 0;
  animation: rotateBottomSideFirst .8s both ease-in;
}

.page-glueTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* glueBottom */

.page-glueBottom-enter-active,
.page-glueBottom-leave-active {
  animation-delay: 0s;
}

.page-glueBottom-leave-active {
  transform-origin: 50% 100%;
  animation: rotateTopSideFirst .8s both ease-in;
}

.page-glueBottom-enter-active {
  animation: moveFromTop 1s ease both;
}

/* rotateFall */

.page-rotateFall-enter-active,
.page-rotateFall-leave-active {
  animation-delay: 0s;
}

.page-rotateFall-leave-active {
  transform-origin: 0% 0%;
  animation: rotateFall 1s both ease-in;
  z-index: 9;
}

.page-rotateFall-enter-active {}

/* none */

.page-none-enter-active,
.page-none-leave-active {
  animation: none;
}

/* pushLeft */

.page-rotateFall-enter-active {}

/* pushLeft */

.page-pushLeft-enter-active,
.page-rotateFall-enter-active {}

/* pushLeft */

.page-rotateFall-enter-active {}

/* pushLeft */

.page-pushLeft-leave-active {
  animation-delay: 0s;
}

.page-pushLeft-leave-active {
  transform-origin: 0% 50%;
  animation: rotatePushLeft 1s both ease;
}

.page-pushLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* pushRight */

.page-pushRight-enter-active,
.page-pushRight-leave-active {
  animation-delay: 0s;
}

.page-pushRight-leave-active {
  transform-origin: 100% 50%;
  animation: rotatePushRight 1s both ease;
}

.page-pushRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* pushTop */

.page-pushTop-enter-active,
.page-pushTop-leave-active {
  animation-delay: 0s;
}

.page-pushTop-leave-active {
  transform-origin: 50% 0;
  animation: rotatePushTop 1s both ease;
}

.page-pushTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* pushBottom */

.page-pushBottom-enter-active,
.page-pushBottom-leave-active {
  animation-delay: 0s;
}

.page-pushBottom-leave-active {
  transform-origin: 50% 100%;
  animation: rotatePushBottom 1s both ease;
}

.page-pushBottom-enter-active {
  animation: moveFromTop 1s ease both;
}

/* foldLeft */

.page-foldLeft-enter-active,
.page-foldLeft-leave-active {
  animation-delay: 0s;
}

.page-foldLeft-leave-active {
  transform-origin: 100% 50%;
  animation: rotateFoldLeft 1s both ease;
}

.page-foldLeft-enter-active {
  animation: moveFromRight 1s ease both;
}

/* foldRight */

.page-foldRight-enter-active,
.page-foldRight-leave-active {
  animation-delay: 0s;
}

.page-foldRight-leave-active {
  transform-origin: 0 50%;
  animation: rotateFoldRight 1s both ease;
}

.page-foldRight-enter-active {
  animation: moveFromLeft 1s ease both;
}

/* foldTop */

.page-foldTop-enter-active,
.page-foldTop-leave-active {
  animation-delay: 0s;
}

.page-foldTop-leave-active {
  transform-origin: 50% 100%;
  animation: rotateFoldTop 1s both ease;
}

.page-foldTop-enter-active {
  animation: moveFromBottom 1s ease both;
}

/* foldBottom */

.page-foldBottom-enter-active,
.page-foldBottom-leave-active {
  animation-delay: 0s;
}

.page-foldBottom-leave-active {
  transform-origin: 50% 0;
  animation: rotateFoldBottom 1s both ease;
}

.page-foldBottom-enter-active {
  animation: moveFromTop 1s ease both;
}