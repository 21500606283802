<script>
import Moment from 'moment-timezone'

export default {
  name: 'SlackDateMarker',

  props: {
    time: { type: Number, default: 0 }
  },

  data () {
    return {
      isToday: false,
      isThisYear: false,
      timer: undefined
    }
  },

  computed: {
    date () {
      if (!this.time) { return }
      return this.isToday
        ? this.$t('_common.time.today')
        : (
            this.isThisYear
              ? Moment(this.time, 'X').format('MMMM Do')
              : Moment(this.time, 'X').format('MMMM Do, YYYY')
          )
    }
  },

  watch: {
    time (newValue) {
      if (newValue) {
        this.checkIsToday()
      }
    },

    isToday (isToday) {
      clearInterval(this.timer)
      // Make sure to auto update date marker after 00:00
      if (isToday) {
        this.timer = setInterval(() => {
          this.checkIsToday()
        }, 1000)
      }
    }
  },

  mounted () {
    clearInterval(this.timer)
    this.checkIsToday()
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  methods: {
    checkIsToday () {
      if (!this.time) {
        this.isToday = false
        return
      }
      const now = Moment()
      const ts = Moment(this.time, 'X')
      this.isToday = now.format('YYYY-MM-DD') === ts.format('YYYY-MM-DD')
      this.isThisYear = now.format('YYYY') === ts.format('YYYY')
    }
  }
}
</script>

<template lang="pug">
.slack-date-marker
  span {{ date }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.slack-date-marker {
  position: relative;
  z-index: 0;
  text-align: center;
  margin: 1.5em 0;

  &:before {
    position: absolute;
    content: '';
    top: 1.1em;
    left: -1.5em;
    right: -1.5em;
    border-top: 1px solid -black(0.1);
    z-index: 1;
  }

  span {
    font-size: 1.5em;
    color: var(--gray-dark);
    font-weight: 600;
    padding: 0.1em 0.8em;
    background: #f9f9f9;
    position: relative;
    z-index: 5;
  }
}
</style>
