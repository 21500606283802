<script>
import { mapGetters } from 'vuex'

import PageContents from './PageContents.vue'

import PlaylistTemplate from 'services/playlist-template'
import Log from 'services/log.js'

export default {
  name: 'PlaylistPageTemplate',

  components: { PageContents },

  computed: {
    ...mapGetters([
      'templateById'
    ]),
    templateID () {
      return this.$route.params.templateID
    },
    template () {
      if (!this.templateID) { return }
      return this.templateById(this.templateID)
    }
  },

  mounted () {
    this.getTemplateById(this.templateID)
  },

  methods: {
    getTemplateById (templateID) {
      if (!templateID || !templateID.length) { return }

      PlaylistTemplate.get(templateID).catch(err => {
        Log.warn('template', `Get Playlist Template errored -  ${err.message || err.toString()}`, 'WAR_PLAYLISTTEMPLATEERR', err, false)
      })
    }
  }
}
</script>

<template lang="pug">
section#playlist-page-template
  page-contents(v-if="template && template.id"
                show-immediately
                show-prime
                :prime-page="template")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section#playlist-page-template
  z-index: 10
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden

  // Predefined Font Sets
  predefinedFonts()
</style>
