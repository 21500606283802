<script>
import Moment from 'moment-timezone'

import VideoItem from 'components/items/VideoItem.vue'
import Logo from 'components/common/Logo.vue'
import Spinner from 'components/common/Spinner.vue'
import DateWidget from 'components/widgets/Date.vue'
import Clock from 'components/widgets/Clock.vue'

import {
  mapGetters
} from 'vuex'

import qs from 'qs'
import Utils from 'services/utils'
import RSS from 'services/rss'
import Media from 'services/media'
import Log from 'services/log'

const config = {
  REFRESH_INTERVAL: 60000, // In ms.
  HIDE_DETAIL_AFTER: 15000, // in ms. Auto return to overview screen after...
  HIDE_OVERLAY_AFTER: 30000, // in ms. Auto hide floorplan overlay after...
  BACK_TO_FIRST_PAGE_AFTER: 30000 // in ms. Auto return to the first page of overview screen after...
}

export default {
  name: 'WayFindingItem',
  components: { DateWidget, Clock, Spinner, Logo, VideoItem },
  props: {
    active: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rss: undefined,
      title: undefined,
      defaultMedia: undefined,
      mediaBaseOnTag: false,
      theme: 'dark',

      hasTaggedMedia: false,
      taggedMedia: undefined,
      zoomingMedia: undefined,

      itemsByDate: [],
      eventList: [],
      categoryList: [],
      items: [],
      currentCategory: undefined,
      selectedDetail: undefined,
      showDetail: false,

      zoomInMedia: false,

      loading: true,
      hasError: false,
      errorMessage: '',
      errorCount: -1,

      refreshInterval: undefined,
      hideDetailTimer: undefined,
      hideFoorplanTimer: undefined,
      toFirstPageTimer: undefined
    }
  },

  watch: {
    'item.url' () {
      this.render()
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),
    hasMedia () {
      if (this.defaultMedia && this.defaultMedia.length) {
        if (this.isImageURL(this.defaultMedia) || this.isVideoURL(this.defaultMedia)) {
          return true
        }
      }
      return false
    },

    prevButtonEnabled () {
      return this.categoryList.includes(this.currentCategory) && this.categoryList.indexOf(this.currentCategory) > 0
    },

    nextButtonEnabled () {
      return this.categoryList.includes(this.currentCategory) && this.categoryList.indexOf(this.currentCategory) < this.categoryList.length - 1
    },

    activeMedia () {
      return (this.hasTaggedMedia && this.taggedMedia) ? this.taggedMedia : this.defaultMedia
    },

    offlineTime () {
      if (this.errorCount >= 60) {
        return this.errorCount % 60 === 0 ? `${Math.floor(this.errorCount / 60)}hr` : `${Math.floor(this.errorCount / 60)}hr ${this.errorCount % 60}min`
      }
      return `${this.errorCount}min`
    }
  },

  mounted () {
    clearTimeout(this.hideDetailTimer)
    clearTimeout(this.hideFoorplanTimer)
    clearTimeout(this.toFirstPageTimer)

    this.render()

    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.getData(true)
    }, config.REFRESH_INTERVAL)
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
    clearTimeout(this.hideDetailTimer)
    clearTimeout(this.hideFoorplanTimer)
    clearTimeout(this.toFirstPageTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))
      this.rss = decodeURIComponent(options.rss || '')
      this.title = options.title || 'Wayfinding'
      this.defaultMedia = options.default_media ? decodeURIComponent(options.default_media) : undefined

      const mediaBaseOnTag = options.media_base_on_tag || false
      this.mediaBaseOnTag = (mediaBaseOnTag === true || mediaBaseOnTag === 'true')

      this.theme = options.theme || 'dark'

      if (!this.rss || !this.rss.length) { return }

      this.getData()
    },

    getData (update) {
      RSS.getCacheFirst(this.rss, { count: 200 }, { noserviceworker: true })
        .then(res => {
          this.hasError = false
          this.errorMessage = ''

          this.loaded()

          this.items = RSS.parseCollegenetData(res.items)

          this.updateSchedule(this.items)
          this.errorCount = -1
        }).catch(err => {
          if (!update) {
            this.hasError = true
            this.loaded()
            this.errorMessage = err ? (err.message || err.toString()) : 'RSS Service Error'
          } else if (this.items && this.items.length) {
            this.updateSchedule(this.items)
          }
          this.errorCount++
          Log.warn('app', `Wayfinding: RSS feed error - ${err.message || err.toString()} "${this.rss}"`, 'WAR_WAYFINDINGRSSERR', { url: this.rss, error: err })
        })
    },

    updateSchedule (items) {
      const now = +Moment().format('X')
      const validItems = items.filter(item => {
        if (item.end && item.end.dateTime) {
          return +item.end.dateTime.format('X') >= now
        }
      })

      const existingDates = []
      const itemsByDate = []

      validItems.forEach(item => {
        // NOTE @ Dec 6, 2917
        // The 25Live feed structure had changed recently
        const category = item.category || (item.categories && item.categories[0])
        if (!existingDates.includes(category)) {
          existingDates.push(category)
          itemsByDate.push({
            category: category,
            items: [item]
          })
        } else {
          const existingIndex = itemsByDate.findIndex(li => {
            return li.category === category
          })
          itemsByDate[existingIndex].items.push(item)
        }
      })

      this.itemsByDate = itemsByDate
      this.categoryList = existingDates

      // Flip to the next day after 00:00, or when no items found today
      if (!this.currentCategory || !this.categoryList.includes(this.currentCategory)) {
        const firstItemByDate = itemsByDate[0]
        if (!firstItemByDate) {
          Log.warn('app', 'Wayfinding: No valid RSS item found', 'WAR_WAYFINDINGNOVALIDRSS')
        }
        this.currentCategory = firstItemByDate.category
        this.eventList = firstItemByDate.items
      } else {
        const currentIndex = this.categoryList.indexOf(this.currentCategory)
        this.eventList = this.itemsByDate[currentIndex].items
      }
    },

    toTime (ts) {
      return Moment(ts, 'X').format('HH:mm')
    },

    categoryToDate (category) {
      if (category) {
        return Moment(category, 'YYYY/MM/DD (ddd)').format('ddd, MMM D, YYYY')
      }
    },

    isImageURL (url) {
      return Utils.isImageURL(url)
    },

    isVideoURL (url) {
      return Utils.isVideoURL(url)
    },

    changeCategory (enabled, navToPrev) {
      if (!enabled) { return }

      const currentIndex = this.categoryList.indexOf(this.currentCategory)

      if (navToPrev) {
        this.currentCategory = this.categoryList[currentIndex - 1]
        this.eventList = this.itemsByDate[currentIndex - 1].items
      } else {
        this.currentCategory = this.categoryList[currentIndex + 1]
        this.eventList = this.itemsByDate[currentIndex + 1].items
      }

      this.$nextTick(() => {
        this.scrollListToTop()
      })

      clearTimeout(this.toFirstPageTimer)
      this.toFirstPageTimer = setTimeout(() => {
        clearTimeout(this.toFirstPageTimer)
        this.backToFirstPage()
      }, config.BACK_TO_FIRST_PAGE_AFTER)
    },

    getMatchingMedia (tags) {
      Media.getMediaByTags(tags).then(media => {
        if (!media || !media.length || !this.showDetail) { return }
        const randomIndex = Math.floor(Math.random() * media.length)
        this.taggedMedia = media[randomIndex]
        this.hasTaggedMedia = true
      }).catch(err => {
        console.log(err)
        this.hasTaggedMedia = false
      })
    },

    scrollListToTop () {
      const listContainer = this.$el.querySelector('.table-inner-wrapper')
      if (!listContainer) { return }
      listContainer.scrollTop = 0
    },

    displayDetail (item) {
      clearTimeout(this.hideDetailTimer)
      if (!item) { return }
      this.selectedDetail = item
      this.showDetail = true
      if (this.mediaBaseOnTag) {
        this.getMatchingMedia(item.tags)
      }
      clearTimeout(this.hideDetailTimer)
      this.hideDetailTimer = setTimeout(() => {
        clearTimeout(this.hideDetailTimer)
        this.hideDetail()
      }, config.HIDE_DETAIL_AFTER)
    },

    hideDetail () {
      clearTimeout(this.hideDetailTimer)
      this.showDetail = false
      this.selectedDetail = undefined
      this.hasTaggedMedia = false
      this.taggedMedia = undefined
    },

    showMedia (activeMedia) {
      clearTimeout(this.hideFoorplanTimer)
      if (!activeMedia) { return }
      this.zoomInMedia = true
      this.zoomingMedia = activeMedia
      clearTimeout(this.hideFoorplanTimer)
      this.hideFoorplanTimer = setTimeout(() => {
        clearTimeout(this.hideFoorplanTimer)
        this.hideMedia()
      }, config.HIDE_OVERLAY_AFTER)
    },

    hideMedia () {
      clearTimeout(this.hideFoorplanTimer)
      this.zoomInMedia = false
      this.zoomingMedia = undefined
    },

    backToFirstPage () {
      if (!this.categoryList) { return }
      const currentIndex = this.categoryList.indexOf(this.currentCategory)
      if (currentIndex !== 0) {
        this.currentCategory = this.itemsByDate[0].category
        this.eventList = this.itemsByDate[0].items
      }
    },

    sanitizeText (text) {
      return text.replace(/<script.+?>/g, '').replace(/<\/script>/g, '')
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section#way-finding(:class="{'light-theme': theme === 'light'}")
  header
    h1.building-name {{ title }}
    .date-and-time
      logo.brand-logo
      date-widget
      .clock-wrapper
        clock(alignment="right")

  .main-containter(v-if="!loading && !hasError")
    //- Time Table
    .time-table(v-if="!showDetail")
      .date-viewing
        button.nav-button.prev(v-bind:class="{enabled: prevButtonEnabled}" @click="changeCategory(prevButtonEnabled, true)")
          fa.fa-icon(icon="angle-left" fixed-width)
        .date {{ categoryToDate(currentCategory) }}
        button.nav-button.next(v-bind:class="{enabled: nextButtonEnabled}" @click="changeCategory(nextButtonEnabled)")
          fa.fa-icon(icon="angle-right" fixed-width)
      .table-containter
        .inner-wrapper.table-inner-wrapper
          table.event-list
            thead
              tr
                th Time
                th Event
                th Location
            tbody
              tr(v-for="(item, index) in eventList" v-bind:key="index" @click.stop="displayDetail(item)")
                td.timeframe {{ toTime(item.start.dateTime) }} - {{ toTime(item.end.dateTime) }}
                td.event(v-html="sanitizeText(item.title)")
                td.location(v-html="sanitizeText(item.rooms.join(', '))")

    //- Event Detail
    .detail-wrapper(v-if="showDetail && selectedDetail")
      .detail-containter
        h2.event-title(v-html="sanitizeText(selectedDetail.title)")
        .details
          .detail-block(v-if="selectedDetail.when")
            .info-label When:
            .info-text(v-html="sanitizeText(selectedDetail.when)")
          .detail-block(v-if="selectedDetail.rooms && selectedDetail.rooms.length")
            .info-label Where:
            .info-text(v-html="sanitizeText(selectedDetail.rooms.join(', '))")
          .detail-block(v-if="selectedDetail.event_state")
            .info-label Event State:
            .info-text {{ selectedDetail.event_state }}
          .detail-block(v-if="selectedDetail.organization")
            .info-label Organization:
            .info-text(v-html="sanitizeText(selectedDetail.organization)")

      footer
        button.back-button(@click="hideDetail") Back

    .media-container(v-if="hasMedia || hasTaggedMedia")
      .image-containter(v-if="isImageURL(activeMedia)" v-bind:style="{backgroundImage: 'url(' + activeMedia + ')'}" @click="showMedia(activeMedia)")
      .video-containter(v-else-if="isVideoURL(activeMedia)")
        video-item(:item="{url: activeMedia, muted: true }" mode="fit" @click.native="showMedia(activeMedia)")

  transition(name="fade" appear v-bind:duration="300")
    .media-zoomin(v-if="zoomInMedia && zoomingMedia" @click="hideMedia")
      img.media-img-zoomed(v-if="isImageURL(zoomingMedia)" v-bind:src="zoomingMedia" @click.stop="")
      .media-video-zoomed(v-else-if="isVideoURL(zoomingMedia)")
        video-element(mode="fit" v-bind:src="zoomingMedia")
      fa.fa-icon.close-button(icon="times-circle" fixed-width @click="hideMedia")

  transition(v-if="loading" name="fade" mode="out-in" appear)
    spinner(size="100px")

  transition(name="fade" mode="out-in" appear)
    .messages(v-if="hasError && !loading")
      p.url {{ rss }}
      p.msg {{ errorMessage }}
      p Please check your network, proxy or firewall settings.
      p And make sure the source is correct and has public access.

  transition(name="fade" mode="out-in" appear)
    .offline-hint(v-if="errorCount > 0")
      .offline-hint-icons
        fa.fa-icon.icon1(icon="wifi" fixed-width size="2x")
        fa.fa-icon.icon2(icon="question" fixed-width)
      .text-wrapper Device is offline for {{offlineTime}}.<br>Please check your network settings.

</template>

<style lang="stylus">
@import '../../style/mixins.styl';

$gray-mid = #2f3241;
$gray-dark = #1E202B;
$gray-lighter = #D2D2D5;

section#way-finding {
  width: 100%;
  height: 100%;
  font-size: 1vmin;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  z-index: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $gray-mid;

  .nav-button {
    background: transparent;
    color: var(--gray);
    cursor: default;
    font-size: 1em;

    .fa-icon {
      font-size: 1.2em;
    }
  }

  .nav-button,
  .back-button {
    border-radius: 3px;
    border: 0;
    padding: 0.4em 0.5em;
    outline: 0;
  }

  .nav-button.enabled,
  .back-button {
    background: $gray-dark;
    color: var(--brand-primary);
    cursor: pointer;
  }

  .back-button {
    font-size: 1.2em;
    padding-left: 1em;
    padding-right: 1em;
  }

  header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 1.3vmin 3vmin 1.6vmin 3.5vmin;
    background: var(--gray-darker);

    .building-name {
      margin: 0;
      flex: 1;
      font-size: 3em;
      font-weight: 400;
    }

    .date-and-time {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      opacity: 0.6;
      font-size: 2em;
    }

    .date-widget {
      .datetime {
        font-size: 100%;
      }
    }

    .clock-wrapper {
      position: relative;

      .simple-clock {
        font-size: 100%;
        flex-flow: row nowrap
        justify-content: space-between
        .time-wrapper {
          margin-left: 0.5em;
          font-weight: normal
        }
      }
    }

    .brand-logo {
      height: 4vmin;
      width: 10vw;
      margin-right: 1.5em;
    }
  }

  .main-containter {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    font-size: 2em;
  }

  .media-container {
    box-sizing: content-box;
    width: 30vw;
    margin: 2vmin;
    overflow: hidden;
    position: relative;

    .image-containter,
    .video-containter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }

    .image-containter {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }

  .time-table {
    flex: 1;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    padding: 1vmin 2vmin;
  }

  .date-viewing {
    text-align: center;
    padding: 0.3em 0.5em 0.5em 0.5em;
    text-transform: uppercase;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed alpha(white, 0.2);

    .date {
      padding: 0 1em;
    }
  }

  .table-containter {
    flex: 1;
    overflow: hidden;
    position: relative;
    z-index: 0;

    .inner-wrapper {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;

      scrollBarDark()
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4em;
      background-image: linear-gradient(to top, $gray-mid 0%, transparentify($gray-mid, $gray-mid, 0.5) 60%, transparentify($gray-mid, $gray-mid 0) 95%);
    }
  }

  table.event-list {
    border-collapse:separate;
    border-spacing: 0 0.5em;
    padding-right: 0.1em;
    width: 100%;
    margin-bottom: 4em;

    th {
      color: #fff;
      font-weight: 600;
      text-align: left;
    }

    td,
    th {
      padding: 0.2em 0.5em;
    }

    tbody {
      tr {
        background: $gray-dark;
        cursor: pointer;
        vertical-align: top;

        &:focus,
        &:active {
          background: transparentify($gray-dark, $gray-dark, 0.7);
        }
      }

      td {
        padding-top: 0.8em;
        padding-bottom: 0.8em;

        &.timeframe {
          width: 8em;
        }

        &.event {
          color: var(--brand-primary);
          font-weight: 600;
        }
      }
    }
  }

  .detail-wrapper {
    flex: 1;
    padding: 3vmin;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;

    footer {
      padding: 1vmin 3vmin 0 3vmin;
      text-align: center;
    }

    .detail-containter {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    .event-title {
      font-size: 1.8em;
      line-height: 130%;
      margin-bottom: 0.5em;
      text-align: center;
    }

    .details {
      display: table;
      max-width: 100%;
    }

    .detail-block {
      display: table-row;

      .info-label,
      .info-text {
        display: table-cell;
        padding: 0.5em 0;
        vertical-align: middle;
      }

      .info-label {
        padding-right: 1em;
        text-align: right;
        font-weight: 600;
        opacity: 0.5;
      }

      .info-text {
        font-weight: 400;
      }
    }
  }

  .media-zoomin {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: alpha(black, 0.85);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    animation-duration: 300ms;

    .media-img-zoomed {
      width: auto;
      height: auto;
      min-width: 50vw;
      max-width: 90vw;
    }

    .media-video-zoomed {
      width: 90vw;
      height: 90vh;
      position: relative;
    }

    .close-button {
      position: fixed;
      z-index: 1001;
      top: 3vmin;
      right: 5vmin;
      font-size: 5em;
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .messages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    padding: 5vmin;

    p.url {
      opacity: 0.7;
      font-size: 0.8em;
    }

    p.msg {
      margin-bottom: 1em;
    }
  }

  .offline-hint {
    position: absolute;
    right: 1vmin;
    bottom: 1vmin;
    padding: 1vmin 1.5vmin;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .offline-hint-icons {
      margin-right: 1.5vmin;

      .icon2 {
        color: var(--brand-warning);
        margin-left: 2px;
        vertical-align: text-top;
      }
    }

    .text-wrapper {
      font-size: 1.5em;
    }
  }

  // ===============
  // LIGHT THEME
  // ===============

  &.light-theme {
    color: #333;
    background: $gray-lighter;

    .nav-button {
      color: var(--gray);
    }

    .nav-button.enabled,
    .back-button {
      color: var(--gray-darker);
      background: #fff;
    }

    header {
      background: #3d3d4e;
      color: #fff;

      .building-name {
        font-weight: 600;
      }
    }

    .date-viewing {
      border-bottom: 1px dashed alpha(black, 0.3);
    }

    .table-containter {
      .inner-wrapper {
        scrollBarLight()
      }

      &:after {
        background-image: linear-gradient(to top, $gray-lighter 0%, transparentify($gray-lighter, $gray-lighter, 0.5) 60%, transparentify($gray-lighter, $gray-lighter, 0) 95%);
      }
    }

    table.event-list {
      th {
        color: $gray-mid;
      }

      tbody {
        tr {
          background: #fff;
        }

        td {
          color: $gray-mid;
          &.event {
            color: var(--gray-darker);
          }
        }
      }
    }

    .detail-wrapper {
      .info-label {
        opacity: 0.7;
      }

      .info-text {
        color: var(--gray-darker);
      }
    }

    .media-zoomin {
      .close-button {
        opacity: 1;
      }
    }
  }
}
</style>
