<script>
import {
  mapGetters
} from 'vuex'
import Env from 'services/environment'
import { EventBus } from 'services/eventbus.js'

const BOARD_BASE_URL = Env.baseBoardsURL()

export default {
  name: 'BoardV2',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      lastPong: undefined,
      bridgeFinished: false,
      desktopWindowIsHidden: false,

      pingInterval: undefined,
      bridgeTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),

    boardID () {
      // Uses `ref_id` as fallback for board pages created between DEV-928 and DEV-1319
      return (this.item && (this.item.board_id || this.item.ref_id || this.item.url || this.item.location)) || ''
    },

    iframeSrc () {
      if (!this.boardID || !this.activeToken || this.desktopWindowIsHidden) {
        return ''
      }
      const muteVideos = Env.muteVideos ? '&mutevideos=true' : ''
      const regionInfo = Env.activeRegion() ? `&region=${Env.activeRegion()}` : ''

      return `${BOARD_BASE_URL}/board/${this.boardID}?token=${this.activeToken}${muteVideos}${regionInfo}`
    }
  },

  mounted () {
    this.lastPong = Date.now()
    window.addEventListener('message', this.receiveMessage, false)
    EventBus.$on('app-window-hidden', this.visibilityHandler)

    clearTimeout(this.bridgeTimer)

    clearInterval(this.pingInterval)
    this.pingInterval = setInterval(() => {
      if (Date.now() - this.lastPong > 60000) {
        this.$emit('finished')
      } else {
        if ((this.iframeSrc || '').indexOf(BOARD_BASE_URL) === 0 && this.$refs.iframe.contentWindow) {
          this.$refs.iframe.contentWindow.postMessage({type: 'ping'}, BOARD_BASE_URL)
        }
      }
    }, 15000)
  },

  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage, false)
    EventBus.$off('app-window-hidden', this.visibilityHandler)
    clearInterval(this.pingInterval)
    clearTimeout(this.bridgeTimer)
  },

  methods: {
    // NOTE @ Dec 18, 2018 [DEV-437]
    // The 'message' event listener cannot be removed when event handler is assigned in vue component
    // Other repositories don't have this issue
    // Probably due to the difference between Parcel and Webpack
    //
    // + NOTE @ Jan 17, 2019 [DEV-565]
    // Have to move the message handler back to vue component to handle loading state
    receiveMessage (evt) {
      if (evt.origin !== BOARD_BASE_URL) {
        return
      }
      switch (evt.data.type) {
        case 'pong':
          this.lastPong = Date.now()
          break
        case 'bridge-finished':
          if (evt.data && evt.data.data && evt.data.data.boardID === this.boardID) {
            this.bridgeFinished = true
          }
          break
        case 'board-is-ready':
          if (evt.data && evt.data.data && evt.data.data.boardID === this.boardID) {
            this.$emit('loaded')
          }
          break
      }
    },

    startBridging () {
      clearTimeout(this.bridgeTimer)
      if (!this.bridgeFinished) {
        if (!this.$refs || !this.$refs.iframe) {
          return
        }
        if (!this.iframeSrc) {
          return
        }
        const msg = {
          type: 'bridge-start',
          data: {
            ts: new Date().getTime()
          }
        }
        this.$refs.iframe.contentWindow.postMessage(msg, BOARD_BASE_URL)
        clearTimeout(this.bridgeTimer)
        this.bridgeTimer = setTimeout(() => {
          clearTimeout(this.bridgeTimer)
          this.startBridging()
        }, 400)
      }
    },

    visibilityHandler (isHidden) {
      this.desktopWindowIsHidden = isHidden
      if (isHidden) {
        this.bridgeFinished = false
      }
    }
  }
}
</script>

<template lang="pug">
iframe.board-v2(ref="iframe"
       :src="iframeSrc"
       frame-border="0"
       scrolling="no"
       sandbox="allow-same-origin allow-scripts allow-presentation allow-top-navigation"
       allow="autoplay; fullscreen"
       allowfullscreen
       seamless
       @load="startBridging")
</template>

<style lang="stylus">
iframe.board-v2 {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
