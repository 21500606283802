<!-- Aimed for Title Texts -->
<!-- Scale down font size to fit the container only when needed -->
<!-- Only perform scaling DOWN when there's not enough space -->

<script>
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

const config = {
  LINE_HEIGHT: 1.2
}

export default {
  name: 'FitTextBlock',

  props: {
    text: {
      type: String,
      default: ''
    },
    // In em
    baseFontSize: {
      type: Number,
      required: true
    },
    // In em
    lineHeight: {
      type: Number,
      default: +config.LINE_HEIGHT
    },
    fontClass: {
      type: String,
      default: ''
    },
    skipScaling: {
      type: Boolean,
      default: false
    },
    useHTML: {
      type: Boolean,
      default: false
    },
    fontSizeScale: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      fontSizeRatio: 1,

      // Failsafe for "Minimum Font Size" on Chrome
      lastBoxSize: {},

      resizing: true,
      adjusting: false,

      debounceTimer: undefined,
      measureTimer: undefined
    }
  },

  computed: {
    fontSizeStyle () {
      if (!this.text || !this.text.length || !this.baseFontSize) { return }
      if (this.fontSizeScale === '') {
        return {
          fontSize: `${this.fontSizeRatio * +this.baseFontSize}em`
        }
      } else if (this.fontSizeScale !== '') {
        return {
          fontSize: `${this.fontSizeScale}em`
        }
      }
    },

    lineHeightStyle () {
      return {
        lineHeight: `${(this.lineHeight || config.LINE_HEIGHT) * 100}%`
      }
    },

    skipCheckSize () {
      return Boolean(!this.text || !this.text.length)
    }
  },

  watch: {
    text (newVal) {
      if (newVal && newVal.length) {
        this.debounceCheckSize()
      }
    },
    fontClass () {
      if (!this.skipCheckSize) {
        this.debounceCheckSize()
      }
    },
    baseFontSize () {
      if (!this.skipCheckSize) {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.measureTimer)
    clearTimeout(this.debounceTimer)
    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.measureTimer)
    clearTimeout(this.debounceTimer)
  },

  methods: {
    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)

      if (this.skipScaling || this.skipCheckSize) {
        this.fontSizeRatio = 1
        this.resizing = false
        return
      }

      // Prevent infinite looping
      if (this.adjusting) {
        return
      }

      this.resizing = true

      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.fontSizeRatio = 1
        clearTimeout(this.measureTimer)
        this.measureTimer = setTimeout(() => {
          clearTimeout(this.measureTimer)
          if (this.adjusting) { return }
          this.adjustFontSize()
        }, 50)
      }, timeout || 300)
    },

    adjustFontSize () {
      if (!this.$el || !this.$el.parentElement || !this.$refs || !this.$refs.box || this.skipCheckSize) {
        clearTimeout(this.measureTimer)
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
        return
      }

      if (this.skipScaling) {
        clearTimeout(this.measureTimer)
        this.fontSizeRatio = 1
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
        return
      }

      this.resizing = true
      this.adjusting = true

      const box = this.$refs.box
      const parentElm = this.$el.parentElement

      const containerMetrics = parentElm.getBoundingClientRect()
      // Round values to integer to add some tolerance
      const containerHeight = Math.ceil(containerMetrics.height)
      const containerWidth = Math.ceil(containerMetrics.width)

      if (!containerWidth || !containerHeight) {
        clearTimeout(this.measureTimer)
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
        return
      }

      if ((box.scrollHeight > containerHeight) || (box.scrollWidth > containerWidth)) {
        // When hitting the "Minimum Font Size" boundary on Chrome, stop scaling
        if (this.lastBoxSize && this.lastBoxSize.h === box.scrollHeight && this.lastBoxSize.w === box.scrollWidth) {
          clearTimeout(this.measureTimer)
          this.resizing = false
          this.adjusting = false
          this.lastBoxSize = {}
          return
        }

        this.lastBoxSize = {
          h: box.scrollHeight,
          w: box.scrollWidth
        }

        this.fontSizeRatio *= 0.9

        clearTimeout(this.measureTimer)
        this.measureTimer = setTimeout(() => {
          clearTimeout(this.measureTimer)
          this.adjustFontSize()
        }, 50)
      } else {
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
      }
    }
  }
}
</script>

<template lang="pug">
.fit-text-box
  .resize-sensor(ref="sensor")
  .text-box(v-if="useHTML" ref="box",:style="[lineHeightStyle, fontSizeStyle]",  v-html="text"  :class="{'visible': !resizing }")
  .text-box(v-else ref="box", :style="[lineHeightStyle, fontSizeStyle]", :class="{'visible': !resizing }") {{ text }}
</template>

<style lang="stylus">
.fit-text-box
  position: relative
  height: 100%

  // Firefox fixes
  min-height: 0

  max-width: 100%
  max-height: 100%

  overflow: hidden
  line-height: 100%

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .text-box
    // Firefox fixes
    min-height: 0

    max-width: 100%
    max-height: 100%
    overflow: hidden
    flex-grow: 1
    height: 100%

    line-height: 100%

    z-index: 1
    position: relative

    opacity: 0
    transition: opacity .1s

    // Prevent flickering when font-size is unscaled
    &.visible
      opacity: 1
</style>
