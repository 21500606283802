<script>
import Env from 'services/environment'

import {
  mapGetters
} from 'vuex'

const BASE_PROXY_URL = `${Env.cdnURL()}/proxy`

export default {
  name: 'OverrideWebImage',

  props: {
    url: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),
    proxyImage () {
      if (!this.url || !this.url.length) { return }
      return {
        backgroundImage: `url(${BASE_PROXY_URL}?url=${encodeURIComponent(this.url)}&token=${this.activeToken})`
      }
    }
  }
}
</script>

<template lang="pug">
.override-web-image
  .img-content(:style="proxyImage")
</template>

<style lang="stylus">
.override-web-image
  z-index: 52
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  .img-content
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

    border: 0
    outline: 0

    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
</style>
