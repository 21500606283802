<script>
import qs from 'qs'

import Webapp from 'services/webapp'

import FilesyncItem from 'components/items/FilesyncItem.vue'
import HtmlItem from 'components/items/HtmlItem.vue'

// Load Staled Web App Content after this timeout
// Failsafe for Player starting offline
const APP_INIT_TIMEOUT = 2500 // When it's offline, it'll need another 5s timeout to get HTML content from cache.

export default {
  name: 'WebappPage',
  components: {
    HtmlItem,
    FilesyncItem
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { return {} }
    },
    webapp: {
      type: Object,
      default: () => { return {} }
    },
    // Indicator to use the "webapp" prop instead of "item"
    useWebappProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      webappId: null,

      isIDE: false,

      // 'git' | 'simple' [DEV-4034]
      type: 'simple',

      // developer defined "arguments"
      args: [],

      // user inputed fields (according to "arguments")
      fields: [],

      htmlItemOptions: null,

      filesyncId: null,
      filesyncToken: null,
      filesyncLastBuiltAt: null,

      initTimer: null,
      updateTimer: null
    }
  },

  computed: {
    isGitSource () {
      return this.type === 'git'
    },

    isZipSource () {
      return this.type === 'zip'
    }
  },

  watch: {
    'item.url' () {
      this.render()
    },

    'webapp': {
      deep: true,
      handler (newValue) {
        if (!this.useWebappProp) { return }
        if (newValue) {
          this.updateHandler(newValue)
        }
      }
    }
  },

  mounted () {
    clearTimeout(this.initTimer)
    clearTimeout(this.updateTimer)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.initTimer)
    clearTimeout(this.updateTimer)
  },

  methods: {
    render () {
      if (this.useWebappProp) {
        this.updateData(this.webapp)
        return
      }

      if (!this.item || !this.item.url || !this.item.url.length) { return }
      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.type = options.source || 'simple'
      this.fields = options.fields || []

      // Preview from js_user
      if (options.idePreview === 'true') {
        clearTimeout(this.initTimer)
        this.isIDE = true
        this.args = options.args || []
        this.webappId = options.webappId || ''

        if (this.isGitSource || this.isZipSource) {
          this.filesyncId = options.filesyncId
          this.filesyncToken = options.filesyncToken
        } else {
          this.htmlItemOptions = { url: qs.stringify({ idePreview: true }) }
        }

      // Player
      } else {
        this.isIDE = false
        this.webappId = options.templateId || ''
        this.getWebapp()
      }
    },

    getWebapp () {
      if (!this.webappId) {
        clearTimeout(this.initTimer)
        return
      }

      this.recheckAppFetching()

      Webapp.get(this.webappId).then(app => {
        clearTimeout(this.initTimer)
        this.updateData(app)
      }).catch(() => {
        clearTimeout(this.initTimer)
        this.fetchErrorHandler()
      })
    },

    updateData (data) {
      if (!data) { return }

      if (this.useWebappProp) {
        this.isIDE = false
        this.webappId = data.id
        this.type = data.source || 'simple'
        this.fields = data.fields || []
      }

      if (this.isGitSource || this.isZipSource) {
        this.filesyncId = (data.filesync && data.filesync.id) || null
        this.filesyncToken = (data.filesync && data.filesync.http_token) || null
        this.filesyncLastBuiltAt = (data.filesync && data.filesync.last_built_at) || null
      } else {
        const htmlTemplateId = data.html_content_id || ''
        this.htmlItemOptions = htmlTemplateId ? { url: qs.stringify({htmlId: htmlTemplateId}) } : null
      }

      this.args = data.arguments || []
    },

    updateHandler (payload) {
      clearTimeout(this.updateTimer)
      this.updateTimer = setTimeout(() => {
        clearTimeout(this.updateTimer)
        this.updateData(payload)
      }, 1000)
    },

    recheckAppFetching () {
      clearTimeout(this.initTimer)
      this.initTimer = setTimeout(() => {
        clearTimeout(this.initTimer)
        this.fetchErrorHandler()
      }, APP_INIT_TIMEOUT)
    },

    async fetchErrorHandler () {
      const staleData = await Webapp.getCachedData(this.webappId)
      if (staleData) {
        this.updateData(staleData)
      }
    },

    loadedHandler () {
      this.$emit('loaded')
    }
  }
}
</script>

<template lang="pug">
section.webapp
  html-item(v-if="!isGitSource && !isZipSource && htmlItemOptions"
            :item="htmlItemOptions"
            :args="args"
            :fields="fields"
            :webapp-id="webappId"
            is-webapp
            active
            @loaded="loadedHandler")
  filesync-item(v-if="(isGitSource || isZipSource) && filesyncId"
                :filesync-id="filesyncId"
                :filesync-token="filesyncToken"
                :last-built-at="filesyncLastBuiltAt"
                :webapp-id="webappId"
                :ide-preview="isIDE"
                @loaded="loadedHandler")
</template>

<style lang="stylus">
section.webapp
  position: relative
  width: 100%
  height: 100%
</style>
