<script>
import CarouselText from 'components/widgets/CarouselText.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

export default {
  name: 'InfoBarItemsContainer',
  components: {
    CarouselText,
    ResponsiveMarkupText
  },

  props: {
    type: { type: String, required: true },
    items: { type: Array, default: () => { return [] } },

    isPortrait: { type: Boolean, default: false },
    isTall: { type: Boolean, default: false },
    isWide: { type: Boolean, default: false },
    isXWide: { type: Boolean, default: false },

    // Item transition interval set in the parent container (InfoBar.vue)
    interval: { type: Number, default: 10 },

    baseFontSize: { type: Number, default: 1.0 },
    fontClass: { type: String, default: '' }
  },

  data () {
    return {
      showPrime: false,
      currentIndex: -1,
      primeItem: {},
      baseItem: {},

      debounceTimer: undefined,
      showNextTimer: undefined
    }
  },

  computed: {
    needsPaginate () {
      return this.items && this.items.length > 1
    }
  },

  watch: {
    items: {
      deep: true,
      handler () {
        this.debounceRender()
      }
    },

    type () {
      this.currentIndex = -1
      this.debounceRender()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.showNextTimer)
    this.showNext()
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.showNextTimer)
  },

  methods: {
    showNext () {
      clearTimeout(this.showNextTimer)

      if (!this.items || !this.items.length) {
        this.currentIndex = -1
        this.primeItem = {}
        this.baseItem = {}

        // Still, display the empty section for requested interval
        this.prepareNext(true)
        return
      }

      let nextIndex
      if (this.currentIndex < 0) {
        nextIndex = 0
      } else {
        // Reach the end of list
        if (this.currentIndex === this.items.length - 1) {
          this.$emit('end-of-list')
          // Skip unnecessary animation while there's only one item + one Main Section
          if (!this.needsPaginate) { return }
        }
        nextIndex = (this.currentIndex + 1) % this.items.length
      }

      const nextItem = JSON.parse(JSON.stringify(this.items[nextIndex]))
      if (this.showPrime) {
        this.showPrime = false
        this.baseItem = nextItem
      } else {
        this.showPrime = true
        this.primeItem = nextItem
      }
      this.currentIndex = nextIndex

      this.prepareNext()
    },

    debounceRender () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.showNext()
      }, 100)
    },

    prepareNext (forceAdvance) {
      clearTimeout(this.showNextTimer)
      this.showNextTimer = setTimeout(() => {
        clearTimeout(this.showNextTimer)
        if (forceAdvance) {
          this.$emit('end-of-list')
          return
        }
        this.showNext()
      }, this.interval * 1000)
    }
  }
}
</script>

<template lang="pug">
.infobar-items-wrapper(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide, 'is-x-wide': isXWide, 'is-tall': isTall}")
  //- PRIME
  transition(name="fade")
    .infobar-item.prime(v-if="showPrime")
      .title-text-wrapper(v-if="type === 'rss' && primeItem.title")
        carousel-text.title-text(:text="primeItem.title", :interval="interval")
        //- Invisible block for height placeholder
        .title-text.placeholder {{ primeItem.title }}

      .carousel-wrapper(:class="{'is-twitter': type === 'twitter'}")
        responsive-markup-text.prime(v-if="type === 'messages'"
          :text="(primeItem && primeItem.message) || ''"
          :base-font-size="baseFontSize"
          :font-class="fontClass")
        carousel-text(v-else :text="primeItem.message", :interval="interval")

  //- BASE
  transition(name="fade")
    .infobar-item.base(v-if="!showPrime")
      .title-text-wrapper(v-if="type === 'rss' && baseItem.title")
        carousel-text.title-text(:text="baseItem.title", :interval="interval")
        //- Invisible block for height placeholder
        .title-text.placeholder {{ baseItem.title }}
      .carousel-wrapper(:class="{'is-twitter': type === 'twitter'}")
        responsive-markup-text.base(v-if="type === 'messages'"
          :text="(baseItem && baseItem.message) || ''"
          :base-font-size="baseFontSize"
          :font-class="fontClass")
        carousel-text(v-else :text="baseItem.message", :interval="interval")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.infobar-items-wrapper
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden

  .infobar-item
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch

  .title-text-wrapper
    position: relative
    margin-bottom: 0.2em

  .title-text
    font-size: 1.8em
    line-height: 130%

    // Title height placeholder [DEV-2779]
    &.placeholder
      position: relative
      visibility: hidden
      z-index: -999
      opacity: 0

  .carousel-wrapper
    flex: 1 1 0.0001px
    position: relative
    &.is-twitter
      font-size: 1.5em

  // ==============
  // LANDSCAPE
  // ==============

  &.landscape
    &:not(.is-wide)
      .carousel-wrapper
        .carousel-text-container
          justify-content: flex-start

  // ==============
  // WIDE
  // ==============

  &.is-wide
    &:not(.is-x-wide)
      .title-text.placeholder
        // Clip text to two lines max
        max-height: 2.7em
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical

  // ==============
  // X-WIDE
  // ==============

  &.is-x-wide
    .title-text.placeholder
      ellipsis()

  // ==============
  // PORTRAIT
  // ==============

  &.is-portrait
    .title-text
      font-size: 2em
      padding-bottom: 0.2em

    .carousel-wrapper
      .carousel-text-container
        justify-content: flex-start

  // ==============
  // TALL
  // ==============

  &.is-tall
    .title-text
      font-size: 1.6em
</style>
