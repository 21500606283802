<script>
import Axios from 'axios'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import { createFile as MP4Box } from '../../vendors/mp4box.all.js'
import Log from 'services/log.js'
import Env from 'services/environment'
import Utils from 'services/utils.js'
import Media from 'services/media.js'
import OfflineCaches from 'services/offline-caches'
import ContentCache from 'services/contentCache.js'

const CHUNK_SIZE = 1024 * 1024 * 10
// Maximum number of chunks to append
const MAX_CHUNKS_TO_APPEND = 5
// In seconds. Prevent removing array buffer that's still in use
const CHUNK_DURATION_DELTA = 5
// Counts. Fallback for freezing video
const KICK_PLAYBACK_AFTER = 5

const VIDEO_LENGTH_KEY_BASE = 'video_totalLength'
const HINT_NOT_CHUNKED = 'This video is not chunked or is corrupt, unable to play. Please try re-uploading the video.'
const HINT_NOT_SYNC_TIME = 'Skipping playback, video cannot be downloaded due to video sync scheduling restrictions'

export default {
  name: 'TVideo',
  props: {
    href: {
      type: String,
      required: true
    },
    subtitles: {
      type: String,
      default: ''
    },
    chunkSize: {
      type: Number,
      default: +CHUNK_SIZE
    },
    muted: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    poster: {
      type: String,
      default: ''
    },
    preload: {
      type: String,
      default: ''
    },
    allowNonchunkedPlayback: {
      type: Boolean,
      default: false
    },
    isManuallyPaused: {
      type: Boolean,
      default: false
    },
    checkTotalTimeReliability: {
      type: Boolean,
      default: false
    },
    // fit (default) | fill
    mode: {
      type: String,
      default: 'fit'
    },
    videoSync: {
      type: Object,
      default: () => { return {} }
    }
  },

  data () {
    return {
      mediaSource: null,
      sourceBuffer: null,
      mp4box: null,
      abortController: null,

      bakObjectURL: '',
      mime: '',
      chunkApproxDuration: 0,
      firstChunkDuration: 0,
      contentByteLength: 0,

      totalChunks: 0,
      lastFetchedChunkIndex: -1,
      currentLoadedChunks: [],
      cachedChunks: new Map(),

      firstChunkRendered: false,
      loadingNextChunk: false,
      appendingBuffer: false,
      removingBuffer: false,
      endOfStream: false,
      hasError: false,
      destroyed: false,

      lastCurrentTime: 0,
      currentTimeFreezedCount: 0,

      // For `fill` mode
      videoSize: {},
      videoStyle: {},

      resizeTimer: undefined,
      checkCurrentTimer: undefined
    }
  },

  computed: {
    modeClass () {
      return `mode-${this.mode || 'fit'}`
    },

    // Force mute all videos in Safari [DEV-1047]
    isSafari () {
      return (Env.browserName || '').toLowerCase() === 'safari'
    },

    srcFileName () {
      return Utils.trimedFilePath(this.href)
    },

    needsGradualLoading () {
      return this.totalChunks > MAX_CHUNKS_TO_APPEND
    },

    stillProccessingBuffer () {
      return this.removingBuffer || this.appendingBuffer
    },

    possibleHDVideo () {
      // The first chunk's (10MB) duration is less than 5 seconds
      return this.firstChunkDuration && this.firstChunkDuration < CHUNK_DURATION_DELTA
    },

    alreadyAppendedLastChunk () {
      return this.currentLoadedChunks[this.currentLoadedChunks.length - 1] >= this.totalChunks - 1
    },

    estimatedTotalDuration () {
      if (this.firstChunkDuration <= 0 || this.contentByteLength <= 0 || this.totalChunks <= 0) {
        return -1
      }
      // Tiny file, only one chunk
      if (this.totalChunks === 1) {
        return this.firstChunkDuration
      }
      // Medium size file with all chunks loaded
      if (this.totalChunks <= MAX_CHUNKS_TO_APPEND && this.currentLoadedChunks.length === this.totalChunks) {
        return this.getDuration()
      }
      const chunksCountInFloat = this.contentByteLength / this.chunkSize
      return Math.round(this.firstChunkDuration * chunksCountInFloat * 100) / 100
    }
  },

  watch: {
    async href () {
      await this.resetAllStatus()
      this.initVideoStream()
    },

    estimatedTotalDuration (newVal) {
      if (newVal > 0) {
        this.$emit('duration', newVal)
      }
    },

    totalChunks () {
      this.updateTotalTimeReliability()
    },

    'currentLoadedChunks.length' () {
      this.updateTotalTimeReliability()
    }
  },

  async mounted () {
    clearTimeout(this.resizeTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    this.debounceCheckSize()

    await this.resetAllStatus()
    this.initVideoStream()

    clearInterval(this.checkCurrentTimer)
    this.checkCurrentTimer = setInterval(async () => {
      if (this.destroyed) {
        clearInterval(this.checkCurrentTimer)
        return
      }
      await this.shiftAndLoadNextChunkWhenNeeded()
      this.checkIfVideoFreezed()
    }, 1000)
  },

  async beforeDestroy () {
    this.destroyed = true

    if (this.$refs?.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    clearTimeout(this.resizeTimer)
    clearInterval(this.checkCurrentTimer)

    await this.resetAllStatus()

    if (this.$refs.video) {
      // Clear src to release memory
      this.$refs.video.src = ''
      // Force browser to release resources
      this.$refs.video?.load?.()
    }
  },

  methods: {
    async resetAllStatus () {
      this.clearSourceBuffer()
      await this.clearMediaSource()
      this.cancelAxiosRequest()
      this.clearMp4box()
      this.revokeBakObjectURL()

      this.mime = ''
      this.chunkApproxDuration = 0
      this.firstChunkDuration = 0
      this.contentByteLength = 0

      this.totalChunks = 0
      this.lastFetchedChunkIndex = -1
      this.currentLoadedChunks = []

      if (this.cachedChunks.size > 0) {
        this.cachedChunks.clear()
      }

      this.firstChunkRendered = false
      this.loadingNextChunk = false
      this.appendingBuffer = false
      this.removingBuffer = false
      this.endOfStream = false
      this.hasError = false

      this.lastCurrentTime = 0
      this.currentTimeFreezedCount = 0
    },

    // Note: Not making it a computed property because the document and Env values might not be updated immediately
    windowIsHidden () {
      return document.hidden || Env.appWindowHidden()
    },

    // To start playback from the beginning
    // Only reset some of the status
    async restartGradualPlayback () {
      this.clearSourceBuffer()
      await this.clearMediaSource()
      this.cancelAxiosRequest()
      this.clearMp4box()
      this.revokeBakObjectURL()

      this.mime = ''

      this.lastFetchedChunkIndex = -1
      this.currentLoadedChunks = []

      this.firstChunkRendered = false
      this.loadingNextChunk = false
      this.appendingBuffer = false
      this.removingBuffer = false
      this.endOfStream = false
      this.hasError = false

      this.lastCurrentTime = 0
      this.currentTimeFreezedCount = 0

      this.initVideoStream()
    },

    onMediaSourceOpen () {
      // We are adding ArrayBuffer one by one into the source buffer
      // Which will trigger the `sourceopen` event repeatedly
      // Hence, don't reset the source buffer if it's already created
      if (this.firstChunkRendered) { return }
      this.initFetching()
    },

    onSourceUpdateEnd () {
      this.setSourceBufferEnd()
    },

    initVideoStream () {
      if (!this.href || !this.$refs?.video) { return }

      this.loadPoster()

      if (window.MediaSource) {
        this.mediaSource = new MediaSource()
        this.mediaSource.addEventListener('sourceopen', this.onMediaSourceOpen)

        this.revokeBakObjectURL()

        const objectURL = URL.createObjectURL(this.mediaSource)
        this.$refs.video.src = objectURL
        this.bakObjectURL = objectURL
      } else {
        // Outdated device or Player, stop playback immediately
        Log.error('media', 'The Media Source Extensions API is not supported. Please make sure your device Operating System and Player are up to date.', 'ERR_NOTSUPPORTMEDIAEXT', null, true)
        this.emitError('window.MediaSource not supported')
      }
    },

    async initFetching () {
      const href = this.href || ''
      if (!href) { return }

      // TTV Uploads URLs
      if (ContentCache.isTTVUploads(href)) {
        const success = await this.loadFirstChunk()

        if (!success) {
          // non-success error messages already handled during the `loadFirstChunk` process
          // No need to log them again here
          this.emitError(`Failed to load the first chunk (${this.srcFileName})`)
          return
        }

        // NOTE: Force set end of stream in order to get the first chunk's duration
        await this.forceEndOfStream()

        // Check remaining chunks and fetch them if any
        this.calculateAndFetchRemainingChunks()

      // Non-TTV Uploads URLs
      } else {
        // TTV Resources (predefined background videos)
        if (ContentCache.isResourcesUrl(href)) {
          Log.debug('media', `Play resource video in bulk mode "${this.srcFileName}"`, 'DBG_RESOURCESVIDEO', { url: href })
          this.playNonChunked()
        // External URLs that allowed to play in bulk mode
        } else if (this.allowNonchunkedPlayback) {
          Log.debug('media', `External video, try playing in bulk mode "${this.srcFileName}"`, 'DBG_EXTFILEBULKPLAYBACK', { url: href })
          this.playNonChunked()
        // The other external URLs
        } else {
          const message = `This is an external video and non-chunked playback is not allowed for it "${this.srcFileName}"`
          Log.error('media', message, 'ERR_SKIPPLAYBACKEXTFILE', { url: href }, true)
          this.emitError(message)
        }
      }
    },

    async loadFirstChunk () {
      const chunkUrl = this.getChunkUrl(0)
      if (!chunkUrl) {
        Log.debug('media', `Invalid first chunk URL (${this.srcFileName})`, 'DEB_INVALIDCHUNKURL', { url: this.href })
        return false
      }

      this.endOfStream = false

      const response = await this.fetchChunkFromCacheFirst(chunkUrl)

      if (!response?.headers || !response?.arrayBuffer) {
        if (!response?.headers) {
          Log.warn('media', `Video response headers of the first chunk is empty (${this.srcFileName})`, 'WAR_EMPTYCHUNKHEADERS', { url: this.href })
        }
        if (!response?.arrayBuffer) {
          Log.warn('media', `Video ArrayBuffer of the first chunk is empty (${this.srcFileName})`, 'WAR_EMPTYCHUNKBUFFER', { url: this.href })
        }
        return false
      }

      const contentRange = response.headers.get('Content-Range')
      const contentRangeSplit = contentRange ? contentRange.split('/')[1] : null
      const byteLengthFromContentRange = contentRange && contentRangeSplit ? parseInt(contentRangeSplit, 10) : 0
      const chunkContentLength = parseInt(response.headers.get('Content-Length'), 10) || 0

      // Valid content total length found in the response header
      if (byteLengthFromContentRange > 0) {
        this.contentByteLength = byteLengthFromContentRange
      // Total content length not found in 'Content-Range' header
      // But the first chunk's size is less than the defined chunk size --> has only one chunk
      } else if (chunkContentLength > 0 && chunkContentLength < this.chunkSize) {
        this.contentByteLength = chunkContentLength
        this.totalChunks = 1
      }

      let success = false
      let arrayBuffer = await this.getArrayBufferFromResponse(response, true)
      if (arrayBuffer) {
        success = await this.handleFirstArrayBuffer(arrayBuffer)
      }
      // Release the buffer memory
      arrayBuffer = null

      if (success) {
        this.cachedChunks.set(0, chunkUrl)
      }
      // Non-success error messages already handled during the `getArrayBufferFromResponse` and `handleFirstArrayBuffer` process
      // No need to handle them again here
      return success
    },

    async handleFirstArrayBuffer (arrayBuffer) {
      return new Promise((resolve) => {
        arrayBuffer.fileStart = 0

        // The Boolean parameter is "_keepMdatData"
        // Indicating if bytes containing media data should be kept in memory.
        this.mp4box = new MP4Box(false)

        // onReady callback will be called when the the 'moov' box has been parsed,
        // i.e. when the metadata about the file is parsed.
        this.mp4box.onReady = async (info) => {
          const success = await this.onMP4BoxReady(info, arrayBuffer)
          // Clean arrayBuffer after successful processing or failure
          arrayBuffer = null
          if (success) {
            this.firstChunkRendered = true
            this.currentLoadedChunks = [0]
            this.lastFetchedChunkIndex = 0
            resolve(true)
          } else {
            resolve(false)
          }
        }

        // Handle error occurred during the processing
        // NOTE: "err" is a String (defined in MP4Box.js)
        this.mp4box.onError = (err) => {
          this.onMP4BoxError(err)
          // Clean arrayBuffer on error
          arrayBuffer = null
          resolve(false)
        }

        // Append the first buffer to the MP4Box
        let nextBufferStart = 0
        try {
          nextBufferStart = this.mp4box.appendBuffer(arrayBuffer)
        } catch (e) {
          nextBufferStart = -1
          // Clean arrayBuffer on error
          arrayBuffer = null
          Log.warn('media', `Error when appending mp4box buffer (${this.srcFileName})`, 'WAR_ERRORAPPENDMP4BUFFER', { error: e, url: this.href})
        }

        // Actual buffer size not matching
        if (nextBufferStart !== arrayBuffer.byteLength) {
          // Previous "WAR_NOTMP4FILE"
          const payload = {
            nextBufferStart,
            arrayBufferLength: +arrayBuffer?.byteLength || 0,
            url: this.href
          }

          if (ContentCache.isResourcesUrl(this.href)) {
            Log.debug('media', `Play resource video in bulk mode "${this.srcFileName}"`, 'DBG_RESOURCESVIDEO2', payload)
            this.playNonChunked()
          } else if (this.allowNonchunkedPlayback) {
            if (ContentCache.isTTVUploads(this.href)) {
              Log.warn('media', `Corrupt video, unable to cache. (${this.srcFileName})`, 'WAR_CORRUPTUNCACHE', payload)
            } else {
              Log.warn('media', 'This external video is corrupt or non-chunkable. Start playing it in bulk.', 'WAR_CORRUPTEXTFILE', payload)
            }
            this.playNonChunked()
          } else {
            const errMsg = `${HINT_NOT_CHUNKED} (${this.srcFileName})`
            Log.error('media', errMsg, 'ERR_CORRUPTFILE', payload, true)
            this.emitError(errMsg)
          }

          resolve(false)
        }

        this.mp4box.flush()
        // Wait for the 'resolved' from either `onReady` or `onError`
      })
    },

    async calculateAndFetchRemainingChunks () {
      // The "Content-Range" header was not found in first chunk response.
      // Try fethcing the value from the API
      if (!this.contentByteLength || this.contentByteLength <= 0) {
        try {
          const length = await this.getContentLength()
          if (length) {
            this.contentByteLength = length || 0
          } else {
            Log.warn('media', `Video Content-Length could not be fetched (${this.srcFileName})`, 'WAR_CONTENTLENGTHFETCH', { url: this.href })
          }
        } catch (err) {
          const errMsg = err.message || err.toString() || ''
          const loweredMsg = errMsg.toLowerCase()
          if (
            loweredMsg.includes('timeout') ||
            loweredMsg.includes('network error') ||
            loweredMsg.includes('networkerror')
          ) {
            Log.info('media', `Video Content-Length could not be fetched due to network error (${this.srcFileName})`, 'INF_CONTENTLENGTHFETCH2', { url: this.href, error: err })
          } else {
            Log.warn('media', `Video Content-Length could not be fetched (${this.srcFileName}) - "${errMsg}"`, 'WAR_CONTENTLENGTHFETCH3', { url: this.href, error: err })
          }
        }
        // Still no content length value
        if (!this.contentByteLength || this.contentByteLength <= 0) {
          // This is a fallback for those that don't have internet connection and can't fetch the content length at this moment
          Log.debug('media', `Unable to fetch the content length, try playing from existing caches (${this.srcFileName})`, 'DEB_TRYPLAYFROMCACHE', { url: this.href })
          this.playFromOldCaches()
          return
        }
      }

      // The `contentByteLength` is available and larger than 0
      const totalChunks = Math.max(1, Math.ceil(this.contentByteLength / this.chunkSize))
      this.totalChunks = totalChunks

      // Multiple chunks
      if (totalChunks > 1) {
        this.endOfStream = false
        this.loopToLoadNextChunk()
      // Single chunk, and already appended
      } else {
        await this.forceEndOfStream()
      }
    },

    // Start from the 2nd chunk till the end
    async loopToLoadNextChunk () {
      if (
        // Still processing last call
        this.loadingNextChunk ||
        // Tiny file, only one chunk, or MediaSource is not ready
        this.totalChunks <= 1 ||
        // Error occurred in some chunk(s)
        this.hasError
      ) { return }

      // No more chunks to fetch
      if (this.lastFetchedChunkIndex >= this.totalChunks - 1) {
        if (this.totalChunks <= MAX_CHUNKS_TO_APPEND) {
          await this.forceEndOfStream()
        }
        return
      }

      this.loadingNextChunk = true

      const nextChunkIndex = this.lastFetchedChunkIndex + 1

      // When the next chunk's index is ahead of the maximum chunks to append at this moment.
      // We will not append it to the SourceBuffer immediately,
      // But check if it exists in the cache, if not, fetch and cache it.
      const checkIfCachedOnly = this.currentLoadedChunks[0] + MAX_CHUNKS_TO_APPEND - 1 < nextChunkIndex

      const chunkUrl = this.getChunkUrl(nextChunkIndex)

      let success = false
      if (checkIfCachedOnly && this.cachedChunks.get(nextChunkIndex) === chunkUrl) {
        success = true
      } else {
        success = await this.fetchChunkOnDemand(nextChunkIndex, checkIfCachedOnly)
      }

      // Need to append to the SourceBuffer
      if (!checkIfCachedOnly) {
        // Successfullly fetched and appended the chunk
        if (success) {
          this.forceUpdateCurrentTime()

        // Failed to load or append the chunk
        } else {
          // Error came from the next chunk in the playback queue
          // E.g. When playing index 1, error came from index 2
          // Stop looping the rest and force end of stream
          if (nextChunkIndex === this.currentLoadedChunks[0] + 1) {
            Log.warn('media', `Failed to load the next chunk (index: ${nextChunkIndex}, total: ${this.totalChunks}), will play until the buffer ends (${this.srcFileName})`, 'WAR_FAILEDPRELOADCHUNK', { url: this.href })
            await this.forceEndOfStream()
            this.lastFetchedChunkIndex = nextChunkIndex
            this.hasError = true
            this.loadingNextChunk = false
            return
          }
        }
      }

      this.lastFetchedChunkIndex = nextChunkIndex
      this.loadingNextChunk = false

      this.loopToLoadNextChunk()
    },

    // Append latter chunks to the SourceBuffer
    // Usually starting from the $MAX_CHUNKS_TO_APPEND chunk, after removing the first active chunk
    async loadAndAppendNextChunk () {
      if (
        // Error occurred in some chunk(s)
        this.hasError ||
        // Tiny file, only one chunk, or MediaSource is not ready
        this.totalChunks <= 1 ||
        !this.currentLoadedChunks.length ||
        // The last chunk is already appended
        this.alreadyAppendedLastChunk
      ) { return }

      const nextChunkIndex = this.currentLoadedChunks[this.currentLoadedChunks.length - 1] + 1

      // Fallback checking for last chunk again
      if (nextChunkIndex >= this.totalChunks) {
        await this.forceEndOfStream()
        return
      }

      this.endOfStream = false

      const success = await this.fetchChunkOnDemand(nextChunkIndex)

      if (!success) {
        Log.warn('media', `Failed to load and append the upcoming chunk (index: ${nextChunkIndex}, total: ${this.totalChunks}), will play until the buffer ends (${this.srcFileName})`, 'WAR_FAILEDLOADAPPENDCHUNK', { url: this.href })
        this.hasError = true
        await this.forceEndOfStream()
        this.forceUpdateCurrentTime()
        return
      }

      if (this.alreadyAppendedLastChunk) {
        await this.forceEndOfStream()
      }

      this.forceUpdateCurrentTime()
    },

    async appendSourceBuffer (arrayBuffer, isFirstChunk = false) {
      if (this.stillProccessingBuffer) {
        // Stilling removing or appending buffer, wait for it to finish
        await this.waitForSourceBufferUpdateEnd()
        return this.appendSourceBuffer(arrayBuffer, isFirstChunk)
      }

      if (!this.sourceBuffer) {
        // Clean arrayBuffer if sourceBuffer is not available
        arrayBuffer = null
        Log.debug('media', `Skip appendBuffer since the SourceBuffer is already gone (${this.srcFileName})`, 'DEB_SOURCEBUFFERNULL', { url: this.href })
        return false
      }

      this.appendingBuffer = true

      let success = true
      try {
        // Not async, the source buffer could be still 'updating' for some time
        this.sourceBuffer.appendBuffer(arrayBuffer)
        // Clean arrayBuffer after successful append
        // We can safely clean it here because sourceBuffer makes a copy of the data
        arrayBuffer = null
      } catch (e) {
        const errorType = this.sourceBufferErrorHandler(e, 'appendBuffer')
        // Clean arrayBuffer on error
        arrayBuffer = null

        // SourceBuffer is full, unable to append ArrayBuffer
        if (errorType === 'isFull') {
          if (isFirstChunk) {
            Log.warn('media', `The ArrayBuffer of the first chunk is too large, unable to append to SourceBuffer due to browser limitation (${this.srcFileName})`, 'WAR_FULLBUFFERON1STCHUNK', { url: this.href })
            this.emitError('The first chunk is too large, unable to play.')
          } else {
            Log.debug('media', `SourceBuffer is full, unable to append more ArrayBuffer, will try again later (${this.srcFileName})`, 'DEB_FAILEDAPPENDTOFULLSB', { url: this.href })
          }
        }
        success = false
      }

      await this.waitForSourceBufferUpdateEnd()
      this.appendingBuffer = false

      return success
    },

    async removePlayedBuffer (start = 0, end = 0, shiftChunksAndLoadNext = false) {
      if (this.stillProccessingBuffer) { return }

      if (!this.sourceBuffer) {
        this.removingBuffer = false
        return
      }

      if (start < 0 || end <= 0 || end <= start) {
        Log.debug('media', `Invalid time range for removing chunk - start: ${start}, end: ${end} (${this.srcFileName})`, 'DEB_INVALIDREMOVETIME', { url: this.href })
        return
      }

      this.removingBuffer = true

      try {
        await this.waitForSourceBufferUpdateEnd()
        this.sourceBuffer.remove(start, end)
      } catch (e) {
        this.sourceBufferErrorHandler(e, 'remove')
      }

      await this.waitForSourceBufferUpdateEnd()

      if (shiftChunksAndLoadNext) {
        this.currentLoadedChunks.shift()
      }

      this.removingBuffer = false

      this.forceUpdateCurrentTime()

      if (shiftChunksAndLoadNext) {
        await this.loadAndAppendNextChunk()
      }
    },

    async shiftAndLoadNextChunkWhenNeeded () {
      if (
        // First chunk is not rendered yet
        this.totalChunks <= 0 ||
        // Small file, no need to remove chunks
        !this.needsGradualLoading ||
        // Chunk duration is not available yet (Or, somehow the video duration is not accessible)
        this.chunkApproxDuration <= 0 ||
        // Not enough chunks to remove
        // Note: not using `<= MAX_CHUNKS_TO_APPEND` here because some edge cases the SourceBuffer can be full with only two chunk
        this.currentLoadedChunks.length <= 1 ||
        // Already appended the last chunk
        this.alreadyAppendedLastChunk ||
        // Error occurred in some chunk(s)
        this.hasError
      ) { return }

      const currentTime = this.currentTime()
      if (!currentTime || currentTime <= 0) { return }

      const activeFirstChunkIndex = this.currentLoadedChunks[0]

      const delta = this.possibleHDVideo
        ? ~~((this.firstChunkDuration / 2) * 100) / 100
        : CHUNK_DURATION_DELTA

      const nextCheckPoint = this.possibleHDVideo
        ? ~~(this.firstChunkDuration * (activeFirstChunkIndex + 1) * 100) / 100
        : this.chunkApproxDuration * (activeFirstChunkIndex + 1)

      // Note that the video `currentTime` remains even after early buffer is removed
      if (currentTime > nextCheckPoint + delta) {
        this.removePlayedBuffer(0, nextCheckPoint, true)
      }
    },

    async checkIfVideoFreezed () {
      // Not rendered yet
      if (!this.firstChunkRendered || this.totalChunks <= 0) { return }

      // Window is hidden.
      // Chrome will reject any video playback request when the window is not visible.
      if (this.windowIsHidden()) { return }

      // Video is manually paused by user with features like:
      // Media Folder > Enable Tap Interaction
      if (this.isManuallyPaused && this.isPaused()) { return }

      const currentTime = this.currentTime()

      // Video is freezed
      if (currentTime === this.lastCurrentTime) {
        this.currentTimeFreezedCount++

        // Reached the count threshold, Force kick to play again
        if (this.currentTimeFreezedCount >= KICK_PLAYBACK_AFTER) {
          Log.debug('media', `Video already freezed for ${this.currentTimeFreezedCount}s, force playback again (${this.srcFileName})`, 'DBG_VIDEOFREEZED', { url: this.href })

          await this.waitForSourceBufferUpdateEnd()

          const leapTo = currentTime + 1
          this.setCurrentTime(leapTo)
          this.lastCurrentTime = leapTo

          if (this.isPaused()) {
            this.runPlayPromise('froze', 'WAR_VIDERRFROZEPLAYBACK')
          }
        }

      // Video is playing
      } else {
        this.lastCurrentTime = currentTime
        this.currentTimeFreezedCount = 0
      }
    },

    async onMP4BoxReady (info, arrayBuffer) {
      const isValid = await this.checkMp4BufferValidity(info)
      // Not properly fragmented video
      if (!isValid) { return false }
      // Regular fragmented video
      this.mime = info.mime
      this.sourceBuffer = this.mediaSource.addSourceBuffer(this.mime)
      this.sourceBuffer.mode = 'sequence'
      this.sourceBuffer.addEventListener('updateend', this.onSourceUpdateEnd)
      await this.appendSourceBuffer(arrayBuffer, true)
      return true
    },

    onMP4BoxError (err = '') {
      const errMsg = `MP4Box error: ${err} (${this.srcFileName})`
      Log.error('media', errMsg, 'ERR_MP4BOX', { url: this.href })
      if (this.allowNonchunkedPlayback) {
        this.playNonChunked()
      } else {
        this.emitError(errMsg)
      }
    },

    checkMp4BufferValidity (info) {
      // Not supported video type
      if (!MediaSource.isTypeSupported(info.mime)) {
        const errMsg = `This video type is not supported "${info.mime}" (${this.srcFileName})`
        Log.warn('media', errMsg, 'WAR_MIMETYPENOTSUPPORTED', { url: this.href })
        this.emitError(errMsg)
        return false
      }
      // Not fragmented video
      if (!info.isFragmented) {
        if (this.allowNonchunkedPlayback) {
          Log.warn('media', `This video file is not fragmented (${this.srcFileName})`, 'WAR_VIDEONOTFRAGMENTED', { url: this.href })
          this.playNonChunked()
          return false
        } else {
          const errMsg = `${HINT_NOT_CHUNKED} (${this.srcFileName})`
          Log.error('media', errMsg, 'ERR_CORRUPTFILE2', { url: this.href }, true)
          this.emitError(errMsg)
          return false
        }
      }
      // Media Source is already 'closed' or 'ended'
      if (!this.mediaSource || this.mediaSource?.readyState !== 'open') {
        Log.warn('media', `MediaSource is already closed or ended (${this.srcFileName})`, 'WAR_MEDIASRCGONE', { url: this.href }, true)
        return false
      }
      return true
    },

    async fetchChunkOnDemand (chunkIndex, checkIfCachedOnly = false) {
      const chunkUrl = this.getChunkUrl(chunkIndex)
      if (!chunkUrl) {
        Log.debug('media', `Invalid chunk[${chunkIndex}] URL (${this.srcFileName})`, 'DEB_INVALIDCHUNKURL2', { url: this.href })
        return false
      }

      if (checkIfCachedOnly) {
        const exists = await ContentCache.existsInCache(ContentCache.VIDEOS, chunkUrl, true)
        if (exists) {
          this.cachedChunks.set(chunkIndex, chunkUrl)
          return true
        }
      }

      // Not exists, or needs to download
      const response = await this.fetchChunkFromCacheFirst(chunkUrl)

      if (!response?.arrayBuffer) {
        Log.warn('media', `Video ArrayBuffer of chunk[${chunkIndex}] is empty (${this.srcFileName})`, 'WAR_EMPTYCHUNKBUFFER2', { url: this.href })
        return false
      }

      this.cachedChunks.set(chunkIndex, chunkUrl)

      // Need to append to the SourceBuffer
      if (!checkIfCachedOnly) {
        let arrayBuffer = await this.getArrayBufferFromResponse(response)
        if (arrayBuffer) {
          const success = await this.appendSourceBuffer(arrayBuffer)
          // Clean arrayBuffer after processing (success or failure)
          arrayBuffer = null
          if (success) {
            this.currentLoadedChunks.push(chunkIndex)
            return true
          }
        }
        // Non-success error messages already handled during the `appendSourceBuffer` and `appendSourceBuffer` process
        return false
      }
      return true
    },

    // Fallback for:
    // - device is offline, and
    // - the cached video is missing the "Content-Range" header
    async playFromOldCaches () {
      const items = await ContentCache.searchInCache(ContentCache.VIDEOS, this.href)
      if (!items || items.length === 0) {
        this.forceEndOfStream()
        this.emitError('Video not found in cache')
        return
      }

      // There's only one chunk in cache, and it's been buffered by "handleFirstArrayBuffer".
      // Skip the rest part and called end of stream.
      if (items.length === 1) {
        this.forceEndOfStream()
        return
      }

      // Sort items by URL `&range=$START-$END` value
      items.sort((a, b) => {
        const rangeA = this.getRangeStartFromURL(a.url)
        const rangeB = this.getRangeStartFromURL(b.url)
        return rangeA < rangeB ? -1 : 1
      })

      let lastChunkIndex = -1
      items.forEach((item, index) => {
        const startRange = this.getRangeStartFromURL(item.url)
        if (startRange < 0) { return }
        const chunkIndex = ~~(startRange / this.chunkSize)
        this.cachedChunks.set(chunkIndex, item.url)
        if (index === items.length - 1) {
          lastChunkIndex = chunkIndex
        }
      })

      // Fallback for missing some chunks in the middle
      if (lastChunkIndex >= 0 && lastChunkIndex !== this.cachedChunks.size - 1) {
        this.totalChunks = lastChunkIndex + 1
      // Regular case with all chunks
      } else {
        this.totalChunks = this.cachedChunks.size
      }

      this.loopToLoadNextChunk()
    },

    // Play in bulk mode
    async playNonChunked () {
      await this.resetAllStatus()

      if (!this.$refs?.video || !this.href?.length) { return }

      if (this.href.startsWith('file://')) {
        Log.debug('media', `Loading local video (non-chunked) "${this.srcFileName}"`, 'DBG_LOCALVIDEOLOAD', { url: this.href })
      } else if (ContentCache.isTTVUploads(this.href)) {
        Log.info('media', `Loading TTV upload video (non-chunked) "${this.srcFileName}"`, 'INF_TTVUPLOADVIDEOLOAD', { url: this.href })
      } else if (ContentCache.isProxiedUrl(this.href)) {
        Log.info('media', `Loading TTV Proxied video (non-chunked) "${this.srcFileName}"`, 'INF_TTVPROXIEDVIDEOLOAD', { url: this.href })
      } else if (ContentCache.isCdnUrl(this.href)) {
        Log.info('media', `Loading TTV CDN video (non-chunked) "${this.srcFileName}"`, 'INF_TTVCDNVIDEOLOAD', { url: this.href })
      } else if (ContentCache.isResourcesUrl(this.href)) {
        Log.debug('media', `Loading TTV resource video (non-chunked) "${this.srcFileName}"`, 'DBG_RESOURCESVIDEOLOAD', { url: this.href })
      } else {
        Log.debug('media', `Loading external video (non-chunked) "${this.srcFileName}"`, 'DBG_EXTERNALVIDEOLOAD', { url: this.href })
      }

      this.$refs.video.src = this.href
    },

    checkIfDownloadAllowed () {
      let download = true
      // Check Video Sync timeframe
      if (this.videoSync.start && this.videoSync.end) {
        download = Media.inVideoSyncTime(this.videoSync)
      // Check whether device has internal cache errors [DEV-4523]
      } else if (ContentCache.forceCachedPlayback) {
        download = false
      }
      return download
    },

    getChunkUrl (index = 0) {
      if (!this.href || index < 0) { return '' }

      const begin = index * this.chunkSize
      let end = begin + this.chunkSize
      if (this.contentByteLength > 0) {
        end = Math.min(end, this.contentByteLength)
      }

      const url = new URL(this.href)
      url.searchParams.set('noserviceworker', 'true')
      url.searchParams.set('range', `${begin}-${end}`)
      return url.toString()
    },

    async getContentLength () {
      let contentLength = await OfflineCaches.get(`${VIDEO_LENGTH_KEY_BASE}_${this.href}`)
      if (contentLength) {
        return contentLength
      }

      // Cancel previous request if exists
      this.cancelAxiosRequest()
      // Create new AbortController
      this.abortController = new AbortController()

      try {
        const response = await Axios.get(
          `${Env.cdnURL()}/uploads/content_length?path=${this.href}`,
          { 
            responseType: 'json',
            timeout: 5000,
            signal: this.abortController.signal
          }
        )
        if (response?.data?.content_length) {
          contentLength = response.data.content_length
          await OfflineCaches.set(`${VIDEO_LENGTH_KEY_BASE}_${this.href}`, parseInt(contentLength, 10))
          return contentLength
        }
      } catch (err) {
        // Only throw error to the caller for non-aborted errors
        if (err.name !== 'AbortError') {
          throw err
        }
      }
    },

    getRangeStartFromURL (url = '') {
      const urlSearchParams = new URLSearchParams(url.split('?')[1])
      const range = urlSearchParams.get('range')
      if (!range) { return -1 }
      return parseInt(range.split('-')[0], 10)
    },

    async fetchChunkFromCacheFirst (chunkUrl) {
      const shouldDownload = this.checkIfDownloadAllowed()
      const response = await ContentCache.cachedFetch(ContentCache.VIDEOS, chunkUrl, shouldDownload)
        .catch(err => {
          if (err.name && err.name.indexOf('NotInCache') >= 0) {
            if (shouldDownload) {
              err = 'fetch failed'
              Log.warn('media', `ContentCache.cachedFetch() error "${err.toString()}" (${this.srcFileName})`, 'WAR_CONTENTFETCHFAIL', { url: chunkUrl, error: err })
            } else {
              err = HINT_NOT_SYNC_TIME + ''
              Log.warn('media', `${err} (${this.srcFileName})`, 'WAR_CONTENTTIMEOUTSYNC', { url: chunkUrl, error: err })
            }
            this.$store.commit('removeCachedContentURL', {url: chunkUrl, cacheName: ContentCache.VIDEOS})
          }
          this.emitError(err)
        })
      return response
    },

    async getArrayBufferFromResponse (response, isFirstChunk = false) {
      let arrayBuffer
      try {
        arrayBuffer = await response.arrayBuffer()
        return arrayBuffer
      } catch (err) {
        const errString = `${(err?.message || err?.toString?.()) || err}`
        // Identified error cases
        if (
          (err?.name === 'AbortError') || /abort/i.test(errString)
        ) {
          // AbortError is safe to ignored - It happens when switching to another video
          return
        } else if (
          // Temporally network error
          /failed to fetch/i.test(errString) ||
          // "Blob loading failed" currently only seen in Safari (not our recommended browser)
          // And it's related to cache read/write error
          /blob loading failed/i.test(errString)
        ) {
          Log.warn('media', `response.arrayBuffer() error "${errString}" (${this.srcFileName})`, 'WAR_VIDEORESPONSEERR', { url: response.url || this.href, error: err }, true)
        // Keep reporting unknown errors
        } else {
          Log.error('media', `response.arrayBuffer() error "${errString}" (${this.srcFileName})`, 'ERR_VIDEOBUFFER', { url: response.url || this.href, error: err })
        }
        if (isFirstChunk) {
          this.emitError(err)
        }
        arrayBuffer = null
        return null
      }
    },

    async waitForSourceBufferUpdateEnd () {
      // Temporally workaround before the experimental `appendBufferAsync` is available in all modern browsers
      // > https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/appendBufferAsync
      return new Promise((resolve) => {
        if (this.sourceBuffer?.updating) {
          this.sourceBuffer.addEventListener('updateend', () => {
            resolve()
          }, { once: true })
        } else {
          resolve()
        }
      })
    },

    // Called when loaded enough ArrayBuffer to play, or when any chunk has error
    async forceEndOfStream () {
      this.endOfStream = true
      await this.waitForSourceBufferUpdateEnd()
      this.setSourceBufferEnd()
    },

    setSourceBufferEnd () {
      if (
        this.endOfStream &&
        !this.sourceBuffer?.updating &&
        this.mediaSource?.readyState === 'open'
      ) {
        const buffered = this.sourceBuffer?.buffered
        this.mediaSource.endOfStream()

        // Non-TTV-Uploads URLs use non-chunked mode, safe to skip warning report
        // - like Resources URLs, CDN URLs or proxied videos
        if (!ContentCache.isTTVUploads(this.href)) { return }

        // Video chunks with valid buffer should have length >= 1.
        // If not, it means the video is not playable
        if (!buffered?.length) {
          Log.warn('media', `The video cache is incomplete or invalid, unable to play (${this.srcFileName})`, 'WAR_VIDBUFFERINVALID', { url: this.href })
          this.emitError('The "TimeRanges" of video buffering is not available, unable to play.')
        }
      }
    },

    sourceBufferErrorHandler (err, method = '') {
      const errMsg = err.message || err.toString() || `${err}`
      const errName = err.name || ''

      // Unable to append more ArrayBuffer due to browser's limitation
      // > https://developer.chrome.com/blog/quotaexceedederror#how_much_data_can_i_append
      if (
        // Chrome
        /SourceBuffer is full/i.test(errMsg) ||
        /cannot free space/i.test(errMsg) ||
        // Firefox
        /MediaSource buffer not sufficient/i.test(errMsg) ||
        // Android
        /Unable to allocate space/i.test(errMsg) ||
        // General
        /quota has been exceeded/i.test(errMsg) ||
        errName === 'QuotaExceededError'
      ) {
        Log.debug('media', `Unable to "${method}" because the SourceBuffer is full due to browser limitation: "${errMsg}" (${this.srcFileName})`, 'DEB_SOURCEBUFFERFULL', { url: this.href })
        return 'isFull'
      }

      // SourceBuffer is still processing, or has been removed from MediaSource
      if (errName === 'InvalidStateError') {
        // SourceBuffer 'updating' state is true
        if (
          /SourceBuffer is still processing/i.test(errMsg) ||
          /is not, or is no longer, usable/i.test(errMsg)
        ) {
          Log.debug('media', `SourceBuffer is still processing: "${errMsg}" (${this.srcFileName})`, 'DBG_SOURCEBUFFERBUSY', { url: this.href })
          return 'isBusy'
        }
        // SourceBuffer is gone
        Log.warn('media', `SourceBuffer is no longer available: "${errMsg}" (${this.srcFileName})`, 'WAR_SOURCEBUFFERGONE', { url: this.href })
        return 'isGone'
      }

      if (errName === 'InvalidAccessError') {
        if (method === 'remove') {
          // Indicates the 'start' or 'end' is not valid
          Log.warn('media', `SourceBuffer.remove() is called with invalid "start" or "end" value: "${errMsg}" (${this.srcFileName})`, 'WAR_INVALIDSTARTEND', { url: this.href, error: err })
        }
        return 'isInvalid'
      }

      // Unknow error
      Log.error(
        'media',
        `SourceBuffer ${method} error: "${errMsg}" (${this.srcFileName}) - ${err}`,
        'ERR_SOURCEBUFFER',
        { url: this.href, error: err }
      )
    },

    async emitError (e) {
      this.hasError = true
      this.$emit('error', e)
      await this.resetAllStatus()
    },

    emit (e) {
      if (e.type === 'durationchange') {
        this.calculateChunkDuration(e)
      }
      if (e.type === 'loadedmetadata') {
        this.setVideoSize(e)
      }
      if (e.type === 'ended') {
        this.handleVideoEnd()
      }
      this.$emit(e.type, e)
    },

    play () {
      return this.$refs?.video?.play?.()
    },

    runPlayPromise (fromStep = '', errorCode = '') {
      const playPromise = this.play()
      if (
        playPromise &&
        typeof playPromise.then === 'function' &&
        typeof playPromise.catch === 'function'
      ) {
        playPromise.catch(error => {
          if (error.name === 'AbortError') {
            // AbortError is expected when:
            // - Switching to another video, or
            // - Switching to another page without any video
            // - Or window is hidden (reject by browser-level)
            // So it's safe to ignore it.
            return
          }
          if (fromStep?.length && errorCode?.length) {
            Log.warn('media', `Failed to play video (${this.srcFileName}) after ${fromStep} with error ${error}`, errorCode, { url: this.href, error })
          } else {
            Log.warn('media', `Failed to play video (${this.srcFileName}) with error ${error}`, 'WAR_VIDEOPLAYPROMISEERROR', { url: this.href, error })
          }
        })
      }
    },

    isPaused () {
      return this.$refs?.video?.paused || false
    },

    pause () {
      return this.$refs?.video?.pause?.()
    },

    load () {
      return this.$refs?.video?.load?.()
    },

    currentTime () {
      return this.$refs?.video?.currentTime || 0
    },

    setCurrentTime (newValue) {
      if (!this.$refs?.video) { return }
      this.$refs.video.currentTime = Math.max(0, newValue)
    },

    forceUpdateCurrentTime () {
      // NOTE: A trick to prevent currentTime keep increasing while the video footage is indeed freezing
      this.setCurrentTime(this.currentTime())
    },

    getDuration () {
      return this.$refs?.video?.duration
    },

    calculateChunkDuration (e) {
      // Only record for the first time
      if (this.chunkApproxDuration > 0) { return }

      if (e?.target?.duration) {
        const duration = e.target.duration

        if ((isFinite(duration) && !isNaN(duration) && duration > 0)) {
          // Actual duration of the first chunk
          this.firstChunkDuration = duration

          // Note that this is approximate duration of each chunk
          // Since every chunk can have slightly different duration
          this.chunkApproxDuration = Math.ceil(duration)
        }
      }
    },

    updateTotalTimeReliability () {
      // Checking not required
      if (!this.checkTotalTimeReliability) { return }
      // No chunks loaded yet
      if (this.totalChunks <= 0 || !this.currentLoadedChunks.length) { return }

      // Is a medium or small size file, with all chunks are loaded
      if (this.totalChunks <= MAX_CHUNKS_TO_APPEND && this.totalChunks === this.currentLoadedChunks.length) {
        this.$emit('total-time-reliable', true)
      // Possible large file, or only some of the chunks are loaded at this moment
      } else {
        this.$emit('total-time-reliable', false)
      }
    },

    restartPlayback () {
      if (this.needsGradualLoading) {
        this.restartGradualPlayback()
      } else {
        this.setCurrentTime(0)
        if (this.isPaused() && this.$refs?.video) {
          this.runPlayPromise('restart', 'WAR_VIDERRRESTART')
        }
      }
    },

    handleVideoEnd () {
      // Clear poster image on video ended
      if (this.$refs?.video?.setAttribute) {
        this.$refs.video.setAttribute('poster', '')
      }

      // Loop is required, restart immediately
      if (this.loop) {
        this.restartPlayback()
      }
    },

    clearSourceBuffer () {
      if (this.sourceBuffer) {
        try {
          // Remove event listener first
          this.sourceBuffer.removeEventListener('updateend', this.onSourceUpdateEnd)

          // Only call abort() if the sourceBuffer is still usable
          if (this.mediaSource?.readyState === 'open') {
            try {
              this.sourceBuffer.abort?.()
            } catch (abortErr) {
              // Safe to ignore abort errors since the sourceBuffer might be in an invalid state
              // Logged as debug level just in case
              Log.debug('media', `Unable to abort SourceBuffer: ${abortErr.message || abortErr.toString()} (${this.srcFileName})`, 'DEB_ABORTSOURCEBUFFER', { url: this.href, error: abortErr })
            }
          }

          // Clear the reference
          this.sourceBuffer = null
        } catch (err) {
          Log.debug('media', `Failed to clear SourceBuffer: ${err.message || err.toString()} (${this.srcFileName})`, 'DEB_CLEARSOURCEBUFFER', { url: this.href, error: err })
        }
      }
    },

    async clearMediaSource () {
      if (this.mediaSource) {
        try {
          this.mediaSource.removeEventListener('sourceopen', this.onMediaSourceOpen)
          await this.forceEndOfStream()
          this.mediaSource = null
        } catch (err) {
          Log.debug('media', `Failed to clear MediaSource: ${err.message || err.toString()} (${this.srcFileName})`, 'DEB_CLEARMEDIASOURCE', { url: this.href, error: err })
        }
      }
    },

    cancelAxiosRequest () {
      if (this.abortController) {
        this.abortController.abort?.()
        this.abortController = null
      }
    },

    revokeBakObjectURL () {
      if (this.bakObjectURL) {
        URL.revokeObjectURL(this.bakObjectURL)
      }
      this.bakObjectURL = ''
    },

    clearMp4box () {
      this.mp4box?.flush?.()
      this.mp4box = null
    },

    setVideoSize (e) {
      if (!e || !e.target || !e.target.clientWidth || !e.target.clientHeight) {
        this.videoSize = {}
        return
      }
      this.videoSize = {
        w: e.target.clientWidth,
        h: e.target.clientHeight
      }
      this.debounceCheckSize()
    },

    checkSize () {
      if (this.mode !== 'fill' || !this.videoSize?.w || !this.videoSize?.h || !this.$el) {
        this.videoStyle = null
        return
      }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          this.videoStyle = null
          FastDom.clear(measure)
          return
        }
        width = container.clientWidth || container.offsetWidth
        height = container.clientHeight || container.offsetHeight

        if (!width || !height) {
          this.videoStyle = null
          FastDom.clear(measure)
          return
        }

        const sizeRatio = Math.max(
          width / this.videoSize.w,
          height / this.videoSize.h
        )
        this.videoStyle = {
          width: `${sizeRatio * this.videoSize.w}px`,
          height: `${sizeRatio * this.videoSize.h}px`
        }

        FastDom.clear(measure)
      })
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        clearTimeout(this.resizeTimer)
        this.checkSize()
      }, timeout || 200)
    },

    loadPoster () {
      if (!(Env.pageEditMode && Env.fromPlaylistEditor)) { return }

      let href = this.href || ''

      // Only TTV Uploads and Resources URLs have thumb images
      if (!ContentCache.isTTVUploads(href) && !ContentCache.isResourcesUrl(href)) { return }

      href = href.split('.').slice(0, -1).join('.')
      href = `${href}.thumb.png?noserviceworker=true`
      ContentCache.cachedFetch(ContentCache.IMAGES, href, true).then(response => {
        if (response.ok) {
          if (this.$refs?.video?.setAttribute) {
            this.$refs.video.setAttribute('poster', href)
          }
        }
      }).catch(err => {
        Log.debug('media', `Can't load poster image "${href}" - ${err.message || err.toString()} (${this.srcFileName})`, 'DBG_LOADPOASTERIMG', { error: err, url: this.href }, true)
      })
    }
  }
}
</script>

<template lang="pug">
.t-video(:class="modeClass")
  .resize-sensor(ref="sensor")

  video(ref="video"
        :muted="isSafari || muted"
        :autoplay="autoplay"
        :controls="controls"
        :poster="poster"
        :preload="preload"
        :style="videoStyle"
        crossorigin="anonymous"
        playsinline="true"
        controlList="nofullscreen nodownload noplaybackrate noremoteplayback"
        disablepictureinpicture
        @abort="emit"
        @canplay="emit"
        @canplaythrough="emit"
        @durationchange="emit"
        @emptied="emit"
        @encrypted="emit"
        @ended="emit"
        @error="emit"
        @loadeddata="emit"
        @loadedmetadata="emit"
        @loadstart="emit"
        @pause="emit"
        @play="emit"
        @playing="emit"
        @progress="emit"
        @ratechange="emit"
        @seeked="emit"
        @seeking="emit"
        @stalled="emit"
        @suspend="emit"
        @timeupdate="emit"
        @volumechange="emit"
        @waiting="emit"
  )
    track(v-if="subtitles" kind="subtitles" :src="subtitles" srclang="en" default)
</template>

<style lang="stylus">
.t-video
  width: 100%
  height: 100%
  position: relative

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  > video
    position: relative
    width: 100%
    height: 100%
    // Hide fullscreen button
    // Works on webkit only. Firefox doesn't support this yet, With or Without -moz- prefix.
    // What's more, Firefox doesn't supprt controlList either (NOTE @ Jun, 2023)
    // > https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/controlsList
    &::-webkit-media-controls-fullscreen-button
      display: none !important

  // VIDEO FILL MODE
  //
  // NOTE @ May 2019
  // There is a short hand CSS property `object-fit: cover`
  // However, it's not fully supported in Windows Edge
  // > https://caniuse.com/#feat=object-fit
  // So we still need to do it with Javascript
  &.mode-fill
    > video
      min-width: 100%
      min-height: 100%
      width: auto
      height: auto
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
</style>
