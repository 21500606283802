<script>
import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import PreviewNotAvailable from 'components/common/PreviewNotAvailable.vue'
import ImageItem from 'components/items/ImageItem.vue'
import VideoItem from 'components/items/VideoItem.vue'

import PlaceExchange from 'services/place-exchange'
import Env from 'services/environment'
import Log from 'services/log'
import { EventBus } from 'services/eventbus'

const BASE_CDN_PROXY_URL = `${Env.cdnURL()}/proxy`

// In ms. Fallback for Editor/Previews
const GET_TIMEOUT = 5000

export default {
  name: 'PlaceExchangeAd',
  components: { PreviewNotAvailable, ImageItem, VideoItem },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      orgId: '',
      token: '',
      adunitName: '',
      dealNames: '',

      mute: false,
      forceAdvance: false,

      adItem: {},
      rawURL: '',
      // Required by record play
      contextId: '',
      impX: -1,

      itemHasLoaded: false,
      itemStartTime: -1,
      errorMessage: '',

      // prevent sending multiple times
      playbackSent: false,

      imageTimer: null,
      getAdTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'device',
      'activeToken',
      'adDataByURL',
      'apiIsOffline'
    ]),

    requestAPI () {
      if (!this.orgId || !this.adunitName) { return '' }
      // Note: Add "deviceName" to trigger URL update for `adunitName: "name"` when device name changed
      return PlaceExchange.adRequestURL(this.orgId, this.adunitName, this.dealNames, this.deviceName)
    },

    deviceAdunitType () {
      return PlaceExchange.getAdunitType(this.adunitName)
    },

    deviceAdunitName () {
      // Note: Add "deviceName" to trigger value update for `adunitName: "name"` when device name changed
      return PlaceExchange.getDeviceAdunitName(this.adunitName, this.deviceName)
    },

    deviceName () {
      const device = this.device || {}
      return device.name || ''
    },

    itemType () {
      return (this.adItem && this.adItem.type) || ''
    },

    itemDuration () {
      return (this.adItem && this.adItem.duration) || -1
    },

    isPlayingContent () {
      return Boolean(this.contextId.length, this.itemHasLoaded)
    },

    // Require actual device info as adunitName
    previewNotAvailable () {
      return Env.previewMode || Env.desktopMode || Env.pageEditMode || Env.iframeEmbedMode
    },

    nonPlaybackMode () {
      // In Playlist Editor or App Preview mode:
      // - Lookahead will not work (only works on full Playlist playback)
      // - Skip sending playback record to Place Exchange (not an actual viewer)
      return Env.previewMode || Env.desktopMode || Env.pageEditMode || Env.iframeEmbedMode
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    requestAPI (newVal, oldVal) {
      if (oldVal && oldVal.length && newVal !== oldVal) {
        this.resetItem()
      }
    },

    deviceName (newVal) {
      if (this.deviceAdunitType === 'name') {
        this.requestData()
      }
    }
  },

  mounted () {
    clearTimeout(this.imageTimer)
    clearTimeout(this.getAdTimer)
    EventBus.$on('placeexchange-errored', this.errorHandler)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.imageTimer)
    clearTimeout(this.getAdTimer)
    EventBus.$off('placeexchange-errored', this.errorHandler)

    // Send out playback record on an early/manual page navigation
    if (!this.playbackSent) {
      this.sendPlaybackRecord()
    }
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.orgId = options.orgId || ''
      this.token = options.token || ''
      this.adunitName = options.adunitName || ''
      this.dealNames = options.dealNames || ''

      this.mute = (options.mute === true || options.mute === 'true')
      this.forceAdvance = (options.forceAdvance === true || options.forceAdvance === 'true')

      this.$nextTick(() => {
        this.requestData()
      })
    },

    clearData () {
      this.contextId = ''
      this.impX = -1
      this.adItem = {}
      this.rawURL = ''
    },

    // Fallback for the following cases:
    // - When visit from Playlist Editor, or App Preview
    // - Manually navigate to the page through the Interactive Menu, or via device debug mode
    requestData () {
      if (this.previewNotAvailable) { return }

      if (!this.requestAPI || !this.requestAPI.length) {
        this.errorMessage = 'Place Exchange required parameter is missing.'
        return
      }

      this.errorMessage = ''

      let data = this.adDataByURL(this.requestAPI)

      // Data already fetched in the lookahead process
      if (data) {
        this.parseData(data)
        return
      }

      if (!data) {
        this.timeoutChecking()

        if (this.apiIsOffline) {
          this.errorMessage = 'Unable to get Place Exchange data. The device is currently offline.'
        } else {
          this.errorMessage = ''
        }

        PlaceExchange.getAd(
          {
            token: this.token,
            orgId: this.orgId,
            adunitName: this.adunitName,
            dealNames: this.dealNames
          },
          // Catch the emit error in "errorHandler"
          true
        ).then(hasContent => {
          if (hasContent) {
            clearTimeout(this.timeoutTimer)
            this.errorMessage = ''
            data = this.adDataByURL(this.requestAPI)
            this.parseData(data)
          } else {
            this.finished()
          }
        }).catch(() => {
          this.finished()
        })
      }
    },

    proxyURL (url = '') {
      if (!url.length) { return }

      let encodedUrl
      if (!url.startsWith('https:') && !url.startsWith('http:')) {
        console.debug(`PE: URL already encoded "${url}"`)
        encodedUrl = url
      } else {
        encodedUrl = encodeURIComponent(url)
      }

      return `${BASE_CDN_PROXY_URL}?url=${encodedUrl}&token=${this.activeToken}&noserviceworker=true`
    },

    parseData (data = {}) {
      if (!data || !data.creative || !data.creative.type || !data.creative.snapshots || !data.creative.snapshots.length) {
        this.clearData()
        return
      }

      const snapshots = data.creative.snapshots || []
      const item = {}
      let snapshot

      // Type: "display" ==> image | "video" ==> video
      if (data.creative.type === 'video') {
        snapshot = snapshots[0]
        if (snapshot && snapshot.curl) {
          this.rawURL = snapshot.curl
          this.contextId = data.context
          this.impX = (data.bid && data.bid.imp_x) || -1

          item.url = this.proxyURL(snapshot.curl)
          item.rawURL = snapshot.curl
          item.cacheKey = snapshot.curl
          item.type = 'video'
          item.width = snapshot.warn
          item.height = snapshot.h
          item.duration = data.creative.duration
        }
      } else if (data.creative.type === 'display') {
        // Use the "scaling_factor: 1" by default
        snapshot = snapshots.find(ss => ss.scaling_factor === 1)
        // Fallback to the first snapshot
        if (!snapshot) {
          snapshot = snapshots[0]
        }
        if (snapshot && snapshot.iurl) {
          this.rawURL = snapshot.iurl
          this.contextId = data.context
          this.impX = (data.bid && data.bid.imp_x) || -1

          item.url = this.proxyURL(snapshot.iurl)
          item.cacheKey = snapshot.iurl
          item.type = 'image'
          item.width = snapshot.w
          item.height = snapshot.h
          item.duration = data.creative.duration
          item.image_fit = 'contain'
        }
      } else {
        this.clearData()
        return
      }

      this.adItem = item
    },

    sendPlaybackRecord () {
      if (this.nonPlaybackMode || this.previewNotAvailable) {
        // Do not set playback report in preview/edit mode
        return
      }
      if (this.playbackSent || !this.isPlayingContent || this.apiIsOffline) {
        // Do not send duplicate playback reports, or content is not loaded, or device is offline
        return
      }

      let duration
      if (this.itemStartTime > 0) {
        duration = Math.max(1, this.getNowInSec() - this.itemStartTime)
      } else {
        duration = this.itemDuration
      }

      this.playbackSent = true

      PlaceExchange.recordPlay(this.token, this.orgId, this.adunitName, {
        imp_x: this.impX,
        adunit: this.adunitName,
        context: this.contextId,
        duration: duration,
        start: this.itemStartTime
      })
    },

    resetItem () {
      this.itemStartTime = -1
      this.playbackSent = false
      this.itemHasLoaded = false
    },

    getNowInSec () {
      return ~~(Date.now() / 1000)
    },

    itemLoaded () {
      this.itemHasLoaded = true
      this.$emit('loaded')

      if (this.itemStartTime <= 0) {
        this.itemStartTime = this.getNowInSec()
      }

      clearTimeout(this.imageTimer)
      if (this.itemType === 'image' && this.itemDuration > 0) {
        this.imageTimer = setTimeout(() => {
          clearTimeout(this.imageTimer)
          this.itemFinished()
        }, this.itemDuration * 1000)
      }
    },

    sendDuration (value) {
      if (isFinite(value) && !isNaN(value) && value > 0) {
        this.$emit('duration', value)
      }
    },

    finished () {
      if (this.forceAdvance) {
        this.$emit('finished')
      }
    },

    itemFinished () {
      this.sendPlaybackRecord()
      this.finished()
    },

    itemErrored (err) {
      Log.warn('ad', 'Place Exchange: Ad playback errored', 'WAR_ADPLAYBACKERR', { url: this.rawURL, error: err })
      this.finished()
    },

    timeoutChecking () {
      clearTimeout(this.getAdTimer)
      this.getAdTimer = setTimeout(() => {
        clearTimeout(this.getAdTimer)
        if (!this.errorMessage.length) {
          this.errorMessage = 'No Content'
        }
        this.finished()
      }, +GET_TIMEOUT)
    },

    errorHandler (payload = {}) {
      if (!payload.error) { return }
      if (payload.orgId === this.orgId && payload.adunitType === this.deviceAdunitType && (payload.token === this.token || (!payload.token && !this.token))) {
        const errMsg = PlaceExchange.parseErrorMessage(payload.error)
        Log.warn('ad', `Place Exchange: Ad request errored - "${errMsg}"`, 'WAR_ADREQUESTERROR')
        this.errorMessage = errMsg
        this.clearData()
      }
    }
  }
}
</script>

<template lang="pug">
section.place-exchange
  preview-not-available(v-if="previewNotAvailable")

  template(v-if="!previewNotAvailable")
    image-item.placeexchange-item(v-if="itemType === 'image'"
               :item="adItem"
               :active="active"
               stack="ads"
               @loaded="itemLoaded"
               @error="itemErrored")

    video-item.placeexchange-item(v-else-if="itemType === 'video'"
               :item="adItem"
               :active="active"
               stack="ads"
               @loaded="itemLoaded"
               @finished="itemFinished"
               @duration="sendDuration"
               @error="itemErrored")

    .messages-wrapper(v-else-if="errorMessage")
      p {{ errorMessage }}
      p.more-info {{ deviceAdunitType }}: "{{ deviceAdunitName }}"
      p.more-info organization: "{{ orgId }}"
</template>

<style lang="stylus">
section.place-exchange
  position: absolute
  width: 100%
  height: 100%

  .placeexchange-item
    z-index: 5

  > .messages-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    padding: 1em

    p
      font-size: 1.3em
      text-align: center
      // Support showing `\n`
      white-space: pre-line
      &.more-info
        margin-top: 0
        line-height: 130%
        opacity: 0.6
        font-size: 0.8em
</style>
