<script>
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'
import FitText from 'components/widgets/FitText.vue'

const config = {
  // The width/height ratio for profile picture area
  PICTURE_WHRATIO: 0.7,

  // Use 'wide' layout when width/height ratio > this value
  IS_WIDE: 2.75
}

export default {
  name: 'ProfileItem',
  components: {
    ResponsiveMarkupText,
    FitText
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    profileMedia: {
      type: String,
      default: ''
    },

    isPortrait: {
      type: Boolean,
      default: false
    },

    baseFontSize: {
      type: [Number, Object],
      default: undefined
    },

    horzClass: {
      type: String,
      default: ''
    },

    vertClass: {
      type: String,
      default: ''
    },

    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // Size for profile picture
      picSize: {},

      // Item box size
      boxSize: {},

      resizing: true,

      debounceTimer: undefined
    }
  },

  computed: {
    picture () {
      if (!this.hasValue(this.profileMedia)) { return }
      return {
        backgroundImage: `url(${this.profileMedia})`
      }
    },

    usePortraitAltLayout () {
      return Boolean(this.isPortrait && this.hasValue(this.description) && this.hasValue(this.profileMedia))
    },

    isWide () {
      if (!this.boxSize || !this.boxSize.w || !this.boxSize.h) {
        return false
      }
      return this.boxSize.w / this.boxSize.h >= config.IS_WIDE
    },

    fixDisplayNameHeight () {
      return !this.isWide && (this.hasValue(this.description) || (!this.hasValue(this.description) && this.isPortrait))
    }
  },

  watch: {
    usePortraitAltLayout () {
      this.debounceCheckSize()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    this.$nextTick(() => {
      this.debounceCheckSize()
    })
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
  },

  methods: {
    hasValue (input) {
      return Boolean(input && (input || '').trim().length)
    },

    debounceCheckSize (timeout) {
      this.resizing = true
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.updatePicSize()
      }, timeout || 300)
    },

    updatePicSize () {
      if (!this.$refs || !this.$refs.sensor) {
        this.picSize = {}
        this.boxSize = {}
        this.resizing = false
        return
      }

      const metrics = this.$refs.sensor.getBoundingClientRect()

      if (!metrics || !metrics.width || !metrics.height) {
        this.picSize = {}
        this.boxSize = {}
        this.resizing = false
        return
      }

      this.boxSize = {
        w: metrics.width,
        h: metrics.height
      }

      if (!this.hasValue(this.profileMedia)) {
        this.picSize = {}
        this.resizing = false
        return
      }

      let picWidth
      let picHeight
      if (!this.isPortrait) {
        picHeight = metrics.height
        picWidth = picHeight * config.PICTURE_WHRATIO
      } else if (this.hasValue(this.description)) {
        picWidth = metrics.width * 0.3
        picHeight = picWidth / config.PICTURE_WHRATIO
      }

      if (picWidth && picHeight) {
        this.picSize = {
          width: `${picWidth}px`,
          height: `${picHeight}px`
        }
      } else {
        this.picSize = {}
      }
      this.resizing = false
    }
  }
}
</script>

<template lang="pug">
section.profile-item-block(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait, 'portrait-alt': usePortraitAltLayout}")
  .resize-sensor(ref="sensor")

  //- Normal Layout
  template(v-if="!usePortraitAltLayout")
    .pic-container.app-context-section.primary(v-if="hasValue(profileMedia)")
      .picture(:style="picture", :class="{'visible': !resizing}")
      .picture-placeholder(:style="picSize")
    .texts-container(:class="{'no-description': !hasValue(description), 'is-wide': isWide}")
      .profile-description.app-context-section.primary(v-if="hasValue(description)")
        .description-container
          responsive-markup-text(:class="[horzClass, vertClass]"
            :text="description"
            :base-font-size="baseFontSize"
            :line-height="1.2"
            :font-class="fontClass")
      .display-name.app-context-section.secondary(:class="{'border-left': !hasValue(description) && !isPortrait, 'border-top': hasValue(description) || isPortrait, 'visible': !resizing, 'fixed-height': fixDisplayNameHeight}")
        .fit-text-wrapper(:class="[horzClass, vertClass]")
          fit-text(:text="name"
                   :base-font-size="1.8"
                   :font-class="fontClass")

  //- Alternative Portrait Layout
  template(v-else)
    .top-area
      .pic-container.app-context-section.secondary.border-bottom
        .picture(:style="picture", :class="{'visible': !resizing}")
        .picture-placeholder(:style="picSize")
      .display-name.app-context-section.secondary.border-bottom(:class="{'visible': !resizing}")
        .fit-text-wrapper(:class="[horzClass, vertClass]")
          fit-text(:text="name"
                   :base-font-size="1.8"
                   :line-height="1.2"
                   :font-class="fontClass")
    .bottom-area.profile-description.app-context-section.primary
      .description-container
        responsive-markup-text(:class="[horzClass, vertClass]"
          :text="description"
          :base-font-size="baseFontSize"
          :font-class="fontClass")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

section.profile-item-block
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1

  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .pic-container
    overflow: hidden
    position: relative
    flex: none
    max-height: 100%

    .picture-placeholder
      position: relative

    .picture
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0

      background-repeat: no-repeat
      background-position: 50% 50%
      background-size: cover

      opacity: 0
      transition: opacity 0.2s

      &.visible
        opacity: 1

  .texts-container
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    &.is-wide
      flex-flow: row nowrap
      .display-name
        order: 0
        max-width: 33%
      .profile-description
        order: 1
        flex: 1 1 0.00001px

    &.no-description
      .display-name
        flex: 1 1 0.00001px

  .display-name
    font-weight: bold
    padding: 1em
    opacity: 0
    transition: opacity 0.2s

    flex: none
    display: flex
    flex-flow: row wrap
    overflow: hidden

    &.visible
      opacity: 1

    &.fixed-height
      height: 4em

    // Add extra layout to resolving padding calculation
    .fit-text-wrapper
      flex: 1 1 0.00001px
      align-self: stretch
      position: relative
      overflow: hidden
      contentBoxAlignments()

  .profile-description
    position: relative
    flex: 1 1 0.00001px
    padding: 1em
    display: flex
    flex-flow: column nowrap
    align-items: stretch

    .description-container
      flex: 1 1 0.00001px
      position: relative

  //
  // Portrait Layout
  //
  &.is-portrait
    flex-flow: column nowrap

    &:not(.portrait-alt)
      .pic-container
        flex: 1 1 0.00001px
      .texts-container
        flex: 1 0 0.00001px // [DEV-1728] fix text description shrink to small in narrow zone
        .display-name
          flex: none

  //
  // Alternative Portrait Layout
  //
  &.portrait-alt
    .top-area
      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: stretch
      position: relative
      overflow: hidden
      .pic-container
        display: block
        flex: none
      .display-name
        flex: 1 1 0.00001px

    .bottom-area
      flex: 1 1 0.00001px
      overflow: hidden
      position: relative
</style>
