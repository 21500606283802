<!-- Simple Footer Clock -->
<script>
import Moment from 'moment-timezone'

export default {
  name: 'SimpleClock',

  props: {
    // ['12H', '24H']
    hours: { type: String, default: '12H' },

    // ['hide', 'show']
    seconds: { type: String, default: 'hide' },

    showDate: { type: Boolean, default: false },
    timezone: { type: String, default: '' },
    darkMode: { type: Boolean, default: false }
  },

  data () {
    return {
      tickInterval: undefined,
      apm: undefined,
      hhmm: '',
      ss: undefined,
      date: undefined
    }
  },

  mounted () {
    this.tick()
    clearInterval(this.tickInterval)
    this.tickInterval = setInterval(() => {
      this.tick()
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.tickInterval)
  },

  methods: {
    tick () {
      const timeNow = this.timezone ? Moment().tz(this.timezone) : Moment()

      if (this.hours === '12H') {
        this.apm = timeNow.format('a')
        this.hhmm = timeNow.format('h:mm')
      } else {
        this.apm = undefined
        this.hhmm = timeNow.format('HH:mm')
      }

      if (this.seconds === 'show') {
        this.ss = timeNow.format('ss')
      } else {
        this.ss = undefined
      }

      if (this.showDate) {
        this.date = timeNow.format('ddd, MMM D')
      } else {
        this.date = undefined
      }
    }
  }
}
</script>

<template lang="pug">
.simple-clock(:class="{'dark-mode': darkMode}")
  .date-wrapper(v-if="showDate") {{ date }}

  .time-wrapper
    span.hhmm(v-text="hhmm")
    span.ss(v-if="ss && ss.length" v-text="':'+ ss")
    span.apm(v-if="apm && apm.length" v-text="apm")
</template>

<style lang="stylus">
.simple-clock
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  line-height: 100%
  color: inherit

  &.dark-mode
    color: #000000

  .date-wrapper
    font-size: 0.9em
    opacity: 0.6
    padding-bottom: 0.2em
    line-height: 130%

  .time-wrapper
    font-size: 1.2em
    font-weight: 600
    line-height: 130%
    .apm
      padding-left: 0.3em
      font-weight: 400
</style>
