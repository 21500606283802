* {
  box-sizing: border-box
}

html {
  background-color: #000;
  color: var(--gray-lightest);
  background-clip: border-box;
  user-select: none;
  cursor: default;
  text-shadow: rgba(255, 255, 255, 0.01) 0 0 1px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 10px;
  -webkit-text-size-adjust: none;
  text-rendering: optimizeLegibility;
}

body {
  font-family: var(--font-main);
  font-weight: 400;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-size: 1.6rem;
  -webkit-text-size-adjust: none;
}

h1 {
  font-family: var(--font-secondary);
  font-weight: normal;
  font-size: 2em;
  font-weight: 600;
  padding: 0;
  margin: 0 0 0.5em 0;
}

h2 {
  font-family: var(--font-secondary);
  font-weight: 600;
  padding: 0;
  margin: 0 0 0.5em 0;
}

h3 {
  font-family: var(--font-secondary);
  font-weight: 600;
  padding: 0;
  margin: 0 0 0.5em 0;
}

h4, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  padding: 0;
  margin: 0 0 0.5em 0;
}

input,
select,
button {
  font-family: var(--font-main);
}
