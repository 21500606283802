<script>
// MIXIN
import WeatherMixins from './WeatherMixins.vue'

import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'
import Weather from 'services/weather/index.js'
import Env from 'services/environment'

import WeatherIcon from './WeatherIcon.vue'
import Clock from 'components/widgets/Clock.vue'

const RESOURCES_BASE = Env.resourcesURL()

export default {
  name: 'WeatherPhoto',

  components: { Clock, WeatherIcon },

  // MIXIN
  // All common functions were moved there
  mixins: [WeatherMixins],

  // Inject config from parent component `WeatherItem.vue`
  inject: ['config'],

  props: {
    // Trigger delayed transitions
    show: { type: Boolean, default: false },

    mdyFormat: { type: Boolean, default: false },
    location: { type: String, required: true },
    locationDisplayName: { type: String, default: '' },
    show24hr: { type: Boolean, default: false },

    isPortrait: { type: Boolean, default: false },
    isWide: { type: Boolean, default: false },

    temperatureUnit: { type: String, default: '' },
    fontClass: { type: String, default: '' },
    fontScale: { type: Number, default: 1.0 },
    titleFontScale: { type: Number, default: 1.0 }
  },

  data () {
    return {
      bgs: Weather.backgrounds(),
      bgsInWhiteTheme: Weather.bgsInLightTheme(),

      refreshInterval: undefined
    }
  },

  computed: {
    scaledFontSizeStyle () {
      return {
        fontSize: `${this.fontScale || 1.0}em`
      }
    },

    scaledTitleFontSizeStyle () {
      return {
        fontSize: `${this.titleFontScale || 1.0}em`
      }
    },

    theme () {
      if (this.image) {
        if (this.bgsInWhiteTheme.indexOf(this.image) !== -1) {
          return 'light'
        }
      }
      return 'dark'
    },

    image () {
      if (this.hasError && !this.loading) {
        // Placeholder image
        return 'sunny3'
      }

      if (!this.weatherCode || !this.bgs[this.weatherCode]) { return '' }

      let code = this.weatherCode
      if (code === '800') {
        if (this.daynight === 'n') {
          code = '800-night'
        } else if (this.daynight === 'd') {
          code = '800-day'
        }
      } else if (code === '801' || code === '802' || code === '803') {
        if (this.daynight === 'n') {
          code = `${code}-night`
        }
      }

      return this.bgs[code].image || ''
    },

    weatherImage () {
      if (!this.image || !this.image.length) { return '' }
      return {
        backgroundImage: `url(${RESOURCES_BASE}/images/weather/photo-bg/${this.image}.jpg)`
      }
    },

    adjustLeft () {
      return (this.weatherTemp >= 10 && this.weatherTemp < 20) || (this.weatherTemp >= 100 && this.weatherTemp < 200)
    }
  },

  watch: {
    location (val) {
      if (!val) { return }
      this.updateWeather()
    },

    // [DEV-1743] add display unit options
    temperatureUnit () {
      this.updateWeather()
    },

    // [DEV-1869] Weather Localization
    localeLangKey () {
      this.updateWeather()
    }
  },

  mounted () {
    this.loading = true
    this.updateWeather()

    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.hasError = false
      this.loading = true
      this.updateWeather()
    }, this.config.REFRESH_INTERVAL * 1000)
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },

  methods: {

    updateWeather (useFahrenheit) {
      this.unit = useFahrenheit ? 'f' : 'c'

      const location = this.location
      if (!location) {
        if (this.show) {
          Log.warn('app', 'Weather app: Unable to determine location', 'WAR_PHOTONOLOC')
        }
        this.loaded()
        return
      }

      const locationStr = location.replace(/(\s|,|')+/g, '-').toLowerCase()
      this.locationKey = `weather_page_${locationStr}+daily`

      this.timeoutChecking()

      Promise.all([
        Weather.current(location, useFahrenheit, this.localeLangKey),
        Weather.dailyForecast(location, useFahrenheit, this.localeLangKey)
      ])
        .then(results => {
          clearTimeout(this.timeoutTimer)
          const [currentWeather, forecastWeather] = results

          if (currentWeather.statusCode && currentWeather.statusCode !== 200) {
            this.handleError(currentWeather)
            this.hasError = true
            return
          } else if (forecastWeather.statusCode && forecastWeather.statusCode !== 200) {
            this.handleError(forecastWeather)
            this.hasError = true
            return
          }

          // [DEV-1743] add display unit options
          const needsFahrenheit = this.needsFahrenheit(currentWeather)
          const isUseFahrenheit =
          (needsFahrenheit && this.unit !== 'f' && this.temperatureUnit !== 'c') ||
          (!needsFahrenheit && this.unit !== 'f' && this.temperatureUnit === 'f')
          if (isUseFahrenheit) {
            this.updateWeather(true)
            return
          }

          this.hasError = false
          this.skipReload = true
          this.errorMessage = ''
          const weather = currentWeather

          const units = {
            unit: this.unit
          }
          // AccuWeather contains units in the result
          if (currentWeather.units) {
            units.speed = currentWeather.units.wind
            units.temperature = currentWeather.units.temp
            units.pressure = currentWeather.units.pressure
          } else {
            units.speed = this.unit === 'f' ? 'mi/h' : 'm/s'
            units.temperature = this.unit === 'f' ? 'F' : 'C'
            units.pressure = 'hPa'
          }
          this.units = units

          // AccuWeather will return timezone name in data
          if (currentWeather.timezone && currentWeather.timezone.Name) {
            this.updateDateTimeInfo(currentWeather, this.mdyFormat)
          }

          const forecastData = []

          // - Start forcasting from tommorrow (0 = today)
          for (let idx = 1; idx <= this.config.DAYS_TO_SHOW; idx++) {
            const day = forecastWeather[idx]
            if (day) {
              forecastData.push(day)
            }
          }
          weather.forecast = forecastData

          this.weather = weather
          this.loaded()

          // Store weather data
          const weatherData = JSON.parse(JSON.stringify(this.weather))
          weatherData.timestamp = new Date().getTime()
          weatherData.src = 'aw'
          OfflineCaches.set(this.locationKey, weatherData)
        }).catch(err => {
          clearTimeout(this.timeoutTimer)
          this.handleError(err)
        })
    }
  }
}
</script>

<template lang="pug">
.photo-weather-wrapper(:class="fontClass")
  .photo-weather-item(v-if="weather", :class="[theme]", :style="[weatherImage]")

    .top-zone-mask

    .top-zone(:class="{'is-wide': isWide}" :style="scaledTitleFontSizeStyle")
      .time-n-date(v-if="!isWide")
        .time(v-if="datetimeInfo")
          clock(:timezone="timezone", :hours="show24hr ? '24H' : '12H'", :dark-mode="theme === 'light'")
        .current-date(v-if="datetimeInfo") {{datetimeInfo}}
      .location(v-if="locationDisplayName")
        .city(v-text="locationDisplayName")
      .location(v-else)
        .city(v-text="weather.location")
        .country(v-text="weather.country")

    .current(:class="{'is-portrait': isPortrait, 'is-wide': isWide}", :style="scaledFontSizeStyle")
      //- weather-icon(:code="weather.code", :daynight="weather.daynight", :theme="theme" use-animated-sets)
      weather-icon(:code="weather.code", :daynight="weather.daynight", :theme="theme" use-png-sets)
      .meta
        transition(name="fadeRight" appear)
          .temp.delay-500(v-if="show && weather && weather.time", :class="{'adjust-left': adjustLeft, 'is-portrait': isPortrait}")
            | {{weatherTemp}}°{{units.temperature}}
        transition(name="fadeRight" appear)
          .current-condition.delay-700(v-if="show && weather && weather.time") {{weather.description}}
        .atmosphere(v-if="!isWide")
          .item.wind
            transition(name="slideRight" appear)
              .inner.delay-900(v-if="show && weather && weather.wind")
                .label-text {{ $t('pageItems.weather.wind') }}
                .content {{windDirection(weather)}} {{windSpeed(weather)}}{{units.speed}}
          .item.humidity
            transition(name="slideRight" appear)
              .inner.delay-1100(v-if="show && weather && weather.time")
                .label-text {{ $t('pageItems.weather.humidity') }}
                .content {{ weather.humidity }}%
          .item.visibility
            transition(name="slideRight" appear)
              .inner.delay-1300(v-if="show && weather && weather.pressure")
                .label-text {{ $t('pageItems.weather.pressure') }}
                .content {{ pressure(weather) }}{{units.pressure}}

    .forcast-bg-wrapper(v-if="!isWide", :class="{'is-portrait': isPortrait}")

    .forecast(v-if="!isWide", :class="{'is-portrait': isPortrait}")
      .blur-wrapper(:style="weatherImage")
      .adjust-mask
      .day(v-for="(day, index) in weather.forecast", :key="index", :style="scaledFontSizeStyle")
        transition(name="fadeUpBig" appear)
          .inner(v-if="show && weather && weather.forecast", :class="delayAnimationClass(1200, index, 200)")
            .dow {{ getWeekday(day.time) }}
            .weather
              //- weather-icon(:code="day.code", :theme="theme" use-animated-sets)
              weather-icon(:code="day.code", :theme="theme" use-png-sets)
              .text(:class="{'is-portrait': isPortrait}") {{day.label}}
            .temps
              .high
                .value {{ parseTemp(day.temp.max) }}°{{units.temperature}}
                .label-text {{ $t('pageItems.weather.temperature_high') }}
              .low
                .value {{ parseTemp(day.temp.min) }}°{{units.temperature}}
                .label-text {{ $t('pageItems.weather.temperature_low') }}

  .messages
    .loading(v-if="!weather && loading") {{ $t('pageItems.weather.hintLoadingForecast') }} {{locationText}}
    .error-message(v-if="hasError && !loading" v-text="errorMessage")
    .retry-in(v-if="showRetryMessage && retryInSeconds") {{ $t('pageItems.weather.hintRetryIn', {retryInSeconds}) }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.photo-weather-wrapper
  position: absolute
  z-index: 1
  left: 0
  right: 0
  bottom: 0
  top: 0

  .photo-weather-item
    position: absolute
    z-index: 2
    left: 0
    right: 0
    bottom: 0
    top: 0
    background-repeat: no-repeat
    background-position: bottom center
    background-size: cover
    text-shadow: 0.05em 0.05em 0.05em -black(.7)

  .wi
    // If animated SVG is availabe, hide the static icon
    &.animated
      &:before
        visibility: hidden
        opacity: 0

  .top-zone
    position: absolute
    z-index: 10
    top: 3em
    left: 0
    right: 5em

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: flex-end
    text-align: right

    .location
      border-top: 1px solid -white(.5)
      padding-top: 1.5em
      margin-top: 1.5em
      text-transform: uppercase

      *
        line-height: 100%

      .city
        font-size: 4em
        padding-bottom: 0.3em

      .country
        font-size: 3em
        opacity: 0.7

    .time-n-date
      width: 25em

      .time
        position: relative
        font-size: 5em
        height: 1.3em;
        .simple-clock
          font-size: inherit
          align-items: flex-end
          white-space: nowrap
          .time-wrapper
            font-weight: 300
            .apm
              font-weight: 300

      .current-date
        font-size: 2.5em
        opacity: 0.7
        padding: 0.5em 0 0 0
        font-weight: 300

    &.is-wide
      .location
        border-top: 0
        padding: 0
        margin: 0

  .current
    z-index: 10
    position: absolute
    bottom: 35%
    left: 10%
    right: 0
    display: flex
    flex-flow: row nowrap
    justify-content: flex-start
    align-items: center

    &.is-portrait
      bottom: 28%
      left: 6%

    &.is-wide
      bottom: 0
      left: 6%
      top: 0

    .wi
      display: inline-block
      font-size: 24em

    .meta
      margin-left: 4em

      *
        line-height: 100%

      .temp
        font-size: 10em

        &.is-portrait
          font-size: 8em

        // Adjust the visual bias for number `1` of font Nunito
        &.adjust-left
          margin-left: -0.05em

      .current-condition
        font-size: 5em
        padding-top: 0.2em
        text-transform: capitalize

      .atmosphere
        display: flex
        flex-flow: row wrap
        padding: 3em 0 0 .15em

        .item
          position: relative
          overflow: hidden
          margin-left: 5em

          &:first-of-type
            margin-left: 0

        .label-text
          text-transform: uppercase
          font-size: 1.5em
          opacity: 0.8
          padding-bottom: 0.5em

        .content
          font-size: 2em
          line-height: 130%

  .top-zone-mask
    background-image: linear-gradient(200deg, -black(.3) 5%, -black(.15) 25%, -black(0) 40%)
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 9
    pointer-events: none;

  .forcast-bg-wrapper
    background-image: linear-gradient(to top, -black(.7) 5%, -black(.35) 45%, -black(0) 90%)
    position: absolute
    top: 60%
    bottom: 0
    left: 0
    right: 0
    z-index: 9
    pointer-events: none;

    &.is-portrait
      top: 45%

  .forecast
    text-align: center
    padding: 2em 5em 2em 5em
    position: absolute
    bottom: 0
    left: 0
    right: 0
    z-index: 10
    display: flex
    align-content: space-between
    overflow: hidden

    -webkit-clip: rect(0, auto, auto, 0)
    clip: rect(0, auto, auto, 0)

    &.is-portrait
      padding: 4em .5em 4em .5em

    .blur-wrapper
      position: fixed
      z-index: 11
      top: -10px
      left: -10px
      right: -10px
      bottom: -10px
      -webkit-filter: blur(8px)
      filter: blur(8px)
      background-repeat: no-repeat
      background-position: bottom center
      background-size: cover

    .adjust-mask
      position: absolute
      bottom: 0
      left: 0
      right: 0
      top: 0
      z-index: 12
      background-color: -black(.4)

    .day
      position: relative
      z-index: 15
      flex: 1
      .dow
        font-size: 2em
        line-height: 1.1em
        text-transform: uppercase
        font-weight: 600
      .weather
        margin: 2em 0
        .wi
          font-size: 4em
          line-height: 2em
        .text
          font-size: 2em
          margin-top: 0.5em
          line-height: 1.2em
          text-transform: capitalize

          &.is-portrait
            font-size: 1.8em

      .temps
        display: flex
        flex-flow: row wrap
        justify-content: center
        align-items: center
        font-size: 2em
        .high, .low
          line-height: 1.2em
          padding: 0 0.4em

          .label-text
            opacity: 0.7
            font-size: 0.8em
            font-weight: 200

          .value
            font-weight: 300

        .high
          border-right: 1px solid -white(.2)

  .photo-weather-item.light
    color: -black(.8)
    text-shadow: none

    .top-zone-mask
      background-image: linear-gradient(200deg, -white(.3) 5%, -white(.15) 25%, -white(0) 40%)

    .forcast-bg-wrapper
      background-image: linear-gradient(to top, -white(.7) 5%, -white(.35) 45%, -white(0) 90%)

    .adjust-mask
      background-color: -white(.4)

    .top-zone
      .location
        border-top: 1px solid -black(.5)

    .temps
      .high
        border-right: 1px solid -black(.2)

  // MESSAGES

  .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 2
    pointer-events: none;

    .error-message,
    .loading
      background-color: transparent !important

    .loading
      opacity: 0.7
      color: var(--gray)
      text-align: right
      padding: 3% 3% 0 0

    .error-message
      text-align: center
      padding: 48% 1em 0 1em
      font-size: 2.5em

    .retry-in
      text-align: center
      padding-top: 2em
</style>
