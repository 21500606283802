<script>
export default {
  name: 'PreviewNotAvailable'
}
</script>

<template lang="pug">
section.item-preview-not-available
  h3.title {{ $t('pageItems.noPreview.title') }}
  p {{ $t('pageItems.noPreview.message') }}
</template>

<style lang="stylus">
section.item-preview-not-available
  position: absolute
  width: 100%
  height: 100%

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  text-align: center
  padding: 1em

  .title
    font-weigt: 600
    font-size: 1.3em
    line-height: 150%
    margin: 0
    padding: 0

  p
    text-align: center
    line-height: 180%
    // Support showing `\n`
    white-space: pre-line
</style>
