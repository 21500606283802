<script>
import Log from 'services/log.js'
export default {
  name: 'OverrideWebsite',

  props: {
    url: {
      type: String,
      default: ''
    }
  },

  computed: {
    iframeSrc () {
      if (this.url && (this.url.startsWith('http://') || this.url.startsWith('https://'))) {
        return this.url
      }
      Log.error('app', `Override Website: Invalid URL "${this.url}"`, 'ERR_OVERRIDEWEBSITEURL', { url: this.url })
      return ''
    }
  }
}
</script>

<template lang="pug">
.override-website
  iframe(v-if="iframeSrc && iframeSrc.length"
         :src="iframeSrc"
         frame-border="0"
         sandbox="allow-scripts allow-same-origin allow-presentation"
         allow="autoplay; fullscreen"
         allowfullscreen
         seamless)
</template>

<style lang="stylus">
.override-website
  z-index: 52
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  > iframe
    width: 100%
    height: 100%
    border: 0
</style>
