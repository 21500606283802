<script>
import ObjectShape from './ObjectShape'
import ObjectIcon from './ObjectIcon'
import ObjectText from './ObjectText'
import {
  positionDefault,
  shapesDefault,
  iconDefault,
  textDefault
} from 'services/free-form'
import { validateNumericInput } from 'utilities/validate-input.ts'

export default {
  name: 'ObjectItem',

  components: {
    ObjectShape,
    ObjectIcon,
    ObjectText
  },

  props: {
    type: {
      type: String,
      required: true
    },
    itemId: {
      type: String,
      required: true
    },
    properties: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },

    playlistThemeIsSet: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      positionDefault,
      shapesDefault,
      iconDefault,
      textDefault
    }
  },

  computed: {
    isShape () {
      return (this.type || '').startsWith('object-shape-')
    },

    useChildAutoHeight () {
      return this.type === 'object-text'
    }
  },

  methods: {
    validateNumericInput
  }
}
</script>

<template lang="pug">
.ttv-object-item(:class="{'auto-height': useChildAutoHeight}")
  object-shape(v-if="isShape"
               :type="type"
               :width="validateNumericInput(position.width, shapesDefault.width)"
               :height="validateNumericInput(position.height, shapesDefault.height)"
               :size="validateNumericInput(position.size, shapesDefault.size)"
               :x="validateNumericInput(position.x, positionDefault.x)"
               :y="validateNumericInput(position.y, positionDefault.y)"
               :rotate="validateNumericInput(position.rotate, positionDefault.rotate)"
               :rounded="validateNumericInput(properties.rounded, shapesDefault.rounded, 0)"
               :stroke-width="validateNumericInput(properties.strokeWidth, shapesDefault.strokeWidth)"
               :stroke-color="properties.strokeColor"
               :fill-color="properties.fillColor"
               :opacity="validateNumericInput(properties.opacity, shapesDefault.opacity)")
  object-icon(v-if="type === 'object-icon'"
              :x="validateNumericInput(position.x, positionDefault.x)"
              :y="validateNumericInput(position.y, positionDefault.y)"
              :size="validateNumericInput(position.size, iconDefault.size)"
              :rotate="validateNumericInput(position.rotate, positionDefault.rotate)"
              :icon="properties.icon"
              :fill-color="properties.fillColor"
              :opacity="validateNumericInput(properties.opacity, iconDefault.opacity)")
  object-text(v-if="type === 'object-text'"
              :x="validateNumericInput(position.x, positionDefault.x)"
              :y="validateNumericInput(position.y, positionDefault.y)"
              :width="validateNumericInput(position.width, textDefault.width)"
              :text="properties.text"
              :text-color="properties.textColor"
              :font-size="validateNumericInput(properties.fontSize, textDefault.fontSize)"
              :font="properties.font"
              :h-align="properties.hAlign"
              :opacity="validateNumericInput(properties.opacity, textDefault.opacity)"
              :line-height="validateNumericInput(properties.lineHeight, textDefault.lineHeight)"
              :playlist-theme-is-set="playlistThemeIsSet")
</template>

<style lang="stylus">
.ttv-object-item
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0

  &.auto-height
    position: relative
    height: auto
    bottom: auto
</style>
