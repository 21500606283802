<script>
import MetricsMixins from './MetricsMixins.vue'

import TelemetryMarkup from 'services/telemetry-markup.js'

export default {
  name: 'MetricsText',

  mixins: [MetricsMixins],

  props: {
    fontOutline: { type: Boolean, default: false },
    vAlign: { type: String },
    hAlign: { type: String }
  },

  computed: {
    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    }
  },

  methods: {
    itemText (item) {
      if (!item || !item.metric || !item.metric.ref) { return '' }
      const metric = this.subscribedValue(item.metric.ref)
      return metric ? metric.value : ''
    },

    compileTmu (text) {
      return TelemetryMarkup.compile(text).replace(/\n/g, '<br>')
    },

    textColor (color) {
      if (color && color.length) {
        return { color }
      }
    }
  }
}
</script>

<template lang="pug">
section.metrics-text
  transition(:name="transitionName", :duration="500" appear)
    .metrics-item-wrapper.metrics-text-item.prime(v-if="showPrime && primeItem", :class="{'outlined' : fontOutline}")
      .item-title.app-context-section.secondary(v-if="!removeTitle") {{ itemTitle(primeItem) }}
      .item-body.app-context-section.primary(
                :class="[horzClass, vertClass]"
                :style="textColor(primeItem.color)"
                v-html="compileTmu(itemText(primeItem))")

  transition(:name="transitionName", :duration="500" appear)
    .metrics-item-wrapper.metrics-text-item.base(v-if="!showPrime && baseItem", :class="{'outlined' : fontOutline}")
      .item-title.app-context-section.secondary(v-if="!removeTitle") {{ itemTitle(baseItem) }}
      .item-body.app-context-section.primary(
                :class="[horzClass, vertClass]"
                :style="textColor(baseItem.color)"
                v-html="compileTmu(itemText(baseItem))")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

section.metrics-text
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-text-item
    &.outlined
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000

    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s

    .item-body

      // Alignments
      contentBoxAlignments()
</style>
