/* ===== Food Icons Font ===== */
@font-face {
  font-family: "foodicons";
  src: url("https://resources.telemetrytv.com/fonts/food-icons.woff2") format('woff2');
}

@font-face {
  font-family: "foodicons-qa";
  src: url("https://qa-resources.telemetrytv.com/fonts/food-icons.woff2") format('woff2');
}
/* =========================== */

/*
  FlatIcon icon font: foodicon
  Creation date: 15/01/2019 11:25
*/

[class^="foodicon-"]:before, [class*=" foodicon-"]:before,
[class^="foodicon-"]:after, [class*=" foodicon-"]:after {
  font-family: "foodicons";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="foodicon-"].qa-src:before, [class*=" foodicon-"].qa-src:before,
[class^="foodicon-"].qa-src:after, [class*=" foodicon-"].qa-src:after {
  font-family: "foodicons-qa";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="foodicon-"] {
  display: inline-block;
}

.foodicon-fruit:before { content: "\f100"; }
.foodicon-meat:before { content: "\f101"; }
.foodicon-lobster:before { content: "\f102"; }
.foodicon-lemon:before { content: "\f103"; }
.foodicon-broccoli:before { content: "\f104"; }
.foodicon-nut:before { content: "\f105"; }
.foodicon-almond:before { content: "\f106"; }
.foodicon-soy-milk:before { content: "\f107"; }
.foodicon-peanut:before { content: "\f108"; }
.foodicon-branch-with-leaves:before { content: "\f109"; }
.foodicon-color-picker:before { content: "\f10a"; }
.foodicon-half-avocado:before { content: "\f10b"; }
.foodicon-coffee-beans:before { content: "\f10c"; }
.foodicon-thick-steak:before { content: "\f10d"; }
.foodicon-raspberry:before { content: "\f10e"; }
.foodicon-blueberries:before { content: "\f10f"; }
.foodicon-salad:before { content: "\f110"; }
.foodicon-salad-1:before { content: "\f111"; }
.foodicon-coffee:before { content: "\f112"; }
.foodicon-cantaloupe:before { content: "\f113"; }
.foodicon-carrot:before { content: "\f114"; }
.foodicon-cheese-1:before { content: "\f115"; }
.foodicon-cheese-2:before { content: "\f116"; }
.foodicon-cherries:before { content: "\f117"; }
.foodicon-chocolate-bar:before { content: "\f118"; }
.foodicon-spice:before { content: "\f119"; }
.foodicon-vitamins:before { content: "\f11a"; }
.foodicon-lemon-1:before { content: "\f11b"; }
.foodicon-drink:before { content: "\f11c"; }
.foodicon-fruit-1:before { content: "\f11d"; }
.foodicon-coconut:before { content: "\f11e"; }
.foodicon-spinach:before { content: "\f11f"; }
.foodicon-corn:before { content: "\f120"; }
.foodicon-flavor:before { content: "\f121"; }
.foodicon-boiled-egg:before { content: "\f122"; }
.foodicon-egg:before { content: "\f123"; }
.foodicon-eggplant:before { content: "\f124"; }
.foodicon-fish:before { content: "\f125"; }
.foodicon-orange-juice:before { content: "\f126"; }
.foodicon-pudding:before { content: "\f127"; }
.foodicon-gluten:before { content: "\f128"; }
.foodicon-grapes:before { content: "\f129"; }
.foodicon-nuts:before { content: "\f12a"; }
.foodicon-melon:before { content: "\f12b"; }
.foodicon-hot-dog:before { content: "\f12c"; }
.foodicon-hummus:before { content: "\f12d"; }
.foodicon-jelly:before { content: "\f12e"; }
.foodicon-kiwi:before { content: "\f12f"; }
.foodicon-chop:before { content: "\f130"; }
.foodicon-pea:before { content: "\f131"; }
.foodicon-lemon-2:before { content: "\f132"; }
.foodicon-preserves:before { content: "\f133"; }
.foodicon-mango:before { content: "\f134"; }
.foodicon-mayonnaise:before { content: "\f135"; }
.foodicon-melon-1:before { content: "\f136"; }
.foodicon-mussel:before { content: "\f137"; }
.foodicon-ketchup:before { content: "\f138"; }
.foodicon-pack-of-oats:before { content: "\f139"; }
.foodicon-peanut-1:before { content: "\f13a"; }
.foodicon-peas:before { content: "\f13b"; }
.foodicon-pistachio:before { content: "\f13c"; }
.foodicon-pomegranate:before { content: "\f13d"; }
.foodicon-pork:before { content: "\f13e"; }
.foodicon-honey-jar:before { content: "\f13f"; }
.foodicon-rice:before { content: "\f140"; }
.foodicon-sesame:before { content: "\f141"; }
.foodicon-shrimps:before { content: "\f142"; }
.foodicon-shrimp:before { content: "\f143"; }
.foodicon-toffee:before { content: "\f144"; }
.foodicon-sweet-potato:before { content: "\f145"; }
.foodicon-vanilla:before { content: "\f146"; }
.foodicon-vinegar-jar:before { content: "\f147"; }
.foodicon-hair-dye:before { content: "\f148"; }
.foodicon-gloves:before { content: "\f149"; }
.foodicon-pecan:before { content: "\f14a"; }
.foodicon-sunflower:before { content: "\f14b"; }
.foodicon-seed:before { content: "\f14c"; }
.foodicon-banana:before { content: "\f14d"; }
.foodicon-cheese:before { content: "\f14e"; }
.foodicon-mushroom:before { content: "\f14f"; }
.foodicon-milk:before { content: "\f150"; }
.foodicon-pineapple:before { content: "\f151"; }
.foodicon-olive:before { content: "\f152"; }
.foodicon-potatoes:before { content: "\f153"; }
.foodicon-walnut:before { content: "\f154"; }
.foodicon-watermelon:before { content: "\f155"; }
.foodicon-whey:before { content: "\f156"; }
.foodicon-yogurt:before { content: "\f157"; }
.foodicon-pine-cone:before { content: "\f158"; }
.foodicon-pine-cone-1:before { content: "\f159"; }
.foodicon-soybeans-in-pod:before { content: "\f15a"; }
.foodicon-two-eggs:before { content: "\f15b"; }
.foodicon-apple-black-silhouette-with-a-leaf:before { content: "\f15c"; }
.foodicon-juice:before { content: "\f15d"; }
.foodicon-strawberry:before { content: "\f15e"; }
.foodicon-peach:before { content: "\f15f"; }
.foodicon-tomato:before { content: "\f160"; }
.foodicon-fruit-2:before { content: "\f161"; }
.foodicon-jar:before { content: "\f162"; }