<script>
// Video component which uses Blob URL generated from arrayBuffer of cached response

import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import Log from 'services/log.js'
import Env from 'services/environment'
import Utils from 'services/utils.js'
import ContentCache from 'services/contentCache.js'
import { EventBus } from 'services/eventbus.js'

const RESPONSE_IS_NULL = 'The response is null'
const ERROR_READING = 'Error reading arryBuffer from response'

export default {
  name: 'BlobVideo',

  props: {
    href: {
      type: String,
      default: ''
    },
    muted: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    poster: {
      type: String,
      default: ''
    },
    preload: {
      type: String,
      default: ''
    },
    // Configure optional Cache stack
    // - "ads": Programmatic Ad (E.g., Place Exchanged)
    // - "playlistAd": Playlist Advertising Sources
    stack: {
      type: String,
      default: ''
    },
    cacheKey: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      blobUrl: '',

      // For `fill` mode
      videoSize: {},
      videoStyle: {},

      resizeTimer: null
    }
  },

  computed: {
    modeClass () {
      return `mode-${this.mode || 'fit'}`
    },

    // Force mute all videos in Safari [DEV-1047]
    isSafari () {
      return (Env.browserName || '').toLowerCase() === 'safari'
    },

    // The target URL of proxied url
    rawUrl () {
      if (Utils.isProxiedUrl(this.href)) {
        return Utils.getProxyTargetURL(this.href)
      }
      return this.href
    },

    srcCleanUrl () {
      // URL without any `?token` etc info
      return Utils.getUrlPath(this.rawUrl)
    },

    srcFileName () {
      return Utils.trimedFilePath(this.rawUrl)
    }
  },

  watch: {
    href (newValue) {
      if (newValue && newValue.length) {
        this.fetchVideo()
      } else {
        this.$refs.video.src = null
      }
    }
  },

  mounted () {
    clearTimeout(this.resizeTimer)
    if (this.$refs && this.$refs.sensor) {
      AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    this.fetchVideo()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    clearTimeout(this.resizeTimer)
    this.revokeBolbURL(this.blobUrl)

    if (this.$refs.video) {
      // Clear src to release memory
      this.$refs.video.src = ''
      // Force browser to release resources
      this.$refs.video?.load?.()
    }
  },

  methods: {
    async arrayBufferFromResponse (response) {
      if (!response) {
        return Promise.reject(new Error(RESPONSE_IS_NULL))
      }
      let arrayBuffer
      try {
        arrayBuffer = await response.arrayBuffer()
      } catch (err) {
        const errMsg = `${ERROR_READING}: ${err.message || err.toString()}`
        Log.debug('ad', errMsg, 'DBG_ARRBUFFERRESP', err)
        return Promise.reject(new Error(errMsg))
      }
      return Promise.resolve(arrayBuffer)
    },

    fetchVideo () {
      if (!this.href || !this.href.length) { return }

      let promise
      if (this.stack === 'playlistAd') {
        promise = ContentCache.cachedFetch(ContentCache.PLAYLIST_AD, this.href, true, this.cacheKey)
      } else if (this.stack === 'ads') {
        promise = ContentCache.cachedFetch(ContentCache.PRGM_AD, this.href, true, this.cacheKey)
      }
      if (!promise) { return }

      promise.then(async (response) => {
        return this.arrayBufferFromResponse(response).then(arrayBuffer => {
          const blob = new Blob([arrayBuffer])

          const blobUrl = URL.createObjectURL(blob)

          if (this.blobUrl && blobUrl !== this.blobUrl) {
            this.revokeBolbURL(this.blobUrl)
          }

          this.$refs.video.src = blobUrl

          // Store for GC in a latter phase
          this.blobUrl = blobUrl
        }).catch(error => {
          // Return + throw error to the upper level "catch" errorHandler
          throw error
        })
      }).catch(err => {
        const errMsg = err.message || err.toString() || ''
        if (err.name && (err.name.indexOf('NotInCache') >= 0 || err.name.indexOf('FetchFailed') >= 0)) {
          err = 'fetch failed'
          Log.warn('ad', `AD: Video fetch failed (${this.srcFileName})`, 'WAR_ADVIDEOFETCHFAILED', { url: this.srcCleanUrl, error: err })
        } else {
          Log.debug('ad', `AD: Video fetch errored "${errMsg}" (${this.srcFileName})`, 'DBG_ADVIDEOFETCHERR', { url: this.srcCleanUrl, error: err })
        }
        this.emitError(err)
      })
    },

    emitError (e) {
      this.$emit('error', e)
    },

    setVideoSize (e) {
      if (!e || !e.target || !e.target.clientWidth || !e.target.clientHeight) {
        this.videoSize = {}
        return
      }
      this.videoSize = {
        w: e.target.clientWidth,
        h: e.target.clientHeight
      }
      this.debounceCheckSize()
    },

    checkSize () {
      if (
        this.mode !== 'fill' ||
        !this.videoSize ||
        !this.videoSize.w ||
        !this.videoSize.h
      ) {
        this.videoStyle = null
        return
      }

      if (!this.$el) {
        this.videoStyle = null
        return
      }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          this.videoStyle = null
          FastDom.clear(measure)
          return
        }

        width = container.clientWidth || container.offsetWidth
        height = container.clientHeight || container.offsetHeight

        if (!width || !height) {
          this.videoStyle = null
          FastDom.clear(measure)
          return
        }

        const sizeRatio = Math.max(
          width / this.videoSize.w,
          height / this.videoSize.h
        )
        this.videoStyle = {
          width: `${sizeRatio * this.videoSize.w}px`,
          height: `${sizeRatio * this.videoSize.h}px`
        }

        FastDom.clear(measure)
      })
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        clearTimeout(this.resizeTimer)
        this.checkSize()
      }, timeout || 200)
    },

    revokeBolbURL (blobUrl = '') {
      if (!blobUrl.length) { return }
      // Revoke for better GC
      // Handle URL.revokeObjectURL() in the app.vue (root) with delay to prevent flickering
      EventBus.$emit('revoke-blob-url', blobUrl + '')
    },

    async checkBuffering () {
      const video = this.$refs.video
      if (!video || this.isPaused() || video.buffered.length === 0 || this.iter === 0) {
        return
      }
      const currentPlayPos = this.$refs.video.currentTime
      const currentDuration = video.buffered.end(0) - video.buffered.start(0)
      const timePerBuffer = currentDuration / this.iter
      // should fetch before one fragment
      if (!this.endOfStream && !this.fetching && currentPlayPos + timePerBuffer > currentDuration) {
        await this.fetchVideoFragment()
      }
    },

    emit (e) {
      if (e.type === 'loadedmetadata') {
        this.setVideoSize(e)
      }
      if (e.type === 'ended') {
        // Clear poster image on video ended
        if (this.$refs && this.$refs.video) {
          this.$refs.video.setAttribute('poster', '')
        }
      }
      this.$emit(e.type, e)
    },

    play () {
      return this.$refs.video.play()
    },

    isPaused () {
      return this.$refs.video.paused
    },

    pause () {
      return this.$refs.video.pause()
    },

    load () {
      return this.$refs.video.load()
    },

    duration () {
      return this.$refs.video.duration
    },

    currentTime () {
      return this.$refs.video.currentTime
    }
  }
}
</script>

<template lang="pug">
.b-video(:class="modeClass")
  .resize-sensor(ref="sensor")

  video(ref="video"
        :muted="isSafari || muted"
        :loop="loop"
        :autoplay="autoplay"
        :controls="controls"
        :poster="poster"
        :preload="preload"
        :style="videoStyle"
        playsinline="true"
        @abort="emit"
        @canplay="emit"
        @canplaythrough="emit"
        @durationchange="emit"
        @emptied="emit"
        @encrypted="emit"
        @ended="emit"
        @error="emit"
        @loadeddata="emit"
        @loadedmetadata="emit"
        @loadstart="emit"
        @pause="emit"
        @play="emit"
        @playing="emit"
        @progress="emit"
        @ratechange="emit"
        @seeked="emit"
        @seeking="emit"
        @stalled="emit"
        @suspend="emit"
        @timeupdate="emit"
        @volumechange="emit"
        @waiting="emit")
</template>

<style lang="stylus">
.b-video {
  width: 100%;
  height: 100%;
  position: relative;

  > .resize-sensor {
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  > video {
    position: relative;
    width: 100%;
    height: 100%;
  }

  // VIDEO FILL MODE
  //
  // NOTE @ May 2019
  // There is a short hand CSS property `object-fit: cover`
  // However, it's not fully supported in Windows Edge
  // > https://caniuse.com/#feat=object-fit
  // So we still need to do it with Javascript
  &.mode-fill {
    > video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
