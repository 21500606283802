<script>
import {
  mapGetters,
  mapState
} from 'vuex'

import PreviewNotAvailable from 'components/common/PreviewNotAvailable.vue'
import ImageItem from 'components/items/ImageItem.vue'
import VideoItem from 'components/items/VideoItem.vue'
import SlidesItem from 'components/items/SlidesItem.vue'

import Ad from 'services/ad'
import Env from 'services/environment'
import Utils from 'services/utils'
import Pages from 'services/pages'
import Log from 'services/log'

const BASE_CDN_PROXY_URL = `${Env.cdnURL()}/proxy`

// In ms
const GET_TIMEOUT = 10000

// In seconds
const DEFAULT_AD_DURATION = 15

export default {
  name: 'AdvertisingItem',
  components: { PreviewNotAvailable, ImageItem, VideoItem, SlidesItem },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    // Indicates if the item is embedded in a page zone (E.g., Media Folder)
    embedding: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      adItem: {},
      adPageBak: {},
      playlistAdBak: {},

      itemHasLoaded: false,
      itemStartTime: -1,

      errorMessage: '',

      timeoutTimer: null,
      loadTimer: null,
      imageDisplayTimer: null,
      delayTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'activeToken',
      'apiIsOffline',
      'currentPage'
    ]),
    ...mapState({
      playlistAd: state => state.ad.playlistAd
    }),

    isDisabledMode () {
      return Env.previewMode || Env.desktopMode || Env.pageEditMode || Env.iframeEmbedMode
    }
  },

  watch: {
    // Indicates switching between adjacent Ad pages
    'item.id' (newVal) {
      if (newVal) {
        this.logCampaignView()
        this.requestData()
      }
    }
  },

  async mounted () {
    clearTimeout(this.loadTimer)
    clearTimeout(this.imageDisplayTimer)
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.delayTimer)

    this.$nextTick(() => {
      this.backupPageInfo()
      this.requestData()
    })
  },

  beforeDestroy () {
    clearTimeout(this.loadTimer)
    clearTimeout(this.imageDisplayTimer)
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.delayTimer)

    // Fallback for manual page navigation (not navigated by the "finished" event).
    if (this.itemStartTime > 0) {
      this.$store.commit('removePlaylistAd')
      this.logCampaignView()
    }
  },

  methods: {
    async requestData () {
      if (this.isDisabledMode) {
        if (this.embedding) {
          this.finished()
        } else {
          this.delayFinished()
        }
        return
      }

      this.errorMessage = ''

      if (this.playlistAd && this.playlistAd.url) {
        // The `playlistAd` is not the same as the `playlistAdBak`
        if (!this.playlistAdBak || !this.playlistAdBak.url || (this.playlistAdBak.url && this.playlistAdBak.url !== this.playlistAd.url)) {
          this.renderItem(this.playlistAd || {})
          Ad.increasePlaybackCount(this.playlistAd)
          return
        }
      }

      if (this.apiIsOffline) {
        this.errorMessage = 'Unable to get Ad Content when device is offline.'
        if (this.embedding) {
          this.finished()
        } else {
          this.delayFinished()
        }
        return
      }

      this.timeoutChecking()

      Ad.getAd().then(hasContent => {
        if (hasContent) {
          clearTimeout(this.timeoutTimer)
          this.errorMessage = ''
          this.$nextTick(() => {
            this.renderItem(this.playlistAd || {})
            Ad.increasePlaybackCount(this.playlistAd)
          })
        } else {
          this.finished()
        }
      }).catch(() => {
        // Error Log already thrown in the Ad service
        this.finished()
      })
    },

    proxyURL (url = '') {
      let encodedUrl
      if (!url.startsWith('https:') && !url.startsWith('http:')) {
        // URL already encoded
        encodedUrl = url
      } else {
        encodedUrl = encodeURIComponent(url)
      }
      return `${BASE_CDN_PROXY_URL}?url=${encodedUrl}&token=${this.activeToken}&noserviceworker=true`
    },

    renderItem (payload = {}) {
      const url = payload.url || ''
      if (!url || !url.length) {
        this.adItem = {}
        this.finished()
        return
      }

      this.playlistAdBak = JSON.parse(JSON.stringify(payload))

      const item = {}

      if (Utils.isVideoURL(url)) {
        item.type = 'video'
      } else if (Utils.isImageURL(url)) {
        item.type = 'image'
        item.duration = payload.duration || +DEFAULT_AD_DURATION
      } else {
        // Unknown type
        Log.info('ad', 'Playlist Ad: Unknown Ad content type', 'INF_PLADUNKNOWNTYPE', { url })
        this.adItem = {}
        this.finished()
        return
      }

      // TTV Media Content ("folder")
      if (Utils.isTTVUploads(url)) {
        item.origin = 'media'
        item.url = url + ''
        // Double check for slides
        if (item.type === 'image') {
          const urlParts = url.split(',')
          if (urlParts.length > 1) {
            item.type = 'slides'
          }
        }
      // VAST or TelemetryTV CTV
      } else {
        item.origin = 'ad'
        item.url = this.proxyURL(url)
        item.stack = 'playlistAd'
        item.cacheKey = url + ''
      }

      this.loadTimeChecking()

      this.adItem = item

      if (item.type === 'image') {
        this.imageDisplayTimemout(item.duration)
      }
    },

    backupPageInfo () {
      if (this.currentPage?.id) {
        // Backup the current page to prevent data changes during the page transition
        this.adPageBak = {
          id: this.currentPage.id,
          description: this.currentPage.description,
          playlist_id: this.currentPage.playlist_id
        }

        if (this.playlistAd && this.playlistAd.campaign_id) {
          this.adPageBak.campaign_id = this.playlistAd.campaign_id
        }
      }
    },

    itemLoaded () {
      clearTimeout(this.loadTimer)

      this.itemStartTime = Date.now() / 1000

      this.backupPageInfo()

      this.$emit('loaded')
    },

    finished () {
      if (this.playlistAd) {
        const lastPlayedAd = {}
        lastPlayedAd.campaign_id = this.playlistAd.campaign_id
        lastPlayedAd.ad_source_id = this.playlistAd.ad_source_id
        if (this.playlistAd.origin === 'folder') {
          lastPlayedAd.content_folder_id = this.playlistAd.content_folder_id
        }
        this.$store.commit('setLastPlayedAd', lastPlayedAd)
      }
      this.$store.commit('removePlaylistAd')
      this.$emit('finished', true)
    },

    itemFinished () {
      this.logAdPlayed()
      this.logCampaignView()
      this.finished()
    },

    itemErrored (err) {
      this.itemStartTime = -1
      const errMsg = err.message || err.toString() || ''
      Log.warn('ad', `Playlist Ad: content playback errored - "${errMsg}"`, 'WAR_PLADPLAYBACKERR', { url: this.adItem.cacheKey || this.adItem.url, error: err })
      this.finished()
    },

    logAdPlayed () {
      if (this.itemStartTime <= 0) { return }
      if (!this.playlistAd || !this.playlistAd.content_folder_id) { return }

      const endTime = Date.now() / 1000
      const startTime = +this.itemStartTime
      const ad = JSON.parse(JSON.stringify(this.playlistAd))

      Ad.logAdPlayed(ad, startTime, endTime)
    },

    logCampaignView () {
      if (this.itemStartTime <= 0) { return }
      if (!this.adPageBak || !this.adPageBak.id) { return }

      const endTime = Date.now() / 1000
      const startTime = +this.itemStartTime
      const page = JSON.parse(JSON.stringify(this.adPageBak))
      Pages.logCampaignView(page, startTime, endTime)

      // Reset value to prevent duplicate-logging
      this.itemStartTime = -1
    },

    timeoutChecking () {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        this.finished()
      }, +GET_TIMEOUT)
    },

    loadTimeChecking () {
      clearTimeout(this.loadTimer)
      this.loadTimer = setTimeout(() => {
        clearTimeout(this.loadTimer)
        this.finished()
      }, +DEFAULT_AD_DURATION * 1000)
    },

    imageDisplayTimemout (durationInSeconds = 0) {
      clearTimeout(this.imageDisplayTimer)
      this.imageDisplayTimer = setTimeout(() => {
        clearTimeout(this.imageDisplayTimer)
        this.itemFinished()
      }, (+durationInSeconds || +DEFAULT_AD_DURATION) * 1000)
    },

    delayFinished () {
      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.finished()
      }, 3000)
    }
  }
}
</script>

<template lang="pug">
section.ttvad-placement
  preview-not-available(v-if="isDisabledMode && !embedding")

  template(v-if="!isDisabledMode")
    image-item.playlist-ad-item(v-if="adItem.type === 'image'"
               :item="adItem"
               :stack="adItem.stack"
               active
               @loaded="itemLoaded"
               @error="itemErrored")
    video-item.playlist-ad-item(v-if="adItem.type === 'video'"
               :item="adItem"
               :stack="adItem.stack"
               active
               @loaded="itemLoaded"
               @finished="itemFinished"
               @error="itemErrored")
    slides-item.playlist-ad-item(v-if="adItem.type === 'slides'"
               :item="adItem"
               active
               @loaded="itemLoaded"
               @finished="itemFinished"
               @error="itemErrored")

    .messages-wrapper(v-if="!adItem.type && errorMessage.length")
      p {{ errorMessage }}
</template>

<style lang="stylus">
section.ttvad-placement
  position: absolute
  width: 100%
  height: 100%

  .playlist-ad-item
    z-index: 5

  > .messages-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    padding: 1em

    p
      font-size: 1.3em
      text-align: center
      // Support showing `\n`
      white-space: pre-line
</style>
