<script>
import qs from 'qs'
import Moment from 'moment-timezone'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  mapGetters
} from 'vuex'

import Log from 'services/log.js'
import Utils from 'services/utils.js'
import Telemetry from 'services/telemetry.js'
import OfflineCaches from 'services/offline-caches'
import Env from 'services/environment'
import { EventBus } from 'services/eventbus.js'

import Spinner from 'components/common/Spinner.vue'
import SlackAppItem from './slack/Item.vue'
import SlackDateMarker from './slack/DateMarker.vue'

const RESOURCES_BASE = Env.resourcesURL()

const config = {
  // in second. If WS is not responeding, show cached (if any) messages after this threshold
  FALLBACK_AFTER: 30,
  // in ms
  SCROLL_DELAY: 1000,
  // Limit items stored in localStorage
  STORE_LIMIT: 30
}

const defaultErrorMsg = 'Unable to connect to Slack. Please check your network, proxy or firewall settings.'

export default {
  name: 'SlackItem',

  components: {
    Spinner,
    SlackAppItem,
    SlackDateMarker
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      channelID: undefined,
      channelName: undefined,

      bakChannelID: undefined,

      items: [],
      validItems: [],
      itemsByDate: [],

      // Actual width/height
      itemSize: {},

      loading: true,
      isEmpty: true,
      hasError: false,
      reactedOnly: false,

      errorMessage: '',

      currentItem: undefined,
      primeItem: undefined,
      baseItem: undefined,
      showPrime: false,

      fallbackTimer: undefined,
      scrollTimer: undefined,
      debounceTimer: undefined,

      hasRendered: false
    }
  },

  computed: {
    ...mapGetters([
      'messagesByChannelID', 'apiStatus'
    ]),

    localKey () {
      if (this.channelID) {
        return `slack_${this.channelID}`
      }
    },

    baseFontSize () {
      const fontSize = Utils.baseFontSize(this.itemSize)
      if (fontSize) {
        return {
          fontSize: `${fontSize * 2}px`
        }
      }
    },

    historyMessages () {
      if (this.channelID && this.channelID.length) {
        return this.messagesByChannelID(this.channelID)
      }
      return []
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > 4
      }
      return false
    },

    brandingLogo () {
      return {
        background: `url(${RESOURCES_BASE}/images/slack/slack_icon.svg)`
      }
    }
  },

  watch: {
    historyMessages: {
      deep: true,
      handler (newValue) {
        if (newValue && newValue.length) {
          this.loaded()
          this.parseHistoryMsg(newValue)
        }
      }
    },
    apiStatus (status) {
      if (status === 'offline' && this.channelID) {
        this.$store.dispatch('unlistenSlack', this.channelID).catch(err => {
          Log.warn('ws', `Error unlisten to Slack channel "${this.channelID}" - ${err.message || err.toString()}`, 'WAR_UNLISTENSLACKCHAN', err)
        })
      }
    },
    reactedOnly () {
      this.parseHistoryMsg(this.historyMessages)
    },
    'item.url': {
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.fallbackTimer)
    clearTimeout(this.scrollTimer)
    clearTimeout(this.debounceTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    Telemetry.on('slack-updated', this.newMessageHandler)

    this.render()
    this.debounceCheckSize()

    this.fallbackTimer = setTimeout(() => {
      clearTimeout(this.fallbackTimer)
      if (!this.hasRendered) {
        this.showCachedData()
      }
    }, config.FALLBACK_AFTER * 1000)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    if (this.channelID) {
      this.$store.dispatch('unlistenSlack', this.channelID).catch(err => {
        Log.warn('ws', `Error unlisten to Slack channel "${this.channelID}" - ${err.message || err.toString()}`, 'WAR_UNLISTENSLACKCHAN2', err)
      })
    }
    Telemetry.removeListener('slack-updated', this.newMessageHandler)
    clearTimeout(this.fallbackTimer)
    clearTimeout(this.scrollTimer)
    clearTimeout(this.debounceTimer)
  },

  methods: {
    render () {
      if (!this.item.url && !this.item.location) { return }
      const params = qs.parse(decodeURIComponent(this.item.url || this.item.location))
      if (!params) { return }

      this.channelID = params.channel
      this.channelName = params.channel_name || ''

      const reactedOnly = params.reacted_only || false
      this.reactedOnly = (reactedOnly === true || reactedOnly === 'true')

      if (this.bakChannelID && this.channelID && this.bakChannelID !== this.channelID) {
        this.loading = true
        this.resetItems()
      }
      this.bakChannelID = params.channel

      if (!this.channelID) {
        this.hasError = true
        this.errorMessage = 'Please select a Slack channel first'
        this.hasRendered = true
        this.resetItems()
        this.loaded()
        return
      }

      this.$store.dispatch('listenSlack', this.channelID).then((result) => {
        if (result?.isEmpty) {
          this.isEmpty = true
          this.hasError = false
          this.errorMessage = ''
          this.hasRendered = true
          this.resetItems()
          this.loaded()
        }
      }).catch(err => {
        const errMsg = err.message || err.toString() || ''
        let message = ''
        let skipReadingCache = true
        if (errMsg.includes('429')) {
          message = `Slack API rate limit exceeded, please retry later - "${errMsg}"`
          Log.error('app', message, 'ERR_SLACKAPIRATELIMIT', err, true)
        } else if (errMsg.includes('token_revoked')) {
          message = `Slack token revoked, please reconnect the Slack app through the TelemetryTV Administration Interface and try again - "${errMsg}"`
          Log.error('app', message, 'ERR_SLACKAPITOKENREVOKED', err, true)
        } else if (errMsg.toLowerCase().includes('timeout')) {
          skipReadingCache = false
          message = defaultErrorMsg
          Log.error('app', message, 'ERR_LISTENSLACKTIMEOUT', err, true)
        // Report unknown errors to BugSnag
        } else {
          message = `Error listening to Slack channel "${this.channelID}" - "${errMsg}"`
          Log.error('app', message, 'ERR_LISTENSLACKCHAN', err)
        }
        this.errorMessage = message
        this.hasError = true
        this.hasRendered = skipReadingCache
        this.loaded()
      })
    },

    resetItems () {
      this.items = []
      this.validItems = []
      this.itemsByDate = []
    },

    parseHistoryMsg (msgItems) {
      this.hasRendered = true

      const items = JSON.parse(JSON.stringify(msgItems || []))
      items.sort((a, b) => {
        if (a.ts > b.ts) { return 1 }
        if (a.ts < b.ts) { return -1 }
        return 0
      })

      const list = []
      items.forEach(item => {
        const it = {
          event: JSON.parse(JSON.stringify(item)),
          event_time: parseInt(item.ts),
          event_id: item.ts + ''
        }
        if (item.subtype === 'thread_broadcast') {
          it.event.message = JSON.parse(JSON.stringify(item))
          it.event.ts = item.ts
          it.root = item.root
          delete it.event.subtype
        } else {
          if (item.attachments) {
            it.attachments = item.attachments
          }

          // NOTE @ OCT 2018
          // File Share main thread will NOT have 'file_share' in its subtype
          if (!item.subtype && item.files) {
            it.event.subtype = 'file_share'
          }
        }
        list.push(it)
      })

      this.items = []
      this.validItems = []

      if (list.length) {
        this.renderItems(list, true)
      }
    },

    newMessageHandler (m) {
      if (!m || !m.data) { return }
      const newData = JSON.parse(JSON.stringify(m.data))

      // For multiple slack channel listening verification
      if (this.channelID && newData.channel === this.channelID) {
        this.$store.dispatch('newSlackMessage', newData)
        this.renderItems([newData])
      }
    },

    renderItems (newItems, forceUpdate) {
      const list = []
      const validList = []
      const metaInfo = []
      let isValid

      newItems.forEach(newItem => {
        if (this.reactedOnly) {
          isValid = newItem.event.reaction || newItem.event.reactions
        } else {
          isValid = true
        }

        if (newItem.event?.thread_ts) {
          // Set reply counts to root item
          newItem.reply_count = newItem.event.reply_count || 0
        }

        const thisEventType = this.eventType(newItem)

        // Plain Text message
        if (thisEventType === 'message' || thisEventType === 'thread_broadcast') {
          if (thisEventType === 'thread_broadcast') {
            newItem.event.message = JSON.parse(JSON.stringify(newItem.event))
          }

          // Spotting live + hidden thread messages
          // > https://api.slack.com/messaging/retrieving#finding_threads
          // `!forceUpdate` means it's a live update (from `event_callback`)
          if (!forceUpdate && thisEventType === 'message' && newItem.event?.thread_ts && newItem.event?.thread_ts !== newItem.event?.ts) {
            newItem.hiddenThread = true
          }

          if (!newItem.reactions && newItem.event.reactions) {
            newItem.reactions = newItem.event.reactions
          }
          if (newItem.event?.edited) {
            newItem.isEdited = true
          }

          if (newItem.event?.pinned_info) {
            newItem.isPinned = true
          }

          // Slack changed the API response data, no more "is_thread_broadcast" property, all messages with `subtype: "thread_broadcast"` should be displayed
          if (newItem.event?.thread_ts) {
            // Update the replies count under thread's root item
            const rootItemIndex = this.items.findIndex(item => {
              return item.event.ts === newItem.event.thread_ts
            })
            if (rootItemIndex !== -1) {
              this.$set(this.items[rootItemIndex], 'reply_count', (this.items[rootItemIndex].reply_count || 0) + 1)
            }
          }

          list.push(newItem)
          if (isValid && !newItem.hiddenThread) {
            validList.push(newItem)
          }
        } else if ([
          'message_changed',
          'reaction_added',
          'message_deleted',
          'reaction_removed'
        ].includes(thisEventType)) {
          metaInfo.push(newItem)
        } else if ([
          'channel_join',
          'channel_leave',
          'channel_topic',
          'channel_purpose',
          'channel_name',
          'channel_archive',
          'channel_unarchive',
          'group_leave',
          'group_join',
          'group_topic',
          'group_purpose',
          'group_name',
          'group_archive',
          'group_unarchive',
          'pinned_item',
          'unpinned_item',
          'retention_threshold'
        ].includes(thisEventType)) {
          newItem.isSystemMessage = true
          list.push(newItem)
          if (isValid) {
            validList.push(newItem)
          }
        } else if (thisEventType === 'bot_message') {
          if (!newItem.attachments && newItem.event.attachments) {
            newItem.attachments = newItem.event.attachments
          }
          list.push(newItem)
          if (isValid) {
            validList.push(newItem)
          }
        // File Share
        // No more "file" property, use "files" instead
        // > https://api.slack.com/events/message/file_share
        } else if (thisEventType === 'file_share') {
          if (!newItem.files && newItem.event?.files) {
            newItem.files = newItem.event.files || []
          }
          if (!newItem.reactions && newItem.event?.reactions) {
            newItem.reactions = newItem.event.reactions
          }
          list.push(newItem)
          if (isValid) {
            validList.push(newItem)
          }
        } else if (thisEventType === 'file_comment') {
          if (newItem.event.comment && newItem.event.comment.comment) {
            newItem.event.text = newItem.event.comment.comment
            newItem.event.user = newItem.event.comment.user
            newItem.isComment = true
            list.push(newItem)
            if (isValid) {
              validList.push(newItem)
            }
          }
        } else {
          Log.debug('app', `Unknown Slack event type ${thisEventType}`, 'DBG_UNKNOWNSLACKEVENT', newItem)
        }
      })

      this.items = forceUpdate ? list : [].concat(this.items, list)
      this.validItems = forceUpdate ? validList : [].concat(this.validItems, validList)
      this.isEmpty = this.reactedOnly ? !this.validItems.length : !this.items.length
      this.hasError = false
      this.errorMessage = ''

      metaInfo.forEach(infoMsg => {
        // Message Changes
        if (this.eventType(infoMsg) === 'message_changed') {
          if (!infoMsg.event.previous_message) { return }
          const targetIndex = this.items.findIndex(item => {
            return item.event.ts === infoMsg.event.previous_message.ts
          })
          if (targetIndex !== -1) {
            const targetMsg = JSON.parse(JSON.stringify(this.items[targetIndex]))
            let updateAsNew = false

            // Message Text Edited
            if (infoMsg.event.message.edited) {
              targetMsg.event.text = infoMsg.event.message.text
              targetMsg.isEdited = true

              // Plus, it's a thread's root item as well
              if (infoMsg.event.message.thread_ts && infoMsg.event.message.thread_ts === infoMsg.event.message.ts) {
                EventBus.$emit('slack-thread-updated', JSON.parse(JSON.stringify(infoMsg.event.message)))
              }
            }

            // Comment Message Edited
            if (infoMsg.event.message.subtype === 'file_comment' && infoMsg.event.message.comment) {
              targetMsg.event.text = infoMsg.event.message.comment.comment
            }

            // URL attachments
            if (infoMsg.event.message.attachments) {
              targetMsg.attachments = infoMsg.event.message.attachments
            }

            // New Thread Replies
            if (infoMsg.event.message.subtype === 'thread_broadcast' && infoMsg.event.message.root) {
              targetMsg.root = infoMsg.event.message.root
              EventBus.$emit('slack-thread-updated', JSON.parse(JSON.stringify(infoMsg.event.message.root)))

              // Update the replies count under thread's root item
              const rootItemIndex = this.items.findIndex(item => {
                return item.event.ts === infoMsg.event.message.root.ts
              })
              if (rootItemIndex !== -1) {
                this.$set(this.items[rootItemIndex], 'reply_count', infoMsg.event.message.root.reply_count || 0)
              }
            }

            // Target is thread root, and its children thread got deleted
            if (infoMsg.event.message.thread_ts && infoMsg.event.message.thread_ts === infoMsg.event.message.ts) {
              targetMsg.reply_count = infoMsg.event.message.reply_count || 0
            } else if (!infoMsg.event.message.thread_ts && infoMsg.event.previous_message.thread_ts) {
              targetMsg.reply_count = 0
            }

            // Reaction added to a Shared File
            if (infoMsg.event.message.file && infoMsg.event.message.file.reactions) {
              targetMsg.reactions = infoMsg.event.message.file.reactions
              updateAsNew = true
            }

            this.$set(this.items, targetIndex, targetMsg)
            this.updateValidList(targetMsg, updateAsNew)
          }

        // Reactions
        } else if (this.eventType(infoMsg) === 'reaction_added' || this.eventType(infoMsg) === 'reaction_removed') {
          if (!infoMsg.event.item) { return }
          const targetIndex = this.items.findIndex(item => {
            return item.event.ts === infoMsg.event.item.ts
          })
          if (targetIndex !== -1) {
            const targetMsg = JSON.parse(JSON.stringify(this.items[targetIndex]))
            if (!targetMsg.reactions) {
              targetMsg.reactions = []
            }

            // Property `reaction` is from live event update, `name` is from history data
            const action = infoMsg.event?.reaction
            const user = infoMsg.event?.item_user

            // Reaction added
            if (this.eventType(infoMsg) === 'reaction_added') {
              const actionIndex = targetMsg.reactions.findIndex(reaction => {
                return reaction.name === action
              })

              if (actionIndex === -1) {
                targetMsg.reactions.push({
                  name: action,
                  count: 1,
                  users: [user]
                })
              } else if (!targetMsg.reactions[actionIndex]?.users?.includes(user)) {
                targetMsg.reactions[actionIndex].count++
                targetMsg.reactions[actionIndex].users.push(user)
              }

              EventBus.$emit('slack-reaction-added', infoMsg.event)

            // Reaction Removed
            } else {
              const actionIndex = targetMsg.reactions.findIndex(reaction => {
                return reaction.name === action
              })

              if (actionIndex > -1) {
                const userIndex = targetMsg.reactions[actionIndex].users.findIndex(u => {
                  return u === user
                })
                if (userIndex > -1) {
                  targetMsg.reactions[actionIndex].count--
                  targetMsg.reactions[actionIndex].users.splice(userIndex, 1)
                }
                if (targetMsg.reactions[actionIndex].count === 0) {
                  targetMsg.reactions.splice(actionIndex, 1)
                }
              }
            }

            this.$set(this.items, targetIndex, targetMsg)
            this.updateValidList(targetMsg, this.eventType(infoMsg) === 'reaction_added')
          }

        // Message Deleted
        } else if (this.eventType(infoMsg) === 'message_deleted') {
          if (!infoMsg.event.previous_message) { return }
          const targetIndex = this.items.findIndex(item => {
            return item.event.ts === infoMsg.event.previous_message.ts
          })
          if (targetIndex !== -1) {
            this.items.splice(targetIndex, 1)
            this.deleteFromValidList(infoMsg)
          }
        }
      })

      if (!this.reactedOnly) {
        this.sortItemsByDate()
      } else {
        this.renderReactedList()
      }

      if (this.localKey) {
        const storeData = [].concat([], this.items)
        if (storeData.length > config.STORE_LIMIT) {
          storeData.splice(storeData.length - config.STORE_LIMIT, storeData.length)
        }
        OfflineCaches.set(this.localKey, storeData)
      }
    },

    eventType (item) {
      if (!item.event || !item.event.type) { return }

      if (item.event.type === 'message') {
        // Plain Text message
        if (!item.event.subtype) {
          return 'message'
        // Follow up rich meta info
        } else {
          return item.event.subtype
        }
      }
      return item.event.type
    },

    scrollToBottom () {
      clearTimeout(this.scrollTimer)
      const wrapper = this.$el.querySelector('.inner-wrapper')
      if (!wrapper) { return }
      wrapper.scrollTop = wrapper.scrollHeight
      // Double insurance
      clearTimeout(this.scrollTimer)
      this.scrollTimer = setTimeout(() => {
        wrapper.scrollTop = wrapper.scrollHeight
        clearTimeout(this.scrollTimer)
      }, config.SCROLL_DELAY)
    },

    getFullDate (rawTime) {
      return Moment(rawTime, 'X').format('YYYY-MM-DD')
    },

    sortItemsByDate () {
      const items = JSON.parse(JSON.stringify(this.items))
      const sortedList = []
      const existingDates = []
      items.forEach(item => {
        const date = this.getFullDate(item.event_time)
        if (!existingDates.includes(date)) {
          existingDates.push(date)
          sortedList.push({
            date,
            firstTimestamp: item.event_time,
            messages: [item]
          })
        } else {
          const existingIndex = sortedList.findIndex(li => {
            return li.date === date
          })
          sortedList[existingIndex].messages.push(item)
        }
      })

      this.itemsByDate = sortedList

      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },

    updateValidList (newItem, updateAsNew) {
      if (!this.reactedOnly) { return }
      const targetIndex = this.validItems.findIndex(item => {
        return item.event.ts === newItem.event.ts
      })
      if (targetIndex !== -1) {
        // If all reactions are removed
        if (!newItem.reactions || !newItem.reactions.length) {
          this.validItems.splice(targetIndex, 1)
        } else {
          this.$set(this.validItems, targetIndex, newItem)
        }
      } else if (updateAsNew) {
        this.validItems.push(newItem)
        this.isEmpty = false
      }
      if (this.reactedOnly) {
        if (!this.isEmpty) {
          this.renderReactedList()
        }
      }
    },

    renderReactedList () {
      if (!this.validItems || !this.validItems.length) { return }

      this.validItems.sort((a, b) => {
        if (a.event_time < b.event_time) { return 1 }
        if (a.event_time > b.event_time) { return -1 }
        return 0
      })

      const nextItem = JSON.parse(JSON.stringify(this.validItems[0]))
      // Is the current item, just update the contents
      if (this.currentItem && this.currentItem.event && nextItem.event && this.currentItem.event.ts === nextItem.event.ts) {
        if (this.showPrime) {
          this.primeItem = nextItem
        } else {
          this.baseItem = nextItem
        }
      // Is a new item, fade in
      } else {
        if (this.showPrime) {
          this.baseItem = nextItem
          this.showPrime = false
        } else {
          this.primeItem = nextItem
          this.showPrime = true
        }
      }
      this.currentItem = nextItem
    },

    deleteFromValidList (targetItem) {
      if (!this.reactedOnly || !targetItem.event.previous_message) { return }
      const targetIndex = this.validItems.findIndex(item => {
        return item.event.ts === targetItem.event.previous_message.ts
      })
      if (targetIndex !== -1) {
        this.validItems.splice(targetIndex, 1)
        this.isEmpty = !this.validItems.length
      }
    },

    async showCachedData () {
      if (this.hasRendered) { return }

      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        const staleUsers = await OfflineCaches.get(`${this.localKey}_users`)
        const staleBots = await OfflineCaches.get(`${this.localKey}_bots`)

        if (staleUsers) {
          this.$store.commit('setSlackUsers', staleUsers)
        }

        if (staleBots) {
          this.$store.commit('setSlackBots', staleBots)
        }

        if (staleData && staleData.length) {
          this.$store.commit('setSlackMessages', {messages: staleData, channelID: this.channelID})
          this.hasError = false
          this.errorMessage = ''
        } else {
          this.hasError = true
          this.errorMessage = defaultErrorMsg
          this.isEmpty = false
        }
      } else {
        this.hasError = true
        this.errorMessage = defaultErrorMsg
        this.isEmpty = false
      }
      this.loaded()
      this.hasRendered = true
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      const container = this.$el
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        const metrics = container.getBoundingClientRect()

        if (!metrics.width || !metrics.height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: metrics.width,
          h: metrics.height
        }

        this.$nextTick(() => {
          this.scrollToBottom()
        })

        FastDom.clear(measure)
      })
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.slack-page-item(:style="baseFontSize")
  .resize-sensor(ref="sensor")

  template(v-if="!reactedOnly")
    .sidebar
      .branding(:style="brandingLogo")
    .main-wrapper(:class="{'is-wide': isWide}")
      h1.channel-name(v-if="channelName && channelName.length") &#35;{{ channelName }}
      .main-content
        .inner-wrapper
          .date-wrapper(v-for="date in itemsByDate", :key="date.date")
            slack-date-marker(:time="date.firstTimestamp")
            template(v-for="item in date.messages")
              slack-app-item(v-if="!item.hiddenThread", :key="item.event_id", :item="item")

        //- Loading
        transition(name="fade" mode="out-in", :duration="{enter: 100, leave: 300}" appear)
          .loading-mask(v-if="loading")
            spinner(size="5em")

        transition(name="fade" appear)
          .messages(v-if="(isEmpty || hasError) && !loading")
            .is-empty(v-if="isEmpty && !hasError") Recent messages not found on&nbsp;
              template(v-if="channelName && channelName.length") &#35;{{channelName}}
              template(v-else) this channel
            .has-error(v-if="hasError") {{ errorMessage }}

  template(v-if="reactedOnly")
    .main-wrapper.reacted-only
      template(v-if="validItems.length")
        transition(name="fade" appear mode="out-in")
          slack-app-item(v-if="showPrime", :item="primeItem" reacted-only)
        transition(name="fade" appear mode="out-in")
          slack-app-item(v-if="!showPrime", :item="baseItem" reacted-only)

      //- Loading
      transition(name="fade" mode="out-in", :duration="{enter: 100, leave: 300}" appear)
        .loading-mask(v-if="loading")
          spinner(size="5em")

      transition(name="fade" appear)
        .messages(v-if="(isEmpty || hasError) && !loading")
          .is-empty(v-if="isEmpty && !hasError") No Messages With Reactions on&nbsp;
            template(v-if="channelName && channelName.length") &#35;{{channelName}}
            template(v-else) this channel
          .has-error(v-if="hasError") {{ errorMessage }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

$sideBarBg = #303E4D

.slack-page-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;

  > .resize-sensor {
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
  }

  .loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  //- Error Messages
  .messages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    padding: 0 1em;
  }

  .sidebar {
    width: 6%;
    background: $sideBarBg;
    padding: 0.8em 1em 2em 1em;

    .branding {
      padding-top: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 0%;
    }
  }

  .channel-name {
    font-family: inherit;
    font-size: 1.5em;
    color: var(--gray-dark);
    font-weight: 600;
    padding: 0.8em 1.2em;
    border-bottom: 1px solid -black(0.15);
    margin: 0;
  }

  .main-wrapper {
    flex: 1 1 0.00001px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    overflow: hidden;
    position: relative;

    .main-content {
      flex: 1 1 0.00001px;
      overflow: hidden;
      position: relative;
      z-index: 1;

      .inner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1.5em 0;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;
      }
    }

    &.is-wide {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .channel-name {
        padding: 0.3em 1em;
        border-right: 1px solid -black(0.15);
        border-bottom: 0;
      }

      .main-content {
        .inner-wrapper {
          padding: 1em 1.5em 0 1.5em;
        }
      }
    }

    &:not(.reacted-only) {
      background: #f9f9f9;
      color: var(--gray);
    }

    &.reacted-only {
      color: -white(0.6);
    }
  }
}
</style>
