<script>
import CommonStyling from 'services/common-styling'
import { positionDefault, textDefault } from 'services/free-form'

export default {
  name: 'ObjectText',

  props: {
    text: {
      type: String,
      required: true
    },
    x: {
      type: Number,
      default: positionDefault.x,
      validator: value => value >= 0 && value <= 100
    },
    y: {
      type: Number,
      default: positionDefault.y,
      validator: value => value >= 0 && value <= 100
    },
    width: {
      type: Number,
      default: textDefault.width,
      validator: value => value >= 0 && value <= 100
    },
    rotate: {
      type: Number,
      default: positionDefault.rotate,
      validator: value => value >= 0 && value <= 360
    },
    textColor: {
      type: String,
      default: textDefault.textColor
    },
    fontSize: {
      type: Number,
      default: textDefault.fontSize,
      validator: value => value >= 0
    },
    font: {
      type: String,
      default: textDefault.font
    },
    hAlign: {
      type: String,
      default: textDefault.hAlign
    },
    lineHeight: {
      type: Number,
      default: textDefault.lineHeight
    },
    opacity: {
      type: Number,
      default: textDefault.opacity,
      validator: value => value >= 0 && value <= 1
    },
    playlistThemeIsSet: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    positionStyle () {
      return {
        // The true `width` style is defined in ascestor FreeFormItemContainer
        width: '100%',
        height: 'auto',
        left: `${this.x}%`,
        top: `${this.y}%`
      }
    },

    textStyle () {
      return {
        color: this.textColor,
        fontSize: `${this.fontSize}px`,
        textAlign: this.hAlign,
        lineHeight: `${(this.lineHeight || 1) * 100}%`,
        opacity: this.opacity
      }
    },

    fontClass () {
      if (this.playlistThemeIsSet) {
        // Playlist Font is set, skip object-level font settings
        return ''
      }
      return this.font ? CommonStyling.getfontClass(this.font) : ''
    }
  },

  watch: {
    fontClass (newClass = '') {
      if (newClass) {
        CommonStyling.loadExtendFont(newClass)
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      CommonStyling.loadExtendFont(this.fontClass)
    })
  }
}
</script>

<template lang="pug">
.object-text(:class="fontClass" :style="[positionStyle, textStyle]")
  | {{ text }}
</template>

<style lang="stylus">
.object-text
  font-family: var(--font-secondary)
  // Preserve line breaks
  white-space: pre-wrap
</style>
