<script>
import MetricsMixins from './MetricsMixins.vue'
import MetricsDataMixins from './MetricsDataMixins.vue'

import LeaderboardItem from './LeaderboardItem.vue'

import { EventBus } from 'services/eventbus.js'

export default {
  name: 'MetricsLeaderboard',
  components: { LeaderboardItem },

  mixins: [MetricsMixins, MetricsDataMixins],

  data () {
    return {
      data: [],
      itemsData: [],

      itemsMetricKeysMap: []
    }
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)
    this.renderInitData()
  },

  beforeDestroy () {
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        return
      }

      const refsMap = []
      this.validItems.forEach((item, itemIdx) => {
        const metric = item.metric

        if (!refsMap[itemIdx]) {
          refsMap[itemIdx] = {}
        }

        if (metric && metric.ref && metric.ref.length) {
          refsMap[itemIdx].ref = metric.ref
        }
      })

      this.itemsMetricKeysMap = refsMap
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateItemData (idx) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[idx]
      if (!item) { return }

      const targetData = ((this.data && this.data[idx]) || {}).value
      if (!targetData) { return }

      const leaders = []
      if (Array.isArray(targetData)) {
        targetData.forEach(leader => {
          if (Array.isArray(leader) && leader.length >= 3) {
            leaders.push({
              // Decode values where we expect strings
              name: this.decodeEntities(leader[0]),
              description: this.decodeEntities(leader[1]),
              points: leader[2]
            })
          } else if (typeof leader === 'object') {
            leader.name = this.decodeEntities(leader.name)
            leader.description = this.decodeEntities(leader.description)
            leaders.push(leader)
          }
        })
      }

      this.$set(this.itemsData, idx, {
        highlight_color: item.highlight_color,
        stripe_color: item.stripe_color,
        paginate: item.paginate,
        leaders: leaders
      })
    },

    // Ref from "henry-he" on stackoverflow: https://stackoverflow.com/a/44195856
    decodeEntities (encodedString) {
      if (typeof encodedString === 'number') {
        return String(encodedString)
      }
      if (!encodedString) return ''
      const translateRe = /&(nbsp|amp|quot|lt|gt);/g
      const translate = {
        'nbsp': ' ',
        'amp': '&',
        'quot': '"',
        'lt': '<',
        'gt': '>'
      }
      return encodedString.replace(translateRe, function (match, entity) {
        return translate[entity]
      }).replace(/&#(\d+);/gi, function (match, numStr) {
        const num = parseInt(numStr, 10)
        return String.fromCharCode(num)
      })
    }
  }
}
</script>

<template lang="pug">
section.metrics-leaderboard
  transition(:name="transitionName", :duration="500" appear)
    leaderboard-item.prime(v-if="showPrime && primeItem"
                    :title="itemTitle(primeItem)"
                    :remove-title="removeTitle"
                    :item="itemData(primeIndex)"
                    :sort-by-value="primeItem.sort"
                    :is-paginated="needsPaginate"
                    :interval="interval")

  transition(:name="transitionName", :duration="500" appear)
    leaderboard-item.base(v-if="!showPrime && baseItem"
                    :title="itemTitle(baseItem)"
                    :remove-title="removeTitle"
                    :item="itemData(baseIndex)"
                    :sort-by-value="baseItem.sort"
                    :is-paginated="needsPaginate"
                    :interval="interval")
</template>

<style lang="stylus">
section.metrics-leaderboard
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-leaderboard-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
