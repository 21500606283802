<script>
import Moment from 'moment-timezone'

export default {
  name: 'Date',

  props: {
    noAbbreviate: { type: Boolean, default: false },
    timezone: { type: String, default: '' }
  },

  data () {
    return {
      tickInterval: undefined,
      date: undefined
    }
  },

  mounted () {
    this.tick()

    clearInterval(this.tickInterval)
    this.tickInterval = setInterval(() => {
      this.tick()
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.tickInterval)
  },

  methods: {
    tick () {
      const now = Moment()

      let timeNow
      if (this.timezone && this.timezone.length > 0) {
        timeNow = Moment.tz(now, this.timezone)
      } else {
        timeNow = now
      }

      if (this.noAbbreviate) {
        this.date = timeNow.format('dddd, MMMM Do')
      } else {
        this.date = timeNow.format('ddd, MMM D')
      }
    }
  }
}
</script>

<template lang="pug">
.date-widget
  .datetime(v-text="date")
</template>

<style lang="stylus">
.date-widget
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center

  .datetime
    font-size: 1.2em
    letter-spacing: 0.03em
    word-spacing: 0.08em
</style>
