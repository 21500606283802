<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import Pages from 'services/pages.js'

import PageThumbnail from 'components/common/PageThumbnail.vue'

// In ms. Pull devices campaigns every interval
const CAMPAIGN_REFRESH_INTERVAL = 60000

export default {
  name: 'Overview',
  components: {
    PageThumbnail
  },

  data () {
    return {
      scrollToOpt: {
        container: '#pages-scroll-area',
        offset: -210,
        x: true,
        y: false
      },

      debounceTimer: undefined,
      checkAdTimer: undefined,
      campaignRefreshTimer: undefined
    }
  },

  computed: {
    ...mapState({
      currentPageID: s => s.playlists.currentPageID,
      activeOverride: s => s.overrides.activeOverride,
      screensaverIsOn: s => s.playbackmode.screensaverMode,
      deviceValidCampaigns: s => s.campaigns.deviceValidCampaigns || []
    }),
    ...mapGetters([
      'pages',
      'currentPage',
      'advertisingEnabled',
      'apiIsOffline',
      'deviceTagsSorted',
      'deviceCampaigns'
    ]),

    isShowingDefaultPage () {
      return Pages.isShowingDefaultPage(this.currentPage)
    },

    hasCampaigns () {
      return this.deviceCampaigns && this.deviceCampaigns.length > 0
    },

    hasValidCampaigns () {
      return this.deviceValidCampaigns && this.deviceValidCampaigns.length > 0
    },

    hasEnableByDayAd () {
      if (!this.hasCampaigns || this.hasValidCampaigns) { return false }
      return this.deviceCampaigns.some(campaign => {
        const schedule = campaign.valid_periods
        return Boolean(schedule && ((schedule.days && schedule.days.length > 0) || schedule.start_time || schedule.end_time))
      })
    },

    hasEnableByTimeAd () {
      if (!this.hasCampaigns || this.hasValidCampaigns) { return false }
      return this.deviceCampaigns.some(campaign => {
        const schedule = campaign.valid_periods
        return Boolean(schedule && schedule.start_hour && schedule.start_hour.length > 0 && schedule.end_hour && schedule.end_hour.length > 0)
      })
    }
  },

  watch: {
    currentPageID (pageID) {
      if (pageID) {
        this.debounceScrollTo(pageID)
      }
    },

    deviceTagsSorted: {
      deep: true,
      handler () {
        // Update campaigns right away
        this.getCampaigns()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    this.getCampaigns()

    clearInterval(this.checkAdTimer)
    this.checkAdTimer = setInterval(() => {
      if (this.advertisingEnabled && this.hasCampaigns) {
        this.$store.dispatch('checkForActiveCampaign')
      }
    }, 1000)

    clearInterval(this.campaignRefreshTimer)
    this.campaignRefreshTimer = setInterval(() => {
      this.getCampaigns()
    }, CAMPAIGN_REFRESH_INTERVAL)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearInterval(this.checkAdTimer)
    clearInterval(this.campaignRefreshTimer)
  },

  methods: {
    pageIcon (page) {
      if (this.isFreeFormPage(page)) {
        return 'layer-group'
      }

      // Page with non-default grid layout
      if (page.grid_template && page.items && page.items.length > 1) {
        return ['far', 'th-large']
      }

      const pageType = (page.items && page.items[0] && page.items[0].type) || page.type

      // Unknown or Empty page
      if (!pageType) {
        return ['far', 'square']
      }

      switch (pageType) {
        case 'video':
          return 'video'
        case 'image':
          return 'image'
        case 'slides':
          return 'copy'
        case 'media':
          return 'photo-video'
        case 'board':
        case 'board_v2':
          return 'th'
        case 'media_folder':
        case 'onedrive_folder':
          return 'folder-open'
        // NOTE @ Sep 2018: No more'info' and 'alert' type.
        // To be deprecated?
        case 'info':
          return 'info-circle'
        case 'alert':
          return 'exclamation-triangle'
        default:
          return 'cube'
      }
    },

    isAdPlacement (page) {
      return page.type === 'advertising'
    },

    isActive (pageID) {
      // Do not highlight current page when device is being overridden
      if (this.activeOverride && this.activeOverride.type !== 'playlists') { return false }
      return this.currentPageID && this.currentPageID === pageID
    },

    notVisible (page) {
      return !Pages.pageIsVisible(page)
    },

    dimPageThumbnail (page) {
      return this.showInvisibleSign(page) || this.isAdPageInvisible(page)
    },

    showHintIcons (page) {
      return !this.isAdPlacement(page) && (this.showInvisibleSign(page) || this.notVisible(page) || this.isHideInMenu(page))
    },

    showInvisibleSign (page) {
      if (this.isProgrammaticAd(page) && this.apiIsOffline) {
        return true
      }
      return this.notVisible(page) && !(this.isDefaultPage(page) && this.isShowingDefaultPage)
    },

    isFreeFormPage (page) {
      return Boolean(page?.layout_scheme === 'freeform')
    },

    isDefaultPage (page) {
      return Boolean(page && page.default_page)
    },

    isRepeatEveryXPage (page) {
      return Boolean(page && page.min_period && page.min_period > 0)
    },

    isEnableByDay (page) {
      return Boolean(page && ((page.days && page.days.length > 0) || page.start || page.end))
    },

    isEnableByTime (page) {
      return Boolean(page && page.hours && page.hours.length > 0)
    },

    isFilteredByTags (page) {
      return Boolean(page && ((page.require_tags && page.require_tags.length) || (page.ignored_tags && page.ignored_tags.length)))
    },

    // Controlled by Playlist Shared Counters
    isCounterPage (page) {
      return Pages.isCounterPage(page)
    },

    isProgrammaticAd (page) {
      return page.type !== 'advertising' && Pages.needsLookAhead(page)
    },

    // Hide page in the Interactive Menu (DEV-3467)
    isHideInMenu (page) {
      return Boolean(page && page.hide_in_menu)
    },

    // Page visible in the Interactive Menu only, skip in regular playback (DEV-3527)
    isMenuOnly (page) {
      return Boolean(page && page.in_menu_only)
    },

    isAdPageInvisible (page) {
      return this.isAdPlacement(page) && (this.apiIsOffline || !this.hasValidCampaigns)
    },

    getCampaigns () {
      if (this.advertisingEnabled && !this.apiIsOffline) {
        this.$store.dispatch('getCampaigns')
      }
    },

    scrollToItem (pageID) {
      this.$scrollTo(`#page-item-${pageID}`, 300, this.scrollToOpt)
    },

    debounceScrollTo (pageID) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.scrollToItem(pageID)
      }, 200)
    },

    goToPage (page) {
      if (this.screensaverIsOn) {
        Pages.stopScreensaver(page)
      } else {
        Pages.goToPage(page)
      }
    }
  }
}
</script>

<template lang="pug">
section#overview
  #pages-scroll-area.pages-list
    .list-inner
      .page-item(v-for="page in pages", :key="page.id"
                 :id="'page-item-' + page.id"
                 :class="{active: isActive(page.id), 'not-visible': dimPageThumbnail(page), 'is-ttvad': isAdPlacement(page)}"
                 @click="goToPage(page)")
        .icon-n-label(v-if="!isAdPlacement(page)")
          .icon
            fa.fa-icon(:icon="pageIcon(page)" fixed-width)
          .label {{page.description || page.type}}

        .counter-ad-flag(v-if="isCounterPage(page) || isProgrammaticAd(page)")
          fa.fa-icon(v-if="isCounterPage(page)", :icon="['far', 'stopwatch']" fixed-width)
          fa.fa-icon(v-if="isProgrammaticAd(page)" icon="ad" fixed-width)

        //- Hint icons for regular pages
        .hint(v-if="showHintIcons(page)")
          fa.fa-icon(v-if="showInvisibleSign(page)", :icon="['far', 'eye-slash']" fixed-width)
          fa.fa-icon(v-if="isDefaultPage(page)" icon="star" fixed-width)
          fa.fa-icon(v-if="isEnableByDay(page)" icon="calendar-alt" fixed-width)
          fa.fa-icon(v-if="isEnableByTime(page)" icon="clock" fixed-width)
          fa.fa-icon(v-if="isRepeatEveryXPage(page)" icon="repeat-alt" fixed-width)
          fa.fa-icon(v-if="isFilteredByTags(page)" icon="tag" fixed-width)
          fa.fa-icon(v-if="isProgrammaticAd(page) && apiIsOffline" icon="wifi-slash" fixed-width)
          fa.fa-icon(v-if="isMenuOnly(page)" icon="bars" fixed-width)
          fa.fa-icon(v-if="isHideInMenu(page)" icon="align-slash" fixed-width)

        //- Hint icons for Ad slots
        .hint-ad(v-if="isAdPageInvisible(page)")
          fa.fa-icon(:icon="['far', 'eye-slash']" fixed-width)
          fa.fa-icon(v-if="apiIsOffline" icon="wifi-slash" fixed-width)
          fa.fa-icon(v-if="hasEnableByDayAd" icon="calendar-alt" fixed-width)
          fa.fa-icon(v-if="hasEnableByTimeAd" icon="clock" fixed-width)

        page-thumbnail(:page="page")
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section#overview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;

  .pages-list {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: visible;

    .list-inner {
      white-space: nowrap;
    }

    .page-item {
      display: inline-block;
      position: relative;
      margin: 0 0.5em;
      border-radius: 6px;
      overflow: hidden;
      background: #222;
      border: 3px solid #222;
      box-shadow: 2px 2px 6px -black(0.5);
      cursor: pointer;
      transition: border-color 0.2s, box-shadow 0.2s;

      .icon-n-label {
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background: -black(0.6);
        color: #fff;
        padding: 0.4em 0.5em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icon {
          padding-right: 0.5em;
        }

        .label {
          flex: 1 1 0.00001px;
          font-weight: 400;
          ellipsis();
        }
      }

      .hint,
      .hint-ad {
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        margin-left: 1.5em;
        z-index: 10;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;

        .fa-icon {
          color: -white(0.8);
          font-size: 1.2em;
          margin-left: 0.3em;
          margin-top: 0.3em;
          appIconShadow()
        }
      }

      .hint-ad {
        margin-left: 0;
        left: 0.5em;
      }

      .counter-ad-flag {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 5;
        padding: 0.1em;
        text-align: center;
        font-size: 1.2em;
        line-height: 100%;
        background: var(--brand-warning);
        border-radius: 0 4px 0 0;
        .fa-icon {
          color: #000;
          display: block;

          &:not(:first-child) {
            margin-top: 0.1em;
          }
        }
      }

      &.not-visible {
        box-shadow: none;
        background: alpha(#222, 0.5);
        border: 3px solid alpha(#222, 0.5);

        .icon-n-label {
          opacity: 0.4;
        }

        .page-thumbnail,
        .counter-ad-flag {
          opacity: 0.3;
        }
      }

      &.active {
        border-color: var(--brand-primary);
        box-shadow: 0 0 0 3px var(--brand-primary), 2px 2px 12px -black(0.35);
        background: var(--brand-primary);

        .icon-n-label {
          color: var(--brand-primary);
          background: -black(0.7);
        }
      }
    }
  }
}
</style>
