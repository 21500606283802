<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import Moment from 'moment-timezone'
import ICAL from 'ical.js'
import {
  mapGetters
} from 'vuex'

import Timezone from 'services/timezone'
import OfflineCaches from 'services/offline-caches'
import Env from 'services/environment'
import Log from 'services/log.js'

const RESOURCES_BASE = Env.resourcesURL()

// Cache for 5 minutes
// NOTE: Remember to update the "ICAL_DATA" value in ContentCache after changing this value
// - In second. For request header
const CACHE_CONTROL = 300
// - In ms. For LRUCache and refresh timer
const CACHE_MAX_AGE = CACHE_CONTROL * 1000

// Max UTC Offest time (24hrs). In ms
const MAX_UTC_OFFSET = 24 * 3600 * 1000

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // In ms
  // Add 1s extra interval to ensure the cache expires before next fetch
  ICAL_UPDATE_INTERVAL: +CACHE_MAX_AGE + 1000,

  // In ms. Transition Duration for current active event
  TRANSITION_TIME: 1500,

  // Limit maximum valid items, to prevent overwhelm the DOM
  MAX_ITEMS_COUNT: 20,

  // Parse the next ${n}th occurance
  REPEAT_DAY_COUNT: 14,
  REPEAT_WEEK_COUNT: 4,
  REPEAT_MONTH_COUNT: 3,
  REPEAT_YEAR_COUNT: 2,

  // Default repeat count for MINUTELY | SECONDLY from iCal
  REPEAT_DEFAULT_COUNT: 10,

  IS_THIN: 3,
  IS_WIDE: 4,
  BASE_COUNTDOWN_FONTSIZE: 5
}

// Get Recurrence Type
// > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
const ICAL_RECUR_TYPES_COUNT = {
  'YEARLY': 'REPEAT_YEAR_COUNT',
  'MONTHLY': 'REPEAT_MONTH_COUNT',
  'WEEKLY': 'REPEAT_WEEK_COUNT',
  'DAILY': 'REPEAT_DAY_COUNT',
  'MINUTELY': 'REPEAT_DEFAULT_COUNT',
  'SECONDLY': 'REPEAT_DEFAULT_COUNT'
}

const WEEKDAY_MAP = {
  'sun': 0,
  'mon': 1,
  'tue': 2,
  'wed': 3,
  'thu': 4,
  'fri': 5,
  'sat': 6
}

const A_MINUTE = 60
const AN_HOUR = 3600
const A_DAY = 86400
const A_WEEK = 604800

export default {
  name: 'CountdownPage',

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isUseIcal: false,

      // List countdown data from js_user
      iCalList: [],
      eventList: [],

      // Valid event to use
      validEvents: [],

      allEvents: [],

      containRecurring: false,

      stopAtZero: false,

      targetTimestamp: undefined,
      eventState: '',
      eventDisplayTime: '',

      showCountdownDay: true,
      showCountdownTime: true,
      showDayCombinedWithTime: false,
      daysLeft: 0,
      hoursLeft: 0,
      minutesLeft: 0,
      secondsLeft: 0,
      // For 'Now' and 'Today'
      countdownValue: '',

      showPulse: false,

      alertSoundPlayed: false,

      tickTimer: undefined,
      iCalTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'accountLocale',
      'contentForURL'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    timeUnset () {
      return !this.activeEvent.startHour && !this.activeEvent.startMinute && !this.activeEvent.startSecond
    },

    dimHour () {
      if (this.showCountdownDay || this.showCountdownTime) {
        return true
      }
      return this.hoursLeft === 0
    },

    dimMinute () {
      if (this.showCountdownDay || this.showCountdownTime) {
        return true
      }
      return this.dimHour && this.minutesLeft === 0
    },

    dimSecond () {
      if (this.showCountdownDay || this.showCountdownTime) {
        return true
      }
      return this.dimHour && this.dimMinute && this.secondsLeft === 0
    },

    // Filter duplicate url, empty url, empty label
    validIcalItems () {
      if (!this.isUseIcal || !this.iCalList) {
        return []
      }

      return this.iCalList
        .filter(obj => obj.label && obj.url) // filter empty url, label
        .filter((obj, idx, arr) => { // filter duplicate url
          return arr.map(mapObj => mapObj['url']).indexOf(obj['url']) === idx
        })
    },

    validEventItems () {
      if (this.isUseIcal || !this.eventList) {
        return []
      }

      return this.eventList.filter(obj => obj.title) // filter empty title
        .filter(obj => {
          if (obj.styleOpt === 'date' || obj.styleOpt === 'date-time') {
            return !!obj.date
          }

          if (obj.styleOpt === 'recurring' && obj.recurAt === 'daily') {
            return obj.validDays && obj.validDays.length
          }

          return true
        })
    },

    showDescription () {
      if (!this.activeEvent) {
        return false
      }

      return !!this.activeEvent.description
    },

    showPastText () {
      return !this.stopAtZero && this.eventState === 'past'
    },

    activeEvent () {
      if (!this.validEvents || !this.validEvents.length) {
        return
      }

      return this.validEvents[0]
    },

    eventTitle () {
      if (!this.activeEvent) { return '' }

      return this.activeEvent.title || ''
    },

    appID () {
      return this.item && this.item.ref_id
    },

    localKey () {
      if (this.appID && this.appID.length) {
        // Use the AppID as localStorage key
        return `ttvCountdowns_${this.appID}`
      }
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE
      }
      return false
    },

    isHighAndThin () {
      if (this.itemSize && this.itemSize.w && this.isPortrait) {
        return this.itemSize.h / this.itemSize.w > config.IS_THIN
      }
      return false
    },

    wideRatio () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h
      }

      return 1
    },

    countdownWrapperStyle () {
      if (this.isWide && this.wideRatio > 1) {
        return {fontSize: config.BASE_COUNTDOWN_FONTSIZE / this.wideRatio + 'em'}
      }

      return {}
    }

  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    'accountLocale.timezone': {
      handler () {
        if (this.isUseIcal) {
          this.fetchICals()
        }
      }
    },

    activeEvent (newVal) {
      if (!newVal) { return }
      this.setCountdownTs()
      this.controlShowingDayTime()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    // Show stale data first to speed up app display
    this.getStaleData()

    this.debounceCheckSize()
    this.render()
    this.tick()

    clearInterval(this.tickTimer)
    this.tickTimer = setInterval(() => {
      this.checkValidEvents()
      this.tick()
    }, 1000)

    clearInterval(this.iCalTimer)
    this.iCalTimer = setInterval(() => {
      if (this.isUseIcal) {
        this.fetchICals()
      }
    }, config.ICAL_UPDATE_INTERVAL)

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearInterval(this.tickTimer)
    clearInterval(this.iCalTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))
      this.isUseIcal = options.isUseIcal + '' === 'true'
      this.eventList = options.eventList || []
      this.iCalList = options.iCalList || []
      this.showDayCombinedWithTime = options.showDays + '' === 'true' || false

      this.stopAtZero = options.stopAtZero + '' === 'true'
      this.alertSoundPlayed = false // Reset everytime update new input

      // Fallback for app create before DEV-1043
      if (options.isUseIcal === undefined) {
        this.isUseIcal = false
        this.eventList = [
          {
            title: options.title || 'Unamed Event',
            date: options.date || '',
            startHour: options.startHour || '',
            startMinute: options.startMinute || '',
            startSecond: options.startSecond || '',

            styleOpt: options.styleOpt || 'date-time',
            recurAt: options.recurAt || 'daily',
            validDays: options.validDays || [],

            offsetHour: options.offsetHour || '',
            offsetMinute: options.offsetMinute || '',
            offsetSecond: options.offsetSecond || '',

            sound: options.sound || 'default'
          }
        ]
      }

      if (this.isUseIcal) {
        this.fetchICals()
      } else {
        this.processEvents()
      }
    },

    processEvents () {
      const eventList = []
      this.containRecurring = false
      this.validEventItems.forEach(event => {
        const { title, date, startHour, startMinute, startSecond, styleOpt, recurAt, validDays, description, offsetHour, offsetMinute, offsetSecond, sound } = event

        const itemTemplate = {
          title: title || '',
          date: date || '',
          startHour: startHour || '',
          startMinute: startMinute || '',
          startSecond: startSecond || '',
          styleOpt: styleOpt || 'date-time',
          recurAt: recurAt || 'daily',
          validDays: validDays || [],
          description: description || '',
          offsetHour: offsetHour || '',
          offsetMinute: offsetMinute || '',
          offsetSecond: offsetSecond || '',
          sound: sound || 'default'
        }

        if (styleOpt === 'date' || styleOpt === 'date-time') {
          eventList.push({...itemTemplate})
        } else if (styleOpt === 'recurring') {
          this.containRecurring = true
          const now = Moment()
          if (recurAt === 'hourly') {
            for (let i = 0; i < config.REPEAT_DEFAULT_COUNT; i++) {
              const nextStartHour = now.clone().add(i, 'h').hours()
              const startDate = now.clone().add(i, 'h').format('MM/DD/YYYY')

              const item = {
                ...itemTemplate,
                ...{
                  styleOpt: 'date-time',
                  date: startDate,
                  startHour: nextStartHour + '',
                  startMinute: itemTemplate.offsetMinute,
                  startSecond: itemTemplate.offsetSecond
                }
              }
              eventList.push(item)
            }
          } else if (recurAt === 'daily') {
            event.validDays.forEach(weekday => {
              for (let i = 0; i < config.REPEAT_DAY_COUNT; i++) {
                const startDate = now.clone().day(WEEKDAY_MAP[weekday] + i * 7).format('MM/DD/YYYY')

                const item = {
                  ...itemTemplate,
                  ...{
                    styleOpt: 'date-time',
                    date: startDate,
                    startHour: itemTemplate.offsetHour,
                    startMinute: itemTemplate.offsetMinute,
                    startSecond: itemTemplate.offsetSecond
                  }
                }
                eventList.push(item)
              }
            })
          } else if (recurAt === 'monthly') {
            for (let i = 0; i < config.REPEAT_DAY_COUNT; i++) {
              const offsetMonth = now.clone().add(i, 'months')
              const startDate = offsetMonth.date(1).format('MM/DD/YYYY')

              const item = {
                ...itemTemplate,
                ...{
                  styleOpt: 'date-time',
                  date: startDate,
                  startHour: itemTemplate.offsetHour,
                  startMinute: itemTemplate.offsetMinute,
                  startSecond: itemTemplate.offsetSecond
                }
              }
              eventList.push(item)
            }
          }
        }
      })
      this.allEvents = eventList
      this.sortEventAndSaveData()
    },

    fetchICals () {
      if (!this.isUseIcal || !this.iCalList || !this.iCalList.length || !this.validIcalItems || !this.validIcalItems.length) { return }

      this.containRecurring = false
      const promises = []
      this.validIcalItems.forEach((ical, index) => {
        promises.push(this.fetchSingleIcal(ical))
      })

      Promise.all(promises).then(results => {
        const fetchedEvents = [].concat.apply([], results)
        this.allEvents = fetchedEvents
        this.sortEventAndSaveData()
      }).catch(() => {
        this.resetDisplayValue()
        this.getStaleData()
      })
    },

    fetchSingleIcal (ical) {
      if (!ical) { return }
      const { label, url: iCalURL, sound: alertSound, description } = ical
      return this.$store.dispatch('getContentForURL', {
        url: iCalURL,
        // LRUCache accepts `maxAge` in ms.
        maxAge: CACHE_MAX_AGE,
        // Set a shorter cache time for iCals [DEV-2860]
        cacheName: 'ICAL_DATA'
      }).then(() => {
        const iCalString = this.contentForURL(iCalURL)
        const iCalParsed = ICAL.parse(iCalString)
        const vcalendar = new ICAL.Component(iCalParsed)
        const vevents = vcalendar.getAllSubcomponents('vevent')

        const now = Moment().valueOf()

        // ==========================================================
        // Filter out outdated events to speed up parsing [DEV-2771]

        // [O] Recurrence Exception Instances [DEV-2876]
        const RecurrenceExecptions = []

        // [A] One Time events
        const oneTimeEvents = vevents.filter((vevent, veIdx) => {
          const rrule = vevent.getFirstPropertyValue('rrule')
          const recurrenceId = vevent.getFirstPropertyValue('recurrence-id')

          // Has "rrule" >> recurring event
          if (rrule) { return false }

          const dtend = vevent.getFirstPropertyValue('dtend')

          // Rough test. Rule out any events that ends 24hrs before now.
          if (dtend && (dtend.toUnixTime() * 1000 + MAX_UTC_OFFSET) < now) {
            return false
          }

          // A particular altered instance of a recurring event
          // - "RECURRENCE-ID" property is used in conjunction with the "UID" and "SEQUENCE" properties to identify a particular instance of a recurring event,
          // > https://icalendar.org/iCalendar-RFC-5545/3-8-4-4-recurrence-id.html
          if (!rrule && recurrenceId) {
            RecurrenceExecptions.push(vevent)
          }

          return true
        })

        // [B] Reurring events
        const recurringEvents = vevents.filter((vevent, veIdx) => {
          const rrule = vevent.getFirstPropertyValue('rrule')

          // Does NOT have "rrule" >> one time
          if (!rrule) { return false }

          const rruleUntil = rrule && rrule.until

          // NOTE
          // a. `rruleUntil` is undefined - infinite repeat event
          // b. `rruleUntil` is defined - finite recurring event with an endtime
          if (rruleUntil && (rruleUntil.toUnixTime() * 1000) < now) {
            return false
          }
          return true
        })
        // EOF quick filtering
        // ==========================================================

        if (recurringEvents.length) {
          this.containRecurring = true
        }

        // ================================
        // Recurrence Exception Instances
        // ================================

        const recurExceptions = []

        if (RecurrenceExecptions.length) {
          RecurrenceExecptions.forEach((vevent, veIdx) => {
            const dstart = vevent.getFirstPropertyValue('dtstart')
            const item = {
              uid: vevent.getFirstPropertyValue('uid'),
              summary: vevent.getFirstPropertyValue('summary'),
              sequence: vevent.getFirstPropertyValue('sequence'),
              recurrenceId: vevent.getFirstPropertyValue('recurrence-id'),
              dtstart: dstart,
              dtend: vevent.getFirstPropertyValue('dtend'),
              startTs: dstart.toUnixTime(),
              vevent: JSON.parse(JSON.stringify(vevent))
            }
            recurExceptions.push(item)
          })
        }

        const events = []

        // ========================
        // NON-Recurring Events
        // ========================

        oneTimeEvents.forEach((vevent, veIdx) => {
          const dtstart = vevent.getFirstPropertyValue('dtstart')
          const dtend = vevent.getFirstPropertyValue('dtend')
          const duration = vevent.getFirstPropertyValue('duration')

          let durationMinute = 1440
          if (duration) {
            durationMinute = duration.toSeconds() / 60
          } else if (dtend) {
            durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
          }

          let start = Moment.tz(dtstart.toUnixTime() * 1000, 'UTC')

          try {
            // Adjust time with event timezone
            if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
              const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(dtstart.toUnixTime() * 1000)
              start.add(utcOffsetFix, 'minutes')
            }
          } catch (e) {}

          const end = start.clone().add(durationMinute, 'minutes')

          // Skip outdated events
          if (end.valueOf() < now) {
            return
          }

          const allDay = (durationMinute % 1440 === 0)

          // Display with account local time
          // All-day events don't have "timezone" property, hence no need to adjust
          if (!allDay) {
            start = start.tz(this.accountLocale.timezone)
          }

          const summary = vevent.getFirstPropertyValue('summary')

          const event = {
            title: summary || label || '',
            date: start.format('MM/DD/YYYY') || '',
            description: description || '',
            startHour: start.hour(),
            startMinute: start.minute(),
            startSecond: start.second(),
            styleOpt: 'date-time',
            sound: alertSound || 'default'
          }
          events.push(event)
        }) // EOF oneTimeEvents

        // ========================
        // Recurring Events
        // ========================

        recurringEvents.forEach((vevent, veIdx) => {
          const summary = vevent.getFirstPropertyValue('summary')
          const dtstart = vevent.getFirstPropertyValue('dtstart')
          const dtend = vevent.getFirstPropertyValue('dtend')
          const duration = vevent.getFirstPropertyValue('duration')

          let durationMinute = 1440
          if (duration) {
            durationMinute = duration.toSeconds() / 60
          } else if (dtend) {
            durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
          }
          const allDay = (durationMinute % 1440 === 0)

          const icEvent = new ICAL.Event(vevent)

          // Get Recurrence Type to reduce "while" loops [DEV-2771]
          // > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
          const recurType = this.getRecurType(icEvent.getRecurrenceTypes())
          const maxRepeatCountByType = config[ICAL_RECUR_TYPES_COUNT[recurType]] || config.REPEAT_DEFAULT_COUNT

          const rrule = vevent.getFirstPropertyValue('rrule')
          const rruleUntil = rrule && rrule.until

          const uid = vevent.getFirstPropertyValue('uid')
          const sequence = vevent.getFirstPropertyValue('sequence')

          const expand = new ICAL.RecurExpansion({
            component: vevent,
            dtstart: dtstart
          })

          let start
          let end
          let testEnd

          let next = dtstart

          // Count for valid (ts >= now) item
          let count = 0

          while (
            count < maxRepeatCountByType &&
            (next = expand.next())
          ) {
            if (
              !next ||
              (!!rruleUntil && (next.toUnixTime() + (MAX_UTC_OFFSET / 1000) > rruleUntil.toUnixTime()))
            ) {
              // No valid "next" found, or it already passed the rule until. Break the while loop.
              return
            }

            start = Moment.tz(next.toUnixTime() * 1000, 'UTC')
            testEnd = start.clone().add(durationMinute, 'minutes')

            // Rough test. Rule out any events that ends 24hrs before now.
            if ((testEnd.valueOf() + MAX_UTC_OFFSET) < now) {
              continue
            }

            if (recurExceptions.length) {
              const exception = recurExceptions.find(item => {
                return (item.uid === uid && item.sequence === sequence && item.startTs === next.toUnixTime())
              })
              if (exception) {
                // [DEV-2876] Current recurring instance matches one of the Recurring Exceptions.
                // Any recurring exceptions is displayed as one-time event, so we should skip it in the recurring list
                continue
              }
            }

            try {
              // Adjust time with event timezone
              if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
                const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(next.toUnixTime() * 1000)
                start.add(utcOffsetFix, 'minutes')
              }
            } catch (e) {}

            // NOTE (DEV-2876): Final test for `rruleUntil`
            // Coz' `rruleUntil` is always in UTC time, while `next` may contain timezone info
            if (rruleUntil && start.valueOf() > (rruleUntil.toUnixTime() * 1000)) {
              continue
            }

            end = start.clone().add(durationMinute, 'minutes')

            // Final test for end-time with timezone offset
            if (end.valueOf() < now) {
              continue
            }

            count++

            // Display with account local time
            // All-day events don't have "timezone" property, hence no need to adjust
            if (!allDay) {
              start = start.tz(this.accountLocale.timezone)
            }

            const event = {
              title: summary || label || '',
              date: start.format('MM/DD/YYYY') || '',
              description: description || '',
              startHour: start.hour(),
              startMinute: start.minute(),
              startSecond: start.second(),
              styleOpt: 'date-time',
              sound: alertSound || 'default'
            }
            events.push(event)
          }
        }) // EOF recurringEvents

        return events || []
      }).catch(err => {
        Log.debug('app', `Countdown iCalendar fetch error - ${err.message || err.toString()}`, 'DBG_CALENDARFETCH2', { url: iCalURL })
        // NOTE: Move the *real* error catcher to Promise.all
        throw err
      })
    },

    sortEventAndSaveData () {
      if (!this.allEvents || !this.allEvents.length) {
        this.validEvents = []
        this.loaded()
        return
      }

      this.allEvents.sort((a, b) => {
        const aTs = this.getEventTs(a)
        const bTs = this.getEventTs(b)

        // when event timestamp is equal compare event title
        if (aTs === bTs) {
          const aTitle = a.title.toLowerCase()
          const bTitle = b.title.toLowerCase()

          if (aTitle < bTitle) { return -1 }
          if (aTitle > bTitle) { return 1 }
          return 1 // Get the first event if 2 event name is equal
        }

        return aTs - bTs
      })
      if (this.localKey) {
        OfflineCaches.set(this.localKey, this.allEvents)
      }
      this.checkValidEvents()
    },

    getRecurType (typesObj) {
      const keys = Object.keys(typesObj) || []
      if (!keys || !keys.length) { return }
      return keys[0]
    },

    checkValidEvents () {
      if (!this.allEvents || !this.allEvents.length) {
        this.resetDisplayValue()
        this.loaded()
        return
      }

      const now = Moment().unix()
      const newEvents = this.allEvents.filter(event => {
        const eventTs = this.getEventTs(event)
        return eventTs > now
      })

      // All event is passed
      if (!newEvents || !newEvents.length) {
        // Run out of event but not use `recurring` -> keep last event for `stopAtZero=false` case
        if (!this.containRecurring) {
          const lastEvent = this.allEvents[this.allEvents.length - 1]
          this.validEvents = [lastEvent]
          return
        }

        // Repare next recurring event
        if (this.isUseIcal) {
          this.fetchICals()
          return
        }

        this.processEvents()
        return
      }

      this.validEvents = newEvents.splice(0, config.MAX_ITEMS_COUNT)
      this.loaded()
    },

    resetDisplayValue () {
      this.validEvents = []
      this.daysLeft = 0
      this.hoursLeft = 0
      this.minutesLeft = 0
      this.secondsLeft = 0
      this.eventState = ''
      this.eventDisplayTime = 'No valid event found'
    },

    getEventTs (event) {
      if (!event || !event.date) {
        return 0
      }

      const startTime = `${event.startHour || '00'}:${event.startMinute || '00'}:${event.startSecond || '00'}`
      return Moment(`${event.date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
    },

    controlShowingDayTime () {
      if (!this.activeEvent) { return }

      const { styleOpt } = this.activeEvent
      if (styleOpt === 'date-time') {
        this.showCountdownDay = (this.daysLeft > 0)
        this.showCountdownTime = true
      } else if (styleOpt === 'date') {
        this.showCountdownDay = (this.daysLeft > 0)
        this.showCountdownTime = (this.daysLeft === 0)
      } else if (styleOpt === 'recurring') {
        const { recurAt } = this.activeEvent
        if (recurAt === 'hourly') {
          this.showCountdownDay = false
          this.showCountdownTime = true
        } else if ((recurAt === 'daily') || (recurAt === 'monthly')) {
          this.showCountdownDay = (this.daysLeft > 0)
          this.showCountdownTime = (this.daysLeft === 0)
        }
      }
    },

    setCountdownTs () {
      if (!this.activeEvent) { return }
      const { date: eventDate } = this.activeEvent
      if (!eventDate) {
        this.targetTimestamp = undefined
        return
      }

      const { startHour, startMinute, startSecond } = this.activeEvent
      const startTime = `${startHour || '00'}:${startMinute || '00'}:${startSecond || '00'}`
      const timestamp = Moment(`${eventDate} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()

      this.targetTimestamp = timestamp
    },

    tick () {
      if (!this.activeEvent) { return }

      const now = Moment()
      const nowTs = now.unix()

      const eventTime = Moment(this.targetTimestamp, 'X')

      if (!this.targetTimestamp) {
        this.eventState = ''
        return
      }

      if (this.timeUnset) {
        const dayStartTs = eventTime.clone().hour(0).minute(0).second(0).unix()
        const dayEndTs = eventTime.clone().hour(23).minute(59).second(59).unix()
        if (dayStartTs > nowTs) {
          this.eventState = 'future'
          this.renderCountdownValue(dayStartTs, nowTs)
          this.countdownValue = ''
          this.showPulse = false
        } else if (dayStartTs <= nowTs && dayEndTs >= nowTs) {
          this.eventState = 'active'
          this.countdownValue = 'Today'
          this.showPulse = true
          if (!this.alertSoundPlayed) {
            this.playAlertSound()
            this.alertSoundPlayed = true
          }
        } else {
          this.eventState = 'past'
          this.showPulse = false
          this.countdownValue = this.stopAtZero ? this.$t('_common.time.now') : ''
          this.renderCountdownValue(dayEndTs, nowTs)
        }
      // Specific Time is set
      } else {
        const targetTs = eventTime.unix()
        if (targetTs > nowTs) {
          this.eventState = 'future'
          this.countdownValue = ''
          // Show pulse when countdown time is within 1hr
          this.showPulse = (nowTs >= targetTs - AN_HOUR)
        } else {
          if (targetTs === nowTs) {
            this.eventState = 'active'
            this.showPulse = true
            if (!this.alertSoundPlayed) {
              this.playAlertSound()
              this.alertSoundPlayed = true
            }
            this.countdownValue = ''
          } else {
            this.eventState = 'past'
            this.showPulse = false
            this.countdownValue = this.stopAtZero ? 'Now' : ''
          }
        }
        this.renderCountdownValue(targetTs, nowTs)
      }

      this.renderEventDisplayTime()
    },

    renderCountdownValue (targetTs, nowTs) {
      const absDiff = Math.abs(targetTs - nowTs)

      // Display days count when > 24hr
      const showDay = absDiff > A_DAY

      const daysLeft = showDay ? ~~(absDiff / A_DAY) : 0

      let secondsLeft = showDay ? absDiff % A_DAY : absDiff

      const hoursLeft = ~~(secondsLeft / AN_HOUR)
      secondsLeft = absDiff % AN_HOUR
      const minutesLeft = ~~(secondsLeft / A_MINUTE)
      secondsLeft = absDiff % A_MINUTE

      this.daysLeft = daysLeft
      this.hoursLeft = hoursLeft
      this.minutesLeft = minutesLeft
      this.secondsLeft = secondsLeft

      this.$nextTick(() => {
        this.controlShowingDayTime()
      })
    },

    renderEventDisplayTime () {
      if (!this.activeEvent) { return }
      if (!this.targetTimestamp) {
        this.eventDisplayTime = ''
        return
      }

      const now = Moment()
      let displayTime = ''

      const eventTime = Moment(this.targetTimestamp, 'X')
      const nowTs = now.unix()
      const eventTs = eventTime.unix()

      const absDiff = Math.abs(eventTs - nowTs)
      const isPast = eventTs < nowTs

      // Within 24hr
      if (absDiff < A_DAY) {
        // Is Today
        if (eventTime.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
          displayTime += this.$t('_common.time.today')
        } else if (isPast) {
          displayTime += this.$t('_common.time.yesterday')
        } else {
          displayTime += this.$t('_common.time.tomorrow')
        }
        if (!this.timeUnset) {
          if (this.accountLocale.timeFormat === '12H') {
            displayTime += eventTime.format(' h:mm a')
          } else {
            displayTime += eventTime.format(' HH:mm')
          }
        }
      // Within a week
      } else if (absDiff < A_WEEK) {
        // Not in the same week
        if (eventTime.format('w') !== now.format('w')) {
          displayTime += `${isPast ? this.$t('_common.time.prefixLast') : this.$t('_common.time.prefixNext')} `
        }
        displayTime += eventTime.format('dddd')
        if (!this.timeUnset) {
          if (this.accountLocale.timeFormat === '12H') {
            displayTime += eventTime.format(', h:mm a')
          } else {
            displayTime += eventTime.format(', HH:mm')
          }
        }
      // More than a week
      } else {
        displayTime += eventTime.format('dddd, MMM Do')
        // Not in this year
        if (eventTime.format('YYYY') !== now.format('YYYY')) {
          displayTime += eventTime.format(', YYYY')
        }
      }
      this.eventDisplayTime = displayTime
    },

    fillZero (value) {
      if (+value < 10) {
        return `0${+value}`
      }
      return value
    },

    playAlertSound () {
      if (!this.activeEvent) { return }
      if (this.activeEvent.sound === 'mute') { return }

      const sound = this.activeEvent.sound === 'default' ? 'default_alert.mp3' : `${this.activeEvent.sound}.mp3`
      const audio = new window.Audio(`${RESOURCES_BASE}/sounds/${sound}`)
      // Workaround for the `play() can only be initiated by a user gesture` error in Chrome v65
      audio.setAttribute('muted', 'muted')
      audio.play().then(() => {
        if (audio.muted) { audio.muted = false }
      }).catch(DOMException => {
        Log.debug('app', 'Countdown: Unable to play alert sound.\nBrowser requires a user gesture to start audio playback', 'DBG_UNABLEPLAYSOUND', null, true)
      }).catch(err => {
        Log.debug('app', 'Countdown: Alert sound playback error', 'DBG_UNABLEPLAYSOUND2', err, true)
      })
    },

    async getStaleData () {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData && staleData.length) {
          if (!this.allEvents.length) {
            this.allEvents = staleData
          }
          this.checkValidEvents()
        }
      }
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }

}
</script>

<template lang="pug">
section.countdown-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide, 'is-thin': isHighAndThin}]" :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .countdown-wrapper.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]" :style="{...boxMarginStyle, ...countdownWrapperStyle}")
    .countdown-time-section.app-context-section.secondary.border-bottom(:class="{'bg-pulse': showPulse}")
      .time-left
        //- For `Now` (stopAtZero) and 'Today'
        template(v-if="countdownValue") {{ countdownValue }}
        //- Normal countdown
        template(v-else)
          .day-block(v-if="showCountdownDay && !showDayCombinedWithTime") {{ daysLeft }}{{ $t('_common.time.dayInShort') }}

          .time-block-wrapper(v-if="showCountdownTime")
            .day-block(v-if="showCountdownDay && showDayCombinedWithTime") {{ fillZero(daysLeft) }}
            .colon(v-if="showCountdownDay && showDayCombinedWithTime" :class="{dimmed: dimHour}") :
            .time-block.hour(:class="{dimmed: dimHour}") {{ fillZero(hoursLeft) }}
            .colon(:class="{dimmed: dimHour}") :
            .time-block.minute(:class="{dimmed: dimMinute}") {{ fillZero(minutesLeft) }}
            .colon(:class="{dimmed: dimMinute}") :
            .time-block.second(:class="{dimmed: dimSecond}") {{ fillZero(secondsLeft) }}
            .is-past-has-des(v-if="showPastText && showDescription") {{ $t('_common.time.timeAgo') }}
      .is-past(v-if="showPastText && !showDescription") {{ $t('_common.time.timeAgo') }}
    .main-section.app-context-section.primary(:class="{'bg-pulse': showPulse}")
      .event-title(:class="{'has-des': showDescription}") {{ eventTitle }}
      .event-time(:class="{'has-des': showDescription}") {{ eventDisplayTime }}
      .event-description(v-if="showDescription") {{ activeEvent.description }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.countdown-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .countdown-wrapper
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    .countdown-time-section
      flex: 1 1 0.00001px
      padding: 1em
      display: flex
      flex-flow: column nowrap
      justify-content: center
      align-items: center
      text-align: center

      .time-left
        font-size: 3em
        line-height: 120%
        font-weight: bold
        display: flex
        flex-flow: row wrap
        justify-content: center
        align-items: center
        text-align: center

        .day-block,
        .time-block-wrapper,
        .time-block
          display: inline-block
          white-space: nowrap

        .day-block
          margin-right: 0.5em

        .time-block-wrapper
          display: flex
          flex-flow: row nowrap
          justify-content: flex-start
          align-items: center
          text-align: left

          .day-block
            margin: 0
            min-width: 1.4em
            text-align: center

        .time-block
          // Prevent width changes when ticking
          min-width: 1.4em
          text-align: center

          &.dimmed
            opacity: 0.5

        .colon
          padding: 0 0.05em
          &.dimmed
            opacity: 0.5

        .is-past-has-des
          font-weight: normal
          opacity: 0.7
          font-size: 0.5em
          margin-left: 0.2em
          line-height: 100%

      .is-past
        font-weight: normal
        opacity: 0.7
        font-size: 1.5em
        line-height: 100%

    .main-section
      padding: 0.8em 1em
      text-align: center
      overflow: hidden

      .event-title
        font-weight: bold
        font-size: 1.5em
        line-height: 120%
        &.has-des
          font-size: 1.3em

      .event-time
        opacity: 0.7
        margin-top: 0.5em
        line-height: 120%
        &.has-des
          margin-top: 0

      .event-description
        font-size: 0.7em
        opacity: 0.7
        word-break: break-all

  //
  // Portrait Layout
  //
  &.is-portrait
    .countdown-wrapper
      .countdown-time-section
        padding: 1em 1.3em

        .day-block
          display: block
          width: 100%
          margin: 0 0 0.5em 0
    // For thin layout
    &.is-thin
      .time-left
        .time-block-wrapper
          flex-direction: column

  //
  // Portrait Layout
  //
  &.is-wide
    .countdown-wrapper
      .countdown-time-section
      .main-section
        font-size: 1.2em
        padding-top: 0
</style>
