<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import ClockItem from './clock/Item'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // Width / Height ratio. Switch to `wide` layout when threshold reached
  IS_WIDE: 2.5,

  // Width / Height ratio. Switch to `x-wide` layout when threshold reached
  IS_X_WIDE: 4,

  // Width / Height ratio. Switch to `tall` layout when threshold reached
  IS_TALL: 0.4,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 1000
}

export default {
  name: 'ClockPage',

  components: { ClockItem },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      cities: [],
      interval: 10,
      show24hr: false,
      transition: '',

      hAlign: 'left',
      vAlign: 'justify',

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      currentIndex: -1,

      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'pageItemTransition'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE
      }
      return false
    },

    isXwide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_X_WIDE
      }
      return false
    },

    isTall () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h < config.IS_TALL
      }
      return false
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    validItems () {
      return (this.cities || []).filter(item => (item.name && (item.useDeviceClock || item.timezone)))
    },

    url () {
      return ((this.item && (this.item.url || this.item.location)) || '')
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    url: {
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
  },

  methods: {
    render () {
      if (!this.url) { return }

      const options = qs.parse(decodeURIComponent(this.url))

      this.cities = options.cities || []

      this.interval = Math.max(4, options.interval || 10)
      this.show24hr = (options.show24hr === true || options.show24hr === 'true')
      this.transition = options.transition || ''

      this.hAlign = options.hAlign || 'left'
      this.vAlign = options.vAlign || 'justify'

      this.prepareNext(true)
    },

    prepareNext (forceReset) {
      clearTimeout(this.showNextTimer)

      if (!this.validItems || !this.validItems.length) { return }

      if (forceReset) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.validItems.length
      const nextItem = JSON.parse(JSON.stringify(this.validItems[nextIndex]))

      if (this.validItems.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      // Is first init
      if (this.currentIndex < 0) {
        this.showNext(nextIndex)
      } else if (this.validItems.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext (nextIndex) {
      clearTimeout(this.prepareTimer)
      const activeIndex = +this.currentIndex

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (!this.validItems.length) {
        return
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.validItems.length && activeIndex >= this.validItems.length - 1) {
        this.$emit('finished')
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, (this.interval || 10) * 1000 - config.PREPARE_TIME)
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    }
  }
}
</script>

<template lang="pug">
section.clock-item(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait }]"
                   :style="[fontSizeStyle, zonePaddingsStyle, scaledFontSizeStyle]")
  .resize-sensor(ref="sensor")

  .clock-detail-container.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize || !validItems.length, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
                                            :style="boxMarginStyle")
    //- Prime
    transition(:name="transitionName", :duration="500" appear)
      clock-item.prime(v-show="showPrime"
        :item="primeItem"
        :show24hr="show24hr"
        :is-portrait="isPortrait"
        :is-tall="isTall"
        :is-wide="isWide"
        :is-x-wide="isXwide"
        :horz-class="horzClass"
        :vert-class="vertClass"
      )

    //- Base
    transition(:name="transitionName", :duration="500" appear)
      clock-item.base(v-show="!showPrime"
        :item="baseItem"
        :show24hr="show24hr"
        :is-portrait="isPortrait"
        :is-tall="isTall"
        :is-wide="isWide"
        :is-x-wide="isXwide"
        :horz-class="horzClass"
        :vert-class="vertClass"
      )
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.clock-item
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  .clock-detail-container
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    z-index: 0
  
  .clock-inner-wrapper
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
