<script>
import MetricsMixins from './MetricsMixins.vue'
import MetricsDataMixins from './MetricsDataMixins.vue'

import NumberItem from './NumberItem.vue'

import { mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import NumberHelper from 'services/number-helper.js'
import { EventBus } from 'services/eventbus.js'

const config = {
  // In seconds. The interval check if any metric data is outdated
  REFRESH_INTERVAL: 60
}

export default {
  name: 'MetricsNumber',
  components: { NumberItem },

  mixins: [MetricsMixins, MetricsDataMixins],

  props: {
    lightTheme: { type: Boolean, default: false },
    fontOutline: { type: Boolean, default: false },
    unitFontScale: { type: Number, default: 1.0 },
    vAlign: { type: String },
    hAlign: { type: String }
  },

  data () {
    return {
      data: [],
      itemsData: [],
      itemsComparisons: [],

      metricKeysList: [],
      metricRefreshQueue: [],
      itemsMetricKeysMap: [],

      metricRefreshTimer: undefined,
      firstRefreshTimer: undefined,
      refreshDelayTimer: undefined
    }
  },

  computed: {
    ...mapGetters({
      queriedValue: 'getQueriedValue',
      appWindowIsHidden: 'appWindowIsHidden'
    })
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    clearTimeout(this.refreshDelayTimer)

    EventBus.$on('ws-query-metrics', this.updateQueryData)
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)

    clearInterval(this.metricRefreshTimer)

    this.renderInitData()

    // Make sure next checking is around '0' point (with some random delay)
    clearTimeout(this.firstRefreshTimer)
    this.firstRefreshTimer = setTimeout(() => {
      clearTimeout(this.firstRefreshTimer)
      this.checkMetricValueFreshness()
      // Start interval checking
      clearInterval(this.metricRefreshTimer)
      this.metricRefreshTimer = setInterval(() => {
        this.checkMetricValueFreshness()
      }, config.REFRESH_INTERVAL * 1000)
    }, (60 - new Date().getSeconds()) * 1000 + ~~(Math.random() * 1000))
  },

  beforeDestroy () {
    EventBus.$off('ws-query-metrics', this.updateQueryData)
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
    clearInterval(this.metricRefreshTimer)
    clearTimeout(this.firstRefreshTimer)
    clearTimeout(this.refreshDelayTimer)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        return
      }

      const refsMap = []
      this.validItems.forEach((item, itemIdx) => {
        const metric = item.metric

        if (!refsMap[itemIdx]) {
          refsMap[itemIdx] = {}
        }

        if (metric && metric.ref && metric.ref.length) {
          refsMap[itemIdx].ref = metric.ref
        }

        // - Current Value
        if (metric.range_type === 'current') {
          // Watch metric comparisons
          //
          // -- Comparisons SET
          if (item.metric_comparisons) {
            const compareKeys = []
            const metricComparisons = item.metric_comparisons
            const compareMetric = Object.assign({}, metric)
            compareMetric.range_type = 'avg'

            // [A] Over last %bucket startsWith 'common_'
            if (metricComparisons.startsWith('common_')) {
              compareMetric.range_string = metricComparisons
            // [B] Previous X %bucket (series)
            } else if (metricComparisons.startsWith('series_')) {
              compareMetric.series = true
              // To separate series queries
              compareMetric.previous = true
              const parts = metricComparisons.split('_')
              if (parts.length < 3) {
                return
              }
              const comparisonRange = parseFloat(parts[1])
              compareMetric.date_range = comparisonRange
              compareMetric.range_string = ''
            // [C] 'Day to Day', 'Hour to Hour' ...
            // - `compare_24_day+to+day`, `compare_60_hour+to+hour`
            } else if (metricComparisons.startsWith('compare_') && metricComparisons.includes('+to+')) {
              compareMetric.series = true
              // To separate series queries
              compareMetric.compare = true
              const parts = metricComparisons.split('_')
              if (parts.length < 3) {
                return
              }
              const comparisonRange = parts[1]
              const compareMetric2 = Object.assign({}, compareMetric)
              compareMetric.range_string = `common_last_${comparisonRange}`
              compareMetric2.range_string = `common_this_${comparisonRange}`
              // Add extra metric query
              const qKey = Metrics.buildQueryKey(compareMetric2)
              if (qKey) {
                compareKeys.push(qKey)
                if (!this.metricKeysList.includes(qKey)) {
                  this.metricKeysList.push(qKey)
                }
              }
            } else {
              return
            }

            const qKey = Metrics.buildQueryKey(compareMetric)
            if (qKey) {
              compareKeys.push(qKey)
              if (!this.metricKeysList.includes(qKey)) {
                this.metricKeysList.push(qKey)
              }
            }

            refsMap[itemIdx].query = compareKeys

            const updateInfo = {
              range: metricComparisons,
              keys: compareKeys,
              ts: new Date().getTime()
            }
            this.$set(this.itemsComparisons, itemIdx, updateInfo)

          // -- Comparisons NOT set
          } else {
            this.removeItemComparison(itemIdx)
          }
        // - Aggregated value
        } else if (metric.bucket_size && metric.bucket_size > 0) {
          if (metric.range_type !== 'max_all' && metric.range_type !== 'min_all') {
            const qKey = Metrics.buildQueryKey(metric)
            if (qKey && !this.metricKeysList.includes(qKey)) {
              this.metricKeysList.push(qKey)
            }
            refsMap[itemIdx].query = [qKey]

            this.removeItemComparison(itemIdx)
          }
        }
      })

      this.itemsMetricKeysMap = refsMap
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateQueryData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.query) { return }
        for (let i = 0; i < item.query.length; i++) {
          const key = item.query[i]
          if (m.includes(key)) {
            this.updateItemData(idx, true)
            break
          }
        }
      })
    },

    updateItemData (idx, updateComparison) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[idx]
      if (!item) { return }

      const metric = item.metric || {}

      const rounding = item.rounding || 0
      const abbreviate = item.abbreviate
      const valueType = item.value_type

      const nextUpdateTime = Metrics.nextUpdateTime(metric)
      let labelText = Metrics.subtitleText(metric)

      const targetData = (this.data && this.data[idx]) || {}

      if (metric.range_type !== 'current' && metric.bucket_size > 0) {
        if (metric.range_type !== 'max_all' && metric.range_type !== 'min_all') {
          const qKey = this.itemsMetricKeysMap[idx].query[0]
          if (qKey) {
            const queriedValue = this.queriedValue(qKey)
            if (typeof queriedValue !== 'undefined') {
              targetData[metric.range_type] = queriedValue
            }
          }
        }
      }

      const valueIsFresh = Metrics.isFreshValue(targetData)

      if (nextUpdateTime) {
        this.$set(this.metricRefreshQueue, idx, {
          ts: nextUpdateTime,
          bucket_size: metric.bucket_size,
          ref: metric.ref
        })
      }

      // - Current Value
      if (metric.range_type === 'current') {
        let validValue
        if (valueIsFresh) {
          validValue = targetData.value
        } else if (NumberHelper.isNumber(targetData.value)) {
          validValue = 0
        }
        this.$set(this.itemsData, idx, {
          number: { value: validValue, rounding, abbreviate, value_type: valueType },
          label: { text: labelText }
        })

        // Watch metric comparisons
        //
        // -- Comparisons SET
        if (item.metric_comparisons) {
          const metricComparisons = item.metric_comparisons
          const isCompare = metricComparisons.startsWith('compare_') && metricComparisons.includes('+to+')

          if (!metricComparisons.startsWith('common_') && !metricComparisons.startsWith('series_') && !metricComparisons.startsWith('compare_')) {
            return
          }

          // Help reducing sparkline (item comparison) redraw time
          // Items will watch `ts` changes to update sparkline
          if ((updateComparison || isCompare) && this.itemsComparisons[idx]) {
            this.$set(this.itemsComparisons[idx], 'ts', new Date().getTime())
          }
        }
        return
      // - Aggregated value
      } else {
        let operation = metric.range_type
        if (operation) {
          if (operation === 'avg' || operation === 'average') {
            this.$set(this.itemsData, idx, {
              number: { value: valueIsFresh ? (targetData.sum / targetData.count) : 0, rounding, abbreviate, value_type: valueType },
              label: { text: labelText }
            })
            return
          } else {
            if (metric.bucket_size > 0) {
              if (operation === 'max' || operation === 'min') {
                labelText = `previous ${Metrics.bucketSizeLabel(metric.bucket_size)} ${operation}`
              }
              if (operation === 'max_all' || operation === 'min_all') {
                operation = operation.split('_')[0]
                labelText = `all-time ${operation}`
              }
            }
            this.$set(this.itemsData, idx, {
              number: { value: valueIsFresh ? (targetData[operation] || 0) : 0, rounding, abbreviate, value_type: valueType },
              label: { text: labelText }
            })
            return
          }
        }
      }

      this.$set(this.itemsData, idx, {})
    },

    checkMetricValueFreshness () {
      if (this.appWindowIsHidden) { return }

      const now = ~~(new Date().getTime() / 1000)
      const list = this.metricRefreshQueue.filter(item => item.ts <= now)
      const keys = []
      const refs = []
      let hasMinuteKeys = false

      if (list && list.length) {
        list.forEach(item => {
          if (item.bucket_size <= 60) {
            hasMinuteKeys = true
          }
          if (item.key) {
            keys.push(item.key)
          } else if (item.ref) {
            refs.push(item.ref)
          }
        })
        if (refs.length) {
          this.updateInvolvedItems(refs)
        }
      }

      // Manually query for metric comparisons (this.metricKeysList)
      const allKeys = [].concat(JSON.parse(JSON.stringify(this.metricKeysList || [])), keys)
      if (allKeys.length) {
        // Add random delay timer to prevent overload the service at each zero points
        clearTimeout(this.refreshDelayTimer)
        this.refreshDelayTimer = setTimeout(() => {
          clearTimeout(this.refreshDelayTimer)
          EventBus.$emit('refresh-metric-query', allKeys)
        }, this.debounceDelta(hasMinuteKeys))
      }
    },

    comparisons (index) {
      return this.itemsComparisons[index] || {}
    },

    removeItemComparison (index) {
      this.$set(this.itemsComparisons, index, null)
    },

    debounceDelta (hasMinuteKeys) {
      return ~~((hasMinuteKeys ? Math.random() * 2 : 2 + 8 * Math.random()) * 1000)
    }
  }
}
</script>

<template lang="pug">
section.metrics-number
  transition(:name="transitionName", :duration="500" appear)
    number-item.prime(v-if="showPrime && primeItem"
               :title="itemTitle(primeItem)"
               :remove-title="removeTitle"
               :item="itemData(primeIndex)"
               :comparisons="comparisons(primeIndex)"
               :metric="primeItem.metric"
               :color="primeItem.color"
               :chart-color="primeItem.chart_color"
               :unit-color="primeItem.unit_color"
               :is-paginated="needsPaginate"
               :font-outline="fontOutline"
               :unit-font-scale="unitFontScale"
               :v-align="vAlign"
               :h-align="hAlign"
               :light-theme="lightTheme")

  transition(:name="transitionName", :duration="500" appear)
    number-item.base(v-if="!showPrime && baseItem"
               :title="itemTitle(baseItem)"
               :remove-title="removeTitle"
               :item="itemData(baseIndex)"
               :comparisons="comparisons(baseIndex)"
               :metric="baseItem.metric"
               :color="baseItem.color"
               :chart-color="baseItem.chart_color"
               :unit-color="baseItem.unit_color"
               :is-paginated="needsPaginate"
               :font-outline="fontOutline"
               :unit-font-scale="unitFontScale"
               :v-align="vAlign"
               :h-align="hAlign"
               :light-theme="lightTheme")
</template>

<style lang="stylus">
section.metrics-number
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-number-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
