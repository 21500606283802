<script>
import Slack from 'services/slack'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'SlackThreadRoot',

  props: {
    root: { type: Object, required: true }
  },

  data () {
    return {
      rootItem: {},
      threadKey: undefined
    }
  },

  computed: {
    text () {
      if (this.rootItem.text?.length) {
        return Slack.formatMessage(this.rootItem.text)
      }
      if (this.rootItem.attachments?.length) {
        let text = ''
        this.rootItem.attachments.forEach((a, index) => {
          if (index > 0) { text += '<br>' }
          if (a.pretext) {
            text += Slack.formatMessage(a.pretext) + '<br>'
          }
          text += Slack.formatMessage(a.text || a.title)
        })
        return text
      }
      return ''
    }
  },

  created () {
    EventBus.$on('slack-thread-updated', this.update)
  },

  mounted () {
    this.rootItem = JSON.parse(JSON.stringify(this.root))
    this.threadKey = this.rootItem.thread_ts
  },

  beforeDestroy () {
    EventBus.$off('slack-thread-updated', this.update)
  },

  methods: {
    update (m) {
      if (m.thread_ts === this.threadKey) {
        this.rootItem = m
      }
    }
  }
}
</script>

<template lang="pug">
.slack-thread-root
  .thread-body
    .hint Replied to a thread:
    .main-thread(v-html="text")
</template>

<style lang="stylus">
// Threads (Under child items)
.slack-thread-root {
  padding: 0.1em 0 1em 0;
  overflow: hidden;

  .thread-body {
    font-size: 1.2em;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .hint {
    padding-bottom: 0.8em;
  }

  .main-thread {
    color: var(--gray-dark);
    white-space: pre-wrap;
  }

  &.reacted-only {
    .thread-body {
      font-size: 2em;
    }

    .replies-count {
      font-size: 2em;
    }

    .main-thread {
      color: #fff;
    }
  }
}
</style>
