<script>
import ShapeSquare from './ShapeSquare'
import ShapeRectangle from './ShapeRectangle'
import ShapeCircle from './ShapeCircle'
import ShapeOval from './ShapeOval'
import ShapeTriangle from './ShapeTriangle'
import { positionDefault, shapesDefault } from 'services/free-form'

export default {
  name: 'ObjectShape',

  components: {
    ShapeSquare,
    ShapeRectangle,
    ShapeCircle,
    ShapeOval,
    ShapeTriangle
  },

  props: {
    type: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: shapesDefault.width,
      validator: value => value >= 0 && value <= 100
    },
    height: {
      type: Number,
      default: shapesDefault.height,
      validator: value => value >= 0 && value <= 100
    },
    size: {
      type: Number,
      default: shapesDefault.size,
      validator: value => value >= 0 && value <= 100
    },
    rotate: {
      type: Number,
      default: positionDefault.rotate,
      validator: value => value >= 0 && value <= 360
    },
    rounded: {
      type: Number,
      default: shapesDefault.rounded,
      validator: value => value >= 0 && value <= 100
    },
    opacity: {
      type: Number,
      default: shapesDefault.opacity,
      validator: value => value >= 0 && value <= 1
    },
    x: {
      type: Number,
      default: positionDefault.x,
      validator: value => value >= 0 && value <= 100
    },
    y: {
      type: Number,
      default: positionDefault.y,
      validator: value => value >= 0 && value <= 100
    },
    strokeWidth: {
      type: Number,
      default: shapesDefault.strokeWidth,
      validator: value => value >= 0
    },
    strokeColor: {
      type: String,
      default: shapesDefault.strokeColor
    },
    fillColor: {
      type: String,
      default: shapesDefault.fillColor
    }
  },

  computed: {
    shortType () {
      return this.type?.replace?.('object-shape-', '') || ''
    },

    opacityStyle () {
      return {
        opacity: this.opacity
      }
    }
  }
}
</script>

<template lang="pug">
.object-shape-item(:style="[opacityStyle]")
  shape-square(v-if="shortType === 'square'" :size="size" :rounded="rounded" :stroke-width="strokeWidth" :stroke-color="strokeColor" :fill-color="fillColor")
  shape-rectangle(v-if="shortType === 'rectangle'" :width="width" :height="height" :rounded="rounded" :stroke-width="strokeWidth" :stroke-color="strokeColor" :fill-color="fillColor")
  shape-circle(v-if="shortType === 'circle'" :size="size" :stroke-width="strokeWidth" :stroke-color="strokeColor" :fill-color="fillColor")
  shape-oval(v-if="shortType === 'oval'" :width="width" :height="height" :stroke-width="strokeWidth" :stroke-color="strokeColor" :fill-color="fillColor")
  shape-triangle(v-if="shortType === 'triangle'" :width="width" :height="height" :stroke-width="strokeWidth" :stroke-color="strokeColor" :fill-color="fillColor")
</template>

<style lang="stylus">
.object-shape-item
  position: absolute
  z-index: 0
  width: 100%
  height: 100%
  top: 0
  left: 0
</style>
