<script>
import TitanMenu from 'services/titan-menu.js'
import Env from 'services/environment'

export default {
  name: 'AllergenIcon',
  props: {
    item: {
      type: String,
      default: ''
    }
  },

  computed: {
    fontClass () {
      if (!this.item) { return }
      return TitanMenu.getAllergieIcon(this.item.toLowerCase())
    },

    isDevelopment () {
      return Env.isDevelopment()
    },

    abbr () {
      if (this.fontClass && this.fontClass.length) { return }
      const parts = (this.item || '').split(/\s/)
      if (!parts || !parts.length) { return }
      if (parts.length === 1) {
        return parts[0].substr(0, 1) + parts[0].substr(-1)
      }
      return parts.map(p => {
        return p.substr(0, 1).toUpperCase()
      }).join('')
    }
  }
}
</script>

<template lang="pug">
.allergen-icon(:class="[fontClass, {'no-icon': abbr && abbr.length, 'qa-src': isDevelopment}]")
  template(v-if="!fontClass || !fontClass.length") {{ abbr }}
</template>

<style lang="stylus">
.allergen-icon
  display: inline-block
  font-weight: 300
  font-style: normal
  
  &.no-icon
    min-width: 1.5em
    font-size: 0.8em
    text-align: center
    text-transform: capitalised
</style>
