<script>
// DEV-4572, DEV-1761
// Overlay Container for displaying target contents from Navigation (previously called "Button") app

import {
  mapState,
  mapGetters
} from 'vuex'

import Spinner from 'components/common/Spinner.vue'
import Item from 'components/items/Item.vue'
import MediaItem from 'components/common/MediaItem.vue'
import InteractiveGridList from 'components/player/InteractiveGridList.vue'
import WebApp from 'components/items/WebApp.vue'

import Pages from 'services/pages'

// In ms
const DELAY_TIME = 500

export default {
  name: 'OverlayContainer',

  components: {
    Spinner,
    Item,
    MediaItem,
    InteractiveGridList,
    WebApp
  },

  data () {
    return {
      showContainer: false,
      waitingForFolderItem: false,
      waitingMediaTarget: false,
      mediaTargetIsPaused: false,

      autoCloseTimer: undefined,
      errorTimer: undefined,
      delayTimer: undefined
    }
  },

  computed: {
    ...mapState({
      loading: s => s.interactions.loadingContent,
      targetId: s => s.interactions.targetId,
      targetType: s => s.interactions.targetType,
      contentType: s => s.interactions.contentType,
      contentUrl: s => s.interactions.contentUrl,
      contentData: s => s.interactions.contentData,
      contentLabel: s => s.interactions.contentLabel,
      overlayMargin: s => s.interactions.overlayMargin,
      folderOptions: s => s.interactions.folderOptions,
      timeout: s => s.interactions.timeout,
      errMessage: s => s.interactions.errMessage,
      paused: s => s.playbackmode.pauseMode,
      isPausedByBtnClick: s => s.interactions.pausedByBtnClick
    }),

    ...mapGetters([
      'isViewingItem',
      'viewingItemIsPaused',
      'waitForFolderItem'
    ]),

    overlayMarginStyle () {
      if (!this.overlayMargin) { return }
      return {
        margin: this.overlayMargin
      }
    },

    appItem () {
      if (!this.targetType || this.targetType !== 'app') { return }
      return {
        url: this.contentUrl,
        type: this.contentType
      }
    },

    mediaItem () {
      if (!this.targetType || this.targetType !== 'media') { return }
      return this.contentData || {}
    },

    folderItem () {
      if (!this.targetType || this.targetType !== 'folder') { return }
      return {
        id: this.targetId,
        url: this.contentUrl,
        name: this.contentLabel
      }
    },

    webappItem () {
      if (!this.targetType || this.targetType !== 'webapp') { return }
      return this.contentData || {}
    },

    hasTimeout () {
      return this.timeout && this.timeout > 0
    },

    hasError () {
      return Boolean(!this.loading && this.errMessage && this.errMessage.length)
    }
  },

  watch: {
    hasError (isTrue) {
      if (isTrue) {
        // Auto close overlay after 3s on error
        clearTimeout(this.errorTimer)
        this.errorTimer = setTimeout(() => {
          clearTimeout(this.errorTimer)
          this.closeOverlay()
        }, 3000)
      }
    },

    // Fixes vuex data racing
    hasTimeout () {
      this.resetTimer()
    },

    waitForFolderItem (isTrue) {
      if (!isTrue && this.waitingForFolderItem) {
        this.closeOverlay()
        this.waitingForFolderItem = false
      }
    }
  },

  mounted () {
    clearTimeout(this.errorTimer)
    clearTimeout(this.delayTimer)
    this.resetTimer()
    document.addEventListener('touchend', this.touchEventHandler)
    document.addEventListener('mouseup', this.touchEventHandler)

    // Delay inner content display to avoid flickering of size changing by "overlayMargin"
    this.delayTimer = setTimeout(() => {
      clearTimeout(this.delayTimer)
      this.showContainer = true
    }, +DELAY_TIME)
  },

  beforeDestroy () {
    clearTimeout(this.autoCloseTimer)
    clearTimeout(this.errorTimer)
    clearTimeout(this.delayTimer)
    document.removeEventListener('touchend', this.touchEventHandler)
    document.removeEventListener('mouseup', this.touchEventHandler)
  },

  methods: {
    closeOverlay () {
      clearTimeout(this.autoCloseTimer)
      Pages.sendKioskReport('kiosk_user_closed', {name: this.contentLabel})
      // Resume Playlist playback if it's currently paused by interaction button click
      if (this.isPausedByBtnClick && this.paused) {
        this.$emit('resume-playback')
      }

      if (this.isViewingItem) {
        this.$store.commit('clearViewingItem')
      }

      this.waitingMediaTarget = false
      this.mediaTargetIsPaused = false

      this.$store.dispatch('hideOverlayContent')
    },

    resetTimer () {
      clearTimeout(this.autoCloseTimer)
      if (this.hasTimeout) {
        this.autoCloseTimer = setTimeout(() => {
          clearTimeout(this.autoCloseTimer)
          // Wait for ongoing video/slides to finish before closing overlay
          // Continue to close the overlay if the video is in paused state (and not interactive after timeout)
          if (this.waitForFolderItem && !this.viewingItemIsPaused) {
            this.waitingForFolderItem = true
            return
          }
          if (this.waitingMediaTarget && !this.mediaTargetIsPaused) {
            return
          }
          this.closeOverlay()
        }, this.timeout * 1000)
      }
    },

    touchEventHandler () {
      this.resetTimer()
    },

    onLoaded (mediaType) {
      if (this.targetType === 'media' && (mediaType === 'video' || mediaType === 'slides')) {
        this.waitingMediaTarget = true
      } else {
        this.waitingMediaTarget = false
        this.mediaTargetIsPaused = false
      }
      Pages.sendKioskReport('kiosk_user_viewed', {name: this.contentLabel})
    },

    togglePauseState (toTrue = false) {
      this.mediaTargetIsPaused = toTrue
    },

    onFinished () {
      if (this.hasTimeout && !this.waitingMediaTarget) { return }
      // When Timeout not specified, close overlay container when the containing content reaches end-of-conent
      this.closeOverlay()
    }
  }
}
</script>

<template lang="pug">
section.ttv-overlay-container
  //- In regular cases. Click to close the entire overlay container
  //- When viewing media file in "folder" mode, hide the close button
  .close-button(v-if="!isViewingItem" @click="closeOverlay")
    fa.fa-icon(icon="times-circle")

  .overlay-inner(v-show="showContainer" :style="overlayMarginStyle")
    .loading-mask(v-if="loading")
      spinner(size="8em")

    .error-message(v-else-if="hasError")
      h4.msg-title Unable to get target content
      p.msg-text {{ errMessage }}

    template(v-else)
      item(v-if="targetType === 'app'", :item="appItem" active overlay-mode @loaded="onLoaded" @finished="onFinished")
      media-item(v-else-if="targetType === 'media'", :item="mediaItem" show-controls @loaded="onLoaded" @finished="onFinished" @is-paused="togglePauseState")
      interactive-grid-list(v-else-if="targetType === 'folder'" :folder="folderItem" :folder-options="folderOptions" kiosk-navigation-mode @loaded="onLoaded")
      web-app(v-else-if="targetType === 'webapp'", :webapp="webappItem" active use-webapp-prop @loaded="onLoaded" @finished="onFinished")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.ttv-overlay-container
  // Use "absolute" to make sure the interactive menu won't clip this overlay container
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 5500
  background: transparent
  animation-duration: 300ms
  pointer-events: none

  > .close-button
    position: absolute
    top: 1.2vmin
    right: 1.2vmin
    font-size: 4vmin
    cursor: pointer
    z-index: 10
    outline: 0
    pointer-events: initial !important

    &:active
      opacity: 0.7
      outline: 0
      -webkit-tap-highlight-color: transparent

    .fa-icon
      color: #fff
      appIconShadow()

  .overlay-inner
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 0
    background: #000
    pointer-events: initial

    > .page-item,
    .error-message,
    .loading-mask
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0

    .error-message,
    .loading-mask
      display: flex
      flex-flow: column nowrap
      justify-content: center
      align-items: center
      text-align: center

    .error-message
      color: #333
      .msg-title
        font-size: 2em
      .msg-text
        color: -black(0.7)
        font-size: 1.5em
</style>
