<script>
import Bowser from 'bowser'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  select as Select, max as Max, min as Min, sum as Sum,
  scaleLinear as ScaleLinear, axisBottom as AxisBottom, scaleIdentity as ScaleIdentity, quantile as Quantile,
  line as Line, area as Area, curveCatmullRom as CurveCatmullRom
} from 'd3'
import { mapGetters } from 'vuex'

const config = {
  DEFAULT_RENDERER: 'line',

  DEFAULT_COLOR: '#fff',
  LIGHT_THEME_COLOR: '#000',

  GRAPH_HORZ_PADDING: 2,
  GRAPH_VERT_PADDING: 2,

  // for line graph
  LINE_TICKNESS: 2,
  // for line graph, if distance between 2 dots is too close, we don't display the dots
  MIN_DISTANCE_BETWEEN_DOTS: 10,

  // for area graph
  AREA_OPACITY: 0.4,

  // the dot of the scatterplot or line graph
  DOT_CIRCLE_RADIUS: 3,

  // for bar
  BAR_MARGIN: 2,

  BASE_FONT_SIZE: 20,

  // padding between number & tick
  TICK_PADDING: 2,

  // size of ticks
  TICK_SIZE: 0,

  // portion refers to (total x_labels' width / xAxis' width)
  PORTION_TO_SKIP_XLABELS: 0.8,

  DISTANCE_BETWEEN_X_TICK_AND_GRAPH: 3,

  // In em
  SHADOW_OFFSET: 0.06,
  SHADOW_BLUR: 0.13
}

export default {
  name: 'SparklineChart',

  props: {
    // For single line
    values: { type: Array, default () { return [] } },
    // For multiple lines
    series: { type: Array },

    renderer: { type: String },
    color: { type: [String, Array] },

    // Only works with `renderer: bar`
    xLabels: { type: Array },

    // Only triggers when `baseline: zero`
    baseline: { type: String },

    // App Background Theme
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      seriesLength: 0,
      delayTimer: undefined,
      renderDebouncer: undefined
    }
  },

  computed: {
    ...mapGetters({
      winSizeRatio: 'winSizeRatio'
    }),

    browserInfo () {
      const bs = Bowser.getParser(window.navigator.userAgent)
      return bs.getBrowser() || {}
    },

    isIEorEdge () {
      const browserName = (this.browserInfo.name || '').toLowerCase()
      return browserName === 'edge' || browserName === 'internet explorer'
    },

    hasSeries () {
      return Boolean(this.series && this.series.length > 1)
    },

    shadowId () {
      return `sparkline-chart-shadow-${this._uid}`
    },

    showXlabels () {
      return Boolean(this.renderer === 'bar' && this.xLabels && this.xLabels.length)
    },

    xAxisFontSize () {
      return config.BASE_FONT_SIZE * this.winSizeRatio * 0.8
    },

    xAxisHeight () {
      return this.showXlabels ? this.xAxisFontSize * 1.2 + config.TICK_SIZE + config.TICK_PADDING : 0
    }
  },

  watch: {
    'values': {
      deep: true,
      handler (newValue) {
        if (this.hasSeries) { return }
        this.debounceRender(200, newValue)
      }
    },
    'series': {
      deep: true,
      handler (newValue) {
        if (this.hasSeries) {
          this.debounceRender(200, newValue)
        }
      }
    },
    renderer () {
      this.debounceRender()
    },
    color () {
      this.debounceRender()
    },
    hasSeries (newValue) {
      this.debounceRender(300)
    },
    winSizeRatio () {
      this.debounceRender()
    },
    lightTheme () {
      this.debounceRender()
    }
  },

  mounted () {
    clearTimeout(this.delayTimer)
    clearTimeout(this.renderDebouncer)

    AddResizeListener(this.$refs.sensor, this.debounceRender)

    this.debounceRender()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceRender)
    }
    clearTimeout(this.delayTimer)
    clearTimeout(this.renderDebouncer)
  },

  methods: {
    render (data) {
      data = JSON.parse(JSON.stringify(data || this.series || this.values || []))
      this.drawSparkLineChart(data)
    },

    debounceRender (timeout, data) {
      clearTimeout(this.renderDebouncer)
      this.renderDebouncer = setTimeout(() => {
        clearTimeout(this.renderDebouncer)
        this.render(data)
      }, timeout || 200)
    },

    drawSparkLineChart (values) {
      clearTimeout(this.delayTimer)

      if (!values || !(values instanceof Array)) { return }
      if (!this.hasSeries && values.length < 2) { return }

      if (!this.$el || !this.$refs || !this.$refs.chart) { return }

      const self = this
      const chart = this.$refs.chart
      const series = this.hasSeries ? values : [values]

      this.seriesLength = series.length

      let width
      let height

      const drawChart = function drawChart () {
        const renderer = self.renderer || config.DEFAULT_RENDERER

        let low = self.baseline === 'zero' ? 0 : Min(series.map((d) => Min(d)))
        let high = Max(series.map((d) => Max(d)))
        if (self.baseline === 'zero') {
          high = Math.max(0.5, high)
        }

        const extra = Math.abs((high - low) * 0.1)
        low = low === 0 ? 0 : low - extra
        high = high + extra

        const valuesLength = self.hasSeries ? Max(series, (d) => { return d.length }) : values.length

        let baseSvg = Select(chart).select('svg')
        if (baseSvg.empty()) {
          baseSvg = Select(chart).append('svg:svg')
          const defs = baseSvg.append('svg:defs')

          if (!self.isIEorEdge && !self.lightTheme) {
            const shadowOffset = config.BASE_FONT_SIZE * config.SHADOW_OFFSET * self.winSizeRatio
            defs.append('svg:filter').attr('id', self.shadowId)
              // Make sure shadows will not be clipped
              .attr('y', '-20%')
              .attr('x', '-20%')
              .attr('width', '140%')
              .attr('height', '140%')
              .append('feDropShadow')
              .attr('dx', shadowOffset)
              .attr('dy', shadowOffset)
              .attr('stdDeviation', config.BASE_FONT_SIZE * config.SHADOW_BLUR * self.winSizeRatio)
          }
        }
        baseSvg.merge(baseSvg)
          .attr('width', width).attr('height', height)
          .style('width', width + 'px').style('height', height + 'px')

        let xRange
        let barWidth

        if (config.GRAPH_HORZ_PADDING > width * 0.25) {
          xRange = ScaleLinear().range([0, width]).domain([0, valuesLength - 1])
        } else {
          xRange = ScaleLinear().range([config.GRAPH_HORZ_PADDING, width - config.GRAPH_HORZ_PADDING]).domain([0, valuesLength - 1])
        }

        if (renderer === 'bar') {
          barWidth = Math.max(1, (width - config.BAR_MARGIN * (valuesLength - 1)) / valuesLength)
          xRange.range([config.GRAPH_HORZ_PADDING + barWidth / 2, width - config.GRAPH_HORZ_PADDING - barWidth / 2])
        }

        const chartHeight = height - self.xAxisHeight

        let yRange
        if (config.GRAPH_VERT_PADDING > chartHeight * 0.25) {
          yRange = ScaleLinear().range([0, chartHeight]).domain([high, low])
        } else {
          yRange = ScaleLinear().range([config.GRAPH_VERT_PADDING, chartHeight - config.GRAPH_VERT_PADDING]).domain([high, low])
        }

        const xScale = function (d, i) {
          return xRange(i)
        }

        const yScale = function (d) {
          return yRange(d)
        }

        // Init xAxis for bar chart
        if (self.showXlabels) {
          const xDelta = barWidth / 2
          const rangeInfo = [
            config.GRAPH_HORZ_PADDING + xDelta,
            width - config.GRAPH_HORZ_PADDING - xDelta
          ]

          const xAxisScale = ScaleLinear()
            .domain([0, valuesLength - 1])
            .range(rangeInfo)

          const xAxis = AxisBottom()
            .scale(xAxisScale)
            .tickPadding(config.TICK_PADDING)
            .tickSizeInner(config.TICK_SIZE)
            .tickSizeOuter(1)
            .ticks(valuesLength)

          xAxis.tickFormat(i => {
            return self.xLabels[i]
          })

          let xAxisArea = baseSvg.select('g.x-axis-area')
          if (xAxisArea.empty()) {
            xAxisArea = baseSvg.append('svg:g').attr('class', 'x-axis-area')
          }

          const bottomLine = AxisBottom()
            .scale(ScaleIdentity().domain([config.GRAPH_HORZ_PADDING, width - config.GRAPH_HORZ_PADDING]))
            .ticks(0)
            .tickSizeInner(0)
            .tickSizeOuter(1)
          xAxisArea.append('svg:g').attr('class', 'bottom-line').call(bottomLine)

          xAxisArea.attr('transform', `translate(0, ${chartHeight + config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH})`).call(xAxis)
          xAxisArea.selectAll('g.bottom-line').remove()

          // To Calc total x_label width
          const textArray = []

          xAxisArea.selectAll('text')
            // Override D3 font size
            .style('font-size', self.xAxisFontSize)
            .each(function () {
              textArray.push(this.getBBox().width)
            })

          const xlabelsLen = textArray.length
          const calibrateWidth = (Quantile(textArray, 0.25) + Quantile(textArray, 0.5) + Quantile(textArray, 0.75)) / 3 * xlabelsLen
          const totalLabelWidth = Math.max(Sum(textArray), calibrateWidth)
          const availableWidth = ((width - 2 * config.GRAPH_HORZ_PADDING) / (xlabelsLen - 1))

          // Skip labels where necessary
          const portion = totalLabelWidth / width
          const skipStep = Math.ceil(totalLabelWidth / (xlabelsLen - 1) / availableWidth)

          xAxisArea.selectAll('text').each(function (elm, idx) {
            const text = this.textContent || ''
            const actualLength = text.length
            const actualWidth = this.getBBox().width

            // Calibrate available width
            const _availableWidth = availableWidth * skipStep

            // Skipping labels
            if (portion > config.PORTION_TO_SKIP_XLABELS && idx !== 0 && idx % skipStep !== 0) {
              this.textContent = ''

              // Display labels
            } else {
              if (actualWidth > _availableWidth) {
                let availableLength = Math.floor(actualLength * _availableWidth / actualWidth) - 2
                if ((idx === 0 || self.xLabels.length - 1 === idx) && availableLength > 10) {
                  availableLength = 10
                }
                // Add ellipsis or skip
                if (availableLength >= 2) {
                  this.textContent = text.substr(0, availableLength - 1) + '…'
                } else {
                  this.textContent = ''
                }
              }
            }
          })
        //
        // Remove xAxis when not set
        } else {
          baseSvg.select('g.x-axis-area').remove()
        }

        series.forEach((seriesValues, idx) => {
          const color = (Array.isArray(self.color) ? self.color[idx] : self.color) || (self.lightTheme ? config.LIGHT_THEME_COLOR : config.DEFAULT_COLOR)

          let svg = baseSvg.select(`g.graph.series-${idx}`)
          if (svg.empty()) {
            svg = baseSvg.append('svg:g').attr('class', `graph series-${idx}`)
          }

          // Add Object shadow [#APP-2953]
          if (idx === series.length - 1 && !self.isIEorEdge && !self.lightTheme) {
            svg.style('filter', `url(#${self.shadowId})`)
          } else {
            svg.style('filter', '')
          }

          // Draw an invisible rect to make sure shadow filter works when
          // value line becomes a plain horizontal line (with no height)
          // - https://stackoverflow.com/questions/39475396/svg-mask-makes-line-disappear
          let placeholder = svg.select('rect.placeholder')
          if (placeholder.empty()) {
            placeholder = svg.append('svg:rect').attr('class', 'placeholder')
              .attr('width', width - config.GRAPH_HORZ_PADDING * 2)
              .attr('height', 5)
              .attr('x', config.GRAPH_HORZ_PADDING)
              .attr('y', config.GRAPH_VERT_PADDING)
          }

          if (renderer === 'spline' || renderer === 'line') {
            let valueline
            if (renderer === 'spline') {
              valueline = Line().curve(CurveCatmullRom).x(xScale).y(yScale)
            } else {
              valueline = Line().x(xScale).y(yScale)
            }

            let path = svg.select(`path.renderer-${renderer}`)
            if (path.empty()) {
              path = svg.append('svg:path').attr('class', `renderer-${renderer}`)
            }

            seriesValues = self.fillZero(seriesValues)

            path.datum(seriesValues)
              .attr('stroke', color)
              .attr('stroke-width', config.LINE_TICKNESS)
              .attr('fill', 'none')
              .transition()
              .attr('d', valueline)

            // remove other renderer
            if (renderer === 'spline') {
              svg.selectAll('.renderer-line').remove()
            } else {
              svg.selectAll('.renderer-spline').remove()
            }
            svg.selectAll('.renderer-area').remove()
            svg.selectAll('.renderer-bar').remove()
          // AREA
          } else if (renderer === 'area') {
            const valueArea = Area().x(xScale).curve(CurveCatmullRom).y0(yScale(low)).y1(yScale)

            let path = svg.select('path.renderer-area')
            if (path.empty()) {
              path = svg.append('svg:path').attr('class', 'renderer-area')
            }
            path.datum(seriesValues)
              .attr('stroke', color)
              .attr('stroke-opacity', config.AREA_OPACITY)
              .attr('fill', color)
              .attr('fill-opacity', config.AREA_OPACITY)
              .transition()
              .attr('d', valueArea)

            // remove other renderer
            svg.selectAll('.renderer-line').remove()
            svg.selectAll('.renderer-spline').remove()
            svg.selectAll('.renderer-bar').remove()
          // BAR
          } else if (renderer === 'bar') {
            const line = svg.selectAll('line.renderer-bar').data(seriesValues)
            const enter = line.enter().append('svg:line').attr('class', 'renderer-bar')
              .attr('y1', chartHeight).attr('x1', width)
              .attr('x2', xScale).attr('y2', chartHeight)

            line.merge(enter)
              .attr('stroke', color)
              .attr('stroke-width', barWidth)
              .attr('fill', 'none')
              .transition()
              .attr('x2', xScale)
              .attr('y2', chartHeight)
              .attr('x1', xScale)
              .attr('y1', yScale)

            line.exit().remove()

            // remove other renderer
            svg.selectAll('.renderer-line').remove()
            svg.selectAll('.renderer-spline').remove()
            svg.selectAll('.renderer-area').remove()
          }
        })

        self.removeExtraSeries(baseSvg)
      }

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!chart) {
          FastDom.clear(measure)
          return
        }

        width = chart.offsetWidth
        height = chart.offsetHeight

        const mutate = FastDom.mutate(() => {
          if (!width || !height) {
            FastDom.clear(mutate)
            FastDom.clear(measure)
            return
          }

          drawChart()

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    removeExtraSeries (baseSvg) {
      const currentLength = this.seriesLength
      const oldLength = baseSvg.selectAll('.graph').size()
      for (let i = currentLength; i < oldLength; i++) {
        baseSvg.selectAll(`.series-${i + 1}`).remove()
      }
    },

    fillZero (arrayData) {
      const values = JSON.parse(JSON.stringify(arrayData || []))
      if (!values.length) {
        return values
      }

      for (let i = 0; i < values.length; i++) {
        if (values[i] === null || values[i] === undefined) {
          values[i] = 0
        }
      }
      return values
    }
  }
}
</script>

<template lang="pug">
.sparkline-chart(:class="{'light-theme': lightTheme}")
  .resize-sensor(ref="sensor")
  .chart(ref="chart")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.sparkline-chart
  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .chart
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

  .chart
    rect.placeholder
      fill: none
      stroke: transparent
      stroke-width: 0

  .x-axis-area
    line
      stroke: $metricsLabelColor
    .tick
      text
        fill: $metricsLabelColor

  //==============
  // LIGHT THEME
  //==============
  &.light-theme
    .x-axis-area
      line
        stroke: $metricsLabelColorDark
      .tick
        text
          fill: $metricsLabelColorDark
</style>
