<script>
import Webshots from 'services/webshots.js'
import ContentCache from 'services/contentCache.js'
import Log from 'services/log.js'
import { EventBus } from 'services/eventbus.js'

const config = {
  // In seconds. Fallback "refresh" time
  DEFAULT_REFRESH: 600,
  // In seconds
  MIN_REFRESH: 60,
  // In seconds
  MAX_REFRESH: 86400,

  // In ms
  SHOW_HINT_AFTER: 5000
}

export default {
  name: 'WebshotItem',

  props: {
    url: {
      type: String,
      required: true
    },
    contentId: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => { return {} }
    },
    refresh: {
      type: Number,
      default: +config.DEFAULT_REFRESH
    },
    isSingular: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      displayUrl: '',
      blobUrl: '',

      primeUrl: '',
      baseUrl: '',
      showPrime: false,

      loading: true,
      showHint: false,

      hintTimer: undefined
    }
  },

  computed: {
    refreshInterval () {
      // In seconds
      return Math.min(config.MAX_REFRESH, Math.max(config.MIN_REFRESH, +this.refresh || config.DEFAULT_REFRESH))
    },

    cdnUrl () {
      if (!this.url || !this.url.length) {
        return ''
      }

      // Add timestamp to make sure the we refetch the screenshot after some time
      const ts = ~~(Date.now() / this.refreshInterval / 1000)

      return `${this.url}?ts=${ts}&noserviceworker=true`
    },

    fitMode () {
      return `fit-${this.options.image_fit || 'cover'}`
    },

    posStyle () {
      if (this.options && this.options.image_pos && this.options.image_pos.length) {
        return {
          backgroundPosition: this.options.image_pos
        }
      }
    },

    repeatStyle () {
      if (this.options && this.options.image_repeat && this.options.image_repeat.length) {
        return {
          backgroundRepeat: this.options.image_repeat
        }
      }
    },

    backgroundImage () {
      if (!this.displayUrl || !this.displayUrl.length) { return }
      return {
        backgroundImage: `url(${this.displayUrl})`
      }
    },

    hasError () {
      return this.errorMessage && this.errorMessage.length
    }
  },

  watch: {
    cdnUrl () {
      this.loadImage()
    }
  },

  mounted () {
    clearTimeout(this.hintTimer)

    this.loadImage()
  },

  beforeDestroy () {
    clearTimeout(this.hintTimer)

    this.revokeBolbURL(this.blobUrl)
  },

  methods: {
    loadImage () {
      this.showHint = false
      this.loading = true

      clearTimeout(this.hintTimer)
      this.hintTimer = setTimeout(() => {
        clearTimeout(this.hintTimer)
        if (this.loading) {
          this.showHint = true
        }
      }, config.SHOW_HINT_AFTER)

      const url = this.cdnUrl

      const img = new Image()
      img.onload = () => {
        Webshots.getWebshotImage(url, this.contentId, this.refreshInterval).then(blobData => {
          const blobUrl = URL.createObjectURL(blobData)
          if (this.displayUrl !== blobUrl) {
            this.displayUrl = blobUrl

            if (this.isSingular) {
              this.showPrime ? this.baseUrl = blobUrl : this.primeUrl = blobUrl

              this.$nextTick(() => {
                this.showPrime = !this.showPrime
              })
            }
          }

          this.errorMessage = ''
          this.loaded()

          if (this.blobUrl && blobUrl !== this.blobUrl) {
            this.revokeBolbURL(this.blobUrl)
          }

          // Store for GC in a latter phase
          this.blobUrl = blobUrl
        }).catch(err => {
          this.errorHandler(err)
          this.loaded()
        })
      }

      img.onerror = (err) => {
        this.errorHandler(err, true)
        this.$emit('error')
      }
      img.src = ContentCache.loadTriggerURI()
    },

    errorHandler (err, consoleOnly = false) {
      const errorMsg = err.message || err.toString() || 'Unknown Error'

      const message = `Error loading webshot (${this.contentId}) - ${errorMsg}`
      this.errorMessage = message

      Log.debug('media', `Webshot: ${message}`, 'DBG_WEBSHOTERR', { error: err, url: this.url }, consoleOnly)
    },

    getBgImage (getPrime = false) {
      const displayUrl = getPrime ? this.primeUrl : this.baseUrl
      if (!displayUrl || !displayUrl.length) { return }
      return {
        backgroundImage: `url(${displayUrl})`
      }
    },

    revokeBolbURL (blobUrl = '') {
      // Revoke for better GC
      // Handle URL.revokeObjectURL() in the app.vue (root) with delay to prevent flickering
      EventBus.$emit('revoke-blob-url', blobUrl + '')
    },

    loaded () {
      clearTimeout(this.hintTimer)
      this.showHint = false
      this.loading = false
      this.$emit('loaded')
    }
  }
}
</script>

<template lang="pug">
section.webshot-item
  template(v-if="!isSingular")
    .screenshot-item.singular(:class="[fitMode]"
                              :style="[backgroundImage, repeatStyle, posStyle]")

  //- It's the only one item of its parent Webshot zone
  template(v-if="isSingular")
    //- Use item animation to prevent
    //- flickering of Player backgound color during updates of the screenshot URL
    transition(name="fade", :duration="{ enter: 50, leave: 300 }")
      .screenshot-item.prime(v-if="showPrime"
                             :class="[fitMode]"
                             :style="[getBgImage(true), repeatStyle, posStyle]")

    transition(name="fade", :duration="{ enter: 50, leave: 300 }")
      .screenshot-item.base(v-if="!showPrime"
                            :class="[fitMode]"
                            :style="[getBgImage(false), repeatStyle, posStyle]")

  transition(name="fade" appear)
    .messages(v-if="loading && showHint")
      p Loading Webshot ({{ contentId }})

  transition(name="fade" appear)
    .messages(v-if="!loading && hasError")
      p {{ errorMessage }}
</template>

<style lang="stylus">
section.webshot-item
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  .screenshot-item
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-position: 50% 50%
    background-size: cover
    background-repeat: no-repeat
    backface-visibility: hidden
    z-index: 5

    &.fit-contain
      background-size: contain

    &.fit-fill
      background-size: 100% 100%

    &.fit-origin
      background-size: auto

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 3vmin
</style>
