<script>
import Moment from 'moment-timezone'
import Color from 'color'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import { interpolate, select, arc, scaleLinear, format, max as Max } from 'd3'

import NumberValue from './NumberValue.vue'

import { mapState, mapGetters } from 'vuex'

import NumberHelper from 'services/number-helper.js'
import Metrics from 'services/metrics'
import { EventBus } from 'services/eventbus.js'

const RADIAN_CONST = 0.0174532925

const config = {
  DEFAULT_MAX: 100,
  DEFAULT_MIN: 0,

  CIRCULAR_TRACK_WIDTH: 10,
  CIRCULAR_TRACK_VALUE_WIDTH: 10,
  HORIZONTAL_TRACK_WIDTH: 30,
  HORIZONTAL_TRACK_VALUE_WIDTH: 28,
  TRACK_BORDER_RADIUS: 0,
  TRACK_START_ANGLE: 0,
  TRACK_END_ANGLE: 360,

  // [DEV-142] Default to white
  VALUE_DEFAULT_COLOR: '#fff',
  TICKS_COLOR: '#fff',
  // Dark color for app light theme
  VALUE_DARK_COLOR: '#000',
  TICKS_DARK_COLOR: '#000',

  PEAK_RECHECK_INTERVAL: 10, // in seconds
  PEAK_RANGE_PERCENT: 10, // in percent
  PEAK_RANGE_MIN: 10, // in seconds
  PEAK_ANIMATE_DURATION: 4, // in seconds

  TRACK_PADDING: 15,

  TICK_AREA_SIZE: 20,
  TICK_STROKE_WIDTH: 1,
  MINOR_TICK_SIZE: 5,
  MAJOR_TICK_SIZE: 9,

  BREAKS: [
    0, // TINY
    90, // SMALL
    150, // MEDIUM
    250, // LARGE
    400 // XLARGE
  ],

  SIZES: {
    TINY: 0,
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3,
    XLARGE: 4
  },

  MIN_HEIGHT_FOR_SHOWING_TICKS: 40,
  HORIZONTAL_GAUGE_RATIO: 0.7,
  HORIZONTAL_TEXT_RATIO: 0.3
}

export default {
  name: 'MetricsGaugeItem',
  components: { NumberValue },

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    chartType: { type: String, default: 'circular' },
    metric: { type: Object },
    color: { type: String },
    isPaginated: { type: Boolean, default: false },
    showPercent: { type: Boolean, default: false },
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      valuePath: undefined,
      valueArc: undefined,
      lastAngle: undefined,
      rendering: false,

      // Logs for peak value
      valueLogs: [],
      peakValue: 0,
      peakPath: undefined,
      peakArc: undefined,
      peakStartAngle: undefined,
      peakEndAngle: undefined,
      peakReached: false,

      hasTicks: true,
      numberValueMargin: {
        top: 0,
        right: 0
      },

      dynamicMax: undefined,
      dynamicMin: undefined,

      renderDebouncer: undefined,
      valueLogTimer: undefined,
      peakAnimateTimer: undefined
    }
  },

  computed: {
    ...mapState({
      subscribedMetrics: state => state.metrics.subscribedMetrics || {}
    }),

    ...mapGetters({
      subscribedValue: 'getSubscribedValue',
      queriedValue: 'getQueriedValue',
      winSizeRatio: 'winSizeRatio'
    }),

    dynamicMinMax () {
      return {
        max: Boolean(this.item.track && this.item.track.max && this.item.track.max.type === 'metric' && this.metric && this.metric.ref),
        min: Boolean(this.item.track && this.item.track.min && this.item.track.min.type === 'metric' && this.metric && this.metric.ref)
      }
    },

    minKey () {
      if (!this.dynamicMinMax.min) { return }
      const minMetric = Metrics.toMaxAndMinMetric(this.metric, 'min')
      return Metrics.buildQueryKey(minMetric)
    },

    maxKey () {
      if (!this.dynamicMinMax.max) { return }
      const maxMetric = Metrics.toMaxAndMinMetric(this.metric, 'max')
      return Metrics.buildQueryKey(maxMetric)
    },

    min () {
      if (this.minKey) {
        return this.dynamicMin || config.DEFAULT_MIN
      }
      return (this.item.track && this.item.track.min && this.item.track.min.value) || config.DEFAULT_MIN
    },

    max () {
      if (this.maxKey) {
        let maxValue = this.dynamicMax || config.DEFAULT_MAX
        // Prevent dynamic max goes smaller than min
        if (maxValue <= this.min) {
          maxValue = this.min + 1
        }
        return maxValue
      }
      return (this.item.track && this.item.track.max && this.item.track.max.value) || config.DEFAULT_MAX
    },

    displayNumber () {
      if (this.showPercent) {
        const number = JSON.parse(JSON.stringify(this.item.number || {}))
        number.value = (number.value || 0) / (this.max - this.min) * 100
        number.value_type = '%'
        return number
      }
      return this.item.number || {}
    },

    showSpike () {
      return Boolean(this.item.track && this.item.track.show_spike)
    },

    peakTimeRange () {
      const bucketSize = this.metric && this.metric.bucket_size
      if (bucketSize && bucketSize > 0) {
        return Math.max(config.PEAK_RANGE_MIN, bucketSize * config.PEAK_RANGE_PERCENT / 100)
      }
      return config.PEAK_RANGE_MIN
    },

    fontStyles () {
      return {
        color: this.color || this.defaultValueColor
      }
    },

    numberValueStyles () {
      return this.chartType === 'horizontal'
        ? {
            marginRight: `${this.numberValueMargin.right}px`,
            marginTop: `${this.numberValueMargin.top}px`
          }
        : null
    },

    defaultValueColor () {
      return this.lightTheme ? config.VALUE_DARK_COLOR : config.VALUE_DEFAULT_COLOR
    },

    defaultTicksColor () {
      return this.lightTheme ? config.TICKS_DARK_COLOR : config.TICKS_COLOR
    },

    trackColor () {
      let color
      try {
        color = Color(this.color || this.defaultValueColor).alpha(0.1).string()
      } catch (e) {
        // Fallback for wrongly input HEX color string during board v1 -> v2 migration
        // > https://app.bugsnag.com/telemetry/boards-2/errors/5c76bd508cb192001870c057
        if (this.color && (/^(?:[0-9a-fA-F]{3}){1,2}$/).test(this.color)) {
          color = Color(`#${this.color}`).alpha(0.1).string()
        }
        color = Color(this.defaultValueColor).alpha(0.1).string()
      }
      return color
    }
  },

  watch: {
    'item.number': {
      deep: true,
      handler (newValue) {
        if (!newValue || !NumberHelper.isNumber(newValue.value)) { return }

        if (this.dynamicMinMax.min) {
          if (NumberHelper.isNumber(this.dynamicMin)) {
            this.dynamicMin = Math.min(newValue.value, this.dynamicMin)
          } else {
            this.dynamicMin = newValue.value
          }
        }

        if (this.dynamicMinMax.max) {
          if (NumberHelper.isNumber(this.dynamicMax)) {
            this.dynamicMax = Math.max(newValue.value, this.dynamicMax)
          } else {
            this.dynamicMax = newValue.value
          }
        }

        if (this.showSpike) {
          const now = Moment().unix()
          this.valueLogs.push({
            ts: now,
            value: newValue.value
          })

          clearTimeout(this.peakAnimateTimer)
          if (newValue.value >= this.peakValue) {
            this.peakReached = true
            this.peakAnimateTimer = setTimeout(() => {
              clearTimeout(this.peakAnimateTimer)
              this.peakReached = false
            }, config.PEAK_ANIMATE_DURATION * 1000)
          } else {
            this.peakReached = false
          }

          this.peakValue = Math.max(this.peakValue, newValue.value)
        }

        this.$nextTick(() => {
          this.debounceRender()
        })
      }
    },

    min () {
      this.debounceRender()
    },

    max () {
      this.debounceRender()
    },

    peakValue () {
      this.debounceRender()
    },

    color () {
      this.debounceRender()
    },

    chartType () {
      this.debounceRender()
    },

    numberValueMargin: {
      deep: true,
      handler () {
        this.debounceRender()
      }
    },

    winSizeRatio () {
      this.debounceRender()
    },

    lightTheme () {
      this.debounceRender()
    }
  },

  created () {
    EventBus.$on('ws-query-metrics', this.updateMinMax)
  },

  mounted () {
    clearTimeout(this.peakAnimateTimer)
    clearTimeout(this.renderDebouncer)

    AddResizeListener(this.$refs.sensor, this.debounceRender)

    this.peakReached = false

    const initValue = this.item.number && this.item.number.value
    const validInitValue = NumberHelper.isNumber(initValue)

    if (this.dynamicMinMax.min && this.queriedValue(this.minKey)) {
      this.dynamicMin = this.queriedValue(this.minKey)
      if (validInitValue) {
        this.dynamicMin = Math.min(this.dynamicMin, initValue)
      }
    }

    if (this.dynamicMinMax.max && this.queriedValue(this.maxKey)) {
      this.dynamicMax = this.queriedValue(this.maxKey)
      if (validInitValue) {
        this.dynamicMax = Math.min(this.dynamicMax, initValue)
      }
    }

    this.$nextTick(() => {
      this.peakValue = initValue || this.min || 0

      if (!this.showSpike) { return }

      const now = Moment().unix()
      this.valueLogs.push({
        ts: now,
        value: initValue || 0
      })
    })

    this.debounceRender()

    clearInterval(this.valueLogTimer)
    this.valueLogTimer = setInterval(() => {
      this.recheckPeakValue()
    }, config.PEAK_RECHECK_INTERVAL * 1000)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceRender)
    }

    EventBus.$off('ws-query-metrics', this.updateMinMax)
    clearTimeout(this.peakAnimateTimer)
    clearTimeout(this.renderDebouncer)
    clearInterval(this.valueLogTimer)
  },

  methods: {
    debounceRender () {
      clearTimeout(this.renderDebouncer)
      this.renderDebouncer = setTimeout(() => {
        clearTimeout(this.renderDebouncer)
        this.renderGauge()
      }, 250)
    },

    arcTween (valueArc) {
      return function (transitionFn, newAngle) {
        transitionFn.attrTween('d', function (d) {
          const _interpolate = interpolate(d.endAngle, newAngle)
          return function (t) {
            d.endAngle = _interpolate(t)
            valueArc.endAngle(d.endAngle)
            return valueArc(d)
          }
        })
      }
    },

    peakArcTween (peakArc) {
      return function (transitionFn, newAngle) {
        transitionFn.attrTween('d', function (d) {
          const _interpolateEnd = interpolate(d.endAngle, newAngle)
          const _interpolateStart = interpolate(d.startAngle, Math.max((config.TRACK_START_ANGLE - 180) * RADIAN_CONST, newAngle - RADIAN_CONST))
          return function (t) {
            d.endAngle = _interpolateEnd(t)
            d.startAngle = _interpolateStart(t)
            peakArc.endAngle(d.endAngle)
            peakArc.startAngle(d.startAngle)
            return peakArc(d)
          }
        })
      }
    },

    setHorizontalNumberTrack (number, containerSize) {
      if (this.min >= this.max) { return }
      number = number || this.item.number || {}

      const position = scaleLinear()
        .range([0, containerSize.width])
        .domain([this.min, this.max])

      const self = this

      if (this.valuePath && NumberHelper.isNumber(number.value)) {
        const newX2 = number.value > this.max ? containerSize.width : position(number.value)
        // Don't perform peak track animation on every paginate
        if (this.isPaginated) {
          this.valuePath.attr('x2', newX2)
          this.rendering = false
        } else {
          this.valuePath.transition()
            .duration(1000)
            .attr('x2', newX2)
            .on('end', function () {
              self.rendering = false
            })
        }
      }

      if (this.showSpike && this.peakPath && NumberHelper.isNumber(this.peakValue)) {
        let newX2
        if (this.peakValue > this.min) {
          newX2 = this.peakValue >= this.max ? position(this.max) + 1 : position(this.peakValue)
        } else {
          return
        }

        // Don't perform peak track animation on every paginate
        if (this.isPaginated) {
          this.peakPath
            .attr('x1', newX2 - 2)
            .attr('x2', newX2)
        // Peak track animation
        } else {
          this.peakPath.transition()
            .duration(1000)
            .attr('x1', newX2 - 2)
            .attr('x2', newX2)
        }
      }
    },

    setCircularNumberTrack (number) {
      if (this.min >= this.max) { return }
      number = number || this.item.number || {}

      const startAngle = (config.TRACK_START_ANGLE - 180) * RADIAN_CONST
      const endAngle = (config.TRACK_END_ANGLE - 180) * RADIAN_CONST
      const angleSpan = endAngle - startAngle
      const self = this

      if (this.valueArc && this.valuePath && NumberHelper.isNumber(number.value)) {
        const newAngle = startAngle + 0.00001 + angleSpan * Math.max(0, Math.min(1, (number.value - this.min) / (this.max - this.min)))

        // Don't perform value track animation on every paginate
        if (this.isPaginated) {
          this.valueArc.endAngle(newAngle)
          this.valuePath.attr('d', this.valueArc)
          this.lastAngle = newAngle
          this.rendering = false
        // Value track animation
        } else {
          this.valuePath.transition()
            .duration(1000)
            .call(this.arcTween(this.valueArc), newAngle)
            .on('end', function () {
              self.lastAngle = newAngle
              self.rendering = false
            })
        }
      }

      if (this.showSpike && this.peakArc && this.peakPath && NumberHelper.isNumber(this.peakValue)) {
        let newAngle
        if (this.peakValue > this.min) {
          newAngle = startAngle + 0.00001 + angleSpan * Math.max(0, Math.min(1, (this.peakValue - this.min) / (this.max - this.min)))
        } else if (this.peakEndAngle !== undefined && this.peakEndAngle > startAngle) {
          newAngle = startAngle + 0.00001
        } else {
          return
        }

        // Don't perform peak track animation on every paginate
        if (this.isPaginated) {
          this.peakArc.endAngle(newAngle)
          this.peakPath.attr('d', this.peakArc)
          this.peakEndAngle = newAngle
        // Peak track animation
        } else {
          this.peakPath.transition()
            .duration(1000)
            .call(this.peakArcTween(this.peakArc), newAngle)
            .on('end', function () {
              self.peakEndAngle = newAngle
              self.peakStartAngle = Math.max(startAngle, newAngle - RADIAN_CONST)
            })
        }
      }
    },

    drawHorizontalTrack (container, containerSize) {
      const trackWidth = config.HORIZONTAL_TRACK_WIDTH * this.winSizeRatio
      const valueWidth = config.HORIZONTAL_TRACK_VALUE_WIDTH * this.winSizeRatio

      let trackContainer = container.select('g.track-container')
      if (trackContainer.empty()) {
        trackContainer = container.append('svg:g')
          .attr('class', 'track-container')
          .attr('transform', 'translate(' + 0 + ',' + -trackWidth / 2 + ')')
      }

      // BG TRACK
      let trackPath = trackContainer.select('line.track')
      if (trackPath.empty()) {
        trackPath = trackContainer.append('svg:line')
          .attr('class', 'track')
      }

      trackPath
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', containerSize.width)
        .attr('y2', 0)
        .attr('stroke-width', trackWidth)
        .attr('stroke', this.trackColor)

      // BG TRACK
      let valuePath = trackContainer.select('line.value')
      if (valuePath.empty()) {
        valuePath = trackContainer.append('svg:line')
          .attr('class', 'value')
      }

      valuePath
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', 0)
        .attr('stroke-width', valueWidth)
        .attr('stroke', this.color || this.defaultValueColor)

      if (this.showSpike) {
        let peakPath = trackContainer.select('line.peak')
        if (peakPath.empty()) {
          peakPath = trackContainer.append('svg:line').attr('class', 'peak')
        }

        peakPath
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('x2', 0)
          .attr('y2', 0)
          .attr('stroke-width', valueWidth)

        this.peakPath = peakPath
      } else {
        this.peakPath = undefined
      }

      this.valuePath = valuePath
    },

    drawCircularTrack (container, referenceSize) {
      const trackRadius = referenceSize / 2
      const trackWidth = config.CIRCULAR_TRACK_WIDTH * this.winSizeRatio
      const valueTrackWidth = config.CIRCULAR_TRACK_VALUE_WIDTH * this.winSizeRatio
      const startAngle = (config.TRACK_START_ANGLE - 180) * RADIAN_CONST * this.winSizeRatio

      // BG TRACK
      let trackPath = container.select('circle.track')
      if (trackPath.empty()) {
        trackPath = container.append('svg:circle').attr('class', 'track')
      }
      trackPath
        .attr('stroke-width', trackWidth)
        .attr('r', trackRadius - trackWidth / 2)
        .attr('stroke', this.trackColor)

      const valueTrackRadius = trackRadius - (trackWidth - valueTrackWidth) / 2
      const valueInnerRadius = valueTrackRadius - valueTrackWidth

      // SPIKE (PEAK) INDICATOR
      if (this.showSpike) {
        const peakArcData = {
          innerRadius: valueInnerRadius,
          outerRadius: valueTrackRadius,
          cornerRadius: config.TRACK_BORDER_RADIUS,
          startAngle: this.peakStartAngle !== undefined ? this.peakStartAngle : startAngle,
          endAngle: this.peakEndAngle !== undefined ? this.peakEndAngle : startAngle
        }
        this.peakArc = arc(peakArcData).cornerRadius(config.TRACK_BORDER_RADIUS)

        let peakPath = container.select('path.peak')
        if (peakPath.empty()) {
          peakPath = container.append('svg:path').attr('class', 'peak')
        }
        peakPath.datum(peakArcData).attr('d', this.peakArc)
        this.peakPath = peakPath
      } else {
        this.peakArc = undefined
        this.peakPath = undefined
      }

      // VALUE TRACK (topmost)
      const valueArcData = {
        innerRadius: valueInnerRadius,
        outerRadius: valueTrackRadius,
        cornerRadius: config.TRACK_BORDER_RADIUS,
        startAngle: startAngle,
        endAngle: this.lastAngle !== undefined ? this.lastAngle : startAngle + 0.0001
      }
      this.valueArc = arc(valueArcData).cornerRadius(config.TRACK_BORDER_RADIUS)

      let valuePath = container.select('path.value')
      if (valuePath.empty()) {
        valuePath = container.append('svg:path').attr('class', 'value')
      }
      valuePath
        .datum(valueArcData)
        .attr('d', this.valueArc)
        .attr('fill', this.color || this.defaultValueColor)

      this.valuePath = valuePath
    },

    drawTicks (container, containerSize) {
      const gaugeSize = this.horizontalGaugeSize(containerSize.width)

      if (containerSize.height < config.MIN_HEIGHT_FOR_SHOWING_TICKS || gaugeSize <= config.SIZES.SMALL) {
        this.hasTicks = false
        return
      } else {
        this.hasTicks = true
      }

      const padding = config.TRACK_PADDING * this.winSizeRatio
      const minorTickSize = config.MINOR_TICK_SIZE * this.winSizeRatio
      const majorTickSize = config.MAJOR_TICK_SIZE * this.winSizeRatio
      const trackWidth = config.HORIZONTAL_TRACK_WIDTH * this.winSizeRatio

      const axis = scaleLinear()
        .range([this.min, this.max])
        .domain([this.min, this.max])

      const position = scaleLinear()
        .range([0, containerSize.width])
        .domain([this.min, this.max])

      const lowFormat = format('.1d')
      const highFormat = format('.1s')

      const numberFormat = function (val) {
        if (val < 1000) {
          return lowFormat(val)
        }
        return highFormat(val)
      }

      let index
      let x

      let textContainer = container.select('g.text-container')
      if (textContainer.empty()) {
        textContainer = container.append('svg:g')
          .attr('class', 'text-container')
          .attr('transform', 'translate(' + 0 + ',' + (trackWidth / 2 - padding) + ')')
      }

      if (gaugeSize > config.SIZES.SMALL) {
        textContainer.append('svg:line')
          .attr('x1', position(this.min) - 0.5)
          .attr('x2', position(this.max) + 0.5)
          .attr('class', 'tick major-tick')
          .style('stroke', this.defaultTicksColor)

        for (index = this.min; index <= this.max; index += (this.max - this.min) / 10) {
          x = position(index)

          textContainer.append('svg:line')
            .attr('x1', x)
            .attr('y1', 0.5)
            .attr('x2', x)
            .attr('y2', (gaugeSize > config.SIZES.MEDIUM) ? majorTickSize : minorTickSize)
            .attr('class', 'tick')
            .style('stroke', this.defaultTicksColor)

          if (gaugeSize > config.SIZES.MEDIUM) {
            textContainer.append('text')
              .attr('class', 'tick-text')
              .attr('x', x)
              .attr('y', padding)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'hanging')
              .text(numberFormat(axis(index)))
              .style('fill', this.defaultTicksColor)
          }
        }
      }

      if (gaugeSize > config.SIZES.MEDIUM) {
        for (index = this.min; index < this.max; index += (this.max - this.min) / 20) {
          x = position(index)

          textContainer.append('svg:line')
            .attr('x1', x)
            .attr('y1', 0.5)
            .attr('x2', x)
            .attr('y2', minorTickSize)
            .attr('class', 'tick minor-tick')
            .style('stroke', this.defaultTicksColor)
        }
      }
    },

    horizontalGaugeSize (referenceSize) {
      let size
      for (const key in config.BREAKS) {
        if (referenceSize >= config.BREAKS[key]) {
          size = key
        }
      }
      return +size
    },

    renderGauge () {
      if ((!this.min && this.min !== 0) || (!this.max && this.max !== 0)) { return }
      if (!this.item.number || !NumberHelper.isNumber(this.item.number.value)) { return }

      if (!this.$refs || !this.$refs.svg || !this.$refs.sensor) {
        return
      }

      if (this.rendering) {
        this.debounceRender()
        return
      }

      this.rendering = true

      const self = this

      let containerSize
      let numberSize = { width: 0, height: 0 }

      let drawGauge

      function drawHorizontalGauge () {
        const padding = config.TRACK_PADDING * self.winSizeRatio
        const container = self.$refs.svg
        let mainContainerYPadding
        let mainContainerSize
        const trackWidth = config.HORIZONTAL_TRACK_WIDTH * self.winSizeRatio

        if (numberSize.width + 2 * padding <= containerSize.width * config.HORIZONTAL_TEXT_RATIO) {
          self.$set(self.numberValueMargin, 'right', (containerSize.width * config.HORIZONTAL_TEXT_RATIO / 2 - numberSize.width / 2) > 0 ? containerSize.width * config.HORIZONTAL_TEXT_RATIO / 2 - numberSize.width * 0.6 : 0)
          self.$set(self.numberValueMargin, 'top', 0)
          mainContainerSize = {
            width: containerSize.width * config.HORIZONTAL_GAUGE_RATIO,
            height: containerSize.height
          }
          mainContainerYPadding = containerSize.height / 2
        } else {
          self.$set(self.numberValueMargin, 'right', containerSize.width / 2 - numberSize.width / 2)
          mainContainerSize = {
            width: containerSize.width - 2 * padding,
            height: containerSize.height
          }

          if (numberSize.height <= containerSize.height / 2) {
            self.$set(self.numberValueMargin, 'top', -numberSize.height)
            mainContainerYPadding = containerSize.height / 2 + trackWidth
          } else if (numberSize.height > containerSize.height / 2 && numberSize.height <= containerSize.height * 3 / 4) {
            self.$set(self.numberValueMargin, 'top', -numberSize.height / 2)
            mainContainerYPadding = containerSize.height * 3 / 4
          } else {
            self.$set(self.numberValueMargin, 'top', 0)
            mainContainerYPadding = containerSize.height - (trackWidth < containerSize.height / 8 ? trackWidth * 3 / 2 : 0)
          }
        }

        let svg = select(container).select('svg')
        if (svg.empty()) {
          svg = select(container).select('.svg-wrapper').append('svg:svg')
        } else {
          svg.selectAll('*').remove()
        }

        svg.attr('width', containerSize.width)
          .attr('height', containerSize.height)

        let mainContainer = svg.select('g.main-container')
        if (mainContainer.empty()) {
          mainContainer = svg.append('svg:g')
            .attr('class', 'main-container')
            .attr('transform', 'translate(' + padding + ',' + mainContainerYPadding + ')')
            .style('fill', 'none')
        }

        self.drawHorizontalTrack(mainContainer, mainContainerSize)

        if (containerSize.height >= config.MIN_HEIGHT_FOR_SHOWING_TICKS) {
          self.hasTicks = true
          self.drawTicks(mainContainer, mainContainerSize)
        }

        self.$nextTick(() => {
          self.setHorizontalNumberTrack(self.item.number, mainContainerSize)
        })
      }

      function drawCircularGauge () {
        const padding = config.TRACK_PADDING * self.winSizeRatio
        const referenceSize = Math.min(containerSize.width, containerSize.height - padding)

        const container = self.$refs.svg

        let svg = select(container).select('svg')
        if (svg.empty()) {
          svg = select(container).select('.svg-wrapper').append('svg:svg')
        } else {
          svg.select('*').remove()
        }

        svg.attr('width', referenceSize)
          .attr('height', referenceSize)
          .style('margin-top', (padding / 2) + 'px')

        let mainContainer = svg.select('g.main-container')
        if (mainContainer.empty()) {
          mainContainer = svg.append('svg:g')
            .attr('class', 'main-container')
            .style('fill', 'none')
        }
        mainContainer.attr('transform', 'translate(' + (referenceSize / 2) + ',' + (referenceSize / 2) + ')')

        self.drawCircularTrack(mainContainer, referenceSize)

        self.$nextTick(() => {
          self.setCircularNumberTrack(self.item.number)
        })
      }

      if (this.chartType === 'horizontal') {
        drawGauge = drawHorizontalGauge
      } else {
        drawGauge = drawCircularGauge
      }

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!self.$refs || !self.$refs.sensor) {
          FastDom.clear(measure)
          return
        }

        containerSize = self.$refs.sensor.getBoundingClientRect()
        if (self.$refs.number && self.$refs.number.$el) {
          numberSize = self.$refs.number.$el.getBoundingClientRect()
        }

        const mutate = FastDom.mutate(() => {
          if (containerSize.width === 0 || containerSize.height === 0) {
            self.debounceRender()
            FastDom.clear(mutate)
            FastDom.clear(measure)
            return
          }

          drawGauge()
          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    recheckPeakValue () {
      if (!this.showSpike || !this.valueLogs || !this.valueLogs.length) { return }
      const now = Moment().unix()
      const validLogs = this.valueLogs.filter(log => log.ts > now - this.peakTimeRange)
      if (!validLogs.length) {
        this.peakValue = this.min || 0
      } else {
        this.peakValue = Max(validLogs.map(log => log.value))
      }
      this.valueLogs = validLogs
    },

    updateMinMax (m) {
      if (!m || !Array.isArray(m) || !m.length) { return }
      if (!this.dynamicMinMax.max && !this.dynamicMinMax.min) { return }

      if (this.dynamicMinMax.min && m.includes(this.minKey)) {
        const newMin = this.queriedValue(this.minKey)
        if (NumberHelper.isNumber(this.dynamicMin)) {
          this.dynamicMin = Math.min(this.dynamicMin, newMin)
        } else {
          this.dynamicMin = newMin
        }
      }

      if (this.dynamicMinMax.max && m.includes(this.maxKey)) {
        const newMax = this.queriedValue(this.maxKey)
        if (NumberHelper.isNumber(this.dynamicMax)) {
          this.dynamicMax = Math.max(this.dynamicMax, newMax)
        } else {
          this.dynamicMax = newMax
        }
      }
    }
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-gauge-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}
  .item-body.app-context-section.primary
    .gauge-container(:class="{'is-horizontal': chartType === 'horizontal'}")
      .svg-container(ref="svg", :class="{'light-theme': lightTheme, 'peak-reached': peakReached, 'is-horizontal': chartType === 'horizontal'}")
        .svg-wrapper
        .svg-placeholder(ref="sensor")
      number-value(v-if="displayNumber"
                  ref="number"
                  :number-data="displayNumber"
                  :is-static="isPaginated"
                  :style="[fontStyles, numberValueStyles]")
    .item-label(v-if="item.label && item.label.text") {{ item.label.text }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

@keyframes blink-peak-reached {
  0% { opacity: 1.0; }
  50% { opacity: 0.6; }
  100% { opacity: 1.0; }
}

.metrics-gauge-item
  .item-body
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .number-value
    position: relative
    z-index: 5
    line-height: 130%
    font-weight: 600
    font-size: 2em
    .digit
      font-weight: 700

  .gauge-container
    flex: 1 1 0.0001px
    align-self: stretch
    position: relative
    z-index: inherit
    display: flex
    align-items: center

    // circular (default)
    justify-content: center
    flex-flow: column nowrap

    &.is-horizontal
      justify-content: flex-end
      flex-flow: row nowrap

    .svg-container,
    .svg-placeholder
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 1

    .svg-placeholder
      z-index: -1

    .svg-container
      display: flex
      align-items: center

      // circular (default)
      flex-flow: column nowrap
      justify-content: center

      &.is-horizontal
        flex-flow: row nowrap
        justify-content: flex-start

      .svg-wrapper
        text-align: center
        display: flex

  .item-label
    text-transform: lowercase
    line-height: 110%
    text-align: center
    padding-top: 1em

  .svg-container
    svg
      path.peak
        fill: $metricsLabelColor
      line.peak
        stroke: $metricsLabelColor

    &.peak-reached
      path.value,
      line.value
        animation: blink-peak-reached 1s infinite 1s

    // LIGHT THEME
    &.light-theme
      svg
        path.peak
          fill: $metricsLabelColorDark
        line.peak
          stroke: $metricsLabelColorDark
</style>
