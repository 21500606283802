<script>
// MIXIN
import WeatherMixins from './WeatherMixins.vue'

import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'
import Weather from 'services/weather/index.js'

import WeatherIcon from './WeatherIcon.vue'
import Clock from 'components/widgets/Clock.vue'

export default {
  name: 'WeatherBlueSkies',

  components: { Clock, WeatherIcon },

  // MIXIN
  // All common functions were moved there
  mixins: [WeatherMixins],

  // Inject config from ancestor component `WeatherItem.vue`
  inject: ['config'],

  props: {
    // Trigger delayed transitions
    show: { type: Boolean, default: false },

    location: { type: String, required: true },
    locationDisplayName: { type: String, default: '' },
    show24hr: { type: Boolean, default: false },
    mdyFormat: { type: Boolean, default: false },

    isPortrait: { type: Boolean, default: false },
    isWide: { type: Boolean, default: false },
    isMedium: { type: Boolean, default: false },
    isTall: { type: Boolean, default: false },

    temperatureUnit: { type: String, default: '' },
    fontClass: { type: String, default: '' },
    fontScale: { type: Number, default: 1.0 },
    titleFontScale: { type: Number, default: 1.0 }
  },

  data () {
    return {
      refreshInterval: undefined
    }
  },

  computed: {
    background () {
      if (this.weather) {
        if (this.weather.daynight === 'n') {
          return { backgroundImage: 'linear-gradient(180deg, #0E0B13 5%, #191423 60%, #201A2C 90%)' }
        } else if (Weather.isClearSky(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #0056AD 5%, #0087E5 60%, #3EB0FE 90%)' }
        } else if (Weather.isCloudy(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #3F70A0 5%, #4683AD 60%, #6DA7CE 90%)' }
        } else if (Weather.isRainy(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #283038 5%, #304554 60%, #415D71 90%)' }
        } else if (Weather.isFoggy(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #4A5862 5%, #7D8890 60%, #AAB7BF 90%)' }
        } else if (Weather.isDust(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #A66020 5%, #C58044 60%, #C79D73 90%)' }
        } else if (Weather.isStorm(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #111 5%, #212121 60%, #2F3335 90%)' }
        } else if (Weather.isSnowning(this.weatherCode)) {
          return { backgroundImage: 'linear-gradient(180deg, #636886 5%, #84889E 60%, #9DA1B8 90%)' }
        }
      }
      // Default Gradient
      return { backgroundImage: 'linear-gradient(180deg, #262835 5%, #2F3241 60%, #333647 90%)' }
    },

    scaledFontSizeStyle () {
      return {
        fontSize: `${this.fontScale || 1.0}em`
      }
    },

    scaledTitleFontSizeStyle () {
      return {
        fontSize: `${this.titleFontScale || 1.0}em`
      }
    }
  },

  watch: {
    location (val) {
      if (!val) { return }
      this.updateWeather()
    },

    // [DEV-1743] add display unit options
    temperatureUnit () {
      this.updateWeather()
    },

    // [DEV-1869] Weather Localization
    localeLangKey () {
      this.updateWeather()
    }
  },

  mounted () {
    this.loading = true
    this.updateWeather()

    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.hasError = false
      this.loading = true
      this.updateWeather()
    }, this.config.REFRESH_INTERVAL * 1000)
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },

  methods: {
    updateWeather (useFahrenheit) {
      this.unit = useFahrenheit ? 'f' : 'c'

      const location = this.location
      if (!location) {
        if (this.show) {
          Log.warn('app', 'Weather app: Unable to determine location', 'WAR_BLUESKIESNOLOC')
        }
        this.loaded()
        return
      }

      const locationStr = location.replace(/(\s|,|')+/g, '-').toLowerCase()
      this.locationKey = `weather_page_${locationStr}+hourly+daily`

      this.timeoutChecking()

      Promise.all([
        Weather.current(location, useFahrenheit, this.localeLangKey),
        Weather.dailyForecast(location, useFahrenheit, this.localeLangKey),
        Weather.hourlyForecast(location, useFahrenheit, this.localeLangKey)
      ])
        .then(results => {
          clearTimeout(this.timeoutTimer)
          const [currentWeather, forecastWeather, forecastHourly] = results

          // [DEV-1743] add display unit options
          const needsFahrenheit = this.needsFahrenheit(currentWeather)
          const isUseFahrenheit =
          (needsFahrenheit && this.unit !== 'f' && this.temperatureUnit !== 'c') ||
          (!needsFahrenheit && this.unit !== 'f' && this.temperatureUnit === 'f')
          if (isUseFahrenheit) {
            this.updateWeather(true)
            return
          }

          this.hasError = false
          this.skipReload = true
          this.errorMessage = ''

          const weather = currentWeather

          const units = {
            unit: this.unit
          }
          // AccuWeather contains units in the result
          if (currentWeather.units) {
            units.speed = currentWeather.units.wind
            units.temperature = currentWeather.units.temp
            units.pressure = currentWeather.units.pressure
          } else {
            units.speed = this.unit === 'f' ? 'mi/h' : 'm/s'
            units.temperature = this.unit === 'f' ? 'F' : 'C'
            units.pressure = 'hPa'
          }
          this.units = units

          if (currentWeather.timezone && currentWeather.timezone.Name) {
            // AccuWeather will return timezone name in data
            this.updateDateTimeInfo(currentWeather, this.mdyFormat)
          }

          const forecastData = []
          // - Start forcasting from tommorrow (0 = today)
          for (let idx = 1; idx <= this.config.DAYS_TO_SHOW; idx++) {
            const day = forecastWeather[idx]
            if (day) {
              forecastData.push(day)
            }
          }
          weather.forecast = forecastData

          const localTimeNow = this.getLocalTimestamp()
          const forecastHourlyData = []

          for (let j = 0; j < forecastHourly.length; j++) {
            const fc = forecastHourly[j]
            // Hide elapsed data
            if (fc.time > localTimeNow) {
              forecastHourlyData.push(fc)
            }
            if (forecastHourlyData.length >= 7) { break }
          }
          weather.forecastHourly = forecastHourlyData

          this.weather = weather
          this.loaded()

          // Store weather data
          const weatherData = JSON.parse(JSON.stringify(this.weather))
          weatherData.timestamp = new Date().getTime()
          weatherData.src = 'aw'
          OfflineCaches.set(this.locationKey, weatherData)
        })
        .catch(err => {
          clearTimeout(this.timeoutTimer)
          this.handleError(err)
        })
    }
  }
}
</script>

<template lang="pug">
.blueskies-weather-item(:class="[fontClass, {'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-medium': isMedium, 'is-wide': isWide, 'is-tall': isTall}]")
  .resize-sensor(ref="sensor")

  .background-gradient(:style="background")

  .item-wrapper(v-if="weather")
    .top-zone(:style="scaledTitleFontSizeStyle")
      .location
        .city(v-if="locationDisplayName") {{ locationDisplayName }}
        .city(v-else) {{ weather.location }}, {{ weather.country }}

      .time-n-date
        .current-date
          .date(v-if="datetimeInfo") {{datetimeInfo}}
          clock(v-if="datetimeInfo", :timezone="timezone", :hours="show24hr ? '24H' : '12H'")

    .main-zone
      .current-and-recent
        .current(:style="scaledFontSizeStyle")
          .icon-n-temp(:class="{'smaller-size': weatherTemp >= 100}")
            //- weather-icon.weather-icon(:code="weather.code", :daynight="weather.daynight" use-animated-sets)
            weather-icon.weather-icon(:code="weather.code", :daynight="weather.daynight" use-png-sets)
            .temp {{weatherTemp}}°{{units.temperature}}
          .meta
            .current-condition
              transition(name="slideLeft" appear)
                .inner.delay-500(v-if="show && weather && weather.description") {{weather.description}}
            .atmosphere
              .item.wind
                transition(name="slideLeft" appear)
                  .inner.delay-700(v-if="show && weather && weather.wind")
                    .label-text {{ $t('pageItems.weather.wind') }}
                    .content {{windDirection(weather)}} {{windSpeed(weather)}}{{units.speed}}
              .item.humidity
                transition(name="slideLeft" appear)
                  .inner.delay-900(v-if="show && weather && weather.time")
                    .label-text {{ $t('pageItems.weather.humidity') }}
                    .content {{ weather.humidity }}%
              .item.visibility
                transition(name="slideLeft" appear)
                  .inner.delay-1100(v-if="show && weather && weather.pressure")
                    .label-text {{ $t('pageItems.weather.pressure') }}
                    .content {{ pressure(weather) }}{{units.pressure}}

        .forecast-3hrs(v-if="!isWide", :style="scaledFontSizeStyle")
          .item(v-for="(item, index) in weather.forecastHourly", :key="index")
            transition(name="fadeDown" appear)
              .inner(v-if="show && weather && weather.forecastHourly", :class="delayAnimationClass(1200, index, 100)")
                .hour {{ getHour(item.time) }}
                //- weather-icon.weather-icon(:code="item.code", :daynight="item.daynight" use-animated-sets)
                weather-icon.weather-icon(:code="item.code", :daynight="item.daynight" use-png-sets)
                .temp {{item.temp}}°{{units.temperature}}

      .forecast(v-if="!isWide && !isTall", :style="scaledFontSizeStyle")
        .day(v-for="(day, index) in weather.forecast", :key="index")
          transition(name="fadeUpBig" appear)
            .inner(v-if="show && weather && weather.forecast", :class="delayAnimationClass(2000, index, 200)")
              .contexts
                .dow
                  span {{ getWeekday(day.time) }}
                .temps
                  | {{ parseTemp(day.temp.min) }} ~ {{ parseTemp(day.temp.max) }}°{{units.temperature}}
              //- weather-icon.weather-icon(:code="day.code" use-animated-sets)
              weather-icon.weather-icon(:code="day.code" use-png-sets)

  .messages
    .loading(v-if="!weather && loading") {{ $t('pageItems.weather.hintLoadingForecast') }} {{locationText}}
    .error-message(v-if="hasError && !loading" v-text="errorMessage")
    .retry-in(v-if="showRetryMessage && retryInSeconds") {{ $t('pageItems.weather.hintRetryIn', {retryInSeconds}) }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.blueskies-weather-item {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  > .resize-sensor {
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .item-wrapper {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-shadow: 0.05em 0.05em 0.05em -black(.7);
    padding: 6em 8em;
    overflow: hidden;

    display: flex;
    flex-flow: column;
    align-items: stretch;
    overflow: hidden;
  }

  .background-gradient {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.7;
    -webkit-transition: background .5s;
    transition: background .5s;
  }

  .wi {
    // If animated SVG is availabe, hide the static icon
    &.animated {
      &:before {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .top-zone {
    position: relative;

    .location {
      .city {
        font-size: 6em;
        padding-bottom: 0.2em;
      }
    }

   .time-n-date {
      .current-date {
        min-height: 1em;
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 3em;
      }

      .date {
        padding-right: 0.8em;
        font-weight: 300;
      }

      .simple-clock {
        font-size: inherit;
        align-items: flex-start;
        .time-wrapper {
          font-size: 1em;
          font-weight: 300;
          .apm {
            font-weight: 300;
          }
        }
      }
    }
  }

  .main-zone {
    flex: 1 1 0.00001px;
    display: flex;
    flex-flow: row nowrap;

    .forecast {
      min-width: 25em;
      box-sizing: border-box;
    }

    .current-and-recent {
      flex: 1 1 0.00001px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  // CURRENT WEATHER

  .current {
    flex: 1 1 0.00001px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .wi {
      display: inline-block;
      font-size: 21em;
    }

    .icon-n-temp {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &.smaller-size {
        .weather-icon {
          font-size: 18em;
        }

        .temp {
          font-size: 14em;
        }
      }
    }

    .meta {
      * {
        line-height: 100%;
      }

      margin-left: 5em;

      .current-condition {
        font-size: 5em;
        text-transform: capitalize;
        overflow: hidden;

        .inner {
          line-height: 130%;
        }
      }

      .atmosphere {
        display: flex;
        flex-flow: row nowrap;
        padding: 2.2em 0 0 .15em;

        .item {
          position: relative;
          overflow: hidden;
          margin-left: 3em;
          white-space: nowrap;

          &:first-of-type {
            margin-left: 0;
          }
        }

        .label-text {
          text-transform: uppercase;
          font-size: 1.8em;
          opacity: 0.7;
          padding-bottom: 0.5em;
        }

        .content {
          font-size: 2.2em;
          line-height: 130%;
        }
      }
    }

    .temp {
      font-size: 16em;
      margin-left: 0.15em;
    }
  }

  // 3 HOURS FORECAST

  .forecast-3hrs {
    display: flex;
    flex-flow: row nowrap;

    .item {
      flex: 1 1 0.000001px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .inner {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }

      .hour {
        font-size: 2em;
        opacity: 0.7;
      }

      .temp {
        font-size: 2.8em;
      }

      .weather-icon {
        font-size: 4.5em;
        margin: 0.25em 0;
      }
    }
  }

  // 5 DAYS FORECAST

  .forecast {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow: hidden;

    .day {
      display: flex;
      flex-flow: row nowrap;

      .inner {
        flex: 1 1 0.00001px;
        display: flex;
        flex-flow: row nowrap;
      }

      .contexts {
        flex: 1 1 0.00001px;
      }

      .dow {
        font-size: 2em;
        line-height: 110%;
        text-transform: uppercase;
        opacity: 0.7;
        margin-bottom: 1em;
        padding-top: 0.6em;

        span {
          font-weight: bold;
          background: -black(0.3);
          padding: 0.1em 0.5em 0.18em 0.5em;
          border-radius: 0.3em;
        }
      }

      .temps {
        font-size: 3em;
      }

      .weather-icon {
        font-size: 6.5em;
        margin-left: 0.5em;
        line-height: 130%;
      }
    }
  }

  //-----------------
  // DIFFERENT SIZES
  //-----------------

  &.landscape:not(.is-medium), {
    .forecast {
      margin-left: 8em;
      padding-left: 4em;
      border-left: 1px solid -white(0.3);
    }
  }

  &.is-wide {
    .item-wrapper {
      padding: 2em 4em;
      flex-flow: row nowrap;
      justify-content: center;

      .top-zone {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-end;
        text-align: right;
        margin-right: 10em;

        .location {
          .city {
            font-size: 4em;
          }
        }

       .time-n-date {
          .current-date {
            font-size: 2em;
          }
        }
      }

      .main-zone {
        flex: none;
      }

      .current {
        .wi {
          font-size: 15em;
        }

        .temp {
          font-size: 14em;
        }
      }

      .icon-n-temp {
        &.smaller-size {
          .weather-icon {
            font-size: 12em;
          }

          .temp {
            font-size: 8em;
          }
        }
      }

      .meta {
        margin-left: 10em;

        .current-condition {
          font-size: 4em;
        }

        .atmosphere {
          padding: 1.6em 0 0 .15em;

          .item {
            margin-left: 4em;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.is-medium,
  &.is-portrait {
    // Switch to vertical layout
    .main-zone {
      flex-flow: column nowrap;
      align-items: stretch;
    }

    .forecast-3hrs {
      .item {
        justify-content: center;

        .weather-icon {
          margin: 0.1em 0;
        }
      }
    }

    // Switch to row layout
    .forecast {
      flex-flow: row nowrap;
      margin-left: 0;
      margin-top: 8em;
      padding-top: 3em;
      border-top: 1px solid -white(0.3);

      .day {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin: 0 1em;

        .inner {
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
        }

        .weather-icon {
          margin-left: 0;
        }
      }

      .contexts {
        text-align: center;
        margin-bottom: .5em;
      }
    }
  }

  &.is-medium {
    .item-wrapper {
      padding: 6em;
    }

    .current {
      .wi {
        font-size: 22em;
      }

      .temp {
        font-size: 18em;
      }

      .current-condition {
        font-size: 6em;
      }

      .meta {
        .atmosphere {
          padding-top: 1.6em;

          .item {
            margin-left: 4em;

            &:first-of-type {
              margin-left: 0;
            }
          }

          .label-text {
            font-size: 2em;
          }

          .content {
            font-size: 3em;
          }
        }
      }
    }

    .forecast-3hrs {
      .item {
        .hour {
          font-size: 2.5em;
        }

        .temp {
          font-size: 2.8em;
        }

        .weather-icon {
          font-size: 6em;
        }
      }
    }

    .forecast {
      .day {
        .temps {
          font-size: 2.5em;
        }
      }
    }
  }

  &.is-portrait {
    .item-wrapper {
      padding: 4em 6em;
    }

    .current {
      flex-flow: column nowrap;
      align-items: center;

      .wi {
        font-size: 30em;
      }

      .temp {
        font-size: 24em;
      }

      .current-condition {
        font-size: 8em;
      }

      .meta {
        margin-top: 2em;
        margin-left: 0;
        .atmosphere {
          .item {
            margin-left: 6em;

            &:first-of-type {
              margin-left: 0;
            }

            .label-text {
              font-size: 4em;
            }

            .content {
              font-size: 4em;
            }
          }
        }
      }
    }

    .forecast-3hrs {
      padding-top: 2em;

      .item {
        .hour {
          font-size: 3.2em;
        }

        .temp {
          font-size: 3.5em;
        }

        .weather-icon {
          font-size: 7.5em;
        }
      }
    }

    .forecast {
      margin-top: 6em;
      padding-top: 2em;

      .day {
        .temps {
          font-size: 4em;
        }

        .weather-icon {
          font-size: 9em;
        }
      }
    }
  }

  &.is-tall {
    .current {
      .wi {
        font-size: 22em;
      }

      .temp {
        font-size: 10em;
      }

      .current-condition {
        font-size: 6em;
      }

      .icon-n-temp {
        flex-flow: column nowrap;
      }

      .meta {
        .atmosphere {
          flex-flow: column nowrap;
          padding: 4em 0 0 0;

          .item {
            margin-left: 0;
            margin-top: 4.5em;
            text-align: center;
          }
        }
      }
    }

    .forecast-3hrs {
      padding-top: 0;
      flex-flow: row wrap;
      .item {
        padding-top: 6em;
        width: 33.3%;
        flex: none;

        .hour {
          font-size: 3.5em;
        }

        .temp {
          font-size: 4em;
        }

        .weather-icon {
          font-size: 8em;
        }
      }
    }
  }

  //
  // MESSAGES
  //

  .messages {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    font-size: 3em;

    .error-message,
    .loading {
      background-color: transparent !important;
    }

    .loading {
      opacity: 0.7;
      color: var(--gray);
      text-align: right;
      padding: 3% 3% 0 0;
    }

    .error-message {
      text-align: center;
      padding: 48% 1em 0 1em;
      font-size: 2.5em;
    }

    .retry-in {
      text-align: center;
      padding-top: 2em;
    }
  }
}

</style>
