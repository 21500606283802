<script>
import Log from 'services/log.js'
import Spinner from '../common/Spinner.vue'
import AppControl from 'services/appcontrol.js'
import Environment from 'services/environment'
import { mapGetters } from 'vuex'

const STARTUP_TIMEOUT = 1000 * 30 // 30s

const RESOURCES_BASE = Environment.resourcesURL()

export default {
  name: 'Bumper',

  components: {
    Spinner
  },

  data () {
    return {
      startupTimeout: undefined,
      showSpinner: false // Temporarily disabled due to graphical glitch
    }
  },

  computed: {
    ...mapGetters({
      whiteLabelledLogo: 'whitelabel/logo'
    }),

    skipVideo () {
      // Skip TTV bumper video when a whitelabelled logo is set
      return this.whiteLabelledLogo
    },

    videoSrc () {
      return `${RESOURCES_BASE}/videos/telemetry-bumper.mp4`
    }
  },

  mounted () {
    clearTimeout(this.startupTimeout)

    // Once this component is mounted in the dom start a time out. If the
    // timeout is exceeded then proceed anyhow
    // just in case the bumper doesnt properly play
    AppControl.listenKeyboard() // Native Apps Skip the Bumper and handle their own keyboard

    if ((Environment.browserName || '').toLowerCase() === 'safari') {
      Log.debug('player', `Skip bumper video for ${Environment.osName} ${Environment.browserName}`, 'DBG_SKIPBUMPER', null, true)
      this.$router.push({
        name: 'starting'
      })
      return
    }

    if (this.skipVideo) {
      this.$router.push({
        name: 'starting'
      })
      return
    }

    clearTimeout(this.startupTimeout)
    this.startupTimeout = setTimeout(() => {
      clearTimeout(this.startupTimeout)
      Log.warn('player', 'Startup 30s bailout timeout', 'WAR_STARTUPTIMEOUT')
      this.$router.push({
        name: 'starting'
      })
    }, STARTUP_TIMEOUT)
  },

  beforeDestroy () {
    clearTimeout(this.startupTimeout)
  },

  methods: {
    errored () {
      // Log.error('player', 'Bumper video error')
      this.$router.push({
        name: 'starting'
      })
    },
    ended () {
      this.$router.push({
        name: 'starting'
      })
    },
    playing () {
      this.showSpinner = false
    }
  }
}
</script>

<template lang="pug">
section.bumper-page
  router-link(to="/starting")
    spinner(v-show="showSpinner")
    video(v-if="!skipVideo" autoplay="true" muted="true" playsinline="true" @ended="ended" @playing="playing" @pause="errored" @error="errored")
      source(:src="videoSrc" type="video/mp4")
      h1 {{ $t('bumper.fallbackTitle') }}
</template>

<style lang="stylus">
section.bumper-page
  // #2B2D2F = The "telemetry-bumper.mp4" video's background color
  background-color: #2B2D2F
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center

  img
    flex: 1 1 0.00001px
    z-index: 100
    margin-top: 30vh
    height: 20vh
    width: 20vh

  video
    flex: 1 1 0.00001px
    width: 100%
</style>
