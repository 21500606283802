<script>
import { mapState } from 'vuex'

import BrowserStorage from 'services/browserstorage.js'
import Env from 'services/environment'
import {
  genPageBackgroundOpts,
  genPlaylistThemeOpts
} from 'services/page-helpers.ts'

import PageItem from 'components/items/Item.vue'
import PlaylistOverlays from 'components/common/PlaylistOverlays.vue'
import AppBackground from 'components/common/AppBackground.vue'
import Background from 'components/common/Background.vue'

const allowedMsgTypes = [
  'ttv-page-item-preview',
  'ttv-playlist-overlays-preview',
  'ttv-page-background-preview'
]

function overrideBodyStyle () {
  document.body.style.backgroundColor = 'transparent'
  document.documentElement.style.backgroundColor = 'transparent'
}

export default {
  name: 'FreeFormItemPreview',

  components: {
    PageItem,
    PlaylistOverlays,
    AppBackground,
    Background
  },

  data () {
    return {
      item: {},
      bridgeFinished: false
    }
  },

  computed: {
    ...mapState({
      apiStatus: state => state.api.apiStatus
    }),
    type () {
      return this.$route.params.type
    },
    itemId () {
      return this.$route.params.itemId
    },
    isPlaylistOverlay () {
      return this.type === 'playlist-overlay'
    },
    isPageBackground () {
      return this.type === 'page-background'
    }
  },

  beforeCreate () {
    BrowserStorage.useSessionStorage()
    Env.previewMode = true
    Env.muteVideos = true
    overrideBodyStyle()
  },

  created () {
    const urlParams = new URLSearchParams(window.location.search)

    const region = urlParams.get('region')
    if (region) {
      Env.overrideRegion = region
    }

    const language = urlParams.get('lang')
    if (language) {
      this.$store.commit('setOverrideLanguage', language)
    }

    const token = urlParams.get('session_token') || urlParams.get('user_token')
    if (token) {
      this.$store.commit('setAuthenticationToken', token)
    }
  },

  mounted () {
    window.addEventListener('message', this.messageHandler, false)
    overrideBodyStyle()
    this.notifyParentWin()
  },

  beforeDestroy () {
    window.removeEventListener('message', this.messageHandler, false)
  },

  methods: {
    updateItemData (data) {
      this.item = data
    },

    updatePageBackground (data) {
      if (data.background) {
        const bgOpts = genPageBackgroundOpts(data)
        this.item = {
          type: 'page-background',
          bgOptions: bgOpts
        }
      } else if (data.theme) {
        const themeOpts = genPlaylistThemeOpts(data.theme)
        this.item = {
          type: 'playlist-theme',
          themeOptions: themeOpts
        }
      } else {
        this.item = {}
      }
    },

    notifyParentWin () {
      if (window.parent) {
        window.parent.postMessage({
          type: 'ttv-page-item-preview-mounted',
          data: {
            itemId: this.itemId,
            ts: Date.now()
          }
        }, Env.userAppURL())
      }
    },

    setBridgeFinished (evt) {
      if (this.bridgeFinished) { return }

      const parentWin = evt.source
      if (parentWin) {
        const msg = {
          type: 'ttv-page-item-bridge-finished',
          data: {
            itemId: this.itemId,
            ts: Date.now()
          }
        }
        parentWin.postMessage(msg, Env.userAppURL())
        this.bridgeFinished = true
      }
    },

    messageHandler (evt) {
      if (!evt || !evt.data || evt.origin !== Env.userAppURL()) { return }

      if (!evt?.data?.type || !allowedMsgTypes.includes(evt.data.type)) {
        return
      }

      this.setBridgeFinished(evt)

      // Only start WebSocket when required
      if (evt.data?.wsRequired && this.apiStatus === 'startup') {
        this.startAPI()
      }

      switch (evt.data.type) {
        case 'ttv-page-item-preview':
        case 'ttv-playlist-overlays-preview':
          this.updateItemData(evt.data.data || {})
          break
        case 'ttv-page-background-preview':
          this.updatePageBackground(evt.data.data || {})
          break
      }
    },

    startAPI () {
      this.$store.dispatch('startAPI')
    }
  }
}
</script>

<template lang="pug">
section.freeform-item-preview
  template(v-if="bridgeFinished")
    //- Page Item
    page-item(v-if="!isPlaylistOverlay && !isPageBackground" :item="item" zone-paddings="0" free-from-item-preview active)

    //- Playlist Overlays
    playlist-overlays(v-if="isPlaylistOverlay" :overlays="item.overlays" :page="item.page" :account="item.account" preview-mode)

    //- Page or Playlist Background
    template(v-if="isPageBackground")
      app-background(v-if="item.type === 'page-background'"
                     :type="item.bgOptions.bgType"
                     :bg-color="item.bgOptions.bgColor"
                     :bg-media="item.bgOptions.bgMedia"
                     :stock-media="item.bgOptions.stockMedia"
                     :unsplash-image="item.bgOptions.unsplashImage"
                     :gradient-overlay="item.bgOptions.gradientOverlay"
                     :zoom-effect="item.bgOptions.zoomEffect"
                     :duration="item.bgOptions.duration"
                     :dark-overlay="item.bgOptions.darkOverlay")
      background(v-if="item.type === 'playlist-theme'"
                 :theme="item.themeOptions")
</template>

<style lang="stylus">
section.freeform-item-preview
  height: 100vh
  width: 100vw
  position: relative

  > .page-item
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
</style>
