<script>
import { mapGetters } from 'vuex'
import Env from 'services/environment'
import Log from 'services/log.js'
import qs from 'qs'
import { url } from 'services/validators.js'

const TTVCAPTURE = 'ttvcapture'
const TTVXFRAMEOPTIONS = 'ttvxframeoptions'
const ZOOMOPTIONS = 'ttvzoom'
const APPENDPARAMS = 'ttvappendparams'
const CROPOPTIONS = 'ttvcrop'

// In seconds
const DEFAULT_CAPTURE_INTERVAL = 86400

export default {
  name: 'MustHaveMenus',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      timestamp: Date.now(),
      electron: Env.electronAPI,
      chromeApp: Env.chromeAPI,
      android: Env.androidAPI,

      options: {},
      imageSrc: '',

      resetting: false,

      refreshInSeconds: +DEFAULT_CAPTURE_INTERVAL,

      captureTimeout: undefined,
      debounceTimer: undefined,
      refreshTimer: undefined,
      reloadTimer: null,

      currentPage: 1,
      nextPageTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'activeToken',
      'hasEnvVar',
      'envStringToObj',
      'getValueFromEnvString',
      'envVars'
    ]),

    // Raw Input
    // Might contains Environment Vars
    rawLocation () {
      return (this.item && (this.item.url || this.item.location)) || ''
    },

    captured () {
      try {
        return this.options.mode === TTVCAPTURE
      } catch (e) {
        return false
      }
    },

    xFrameOptions () {
      if (this.captured) {
        return false
      }

      return (this.options.xFrameOptions === true || (this.options.xFrameOptions || '') === 'true')
    },

    zoomStyle () {
      if (this.captured) { return }
      let zoomScale
      try {
        zoomScale = this.options.zoomScale || 1.0
      } catch (e) {
      }
      if (zoomScale && zoomScale !== 1.0) {
        return {
          transform: `scale(${zoomScale})`,
          transformOriginY: '50% 0'
        }
      }
    },

    appendParams () {
      if (this.captured) { return false }
      return ((this.options.appendParams || '') === 'true')
    },

    iframeSrc () {
      if (this.captured) {
        return ''
      } else if (this.options && this.options.url && !this.isValidURL) {
        Log.error('app', `Webpage: Invalid URL "${this.options.url}"`, 'ERR_WEBPAGEURL', { url: this.options.url }, true)
        return ''
      }
      return this.options.url || ''
    },

    displayURL () {
      if (this.resetting) {
        return ''
      } else {
        if (!this.iframeSrc) {
          return ''
        }
        const url = new URL(this.iframeSrc)
        url.searchParams.delete('page')
        url.searchParams.append('page', this.currentPage)
        url.searchParams.append('ttv-no-cache', new Date().getTime())
        return url.toString()
      }
    },

    mixedContentError () {
      const iframeSrc = this.iframeSrc
      return (iframeSrc && (iframeSrc.indexOf('http://') === 0) && !this.electron && !this.android)
    },

    xFrameOptionsError () {
      return (this.xFrameOptions && !this.electron && !this.chromeApp && !this.android)
    },

    isValidURL () {
      if (this.options && this.options.url && this.options.url.length) {
        if (!this.options.url.startsWith('http://') && !this.options.url.startsWith('https://')) {
          return false
        }

        return url(this.options.url)
      }
      return true
    },

    isNotAllowedURL () {
      // Live
      if (!this.captured) {
        // Should exclude Login etc. infos
        if (this.iframeSrc && this.iframeSrc.length) {
          return this.urlIsNotSecure(this.iframeSrc)
        }
      // Captured Image
      } else {
        if (this.options && this.options.url && this.options.url.length) {
          return this.urlIsNotSecure(this.options.url)
        }
      }
      return false
    },

    hasError () {
      return Boolean(!this.isValidURL || this.isNotAllowedURL || this.mixedContentError || this.xFrameOptionsError)
    },

    showHomeButton () {
      return !this.hasError && (this.options && this.options.showHomeButton)
    },

    siteUnavailableDesc () {
      if (!this.isValidURL) {
        return this.$t('pageItems.common.notValidURL', {url: this.options.url})
      } else if (this.isNotAllowedURL) {
        return this.$t('pageItems.common.notAllowedURL')
      } else if (this.mixedContentError) {
        return this.$t('pageItems.common.mixedContentError')
      } else if (this.xFrameOptionsError) {
        return this.$t('pageItems.common.xFrameOptionsError')
      }
    }
  },

  watch: {
    rawLocation () {
      this.debounceCheckForEnvVars()
      this.updateOptions()
    },
    captured () {
      this.updateCaptureInterval()
    },
    'options.ttl' () {
      this.updateCaptureInterval()
    },
    'options.autoReload' () {
      this.updateWebpageReloadInterval()
    },
    'options.reloadInterval' () {
      this.updateWebpageReloadInterval()
    },
    'options.url' () {
      this.setImageSrc()
      this.handlePageNumbers()
    },
    'options.multiPageMode' () {
      this.handlePageNumbers()
      // this.updateWebpageReloadInterval()
    },
    'options.onePageDuration' () {
      this.handlePageNumbers()
    },
    'options.pageToShow' () {
      this.handlePageNumbers()
    },
    'options.numberOfPages' () {
      this.handlePageNumbers()
    },
    envVars () {
      if (this.envVars && this.envVars.length) {
        this.updateOptions()
      }
    }
  },

  mounted () {
    clearTimeout(this.captureTimeout)
    clearTimeout(this.debounceTimer)
    clearInterval(this.refreshTimer)
    clearInterval(this.reloadTimer)

    this.updateOptions()

    this.checkForEnvVars()

    if (this.captured) {
      this.loadImage()
      this.updateCaptureInterval()
    } else {
      this.updateWebpageReloadInterval()
    }
  },

  beforeDestroy () {
    clearTimeout(this.captureTimeout)
    clearTimeout(this.debounceTimer)
    clearInterval(this.refreshTimer)
    clearInterval(this.reloadTimer)
    clearInterval(this.nextPageTimer)
  },

  methods: {
    iframeLoaded () {
      this.$emit('loaded')

      // NOTE: For local debugging only
      // if (this.$refs.iframe.contentWindow) {
      //   this.$refs.iframe.openDevTools()
      // }
      // / EOF Debugging
    },

    // Transcript params - for Capture mode
    // For location URL with Env Var
    transcriptedUrl (baseUrl, optsUrl) {
      if (!baseUrl) { return }
      if (!optsUrl) { return baseUrl }

      const optsUrlSearchParams = this.parse(optsUrl.search)
      const baseUrlSearchParams = this.parse(baseUrl.search)
      // Has Capture options
      if (optsUrlSearchParams[TTVCAPTURE] === 'true') {
        // Capture Mode Flag
        baseUrlSearchParams[TTVCAPTURE] = 'true'
        // Size
        baseUrlSearchParams[`${TTVCAPTURE}size`] = optsUrlSearchParams[`${TTVCAPTURE}size`]
        // TTL
        baseUrlSearchParams[`${TTVCAPTURE}ttl`] = optsUrlSearchParams[`${TTVCAPTURE}ttl`]
        // Delay
        baseUrlSearchParams[`${TTVCAPTURE}delay`] = optsUrlSearchParams[`${TTVCAPTURE}delay`]
      } else {
        if (optsUrlSearchParams[`${ZOOMOPTIONS}scale`]) {
          baseUrlSearchParams[`${ZOOMOPTIONS}scale`] = optsUrlSearchParams[`${ZOOMOPTIONS}scale`]
        }
      }

      baseUrl.search = this.stringify(baseUrlSearchParams)

      return baseUrl
    },

    setImageSrc () {
      clearTimeout(this.captureTimeout)

      if (!this.captured) {
        this.imageSrc = ''
        return
      }

      const size = this.options.size || ''
      const ttl = this.options.ttl || ''
      const delay = parseInt(this.options.delay, 10) || 0
      const startX = this.options.startX || 0
      const startY = this.options.startY || 0
      const width = this.options.width || 0
      const height = this.options.height || 0
      const cropWidth = this.options.areaWidth || 0
      const cropHeight = this.options.areaHeight || 0
      const scrollX = this.options.scrollX || 0
      const scrollY = this.options.scrollY || 0

      if (this.urlIsNotSecure(this.options.url)) {
        this.imageSrc = ''
        return
      }

      let src = `${Env.captureURL()}/webpage_capture?url=${encodeURIComponent(this.options.url)}&token=${this.activeToken}`

      if (size.toUpperCase() === 'HDTV') {
        src = `${src}&width=1920&height=1080`
      } else if (size.toUpperCase() === '4K') {
        src = `${src}&width=3840&height=2160`
      } else if (size.toUpperCase() === 'PORTRAIT') {
        src = `${src}&width=1080&height=1920`
      }

      let ttlSeconds = 0
      if (ttl.toUpperCase() === 'MINUTE') {
        ttlSeconds = 300
      } else if (ttl.toUpperCase() === 'HOUR') {
        ttlSeconds = 3600
      } else if (ttl.toUpperCase() === 'WEEK') {
        ttlSeconds = 604800
      } else if (ttl.toUpperCase() === 'MONTH') {
        ttlSeconds = 2592000
      } else {
        ttlSeconds = 86400
      }
      src = `${src}&ttl=${ttlSeconds}`

      if (delay > 0) {
        src = `${src}&delay=${delay}`
      }

      if (startX || startY || width || height || cropWidth || cropHeight || scrollX || scrollY) {
        src = `${src}&startX=${startX}&startY=${startY}&width=${width}&height=${height}&areaWidth=${cropWidth}&areaHeight=${cropHeight}&scrollX=${scrollX}&scrollY=${scrollY}`
      }

      const timestamp = Math.floor(this.timestamp / 1000 / ttlSeconds) * ttlSeconds
      src = `${src}&${timestamp}`

      // Substract 5 seconds because code block needs to be completed before next cycle
      clearTimeout(this.captureTimeout)
      this.captureTimeout = setTimeout(() => {
        clearTimeout(this.captureTimeout)
        this.timestamp = Date.now()
      }, (ttlSeconds - 5) * 1000)

      this.imageSrc = src
    },

    updateRefreshInSeconds () {
      let ttlSeconds = +DEFAULT_CAPTURE_INTERVAL
      try {
        const ttl = this.options.ttl || ''
        if (ttl.toUpperCase() === 'MINUTE') {
          // 5 minutes
          ttlSeconds = 300
        } else if (ttl.toUpperCase() === 'HOUR') {
          ttlSeconds = 3600
        } else if (ttl.toUpperCase() === 'WEEK') {
          ttlSeconds = 604800
        } else if (ttl.toUpperCase() === 'MONTH') {
          // NOTE: Must set to 3 weeks (21d) here
          // Because of the maximum supporte value for setInterval is `2147483647` ms (< 25d)
          // Any value larger than this (2^31 -1) value will cause instant and infinte loop
          ttlSeconds = 1814400
        }
      } catch (e) {
        // Do nothing, will fallback to default value
      }
      this.refreshInSeconds = ttlSeconds
    },

    updateCaptureInterval () {
      clearInterval(this.refreshTimer)
      if (!this.captured) { return }

      this.updateRefreshInSeconds()

      this.refreshTimer = setInterval(() => {
        this.setImageSrc()
      }, this.refreshInSeconds * 1000)
    },

    updateWebpageReloadInterval () {
      const { autoReload, reloadInterval } = this.options
      if (!autoReload) {
        clearInterval(this.reloadTimer)
        this.reloadTimer = null
        return
      }
      clearInterval(this.reloadTimer)
      this.reloadTimer = setInterval(() => this.backToHome(), reloadInterval * 1000)
    },

    checkForEnvVars () {
      if (
        this.hasEnvVar(this.options.url)
      ) {
        this.$emit('has-env-var', true)
      } else {
        this.$emit('has-env-var', false)
      }
    },

    debounceCheckForEnvVars () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkForEnvVars()
      }, 200)
    },

    loadImage () {
      const url = this.setImageSrc()
      if (url && url.length) {
        const img = new Image()
        img.onload = () => {
          this.$emit('loaded')
        }
        img.src = url
      } else {
        this.$emit('loaded')
      }
    },

    // Prevent localhost or TelemetryTV website
    urlIsNotSecure (url = '') {
      return Boolean(
        url.indexOf('telemetrytv.com') >= 0 ||
        url.indexOf('localhost') >= 0 ||
        url.indexOf('127.0.0.1') >= 0
      )
    },

    parse (search) {
      return qs.parse(search, {
        ignoreQueryPrefix: true,
        decoder: (s) => s
      })
    },

    stringify (obj, addQueryPrefix = true) {
      return qs.stringify(obj, {
        encode: false,
        addQueryPrefix: addQueryPrefix,
        strictNullHandling: true
      })
    },

    cleanUpSearchParams (search) {
      const sourceParams = this.parse(search)

      delete sourceParams[TTVCAPTURE]
      delete sourceParams[`${TTVCAPTURE}size`]
      delete sourceParams[`${TTVCAPTURE}ttl`]
      delete sourceParams[`${TTVCAPTURE}delay`]
      delete sourceParams[TTVXFRAMEOPTIONS]
      delete sourceParams[`${ZOOMOPTIONS}scale`]
      delete sourceParams[APPENDPARAMS]
      delete sourceParams[`${CROPOPTIONS}startX`]
      delete sourceParams[`${CROPOPTIONS}startY`]
      delete sourceParams[`${CROPOPTIONS}width`]
      delete sourceParams[`${CROPOPTIONS}height`]
      delete sourceParams[`${CROPOPTIONS}areaWidth`]
      delete sourceParams[`${CROPOPTIONS}areaHeight`]
      delete sourceParams[`${CROPOPTIONS}scrollX`]
      delete sourceParams[`${CROPOPTIONS}scrollY`]

      return sourceParams
    },

    readParams (sourceParams, options) {
      if (sourceParams && sourceParams[TTVCAPTURE] === 'true') {
        options.mode = TTVCAPTURE
        options.size = sourceParams[`${TTVCAPTURE}size`]
        options.ttl = sourceParams[`${TTVCAPTURE}ttl`]
        options.delay = sourceParams[`${TTVCAPTURE}delay`]

        options.startX = sourceParams && (sourceParams[`${CROPOPTIONS}startX`] || 0)
        options.startY = sourceParams && (sourceParams[`${CROPOPTIONS}startY`] || 0)
        options.width = sourceParams && (sourceParams[`${CROPOPTIONS}width`] || 0)
        options.height = sourceParams && (sourceParams[`${CROPOPTIONS}height`] || 0)
        options.areaWidth = sourceParams && (sourceParams[`${CROPOPTIONS}areaWidth`] || 0)
        options.areaHeight = sourceParams && (sourceParams[`${CROPOPTIONS}areaHeight`] || 0)
        options.scrollX = sourceParams && (sourceParams[`${CROPOPTIONS}scrollX`] || 0)
        options.scrollY = sourceParams && (sourceParams[`${CROPOPTIONS}scrollY`] || 0)
      } else {
        options.mode = ''
        const zoomScale = (sourceParams && sourceParams[`${ZOOMOPTIONS}scale`]) || 1.0
        options.zoomScale = +zoomScale
        options.appendParams = Boolean(sourceParams && sourceParams[APPENDPARAMS] === 'true')
      }
      options.xFrameOptions = sourceParams && sourceParams[TTVXFRAMEOPTIONS]
    },

    updateOptions () {
      const argumentProps = qs.parse(this.rawLocation)
      if (argumentProps['url']) {
        this.options = {}
        Object.keys(argumentProps).forEach(prop => {
          let value = argumentProps[prop]
          if (value === undefined || value === null) { return }

          if (prop === 'url') {
            if (this.hasEnvVar(value)) {
              value = this.location(value).toString()
            }
          }

          if (prop === 'showHomeButton' || prop === 'autoReload' || prop === 'multiPageMode') {
            value = (value === true || value === 'true')
          }

          if (prop === 'reloadInterval') {
            value = +value
          }

          if (prop === TTVXFRAMEOPTIONS) {
            value = (value === true || value === 'true')
            this.$set(this.options, 'xFrameOptions', value)
            return
          }

          this.$set(this.options, prop, value)
        })
      } else {
        const url = this.location(this.rawLocation)
        const options = {}

        if (url) {
          this.readParams(qs.parse(url.search, { ignoreQueryPrefix: true }), options)
          url.search = this.stringify(this.cleanUpSearchParams(url.search))
          options['url'] = decodeURIComponent(url.toString())
        }
        this.options = options
      }
    },

    location (url) {
      const urlBase = this.getValueFromEnvString(url)

      if (!urlBase) { return '' }

      let targetUrl
      try {
        targetUrl = new URL(urlBase)
      } catch (e) {
        // Catch errors when the defined Env Var is not a valid URL
        return ''
      }

      const envObj = this.envStringToObj(url)
      const urlOptsStr = envObj.fallbackInput || envObj.manualInput

      let urlWithOption
      try {
        const optUrl = new URL(urlOptsStr)
        urlWithOption = this.transcriptedUrl(targetUrl, optUrl)
      } catch (e) {
        return targetUrl
      }

      return urlWithOption
    },

    backToHome () {
      // Reset iframe src back to the origin URL
      this.resetting = true
      this.$nextTick(() => {
        setTimeout(() => this.resetting = false, 500)
      })
    },

    handlePageNumbers () {
      clearInterval(this.nextPageTimer)
      const url = new URL(this.options.url || '')
      if (this.options.multiPageMode) {
        const numberOfPages = this.options.numberOfPages || 1
        const onePageDuration = (this.options.onePageDuration || 10) * 1000
        this.currentPage = 1
        this.nextPageTimer = setInterval(() => {
          if (this.currentPage == numberOfPages) {
            this.currentPage = 1
          } else {
            this.currentPage++
          }
        }, onePageDuration)
        return
      }
      this.currentPage = this.options.pageToShow || 1
    }
  }
}
</script>

<template lang="pug">
section.web-page
  template(v-if="captured")
    .captured
      .note(v-if="isNotAllowedURL")
        p {{ $t('pageItems.common.notAllowedURL') }}
      img(v-else :src="imageSrc")
  template(v-else)
    .unavailable(v-if="hasError")
      p {{ $t('pageItems.common.unavailableTitle') }}
      h3 {{ $t('pageItems.common.unavailableText') }}
      p(v-html="siteUnavailableDesc")

    template(v-else)
      webview.webpage-frame.is-webview(v-if="electron"
              :src="displayURL"
              :style="zoomStyle"
              @did-stop-loading="iframeLoaded"
              disablewebsecurity
              webpreferences="allowRunningInsecureContent"
              ref="iframe")
      iframe.webpage-frame(v-if="!electron"
             :src="displayURL"
             :style="zoomStyle"
             frame-border="0"
             sandbox="allow-scripts allow-same-origin allow-presentation allow-forms"
             allow="autoplay; fullscreen; camera; microphone"
             allowfullscreen
             seamless
             @load="iframeLoaded"
             ref="iframe")

</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.web-page
  width: 100%
  height: 100%
  border: 0
  background: #fff
  position: relative

  .webpage-frame
    position: relative
    width: 100%
    height: 100%
    border: 0
    z-index: 1

    &.is-webview
      display: inline-flex

  .home-button
    position: absolute
    z-index: 999
    bottom: 1.5vmin
    left: 1.5vmin
    outline: 0

    background: #111
    color: #ddd

    border-radius: 50%
    width: 6vmin
    height: 6vmin
    cursor: pointer

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    transition: opacity .15s

    appSmallBoxShadow()

    > .fa-icon
      font-size: 2.5vmin
      margin-top: -0.05em

    &:active
      opacity: 0.7
      outline: 0
      -webkit-tap-highlight-color: transparent

  .captured
    width: 100%
    height: 100%
    border: 0
    position: relative
    .note
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      border: 0
      font-size: 2em
      display: flex
      justify-content: center
      align-items: center
      background: black
      color: white
    img
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      border: 0
      width: 100%
      height: 100%

  .unavailable
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    border: 0
    font-size: 2em
    padding: 1em 3em

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center

    background: black
    color: white
</style>
