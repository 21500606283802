<script>
import AnimateValue from './AnimateValue.vue'

export default {
  name: 'BarchartBar',
  components: { AnimateValue },

  props: {
    min: { type: [Number, String] },
    max: { type: [Number, String] },
    value: { type: [Number, String] },
    color: { type: String },

    // To prevent animation on every paginate
    isStatic: { type: Boolean, default: false },

    // App Background Theme
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      currentValue: 0
    }
  },

  computed: {
    nomralizedValue () {
      return +this.value || 0
    },

    normalizedMin () {
      return +this.min || 0
    },

    normalizedMax () {
      return +this.max || 0
    },

    valueBarWidth () {
      const targetValue = this.isStatic ? this.nomralizedValue : this.currentValue
      const percent = (Number(targetValue) - this.normalizedMin) / (this.normalizedMax - this.normalizedMin)
      return {
        width: Math.max(0, Math.min(100, percent * 100)) + '%'
      }
    },

    valueBarColor () {
      if (!this.color) { return }
      return {
        backgroundColor: this.color
      }
    },

    bgBarColor () {
      if (!this.color) { return }
      return {
        backgroundColor: this.color,
        opacity: 0.2
      }
    }
  },

  methods: {
    updateCurrentValue (newValue) {
      this.currentValue = newValue
    }
  }
}
</script>

<template lang="pug">
.barchart-single-bar(:class="{'light-theme': lightTheme}")
  .bar-bg(:style="bgBarColor")
  .bar-value(:style="[valueBarWidth, valueBarColor]")
  animate-value(v-if="!isStatic", :target-value="nomralizedValue" @update="updateCurrentValue")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.barchart-single-bar
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  .bar-bg,
  .bar-value
    position: absolute
    top: 0
    left: 0
    bottom: 0

  .bar-bg
    right: 0
    background-color: -white(0.1)

  .bar-value
    background-color: #fff

  //==============
  // LIGHT THEME
  //==============
  &.light-theme
    .bar-bg
      background-color: -black(0.1)

    .bar-value
      background-color: $appDarkTextColor
</style>
