<script>
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import AllergenIcon from './AllergenIcon'

import NumberHelper from 'services/number-helper.js'

const config = {
  // In seconds. Paginate to next menu if there's more than one valid menu in request
  PAGINATE_INTERVAL: 20,

  // in seconds. Default paginate interval for recipe list
  MIN_PAGE_INTERVAL: 5,

  // in ms
  SHOW_NEXT_DELAY: 800,

  // in ms
  ITEM_STAGGER: 0
}

export default {
  name: 'RecipeList',
  components: {
    AllergenIcon
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    showAllergies: {
      type: Boolean,
      default: false
    },
    nutritionalInfo: {
      type: Array,
      default: () => []
    },

    // Per Screen Paginate interval
    interval: {
      type: Number,
      default: 20
    },

    // Skip Per Category animation - for Per screen layout measurement
    skipAnimation: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      list: [],

      currentFirstIndex: 0,
      currentLastIndex: 0,
      currentChunk: [],
      resizing: true,

      // For height calculation
      metricChunk: [],

      maxChunkLen: 0,

      debounceTimer: undefined,
      delayTimer: undefined,
      expireTimer: undefined,
      measureTimer: undefined
    }
  },

  computed: {
    needsPaginate () {
      if (!this.list || !this.list.length || !this.currentChunk || !this.currentChunk) { return false }
      return this.currentChunk.length < this.list.length
    },

    listInterval () {
      if (this.skipAnimation || !this.needsPaginate) { return }
      const appoxPageCount = Math.ceil(this.list.length / (this.maxChunkLen || 1))
      const perPageInterval = (this.interval || config.PAGINATE_INTERVAL) / (appoxPageCount || 1)
      return perPageInterval >= config.MIN_PAGE_INTERVAL ? perPageInterval : config.MIN_PAGE_INTERVAL
    }
  },

  watch: {
    items: {
      deep: true,
      handler (newValue) {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)
  },

  methods: {
    render () {
      const items = JSON.parse(JSON.stringify(this.items || []))

      items.forEach((item, index) => {
        item.key = `menu-item-${index}`
      })

      this.list = items

      if (this.resizing || this.skipAnimation) { return }

      this.getNewChunk()
    },

    nutrientsInfo (nutrients) {
      if (!nutrients || !nutrients.length) { return }
      const targetItems = nutrients.filter(item => this.nutritionalInfo.includes(item.Name))
      if (targetItems && targetItems.length) {
        let result = ''
        targetItems.forEach((item, index) => {
          if (index > 0) {
            result += ', '
          }
          result += `${NumberHelper.numberToFixed(item.Value || 0)}${item.Unit || ''} ${item.Name}`
        })
        return result.trim()
      }
    },

    getNewChunk (startIndex) {
      if (this.resizing) { return }

      this.currentFirstIndex = startIndex || 0
      this.currentLastIndex = startIndex || 0
      this.metricChunk = []

      this.addSingleItem()
    },

    addSingleItem () {
      clearTimeout(this.measureTimer)

      if (!this.list || !this.list.length || this.resizing || !this.list[this.currentLastIndex]) { return }

      const item = JSON.parse(JSON.stringify(this.list[this.currentLastIndex]))
      this.metricChunk.push(item)

      clearTimeout(this.measureTimer)
      this.measureTimer = setTimeout(() => {
        clearTimeout(this.measureTimer)
        this.checkAvailableHeight()
      }, 50)
    },

    playNext () {
      clearTimeout(this.delayTimer)
      let remainItems = this.currentChunk.length
      this.currentChunk = []
      this.currentFirstIndex = this.currentLastIndex >= this.list.length - 1 ? 0 : this.currentLastIndex + 1

      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.getNewChunk(this.currentFirstIndex)
      }, (remainItems * config.ITEM_STAGGER) + config.SHOW_NEXT_DELAY)
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.resizing = true
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizing = false
        this.render()
      }, timeout || 200)
    },

    renderList () {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      const list = JSON.parse(JSON.stringify(this.list))

      // Skip animation when there's only one page
      if (this.metricChunk.length === list.length) {
        this.currentChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)
      } else {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer)
          this.currentChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)
          clearTimeout(this.expireTimer)
          this.expireTimer = setTimeout(() => {
            clearTimeout(this.expireTimer)
            this.playNext()
          }, (this.listInterval || config.MIN_PAGE_INTERVAL) * 1000)
        }, 300)
      }
      if (!this.skipAnimation) {
        this.$emit('rendered')
      }
    },

    checkAvailableHeight () {
      if (!this.$refs || !this.$refs.measure || !this.$refs.sensor || this.resizing) { return }

      let availableHeight
      let sensorOffsets
      let measureOffsetHeight
      let measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!this.$refs || !this.$refs.measure || !this.$refs.sensor) {
          FastDom.clear(measure)
          return
        }

        sensorOffsets = this.$refs.sensor.getBoundingClientRect()
        measureOffsetHeight = this.$refs.measure.getBoundingClientRect().height

        let mutate = FastDom.mutate(() => {
          availableHeight = sensorOffsets.height - measureOffsetHeight

          if (availableHeight > 0 && this.currentLastIndex < this.list.length - 1) {
            this.currentLastIndex++
            this.addSingleItem()
          } else if (availableHeight < 0) {
            this.currentLastIndex--
            this.metricChunk.pop()
            this.maxChunkLen = Math.max(this.maxChunkLen, this.metricChunk.length)
            this.renderList()
          } else {
            this.maxChunkLen = Math.max(this.maxChunkLen, this.metricChunk.length)
            this.renderList()
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    }
  }
}
</script>
<template lang="pug">
mixin liItemContent
  .text-infos
    .item-name {{ item.RecipeName }}
    .nutrients(v-if="nutritionalInfo && nutritionalInfo.length") {{ nutrientsInfo(item.Nutrients) }}
  .allergies(v-if="showAllergies && item.Allergiens && item.Allergiens.length")
    template(v-for="(algItem, index) in item.Allergiens")
      allergen-icon.allergien(:key="index", :item="algItem")

.menu-recipe-list
  .resize-sensor(ref="sensor")

  //- Real list
  transition-group(v-if="!skipAnimation"
                   tag="ul" class="category-items visible-list"
                   name="page-fade")
    li(v-for="(item, index) in currentChunk", :key="item.key", :data-index="index")
      +liItemContent

  //- For height calculation
  ul.category-items.measure(ref="measure")
    li(v-for="item in metricChunk")
      +liItemContent

  //- Placeholder
  ul.category-items.invisible
    li(v-for="item in list")
      +liItemContent

</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.menu-recipe-list
  overflow: hidden
  font-size: 1em
  max-height: 100%
  position: relative

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  ul.category-items
    margin: 0
    padding: 0
    position: absolute
    top: 0
    left: 0
    right: 0
    
    &.invisible
    &.measure
      visibility: hidden
      opacity: 0

    &.invisible
      position: relative
      z-index: -2

    &.measure
      z-index: -1

    &.visible-list
      z-index: 1

  li
    list-style: none
    overflow: hidden
    padding-top: 0.5em

    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center

    &:first-of-type
      padding-top: 0

    .text-infos
      flex: 1 1 0.000001px
      line-height: 130%
      overflow: hidden

      .item-name
        line-height: 150%

    .allergies
      &:not(:empty)
        padding-left: 1em

      .allergien
        font-size: 1.5em
        margin-left: 0.3em
        &:first-of-type
          margin-left: 0

    .item-name
      ellipsis()

    .nutrients
      opacity: 0.6
      font-size: 0.8em
      ellipsis()

</style>
