<script>
import { positionDefault, iconDefault } from 'services/free-form'

export default {
  name: 'ObjectIcon',

  props: {
    icon: {
      type: String,
      required: true
    },
    x: {
      type: Number,
      default: positionDefault.x,
      validator: value => value >= 0 && value <= 100
    },
    y: {
      type: Number,
      default: positionDefault.y,
      validator: value => value >= 0 && value <= 100
    },
    size: {
      type: Number,
      default: iconDefault.size,
      validator: value => value >= 0 && value <= 100
    },
    rotate: {
      type: Number,
      default: positionDefault.rotate,
      validator: value => value >= 0 && value <= 360
    },
    fillColor: {
      type: String,
      default: iconDefault.fillColor
    },
    opacity: {
      type: Number,
      default: iconDefault.opacity,
      validator: value => value >= 0 && value <= 1
    }
  },

  computed: {
    displayIcon () {
      const [prefix, iconName] = this.icon.split('|')
      if (!prefix || !iconName) {
        return ''
      } else if (prefix && !iconName) {
        return ['fas', prefix]
      }
      return [prefix, iconName]
    },

    positionStyle () {
      return {
        width: `${this.size}%`,
        height: 'auto',
        aspectRatio: '1 / 1',
        left: `${this.x}%`,
        top: `${this.y}%`
      }
    },

    colorStyle () {
      return {
        color: this.fillColor,
        opacity: this.opacity
      }
    }
  }
}
</script>

<template lang="pug">
.object-icon(:style="[positionStyle, colorStyle]")
  fa.fa-icon(:icon="displayIcon" fixed-width)
</template>

<style lang="stylus">
.object-icon
  > svg.fa-icon
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
</style>
