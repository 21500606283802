<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 1000
}

export default {
  name: 'QuotePage',
  components: {
    ResponsiveMarkupText
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      quotes: [],
      interval: 10,
      transition: '',

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      currentIndex: -1,

      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined,

      fixedFontSize: false
    }
  },

  computed: {
    ...mapGetters([
      'pageItemTransition'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    validQuotes () {
      if (!this.quotes || !this.quotes.length) { return [] }
      return this.quotes.filter(item => {
        // Display quotes with valid Name and Quote Message
        return (item.name || '').trim().length &&
               (item.message || '').trim().length
      })
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.quotes = options.quotes || []
      this.interval = Math.max(4, options.interval || 10)
      this.transition = options.transition || ''
      this.fixedFontSize = (options.fixedFontSize === true || options.fixedFontSize === 'true')

      this.prepareNext(true)
    },

    prepareNext (isUpdate) {
      clearTimeout(this.showNextTimer)

      if (!this.validQuotes || !this.validQuotes.length) { return }

      // Force Reset
      if (isUpdate) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.validQuotes.length
      const nextItem = JSON.parse(JSON.stringify(this.validQuotes[nextIndex]))

      if (this.validQuotes.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      // Is first init
      if (this.currentIndex < 0) {
        this.showNext(nextIndex)
      } else if (this.validQuotes.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.validQuotes.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext (nextIndex) {
      clearTimeout(this.prepareTimer)
      const activeIndex = +this.currentIndex

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (!this.validQuotes.length) {
        return
      } else if (this.validQuotes.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.validQuotes.length && activeIndex >= this.validQuotes.length - 1) {
        this.$emit('finished')
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, (this.interval || 10) * 1000 - config.PREPARE_TIME)
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    },

    formatedQuote (message) {
      const text = `<p>${message || ''}</p>`
      return text.replace(/\n/g, '</p><p>')
    }
  }
}
</script>

<template lang="pug">
section.quote-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait}]"
                    :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .quotes-container.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
                                      :style="boxMarginStyle")
    .main-section.app-context-section.primary
      //- Prime
      transition(:name="transitionName", :duration="500" appear)
        .section-wrapper.prime(v-show="showPrime")
          template(v-if="primeItem")
            .quote-message
              .quote-mark
                fa.quote-icon(icon="quote-left" fixed-width)
              .message-block
                div(
                    v-if="fixedFontSize"
                    :style="scaledFontSizeStyle")
                  .wysiwyg-block(v-html="(primeItem.message) || ''")
                responsive-markup-text(
                    v-else
                    :text="formatedQuote(primeItem.message)"
                    :base-font-size="baseFontSize"
                    :font-class="fontClass")
            .user-info
              .user-pic(v-if="primeItem.image", :style="{'background-image': 'url(' + primeItem.image + ')'}")
              .text-info
                .display-name {{ primeItem.name }}
                .display-title(v-if="primeItem.title") {{ primeItem.title }}

      //- Base
      transition(:name="transitionName", :duration="500" appear)
        .section-wrapper.base(v-show="!showPrime")
          template(v-if="baseItem")
            .quote-message
              .quote-mark
                fa.quote-icon(icon="quote-left" fixed-width)
              .message-block
                div(
                    v-if="fixedFontSize"
                    :style="scaledFontSizeStyle")
                  .wysiwyg-block(v-html="(baseItem.message) || ''")
                responsive-markup-text(
                    v-else
                    :text="formatedQuote(baseItem.message)"
                    :base-font-size="baseFontSize"
                    :font-class="fontClass")
            .user-info
              .user-pic(v-if="baseItem.image", :style="{'background-image': 'url(' + baseItem.image + ')'}")
              .text-info
                .display-name {{ baseItem.name }}
                .display-title(v-if="baseItem.title") {{ baseItem.title }}

</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.quote-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .quotes-container
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: stretch

    .main-section
      flex: 1 1 0.00001px
      position: relative
      overflow: hidden

    .section-wrapper
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      padding: 1em 1em 1em 0.8em

      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: stretch

      // Must be equal to the duration set in the Vue `<transition>` component
      animation-duration: 0.5s

      .quote-message
        flex: 1 1 0.00001px
        overflow: hidden
        display: flex
        flex-flow: row nowrap
        justify-content: flex-start
        align-items: stretch
        padding-top: 0.8em

        .quote-mark
          margin-right: 1em
          margin-top: -0.7em

          .quote-icon
            font-size: 2.5em
            opacity: 0.3

        .message-block
          display: flex
          flex: 1 1 0.00001px
          position: relative

      .user-info
        display: flex
        flex-flow: row nowrap
        justify-content: flex-end
        align-items: center
        padding-top: 0.8em

        .user-pic
          width: 2.5em
          height: 2.5em
          border-radius: 6em
          margin-right: 0.6em
          background-repeat: no-repeat
          background-position: 50% 50%
          background-size: cover

        .display-name
          font-weight: bold
          line-height: 120%

        .display-title
          font-size: 0.6em
          opacity: 0.7
          line-height: 110%
          padding-top: 0.3em

  //
  // Portrait Layout
  //
  &.is-portrait
    .quotes-container
      .section-wrapper
        padding: 1em 1.5em
        .quote-message
          padding-top: 0
          flex-flow: column nowrap
          .quote-mark
            margin: 0 0 1em -.3em
</style>
