<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'

import MetricsBarchart from './metrics/Barchart.vue'
import MetricsGauge from './metrics/Gauge.vue'
import MetricsLeaderboard from './metrics/Leaderboard.vue'
import MetricsLinechart from './metrics/Linechart.vue'
import MetricsNumber from './metrics/Number.vue'
import MetricsPiechart from './metrics/Piechart.vue'
import MetricsTable from './metrics/Table.vue'
import MetricsText from './metrics/Text.vue'

import NumberHelper from 'services/number-helper.js'

const config = {
  // In vmin
  BASE_FONTSIZE: 3
}

export default {
  name: 'MetricsApps',
  components: {
    MetricsBarchart,
    MetricsGauge,
    MetricsLeaderboard,
    MetricsLinechart,
    MetricsNumber,
    MetricsPiechart,
    MetricsTable,
    MetricsText
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      items: [],
      interval: 10,
      transition: '',
      removeTitle: false,
      fontOutline: false,
      unitFontScale: 1.0,
      vAlign: 'middle',
      hAlign: 'left'
    }
  },

  computed: {
    configBaseFontSize () {
      return config.BASE_FONTSIZE
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      // Normalize Items
      this.renderItems(options.items)

      this.interval = +options.interval || 10
      this.transition = options.transition || ''

      this.removeTitle = (options.removeTitle === true || options.removeTitle === 'true')
      this.fontOutline = (options.fontOutline === true || options.fontOutline === 'true')

      this.unitFontScale = +options.unitFontScale || 1.0

      // Text && Number
      this.vAlign = options.vAlign || 'middle'
      this.hAlign = options.hAlign || 'left'
    },

    renderItems (items) {
      if (!items || !Array.isArray(items) || !items.length) {
        this.items = []
        return
      }

      // Transform strings to numeric and boolean value
      const nomalizeMetrics = function nomalizeMetrics (metric) {
        if (NumberHelper.isNumber(metric.bucket_size)) {
          metric.bucket_size = +metric.bucket_size
        }
        if (NumberHelper.isNumber(metric.date_range)) {
          metric.date_range = +metric.date_range
        }
        if (NumberHelper.isNumber(metric.default_range)) {
          metric.default_range = +metric.default_range
        }
        if (metric.series === 'false') {
          metric.series = false
        }
      }

      const normalizedItems = JSON.parse(JSON.stringify(items))
      normalizedItems.forEach((item) => {
        if (item.metric && item.metric.ref && item.metric.ref.length) {
          nomalizeMetrics(item.metric)
          return
        }

        const subItem = item.slices || item.series
        if (!Array.isArray(subItem) || !subItem.length) { return }
        subItem.forEach((sbItem) => {
          if (sbItem.metric && sbItem.metric.ref && sbItem.metric.ref.length) {
            nomalizeMetrics(sbItem.metric)
          }
        })
        if (item.slices) { item.slices = subItem }
        if (item.series) { item.series = subItem }
      })

      this.items = normalizedItems
    }
  }
}
</script>

<template lang="pug">
section.metrics-app(:style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .metrics-app-item.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]")

    .main-section(:style="scaledFontSizeStyle")
      metrics-barchart(v-if="item.type === 'metrics-barchart'"
                      :items="items"
                      :interval="interval"
                      :remove-title="removeTitle"
                      :transition="transition"
                      :light-theme="!whiteText")

      metrics-gauge(v-if="item.type === 'metrics-gauge'"
                    :items="items"
                    :interval="interval"
                    :remove-title="removeTitle"
                    :transition="transition"
                    :light-theme="!whiteText")

      metrics-leaderboard(v-if="item.type === 'metrics-leaderboard'"
                          :items="items"
                          :interval="interval"
                          :remove-title="removeTitle"
                          :transition="transition")

      metrics-linechart(v-if="item.type === 'metrics-linechart'"
                        :items="items"
                        :interval="interval"
                        :remove-title="removeTitle"
                        :transition="transition"
                        :light-theme="!whiteText"
                        nested-data)

      metrics-number(v-if="item.type === 'metrics-number'"
                     :items="items"
                     :interval="interval"
                     :font-outline="fontOutline"
                     :unitFontScale="unitFontScale"
                     :remove-title="removeTitle"
                     :transition="transition"
                     :light-theme="!whiteText"
                     :v-align="vAlign"
                     :h-align="hAlign")

      metrics-piechart(v-if="item.type === 'metrics-piechart'"
                      :items="items"
                      :interval="interval"
                      :remove-title="removeTitle"
                      :transition="transition"
                      nested-data)

      metrics-table(v-if="item.type === 'metrics-table'"
                   :items="items"
                   :interval="interval"
                   :remove-title="removeTitle"
                   :transition="transition")

      metrics-text(v-if="item.type === 'metrics-text'"
                   :items="items"
                   :interval="interval"
                   :font-outline="fontOutline"
                   :remove-title="removeTitle"
                   :transition="transition"
                   :v-align="vAlign"
                   :h-align="hAlign")
</template>

<style lang="stylus">
section.metrics-app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .metrics-app-item
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: stretch

    .main-section
      flex: 1 1 0.00001px
</style>
