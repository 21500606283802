<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import FastDom from 'fastdom'
import {
  mapGetters
} from 'vuex'

import InteractionZone from 'components/common/InteractionZone.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 1000
}

export default {
  name: 'NoticePage',
  components: {
    ResponsiveMarkupText,
    InteractionZone
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      items: [],
      interval: 10,
      transition: '',

      vAlign: 'middle',
      hAlign: 'left',
      showIcon: false,
      icon: undefined,
      iconPos: undefined,
      textColor: '',
      fixedFontSize: false,
      hideScrollbar: false,

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      currentIndex: -1,

      resizing: true,

      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'needsInteractiveOverlay',
      'pageItemTransition'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    iconClass () {
      const parts = this.icon.split('|')
      if (!parts.length) { return }
      if (parts.length === 1) {
        return this.icon
      }
      return [parts[0], parts[1]]
    },

    iconLayoutClass () {
      if (!this.showIcon || !this.iconClass) { return }
      return `layout-${this.iconPos || 'ontop'}`
    },

    textStyle () {
      if (!this.textColor || !(this.textColor || '').trim().length) {
        return
      }
      return {
        color: this.textColor
      }
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    // Add Background Box supoort (DEV-1259)
    useContentBox () {
      return !this.hideBox
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.items = options.items || []

      // Fallback for single message format before DEV-805
      if (options.message && !this.items.length) {
        this.items.push({ message: options.message })
      }

      this.interval = Math.max(4, options.interval || 10)
      this.transition = options.transition || ''

      this.vAlign = options.vAlign || 'middle'
      this.hAlign = options.hAlign || 'left'
      this.textColor = options.textColor || ''
      this.fixedFontSize = (options.fixedFontSize === true || options.fixedFontSize === 'true')
      this.hideScrollbar = (options.hideScrollbar === true || options.hideScrollbar === 'true')

      this.showIcon = (options.showIcon === true || options.showIcon === 'true')
      this.icon = options.icon
      this.iconPos = options.iconPos || 'ontop'

      this.prepareNext(true)
    },

    prepareNext (isUpdate) {
      clearTimeout(this.showNextTimer)

      if (!this.items || !this.items.length) { return }

      // Force Reset
      if (isUpdate) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.items.length
      const nextItem = JSON.parse(JSON.stringify(this.items[nextIndex]))

      if (this.items.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
        } else {
          this.primeItem = nextItem
        }
      }

      // Is first init
      if (this.currentIndex < 0) {
        this.showNext(nextIndex)
      } else if (this.items.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.items.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext (nextIndex) {
      clearTimeout(this.prepareTimer)
      const activeIndex = +this.currentIndex

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (!this.items.length) {
        return
      } else if (this.items.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.items.length && activeIndex >= this.items.length - 1) {
        this.$emit('finished')
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, (this.interval || 10) * 1000 - config.PREPARE_TIME)
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    },

    // Override Mixins `checkSize`
    checkSize () {
      if (!this.$el) { return }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        width = container.offsetWidth || container.clientWidth
        height = container.offsetHeight || container.clientHeight

        if (!width || !height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: width,
          h: height
        }

        this.resizing = false

        FastDom.clear(measure)
      })
    }
  }
}
</script>

<template lang="pug">
section.notice-page(:class="{'is-portrait': isPortrait, 'landscape': !isPortrait, 'dark-text': !whiteText, 'show-text-shadow': showTextShadow}"
                    :style="[fontSizeStyle, textStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  interaction-zone(v-if="needsInteractiveOverlay"
                   :grid-name="item.grid_area || 'main'"
                   :grid-type="item.type")

  .notice-inner-wrapper.app-context-block(:class="[iconLayoutClass, {'is-portrait': isPortrait, 'landscape': !isPortrait, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline, 'using-content-box': useContentBox}]"
                        :style="boxMarginStyle")

    .icon-container.app-context-section.primary(v-if="showIcon && iconClass", :class="{visible: !resizing}")
      fa.fa-icon(:icon="iconClass" fixed-width)

    .message-container.app-context-section.primary(:class="[fontClass, {visible: !resizing}]")
      //- Fixed Font Size (DEV-1762)
      template(v-if="fixedFontSize")
        //- Prime
        transition(:name="transitionName", :duration="500" appear)
          .fixed-font-notice.prime(v-show="showPrime"
                            :class="[horzClass, vertClass]"
                            :style="scaledFontSizeStyle")
            .wysiwyg-block(v-html="(primeItem && primeItem.message) || ''", :class="{'hide-scrollbar': hideScrollbar}")

        //- Base
        transition(:name="transitionName", :duration="500" appear)
          .fixed-font-notice.base(v-show="!showPrime"
                            :class="[horzClass, vertClass]"
                            :style="scaledFontSizeStyle")
            .wysiwyg-block(v-html="(baseItem && baseItem.message) || ''", :class="{'hide-scrollbar': hideScrollbar}")

      //- Responsive Font Size (default)
      template(v-else)
        //- Prime
        transition(:name="transitionName", :duration="500" appear)
          responsive-markup-text.responsive-font-notice.prime(v-show="showPrime"
              :class="[horzClass, vertClass]"
              :text="(primeItem && primeItem.message) || ''"
              :base-font-size="baseFontSize"
              :font-class="fontClass")

        //- Base
        transition(:name="transitionName", :duration="500" appear)
          responsive-markup-text.responsive-font-notice.base(v-show="!showPrime"
              :class="[horzClass, vertClass]"
              :text="(baseItem && baseItem.message) || ''"
              :base-font-size="baseFontSize"
              :font-class="fontClass")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.notice-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  > .interaction-zone
    z-index: 0

  .notice-inner-wrapper
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    z-index: 1

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch

    // Passdown pointer events to interaction-zone
    pointer-events: none

    // Is Using Background Box (DEV-1259)
    // Set padding between texts and box border
    &.using-content-box
      .message-container
        .responsive-message-box,
        .fixed-font-notice
          top: 1em
          bottom: 1em
          left: 1em
          right: 1em
        .fixed-font-notice
          // Adjust the extra margin on the bottom
          &.vert-bottom
            bottom: 0
      .icon-container
        padding: 1em !important

  .message-container,
  .icon-container
    position: relative
    z-index: 10

  .message-container
    flex: 1 1 0.00001px
    opacity: 0
    transiton: opacity .2s
    // Prevent flickering when font-size is unscaled
    &.visible
      opacity 1

  .icon-container
    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: center
    opacity: 0
    transiton: opacity .2s
    &.visible
      opacity 1
    .fa-icon
      font-size: 3.5em

  .message-container
    position: relative

    .message-box
      width: 100%

  // Fixed Font Size Container
  .fixed-font-notice
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    // Alignments
    contentBoxAlignments()
    // Markup block
    .wysiwyg-block
      height: auto
      max-height: 100%
      overflow-x: hidden
      overflow-y: auto
      // Preserve scroll event on this block
      pointer-events: initial

      // Remove the margin-bottom on the last child
      > :last-child
        margin-bottom: 0

      // Show scrollbar (default)
      &:not(.hide-scrollbar)
        scrollBarTransparentWhite()

      // Make scrollbar invisible but still scrollable
      &.hide-scrollbar
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
          display: none

  .fixed-font-notice,
  .responsive-font-notice
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s

  // ==================
  // Dark Text
  // ==================
  &.dark-text
    color: $appDarkTextColor
    .fixed-font-notice
      .wysiwyg-block
        &:not(.hide-scrollbar)
          scrollBarTransparentBlack()

  // =======================================
  // Drop Text Shadow When BG Media is used
  // =======================================
  &.show-text-shadow:not(.dark-text)
    appTextShadow()
    .icon-container
      .fa-icon
        appIconShadow()

  // ==================
  // Portrait
  // ==================
  &.is-portrait
    .icon-container
      .fa-icon
        font-size: 4em

  // ===================
  // Icon Layout
  // ===================

  .notice-inner-wrapper

    &.layout-onleft,
    &.layout-onright,
    &.landscape.layout-topleft,
    &.landscape.layout-topright,
    &.landscape.layout-bottomleft,
    &.landscape.layout-bottomright
      flex-flow: row nowrap

    &.layout-onleft,
    &.landscape.layout-topleft,
    &.landscape.layout-bottomleft
      .icon-container
        padding-right: 4em

    &.layout-onright,
    &.landscape.layout-topright,
    &.landscape.layout-bottomright
      .icon-container
        padding-left: 4em

    &.layout-ontop,
    &.is-portrait.layout-topleft,
    &.is-portrait.layout-topright
      .icon-container
        padding-bottom: 1.5em

    &.layout-onbottom,
    &.is-portrait.layout-bottomleft,
    &.is-portrait.layout-bottomright
      .icon-container
        padding-top: 1.5em

    &.layout-onbottom,
    &.layout-onright,
    &.landscape.layout-topright,
    &.landscape.layout-bottomright,
    &.is-portrait.layout-bottomleft,
    &.is-portrait.layout-bottomright
      .icon-container
        order: 10

    &.layout-topleft,
    &.landscape.layout-topright,
    &.is-portrait.layout-bottomleft
      .icon-container
        justify-content: flex-start

    &.layout-bottomright,
    &.landscape.layout-bottomleft,
    &.is-portrait.layout-topright
      .icon-container
        justify-content: flex-end

    &.landscape
      &.layout-topleft,
      &.layout-bottomleft,
      &.layout-topright,
      &.layout-bottomright
        .icon-container
          flex-flow: column nowrap

    &.is-portrait
      &.layout-onleft,
      &.layout-onright
        .icon-container
          .fa-icon
            font-size: 2.5em
      &.layout-onleft
        .icon-container
          padding-right: 2em
      &.layout-onright
        .icon-container
          padding-left: 2em
</style>
