<script>
import Moment from 'moment-timezone'
import { mapState, mapGetters } from 'vuex'

import NumberValue from './NumberValue.vue'
import Sparkline from './Sparkline.vue'

import NumberHelper from 'services/number-helper.js'
import Metrics from 'services/metrics'

const config = {
  USE_BAR_CHART: [
    'series_7_Days',
    'series_4_Weeks', 'series_8_Weeks', 'series_12_Weeks',
    'series_3_x+30d', 'series_6_x+30d', 'series_12_x+30d'
  ],

  // Default (Dark Theme)
  DEFAULT_CHART_COLOR: '#fff',
  BOTTOM_SPARKLINE_COLOR: 'rgba(255,255,255, 0.3)',

  // Dark Color for Light Theme
  DEFAULT_CHART_COLOR_DARK: '#000',
  BOTTOM_SPARKLINE_COLOR_DARK: 'rgba(0,0,0, 0.3)',

  // In em
  DEFAULT_FONT_SIZE: 2.5,
  // 0.5 - 4.0
  DEFAULT_FONT_SCALE: 1.0,
  DEFAULT_UNIT_FONT_SCALE: 1.0
}

export default {
  name: 'MetricsNumberItem',
  components: { NumberValue, Sparkline },

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    comparisons: { type: Object },
    metric: { type: Object },
    color: { type: String },
    chartColor: { type: String },
    unitColor: { type: String },
    fontOutline: { type: Boolean, default: false },
    unitFontScale: { type: Number, default: +config.DEFAULT_UNIT_FONT_SCALE },
    vAlign: { type: String },
    hAlign: { type: String },
    isPaginated: { type: Boolean, default: false },
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      deltaOverLastBucket: undefined,
      seriesOverBuckets: undefined,
      seriesComparisons: undefined,

      debounceTimer: undefined
    }
  },

  watch: {
    'comparisons.ts' () {
      this.debounceUpdateSparkline()
    },

    showSeries () {
      this.$nextTick(() => {
        this.updateSeriesOverBuckets()
      })
    },

    showSeriesCompare () {
      this.$nextTick(() => {
        this.updateSeriesComparisons()
      })
    }
  },

  computed: {
    ...mapState({
      subscribedMetrics: state => state.metrics.subscribedMetrics || {}
    }),

    ...mapGetters({
      subscribedValue: 'getSubscribedValue',
      queriedValue: 'getQueriedValue'
    }),

    isValidNumber () {
      return this.item.number && NumberHelper.isNumber(this.item.number.value)
    },

    showPercent () {
      return this.comparisons && this.comparisons.ts && this.comparisons.range && this.comparisons.range.startsWith('common_')
    },

    showSeries () {
      return this.comparisons && this.comparisons.ts && this.comparisons.range && this.comparisons.range.startsWith('series_')
    },

    showSeriesCompare () {
      return this.comparisons && this.comparisons.ts && this.comparisons.range && this.comparisons.range.startsWith('compare_')
    },

    compareEnabled () {
      return this.showPercent || this.showSeries || this.showSeriesCompare
    },

    comparisonsLabel () {
      if (!this.comparisons || !this.comparisons.range) { return }
      return Metrics.compareRangeToLabel(this.comparisons.range)
    },

    valueColor () {
      return this.color || ''
    },

    chartPrimaryColor () {
      return this.chartColor || (this.lightTheme ? config.DEFAULT_CHART_COLOR_DARK : config.DEFAULT_CHART_COLOR)
    },

    seriesColors () {
      // [Last %bucket, This %bucket]
      return [
        (this.lightTheme ? config.BOTTOM_SPARKLINE_COLOR_DARK : config.BOTTOM_SPARKLINE_COLOR),
        this.chartPrimaryColor
      ]
    },

    useBarChart () {
      return config.USE_BAR_CHART.includes(this.comparisons.range)
    },

    xLabels () {
      if (!this.useBarChart) {
        return
      }

      const parts = this.comparisons.range.split('_')
      if (parts.length < 3) {
        return
      }
      const length = parseFloat(parts[1])
      const now = Moment()

      const labels = []

      switch (this.comparisons.range) {
        case 'series_7_Days':
          const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
          const currentDay = now.day()
          let d = currentDay - 1
          for (let i = 0; i < length; i++) {
            if (d < 0) {
              d = 6
            }
            labels.unshift(days[d])
            d--
          }
          return labels
        case 'series_4_Weeks':
        case 'series_8_Weeks':
        case 'series_12_Weeks':
          const currentWeekNum = +(now.format('w'))
          let wk = currentWeekNum - 1
          for (let i = 0; i < length; i++) {
            if (wk < 1) {
              wk = 53
            }
            if (length <= 4) {
              labels.unshift(`W${wk}`)
            } else if (length > 4) {
              labels.unshift(`${wk}`)
            }
            wk--
          }
          return labels
        case 'series_3_x+30d':
        case 'series_6_x+30d':
        case 'series_12_x+30d':
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          const monthsInital = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
          const currentMonth = now.month()
          let m = currentMonth - 1
          for (let i = 0; i < length; i++) {
            if (m < 0) {
              m = 11
            }
            if (length <= 6) {
              labels.unshift(`${months[m]}`)
            } else {
              labels.unshift(`${monthsInital[m]}`)
            }
            m--
          }
          return labels
      }
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    this.$nextTick(() => {
      this.debounceUpdateSparkline()
    })
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
  },

  methods: {
    debounceUpdateSparkline () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.updateSparkline()
      }, 300)
    },

    updateSparkline () {
      if (this.showSeries) {
        this.updateSeriesOverBuckets()
        this.seriesComparisons = undefined
      } else if (this.showSeriesCompare) {
        this.updateSeriesComparisons()
        this.seriesOverBuckets = undefined
      } else {
        this.seriesComparisons = undefined
        this.seriesOverBuckets = undefined
        if (this.showPercent) {
          this.updateDeltaOverLastBucket()
        }
      }
    },

    updateDeltaOverLastBucket () {
      if (!this.showPercent || !this.comparisons || !this.comparisons.ts || !this.comparisons.keys || !this.comparisons.keys.length || !this.isValidNumber) {
        this.deltaOverLastBucket = undefined
        return
      }
      const lastBucketValue = this.queriedValue(this.comparisons.keys[0]) || 0
      const currentValue = this.item.number.value || 0
      let delta = 0
      let direction
      if (currentValue === 0 && lastBucketValue === 0) {
        // Placeholder, Do nonthing
      } else if (currentValue === 0) {
        delta = 100
        direction = 'down'
      } else if (lastBucketValue === 0) {
        delta = 100
        direction = 'up'
      } else {
        delta = (currentValue - lastBucketValue) / lastBucketValue * 100
        direction = delta < 0 ? 'down' : 'up'
        delta = Math.abs(delta)
        if (delta < 0.1) {
          delta = delta.toFixed(2)
        } else if (delta < 1) {
          delta = delta.toFixed(1)
        } else {
          delta = Math.round(delta)
        }
      }
      this.deltaOverLastBucket = {
        delta,
        direction
      }
    },

    updateSeriesOverBuckets () {
      if (!this.showSeries || !this.comparisons || !this.comparisons.keys || !this.comparisons.keys.length || !this.isValidNumber) {
        this.seriesOverBuckets = undefined
        return
      }
      const data = this.queriedValue(this.comparisons.keys[0]) || []

      const parts = this.comparisons.range.split('_')
      if (parts.length < 3) {
        return
      }
      const comparisonRange = parseFloat(parts[1])

      const metric = JSON.parse(JSON.stringify(this.metric))
      metric.series = true
      metric.previous = true
      metric.date_range = comparisonRange
      metric.range_string = `_${comparisonRange}_`

      this.setCompareSeriesData(metric, data, this.useBarChart).then(result => {
        this.seriesOverBuckets = result
      })
    },

    updateSeriesComparisons () {
      if (!this.showSeriesCompare || !this.comparisons || !this.comparisons.keys || !this.comparisons.keys.length || !this.isValidNumber) {
        this.seriesComparisons = undefined
        return
      }

      const parts = this.comparisons.range.split('_')
      if (parts.length < 3) {
        return
      }
      const comparisonRange = parts[1]

      const keys = this.comparisons.keys
      const data1 = this.queriedValue(keys[1]) || [] // Last %bucket
      const data2 = this.queriedValue(keys[0]) || [] // This %bucket

      const metric1 = JSON.parse(JSON.stringify(this.metric))
      metric1.date_range = metric1.default_range
      const metric2 = Object.assign({}, metric1)

      metric1.series = true
      metric1.compare = true
      metric1.range_string = `common_last_${comparisonRange}`

      metric2.series = true
      metric2.compare = true
      metric2.range_string = `common_this_${comparisonRange}`

      Promise.all([
        this.setCompareSeriesData(metric1, data1, true),
        this.setCompareSeriesData(metric2, data2)
      ]).then(results => {
        const series1 = results[0]
        const series2 = results[1]
        this.seriesComparisons = [series1, series2]
      })
    },

    setCompareSeriesData (metric, data, fillMissing) {
      return new Promise((resolve) => {
        const parts = metric.range_string.split('_')
        if (parts.length < 3) {
          return
        }
        const valueLen = parseFloat(parts[1])

        if (!Array.isArray(data) || data.length === 0) {
          if (!fillMissing || !valueLen) { resolve([]) }
          // Fill missing value with `0`s
          const result = Array(valueLen).fill(0)
          resolve(result)
        }

        const startEnd = Metrics.getBucketTs(metric)
        const start = startEnd.start
        let currentUnix = startEnd.end
        const itemData = []
        while (currentUnix >= start) {
          const node = data.find(x => {
            return x[0] === currentUnix
          })
          if (node) {
            itemData.unshift(node[1])
          } else {
            itemData.unshift(null)
          }
          currentUnix = currentUnix - metric.bucket_size
        }

        if (fillMissing && itemData.length < valueLen) {
          // Fill missing value with `0`s
          const tail = Array(valueLen - itemData.length).fill(0)
          const result = [].concat([], itemData, tail)
          resolve(result)
        }

        resolve(itemData)
      })
    }
  }
}
</script>

<template lang="pug">
mixin comparisonsLabel
  .compare-label(v-if="comparisonsLabel && comparisonsLabel.length") {{ comparisonsLabel }}

.metrics-item-wrapper.metrics-number-item(:class="{'outlined' : fontOutline}")
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}

  .item-body.app-context-section.primary(:class="[horzClass, vertClass, {'has-compare': compareEnabled}]")
    .number-main-content(:class="{'hide': !isValidNumber}")
      //- fontSize: fontSizeValue
      number-value(v-if="item.number"
                   :number-data="item.number"
                   :unit-font-scale="unitFontScale"
                   :unit-color="unitColor"
                   :is-static="isPaginated"
                   :style="{ color: valueColor }")
      .item-label(v-if="item.label && item.label.text") {{ item.label.text }}

    .item-compare(v-if="compareEnabled")
      .over-last-bucket(v-if="showPercent && deltaOverLastBucket")
        .delta-value(:class="[deltaOverLastBucket.direction]")
          fa.fa-icon(v-if="deltaOverLastBucket.direction === 'up'" icon="long-arrow-alt-up")
          fa.fa-icon(v-if="deltaOverLastBucket.direction === 'down'" icon="long-arrow-alt-down")
          | {{ deltaOverLastBucket.delta }}%
        +comparisonsLabel

      .series-block.series-single(v-if="showSeries && seriesOverBuckets")
        .sparkline-graph
          sparkline(:values="seriesOverBuckets"
                    :renderer="useBarChart ? 'bar' : 'line'"
                    :x-labels="xLabels"
                    :color="chartPrimaryColor"
                    :light-theme="lightTheme"
                    baseline="zero")
        template(v-if="!useBarChart")
          +comparisonsLabel

      .series-block.series-duo(v-if="showSeriesCompare && seriesComparisons")
        .sparkline-graph
          sparkline(:series="seriesComparisons"
                    :color="seriesColors"
                    :light-theme="lightTheme"
                    baseline="zero")
        +comparisonsLabel
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.metrics-number-item
  &.outlined
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000

    .fa-icon
      filter: none !important
      path
       stroke: #000
       stroke-width: 15

  .item-body
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: flex-start
    max-width: 100%
    overflow: hidden

    // Alignments
    contentBoxAlignments()

    &.has-compare
      justify-content: space-between

  .number-value
    font-size: 2.5em
    font-weight: 600
    line-height: 120%
    .digit
      font-weight: 700

  .item-label
    opacity: 0.7
    margin-top: 0.3em
    line-height: 110%
    text-transform: lowercase

  .item-compare
    max-width: 100%
    flex: 1 1 0.00001px
    overflow: hidden

    align-self: stretch

    display: flex
    flex-flow: column nowrap
    justify-content: flex-end
    align-items: stretch

    .over-last-bucket
      .fa-icon
        margin-right: 0.3em

      .delta-value
        font-weight: 600
        font-size: 1.1em
        &.up
          color: var(--brand-success)
        &.down
          color: var(--brand-danger)

    .compare-label
      font-size: 0.7em
      line-height: 110%
      text-transform: lowercase

    .series-block
      flex: 1 1 0.000001px
      display: flex
      flex-flow: column nowrap
      justify-content: flex-end
      align-items: stretch
      padding: 0.3em 0 0 0

      .compare-label
        padding-top: 0.6em
    .sparkline-graph
      flex: 1 1 0.000001px
      position: relative

  .hide
    visibility: hidden
    opacity: 0
</style>
