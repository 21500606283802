<script>
// A MIXIN COMPONENT
//
// Store commonly used methods for Metrics apps

import { mapState, mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import { EventBus } from 'services/eventbus.js'

const config = {
  MIN_INTERVAL: 4,

  // In seconds, default paginate interval
  PAGINAE_INTERVAL: 10
}

export default {
  name: 'MetricsMixins',

  props: {
    items: { type: Array, default: () => { return [] } },
    interval: { type: Number, default: +config.PAGINAE_INTERVAL },
    nestedData: { type: Boolean, default: false },
    transition: { type: String },
    removeTitle: { type: Boolean, default: false }
  },

  data () {
    return {
      currentIndex: 0,

      showPrime: false,
      primeItem: undefined,
      baseItem: undefined,
      primeIndex: -1,
      baseIndex: -1,

      paginateTimer: undefined
    }
  },

  computed: {
    ...mapState({
      subscribedMetricKeys: state => state.metrics.subscribedMetricKeys || [],
      queryList: state => state.metrics.queryList || []
    }),

    ...mapGetters({
      subscribedValue: 'getSubscribedValue',
      pageItemTransition: 'pageItemTransition'
    }),

    // Is under ItemPreview (js_user: Apps > AppDetail > Preview)
    isItemPreview () {
      return Boolean(this.$route && this.$route.name === 'item_preview')
    },

    validItems () {
      if (!this.items || !this.items.length) { return [] }
      return this.items.filter(item => {
        if (!this.nestedData) {
          return item.metric && item.metric.ref && item.metric.ref.length
        }
        // Nested Data: Linechart / Piechart
        const subItem = item.slices || item.series
        if (Array.isArray(subItem) && subItem.length > 0) {
          return subItem.some(sItem => {
            const metric = sItem.metric
            return Boolean(metric && metric.ref && metric.ref.length)
          })
        }
        return false
      })
    },

    pageLen () {
      return this.validItems.length || 1
    },

    hasValidItem () {
      return Boolean(this.validItems.length)
    },

    needsPaginate () {
      return this.validItems.length > 1
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    validItems: {
      deep: true,
      handler (items) {
        if (!items || !items.length) { return }
        this.subscribeMetrics(items)
        this.setCurrentItem()
      }
    },

    needsPaginate (isNeeded) {
      if (isNeeded) {
        this.setCurrentItem()
        this.initPaginate()
      } else {
        clearInterval(this.paginateTimer)
        this.currentIndex = 0
      }
    },

    interval () {
      this.initPaginate()
    }
  },

  mounted () {
    clearInterval(this.paginateTimer)
    this.setCurrentItem()
    this.initPaginate()
  },

  beforeDestroy () {
    clearInterval(this.paginateTimer)
  },

  methods: {
    // ******************************
    // Metrics Workflow - 1
    // ******************************
    subscribeMetrics (items) {
      if (!items || !items.length) { return }

      const addQueryItemPromises = []

      items.forEach(item => {
        const metric = item.metric
        if (metric && metric.ref && metric.ref.length) {
          this.$store.commit('addSubsribedMetricKey', metric.ref)

          // Custom Range
          if (Metrics.isCustomRange(metric) || metric.series) {
            addQueryItemPromises.push(this.$store.dispatch('addQueryItem', metric))
          }

          // Watch metric comparisons (Number)
          if (item.metric_comparisons) {
            const newItemPromises = Metrics.watchMetricComparisons(metric, item.metric_comparisons, addQueryItemPromises)
            addQueryItemPromises.push(...newItemPromises)
          }
        }

        // Watch for Max / Min value based on metric (Gauge etc)
        // Now watch for its own metric value since [APP-2955]
        // - Max
        if (item.max && item.max.type === 'metric' && item.metric.ref && item.metric.ref.length) {
          const maxMetric = Metrics.toMaxAndMinMetric(item.metric, 'max')
          addQueryItemPromises.push(this.$store.dispatch('addQueryItem', maxMetric))
        }
        // - Min
        if (item.min && item.min.type === 'metric' && item.metric.ref && item.metric.ref.length) {
          const minMetric = Metrics.toMaxAndMinMetric(item.metric, 'min')
          addQueryItemPromises.push(this.$store.dispatch('addQueryItem', minMetric))
        }

        // Piechart / Linechart
        const subItem = item.slices || item.series
        if (Array.isArray(subItem) && subItem.length > 0) {
          subItem.forEach(sItem => {
            const metric = sItem.metric
            if (metric && metric.ref && metric.ref.length) {
              this.$store.commit('addSubsribedMetricKey', metric.ref)

              if (Metrics.isCustomRange(metric)) {
                addQueryItemPromises.push(this.$store.dispatch('addQueryItem', metric))
              }
            }
          })
        }
      })

      // In Playlist Pages
      if (!this.isItemPreview) {
        EventBus.$emit('listen-metrics')
      // Is under ItemPreview (Apps > AppDetail > Preview)
      } else if (this.subscribedMetricKeys.length) {
        Metrics.subscribeMetricsByTagList(this.subscribedMetricKeys)
      }

      if (addQueryItemPromises.length) {
        Promise.all(addQueryItemPromises).then(() => {
          // In Playlist Pages
          if (!this.isItemPreview) {
            EventBus.$emit('query-metrics')
          // Is under ItemPreview (Apps > AppDetail > Preview)
          } else if (this.queryList.length) {
            Metrics.queryForMetrics(this.queryList)
          }
        })
      }
    },

    setCurrentItem () {
      if (!this.validItems || !this.validItems.length) { return }
      const targetItem = JSON.parse(JSON.stringify(this.validItems[this.currentIndex] || {}))

      if (!this.needsPaginate || !this.showPrime) {
        this.showPrime = true
        this.primeItem = targetItem
        this.primeIndex = +this.currentIndex
      } else {
        this.showPrime = false
        this.baseItem = targetItem
        this.baseIndex = +this.currentIndex
      }
    },

    initPaginate () {
      clearInterval(this.paginateTimer)
      if (!this.needsPaginate) { return }
      const interval = Math.max(config.MIN_INTERVAL, (this.interval || config.PAGINAE_INTERVAL))
      this.paginateTimer = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.pageLen
        this.setCurrentItem()
      }, interval * 1000)
    },

    itemTitle (item, allowEmpty) {
      if (item.title) {
        return Metrics.keyToTitle(item.title)
      }
      if (item.metric) {
        return Metrics.keyToTitle(item.metric.ref)
      }
      if (item.series) {
        const series = item.series.find(s => (s.metric && s.metric.ref))
        if (series && series.metric) {
          return Metrics.keyToTitle(series.metric.ref)
        }
      }
      return allowEmpty ? '' : 'Empty'
    },

    itemData (index) {
      return this.itemsData[index] || {}
    }
  }
}
</script>

<style lang="stylus">
.metrics-item-wrapper
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  .item-title
    &:not(:empty)
      padding: 0.6em 1em

  .item-body
    flex: 1 1 0.0001px
    padding: 1rem
    position: relative
</style>
