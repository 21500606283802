<script>
// Interaction Zone for Tap/Swipe interactions since DEV-1761

import {
  mapState,
  mapGetters
} from 'vuex'

import TouchEvents from 'services/touchevents.js'
import Log from 'services/log.js'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'InteractionZone',

  props: {
    gridName: {
      type: String,
      default: ''
    },
    gridType: {
      type: String,
      default: ''
    },
    // Indicator of placholder for empty zone on InteractiveOverlay
    isPlaceholder: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      touchEvtHandler: undefined
    }
  },

  computed: {
    ...mapState({
      paused: s => s.playbackmode.pauseMode,
      screensaverIsOn: s => s.playbackmode.screensaverMode,
      tappingOn: s => s.interactions.tappingOn
    }),
    ...mapGetters([
      'screensaverEnabled',
      'playlistSwipeEnabled',
      'tapToPauseEnabled'
    ]),
    isMediaZone () {
      if (!this.gridType || this.isPlaceholder) { return false }
      return ['media', 'media_folder', 'image', 'video', 'slides'].includes(this.gridType)
    }
  },

  mounted () {
    if (this.touchEvtHandler) {
      this.touchEvtHandler.destroy()
    }
    this.touchEvtHandler = new TouchEvents(this.$el)

    // Playlist Swipe handler [DEV-1711]
    this.touchEvtHandler.onSwipe('left', this.goToNext)
    this.touchEvtHandler.onSwipe('right', this.goToPrev)
    this.touchEvtHandler.onSwipe('up', this.goToNext)
    this.touchEvtHandler.onSwipe('down', this.goToPrev)

    // Tap event handler [DEV-1704][DEV-1749]
    this.touchEvtHandler.onTap(this.tapEventHandler)
  },

  beforeDestroy () {
    if (this.touchEvtHandler) {
      this.touchEvtHandler.destroy()
      this.touchEvtHandler = undefined
    }
  },

  methods: {
    logZoneName () {
      this.$store.commit('updateTappingTarget', this.gridName)
    },

    getItemByZoneName (zoneName) {
      return this.gridItems.find(gItem => (gItem.grid_area || 'main') === zoneName)
    },

    goToNext () {
      const tapTarget = (this.tappingOn || '') + ''
      this.$store.commit('updateTappingTarget', null)

      // Is interacting on a media zone (priority: DEV-1710 > DEV-1711)
      if (!this.isPlaceholder && tapTarget && tapTarget.length && this.isMediaZone) {
        EventBus.$emit('interaction-go-to-next', tapTarget)
        return
      }

      // Playlist Swipe (DEV-1711)
      // - Advance to next page
      if (this.playlistSwipeEnabled) {
        // Skip swipe to paginate when playlist is paused (DEV-1749)
        if (this.paused) {
          Log.debug('player', 'Playlist is paused. Please resume playback before swiping to change pages', 'DBG_PLAYLISTPAUSED')
          return
        }

        Log.debug('player', 'Swipe to next page', 'DBG_SWIPENEXTPAGE')
        EventBus.$emit('interaction-nextpage')
      }
    },

    goToPrev () {
      const tapTarget = (this.tappingOn || '') + ''
      this.$store.commit('updateTappingTarget', null)

      // Is interacting on a media zone (priority: DEV-1710 > DEV-1711)
      if (!this.isPlaceholder && tapTarget && tapTarget.length && this.isMediaZone) {
        EventBus.$emit('interaction-go-to-prev', tapTarget)
        return
      }

      // Playlist Swipe (DEV-1711)
      // - Go back to previous page
      if (this.playlistSwipeEnabled) {
        // Skip swipe to paginate when playlist is paused (DEV-1749)
        if (this.paused) {
          Log.debug('player', 'Playlist is paused. Please resume playback before swiping to change pages', 'DBG_PLAYLISTPAUSED2')
          return
        }

        Log.debug('player', 'Swipe to previous page', 'DBG_SWIPEPREVPAGE')
        EventBus.$emit('interaction-previouspage')
      }
    },

    tapEventHandler () {
      this.$store.commit('updateTappingTarget', null)

      if (this.screensaverEnabled) {
        // Playlist screensaver (DEV-1704)
        if (!this.screensaverIsOn) {
          EventBus.$emit('interaction-show-screensaver')
        }
      } else if (this.tapToPauseEnabled) {
        // Pause Playlist (DEV-1749)
        EventBus.$emit('interaction-pause-playlist')
      }
    }
  }
}
</script>

<template lang="pug">
aside.interaction-zone(:data-grid-area="gridName"
      @touchstart="logZoneName")
</template>

<style lang="stylus">
.interaction-zone
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: transparent
  pointer-events: initial
</style>
