<script>
import MetricsMixins from './MetricsMixins.vue'

import LinechartItem from './LinechartItem.vue'

import { mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'MetricsLinechart',
  components: { LinechartItem },

  mixins: [MetricsMixins],

  props: {
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      data: [],
      itemsData: [],

      metricKeysList: [],
      itemsMetricKeysMap: []
    }
  },

  computed: {
    ...mapGetters({
      queriedValue: 'getQueriedValue'
    })
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    EventBus.$on('ws-query-metrics', this.updateQueryData)
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)

    this.renderInitData()
  },

  beforeDestroy () {
    EventBus.$off('ws-query-metrics', this.updateQueryData)
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        this.metricKeysList = []
        return
      }

      const refsMap = []
      const keysList = []
      this.validItems.forEach((item, itemIdx) => {
        const series = item.series || []

        series.forEach((series, seriesIdx) => {
          const metric = series.metric || {}

          if (!refsMap[itemIdx]) {
            refsMap[itemIdx] = {}
          }

          if (metric && metric.ref && metric.ref.length) {
            if (!refsMap[itemIdx].ref) {
              refsMap[itemIdx].ref = []
            }
            refsMap[itemIdx].ref[seriesIdx] = metric.ref
          }

          if (metric.series) {
            const qKey = Metrics.buildQueryKey(metric)
            if (qKey && !keysList.includes(qKey)) {
              keysList.push(qKey)
            }
            if (!refsMap[itemIdx].query) {
              refsMap[itemIdx].query = []
            }
            refsMap[itemIdx].query[seriesIdx] = qKey
          }
        })
      })

      this.itemsMetricKeysMap = refsMap
      this.metricKeysList = keysList
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateItemData (itemIdx) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[itemIdx]
      if (!item) { return }

      const series = item.series || []

      let start
      let end
      let bucketSize = 0
      series.forEach((series, seriesIdx) => {
        const metric = series.metric || {}

        if (metric.series) {
          const startEnd = Metrics.getBucketTs(metric)
          if (!start || startEnd.start < start) {
            start = startEnd.start
          }
          if (!end || startEnd.end > end) {
            end = startEnd.end
          }

          if (metric.bucket_size > bucketSize) {
            bucketSize = metric.bucket_size
          }
        }
      })

      series.forEach((series, seriesIdx) => {
        const metric = series.metric || {}
        if (metric.url && metric.url.length) { return }

        if (metric.series) {
          const qKey = this.itemsMetricKeysMap[itemIdx].query[seriesIdx]
          if (!qKey) { return }
          this.setItemData(itemIdx, seriesIdx, bucketSize, start, end, this.queriedValue(qKey))
          return
        }

        if (metric.kind === 'set') {
          const targetData = (this.data && this.data[itemIdx] && this.data[itemIdx][seriesIdx]) || {}
          this.setSeriesValues(itemIdx, seriesIdx, targetData.value)
          return
        }

        this.setSeriesValues(itemIdx, seriesIdx, [])
      })
    },

    setItemData (itemIdx, seriesIdx, bucketSize, startUnix, endUnix, data) {
      if (!Array.isArray(data) || data.length === 0) {
        data = []
      }

      let currentUnix = Math.floor(Date.now() / 1000 / bucketSize) * bucketSize
      const itemData = []
      while (currentUnix >= startUnix) {
        const node = data.find(x => {
          return x[0] === currentUnix
        })
        if (node) {
          itemData.unshift(node[1])
        } else {
          itemData.unshift(null)
        }
        currentUnix = currentUnix - bucketSize
      }
      this.setSeriesValues(itemIdx, seriesIdx, itemData)
    },

    setSeriesValues (itemIdx, seriesIdx, values) {
      if (!Array.isArray(this.itemsData[itemIdx])) {
        this.$set(this.itemsData, itemIdx, [])
      }
      if (!Array.isArray(this.itemsData[itemIdx][seriesIdx])) {
        this.$set(this.itemsData[itemIdx], seriesIdx, [])
      }
      const newValues = JSON.parse(JSON.stringify(values || []))
      this.$set(this.itemsData[itemIdx], seriesIdx, newValues)
    },

    updateQueryData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.query) { return }
        for (let i = 0; i < item.query.length; i++) {
          const key = item.query[i]
          if (m.includes(key)) {
            this.updateItemData(idx)
            break
          }
        }
      })
    },

    // Update method for nested data
    updateCurrentData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        let updateItem = false
        if (!item.ref || !item.ref.length) { return }
        item.ref.forEach((key, sIndex) => {
          if (!key) { return }
          if (m.includes(key)) {
            updateItem = true
            const newData = this.subscribedValue(key)
            if (!newData) { return }
            this.$set(this.data[idx], sIndex, JSON.parse(JSON.stringify(newData)))
          }
        })
        if (updateItem) {
          this.$nextTick(() => {
            this.updateItemData(idx)
          })
        }
      })
    },

    // Update method for nested data
    updateInvolvedItems (refs) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      const forceUpdateAll = !refs || !refs.length
      if (forceUpdateAll) {
        this.itemsMetricKeysMap.forEach((item, idx) => {
          this.updateItemData(idx)
        })
      } else {
        this.itemsMetricKeysMap.forEach((item, idx) => {
          if (!item.ref && !item.ref.length) { return }
          if (item.ref.some(rf => refs.includes(rf))) {
            this.updateItemData(idx)
          }
        })
      }
    },

    // Update method for nested data
    renderInitData () {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }

      const data = []
      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.ref || !item.ref.length) { return }
        if (!data[idx]) {
          data[idx] = []
        }
        item.ref.forEach((key, sIndex) => {
          if (!key) { return }
          const value = this.subscribedValue(key)
          if (!value) { return }
          data[idx][sIndex] = JSON.parse(JSON.stringify(value))
        })
      })

      this.data = data
      this.$nextTick(() => {
        this.updateInvolvedItems()
      })
    }
  }
}
</script>

<template lang="pug">
section.metrics-linechart
  transition(:name="transitionName", :duration="500" appear)
    linechart-item.prime(v-if="showPrime && primeItem"
                  :title="itemTitle(primeItem)"
                  :remove-title="removeTitle"
                  :item="primeItem"
                  :item-data="itemsData[primeIndex]"
                  :is-paginated="needsPaginate"
                  :light-theme="lightTheme")

  transition(:name="transitionName", :duration="500" appear)
    linechart-item.base(v-if="!showPrime && baseItem"
                  :title="itemTitle(baseItem)"
                  :remove-title="removeTitle"
                  :item="baseItem"
                  :item-data="itemsData[baseIndex]"
                  :is-paginated="needsPaginate"
                  :light-theme="lightTheme")
</template>

<style lang="stylus">
section.metrics-linechart
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-linechart-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
