<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import DayEventsSlot from './calendar/DayEventsSlot.vue'

import qs from 'qs'
import Moment from 'moment-timezone'
import ICAL from 'ical.js'
import FastDom from 'fastdom'
import {
  mapGetters
} from 'vuex'

import Timezone from 'services/timezone.js'
import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'

// Cache for 5 minutes
// NOTE: Remember to update the "ICAL_DATA" value in ContentCache after changing this value
// - In second. For request header
const CACHE_CONTROL = 300
// - In ms. For LRUCache and refresh timer
const CACHE_MAX_AGE = CACHE_CONTROL * 1000

// Max UTC Offest time (24hrs). In ms
const MAX_UTC_OFFSET = 24 * 3600 * 1000

const config = {
  // In ms
  // Add 1s extra interval to ensure the cache expires before next fetch
  ICAL_UPDATE_INTERVAL: +CACHE_MAX_AGE + 1000,

  // In vmin
  BASE_FONTSIZE: 1,

  // White, RED, BLUE, GREEN
  DEFAULT_ICAL_COLORS: [
    '#FFFFFF',
    '#FC5543',
    '#2AA4E5',
    '#5CBF63'
  ],

  // Parse the next ${n}th occurance
  REPEAT_DAY_COUNT: 42,
  REPEAT_WEEK_COUNT: 6,
  REPEAT_MONTH_COUNT: 3,
  REPEAT_YEAR_COUNT: 1,

  // Default repeat count for MINUTELY | SECONDLY from iCal
  REPEAT_DEFAULT_COUNT: 42
}

// Get Recurrence Type
// > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
const ICAL_RECUR_TYPES_COUNT = {
  'YEARLY': 'REPEAT_YEAR_COUNT',
  'MONTHLY': 'REPEAT_MONTH_COUNT',
  'WEEKLY': 'REPEAT_WEEK_COUNT',
  'DAILY': 'REPEAT_DAY_COUNT',
  'MINUTELY': 'REPEAT_DEFAULT_COUNT',
  'SECONDLY': 'REPEAT_DEFAULT_COUNT'
}

export default {
  name: 'CalendarPage',

  components: {
    DayEventsSlot
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      title: '',
      iCals: [],
      format24h: true,

      events: [],
      validEvents: [],

      dayBlocks: [],
      localeWeekdays: [],

      weeksInMonth: -1,
      weeksToShow: -1,
      today: '',
      thisMonth: '',
      endOfLastMonth: '',
      startOfThisMonth: '',
      startOfNextMonth: '',
      calendarBoundary: null,
      calEnd: null,

      currentMonthInRange: -1,
      currentYearInRange: -1,
      monthFrom: 0,
      yearFrom: 0,
      monthTo: 0,
      yearTo: 0,
      showDateRange: false,
      latestYear: 0,
      allGridNames: [],

      showCurrentWeek: false,
      hideWeekends: false,
      previousShowDateRange: null,
      previousHideWeekends: null,
      previousShowCurrentWeek: null,
      recalculateDayBlocks: false,

      dateTitleHeight: undefined,
      dateSlotHeight: undefined,
      eventItemBlockHeight: undefined,

      tickTimer: undefined,
      icalTimer: undefined,
      checkHeightTimer: undefined,

      loading: true
    }
  },

  computed: {
    ...mapGetters([
      'accountLocale',
      'deviceActiveLanguage'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    noValidEvents () {
      return Boolean(!this.validEvents || !this.validEvents.length)
    },

    showIcalColor () {
      if (!this.iCals || !this.iCals.length) { return false }
      return Boolean(this.iCals.length > 1)
    },

    icalFlagStyle () {
      if (this.baseFontSize) {
        // Use value in 'px' unit
        // To prevent sizing difference due to Chrome's mimimal font size override
        const size = this.baseFontSize * 0.9
        return {
          width: `${size}px`,
          height: `${size}px`,
          marginRight: `${this.baseFontSize * 0.5}px`
        }
      }
    },

    monthGridStyles () {
      if (this.weeksToShow <= 0) { return }
      return {
        gridTemplateColumns: this.hideWeekends ? 'repeat(5, 1fr)' : 'repeat(7, 1fr)',
        gridTemplateRows: `repeat(${this.weeksToShow}, minmax(${100/this.weeksToShow}%, 1fr))`
      }
    },

    weekdayGridStyles () {
      if (this.weeksToShow <= 0) { return }
      return {
        gridTemplateColumns: this.hideWeekends ? 'repeat(5, 1fr)' : 'repeat(7, 1fr)',
        gridTemplateRows: `repeat(1, 1fr)`
      }
    },

    totalDayBlocksCount () {
      if (this.weeksInMonth <= 0) { return 0 }
      return this.weeksInMonth * 7
    },

    calGridTplAreas () {
      if (!this.allGridNames || !this.allGridNames.length) { return }
      let result = ''
      this.allGridNames.forEach((gridName, bIndex) => {
        if (bIndex % 7 === 0) {
          result += '"'
        } else {
          result += ' '
        }
        result += gridName
        if (bIndex % 7 === 6) {
          result += '"'
          if (bIndex !== this.allGridNames.length - 1) {
            result += '\n'
          }
        }
      })
      return {
        gridTemplateAreas: result
      }
    },

    multiDayEvents () {
      if (this.noValidEvents) { return [] }
      return this.validEvents.filter(event => event.multiDays)
    },

    // To properly split multi-all-day events that spans in multi-weeks (rows)
    multiDayBlocks () {
      if (!this.multiDayEvents || !this.multiDayEvents.length || !this.allGridNames || !this.allGridNames.length) {
        return []
      }
      const mtdEvents = [].concat([], this.multiDayEvents)
      const results = []
      mtdEvents.forEach(event => {
        const dayBlockIndex = this.allGridNames.findIndex(gn => gn === event.gridStart)
        if (dayBlockIndex === -1) { return }

        const startColIndex = dayBlockIndex % 7
        const startRowIndex = ~~(dayBlockIndex / 7)

        let chunkCount = 0
        let evtChunk = JSON.parse(JSON.stringify(event))

        evtChunk.chunkIndex = chunkCount
        evtChunk.colIndex = startColIndex
        evtChunk.rowIndex = startRowIndex
        evtChunk.mainKey = evtChunk.key + ''
        evtChunk.key += `_chunk-${chunkCount}`

        // multi-row
        if (startColIndex + event.daySpan > 7) {
          // First Row/Chunk
          const firstRowSpan = 7 - startColIndex
          evtChunk.colSpan = firstRowSpan
          results.push(evtChunk)

          chunkCount++
          let nextRowIndex = startRowIndex + 1

          // Rest Chunks
          let restDaySpan = event.daySpan - firstRowSpan
          if (restDaySpan <= 0) { return }
          do {
            const colSpanInRow = Math.min(7, restDaySpan)

            evtChunk = JSON.parse(JSON.stringify(event))
            evtChunk.chunkIndex = chunkCount
            evtChunk.colSpan = colSpanInRow
            evtChunk.colIndex = 0
            evtChunk.rowIndex = nextRowIndex
            evtChunk.mainKey = evtChunk.key + ''
            evtChunk.key += `_chunk-${chunkCount}`

            results.push(evtChunk)

            restDaySpan -= colSpanInRow
            chunkCount++
            nextRowIndex++
          } while (restDaySpan > 0 && nextRowIndex < this.weeksToShow)
        // Single-row
        } else {
          evtChunk.colSpan = event.daySpan
          evtChunk.rowIndex = startRowIndex
          results.push(evtChunk)
        }
      })
      return results
    },

    visibleMultiDayBlocks () {
      if (!this.multiDayBlocks || !this.multiDayBlocks.length) { return }
      return this.multiDayBlocks.filter(mtdEvent => {
        if (!mtdEvent) { return false }
        return this.isVisibleInSlot(mtdEvent.key)
      })
    },

    multiDayEventsPriority () {
      if (!this.multiDayBlocks || !this.multiDayBlocks.length) {
        return
      }

      const weekIndex = []
      const priorityInWeek = {}
      const daySlotsByWeek = {}

      this.multiDayBlocks.forEach(mtdEvent => {
        if (!mtdEvent) { return }

        const wkIndex = mtdEvent.rowIndex
        const EventKey = mtdEvent.key
        // Is the first multi-day event in this week
        if (!weekIndex.includes(wkIndex)) {
          weekIndex.push(wkIndex)

          priorityInWeek[wkIndex] = {}
          priorityInWeek[wkIndex][EventKey] = 0

          if (!mtdEvent.daysIncluded || !mtdEvent.daysIncluded.length) { return }

          daySlotsByWeek[wkIndex] = {}
          mtdEvent.daysIncluded.forEach(dgName => {
            if (this.getRowIndexByGridName(dgName) !== wkIndex) { return }
            daySlotsByWeek[wkIndex][dgName] = {
              0: EventKey
            }
          })
        // NOT the 1st occurance
        } else {
          const startDateGrid = mtdEvent.gridStart
          // the start date already got other mtdEvents
          if (daySlotsByWeek[wkIndex] && daySlotsByWeek[wkIndex][startDateGrid]) {
            // Loop till we get an available slot in this day
            let daySlotPriority = 0
            let posFound = false
            const skip = !mtdEvent.daysIncluded || !mtdEvent.daysIncluded.length
            while (!posFound && !skip) {
              if (!daySlotsByWeek[wkIndex][startDateGrid][daySlotPriority]) {
                mtdEvent.daysIncluded.forEach(dgName => {
                  if (this.getRowIndexByGridName(dgName) !== wkIndex) { return }
                  if (!daySlotsByWeek[wkIndex][dgName]) {
                    daySlotsByWeek[wkIndex][dgName] = {}
                  }
                  daySlotsByWeek[wkIndex][dgName][daySlotPriority] = EventKey
                })
                posFound = true
              } else {
                daySlotPriority++
              }
            }
            priorityInWeek[wkIndex][EventKey] = daySlotPriority
          // start date does NOT have multi-day events yet
          } else {
            if (!mtdEvent.daysIncluded || !mtdEvent.daysIncluded.length) { return }

            priorityInWeek[wkIndex][EventKey] = 0
            if (!daySlotsByWeek[wkIndex]) {
              daySlotsByWeek[wkIndex] = {}
            }
            mtdEvent.daysIncluded.forEach(dgName => {
              if (this.getRowIndexByGridName(dgName) !== wkIndex) { return }
              daySlotsByWeek[wkIndex][dgName] = {
                0: EventKey
              }
            })
          }
        }
      })

      const daySlotsWithMtdEvent = {}
      for (let week in daySlotsByWeek) {
        if (!week || !daySlotsByWeek[week]) { return }
        for (let daySlotName in daySlotsByWeek[week]) {
          if (!daySlotName || !daySlotsByWeek[week][daySlotName]) { return }
          daySlotsWithMtdEvent[daySlotName] = JSON.parse(JSON.stringify(daySlotsByWeek[week][daySlotName] || {}))
        }
      }

      let priorityByEventKey = {}
      for (let week in priorityInWeek) {
        if (!week || !priorityInWeek[week]) { return }
        priorityByEventKey = Object.assign({}, priorityByEventKey, priorityInWeek[week])
      }

      return {
        byDateGrid: daySlotsWithMtdEvent,
        byEventKey: priorityByEventKey
      }
    },

    // Estimate the possible max items count in one day slot
    maxItemCountInSlot () {
      let count = -1
      if (this.dateSlotHeight && this.eventItemBlockHeight) {
        count = ~~(this.dateSlotHeight / this.eventItemBlockHeight)
      }
      return count
    },

    localKey () {
      if (this.item && this.item.ref_id) {
        // Use the AppID as localStorage key
        return `ttvCalendar_${this.item.ref_id}`
      }
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    'calendarBoundary': {
      deep: true,
      handler () {
        this.checkValidEvents()
      }
    },

    'accountLocale.timezone': {
      handler () {
        this.fetchICals()
      }
    },

    deviceActiveLanguage () {
      this.genLocaleWeekdays()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.checkHeightTimer)

    clearInterval(this.tickTimer)

    // Show stale data first to speed up app display
    this.getStaleData()

    this.genLocaleWeekdays()
    this.checkCurrentDate()
    this.debounceCheckSize()
    this.calculateCalendarBoundary()
    this.render()

    clearInterval(this.icalTimer)
    this.icalTimer = setInterval(() => {
      this.fetchICals()
    }, config.ICAL_UPDATE_INTERVAL)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.checkHeightTimer)
    clearInterval(this.tickTimer)
    clearInterval(this.icalTimer)
  },

  methods: {
    calculateAllGridNames () {
      if (!this.dayBlocks || !this.dayBlocks.length) { return [] }
      this.allGridNames = this.dayBlocks.map(block => {
        return `dg_${block.date}`
      }) || []
    },

    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.title = options.title || ''
      this.latestYear = options.latestYear
      this.iCals = options.iCals || []

      if ('format24h' in options) {
        this.format24h = options.format24h === true || options.format24h === 'true'
      } else {
        this.format24h = true
      }
      this.fetchICals()
      this.restartCalendar(options)
    },

    calculateCalendarBoundary () {
      if (this.dayBlocks && this.dayBlocks.length) {
        let end = this.dayBlocks[this.dayBlocks.length - 1].date
        if (this.calEnd) {
          if (end > this.calEnd) {
            end = this.calEnd
          }
        }
        this.calendarBoundary = {
          start: this.dayBlocks[0].date,
          end
        }
      }
    },

    getRepeats (recurType) {
      const calStart = Moment(this.calendarBoundary.start, 'YYYY-MM-D')
      const calEnd = Moment(this.calEnd, 'YYYY-MM-D')
      const durMs = Moment.duration(calEnd.diff(calStart))
      if (this.showDateRange) {
        switch (recurType) {
          case 'YEARLY':
            return Math.floor(durMs.as('years')) + 1
          case 'MONTHLY':
            return Math.floor(durMs.as('months')) + 1
          case 'WEEKLY':
            return Math.floor(durMs.as('weeks')) + 1
          case 'DAILY':
            return Math.floor(durMs.as('days')) + 1
        }
      } else {
        return config[ICAL_RECUR_TYPES_COUNT[recurType]] || config.REPEAT_DEFAULT_COUNT
      }
    },

    restartCalendar (options) {
      clearInterval(this.tickTimer)

      let interval
      this.currentYearInRange = -1
      this.currentMonthInRange = -1
      this.showDateRange = options.showDateRange === 'true' || options.showDateRange === true
      this.showCurrentWeek = options.showCurrentWeek === 'true' || options.showCurrentWeek === true
      this.hideWeekends = options.hideWeekends === 'true' || options.hideWeekends === true

      if (this.showDateRange) {
        interval = options.interval
        this.yearFrom = +options.yearFrom
        this.monthFrom = +options.monthFrom
        this.yearTo = +options.yearTo
        this.monthTo = +options.monthTo
      } else {
        interval = 1
      }

      this.checkCurrentDate()

      this.tickTimer = setInterval(() => {
        this.checkCurrentDate()
      }, interval * 1000)
    },

    fetchICals () {
      if (!this.iCals || !this.iCals.length) { return }
      const identicalUrls = []
      const promises = []
      this.iCals.forEach((ical, index) => {
        if (ical && ical.url && ical.url.length && !identicalUrls.includes(ical.url)) {
          identicalUrls.push(ical.url)
          const iCalColor = ical.color || config.DEFAULT_ICAL_COLORS[index]
          promises.push(this.fetchICalendar(ical.url, iCalColor, index))
        }
      })

      Promise.all(promises).then(iCalResults => {
        let events = []
        iCalResults.forEach(resultObj => {
          if (!resultObj || !resultObj.iCalString) { return }
          const parseResult = this.parseICalendar(resultObj.iCalString, resultObj.iCalColor, resultObj.iCalIndex)
          events = [].concat([], events, parseResult)
        })

        events.sort((a, b) => {
          // Has the same start time
          if (a.ts === b.ts) {
            // Both are all-day or multi-day event
            if (a.inTopSlot && b.inTopSlot) {
              // multi-day > all-day
              if (a.multiDays && !b.multiDays) { return -1 }
              if (b.multiDays && !a.multiDays) { return 1 }
            }

            // all-day | multi-day > non-all-day
            if (a.inTopSlot && !b.inTopSlot) { return -1 }
            if (b.inTopSlot && !a.inTopSlot) { return 1 }

            // Sort by iCalIndex (sorted in app UI)
            if (a.iCalIndex > b.iCalIndex) { return 1 }
            if (a.iCalIndex < b.iCalIndex) { return -1 }

            return 0
          }

          // Sort by Timestamp
          if (a.ts > b.ts) { return 1 }
          if (a.ts < b.ts) { return -1 }

          return 0
        })

        this.events = events || []
        this.checkValidEvents()

        if (this.localKey) {
          OfflineCaches.set(this.localKey, events)
        }
        this.loaded()
      }).catch(() => {
        this.getStaleData()
      })
    },

    fetchICalendar (iCalURL, iCalColor, iCalIndex) {
      return this.$store.dispatch('getContentForURL', {
        url: iCalURL,
        // LRUCache accepts `maxAge` in ms.
        maxAge: CACHE_MAX_AGE,
        // Set a shorter cache time for iCals [DEV-2860]
        cacheName: 'ICAL_DATA'
      }).then(() => {
        const iCalString = this.$store.getters.contentForURL(iCalURL)
        return {
          iCalURL,
          iCalString,
          iCalColor,
          iCalIndex
        }
      }).catch(err => {
        Log.debug('app', `Calendar iCalendar fetch error - ${err.message || err.toString()}`, 'DBG_CALENDARFETCH', { url: iCalURL })
        // NOTE: Move the *real* error catcher to Promise.all
        throw err
      })
    },

    parseICalendar (iCalString, iCalColor, iCalIndex) {
      if (!iCalString) { return [] }
      let vevents
      try {
        const iCalParsed = ICAL.parse(iCalString)
        const vcalendar = new ICAL.Component(iCalParsed)
        vevents = vcalendar.getAllSubcomponents('vevent')
      } catch (err) {
        const errMessage = err.message || err.toString()
        const iCalURL = this.iCals && this.iCals[iCalIndex] && this.iCals[iCalIndex].url
        const lowerCasedMsg = errMessage.toLowerCase()

        // Add more details for BugSnag
        Log.debug('app', `Calendar app iCal parse error - ${errMessage} (${iCalURL})`, 'DBG_CALENDARPARSE', {url: iCalURL, error: err}, true)

        // Known error for invalid iCal Feed:
        // - invalid line (no token ";" or ":")
        // - Cannot read property 'property' of undefined
        // - Cannot read properties of undefined
        // - n.designSet is undefined
        // - undefined has no properties
        // - undefined is not an object (evaluating 'a.param')
        if (
          lowerCasedMsg.includes('invalid line') ||
          lowerCasedMsg.includes('cannot read property') ||
          lowerCasedMsg.includes('cannot read properties') ||
          lowerCasedMsg.includes('designset is undefined') ||
          lowerCasedMsg.includes('has no properties') ||
          lowerCasedMsg.includes('is not an object')
        ) {
          // Show warning message on top of the screen
          Log.error('app', `This iCalendar URL is not a valid iCal Feed: "${iCalURL}"`, 'ERR_CALENDARNOTVALIDURL', {url: iCalURL}, true)
          // Skip sending this known error to BugSnag
          return []
        // Known error for invalid iCal data:
        // - invalid ical body. component began but did not end
        } else if (lowerCasedMsg.includes('invalid ical body')) {
          Log.error('app', `The iCalendar data is invalid: "${iCalURL}"`, 'ERR_CALENDARDATAINVALID', {url: iCalURL}, true)
          return []
        }
        Log.error('app', `Calendar app iCal parse error - ${errMessage}`, 'ERR_CALENDARPARSE', err)
        return []
      }

      if (!vevents || !vevents.length) {
        return []
      }

      let calStart
      if (this.calendarBoundary && this.calendarBoundary.start) {
        calStart = Moment(this.calendarBoundary.start, 'YYYY-MM-D')
      } else {
        // Fallback to use this month's start
        calStart = Moment().startOf('month')
      }
      calStart = (calStart.hour(0).minute(0).second(0)).valueOf()

      let calEnd
      if (this.latestYear) {
        calEnd = Moment([this.latestYear, 12, 31], 'YYYY-MM-D')
      } else {
        // Fallback to use this month's end
        calEnd = Moment().endOf('month')
      }
      this.calEnd = calEnd.add(7, 'days').format('YYYY-MM-DD')
      calEnd = (calEnd.hour(23).minute(59).second(59)).valueOf()

      // ==========================================================
      // Filter out outdated events to speed up parsing [DEV-2771]

      // [O] Recurrence Exception Instances [DEV-2876]
      const RecurrenceExecptions = []

      // [A] One Time events
      const oneTimeEvents = vevents.filter((vevent, veIdx) => {
        const rrule = vevent.getFirstPropertyValue('rrule')
        const recurrenceId = vevent.getFirstPropertyValue('recurrence-id')

        // Has "rrule" >> recurring event
        if (rrule) { return false }

        const dtend = vevent.getFirstPropertyValue('dtend')
        const dtstart = vevent.getFirstPropertyValue('dtstart')

        // Rough test. Rule out any events that ends 24hrs before calendar start.
        if (dtend && (dtend.toUnixTime() * 1000 + MAX_UTC_OFFSET) < calStart) {
          return false
        }
        // Rough test. Rule out any events that starts 24hrs after calendar end.
        if (dtstart && (dtstart.toUnixTime() * 1000 - MAX_UTC_OFFSET) >= calEnd) {
          return false
        }

        // A particular altered instance of a recurring event
        // - "RECURRENCE-ID" property is used in conjunction with the "UID" and "SEQUENCE" properties to identify a particular instance of a recurring event,
        // > https://icalendar.org/iCalendar-RFC-5545/3-8-4-4-recurrence-id.html
        if (!rrule && recurrenceId) {
          RecurrenceExecptions.push(vevent)
        }

        return true
      })

      // [B] Reurring events
      const recurringEvents = vevents.filter((vevent, veIdx) => {
        const rrule = vevent.getFirstPropertyValue('rrule')

        // Does NOT have "rrule" >> one time
        if (!rrule) { return false }

        const rruleUntil = rrule && rrule.until

        // NOTE
        // a. `rruleUntil` is undefined - infinite repeat event
        // b. `rruleUntil` is defined - finite recurring event with an endtime
        if (rruleUntil && (rruleUntil.toUnixTime() * 1000) < calStart) {
          return false
        }
        return true
      })
      // EOF quick filtering
      // ==========================================================

      // ================================
      // Recurrence Exception Instances
      // ================================

      const recurExceptions = []

      if (RecurrenceExecptions.length) {
        RecurrenceExecptions.forEach((vevent, veIdx) => {
          const dstart = vevent.getFirstPropertyValue('dtstart')
          const item = {
            uid: vevent.getFirstPropertyValue('uid'),
            summary: vevent.getFirstPropertyValue('summary'),
            sequence: vevent.getFirstPropertyValue('sequence'),
            recurrenceId: vevent.getFirstPropertyValue('recurrence-id'),
            dtstart: dstart,
            dtend: vevent.getFirstPropertyValue('dtend'),
            startTs: dstart.toUnixTime(),
            vevent: JSON.parse(JSON.stringify(vevent))
          }
          recurExceptions.push(item)
        })
      }

      const events = []

      // ========================
      // NON-Recurring Events
      // ========================

      oneTimeEvents.forEach((vevent, veIdx) => {
        const summary = vevent.getFirstPropertyValue('summary')
        const dtstart = vevent.getFirstPropertyValue('dtstart')
        const dtend = vevent.getFirstPropertyValue('dtend')
        const duration = vevent.getFirstPropertyValue('duration')

        let durationMinute = 1440
        if (duration) {
          durationMinute = duration.toSeconds() / 60
        } else if (dtend) {
          durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
        }

        const allDay = (durationMinute % 1440 === 0)
        const multiAllDay = (allDay && durationMinute > 1440)
        const multiDays = durationMinute > 1440
        const inTopSlot = allDay || multiDays

        let start = Moment.tz(dtstart.toUnixTime() * 1000, 'UTC')
        try {
          // Adjust time with event timezone
          if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
            const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(dtstart.toUnixTime() * 1000)
            start.add(utcOffsetFix, 'minutes')
          }
        } catch (e) {}

        // All-day events don't have "timezone" property, hence no need to adjust
        if (!allDay) {
          start = start.tz(this.accountLocale.timezone)
        }

        const startDate = start.format('YYYY-MM-DD')
        const event = {
          title: summary || '',
          date: startDate,
          gridStart: `dg_${startDate}`,
          time: this.formatTime(start),
          ts: start.unix(),
          duration: durationMinute,
          allDay,
          multiAllDay,
          multiDays,
          inTopSlot,
          color: iCalColor,
          iCalIndex
        }

        if (multiDays) {
          let daySpan
          let endDate

          // Multi-All-Day Events
          if (multiAllDay) {
            daySpan = ~~(durationMinute / 1440)
            endDate = start.clone().add(daySpan - 1, 'days').format('YYYY-MM-DD')
          // Multi-day, but NOT all day
          } else {
            const endTime = start.clone().add(durationMinute, 'minutes')
            endDate = endTime.format('YYYY-MM-DD')

            const startDay = start.clone().hour(0).minute(0).second(0).millisecond(0)
            const endDay = endTime.hour(23).minute(59).second(59).millisecond(999)
            daySpan = (endDay.valueOf() + 1 - startDay.valueOf()) / (86400 * 1000)
          }

          event.endDate = endDate
          event.daySpan = daySpan
          event.gridEnd = `dg_${endDate}`
          event.daysIncluded = this.getDaysIncluded(startDate, daySpan)
        }
        events.push(event)
      }) // EOF oneTimeEvents

      // ========================
      // Recurring Events
      // ========================

      recurringEvents.forEach((vevent, veIdx) => {
        const summary = vevent.getFirstPropertyValue('summary')
        const dtstart = vevent.getFirstPropertyValue('dtstart')
        const dtend = vevent.getFirstPropertyValue('dtend')
        const duration = vevent.getFirstPropertyValue('duration')

        let durationMinute = 1440
        if (duration) {
          durationMinute = duration.toSeconds() / 60
        } else if (dtend) {
          durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
        }

        const allDay = (durationMinute % 1440 === 0)
        const multiAllDay = (allDay && durationMinute > 1440)
        const multiDays = durationMinute > 1440
        const inTopSlot = allDay || multiDays

        const icEvent = new ICAL.Event(vevent)

        // Get Recurrence Type to reduce "while" loops [DEV-2771]
        // > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
        const recurType = this.getRecurType(icEvent.getRecurrenceTypes())
        const maxRepeatCountByType = this.getRepeats(recurType)

        const rrule = vevent.getFirstPropertyValue('rrule')
        const rruleUntil = rrule && rrule.until

        const uid = vevent.getFirstPropertyValue('uid')
        const sequence = vevent.getFirstPropertyValue('sequence')

        const expand = new ICAL.RecurExpansion({
          component: vevent,
          dtstart: dtstart
        })

        let start
        let end
        let testEnd

        let next = dtstart
        let reachEndBoundary = false

        // Count for valid (ts >= calStart && ts < calEnd) item
        let count = 0

        while (
          !reachEndBoundary &&
          count < maxRepeatCountByType &&
          (next = expand.next())
        ) {
          if (
            !next ||
            (!!rruleUntil && (next.toUnixTime() + (MAX_UTC_OFFSET / 1000) > rruleUntil.toUnixTime()))
          ) {
            // No valid "next" found, or it already passed the rule until. Break the while loop.
            return
          }

          start = Moment.tz(next.toUnixTime() * 1000, 'UTC')
          testEnd = start.clone().add(durationMinute, 'minutes')

          // Rough test. Rule out any events that ends 24hrs before calendar start.
          if ((testEnd.valueOf() + MAX_UTC_OFFSET) < calStart) {
            continue
          }
          // Rough test. Rule out any events that starts 24hrs after calendar end.
          if ((start.valueOf() - MAX_UTC_OFFSET) > calEnd) {
            // Use "return" to break the loop here because it (and all upcoming "next" iterators) already reaches the calendar boundary
            reachEndBoundary = true
            return
          }

          if (recurExceptions.length) {
            const exception = recurExceptions.find(item => {
              return (item.uid === uid && item.sequence === sequence && item.startTs === next.toUnixTime())
            })
            if (exception) {
              // [DEV-2876] Current recurring instance matches one of the Recurring Exceptions.
              // Any recurring exceptions is displayed as one-time event, so we should skip it in the recurring list
              continue
            }
          }

          try {
            // Adjust time with event timezone
            if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
              const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(next.toUnixTime() * 1000)
              start.add(utcOffsetFix, 'minutes')
            }
          } catch (e) {}

          const startTs = start.valueOf()

          // NOTE (DEV-2876): Final test for `rruleUntil`
          // Coz' `rruleUntil` is always in UTC time, while `next` may contain timezone info
          if (rruleUntil && startTs > (rruleUntil.toUnixTime() * 1000)) {
            continue
          }

          end = start.clone().add(durationMinute, 'minutes')

          // Final tests - only display events strictly within the calendar boundary
          const endTs = end.valueOf()
          if (
            !(startTs >= calStart && endTs <= calEnd) &&
            !(startTs < calStart && endTs >= calStart && endTs <= calEnd)
          ) {
            continue
          }

          // All-day events don't have "timezone" property, hence no need to adjust
          if (!allDay) {
            start = start.tz(this.accountLocale.timezone)
          }

          const startDate = start.format('YYYY-MM-DD')
          const event = {
            title: summary || '',
            date: startDate,
            gridStart: `dg_${startDate}`,
            time: this.formatTime(start),
            ts: start.unix(),
            duration: durationMinute,
            allDay,
            multiAllDay,
            multiDays,
            inTopSlot,
            color: iCalColor,
            iCalIndex
          }
          if (multiDays) {
            let daySpan
            let endDate

            // Multi-All-Day Events
            if (multiAllDay) {
              daySpan = ~~(durationMinute / 1440)
              endDate = start.clone().add(daySpan - 1, 'days').format('YYYY-MM-DD')
            // Multi-day, but NOT all day
            } else {
              const endTime = start.clone().add(durationMinute, 'minutes')
              endDate = endTime.format('YYYY-MM-DD')

              const startDay = start.clone().hour(0).minute(0).second(0).millisecond(0)
              const endDay = endTime.hour(23).minute(59).second(59).millisecond(999)
              daySpan = (endDay.valueOf() + 1 - startDay.valueOf()) / (86400 * 1000)
            }

            event.endDate = endDate
            event.daySpan = daySpan
            event.gridEnd = `dg_${endDate}`
            event.daysIncluded = this.getDaysIncluded(startDate, daySpan)
          }

          count++

          events.push(event)
        } // EOF while
      }) // EOF recurringEvents

      return events || []
    },

    checkValidEvents () {
      if (!this.events || !this.events.length ||
          !this.calendarBoundary || !this.calendarBoundary.start || !this.calendarBoundary.end
      ) {
        this.validEvents = []
        return
      }

      const calStart = Moment(this.calendarBoundary.start, 'YYYY-MM-D').hour(0).minute(0).unix()
      const calEnd = Moment(this.calendarBoundary.end, 'YYYY-MM-D').hour(23).minute(59).unix()
      const list = this.events.filter(event => {
        const endTime = event && (event.ts + event.duration * 60)
        return event && (
          (event.ts >= calStart && event.ts <= calEnd) ||
          // Event starts from the previous boundary but ends in this boundary
          (event.ts < calStart && endTime >= calStart && endTime <= calEnd)
        )
      }) || []

      list.forEach((event, eIndex) => {
        event.key = this.genEventKey(event, eIndex)
      })

      this.validEvents = list
      this.debounceCheckSize()
    },

    genEventKey (event, eventIndex) {
      let keyParts = (event.title || '').replace(/[^a-zA-Z0-9]/g, '')
      if (event.allDay) {
        keyParts += `-allday-${event.ts}`
      } else {
        keyParts += `-${event.ts}-${event.duration}min`
      }
      return `cal-${this._uid}_${eventIndex || 0}_${keyParts}`
    },

    checkCurrentDate () {
      let recalculateCalendarBoundary, recalculateDaysIncluded, currentDate
      const now = Moment()

      if (this.showDateRange) {
        recalculateCalendarBoundary = true
        if (this.currentYearInRange === -1) {
          this.currentYearInRange = this.yearFrom
        }
        if (this.currentMonthInRange === -1) {
          this.currentMonthInRange = this.monthFrom
        }
        this.today = now.year() === this.currentYearInRange && now.month() === this.currentMonthInRange ? +now.format('D') : 0
        currentDate = Moment([this.currentYearInRange, this.currentMonthInRange, 1])
        this.currentMonthInRange++
        recalculateDaysIncluded = true
        if (this.currentYearInRange >= this.yearTo && this.currentMonthInRange > this.monthTo) {
          this.currentYearInRange = this.yearFrom
          this.currentMonthInRange = this.monthFrom
        }
        if (this.currentMonthInRange > 11) {
          this.currentMonthInRange -= 12
          this.currentYearInRange++
        }
      } else {
        currentDate = now
        this.today = +currentDate.format('D')
      }

      const previousThisMonth = this.thisMonth + ''

      this.thisMonth = currentDate.format('MMMM YYYY')

      const firstDayOfMonth = currentDate.clone().startOf('month')
      const lastDayOfMonth = currentDate.clone().endOf('month')

      const daysInMonth = currentDate.daysInMonth()

      const firstDayInWeek = firstDayOfMonth.weekday()
      const daysInTheLastWeek = (daysInMonth + firstDayInWeek) % 7
      const extraWeek = (daysInTheLastWeek > 0 && (daysInTheLastWeek <= firstDayInWeek)) ? 1 : 0

      this.startOfThisMonth = firstDayOfMonth.format('MMM D')

      const endOfLastMonth = firstDayOfMonth.clone().subtract(1, 'days')
      this.endOfLastMonth = endOfLastMonth.format('D')

      const startOfNextMonth = lastDayOfMonth.clone().add(1, 'days')
      this.startOfNextMonth = startOfNextMonth.format('MMM D')

      recalculateCalendarBoundary = recalculateCalendarBoundary || this.previousShowDateRange !== this.showDateRange
      if (this.previousHideWeekends !== this.hideWeekends) {
        this.recalculateDayBlocks = this.previousHideWeekends !== this.hideWeekends || this.previousShowCurrentWeek !== this.showCurrentWeek
        this.genLocaleWeekdays()
      }

      this.previousHideWeekends = this.hideWeekends
      this.previousShowCurrentWeek = this.showCurrentWeek
      this.previousShowDateRange = this.showDateRange

      // Calendar month is already set and didn't changed
      // Skip the following process
      if (!this.recalculateDayBlocks && previousThisMonth && previousThisMonth === this.thisMonth) {
        return
      }

      const weeksInMonth = Math.ceil((lastDayOfMonth.valueOf() + 1 - firstDayOfMonth.valueOf()) / (86400 * 7 * 1000)) + extraWeek
      this.weeksInMonth = weeksInMonth
      this.weeksToShow = this.showCurrentWeek ? 1 : weeksInMonth

      const dayBlocks = []

      const firstDayWeekdayIndex = +firstDayOfMonth.format('d')
      const lastMonth = endOfLastMonth.format('YYYY-MM')
      const thisMonth = currentDate.format('YYYY-MM')
      const nextMonth = startOfNextMonth.format('YYYY-MM')

      // Days from Last Month
      const fillDaysLastMonth = +firstDayWeekdayIndex
      if (fillDaysLastMonth > 0) {
        let lastMonthDate = +this.endOfLastMonth
        for (let i = 0; i < fillDaysLastMonth; i++) {
          dayBlocks.unshift({
            date: `${lastMonth}-${lastMonthDate}`,
            day: +lastMonthDate,
            inLastMonth: true
          })
          lastMonthDate--
        }
      }

      // Days in This Month
      for (let j = 1; j <= daysInMonth; j++) {
        dayBlocks.push({
          date: `${thisMonth}-${j < 10 ? '0' : ''}${j}`,
          day: +j,
          inThisMonth: true
        })
      }

      // Days from Next Month
      const blocksLeft = this.totalDayBlocksCount - dayBlocks.length
      if (this.totalDayBlocksCount && blocksLeft > 0) {
        for (let k = 1; k <= blocksLeft; k++) {
          dayBlocks.push({
            date: `${nextMonth}-0${k}`,
            day: +k,
            inNextMonth: true
          })
        }
      }

      if (this.showCurrentWeek) {
        const todayIndex = dayBlocks.findIndex(block => !block.inLastMonth && !block.inNextMonth && block.day === this.today)
        const weekNumber = Math.floor(todayIndex / 7)
        let indexFrom = weekNumber * 7
        let indexTo = indexFrom + 7
        if (this.hideWeekends) {
          indexFrom++
          indexTo--
        }
        this.dayBlocks = dayBlocks.slice(indexFrom, indexTo)
      } else if (this.hideWeekends) {
        this.dayBlocks = dayBlocks.map((block, i) => this.isWeekendBlock(i) ? null : block).filter(block => block)
        if (this.dayBlocks[this.dayBlocks.length - 5].inNextMonth) {
          this.dayBlocks = this.dayBlocks.slice(0, this.dayBlocks.length - 5)
          this.weeksToShow--
        }
        if (this.dayBlocks[4].inLastMonth) {
          this.dayBlocks = this.dayBlocks.slice(5)
          this.weeksToShow--
        }
      } else {
        this.dayBlocks = dayBlocks
      }
      this.calculateAllGridNames()
      if (recalculateDaysIncluded) {
        this.events.forEach(event => {
          if (event.multiDays) {
            let daySpan

            // Multi-All-Day Events
            if (event.multiAllDay) {
              daySpan = ~~(event.durationMinute / 1440)
            // Multi-day, but NOT all day
            } else {
              const start = Moment(event.ts * 1000)
              const endTime = start.clone().add(event.duration, 'minutes').add(999, 'milliseconds')

              const startDay = start.clone().hour(0).minute(0).second(0).millisecond(0)
              const endDay = endTime.hour(23).minute(59).second(59).millisecond(999)
              daySpan = (endDay.valueOf() + 1 - startDay.valueOf()) / (86400 * 1000)
            }

            event.daysIncluded = this.getDaysIncluded(event.date, daySpan)
          }
        })
      }

      if (recalculateCalendarBoundary) {
        this.calculateCalendarBoundary()
      }
    },

    isWeekendBlock (blockIndex) {
      return blockIndex % 7 === 0 || blockIndex % 7 === 6
    },

    // Localised weekdays (DEV-1485)
    genLocaleWeekdays () {
      const now = Moment()
      const list = []
      for (let i = 0; i < 7; i++) {
        if (!(this.hideWeekends && this.isWeekendBlock(i))) {
          list.push(now.day(i).format('ddd'))
        }
      }
      this.localeWeekdays = list
    },

    getDaysIncluded (startDate, daySpan) {
      if (!startDate || !daySpan) { return }
      const startIndex = this.allGridNames.findIndex(gn => gn === `dg_${startDate}`)
      if (startIndex === -1) { return }
      return this.allGridNames.slice(startIndex, startIndex + daySpan) || []
    },

    getRowIndexByGridName (gridName) {
      // NOTE: Only works with multi-day events grid `dg_YYYY-MM-DD`
      if (!gridName) { return -1 }
      if (!this.allGridNames || !this.allGridNames.length) { return -1 }

      const inGridIndex = this.allGridNames.findIndex(gn => gn === gridName)
      if (inGridIndex < 0) { return -1 }
      return ~~(inGridIndex / 7)
    },

    getRecurType (typesObj) {
      const keys = Object.keys(typesObj) || []
      if (!keys || !keys.length) { return }
      return keys[0]
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
        this.debounceCheckHeights()
      }, timeout || 200)
    },

    debounceCheckHeights () {
      // Debounce to get a more precise height
      clearTimeout(this.checkHeightTimer)
      this.checkHeightTimer = setTimeout(() => {
        clearTimeout(this.checkHeightTimer)
        this.getDateBlockSize()
        this.getDayItemHeight()
      }, 50)
    },

    getDateBlockSize () {
      const firstDateBlockTitle = this.$el.querySelectorAll('.month-day-grid .day-title')[0]
      const firstDateBlockSlot = this.$el.querySelectorAll('.month-day-grid .day-events-container')[0]

      if (!firstDateBlockTitle || !firstDateBlockSlot) { return }

      let titleHeight
      let slotHeight
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!firstDateBlockTitle) {
          FastDom.clear(measure)
          return
        }

        titleHeight = firstDateBlockTitle.getBoundingClientRect().height
        slotHeight = firstDateBlockSlot.getBoundingClientRect().height

        if (!titleHeight || !slotHeight) {
          FastDom.clear(measure)
          return
        }

        this.dateTitleHeight = titleHeight
        this.dateSlotHeight = slotHeight

        FastDom.clear(measure)
      })
    },

    getDayItemHeight () {
      // ".multi-days-event" - fixes height calculation for iCals with multi-day events only (DEV-2842)
      const firstEventItem = this.$el.querySelectorAll('.event-item')[0] || this.$el.querySelectorAll('.multi-days-event')[0]

      if (!firstEventItem) {
        return
      }

      let height
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!firstEventItem) {
          FastDom.clear(measure)
          return
        }

        height = firstEventItem.getBoundingClientRect().height

        if (!height) {
          FastDom.clear(measure)
          return
        }

        this.eventItemBlockHeight = height

        FastDom.clear(measure)
      })
    },

    multiDayGridStyle (event) {
      if (!event || !event.multiDays) { return }
      return {
        'grid-column-start': event.colIndex + 1,
        'grid-column-end': `span ${event.colSpan}`,
        'grid-row': event.rowIndex + 1
      }
    },

    multiDayVertPos (eventKey) {
      if (!eventKey || !this.multiDayEventsPriority || !this.multiDayEventsPriority.byEventKey) {
        return
      }

      const inDayPriority = this.multiDayEventsPriority.byEventKey[eventKey] || 0
      const itemGap = 1

      if (this.dateTitleHeight && this.eventItemBlockHeight) {
        const vertOffset = this.dateTitleHeight + (this.eventItemBlockHeight + itemGap) * inDayPriority
        return {
          marginTop: `${vertOffset}px`,
          visibility: 'visible'
        }
      }
    },

    isVisibleInSlot (eventKey) {
      if (this.maxItemCountInSlot > 0 && this.multiDayEventsPriority && this.multiDayEventsPriority.byEventKey) {
        const inDayPriority = this.multiDayEventsPriority.byEventKey[eventKey] || 0
        return inDayPriority <= this.maxItemCountInSlot - 1
      }
      return false
    },

    async getStaleData () {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData && staleData.length) {
          this.events = staleData
          this.checkValidEvents()
        }
      }
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    },

    formatTime (start) {
      return this.format24h ? start.format(this.$t('_common.time.format_hmm')) : start.format(this.$t('_common.time.format_hmma'))
    }
  }
}
</script>

<template lang="pug">
section.calendar-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait}]"
                    :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .calendar-wrapper.app-context-block(:class="{'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}"
                                      :style="boxMarginStyle")
    .calender-inner-wrapper.app-context-section.primary
      .header-section
        .calendar-title(v-if="title && title.length") {{ title }}
        .current-month {{ thisMonth }}

      .body-section
        //- Weekdays Header
        .weekdays-header(:style="[weekdayGridStyles]")
          .weekday(v-for="(weekday, wdIndex) in localeWeekdays", :key="wdIndex") {{ weekday }}

        .grid-wrapper
          //- Calendar (Month) Grid
          .calendar-grid(:class="{invisible: weeksToShow <= 0}"
                         :style="[monthGridStyles]")
            template(v-if="weeksToShow > 0")
              .month-day-grid(v-for="(day, dbIndex) in dayBlocks", :key="day.key"
                              :class="{'is-weekend': !hideWeekends && isWeekendBlock(dbIndex)}")

                .day-grid-inner
                  .day-title(:class="{'not-this-month': !day.inThisMonth}")
                    span.display-day(:class="{'is-today': day.inThisMonth && day.day === today}")
                      template(v-if="day.inThisMonth && day.day === 1") {{ startOfThisMonth }}
                      template(v-else-if="day.inNextMonth && day.day === 1") {{ startOfNextMonth }}
                      template(v-else) {{ day.day }}

                  .day-events-container
                    day-events-slot(:grid-name="allGridNames[dbIndex]"
                                    :events="validEvents"
                                    :mtd-priority="multiDayEventsPriority"
                                    :light-theme="!whiteText"
                                    :show-ical-color="showIcalColor"
                                    :ical-flag-style="icalFlagStyle"
                                    :max-item-count="maxItemCountInSlot"
                                    :is-portrait="isPortrait")

          //- Events Grid
          //- For multi-all-day events
          .event-grid(:style="[monthGridStyles, calGridTplAreas]")
            template(v-if="visibleMultiDayBlocks && visibleMultiDayBlocks.length")
              .multi-days-event.in-grid(v-for="(mtdEvent, rIndex) in visibleMultiDayBlocks", :key="mtdEvent.key"
                                  :class="{'show-ical-color': showIcalColor, 'invisible': !isVisibleInSlot(mtdEvent.key)}"
                                  :data-startdate="mtdEvent.date"
                                  :data-enddate="mtdEvent.endDate"
                                  :style="[multiDayGridStyle(mtdEvent), multiDayVertPos(mtdEvent.key)]")
                .multi-day-inner
                  .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: mtdEvent.color}]")
                  //- Display start time for multi-day but NOT multi-all-day events [DEV-3048]
                  .start-time(v-if="mtdEvent.multiDays && !mtdEvent.allDay") {{ mtdEvent.time }}
                  .event-title {{ mtdEvent.title }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

// Using solid color here to prevent seeing odd dots at the grids' joint
$borderForDarkBg = #999
$borderForLightBg = #ccc

section.calendar-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  // iCal Color Flag
  .ical-color
    border: 0.1vmin solid -black(0.2)
    box-sizing: content-box
    border-radius: 2em

  .calendar-wrapper
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden

  .calender-inner-wrapper
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch

    .header-section
      display: flex
      flex-flow: row nowrap
      justify-content: flex-start
      align-items: center
      font-size: 3em
      line-height: 130%
      font-weight: 600
      padding: 0.3em 0.5em
      .calendar-title
        flex: 1 1 0.00001px
        text-transform: capitalize
        ellipsis()

    .body-section
      flex: 1 1 0.00001px
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch

      .weekdays-header
        display: grid
        grid-template-rows: auto
        .weekday
          line-height: 150%
          padding-bottom: 0.3em;
          text-align: center
          font-weight: 300
          opacity: 0.7

      .grid-wrapper
        flex: 1 1 0.00001px
        position: relative

    .calendar-grid,
    .event-grid
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0

      display: grid

      // Fixes for `auto` row height display in Safari [DEV-1047]
      height: 100%

    .calendar-grid
      z-index: 0
      // Same value as per grid-item's outline width
      // A trick to make outline look "collapsed"
      grid-gap: 1px

      &.invisible
        opacity: 0
        visibility: hidden

      .month-day-grid
        outline: 1px solid $borderForDarkBg
        position: relative
        &.is-weekend
          background-color: -white(0.1)

    .event-grid
      overflow: hidden
      z-index: 1
      align-items: start
      pointer-events: none
      grid-gap: 1px

    .day-grid-inner
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch

    .day-title
      overflow: hidden
      text-align: right
      padding: 0.5em 0.3em
      &.not-this-month
        opacity: 0.4

      .display-day
        font-size: 1.8em
        line-height: 130%
        display: inline-flex
        justify-content: center
        align-items: center
        height: 1.5em
        padding: 0 0.5em

        &.is-today
          border-radius: 5em
          background-color: #fff
          color: $appDarkTextColor
          text-shadow: none
          appSmallBoxShadow()

    .day-events-container
      flex: 1 1 0.0001px
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch

    .multi-days-event
      margin: 0 0.2em 1px 0.2em
      pointer-events: initial
      border-radius: 0.15em
      padding: 0.1em 0.3em
      overflow: hidden

      background-color: -white(0.7)
      color: $appDarkTextColor
      text-shadow: none

      font-size: 1.5em

      // Hide before complete rendering
      visibility: hidden

      &.invisible
        visibility: hidden !important

      .multi-day-inner
        max-width: 100%
        overflow: hidden
        display: flex
        flex-flow: row nowrap
        justify-content: flex-start
        align-items: baseline

      .start-time
        margin-right: 0.5em
        opacity: 0.6

      .event-title
        ellipsis()
        flex: 1 1 0.00001px

  //
  // Light Theme
  //
  .calendar-wrapper
    &.dark-text
      .day-title
        .display-day
          &.is-today
            background-color: $appDarkTextColor
            color: #fff
            box-shadow: none

      .calendar-grid
        .month-day-grid
          outline: 1px solid $borderForLightBg

      .multi-days-event
        appTextShadow()
        color: #fff
        background-color: -black(0.6)
</style>
