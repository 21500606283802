<script>
const DEFAULT_CUSTOM_BG = '#000'

export default {
  name: 'Background',

  props: {
    theme: {
      type: Object,
      default: () => { return {} }
    },

    // Accepts any String that fits the CSS "background" style
    // NOTE: Will ignored the "theme" option when this value is set
    overrideBackground: {
      type: String
    }
  },

  computed: {
    noTheme () {
      return Boolean(this.theme && this.theme.theme === 'none')
    },

    useTTVTheme () {
      return Boolean(this.theme && this.theme.theme === 'predefined')
    },

    themeClass () {
      if (!this.useTTVTheme) { return }
      if (this.theme.themeName && this.theme.themeName.length) {
        return `ttv-${this.theme.themeName}`
      }
      return 'ttv-default'
    },

    useCustomTheme () {
      return Boolean(this.theme && this.theme.theme === 'custom')
    },

    showGradientOverlay () {
      if (this.themeClass && this.themeClass !== 'ttv-default') {
        return true
      }
      if (this.useCustomTheme && (this.theme.overlay === 'true')) {
        return true
      }
      return false
    },

    finalBgStyle () {
      // Override
      if (this.overrideBackground && this.overrideBackground.length) {
        return {
          background: this.overrideBackground
        }
      }
      // Custom Theme
      if (this.useCustomTheme) {
        return {
          background: this.theme.customColor || DEFAULT_CUSTOM_BG
        }
      }
    }
  }
}
</script>

<template lang="pug">
section.ttv-background(:class="[themeClass, {'none': noTheme, 'gradient-overlay': showGradientOverlay}]"
       :style="finalBgStyle")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.ttv-background
  width: 100%
  height: 100%
  position: absolute
  z-index: 0
  
  // Default to new gradient background as per js_user
  background: linear-gradient(180deg, #072B46 30%, #125462 90%)
  
  // TTV Predefined Theme
  themeColors()

  &.gradient-overlay
    &:before
      content: ''
      position: absolute
      z-index: 1
      top: 0
      bottom: 0
      left: 0
      right: 0
      background: linear-gradient(180deg, -white(0.1) 0%, -black(0.1) 100%)
  
  &.none
    background: #000
</style>
