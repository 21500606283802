<script>
import qs from 'qs'

import Bowser from 'bowser'

import Twitch from 'services/twitch.js'
import Env from 'services/environment'

export default {
  name: 'TwitchItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      url: '',
      urlType: '',
      twitchContentId: '',
      muted: true
    }
  },

  computed: {
    playerID () {
      return `twitch_player_${this._uid}`
    },

    chatID () {
      return `twitch_chat_${this._uid}`
    },

    parentParams () {
      // For sites with multiple subdomains, we must have one parent key for each (sub)domain
      // > https://discuss.dev.twitch.tv/t/twitch-embedded-player-updates-in-2020/23956/119
      switch (Env.stage) {
        case 'production':
          return 'parent=player.telemetrytv.com&parent=app.telemetrytv.com'
        case 'stage':
          return 'parent=stage-player.telemetrytv.com&parent=stage-app.telemetrytv.com'
        case 'beta':
          return 'parent=beta-player.telemetrytv.com&parent=beta-app.telemetrytv.com'
        case 'rc':
          return 'parent=rc-player.telemetrytv.com&parent=rc-app.telemetrytv.com'
        case 'test':
          return 'parent=test-player.telemetrytv.com&parent=test-app.telemetrytv.com'
        case 'qa':
          return 'parent=qa-player.telemetrytv.com&parent=qa-app.telemetrytv.com'
        case 'local':
          return 'parent=localhost'
        default:
          return 'parent=127.0.0.1'
      }
    },

    browserInfo () {
      const bs = Bowser.getParser(window.navigator.userAgent)
      return bs.getBrowser() || {}
    },

    // Force mute all videos in Safari
    isSafari () {
      return (this.browserInfo.name || '').toLowerCase() === 'safari'
    },

    forceMute () {
      // When viewing in Safari, Or being embedded (in js_user etc), force mute all videos
      return this.isSafari ||
        (this.$route && this.$route.query && (
          (this.$route.query.forceMute === 'true' || this.$route.query.forceMute === true) ||
          (this.$route.query.mute_videos === 'true' || this.$route.query.mute_videos === true)
        ))
    },

    showChat () {
      return (this.urlType === 'channel' && this.chat && (this.twitchContentId && this.twitchContentId.length))
    },

    playerEmbedURL () {
      if (!this.urlType || !this.urlType.length || !this.twitchContentId) { return }

      // Clips
      if (this.urlType === 'clip') {
        return `https://clips.twitch.tv/embed?clip=${this.twitchContentId}&${this.parentParams}&muted=${this.mute}&autoplay=true&preload=none`
      }

      // Video / Channel / Collection
      return `https://player.twitch.tv/?${this.urlType}=${this.twitchContentId}&${this.parentParams}&muted=${this.mute}&autoplay=true`
    },

    chatEmbedURL () {
      if (!this.showChat) { return }
      return `https://www.twitch.tv/embed/${this.twitchContentId}/chat?${this.parentParams}`
    }
  },

  watch: {
    'item.url' () {
      this.render()
    }
  },

  mounted () {
    this.render()
  },

  methods: {
    render () {
      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.url = options.url || ''
      this.urlType = Twitch.getTypeFromURL(options.url)

      this.mute = this.forceMute || (options.mute === 'true' || options.mute === true)
      this.chat = (options.chat === 'true' || options.chat === true)

      if (!this.urlType) {
        this.twitchContentId = ''
      }

      if (this.urlType === 'video') {
        this.twitchContentId = Twitch.getVideoIdFromURL(this.url)
      } else if (this.urlType === 'collection') {
        this.twitchContentId = Twitch.getCollectionIdFromURL(this.url)
      } else if (this.urlType === 'clip') {
        this.twitchContentId = Twitch.getClipSlugFromURL(this.url)
      } else {
        this.twitchContentId = Twitch.getChannelIdFromURL(this.url)
      }
    }
  }
}
</script>

<template lang="pug">
section.twitch-app
  .twitch-player-frame
    //- Prevent users from clicking the elements within the iframe
    .overlay-mask
    iframe(v-if="playerEmbedURL && playerEmbedURL.length"
           :id="playerID"
           :src="playerEmbedURL"
           width="100%"
           height="100%"
           allow="autoplay; fullscreen"
           allowfullscreen
           seamless)
  .twitch-chat-frame(v-if="showChat")
    iframe(v-if="chatEmbedURL && chatEmbedURL.length"
           :id="chatID"
           :src="chatEmbedURL"
           width="100%"
           height="100%"
           seamless)
</template>

<style lang="stylus">
section.twitch-app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 0

  display: flex
  flex-flow: row nowrap
  justfy-content: space-between
  align-items: stretch

  .twitch-player-frame
    flex: 1 1 0.0001px
    position: relative

  .twitch-chat-frame
    width: 25%
    position: relative
    z-index: 0

  .overlay-mask
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 10
    background: transparent

  iframe
    position: relative
    z-index: 1
    width: 100%
    height: 100%
    border: 0
    background: transparent
</style>
