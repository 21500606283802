<script>
import qs from 'qs'
import {
  mapGetters,
  mapState
} from 'vuex'

import HtmlContent from 'services/html-content.js'

import Logo from './Logo.vue'
import PlaylistFooter from './PlaylistFooter.vue'
import HtmlItem from 'components/items/HtmlItem.vue'

// Load Staled HTML Content after this timeout
// Failsafe for Player starting offline
const HTML_INIT_TIMEOUT = 5000

export default {
  name: 'PlaylistOverlays',
  components: {
    Logo,
    PlaylistFooter,
    HtmlItem
  },

  props: {
    overlays: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Object,
      default: () => ({})
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      htmlItemOptions: null,
      htmlDebounceTimer: null,
      htmlInitTimer: null
    }
  },

  computed: {
    ...mapState({
      isPausedByBtnClick: s => s.interactions.pausedByBtnClick
    }),
    ...mapGetters([
      'isPaused',
      'showMenuBar',
      'currentPlaylist',
      'currentPage',
      'logoURL',
      'logoType'
    ]),

    dataIsReady () {
      return Boolean(this.previewMode || (!this.previewMode && this.currentPlaylist))
    },

    overlayProps () {
      if (this.previewMode) {
        return this.overlays || {}
      }
      return this.currentPlaylist?.overlay_props || {}
    },

    showLogo () {
      if (!this.dataIsReady) { return false }
      return (this.overlayProps.overlay_logo) || this.currentPlaylist?.overlay_logo || false
    },

    showClock () {
      if (!this.dataIsReady) { return false }
      return (this.overlayProps.overlay_clock) || this.currentPlaylist?.overlay_clock || false
    },

    weatherLocation () {
      if (!this.dataIsReady) { return '' }
      return (this.overlayProps.weather_location) || this.currentPlaylist?.weather_location || ''
    },

    showWeather () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.weatherLocation && this.weatherLocation.trim().length)
    },

    message () {
      if (!this.dataIsReady) { return '' }
      return (this.overlayProps.message) || this.currentPlaylist?.message || ''
    },

    showMessage () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.message && this.message.trim().length)
    },

    tickerTapeVisible () {
      if (!this.dataIsReady) { return false }
      return (this.overlayProps.tickertape_visible) || this.currentPlaylist?.tickertape_visible || false
    },

    showTickertape () {
      if (!this.dataIsReady) { return false }
      // Fallback for existing Tickertapes created before DEV-3259
      if (typeof this.tickerTapeVisible !== 'boolean') {
        return this.hasTickertapeItems
      }
      return this.tickerTapeVisible && this.hasTickertapeItems
    },

    tickerTapeItems () {
      if (!this.dataIsReady) { return [] }
      return (this.overlayProps.tickertape_items) || this.currentPlaylist?.tickertape_items || []
    },

    hasTickertapeItems () {
      if (!this.dataIsReady) { return false }
      return Boolean(Array.isArray(this.tickerTapeItems) && this.tickerTapeItems.length)
    },

    tickerTapeNoOverlay () {
      if (!this.dataIsReady) { return false }
      return (this.overlayProps.tickertape_no_overlay) || this.currentPlaylist?.tickertape_no_overlay || false
    },

    showFooter () {
      return (this.showClock || this.showWeather || this.showTickertape || this.showMessage) && !this.tickerTapeNoOverlay
    },

    customLogo () {
      if (this.showLogo && this.logoURL && this.logoType === 'custom') {
        return this.logoURL
      }
      return null
    },

    showPageTitle () {
      if (!this.dataIsReady) { return false }
      return this.overlayProps.overlay_page_title || this.currentPlaylist?.overlay_page_title || false
    },

    showHeader () {
      return this.showLogo || this.isPaused || this.showPageTitle
    },

    contentOverlayURL () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.content_overlay_url || this.currentPlaylist?.content_overlay_url || ''
    },

    showImageOverlay () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.contentOverlayURL && this.contentOverlayURL.trim().length)
    },

    htmlOverlayId () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.html_overlay_id || this.currentPlaylist?.html_overlay_id || ''
    },

    htmlOverlay () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.html_overlay || this.currentPlaylist?.html_overlay || ''
    },

    htmlOverlayVer () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.html_overlay_version || this.currentPlaylist?.html_overlay_version || ''
    },

    showHtmlOverlay () {
      return Boolean(this.htmlOverlayId || this.htmlOverlay)
    },

    htmlOverlayClickable () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.overlayProps.html_overlay_clickable || this.currentPlaylist?.html_overlay_clickable)
    },

    pageTitle () {
      if (this.previewMode) {
        return this.page.description || ''
      }
      return (this.currentPage && this.currentPage.description) || ''
    },

    imageOverlayStyle () {
      if (!this.showImageOverlay) { return }
      return {
        backgroundImage: `url(${this.contentOverlayURL})`
      }
    }
  },

  watch: {
    htmlOverlayId () {
      this.debounceGetHtmlContent()
    },

    htmlOverlay () {
      this.debounceGetHtmlContent()
    },

    htmlOverlayVer (newValue) {
      if (newValue && newValue.length) {
        this.debounceGetHtmlContent()
      }
    }
  },

  mounted () {
    clearTimeout(this.htmlDebounceTimer)
    clearTimeout(this.htmlInitTimer)
    this.debounceGetHtmlContent()
  },

  beforeDestroy () {
    clearTimeout(this.htmlDebounceTimer)
    clearTimeout(this.htmlInitTimer)
  },

  methods: {
    overlayFontColor (fontType) {
      return {
        color: this.currentPlaylist && this.currentPlaylist.overlay_props && this.currentPlaylist.overlay_props[fontType] && this.currentPlaylist.overlay_props[fontType].color
      }
    },

    overlayAlignment (fontType) {
      if (this.currentPlaylist && this.currentPlaylist.overlay_props && this.currentPlaylist.overlay_props[fontType]) {
        return {
          horz: `horz-${this.currentPlaylist.overlay_props[fontType].horizontal_alignment}`,
          vert: `vert-${this.currentPlaylist.overlay_props[fontType].vertical_alignment}`
        }
      }
      return {}
    },

    resumePlayback () {
      if (this.isPaused) {
        this.$emit('resume-playback')
      }
    },

    debounceGetHtmlContent () {
      clearTimeout(this.htmlDebounceTimer)
      this.htmlDebounceTimer = setTimeout(() => {
        clearTimeout(this.htmlDebounceTimer)
        this.getHtmlContent()
      }, 200)
    },

    recheckContentFetching () {
      clearTimeout(this.htmlInitTimer)
      this.htmlInitTimer = setTimeout(() => {
        clearTimeout(this.htmlInitTimer)
        this.htmlOverlayErrorHandler()
      }, HTML_INIT_TIMEOUT)
    },

    async htmlOverlayErrorHandler () {
      const staleData = await HtmlContent.getCachedData(this.htmlOverlayId)
      if (staleData) {
        this.htmlItemOptions = this.genHtmlItemOptions(staleData)
      }
    },

    genHtmlItemOptions (htmlContent) {
      return {
        url: qs.stringify({ htmlSource: encodeURIComponent(htmlContent || '') })
      }
    },

    getHtmlContent () {
      if (!this.showHtmlOverlay) {
        clearTimeout(this.htmlInitTimer)
        this.htmlItemOptions = null
        return
      }

      this.recheckContentFetching()

      const promise = this.htmlOverlay
        ? Promise.resolve(this.htmlOverlay)
        : HtmlContent.get(this.htmlOverlayId, this.htmlOverlayVer)

      promise.then(htmlContent => {
        clearTimeout(this.htmlInitTimer)
        this.htmlItemOptions = this.genHtmlItemOptions(htmlContent)
      }).catch(() => {
        clearTimeout(this.htmlInitTimer)
        this.htmlOverlayErrorHandler()
      })
    }
  }
}
</script>

<template lang="pug">
section#playlist-overlays
  transition(name="fade" mode="out-in" appear)
    .playlist-image-overlay(v-if="showImageOverlay", :style="imageOverlayStyle")

  transition(name="fade" mode="out-in" appear)
    header.overlay-header(v-if="showHeader")
      .page-title(:style="overlayFontColor('page_title_overlay_font')" :class="overlayAlignment('page_title_overlay_font')['horz']")
        template(v-if="showPageTitle") {{ pageTitle }}
      .account-logo(v-if="showLogo")
        img(v-if="customLogo && customLogo.length", :src="customLogo")
        logo(v-else)

  transition(name="fade" mode="out-in" appear)
    //- Hide button for Interactive Menu and Navigation app active mode
    .pause-icon(v-if="isPaused && !showMenuBar && !isPausedByBtnClick", :class="{'has-footer': showFooter}" @click="resumePlayback")
      fa.fa-icon.fa-2x(icon="pause-circle")

  transition(name="fade" mode="out-in" appear)
    playlist-footer(v-if="showFooter" :overlays="overlays" :account="account" :preview-mode="previewMode")

  transition(name="fade" mode="out-in" appear)
    .playlist-html-overlay(v-if="showHtmlOverlay && htmlItemOptions", :class="{'is-clickable': htmlOverlayClickable}")
      html-item(:item="htmlItemOptions" :overlay-content-id="htmlOverlayId" is-playlist-overlay active)
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section#playlist-overlays
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 5000
  background: transparent

  // Pass down pointer events
  pointer-events: none

  .overlay-header,
  .overlay-footer
    display: flex
    flex-flow: row nowrap
    align-items: center

  .overlay-header
    position: absolute
    z-index: 5003
    top: 0
    left: 0
    right: 0
    padding: 2vmin 4vmin 0 4vmin
    justify-content: space-between

    .account-logo
      height: 8vmin
      width: 30.86vmin
      overflow: hidden
      text-align: right

      img
        border: 0
        display: inline-block
        height: 100%
        width: 100%
        object-fit: contain
        object-position: 100% 50%

    .page-title
      flex: 1 1 0.00001px
      padding-right: 1em
      font-size: 2vmin
      text-shadow: 0.08em 0.08em 0.15em rgba(0, 0, 0, 1)
      font-weight: 600
      line-height: 150%
      ellipsis()
      &.horz-left
        text-align: left
      &.horz-right
        text-align: right
      &.horz-center
        text-align: center

  .playlist-image-overlay
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 5001
    background-size: 100% 100%
    background-position: 50% 50%
    background-repeat: no-repeat

  .playlist-html-overlay
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 5002

    &.is-clickable
      > section.html-page
        > iframe.html-app-frame
          z-index: 1
          // Preserve pointer event
          pointer-events: initial

  .pause-icon
    position: absolute
    z-index: 5004
    left: 4vmin
    bottom: 3vmin
    color: white
    font-size: 2vmin
    padding: 0
    // Preserve pointer event for resumePlayback
    pointer-events: initial

    .fa-icon
      filter: drop-shadow(1px 1px 10px #000)

    &.has-footer
      bottom: 12vmin
</style>
