<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import Background from '../common/Background.vue'
import Override from './Override.vue'
import PageContents from './PageContents.vue'

import OverlayHeader from 'components/common/OverlayHeader.vue'
import Message from 'components/common/Message.vue'
import PlaylistOverlays from 'components/common/PlaylistOverlays.vue'
import Version from 'components/common/Version.vue'
import InteractiveMenu from 'components/player/InteractiveMenu.vue'
import InteractiveMenuOverlay from 'components/player/InteractiveMenuOverlay.vue'
import InteractiveMenuPinCode from 'components/player/InteractiveMenuPinCode.vue'
import OverlayContainer from 'components/common/OverlayContainer.vue'

import AppControl from 'services/appcontrol.js'
import Telemetry from 'services/telemetry.js'
import Pages from 'services/pages.js'
import Log from 'services/log.js'
import Environment from 'services/environment'
import CommonStyling from 'services/common-styling.js'
import HtmlContent from 'services/html-content.js'
import PlayTime from 'services/play-time.js'
import { EventBus } from 'services/eventbus.js'

// Prevent rapid page navigation (DEV-3956)
// The minimum page.duration is `4s` -> should allow up to 15 pages per minute
const MAX_PAGE_NAV_PER_MINUTE = 30
// In ms. Interval to recheck pending page nav
// const PAGE_NAV_RECHECK_INTERVAL = 1000

// Fallback for Playlists with only one page, or page duration is `null`
// In ms ~ 1 hour
const MAX_CAMPAIGN_VIEW_INTERVAL = 3.6e+6

// In ms. Interval to recheck page status when "all pages filtered"
const PAGE_FILTERED_RECHECK_INTERVAL = 10000

export default {
  name: 'Playlists',
  components: {
    Background,
    InteractiveMenu,
    InteractiveMenuOverlay,
    InteractiveMenuPinCode,
    Override,
    PageContents,
    Message,
    PlaylistOverlays,
    OverlayHeader,
    OverlayContainer,
    Version
  },

  data () {
    return {
      showPrimePage: false,
      primePage: null,
      altPage: null,

      // No page to display at this moment
      nopages: false,
      // No pages found in the Playlist(s)
      emptyPlaylist: false,

      endOfPreview: false,
      pageFinished: false,
      showImmediately: false,

      currentPageIsVisible: true,
      forceDisplay: false,

      // DEV-1704
      screensaverTimer: undefined,

      synchronizeTimeout: undefined,

      isRunningGoNext: false,

      pageNavCounter: {},
      pendingPageNav: null,

      startTime: undefined,
      firstPageTimer: undefined,
      logCampaignViewInterval: undefined,
      onePageSyncTimer: null,

      overridePlaylistStartTime: null,

      // Put all tasks with 1s interval here
      oneSecTimer: null,

      pagesFilteredTimer: null
    }
  },

  computed: {
    ...mapState({
      loading: s => s.interactions.loadingContent,
      activeOverride: state => state.overrides.activeOverride,
      currentPageID: s => s.playlists.currentPageID,
      visiblePages: s => s.playlists.visiblePages || [],
      timeOffset: s => s.api.timeOffset || 0,
      paused: s => s.playbackmode.pauseMode,
      screensaverIsOn: s => s.playbackmode.screensaverMode,
      showOverlayContainer: s => s.interactions.showOverlay,
      showPinCode: s => s.interactions.showPinCode || false
    }),
    ...mapGetters([
      'isDevice',
      'isSwitchingRegion',
      'showMenuBar',
      'pages',
      'currentPlaylist',
      'currentPage',
      'currentPageName',
      'currentPlaylistTheme',
      'playlistThemeIsSet',
      'playlistFont',
      'sharedCounters',
      'playlistSchedules',
      'playlistCampaignID',
      'isOverridingByPlaylist',
      'overrideingPlaylistID',
      'isOverridingWithManualDuration',
      'activeOverrideDuration',
      'allPlaylistsFiltered',
      'hasGitPlaylistOnly'
    ]),
    synchronize () {
      return Boolean(this.currentPlaylist && this.currentPlaylist.synchronize)
    },
    synchronizeLength () {
      if (!this.synchronize) {
        return 0
      }
      return this.currentPlaylist.length || 0
    },

    isShowingDefaultPage () {
      return Pages.isShowingDefaultPage(this.currentPage)
    },

    isShowingLastPage () {
      const pagesLen = this.visiblePages.length
      if (pagesLen > 0 && this.currentPage && this.currentPage.id) {
        const lastPage = this.visiblePages[pagesLen - 1]
        return this.currentPage.id === lastPage.id
      }
      return false
    },

    screensaverDuration () {
      if (!this.currentPlaylist || !this.currentPlaylist.timeout) {
        return 60
      }
      return Math.max(4, Math.min(86400, +this.currentPlaylist.timeout || 60))
    },

    fontClass () {
      if (!this.playlistThemeIsSet || !this.playlistFont) { return }
      return CommonStyling.getfontClass(this.playlistFont)
    },

    isRepressingPageNav () {
      return Boolean(this.pendingPageNav && this.pendingPageNav.id)
    }
  },

  watch: {
    synchronizeLength () {
      this.setPlaylistSynchronization()
    },

    'visiblePages.length' (newLength, oldLength) {
      // Only reacts when:
      // - Changing from No Page to >= 1 visible page(s)
      if (!oldLength && newLength && this.nopages) {
        this.goToNextPage()
        return
      }

      if (this.paused) { return }

      // - Changing from only one page to > 1 visible pages
      // - Visible counts changed while displaying the default page
      if (
        (!this.isShowingDefaultPage && oldLength === 1 && newLength > 1) ||
        (this.isShowingDefaultPage && !this.screensaverIsOn && newLength >= 1)
      ) {
        if (this.currentPage) {
          if (!this.currentPage.duration) {
            this.goToNextPage()
          } else if (this.currentPage.duration && this.currentPage.shown_at) {
            const now = new Date().getTime()
            if (this.currentPage.shown_at + this.currentPage.duration <= now) {
              this.goToNextPage()
            }
          }
        }
      }
    },

    isShowingDefaultPage (isTrue = false) {
      // Send logs for switching to the Default Page (non-screensaver mode) due to pages filtered
      if (isTrue && !this.screensaverIsOn) {
        Log.warn('player', 'Using the default pages as no standard pages are visible', 'WAR_DEFAULTPAGES')
      }
    },

    screensaverIsOn (isTrue) {
      // Only reacts when screensaver is off
      if (!isTrue) {
        clearTimeout(this.screensaverTimer)
        // NOTE: Handle page navigation in Pages.stopScreensaver (pages.js)
      }
    },

    fontClass (newValue) {
      if (newValue && newValue.length) {
        CommonStyling.loadExtendFont(newValue)
      }
    },

    overrideingPlaylistID (newValue) {
      // Fetch for Override Playlist
      if (newValue && newValue.length) {
        // Speed up loading and add fallback for offline device
        this.$store.commit('loadOverridePlaylistInfo')
        this.$store.dispatch('getOverridePlaylist', newValue).then(() => {
          this.$store.dispatch('listenPlaylists', [newValue])
          // For Override Playlist with Manual Duration [ENG-715]
          if (this.isOverridingWithManualDuration && this.activeOverrideDuration) {
            this.overridePlaylistStartTime = Date.now()
          } else {
            this.overridePlaylistStartTime = null
          }
        })
      // Resume back to Device Playlist
      } else {
        this.$store.dispatch('getPlaylists').then(() => {
          this.$store.dispatch('listenPlaylists')
        }).catch(err => {
          Log.error('player', `Failed to get playlists - ${err.message || err.toString() || err}`, 'ERR_GETPLAYLISTS', { error: err }, true)
        })
      }
    }
  },

  mounted () {
    clearTimeout(this.synchronizeTimeout)
    clearTimeout(this.firstPageTimer)

    if (this.playlistSchedules) {
      this.$store.commit('updateScheduledPlaylists', this.playlistSchedules)
    }

    this.$store.dispatch('initInteractiveMenu')

    this.$store.dispatch('checkForActiveOverride')

    // Put all tasks/checking with 1s interval here to reduce the total number of timers
    clearInterval(this.oneSecTimer)
    this.oneSecTimer = setInterval(() => {
      // Check playlists and pages schedule status
      if (this.playlistSchedules) {
        this.$store.commit('updateScheduledPlaylists', this.playlistSchedules)
      }
      const visiblePages = Pages.visiblePages()
      this.$store.commit('updateVisiblePages', visiblePages)

      // Fallback checking for pages with schedules
      this.checkCurrentPageStatus()

      // Validate overrides
      this.$store.dispatch('checkActiveOverrideStatus')

      // Shared Counters
      this.checkCounterStatus()

      // Recheck pending page nav (prevent rapid-page-nav)
      this.checkNavCounts()

      // Check if Manual Duration Override Playlist is fully shown
      this.checkOverridePlaylistValidity()
    }, 1000)

    // Fallback checking for "All Pages Filtered"
    clearInterval(this.pagesFilteredTimer)
    this.pagesFilteredTimer = setInterval(() => {
      if (
        // Not all pages are filtered by the schedulers.
        // However, there's still no playable page due to Shared Counters config or Programmatic Ad with no content (code 204)
        (this.nopages && !Pages.allPagesInvisible()) ||
        // When it's showing Default Page with no page duration
        // - Default Page with duration will auto-checked on each `page.duration` timeout
        (this.isShowingDefaultPage && this.currentPage && !this.currentPage.duration)
      ) {
        this.goToNextPage()
      }
    }, +PAGE_FILTERED_RECHECK_INTERVAL)

    // Move HTML Content from localStorage to ContentCache (DEV-2894)
    HtmlContent.upgradeCache()

    document.addEventListener('touchend', this.docTouchEndHandler, { passive: true, capture: true })

    Pages.on('current-page-removed', this.pageRemoved)
    Pages.on('updated', this.pagesUpdated)
    Pages.on('go-to-page', this.goToPageCommand)
    Pages.on('to-screensaver-page', this.startScreensaver)
    Telemetry.on('pause/resume', this.pauseResumeCommand)
    Telemetry.on('previouspage', this.previousPageCommand)
    Telemetry.on('nextpage', this.nextPageCommand)
    Telemetry.on('clear-cache', this.clearAllCache)
    Telemetry.on('soundVolume', this.setSoundVolume)
    AppControl.on('system-key-command', this.keyboardEvent)
    EventBus.$on('interaction-previouspage', this.previousPageCommand)
    EventBus.$on('interaction-nextpage', this.nextPageCommand)
    EventBus.$on('pause-and-go-to-page', this.pauseAndGoToPageByID)
    EventBus.$on('go-to-page', this.sdkGoToPage)

    this.setPlaylistSynchronization()
    this.checkCounterStatus()

    this.$nextTick(() => {
      this.goToNextPage()
    })

    if (this.fontClass && this.fontClass.length) {
      CommonStyling.loadExtendFont(this.fontClass)
    }
  },

  beforeDestroy () {
    clearInterval(this.logCampaignViewInterval)
    clearInterval(this.oneSecTimer)
    clearInterval(this.pagesFilteredTimer)
    clearTimeout(this.synchronizeTimeout)
    clearTimeout(this.firstPageTimer)
    clearTimeout(this.onePageSyncTimer)
    clearTimeout(this.screensaverTimer)

    document.removeEventListener('touchend', this.docTouchEndHandler, { passive: true, capture: true })

    Pages.removeListener('current-page-removed', this.pageRemoved)
    Pages.removeListener('updated', this.pagesUpdated)
    Pages.removeListener('go-to-page', this.goToPageCommand)
    Pages.removeListener('to-screensaver-page', this.startScreensaver)
    Telemetry.removeListener('pause/resume', this.pauseResumeCommand)
    Telemetry.removeListener('clear-cache', this.clearAllCache)
    Telemetry.removeListener('previouspage', this.previousPageCommand)
    Telemetry.removeListener('nextpage', this.nextPageCommand)
    AppControl.removeListener('system-key-command', this.keyboardEvent)
    EventBus.$off('interaction-previouspage', this.previousPageCommand)
    EventBus.$off('interaction-nextpage', this.nextPageCommand)
    EventBus.$off('pause-and-go-to-page', this.pauseAndGoToPageByID)
    EventBus.$off('go-to-page', this.sdkGoToPage)
  },

  methods: {
    setPlaylistSynchronization () {
      clearTimeout(this.synchronizeTimeout)
      if (this.synchronize && this.synchronizeLength > 0) {
        const serverTime = Date.now() + this.timeOffset
        const resetAt = Math.ceil(serverTime / this.synchronizeLength / 1000) * this.synchronizeLength * 1000
        const resetDuration = resetAt - serverTime
        Log.info('player', 'Synchronizing ' + this.synchronizeLength + 's playlist in ' + resetDuration + 'ms', 'INF_PLAYERSYNC')
        clearTimeout(this.synchronizeTimeout)
        this.synchronizeTimeout = setTimeout(() => {
          clearTimeout(this.synchronizeTimeout)
          this.synchronizePlaylist()
        }, resetDuration)
      }
    },

    synchronizePlaylist () {
      Log.info('player', `Synchronized playlist reset ${Date.now() + this.timeOffset}`, 'INF_PLAYERSYNCED')
      Pages.reset()
      this.goToNextPage(null, true)
      this.setPlaylistSynchronization()
    },

    keyboardEvent (event) {
      switch (event.command) {
        case 'pause/resume':
          this.pauseResumeCommand()
          break
        case 'previouspage':
          this.previousPageCommand()
          break
        case 'nextpage':
          this.nextPageCommand()
          break
        case 'toggle-interactive-menu':
          this.toggleInteractiveMenu()
          break
      }
    },

    pauseResumeCommand () {
      // Handle Pause/Resume mode here instead of in `telemetry.js``
      this.$store.dispatch('togglePauseMode')

      if (!this.paused && this.pageFinished) {
        this.goToNextPage()
      // TEMP DEBUG
      } else if (!this.paused && !this.pageFinished) {
        if (this.currentPage && !this.currentPage.duration) {
          Log.info('player', `Skip page advance because the current page has no duration (${this.currentPageName})`, 'INF_CURRENTPAGENODURATION')
        } else {
          Log.warn('player', `Skip page advance because the current page is not finished (${this.currentPageName})`, 'WAR_CURRENTPAGENOTFINISH')
        }
      }
    },

    async previousPageCommand () {
      const page = await Pages.previous()
      if (this.screensaverIsOn) {
        this.stopScreensaver(page)
      } else {
        this.goToNextPage(page, true)
      }
    },

    async nextPageCommand () {
      const page = await Pages.next(true)
      if (this.screensaverIsOn) {
        this.stopScreensaver(page)
      } else {
        this.goToNextPage(page, true)
      }
    },

    goToPageCommand (page) {
      // Manually switch to centain page in Console mode or HTML/Overlays
      this.goToNextPage(page, true, true)
    },

    pauseAndGoToPageByID (pageID) {
      if (!pageID) { return }
      // Command sent from js_user
      const targetPage = this.pages.find(pg => pg.id === pageID)
      if (targetPage) {
        if (!this.paused) {
          this.$store.dispatch('togglePauseMode')
        }
        this.goToNextPage(targetPage, true, true)
      }
    },

    markPageFinished () {
      const startTime = (+this.startTime || 0) * 1000

      this.pageFinished = true

      // Clone value to prevent data update in between
      const currentPage = JSON.parse(JSON.stringify(this.currentPage || {}))

      PlayTime.addLogByPage(currentPage.items, { startTime })

      clearInterval(this.logCampaignViewInterval)
      this.logCampaignViewInterval = undefined
      this.logCampaignView(currentPage)
    },

    finished () {
      // TEMP DEBUG
      if (!Environment.pageEditMode && this.screensaverIsOn) {
        Log.warn('player', `Skip page advance because the Player is showing the screensaver page (${this.currentPageName})`, 'WAR_SCREENSAVERSHOWING')
      }

      if (Environment.pageEditMode || this.screensaverIsOn) { return }

      this.markPageFinished()

      if (!this.paused) {
        this.goToNextPage()
      } else {
        // TEMP DEBUG
        Log.warn('player', `Skip page advance because the Player is paused (${this.currentPageName})`, 'WAR_PLAYERPAUSED2')
      }
    },

    logCampaignView (page) {
      if (!Environment.pageEditMode && !Environment.previewMode && !Environment.desktopMode && !Environment.iframeEmbedMode) {
        // When `playlist.campaign_id` is set, ignore the `page.campaign_id` (DEV-4492)
        if (page && page.campaign_id && !this.playlistCampaignID) {
          // Use constant variable to prevent Vuex value update in between
          const startTime = +this.startTime

          Pages.logCampaignView(page, startTime, Date.now() / 1000)
        }
      }
      this.startTime = Date.now() / 1000
    },

    async goToNextPage (navigateToPage, showImmediately = false, forceDisplay = false) {
      if (this.isRunningGoNext && (!navigateToPage || !forceDisplay)) {
        Log.info('player', 'Device is still checking for the next playable page in the playlist.', 'INF_NEXTPAGECHECKING')
        return
      }

      this.isRunningGoNext = true

      if (this.isRepressingPageNav) {
        Log.warn('player', 'Device playlist is rapidly cycling and has been throttled.', 'WAR_RAPIDCYCLING')
        if (navigateToPage && navigateToPage.id) {
          this.pendingPageNav = navigateToPage
        }
        this.isRunningGoNext = false
        return
      }

      // Finish playing the last visible page on a synchronized Playlist with multiple pages
      if (this.synchronize && this.visiblePages.length > 1 && this.isShowingLastPage && !showImmediately) {
        Log.info('player', 'Waiting for Playlist Synchronize', 'INF_WAITPLAYLISTSYNC')
        this.isRunningGoNext = false
        return
      }

      // Means the page navigation is manually triggered:
      // - By Keyboard Shortcut
      // - By Interactive Menu
      // - By User App panel (js_user)
      // - By SDK
      // Mark the current page as "pageFinished" and sent campaign_view if needed
      if (showImmediately) {
        this.markPageFinished()
      }

      this.forceDisplay = forceDisplay
      this.pageFinished = false
      this.startTime = Date.now() / 1000
      this.showImmediately = showImmediately

      // Clone data to prevent data changes during possible event racing
      const currentPage = this.currentPage ? JSON.parse(JSON.stringify(this.currentPage)) : null

      const isFirstPage = (!currentPage || !currentPage.id)

      if (currentPage && currentPage.id) {
        Pages.setShown(currentPage)
      }

      // Reset counter values for manually navigations
      if (showImmediately) {
        // Not valid page
        if (!navigateToPage) {
          Pages.resetCounterFlags()
        // The upcoming page is a regular page
        } else {
          // [A] The upcoming page is a counter page
          if (Pages.isCounterPage(navigateToPage)) {
            // - The current page is counter page as well
            if (Pages.isCounterPage(currentPage)) {
              Pages.resetCounterFlags()
            }
            const navPageCounterID = navigateToPage.counters[0]
            Pages.resetCounterLogLastShown(navPageCounterID)
            Pages.setLastPageBeforeCounter(navigateToPage, currentPage)

          // [B] Not a counter page
          } else {
            Pages.resetCounterFlags(true)
          }
        }
      }

      this.addPageNavCount()

      let page = navigateToPage || await Pages.next()
      let noPage = (page === null || page === undefined)

      this.emptyPlaylist = Pages.noPages()

      // Add additional fallback
      if (noPage && !this.emptyPlaylist && !Pages.allPagesInvisible()) {
        page = await this.getFallbackPage()
        if (page) {
          noPage = false
        }
      }

      this.nopages = noPage

      if (this.nopages) {
        this.primePage = null
        this.altPage = null
        this.currentPageIsVisible = false
        this.$store.commit('updateCurrentPageID', null)
        this.isRunningGoNext = false
        return
      }

      // Repress rapid page navigation [DEV-3956]
      if (this.isRapidPageNav()) {
        this.pendingPageNav = page
        this.isRunningGoNext = false
        return
      }

      if (Environment.previewMode && !Environment.loopPreviewPlaylist) {
        if (currentPage && currentPage.priority >= page.priority) {
          this.endOfPreview = true
        }
      }

      clearInterval(this.logCampaignViewInterval)
      this.logCampaignViewInterval = setInterval(() => {
        this.logCampaignView(page)
      }, MAX_CAMPAIGN_VIEW_INTERVAL)

      this.currentPageIsVisible = true

      // Synchronsizing an one-page Playlist
      const syncingOnePagePL = this.synchronize && this.visiblePages.length === 1 && showImmediately

      if (
        (this.showPrimePage && this.primePage && (page.id === this.primePage.id)) ||
        (!this.showPrimePage && this.altPage && (page.id === this.altPage.id))
      ) {
        if (syncingOnePagePL) {
          Log.debug('player', 'Synchronizing Playlist with only one page', 'DEB_SYNCONEPAGEPL')
        } else {
          // Skip this log when showing Default Page (which will be periodically refreshed)
          if (!this.isShowingDefaultPage) {
            Log.debug('player', 'Next page is the same as the current page, skipping the change', 'DBG_NEXTPAGESAMECURRENT')
          }
          if (this.showPrimePage) {
            this.primePage = page
          } else {
            this.altPage = page
          }
          // Update for non-Default Page, or Default Page with duration
          if (!this.isShowingDefaultPage || (this.isShowingDefaultPage && page.duration)) {
            if (this.$refs?.pageContents) {
              this.$refs.pageContents.setDurationTimer(page)
              this.$refs.pageContents.startShowing(page)
            }
          }
          this.isRunningGoNext = false
          return
        }
      }

      clearTimeout(this.onePageSyncTimer)
      // Fallback for one-page + synchromize Playlist
      if (syncingOnePagePL) {
        EventBus.$emit('synchronize-reset')
        this.onePageSyncTimer = setTimeout(() => {
          clearTimeout(this.onePageSyncTimer)
          this.switchPages(page)
        }, 500)
      // Regular Playlists
      } else {
        this.switchPages(page)
      }

      if (isFirstPage) {
        // Add some delay to ensure SDK callbacks are initialized
        clearTimeout(this.firstPageTimer)
        this.firstPageTimer = setTimeout(() => {
          clearTimeout(this.firstPageTimer)
          EventBus.$emit('sdk-on-load-first-page', page)
        }, 1000)
      }

      this.isRunningGoNext = false
    },

    async getFallbackPage () {
      let availablePages = Pages.getRegularVisiblePages()
      if (!availablePages || !availablePages.length) {
        // NOTE: When "visible" (non-scheduled) pages > 0, the default page is not included in the "visiblePages" list
        // Hence we need to find it manually
        const defaultPage = Pages.findDefaultPage()
        if (defaultPage) {
          availablePages = [defaultPage]
        } else {
          // No default page found
          return null
        }
      }
      if (availablePages.length === 1) {
        return availablePages[0]
      // length > 1
      } else {
        return availablePages.find(p => !p.default_page)
      }
    },

    switchPages (page) {
      this.showPrimePage = !this.showPrimePage

      if (this.showPrimePage) {
        this.primePage = page
        // Clear previous durationTimer
        if (this.$refs?.pageContents) {
          this.$refs.pageContents.clearTimers()
        }
      } else {
        this.altPage = page
        // Clear previous durationTimer
        if (this.$refs?.pageContents) {
          this.$refs.pageContents.clearTimers()
        }
      }
    },

    pageRemoved () {
      this.goToNextPage()
    },

    pagesUpdated () {
      if (this.nopages || Pages.noPages() || Pages.allPagesInvisible()) {
        this.goToNextPage()
      } else {
        this.updateCurrentPage()
      }
    },

    // reactivate current page changes (layout, etc)
    updateCurrentPage () {
      const targetPage = this.pages.find(p => this.currentPageID && p.id === this.currentPageID)
      if (targetPage) {
        this.showPrimePage ? this.primePage = targetPage : this.altPage = targetPage
      }
    },

    // Remove unused message listener for deprecated `custom-apps-*``

    docTouchEndHandler (evt) {
      // Extend the Screensaver (Default page) when user interacts with the page
      // Close the Screensaver after `screensaverDuration` when user stop interacting the Default page
      if (this.screensaverIsOn) {
        this.setScreensaverTimemout()
      }
    },

    startScreensaver (page) {
      if (!page) { return }

      this.goToNextPage(page, true, true)
      Log.debug('player', `Show the playlist screensaver for ${this.screensaverDuration}s`, 'DBG_SCREENSAVER')

      this.setScreensaverTimemout()
    },

    setScreensaverTimemout () {
      clearTimeout(this.screensaverTimer)
      this.screensaverTimer = setTimeout(() => {
        clearTimeout(this.screensaverTimer)
        this.stopScreensaver()
      }, this.screensaverDuration * 1000)
    },

    stopScreensaver (page) {
      Pages.stopScreensaver(page)
    },

    resumePlayback () {
      Log.debug('player', 'Resume playlist playback', 'DBG_RESUMEPLAYBACK')
      this.pauseResumeCommand()
    },

    sdkGoToPage (page) {
      if (!page || !page.id) { return }

      if (this.screensaverIsOn) {
        this.stopScreensaver(page)
      } else {
        Pages.goToPage(page)
      }
    },

    clearAllCache () {
      AppControl.emit('system-key-command', {command: 'clear-cache'})
    },

    setSoundVolume (event) {
      const volume = (event && event.data) || 0
      Environment.setSoundVolume(volume)
    },

    checkCurrentPageStatus () {
      if (!this.currentPage) { return }

      // Skip verify page schedule when:
      // - Manually navigating to target page in Console mode
      // - Is showing default page
      // - Is repressing rapid-page-nav
      if (this.forceDisplay || this.isShowingDefaultPage || this.isRepressingPageNav) { return }

      const pageIsVisible = Pages.pageIsVisible(this.currentPage)

      // Only triggers when switching from `true` to `false` for the first time
      if (pageIsVisible !== this.currentPageIsVisible) {
        this.currentPageIsVisible = pageIsVisible

        // Force advance to next page when page is not visible anymore
        if (!pageIsVisible) {
          this.finished()
        }
      }
    },

    checkCounterStatus () {
      if (!this.sharedCounters || !this.sharedCounters.length) {
        return
      }
      Pages.checkCountersState()
    },

    toggleInteractiveMenu () {
      if (!this.isDevice) { return }
      Log.info('player', 'Toggle interactive menu', 'INF_TOGGLEINTMENU')
      this.$store.dispatch('toggleInteractiveMenu')
    },

    currentMinTs () {
      // Per minute timestamp
      return ~~(Date.now() / 1000 / 60)
    },

    addPageNavCount () {
      const tsKey = this.currentMinTs()
      if (!this.pageNavCounter[tsKey]) {
        this.pageNavCounter[tsKey] = 1
      } else {
        this.pageNavCounter[tsKey] += 1
      }
    },

    isRapidPageNav () {
      const tsKey = this.currentMinTs()
      return (this.pageNavCounter[tsKey] && this.pageNavCounter[tsKey] >= MAX_PAGE_NAV_PER_MINUTE)
    },

    checkNavCounts () {
      const keys = Object.keys(this.pageNavCounter) || []
      if (!keys.length) { return }

      // Sort descending (new -> old)
      keys.sort((a, b) => b - a)

      const currentTsKey = this.currentMinTs()
      for (let i = keys.length - 1; i >= 0; i--) {
        const key = keys[i]
        // Minute of the past
        if (+key < currentTsKey) {
          // For better GC
          this.pageNavCounter[key] = null
          delete this.pageNavCounter[key]
        }
      }

      // Has pendingPage and that minute already passed
      if (this.pendingPageNav && !(Object.keys(this.pageNavCounter) || []).length) {
        const nextPage = JSON.parse(JSON.stringify(this.pendingPageNav))
        this.pendingPageNav = null
        this.goToNextPage(nextPage, true, true)
      }
    },

    // For Override Playlist with Manual Duration [ENG-715]
    checkOverridePlaylistValidity () {
      if (!this.overrideingPlaylistID || !this.isOverridingWithManualDuration || !this.activeOverrideDuration) {
        return
      }
      if (
        this.overridePlaylistStartTime &&
        Date.now() - this.overridePlaylistStartTime >= this.activeOverrideDuration * 1000
      ) {
        this.$store.commit('addOverrideShowed', this.activeOverride.id)
        this.overridePlaylistStartTime = null
      }
    }
  }
}
</script>

<template lang="pug">
section#playlists
  //- End Of Playlist Preview
  template(v-if="endOfPreview")
    section#endofpreview
      message(severity="info", :title="$t('playlists.common.title')", :text="$t('playlists.endOfPreview.text')" description=" ")

  //- Normal Playback Mode
  template(v-else)
    //- Playlist Background (Theme)
    background(:theme="currentPlaylistTheme")

    //- Has valid page(s)
    section#pages-container(v-if="!nopages", :class="fontClass")
      //- Interactive Menu
      interactive-menu(v-if="showMenuBar")

      //- Playlist Pages Area
      #playlist-area
        interactive-menu-overlay(v-if="showMenuBar")

        transition(name="fade", :duration="200")
          interactive-menu-pin-code(v-if="showPinCode")

        playlist-overlays(v-if="!currentPage || !currentPage.disable_overlays" @resume-playback="resumePlayback")

        //- If there is an active playlist override player replaces it with current playlist
        override(v-if="activeOverride && !isOverridingByPlaylist", :override="activeOverride")

        template(v-else)
          page-contents(ref="pageContents"
                        :show-immediately="showImmediately"
                        :show-prime="showPrimePage"
                        :prime-page="primePage"
                        :alt-page="altPage"
                        @finished="finished"
                        @resume-playback="resumePlayback")

          //- Overlay Content triggered from Button app (DEV-1761)
          transition(name="fade", :duration="300")
            overlay-container(v-if="showOverlayContainer" @resume-playback="resumePlayback")

    //- No valid page found, showing Messages
    section#nullpage(v-else)
      message(v-if="isSwitchingRegion" severity="info", :text="$t('playlists.switchRegion.text')")
      message(v-else-if="hasGitPlaylistOnly" severity="info", :title="$t('playlists.deprecated.titile')", :text="$t('playlists.deprecated.text')" :description="$t('playlists.deprecated.description')" hideSpinner)
      message(v-else-if="allPlaylistsFiltered" severity="info", :title="$t('playlists.common.title')", :text="$t('playlists.playlistsFiltered.text')", :description="$t('playlists.playlistsFiltered.description')" :hideSpinner="!loading")
      message(v-else-if="emptyPlaylist" severity="info", :title="$t('playlists.common.title')", :text="$t('playlists.isEmpty.text')", :description="$t('playlists.isEmpty.description')" :hideSpinner="!loading")
      message(v-else severity="info", :title="$t('playlists.common.title')", :text="$t('playlists.pagesFiltered.text')", :description="$t('playlists.pagesFiltered.description')")
      overlay-header(icon="list")
      version
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section#playlists
  z-index: 10
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden

  // Predefined Font Sets
  predefinedFonts()

  section#pages-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden

    display: flex
    flex-flow: row nowrap
    justify-content: flex-start
    align-items: stretch

    #playlist-area
      flex: 1 1 0.00001px
      position: relative
      overflow: hidden
      z-index: 1

  // NOTE: Need to be consistent with the `:duration` value
  .interactive-menu-pin-code
    animation-duration: 200ms
</style>
