<script>
const config = {
  // In ms. Paginate interval
  PAGINATE_INTERVAL: 4000,

  // In ms. Transition time for fade-in/out animation
  TRANSITON_TIME: 500
}

export default {
  name: 'DayEventsSlot',

  props: {
    gridName: {
      type: String,
      required: true
    },
    lightTheme: {
      type: Boolean,
      default: false
    },
    showIcalColor: {
      type: Boolean,
      default: false
    },
    isPortrait: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default: () => []
    },
    // Multi-day Events Priority
    mtdPriority: {
      type: Object,
      default: () => { return {} }
    },
    icalFlagStyle: {
      type: Object
    },
    maxItemCount: {
      type: Number,
      default: -1
    }
  },

  data () {
    return {
      // For paginate
      currentIndex: -1,
      currentChunk: [],

      paginateTimer: undefined,
      transitionTimer: undefined
    }
  },

  computed: {
    singleAllDayEventsInSlot () {
      if (!this.events || !this.events.length) { return [] }
      return this.events.filter(event => event.allDay && !event.multiDays && event.gridStart === this.gridName)
    },

    regularEventsInSlot () {
      if (!this.events || !this.events.length) { return [] }
      return this.events.filter(event => !event.allDay && !event.multiDays && event.gridStart === this.gridName)
    },

    // For Day Slot WITHOUT multi-day events
    nonMultiDayEventsInSlot () {
      return [].concat(this.singleAllDayEventsInSlot, this.regularEventsInSlot)
    },

    skipAddingItems () {
      // Skip this day block from rendering when:
      // 1. NO single all-day events, and
      // 2. NO regular events found within this day
      // To reduce DOM counts
      return !this.nonMultiDayEventsInSlot.length
    },

    multiDayEventsInSlot () {
      if (!this.events || !this.events.length) { return [] }
      return this.events.filter(event => {
        // Also take event which is NOT started from this date into account
        return event && event.multiDays && event.daysIncluded && event.daysIncluded.includes(this.gridName)
      })
    },

    hasMultiDayEvents () {
      return Boolean(this.multiDayEventsInSlot && this.multiDayEventsInSlot.length)
    },

    mtdEventByDateGrid () {
      return (this.mtdPriority && this.mtdPriority.byDateGrid) || {}
    },

    mtdEventsInSlotPriority () {
      if (!this.events || !this.events.length) { return [] }
      if (!this.mtdEventByDateGrid[this.gridName]) { return [] }
      return this.mtdEventByDateGrid[this.gridName]
    },

    // pre-occupied slots by multi-day events
    preOccupiedSlots () {
      if (!this.mtdEventsInSlotPriority) { return [] }
      return Object.keys(this.mtdEventsInSlotPriority).map(priority => +priority).sort((l, r) => l < r ? -1 : 1)
    },

    availableSlots () {
      if (this.skipAddingItems) { return }
      if (this.maxItemCount <= 0) { return [] }
      const availableSlots = []
      for (let i = 0; i < this.maxItemCount; i++) {
        if (!this.preOccupiedSlots.includes(i)) {
          availableSlots.push(i)
        }
      }
      return availableSlots
    },

    // For Day Slot with multi-day events
    orderedEvents () {
      if (!this.events || !this.events.length || this.skipAddingItems || !this.hasMultiDayEvents) { return [] }

      const nonMtdEvents = [].concat([], this.nonMultiDayEventsInSlot)
      const totalCount = nonMtdEvents.length + this.multiDayEventsInSlot.length

      const result = []
      for (let slotIndex = 0; slotIndex < totalCount; slotIndex++) {
        // Slot not occupied
        if (!this.preOccupiedSlots.includes(slotIndex)) {
          if (nonMtdEvents.length) {
            result.push(nonMtdEvents.splice(0, 1)[0])
          }
        } else {
          const eventKey = this.mtdEventsInSlotPriority[slotIndex]
          const targetEvent = this.multiDayEventsInSlot.find(mtdEvent => {
            // E.g Multi-all day dey difference [#DEV-3048]
            // eventKey: "cal-387_7_tetsetesntestset-allday-1614124800_chunk-0"
            // mtdEvent.key: "cal-387_7_tetsetesntestset-allday-1614124800"
            return eventKey && eventKey.indexOf(mtdEvent.key) === 0
          })
          if (targetEvent) {
            result.push(JSON.parse(JSON.stringify(targetEvent)))
          }
        }
      }
      return result
    },

    needsPaginate () {
      if (this.skipAddingItems || this.maxItemCount <= 0) { return false }
      const inSlotItemsCount = this.nonMultiDayEventsInSlot.length
      // Has any multi-day events
      if (this.hasMultiDayEvents) {
        if (!this.availableSlots.length) {
          // All slots are occupied by multi-day events already
          return false
        }
        return this.availableSlots.length < inSlotItemsCount
      // No multi-day event
      } else {
        return this.maxItemCount < inSlotItemsCount
      }
    },

    itemsPerPage () {
      if (!this.needsPaginate) { return -1 }
      return this.availableSlots.length
    }
  },

  watch: {
    needsPaginate (isTrue) {
      if (isTrue) {
        this.showNext()
      } else {
        this.resetPaginateChunks()
      }
    }
  },

  mounted () {
    this.resetPaginateChunks()
  },

  beforeDestroy () {
    clearTimeout(this.paginateTimer)
    clearTimeout(this.transitionTimer)
  },

  methods: {
    isVisibleInSlot (overallIndex) {
      if (this.maxItemCount > 0) {
        return overallIndex <= this.maxItemCount - 1
      }
      return false
    },

    resetPaginateChunks () {
      clearTimeout(this.paginateTimer)
      clearTimeout(this.transitionTimer)
      this.currentIndex = -1
      this.currentChunk = []
    },

    showNext () {
      if (this.itemsPerPage <= 0 || !this.nonMultiDayEventsInSlot || !this.nonMultiDayEventsInSlot.length) {
        clearTimeout(this.transitionTimer)
        clearTimeout(this.paginateTimer)
        return
      }

      let nextIndex
      if (this.currentIndex < 0) {
        nextIndex = 0
      } else {
        if (this.currentIndex + this.itemsPerPage <= this.nonMultiDayEventsInSlot.length - 1) {
          nextIndex = this.currentIndex + this.itemsPerPage
        } else {
          nextIndex = 0
        }
      }

      let nextChunk = JSON.parse(JSON.stringify(this.nonMultiDayEventsInSlot.slice(nextIndex, nextIndex + this.itemsPerPage)))
      if (this.hasMultiDayEvents) {
        nextChunk = this.injectMultiDayPlaceholder(nextChunk)
      }

      if (!nextChunk || !nextChunk.length) {
        clearTimeout(this.transitionTimer)
        clearTimeout(this.paginateTimer)
        return
      }

      // Is first init, start right away
      if (this.currentIndex < 0) {
        this.prepareNext()
      } else {
        this.currentChunk = []
      }

      clearTimeout(this.transitionTimer)
      this.transitionTimer = setTimeout(() => {
        clearTimeout(this.transitionTimer)
        this.currentIndex = nextIndex
        this.currentChunk = nextChunk
        this.prepareNext()
      }, config.TRANSITON_TIME)
    },

    prepareNext () {
      clearTimeout(this.paginateTimer)
      this.paginateTimer = setTimeout(() => {
        clearTimeout(this.paginateTimer)
        this.showNext()
      }, config.PAGINATE_INTERVAL + config.TRANSITON_TIME)
    },

    injectMultiDayPlaceholder (nextChunk) {
      if (!nextChunk || !nextChunk.length || !this.maxItemCount) { return [] }
      const nonMtdEvents = [].concat([], nextChunk)
      const result = []
      for (let slotIndex = 0; slotIndex < this.maxItemCount; slotIndex++) {
        // Slot not occupied
        if (!this.preOccupiedSlots.includes(slotIndex)) {
          if (nonMtdEvents.length) {
            result.push(nonMtdEvents.splice(0, 1)[0])
          }
        } else {
          const eventKey = this.mtdEventsInSlotPriority[slotIndex]
          const targetEvent = this.multiDayEventsInSlot.find(mtdEvent => {
            return eventKey && mtdEvent.key === eventKey
          })
          if (targetEvent) {
            result.push(JSON.parse(JSON.stringify(targetEvent)))
          }
        }
      }
      return result
    }
  }
}
</script>

<template lang="pug">
//- ============
//- MIXINS
//- Use item named `nonMtdEvent`
mixin nonMultiDayEvent
  //- - Single All-Day
  .single-allday-event.event-item(v-if="nonMtdEvent.allDay", :key="nonMtdEvent.key"
                      :class="{'invisible': !isVisibleInSlot(nmeIndex)}")
    .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: nonMtdEvent.color}]")
    .event-title {{ nonMtdEvent.title }}

  //- - Regular Event
  .regular-event.event-item(v-else :key="nonMtdEvent.key"
                :class="{'invisible': !isVisibleInSlot(nmeIndex)}")
    .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: nonMtdEvent.color}]")
    .event-title {{ nonMtdEvent.title }}
    .start-time(v-if="!isPortrait") {{ nonMtdEvent.time }}

//- Use item named `orderedEvt`
mixin multiDayEventMixture
  //- - Multi-Day
  .multi-days-placeholder.event-item(v-if="orderedEvt.multiDays", :key="orderedEvt.key"
                           :class="{'invisible': !isVisibleInSlot(odIndex)}")
    .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: orderedEvt.color}]")
    //- Display start time for multi-day but NOT multi-all-day events [DEV-3048]
    .start-time(v-if="orderedEvt.multiDays && !orderedEvt.allDay") {{ orderedEvt.time }}
    .event-title {{ orderedEvt.title }}

  //- - Single All-Day
  .single-allday-event.event-item(v-else-if="orderedEvt.allDay", :key="orderedEvt.key"
                      :class="{'invisible': !isVisibleInSlot(odIndex)}")
    .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: orderedEvt.color}]")
    .event-title {{ orderedEvt.title }}

  //- - Regular Event
  .regular-event.event-item(v-else, :key="orderedEvt.key"
                            :class="{'invisible': !isVisibleInSlot(odIndex)}")
    .ical-color(v-if="showIcalColor", :style="[icalFlagStyle, {background: orderedEvt.color}]")
    .event-title {{ orderedEvt.title }}
    .start-time(v-if="!isPortrait") {{ orderedEvt.time }}

//- / EOF MIXINS
//- ============

.day-events-slot(:class="{'light-theme': lightTheme, 'show-ical-color': showIcalColor}")
  template(v-if="!skipAddingItems")

    //- NO Multi-Day Events
    template(v-if="!hasMultiDayEvents")
      //- Paginate NOT needed
      template(v-if="!needsPaginate")
        template(v-for="(nonMtdEvent, nmeIndex) in nonMultiDayEventsInSlot")
          +nonMultiDayEvent

      //- Needs paginate
      template(v-if="needsPaginate")
        transition-group(name="fade", :duration="450")
          template(v-for="(nonMtdEvent, nmeIndex) in currentChunk")
            +nonMultiDayEvent

    //- Has Multi-Day Events
    template(v-if="hasMultiDayEvents")
      //- Paginate NOT needed
      template(v-if="!needsPaginate")
        template(v-for="(orderedEvt, odIndex) in orderedEvents")
          +multiDayEventMixture

      //- Needs paginate
      template(v-if="needsPaginate")
        transition-group(name="fade", :duration="450")
          template(v-for="(orderedEvt, odIndex) in currentChunk")
            +multiDayEventMixture
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.day-events-slot
  flex: 1 1 0.00001px
  overflow: hidden
  padding: 0 0.2em 0.2em 0.2em

  .event-item
    margin-bottom: 1px
    padding: 0.1em 0.3em
    overflow: hidden

    display: flex
    flex-flow: row nowrap
    justify-content: flex-start
    align-items: baseline

    font-size: 1.5em

    .event-title
      ellipsis()
      flex: 1 1 0.00001px

    &.invisible
      visibility: hidden !important

  .multi-days-placeholder,
  .single-allday-event
    border-radius: 0.15em

  // A vertical place holder
  .multi-days-placeholder
    visibility: hidden
    opacity: 0

  .single-allday-event
    background-color: -white(0.7)
    color: $appDarkTextColor
    text-shadow: none

  .regular-event
    padding: 0.1em 0.4em
    justify-content: space-between
    .start-time
      opacity: 0.6
      margin-left: 0.5em

  // SHOW ICAL COLOR
  &.show-ical-color
    .regular-event
      padding: 0.1em 0.3em
  //
  // LIGHT THEME
  //
  &.light-theme
    .single-allday-event
      appTextShadow()
      color: #fff
      background-color: -black(0.6)
</style>
