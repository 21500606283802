<script>
import Log from 'services/log.js'
import Env from 'services/environment'

// In ms. Display time for each message
const MSG_DISPLAY_DURATION = 4000

export default {
  name: 'MessageBanner',

  data () {
    return {
      messages: [],
      activeMessage: null,

      checkMessageTimer: null,
      displayTimer: null
    }
  },

  computed: {
    // DEV-4042
    showErrorLogOnly () {
      return [
        'production',
        'rc',
        'beta',
        'test'
      ].includes(Env.stage)
    }
  },

  mounted () {
    Log.on('created', this.handleNewLog)

    clearTimeout(this.displayTimer)

    clearInterval(this.checkMessageTimer)
    this.checkMessageTimer = setInterval(() => {
      if (!this.activeMessage && this.messages.length) {
        this.setActiveLog()
      }
    }, 1000)
  },

  beforeDestroy () {
    Log.removeListener('created', this.handleNewLog)
    clearInterval(this.checkMessageTimer)
    clearTimeout(this.displayTimer)
  },

  methods: {
    handleNewLog (log) {
      if (
        // Show ALL SDK messages
        log.fromSDK ||
        // Show "Error" level toast only for (semi-)PROD environments [DEV-4042]
        (this.showErrorLogOnly && log.severity === 'error') ||
        // Show "Error" and "Warn" level for the rest environments
        (!this.showErrorLogOnly && ['warn', 'warning', 'error'].includes(log.severity))
      ) {
        this.messages.push(log)
      }
    },

    setActiveLog () {
      if (this.messages.length) {
        this.activeMessage = this.messages.shift()
      } else {
        this.activeMessage = null
        return
      }

      clearTimeout(this.displayTimer)
      this.displayTimer = setTimeout(() => {
        clearTimeout(this.displayTimer)
        this.setActiveLog()
      }, MSG_DISPLAY_DURATION)
    }
  }
}
</script>

<template lang="pug">
section#last-log-overlay
  transition(name="customEase", :duration="500" appear
             enter-active-class="page-easeBottom-enter-active"
             leave-active-class="page-easeTop-leave-active")
    .log-overlay-inner(v-if="activeMessage")
      .time {{ activeMessage.time }}
      .message(:class="activeMessage.severity") {{activeMessage.message}}
</template>

<style lang="stylus">
section#last-log-overlay
  z-index: 1000
  position: absolute
  right: 0
  top: 0
  left: 0
  font-size: 1vh

  .log-overlay-inner
    position: absolute
    right: 0
    top: 0
    left: 0
    z-index: 1

    background-color: rgba(0, 0, 0, 0.4)
    color: white
    padding: 4px
    display: flex
    flex-flow: row nowrap
    justify-content: flex-start
    align-items: center

    animation-duration: .5s

    .time
      color: var(--gray)
      margin-right: 0.5em
    .info
      color: var(--brand-info)
    .warn
      color: var(--brand-warning)
    .error
      color: var(--brand-danger)
    .debug
      color: var(--gray-light)
</style>
