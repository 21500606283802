<script>
import MetricsMixins from './MetricsMixins.vue'
import MetricsDataMixins from './MetricsDataMixins.vue'

import GaugeItem from './GaugeItem.vue'

import { mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import NumberHelper from 'services/number-helper.js'
import { EventBus } from 'services/eventbus.js'

const config = {
  // In seconds. The interval check if any metric data is outdated
  REFRESH_INTERVAL: 60
}

export default {
  name: 'MetricsGauge',
  components: { GaugeItem },

  mixins: [MetricsMixins, MetricsDataMixins],

  props: {
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      data: [],
      itemsData: [],

      metricKeysList: [],
      metricRefreshQueue: [],
      itemsMetricKeysMap: [],

      metricRefreshTimer: undefined,
      firstRefreshTimer: undefined,
      refreshDelayTimer: undefined
    }
  },

  computed: {
    ...mapGetters({
      queriedValue: 'getQueriedValue',
      appWindowIsHidden: 'appWindowIsHidden'
    })
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    clearTimeout(this.refreshDelayTimer)

    EventBus.$on('ws-query-metrics', this.updateQueryData)
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)

    clearInterval(this.metricRefreshTimer)

    this.renderInitData()

    // Make sure next checking is around '0' point (with some random delay)
    clearTimeout(this.firstRefreshTimer)
    this.firstRefreshTimer = setTimeout(() => {
      clearTimeout(this.firstRefreshTimer)
      this.checkMetricValueFreshness()
      // Start interval checking
      clearInterval(this.metricRefreshTimer)
      this.metricRefreshTimer = setInterval(() => {
        this.checkMetricValueFreshness()
      }, config.REFRESH_INTERVAL * 1000)
    }, (60 - new Date().getSeconds()) * 1000 + ~~(Math.random() * 1000))
  },

  beforeDestroy () {
    EventBus.$off('ws-query-metrics', this.updateQueryData)
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
    clearInterval(this.metricRefreshTimer)
    clearTimeout(this.firstRefreshTimer)
    clearTimeout(this.refreshDelayTimer)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        return
      }

      const refsMap = []
      this.validItems.forEach((item, itemIdx) => {
        const metric = item.metric

        if (!refsMap[itemIdx]) {
          refsMap[itemIdx] = {}
        }

        const queryList = []

        if (metric && metric.ref && metric.ref.length) {
          refsMap[itemIdx].ref = metric.ref
        }

        if (metric.range_type !== 'current') {
          const qKey = Metrics.buildQueryKey(metric)
          if (qKey && !this.metricKeysList.includes(qKey)) {
            this.metricKeysList.push(qKey)
          }
          queryList.push(qKey)
        }

        // Max and Min in Metrics
        if (item.max && item.max.type === 'metric' && metric.ref) {
          const maxMetric = Metrics.toMaxAndMinMetric(metric, 'max')
          const qKey = Metrics.buildQueryKey(maxMetric)
          if (qKey && !this.metricKeysList.includes(qKey)) {
            this.metricKeysList.push(qKey)
          }
          queryList.push(qKey)
        }
        if (item.min && item.min.type === 'metric' && metric.ref) {
          const minMetric = Metrics.toMaxAndMinMetric(metric, 'min')
          const qKey = Metrics.buildQueryKey(minMetric)
          if (qKey && !this.metricKeysList.includes(qKey)) {
            this.metricKeysList.push(qKey)
          }
          queryList.push(qKey)
        }

        refsMap[itemIdx].query = queryList
      })

      this.itemsMetricKeysMap = refsMap
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateItemData (idx) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[idx]
      if (!item) { return }

      const metric = item.metric || {}

      const rounding = item.rounding || 0
      const abbreviate = item.abbreviate
      const valueType = item.value_type

      const track = { min: item.min, max: item.max, show_spike: item.show_spike }
      const label = { text: Metrics.subtitleText(metric) }

      const nextUpdateTime = Metrics.nextUpdateTime(metric)

      const targetData = (this.data && this.data[idx]) || {}

      if (metric.range_type !== 'current' && metric.bucket_size > 0) {
        const qKey = this.itemsMetricKeysMap[idx].query[0]
        if (qKey) {
          const queriedValue = this.queriedValue(qKey)
          if (typeof queriedValue !== 'undefined') {
            targetData[metric.range_type] = queriedValue
          }
        }
      }

      const valueIsFresh = Metrics.isFreshValue(targetData)

      if (nextUpdateTime) {
        this.$set(this.metricRefreshQueue, idx, {
          ts: nextUpdateTime,
          bucket_size: metric.bucket_size,
          ref: metric.ref
        })
      }

      // - Current Value
      if (metric.range_type === 'current') {
        let validValue
        if (valueIsFresh) {
          validValue = targetData.value
        } else if (NumberHelper.isNumber(targetData.value)) {
          validValue = 0
        }
        this.$set(this.itemsData, idx, {
          number: { value: validValue, rounding, abbreviate, value_type: valueType },
          label,
          track
        })
        return
      // - Aggregated value
      } else {
        const operation = metric.range_type
        if (operation) {
          if (operation === 'avg' || operation === 'average') {
            this.$set(this.itemsData, idx, {
              number: { value: targetData.sum / targetData.count, rounding, abbreviate, value_type: valueType },
              label,
              track
            })
            return
          } else {
            this.$set(this.itemsData, idx, {
              number: { value: targetData[operation] || 0, rounding, abbreviate, value_type: valueType },
              label,
              track
            })
            return
          }
        }
      }

      this.$set(this.itemsData, idx, {})
    },

    updateQueryData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.query) { return }
        for (let i = 0; i < item.query.length; i++) {
          const key = item.query[i]
          if (m.includes(key)) {
            this.updateItemData(idx)
            break
          }
        }
      })
    },

    checkMetricValueFreshness () {
      if (this.appWindowIsHidden) { return }

      const now = new Date().getTime() / 1000
      const list = this.metricRefreshQueue.filter(item => item.ts <= now)
      const keys = []
      const refs = []
      let hasMinuteKeys = false

      if (list && list.length) {
        list.forEach(item => {
          if (item.bucket_size <= 60) {
            hasMinuteKeys = true
          }
          if (item.key) {
            keys.push(item.key)
          } else if (item.ref) {
            refs.push(item.ref)
          }
        })
        if (refs.length) {
          this.updateInvolvedItems(refs)
        }
      }

      // Manually query for dynamic Max and Min values (this.metricKeysList)
      const allKeys = [].concat(JSON.parse(JSON.stringify(this.metricKeysList || [])), keys)
      if (allKeys.length) {
        // Add random delay timer to prevent overload the service at each zero points
        clearTimeout(this.refreshDelayTimer)
        this.refreshDelayTimer = setTimeout(() => {
          clearTimeout(this.refreshDelayTimer)
          EventBus.$emit('refresh-metric-query', allKeys)
        }, this.debounceDelta(hasMinuteKeys))
      }
    },

    debounceDelta (hasMinuteKeys) {
      return ~~((hasMinuteKeys ? Math.random() * 2 : 2 + 8 * Math.random()) * 1000)
    }
  }
}
</script>

<template lang="pug">
section.metrics-gauge
  transition(:name="transitionName", :duration="500" appear)
    gauge-item.prime(v-if="showPrime && primeItem"
              :title="itemTitle(primeItem)"
              :remove-title="removeTitle"
              :item="itemData(primeIndex)"
              :metric="primeItem.metric"
              :color="primeItem.color"
              :show-percent="primeItem.show_percent === 'true'"
              :chart-type="primeItem.chart_type"
              :is-paginated="needsPaginate"
              :light-theme="lightTheme")

  transition(:name="transitionName", :duration="500" appear)
    gauge-item.base(v-if="!showPrime && baseItem"
              :title="itemTitle(baseItem)"
              :remove-title="removeTitle"
              :item="itemData(baseIndex)"
              :metric="baseItem.metric"
              :color="baseItem.color"
              :show-percent="baseItem.show_percent === 'true'"
              :chart-type="baseItem.chart_type"
              :is-paginated="needsPaginate"
              :light-theme="lightTheme")
</template>

<style lang="stylus">
section.metrics-gauge
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-gauge-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
