<script>
import MomentTZ from 'moment-timezone'
import Moment from 'moment'

// NOTE: Import supported locale language list here
import 'moment/locale/zh-cn.js'
import 'moment/locale/fr.js'
import 'moment/locale/es.js'
import 'moment/locale/ru.js'
import 'moment/locale/de.js'
import 'moment/locale/sv.js'
import 'moment/locale/it.js'
import 'moment/locale/pt.js'
import 'moment/locale/ja.js'
import 'moment/locale/da.js'
import 'moment/locale/nl.js'

export default {
  name: 'LocaleLanguage',

  props: {
    lang: {
      type: String,
      default: 'en'
    }
  },

  watch: {
    lang () {
      this.updateLocaleLanguage()
    }
  },

  methods: {
    // Update Moment locale globally
    updateLocaleLanguage () {
      const langKey = this.$t('_momentLocaleKey') || 'en'
      if (!langKey || langKey === 'en') {
        Moment.locale('en')
        MomentTZ.defineLocale('en', Moment.localeData()._config)
        MomentTZ.locale('en')
      } else {
        Moment.locale(langKey)
        MomentTZ.defineLocale(langKey, Moment.localeData()._config)
        MomentTZ.locale(langKey)
      }
    }
  },

  mounted () {
    this.updateLocaleLanguage()
  },

  beforeDestroy () {
    // Make sure the MomentJS locale resume to default
    // after the Account/Device language is set back to English
    MomentTZ.locale('en')
  }
}
</script>

<template lang="pug">
aside.locale-language(:data-lang="lang")
</template>

<style lang="stylus">
aside.locale-language
  visibility: hidden
  opacity: 0
  z-index: -100
</style>
