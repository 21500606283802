<script>
import qs from 'qs'
import { mapGetters } from 'vuex'

import Env from 'services/environment'
import Webshots from 'services/webshots.js'
import Log from 'services/log.js'

// Ref DEV-3110
const WEBSHOT_BASE_URL = `${Env.contentURL()}/webshots`
// DEBUG: Hardcode test:
// const WEBSHOT_BASE_URL = 'https://qa-cdn.telemetrytv.com/webshots'

const config = {
  // In seconds
  DEFAUT_INTERVAL: 3600,
  MIN_INTERVAL: 60,
  MAX_INTERVAL: 86400,

  // In ms
  SHOW_HINT_AFTER: 5000
}

export default {
  name: 'Dashboard',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      webshotID: '',
      updated: '',

      // In seconds
      refreshInterval: +config.DEFAUT_INTERVAL,

      webshotURL: '',

      displayUrl: '',
      blobUrl: '',

      loading: true,
      showHint: false,
      errorMessage: '',

      hintTimer: undefined,
      refreshTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),

    // The raw screenshot URL without any search queries
    webshotRawURL () {
      if (!this.webshotID || !this.webshotID.length) {
        return false
      }
      return `${WEBSHOT_BASE_URL}/screenshots/${this.webshotID}`
    },

    backgroundImage () {
      if (!this.displayUrl || !this.displayUrl.length) { return }
      return {
        backgroundImage: `url(${this.displayUrl})`
      }
    },

    hasError () {
      return this.errorMessage && this.errorMessage.length
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    webshotURL (newValue) {
      if (newValue && newValue.length) {
        this.loadImage(newValue)
      }
    }
  },

  mounted () {
    clearInterval(this.refreshTimer)
    clearTimeout(this.hintTimer)

    // Force reset URL to prevent flicking of previous screenshot item
    this.displayUrl = ''

    this.render()
  },

  beforeDestroy () {
    clearInterval(this.refreshTimer)
    clearTimeout(this.hintTimer)

    this.revokeBlobURL(this.blobUrl)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.webshotID = options.webshotID || ''
      this.refreshInterval = Math.max(config.MIN_INTERVAL, Math.min(config.MAX_INTERVAL, +options.refreshInterval || +config.DEFAUT_INTERVAL))
      this.updated = options.updated || ''

      clearTimeout(this.hintTimer)
      this.hintTimer = setTimeout(() => {
        clearTimeout(this.hintTimer)
        if (this.loading) {
          this.showHint = true
        }
      }, config.SHOW_HINT_AFTER)

      this.updateWebshotURL()
      this.startRefreshTimer()
    },

    startRefreshTimer () {
      clearInterval(this.refreshTimer)
      this.refreshTimer = setInterval(() => {
        this.updateWebshotURL()
      }, this.refreshInterval * 1000)
    },

    updateWebshotURL () {
      if (!this.webshotRawURL) { return '' }

      // Add timestamp to make sure the we refetch the screenshot after some time
      const ts = ~~(Date.now() / this.refreshInterval / 1000)

      const updatedAt = this.updated && this.updated.length ? `&updated=${this.updated}` : ''

      this.webshotURL = `${this.webshotRawURL}?token=${this.activeToken}&noserviceworker=true&ts=${ts}${updatedAt}`
    },

    loadImage (url) {
      Webshots.getScreenshot(url, this.webshotID, this.refreshInterval).then(bolbData => {
        const blobUrl = URL.createObjectURL(bolbData)
        this.displayUrl = blobUrl

        this.errorMessage = ''
        this.loaded()

        if (blobUrl !== this.blobUrl) {
          this.revokeBlobURL(this.blobUrl)
        }

        // Store for GC in a latter phase
        this.blobUrl = blobUrl
      }).catch(err => {
        const errorMsg = err.message || err.toString() || 'Unknown Error'

        const message = `Error loading screenshot - ${errorMsg}`
        this.errorMessage = message
        this.loaded()

        Log.debug('media', `Dashboard: ${message}`, 'DBG_DASHBOARD', { error: err, url: this.webshotRawURL })
      })
    },

    revokeBolbURL (blobUrl = '') {
      if (!blobUrl.length) { return }
      // Revoke for better GC
      // Handle URL.revokeObjectURL() in the app.vue (root) with delay to prevent flickering
      EventBus.$emit('revoke-blob-url', blobUrl + '')
    },

    loaded () {
      clearTimeout(this.hintTimer)
      this.showHint = false
      this.loading = false
      this.$emit('loaded')
    }
  }
}
</script>

<template lang="pug">
section.dashboard-page
  .screenshot-container(:style="[backgroundImage]")

  transition(name="fade" appear)
    .messages(v-if="loading && showHint")
      p Loading Dashboard

  transition(name="fade" appear)
    .messages(v-if="!loading && hasError")
      p {{ errorMessage }}
</template>

<style lang="stylus">
section.dashboard-page
  width: 100%
  height: 100%
  border: 0
  position: relative
  z-index: 0

  .screenshot-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 10
    background-position: 50% 50%
    background-size: cover
    background-repeat: no-repeat

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 4vmin
</style>
