<script>
import Bowser from 'bowser'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {concat as Concat, flatten as Flatten, min as Min, max as Max, sum as Sum} from 'lodash'
import {
  select as Select, scaleLinear as ScaleLinear, scalePoint as ScalePoint, axisLeft as AxisLeft,
  axisRight as AxisRight, axisBottom as AxisBottom, scaleIdentity as ScaleIdentity, quantile as Quantile,
  line as Line, area as Area, curveBasis as CurveBasis, curveMonotoneX as CurveMonotoneX
} from 'd3'
import { mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import NumberHelper from 'services/number-helper.js'

const config = {
  DEFAUTL_FONT_SCALE: 0.8,
  DEFAULT_RENDERER: 'spline',
  DEFAULT_COLORS: [
    '#2aa4e5', '#31c384', '#fbd444', '#fc5543', '#69bfec', '#5acf9c',
    '#fbdc6d', '#fd7766', '#a9daf4', '#83dbb5', '#fce698', '#fd9f93'
  ],
  BASE_FONT_SIZE: 20,

  GRAPH_PADDING_LEFT: 0,
  GRAPH_PADDING_RIGHT: 0,
  GRAPH_PADDING_TOP: 8,
  GRAPH_PADDING_BOTTOM: 0,

  // vertical ticks
  DISTANCE_BETWEEN_Y_TICK: 50,

  // horizontal ticks for horizontal bar
  DISTANCE_BETWEEN_X_TICK: 75,

  // padding between number & tick
  TICK_PADDING: 4,

  // size of ticks
  TICK_SIZE: 6,

  // portion refers to (total x_labels' width / xAxis' width)
  PORTION_TO_SKIP_XLABELS: 0.9,

  DISTANCE_BETWEEN_Y_TICK_AND_GRAPH: 8,
  DISTANCE_BETWEEN_X_TICK_AND_GRAPH: 8,

  // for line graph
  LINE_TICKNESS: 2,

  // for area graph
  AREA_OPACITY: 0.4,

  // the dot of the scatterplot or line graph
  DOT_CIRCLE_RADIUS: 2.5,

  // for bar & stacked bar
  BAR_WIDTH: 16,
  // distance between a bar and another bar
  BAR_MARGIN: 4,

  // `line_width` configs
  LINE_WIDTH_THIN: 0.5,
  LINE_WIDTH_THICK: 2,

  // annotation y-offest, in px
  ANNOTATION_OFFSET_Y: -14,
  // annotation x-offest for `horizontalbar`, in px
  ANNOTATION_OFFSET_X: 5,

  // When chartHeight been squeezed less than this height (in px), hide legends
  MIN_HEIGHT_FOR_LEGEND: 100,

  // In em
  SHADOW_OFFSET: 0.065,
  SHADOW_BLUR: 0.15
}

const typedValue = function typedValue (value, valueType) {
  const prefixSuffix = NumberHelper.valueType(valueType)
  return prefixSuffix[0] + String(value) + prefixSuffix[1]
}

export default {
  name: 'MetricsLinechartItem',

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    itemData: { type: Array, default: () => { return [] } },
    isPaginated: { type: Boolean, default: false },
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      config: Object.assign({}, config),

      yAxisDelay: undefined,
      xAxisDelay: undefined,

      showXAxis: true,
      spreadTicks: false,

      _chartWidth: 0,
      _chartHeight: 0,

      _svg: undefined,
      _graph: undefined,

      _drawAreas: [],

      _valueMin: undefined,
      _valueMax: undefined,
      _valuesLength: 0,

      _xScale: undefined,
      _graphXScale: undefined,
      _xAxisScale: undefined,
      _xAxisHeight: 0,
      _xAxisPaddingRight: 0,
      _distanceBetweenXTick: 0,
      _xAxisTickCount: 0,

      _tickValues: [],
      _tickDistance: 0,
      _tickFormat: undefined,

      _yScale: undefined,
      _yAxisScale: undefined,
      _yAxisTickCount: 0,
      _yAxisWidth: 0,

      _domainLow: undefined,
      _domainHigh: undefined,

      _bucketSize: 0,
      _startUnix: 0,
      _endUnix: 0,

      // Streamchart related
      _animationRunning: false,
      _animationStartedTime: undefined,
      _pushValueFunctions: undefined,
      _pushValuesCount: 0,
      _newValues: undefined,
      _transitionendObserved: false,

      clipPath: false,
      low: undefined,
      high: undefined,
      chartReady: false,

      debounceUpdateTimer: undefined,

      disableTransition: true,
      fontSize: +config.DEFAUTL_FONT_SCALE
    }
  },

  computed: {
    ...mapGetters({
      winSizeRatio: 'winSizeRatio'
    }),

    browserInfo () {
      const bs = Bowser.getParser(window.navigator.userAgent)
      return bs.getBrowser() || {}
    },

    isIEorEdge () {
      const browserName = (this.browserInfo.name || '').toLowerCase()
      return browserName === 'edge' || browserName === 'internet explorer'
    },

    series () {
      return (this.item.series || [])
    },

    // If the 1st item is a horizontal bar then all others are horizontal bars, because horizontal bar cannot mix
    // with other chart types.
    // If the 1st item is not a horizontal bar, then horizontal bars in other series are rendered as splines.
    horizontalBar () {
      const series = this.series
      return (series.length > 0 && series[0].chart_type === 'horizontalbar')
    },

    bars () {
      return this.series.filter(s => s.chart_type === 'bar') || []
    },

    stackedBars () {
      return this.series.filter(s => s.chart_type === 'stackedbar') || []
    },

    barsCount () {
      return (this.bars.length + (this.stackedBars.length > 0 ? 1 : 0))
    },

    zeroBaseline () {
      return (this.item.baseline === 'zero')
    },

    gridLines () {
      switch (this.item.grid_lines) {
        case 'none':
        case 'vertical':
        case 'both':
          return this.item.grid_lines
        default:
          return 'horizontal'
      }
    },

    minScale () {
      return this.item.min_scale
    },

    hasMinScale () {
      return NumberHelper.isNumber(this.minScale)
    },

    yMin () {
      return this.item.y_min
    },

    hasYMin () {
      return NumberHelper.isNumber(this.yMin)
    },

    yMax () {
      return this.item.y_max
    },

    hasYMax () {
      return NumberHelper.isNumber(this.yMax)
    },

    useCustomYRange () {
      if (this.hasYMin || this.hasYMax) {
        return (this.yMin || 0) < (this.yMax || 0)
      }
      return false
    },

    _yMin () {
      if (this.useCustomYRange) {
        return this.yMin || 0
      }
    },

    _yMax () {
      if (this.useCustomYRange) {
        return this.yMax || 0
      }
    },

    legends () {
      return this.series.map(s => {
        if (!s.show_legend || !s.metric || !s.metric.ref) {
          return ''
        }
        return s.metric.ref.replace(/_|\./g, ' ').replace(/\|/g, ' by ').toUpperCase()
      })
    },

    hasLegend () {
      return !!this.legends.find(l => l.length > 0)
    },

    customXLabels () {
      const item = this.item
      if (item.x_labels === 'custom' && Array.isArray(item.custom_x_labels) && item.custom_x_labels.length > 0) {
        return item.custom_x_labels
      }
      return []
    },

    useCustomXLabels () {
      return (this.customXLabels.length > 0)
    },

    useAutoXLabels () {
      const item = this.item
      return (item.x_labels !== 'none' && !this.useCustomXLabels)
    },

    hasXLabels () {
      return (this.useAutoXLabels || this.useCustomXLabels)
    },

    customYLabels () {
      const item = this.item
      if (item.y_labels === 'custom' && Array.isArray(item.custom_y_labels) && item.custom_y_labels.length > 0) {
        return item.custom_y_labels
      }
      return []
    },

    useCustomYLabels () {
      return (this.customYLabels.length > 0)
    },

    useAutoYLabels () {
      const item = this.item
      return (item.y_labels !== 'none' && !this.useCustomYLabels)
    },

    hasYLabels () {
      return (this.useAutoYLabels || this.useCustomYLabels)
    },

    valueType () {
      return ((this.useAutoYLabels && this.item.value_type) || '')
    },

    rounding () {
      return (this.useAutoYLabels && this.item.rounding)
    },

    hasRounding () {
      return NumberHelper.isNumber(this.rounding)
    },

    abbreviate () {
      return (this.useAutoYLabels && this.item.abbreviate)
    },

    barWidthAndMargin () {
      const series = this.series
      const chartHeight = this._chartHeight
      const chartWidth = this._chartWidth
      const xAxisHeight = this._xAxisHeight
      const yAxisWidth = this._yAxisWidth
      const valuesLength = this._valuesLength
      const xAxisPaddingRight = this._xAxisPaddingRight
      const barsCount = this.barsCount
      const horizontalBar = this.horizontalBar

      let barWidthOption
      const item = series.find(s => s.bar_width)
      if (item) {
        barWidthOption = item.bar_width
      }

      let maxBarWidth
      if (horizontalBar) {
        maxBarWidth = ((chartHeight - xAxisHeight - config.GRAPH_PADDING_TOP) / valuesLength / (series.length || 1))
      } else {
        maxBarWidth = ((chartWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH - yAxisWidth - config.GRAPH_PADDING_LEFT - xAxisPaddingRight) / valuesLength / (barsCount || 1))
      }

      let barWidth
      let barMargin

      if (barWidthOption === 'thin') {
        barWidth = config.BAR_WIDTH * config.LINE_WIDTH_THIN
        barMargin = config.BAR_MARGIN
      } else if (barWidthOption === 'thick') {
        barWidth = config.BAR_WIDTH * config.LINE_WIDTH_THICK
        barMargin = config.BAR_MARGIN
      } else if (barWidthOption === 'maximum') {
        barWidth = maxBarWidth
        barMargin = 0
      } else {
        barWidth = config.BAR_WIDTH
        barMargin = config.BAR_MARGIN
      }

      if (barWidth > maxBarWidth - barMargin) {
        if (barWidth > maxBarWidth) {
          return {
            margin: 0,
            width: maxBarWidth
          }
        }
        return {
          width: barWidth,
          margin: maxBarWidth - barWidth
        }
      }
      return {
        width: barWidth,
        margin: barMargin
      }
    },

    barWidth () {
      return this.barWidthAndMargin.width || 0
    },

    barMargin () {
      return this.barWidthAndMargin.margin || 0
    },

    clippathId () {
      return `draw-area-clip-${this._uid}`
    },

    shadowId () {
      return `linechart-shadow-${this._uid}`
    },

    subTitle () {
      const series = this.series.find(s => (s.metric && s.metric.ref))
      if (!series) {
        return ''
      }
      if (series.metric.kind !== 'number') {
        return ''
      }
      return Metrics.rangeLabel(series.metric)
    }
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.debouncedUpdateItemsData()
      }
    },
    itemData: {
      deep: true,
      handler () {
        this.debouncedUpdateItemsData()
      }
    },
    winSizeRatio () {
      this.debouncedUpdateItemsData()
    },
    lightTheme () {
      this.debouncedUpdateItemsData()
    }
  },

  mounted () {
    clearTimeout(this.xAxisDelay)
    clearTimeout(this.yAxisDelay)
    clearTimeout(this.debounceUpdateTimer)

    // For better GC, use child element "sensor"
    // instead of binding `ref="outer"` to $el root
    AddResizeListener(this.$refs.sensor, this.debouncedUpdateItemsData)

    this.$on('linechart-wh-ready', this.renderChart)
    this.$on('x-axis-calculated', this.prepareAxis)
    this.$on('y-axis-ready', this.yAxisReady)
    this.debouncedUpdateItemsData()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debouncedUpdateItemsData)
    }

    this.$off('y-axis-ready', this.yAxisReady)
    this.$off('x-axis-calculated', this.prepareAxis)
    this.$off('linechart-wh-ready', this.renderChart)
    clearTimeout(this.xAxisDelay)
    clearTimeout(this.yAxisDelay)
    clearTimeout(this.debounceUpdateTimer)
  },

  methods: {
    // NOTE: [DEV-145]
    // Lodash Debounce function was causing slow memory leak
    // Its `.cancel()` method is not helping. Timer still presevered and preventing GC
    debouncedUpdateItemsData () {
      clearTimeout(this.debounceUpdateTimer)
      this.debounceUpdateTimer = setTimeout(() => {
        clearTimeout(this.debounceUpdateTimer)
        this.updateItemsData()
      }, 200)
    },

    updateItemsData () {
      let start
      let end
      let bucketSize = 0
      this.series.forEach((item, idx) => {
        const metric = item.metric || {}

        if (metric.series) {
          const startEnd = Metrics.getBucketTs(metric)
          if (!start || startEnd.start < start) {
            start = startEnd.start
          }
          if (!end || startEnd.end > end) {
            end = startEnd.end
          }

          if (metric.bucket_size > bucketSize) {
            bucketSize = metric.bucket_size
          }
        }
      })

      if (bucketSize > 0 && start && end) {
        this._bucketSize = bucketSize
        this._startUnix = start
        this._endUnix = end
      } else {
        this._bucketSize = 0
        this._startUnix = 0
        this._endUnix = 0
      }

      this.clipPath = false

      this.prepareChart()
    },

    prepareChart () {
      this.calcWidthHeight()
      this.adjustFontSize()
    },

    renderChart () {
      // can't render without enough space
      if (
        this._chartHeight <= (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) ||
        this._chartWidth <= (config.GRAPH_PADDING_LEFT + config.GRAPH_PADDING_RIGHT)
      ) {
        return
      }

      this.initSvg()
      this.initDrawArea()
      this.calcValuesLength()
      this.calcMinMax()
      this.calcXAxisHeight()

      // Wait for xAxis height calculation -> `prepareAxis`
    },

    adjustFontSize () {
      if (!this.$refs || !this.$refs.container) {
        return
      }

      const container = this.$refs.container

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        const metrics = container.getBoundingClientRect()
        const sizeRatio = Math.min(metrics.width || 0, metrics.height || 0) / 100 * 10.8 / 40
        if (config.DEFAUTL_FONT_SCALE * sizeRatio >= config.DEFAUTL_FONT_SCALE) {
          this.fontSize = config.DEFAUTL_FONT_SCALE
          FastDom.clear(measure)
          return
        }
        if (config.DEFAUTL_FONT_SCALE * sizeRatio <= 0.5) {
          this.fontSize = 0.5
          FastDom.clear(measure)
          return
        }
        this.fontSize = config.DEFAUTL_FONT_SCALE * sizeRatio

        FastDom.clear(measure)
      })
    },

    calcWidthHeight () {
      if (!this.$refs || !this.$refs.chart) {
        return
      }

      const chart = this.$refs.chart

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!chart) {
          FastDom.clear(measure)
          return
        }

        const rect = chart.getBoundingClientRect()

        const mutate = FastDom.mutate(() => {
          this._chartWidth = rect.width
          this._chartHeight = rect.height
          this.$emit('linechart-wh-ready')
          FastDom.clear(mutate)
        })
        FastDom.clear(measure)
      })
    },

    initSvg () {
      if (!this.$refs || !this.$refs.chart) {
        return
      }

      const chart = this.$refs.chart

      let svg = Select(chart).select('svg')
      let graph

      if (svg.empty()) {
        svg = Select(chart).append('svg:svg')
          .attr('width', this._chartWidth)
          .attr('height', this._chartHeight)

        const defs = svg.append('svg:defs')
        defs.append('svg:clipPath').attr('id', this.clippathId)
          .append('rect').attr('class', 'clip-rect')

        if (!this.isIEorEdge && !this.lightTheme) {
          const shadowOffset = config.BASE_FONT_SIZE * config.SHADOW_OFFSET * this.winSizeRatio
          defs.append('svg:filter').attr('id', this.shadowId)
            // Make sure shadows will not be clipped
            .attr('y', '-20%')
            .attr('x', '-20%')
            .attr('width', '140%')
            .attr('height', '140%')
            .append('feDropShadow')
            .attr('dx', shadowOffset)
            .attr('dy', shadowOffset)
            .attr('stdDeviation', config.BASE_FONT_SIZE * config.SHADOW_BLUR * this.winSizeRatio)
        }

        svg.append('svg:g')
          .attr('class', 'dummy-text')
          .style('visibility', 'hidden')
          .append('svg:g').attr('class', 'grid')
          .append('text').text('Abcde Fghij')

        graph = svg.append('svg:g').attr('class', 'graph')

        const clipped = graph.append('svg:g').attr('class', 'clipped')
        if (this.clipPath || this.useCustomYRange || this.horizontalBar) {
          clipped.attr('clip-path', 'url(' + document.location.href + '#' + this.clippathId + ')')
        } else {
          clipped.attr('clip-path', '')
        }
        clipped.append('svg:g').attr('class', 'temporal')
      } else {
        svg.attr('width', this._chartWidth).attr('height', this._chartHeight)
        graph = svg.select('g.graph')
      }

      this._svg = svg
      this._graph = graph
    },

    initDrawArea () {
      this._drawAreas = []

      // Grid Line areas
      let gridLineHorzArea = this._graph.select('g.grid-lines.horz-lines')
      if (gridLineHorzArea.empty()) {
        gridLineHorzArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'grid-lines horz-lines')
      }
      let gridLineVertArea = this._graph.select('g.grid-lines.vert-lines')
      if (gridLineVertArea.empty()) {
        gridLineVertArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'grid-lines vert-lines')
      }

      this.series.forEach((s, idx) => {
        const seriesClass = 'series-' + (idx + 1) + '-area'
        let drawArea = this._graph.select('g.draw-area.' + seriesClass)
        if (drawArea.empty()) {
          drawArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'draw-area ' + seriesClass)
        }
        if (!this.isIEorEdge && !this.lightTheme) {
          // Add Object shadow [#APP-2953]
          drawArea.style('filter', `url(#${this.shadowId})`)
        }
        this._drawAreas.push(drawArea)
      })

      // Annotation area
      let annotationArea = this._graph.select('g.draw-area.annotation')
      if (annotationArea.empty()) {
        annotationArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'draw-area annotation')
        this._drawAreas.push(annotationArea)
      }
    },

    sumStackedBarValues () {
      const stackedBarValues = []
      this.series.forEach((series, idx) => {
        if (series.chart_type === 'stackedbar') {
          (this.itemData[idx] || []).forEach((v, vIdx) => {
            stackedBarValues[vIdx] = (stackedBarValues[vIdx] || 0) + (v || 0)
          })
        }
      })
      return stackedBarValues
    },

    mapStackedBarValues () {
      const stackedBarValues = []
      const sums = []
      this.series.forEach((series, idx) => {
        if (series.chart_type === 'stackedbar') {
          stackedBarValues[idx] = []
          const values = (this.itemData[idx] || [])
          values.forEach((v, i) => {
            stackedBarValues[idx][i] = {}
            stackedBarValues[idx][i].start = (sums[i] || 0)
            sums[i] = (sums[i] || 0) + (v || 0)
            stackedBarValues[idx][i].end = (sums[i] || 0)
          })
        }
      })
      return stackedBarValues
    },

    calcMinMax () {
      let min
      if (this.zeroBaseline) {
        min = 0
      } else {
        min = Min(Flatten(this.itemData).filter(i => i !== null)) || 0 // `0`: fallback for completely empty series
      }

      let max
      const nonStackedBarValues = Flatten(
        this.series.map((s, idx) => {
          return s.chart_type === 'stackedbar' ? [] : (this.itemData[idx] || [])
        })
      ).filter(v => v !== null)
      const stackedBarValues = this.sumStackedBarValues()

      max = Max(Concat(nonStackedBarValues, stackedBarValues))
      if (typeof max === 'undefined') {
        max = 1 // Fallback for completely empty series
      }

      if (min === max) {
        min = min - (min / 10)
        max = max + (max / 10)
      }
      min = Math.floor(min / 10) * 10
      max = Math.ceil(max / 10) * 10

      // When y_max is set, ignore `min_scale`
      if (this.hasMinScale && this.minScale > max && !(this.useCustomYRange && this.hasYMax)) {
        max = this.minScale
      }

      this._valueMin = min
      this._valueMax = max
    },

    calcValuesLength () {
      this._valuesLength = Max(this.series.map((s, idx) => (this.itemData[idx] && this.itemData[idx].length) || 0)) || 0 // `0`: Fallback for completely empty series
    },

    calcXAxisHeight () {
      clearTimeout(this.xAxisDelay)

      let showXAxis = (!this.horizontalBar && this.hasXLabels) || (this.horizontalBar && this.hasYLabels)

      let rect
      if (showXAxis) {
        rect = this._svg.select('.dummy-text g.grid text').node().getBBox()
        if (!rect || rect.width === 0) {
          this.xAxisDelay = setTimeout(() => {
            clearTimeout(this.xAxisDelay)
            this.calcXAxisHeight()
          }, 50)
          return
        }
        clearTimeout(this.xAxisDelay)
        showXAxis = rect.width > 0 && ((rect.width * 2) < (this._chartWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH))
      }

      if (showXAxis && this.useCustomXLabels && !this.horizontalBar) {
        showXAxis = (((this._chartWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH) / this._valuesLength) > (rect.width / 2.5))
      }

      if (showXAxis) {
        this._xAxisHeight = rect.height + config.TICK_PADDING + config.TICK_SIZE
        this._xAxisPaddingRight = rect.width / 2 + config.GRAPH_PADDING_RIGHT
        this._distanceBetweenXTick = rect.width
      } else {
        this._xAxisHeight = 0
        this._xAxisPaddingRight = config.GRAPH_PADDING_RIGHT
        this._distanceBetweenXTick = config.DISTANCE_BETWEEN_X_TICK
      }

      if (this.clipPath && !this.horizontalBar) {
        this._xAxisPaddingRight = config.GRAPH_PADDING_RIGHT
      }

      this.showXAxis = showXAxis
      this.$emit('x-axis-calculated')
    },

    prepareAxis () {
      if (this.horizontalBar) {
        this.clipPath = true
        this.buildYScaleHorzBar()
        this.renderYAxisHorzBar()
      } else {
        this.buildYScale()
        this.renderYAxis()
      }
      // Move drawing methods to `drawChart` to ensure only perform drawing when yAxis' metrics calculation is ready
    },

    buildYScale () {
      const effectiveHeight =
        this._chartHeight -
        this._xAxisHeight -
        (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) -
        config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH

      let tickCount = ~~(
        (this._chartHeight - this._xAxisHeight - (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM)) /
        config.DISTANCE_BETWEEN_Y_TICK)
      if (tickCount < 2) {
        tickCount = 2
      }

      let domainMax
      // Fallback when all values are equal to zero
      if (this._valueMax === this._valueMin && this._valueMax === 0) {
        domainMax = 1
      } else {
        domainMax = this._valueMax
      }

      const domainInfo = {
        low: this._valueMin,
        high: domainMax
      }
      if (this.useCustomYRange && NumberHelper.isNumber(this._yMin)) {
        domainInfo.low = this._yMin
      }
      if (this.useCustomYRange && NumberHelper.isNumber(this._yMax)) {
        domainInfo.high = this._yMax
      }

      // yScale for data
      let yScale = ScaleLinear()
        .rangeRound([0, effectiveHeight])
        .domain([domainInfo.high, domainInfo.low])

      if (tickCount > 0 && !this.useCustomYRange) {
        yScale = yScale.nice(tickCount)
      }

      const domain = yScale.domain()
      this._yAxisTickCount = tickCount
      this._yScale = yScale
      this._domainLow = domain[1]
      this._domainHigh = domain[0]

      // yAxis scale for ticks
      if (this.useCustomYLabels) {
        this._yAxisScale = ScalePoint()
          .domain(this.customYLabels)
          .range([effectiveHeight, 0])
      } else {
        this._yAxisScale = yScale
      }

      // Streamchart
      this.low = this._domainLow
      this.high = this._domainHigh
      this.$emit('low', this.low)
      this.$emit('high', this.high)
    },

    renderYAxis () {
      if (!this.hasYLabels || this._yAxisTickCount === 0) {
        this._graph.select('g.grid.y-axis-area').remove()
        this._yAxisWidth = 0
        this.calcClippath()

        // Skip building yAxis Area (calcYAxisArea)
        this._yAxisWidth = config.TICK_PADDING + config.TICK_SIZE
        this.$emit('y-axis-ready')
        return
      }

      let tickValues = []
      if (this.useCustomYLabels) {
        tickValues = this.customYLabels
      } else {
        const step = (this._domainHigh - this._domainLow) / this._yAxisTickCount
        for (let i = 0; i <= this._yAxisTickCount; i++) {
          tickValues.push(this._domainLow + (step * i))
        }
      }

      let yAxis = AxisLeft()
        .scale(this._yAxisScale)
        .tickPadding(config.TICK_PADDING)
        .tickSizeInner(config.TICK_SIZE)
        .tickSizeOuter(1)
        .tickValues(tickValues)

      const abbreviate = this.abbreviate
      const valueType = this.valueType

      const numberFormat = (v) => {
        let rounding = this.rounding
        if (!rounding && rounding !== 0) {
          // Fallback when all values are equal to zero
          if (this._valueMax === this._valueMin && this._valueMax === 0) {
            rounding = 2
            // For domain range no greater than 1
          } else if (this._domainHigh - this._domainLow <= 1) {
            rounding = 2
            // For domain range no greater than 10
          } else if (this._domainHigh - this._domainLow <= 20) {
            rounding = 1
          }
        }
        return NumberHelper.formatter(v, {
          value: v,
          abbreviate,
          rounding,
          value_type: valueType
        })
      }

      if (!this.useCustomYLabels) {
        if (valueType) {
          yAxis = yAxis.tickFormat(v => {
            return typedValue(numberFormat(v), valueType)
          })
        } else {
          yAxis = yAxis.tickFormat(numberFormat)
        }
      }

      let yAxisArea = this._graph.select('g.grid.y-axis-area')
      if (yAxisArea.empty()) {
        yAxisArea = this._graph.append('svg:g').attr('class', 'grid y-axis-area')
      }
      yAxisArea.attr('font-size', null).attr('font-family', null)

      // Remove left-line for HorzBar
      yAxisArea.selectAll('g.left-line').remove()

      yAxisArea.call(yAxis)

      // To drop transparency on `value_types`
      if (valueType) {
        const prefixSuffix = NumberHelper.valueType(valueType)
        const valuePrefix = prefixSuffix[0] || ''
        const valueSuffix = prefixSuffix[1] || ''
        const yAxisText = yAxisArea.selectAll('text')
        yAxisText.each(function () {
          let textContent = this.textContent
          if (valuePrefix.length > 0) {
            textContent = textContent.slice(valuePrefix.length)
          }
          if (valueSuffix.length > 0) {
            textContent = textContent.slice(0, textContent.length - valueSuffix.length)
          }
          this.innerHTML = '<tspan>' + valuePrefix + '</tspan>' + textContent + '<tspan>' + valueSuffix + '</tspan>'
        })
      }

      this.calcYAxisArea(yAxisArea, tickValues)
    },

    calcYAxisArea (yAxisArea, tickValues) {
      clearTimeout(this.yAxisDelay)
      // Test Width
      const testBlock = yAxisArea.select('text').node()
      if (!testBlock || (testBlock.getBBox().width === 0 && testBlock.textContent && testBlock.textContent.length)) {
        this.yAxisDelay = setTimeout(() => {
          clearTimeout(this.yAxisDelay)
          this.calcYAxisArea(yAxisArea, tickValues)
        }, 50)
        return
      }

      let yAxisWidth = 0
      yAxisArea.selectAll('text').each(function () {
        const elWidth = this.getBBox().width
        if (elWidth > yAxisWidth) {
          yAxisWidth = elWidth
        }
      })
      this._yAxisWidth = yAxisWidth + config.TICK_PADDING + config.TICK_SIZE
      this.buildGridLineHorz(tickValues)
      this.$emit('y-axis-ready')
    },

    buildGridLineHorz (tickValues) {
      // Horizontal Grid Lines
      const gridLineHorzArea = this._graph.select('g.grid-lines.horz-lines')
      if (this.gridLines === 'horizontal' || this.gridLines === 'both') {
        let rectWidth
        if (this.barsCount > 0) {
          rectWidth = this._chartWidth - this._yAxisWidth - config.GRAPH_PADDING_LEFT - config.GRAPH_PADDING_RIGHT
        } else {
          rectWidth = this._chartWidth - this._yAxisWidth - config.GRAPH_PADDING_LEFT - this._xAxisPaddingRight
        }
        const horzGridLines = AxisRight()
          .scale(this._yAxisScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(rectWidth)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(function () {
            return ''
          })
        gridLineHorzArea.call(horzGridLines)
        // Remove the extra left line
        gridLineHorzArea.selectAll('path.domain').remove()
      } else {
        gridLineHorzArea.selectAll('*').remove()
      }

      this.calcClippath()
    },

    calcClippath () {
      if ((!this.clipPath && !this.useCustomYRange) || !this._chartWidth) { return }

      let rectWidth = this._chartWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH - this._xAxisPaddingRight - this._yAxisWidth
      if (rectWidth < 0) {
        rectWidth = 0
      }

      let rectHeight = this._chartHeight
      if (this.useCustomYRange) {
        rectHeight = this._chartHeight - this._xAxisHeight - config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH - (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM)
      }

      this._svg.select('defs rect.clip-rect')
        .attr('x', config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH)
        .attr('y', 0)
        .attr('width', rectWidth)
        .attr('height', rectHeight)
    },

    yAxisReady () {
      if (!this.disableTransition) {
        this.drawChart()
      } else {
        if (this.chartReady) {
          this.redrawChart()
        } else {
          this.drawChart()
        }
      }
    },

    redrawChart () {
      this.drawChart()
    },

    drawChart () {
      if (this.horizontalBar) {
        this.calcClippathHorzBar()
        this.positionGraph()
        this.buildXScaleHorzBar()
        this.renderXAxisHorzBar()

        this.series.forEach((item, index) => {
          this.drawHorizontalBar(index, !this.disableTransition)
        })
      } else {
        this.positionGraph()
        this.buildXScale()
        this.renderXAxis()

        this.series.forEach((series, index) => {
          switch (series.chart_type) {
            case 'scatter':
              this.drawScatter(index, !this.disableTransition)
              break
            case 'area':
              this.drawArea(index, !this.disableTransition)
              break
            case 'bar':
              this.drawBar(index, !this.disableTransition)
              break
            case 'stackedbar':
              this.drawStackedBar(index, !this.disableTransition)
              break
            case 'line':
              this.drawLine(index, false, !this.disableTransition)
              break
            default:
              this.drawLine(index, true, !this.disableTransition)
          }
        })
      }

      this.removeExtraSeries()
      // this.renderLabelStyle()
    },

    positionGraph () {
      if (this.isIEorEdge) {
        this._graph.node().setAttribute('transform', `translate(${config.GRAPH_PADDING_LEFT + this._yAxisWidth},${config.GRAPH_PADDING_TOP})`)
      } else {
        this._graph.node().style.transform = `translate(${config.GRAPH_PADDING_LEFT + this._yAxisWidth}px,${config.GRAPH_PADDING_TOP}px)`
      }
    },

    buildXScale () {
      this.spreadTicks = (this.useCustomXLabels && this._valuesLength !== this.customXLabels.length)

      let rangeInfo
      if (this.barsCount > 0) {
        const xDelta = (this.barsCount * (this.barWidth + this.barMargin)) / 2
        rangeInfo = [
          config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH + xDelta,
          this._chartWidth - this._yAxisWidth - config.GRAPH_PADDING_LEFT - this._xAxisPaddingRight - xDelta
        ]
      } else {
        rangeInfo = [
          config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH,
          this._chartWidth - this._yAxisWidth - config.GRAPH_PADDING_LEFT - this._xAxisPaddingRight
        ]
      }

      // xScale for data
      const xScale = ScaleLinear()
        .domain([0, this._valuesLength - 1])
        .range(rangeInfo)
      this._xScale = xScale

      // xAxis scale for ticks
      this._xAxisTickCount = ~~((rangeInfo[1] - rangeInfo[0]) / this._distanceBetweenXTick)
      if (this.spreadTicks && this.useCustomXLabels) {
        this._xAxisScale = ScalePoint()
          .domain(this.customXLabels)
          .range(rangeInfo)
      } else {
        this._xAxisScale = ScaleLinear()
          .domain([0, this._valuesLength - 1])
          .range(rangeInfo)
      }

      this._graphXScale = function (d, i) {
        return xScale(i)
      }
    },

    renderXAxis () {
      const effectiveHeight = this._chartHeight - this._xAxisHeight - (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM)

      let xAxis
      let xAxisArea = 0
      let vertGridLines

      let tickValues

      // [A]
      if (this.showXAxis) {
        let domainWidth = 1
        if (this.clipPath) {
          domainWidth = 0
        }

        if (this.useCustomXLabels) {
          xAxis = AxisBottom()
            .scale(this._xAxisScale)
            .tickPadding(config.TICK_PADDING)
            .tickSizeInner(config.TICK_SIZE)
            .tickSizeOuter(domainWidth)
            .ticks(this._valuesLength)

          if (!this.spreadTicks) {
            xAxis.tickFormat(i => {
              return this.customXLabels[i]
            })
          }

          vertGridLines = AxisBottom()
            .scale(this._xAxisScale)
            .tickPadding(config.TICK_PADDING)
            .tickSizeInner(effectiveHeight)
            .tickSizeOuter(1)
            .ticks(this._valuesLength)
            .tickFormat(function () {
              return ''
            })
        } else if (this._bucketSize && this._startUnix && this._endUnix && this._endUnix > this._startUnix) {
          const diffTime = (this._endUnix - this._startUnix)
          const rangeInfo = this._xAxisScale.range()
          tickValues = NumberHelper.calcTickValues(0, this._valuesLength - 1, this._distanceBetweenXTick, rangeInfo[1] - rangeInfo[0])
          const format = NumberHelper.d3TimeTickFormat(this._bucketSize)
          const distance = diffTime / (this._valuesLength - 1)
          xAxis = AxisBottom()
            .scale(this._xAxisScale)
            .tickPadding(config.TICK_PADDING)
            .tickSizeInner(config.TICK_SIZE)
            .tickSizeOuter(domainWidth)
            .tickValues(tickValues)
            .tickFormat(i => {
              const val = this._startUnix + (distance * i)
              if (i === 0) {
                return format.firstFormat(new Date(val * 1000))
              } else {
                return format.format(new Date(val * 1000))
              }
            })

          vertGridLines = AxisBottom()
            .scale(this._xAxisScale)
            .tickPadding(config.TICK_PADDING)
            .tickSizeInner(effectiveHeight)
            .tickSizeOuter(1)
            .tickValues(tickValues)
            .tickFormat(function () {
              return ''
            })

          this._tickValues = tickValues
          this._tickDistance = distance
          this._tickFormat = format.format
        }
      } // EOF [A] if (this.showXAxis)

      if (xAxis) {
        // [B1] HAS xAxis
        xAxisArea = this._graph.select('g.grid.x-axis-area')
        if (xAxisArea.empty()) {
          if (this.useCustomYRange) {
            xAxisArea = this._graph.append('svg:g').attr('class', 'grid x-axis-area')
          } else {
            xAxisArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'grid x-axis-area')
          }
        }
        xAxisArea.attr('font-size', null).attr('font-family', null)

        xAxisArea.attr('transform', 'translate(0,' + (this._chartHeight - this._xAxisHeight - (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM)) + ')').call(xAxis)
        xAxisArea.selectAll('g.bottom-line').remove()

        // Prevent overlapping xAxis line at the bottom
        xAxisArea.selectAll('path.domain').remove()

        let bottomLine
        if (this.clipPath) {
          bottomLine = AxisBottom()
            .scale(ScaleIdentity().domain([0, this._chartWidth]))
            .ticks(0)
            .tickSizeInner(0)
            .tickSizeOuter(1)
          xAxisArea.append('svg:g').attr('class', 'bottom-line').call(bottomLine)
        } else if (this.barsCount > 0) {
          bottomLine = AxisBottom()
            .scale(ScaleIdentity().domain([config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH, this._chartWidth - this._xAxisPaddingRight]))
            .ticks(0)
            .tickSizeInner(0)
            .tickSizeOuter(1)
          xAxisArea.append('svg:g').attr('class', 'bottom-line').call(bottomLine)
        } else {
          bottomLine = AxisBottom()
            .scale(this._xAxisScale)
            .ticks(0)
            .tickSizeInner(0)
            .tickSizeOuter(1)
          xAxisArea.append('svg:g').attr('class', 'bottom-line').call(bottomLine)
        }

        // [C]
        if (this.hasXLabels) {
          const xAxisWidth = this._chartWidth - this._yAxisWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH - config.GRAPH_PADDING_LEFT - this._xAxisPaddingRight
          // Calc total x_label width
          const textArray = []
          xAxisArea.selectAll('text').each(function () {
            textArray.push(this.getBBox().width)
          })

          const xlabelsLen = textArray.length
          const calibrateWidth = (Quantile(textArray, 0.25) + Quantile(textArray, 0.5) + Quantile(textArray, 0.75)) / 3 * xlabelsLen
          const totalLabelWidth = Math.max(Sum(textArray), calibrateWidth)
          const availableWidth = this.useCustomXLabels ? (xAxisWidth / (xlabelsLen - 1)) : (this._xScale(tickValues[1]) - this._xScale(0))

          const portion = totalLabelWidth / xAxisWidth
          const skipStep = Math.ceil(totalLabelWidth / (xlabelsLen - 1) / availableWidth)

          const self = this // `this` object will be the DOM object.
          xAxisArea.selectAll('text').each(function (elm, idx) {
            const text = this.textContent || ''
            const actualLength = text.length
            const actualWidth = this.getBBox().width

            // Calibrate available width
            const _availableWidth = availableWidth * skipStep

            // Skipping labels
            if (portion > config.PORTION_TO_SKIP_XLABELS && idx !== 0 && idx % skipStep !== 0) {
              this.textContent = ''

              // Display labels
            } else {
              if (actualWidth > _availableWidth) {
                let availableLength = Math.floor(actualLength * _availableWidth / actualWidth) - 2
                if (self.useCustomXLabels) {
                  if ((idx === 0 || this.customXLabels.length - 1 === idx) && availableLength > 10) {
                    availableLength = 10
                  }
                } else if (this._startUnix) {
                  if ((idx === 0 || tickValues.length - 1 === idx) && availableLength > 10) {
                    availableLength = 10
                  }
                }
                // Add ellipsis or skip
                if (availableLength >= 2) {
                  this.textContent = text.substr(0, availableLength - 1) + '…'
                } else {
                  this.textContent = ''
                }
              }
            }
          })
        } // EOF [C] if (this.hasXLabels)

        // Vertical Grid Lines
        const gridLineVertArea = this._graph.select('g.grid-lines.vert-lines')
        if (this.gridLines === 'vertical' || this.gridLines === 'both') {
          gridLineVertArea.call(vertGridLines)
          // Remove the extra top line
          gridLineVertArea.selectAll('path.domain').remove()
        } else {
          gridLineVertArea.selectAll('*').remove()
        }
      } else {
        // [B2] NO xAxis
        this._graph.selectAll('g.grid.x-axis-area').remove()
        const gridLineVertArea = this._graph.select('g.grid-lines.vert-lines')
        gridLineVertArea.selectAll('*').remove()
      }

      // for Streamchart
      this.chartReady = true
      this.$emit('chart-ready', true)
    },

    drawLine (index, interpolate, animate) {
      const className = interpolate ? 'renderer-spline' : 'renderer-line'
      const values = this.itemData[index] || []
      const color = this.seriesColor(index)
      const drawArea = this._drawAreas[index]

      let valueline = Line().x(this._graphXScale).y(this._yScale).defined(function (d) {
        return d !== null && NumberHelper.isNumber(d)
      })

      if (interpolate) {
        valueline = valueline.curve(animate ? CurveMonotoneX : CurveBasis)
      }

      let path = drawArea.select('path.' + className)
      if (path.empty()) {
        path = drawArea.append('svg:path').attr('class', className)
      }

      let pathData = path.data([values])
      pathData.attr('stroke', color).attr('stroke-width', config.LINE_TICKNESS).attr('fill', 'none').attr('vector-effect', 'non-scaling-stroke')
      if (animate) {
        pathData = pathData.transition()
      }
      pathData.attr('d', valueline)

      this.removeOtherRenderers(className, drawArea)
    },

    drawScatter (index, animate) {
      const className = 'renderer-scatter'
      const values = this.itemData[index] || []
      const color = this.seriesColor(index)
      const drawArea = this._drawAreas[index]

      const drawCircles = () => {
        const circleRadius = d => {
          return (d === null ? 0 : config.DOT_CIRCLE_RADIUS)
        }

        const circle = drawArea.selectAll('circle.' + className).data(values)

        const enter = circle.enter().append('svg:circle').attr('class', className)
          .attr('stroke-width', circleRadius).attr('r', circleRadius)

        let sel = circle.merge(enter)
        if (animate) {
          sel = sel.transition()
        }
        sel.attr('stroke', color).attr('fill', color)
          .attr('cx', this._graphXScale).attr('cy', this._yScale)

        circle.exit().remove()
      }

      drawCircles()

      this.removeOtherRenderers(className, drawArea)
    },

    drawArea (index, animate) {
      const className = 'renderer-area'
      const values = this.itemData[index] || []
      const color = this.seriesColor(index)
      const drawArea = this._drawAreas[index]

      const area = Area().curve(animate ? CurveMonotoneX : CurveBasis)
        .x(this._graphXScale).y0(this._yScale(this._domainLow)).y1(this._yScale).defined(function (d) {
          return d !== null && NumberHelper.isNumber(d)
        })

      let path = drawArea.select('path.' + className)
      if (path.empty()) {
        path = drawArea.append('svg:path').attr('class', className)
      }

      let pathData = path.data([values])
      pathData.attr('stroke', color).attr('stroke-opacity', config.AREA_OPACITY).attr('fill', color).attr('fill-opacity', config.AREA_OPACITY)
      if (animate) {
        pathData = pathData.transition()
      }
      pathData.attr('d', area)

      this.removeOtherRenderers(className, drawArea)
    },

    drawBar (index, animate) {
      const className = 'renderer-bar'
      const series = this.series
      const values = this.itemData[index] || []
      const color = this.seriesColor(index)
      const drawArea = this._drawAreas[index]

      let barIndex = -1
      for (let i = 0; i <= index; i++) {
        if (series[i].chart_type === 'bar') {
          barIndex++
        }
      }
      barIndex = barIndex + (this.stackedBars.length > 0 ? 1 : 0)

      const xScaleBar = (d, i) => {
        return this._xScale(i) + (barIndex * (this.barWidth + this.barMargin))
      }

      const drawBarLines = () => {
        const line = drawArea.selectAll('line.' + className).data(values)

        const enter = line.enter().append('svg:line').attr('class', className).attr('fill', 'none')

        let sel = line.merge(enter)
        if (animate) {
          sel = sel.transition()
        }
        sel.attr('x1', xScaleBar).attr('x2', xScaleBar)
          .attr('stroke', color)
          .attr('stroke-width', d => {
            return (d === null ? 0 : this.barWidth)
          })
          .attr('y1', d => {
            return this._yScale(d === null ? this._domainLow : d)
          })
          .attr('y2', this._yScale(this._domainLow))

        line.exit().remove()
      }

      drawBarLines()

      this.removeOtherRenderers(className, drawArea)
    },

    drawStackedBar (itemIndex, animate) {
      const firstIndex = this.series.findIndex(s => s.chart_type === 'stackedbar')
      if (itemIndex > firstIndex) {
        return
      }

      const className = 'renderer-stackedbar'

      const stackedBarValues = this.mapStackedBarValues()

      this.series.forEach((series, index) => {
        if (series.chart_type !== 'stackedbar') {
          return
        }

        const values = this.itemData[index] || []
        const color = this.seriesColor(index)
        const drawArea = this._drawAreas[index]

        const xScaleStackedBar = (d, i) => {
          return this._xScale(i)
        }

        const y1ScaleStackedBar = (d, i) => {
          let v = stackedBarValues[index][i].start
          if (index === 0 && v === 0) {
            v = this._domainLow
          }
          return this._yScale(v)
        }

        const y2ScaleStackedBar = (d, i) => {
          return this._yScale(stackedBarValues[index][i].end)
        }

        const drawStackedBarLines = () => {
          const line = drawArea.selectAll('line.' + className).data(values)

          const enter = line.enter().append('svg:line').attr('class', className).attr('fill', 'none')

          let sel = line.merge(enter)
          if (animate) {
            sel = sel.transition()
          }

          sel.attr('x1', xScaleStackedBar)
            .attr('stroke', color)
            .attr('stroke-width', this.barWidth)
            .attr('x2', xScaleStackedBar)
            .attr('y1', y1ScaleStackedBar)
            .attr('y2', y2ScaleStackedBar)

          line.exit().remove()
        }

        drawStackedBarLines()

        this.removeOtherRenderers(className, drawArea)
      })
    },

    removeOtherRenderers (className, drawArea) {
      const allRenderers = ['line', 'spline', 'area', 'bar', 'stackedbar', 'scatter', 'horizontalbar']
      allRenderers.forEach(r => {
        const c = `renderer-${r}`
        if (c !== className) {
          drawArea.selectAll(`.${c}`).remove()
        }
      })
    },

    removeExtraSeries () {
      const currentLength = this.series.length
      const oldLength = this._graph.selectAll('.draw-area').size()
      for (let i = currentLength; i < oldLength; i++) {
        this._graph.selectAll('.series-' + (i + 1) + '-area').remove()
      }
    },

    seriesColor (index) {
      const color = this.series[index] && this.series[index].color
      if (color) {
        return color
      }
      return config.DEFAULT_COLORS[index % config.DEFAULT_COLORS.length]
    },

    // HORIZONTAL BAR

    buildYScaleHorzBar () {
      const series = this.series

      let requiredHeight =
        (series.length * this._valuesLength * (this.barWidth + this.barMargin)) +
        this._xAxisHeight +
        (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) +
        config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH +
        (this.barWidth / 2)
      requiredHeight = Math.max(requiredHeight, this._chartHeight)

      this._yScale = ScaleLinear()
        .domain([0, this._valuesLength - 1])
        .range([
          (this.barWidth / 2),
          (requiredHeight -
            this._xAxisHeight -
            (series.length * (this.barWidth + this.barMargin)) -
            (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) -
            config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH)
        ])

      const yDomainMax = this._valuesLength - 1

      this._yAxisScale = ScaleLinear()
        .domain([0, yDomainMax])
        .range([
          (this.barWidth / 2),
          (requiredHeight -
            this._xAxisHeight -
            (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) -
            config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH)
        ])

      // If need to show yAxis

      const rect = this._svg.select('.dummy-text g.grid text').node().getBBox()
      this._distanceBetweenYTick = rect.height

      this._yAxisTickCount = 0

      if (this.hasXLabels) {
        this._yAxisTickCount = ~~(
          (requiredHeight -
            this._xAxisHeight -
            (config.GRAPH_PADDING_TOP + config.GRAPH_PADDING_BOTTOM) -
            config.DISTANCE_BETWEEN_X_TICK_AND_GRAPH) /
          this._distanceBetweenYTick
        )
      }
    },

    renderYAxisHorzBar () {
      if (!this.hasXLabels) {
        this._graph.select('g.grid.y-axis-area').remove()
        this._yAxisWidth = 0
        this.calcClippathHorzBar()

        this._yAxisWidth = config.TICK_PADDING + config.TICK_SIZE
        this.$emit('y-axis-ready')
        return
      }

      const ticksLength = this._valuesLength

      const tickValues = []
      for (let i = 0; i < ticksLength; i++) {
        tickValues.push(i)
      }

      let yAxis

      // [A] if (data.x_labels)
      if (this.useCustomXLabels) {
        yAxis = AxisLeft()
          .scale(this._yScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(config.TICK_SIZE)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(i => {
            return this.customXLabels[i]
          })
      } else if (this._bucketSize && this._startUnix && this._endUnix && this._endUnix > this._startUnix) {
        const diff = (this._endUnix - this._startUnix)
        const format = NumberHelper.d3TimeTickFormat(this._bucketSize)
        const distance = diff / (ticksLength - 1)

        yAxis = AxisLeft()
          .scale(this._yScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(config.TICK_SIZE)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(i => {
            const val = this._startUnix + (distance * i)
            if (i === 0) {
              return format.firstFormat(new Date(val * 1000))
            } else {
              return format.format(new Date(val * 1000))
            }
          })
      } // EOF [A] if (data.x_labels)

      // [B] if (yAxis)
      if (yAxis) {
        let yAxisArea = this._graph.select('g.grid.y-axis-area')
        if (yAxisArea.empty()) {
          if (this.useCustomYRange) {
            yAxisArea = this._graph.append('svg:g').attr('class', 'grid y-axis-area')
          } else {
            yAxisArea = this._graph.select('g.temporal').append('svg:g').attr('class', 'grid y-axis-area')
          }
        }
        yAxisArea.attr('font-size', null).attr('font-family', null)
        yAxisArea.call(yAxis)

        this.calcYAxisAreaHorzBar(yAxisArea, tickValues)

        yAxisArea.selectAll('g.left-line').remove()

        const leftLine = AxisLeft()
          .scale(this._yAxisScale)
          .ticks(0)
          .tickSizeInner(0)
          .tickSizeOuter(1)

        yAxisArea.append('svg:g').attr('class', 'left-line').call(leftLine)

        // Check Available Height
        if (this.hasXLabels) {
          const availableHeight = (this.useCustomXLabels ? (this._yScale(1) - this._yScale(0)) : (this._yScale(tickValues[1]) - this._yScale(0)))

          const ticksLength = yAxisArea.selectAll('text').size()
          const maxTicksCount = Math.min(ticksLength, this._yAxisTickCount)
          const step = ~~(ticksLength / maxTicksCount)

          yAxisArea.selectAll('text')
            .each(function (idx) {
              const actualHeight = this.getBBox().height
              if (actualHeight > availableHeight) {
                if (idx % step !== 0) {
                  this.textContent = ''
                }
              }
            })
        }
      } else {
        this._graph.select('g.grid.y-axis-area').remove()
        this._yAxisWidth = 0

        const gridLineHorzArea = this._graph.select('g.grid-lines.horz-lines')
        gridLineHorzArea.selectAll('*').remove()

        this.$emit('y-axis-ready')
      } // EOF [B] if (yAxis)
    },

    calcYAxisAreaHorzBar (yAxisArea, tickValues) {
      clearTimeout(this.yAxisDelay)
      // Test Width
      const testBlock = yAxisArea.select('text').node()
      if (testBlock && testBlock.getBBox().width === 0 && testBlock.textContent && testBlock.textContent.length) {
        this.yAxisDelay = setTimeout(() => {
          clearTimeout(this.yAxisDelay)
          this.calcYAxisAreaHorzBar(yAxisArea, tickValues)
        }, 50)
        return
      }

      let yAxisWidth = 0
      yAxisArea.selectAll('text').each(function () {
        const elWidth = this.getBBox().width
        if (elWidth > yAxisWidth) {
          yAxisWidth = elWidth
        }
      })
      this._yAxisWidth = yAxisWidth + config.TICK_PADDING + config.TICK_SIZE
      this.buildGridLineHorzHorzBar(tickValues)
      this.$emit('y-axis-ready')
    },

    calcClippathHorzBar () {
      if (!this.clipPath) {
        return
      }

      let rectWidth = this._chartWidth + config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH + this._yAxisWidth
      if (this.useCustomYRange) {
        rectWidth = this._chartWidth - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH - this._yAxisWidth - this._xAxisPaddingRight
      }
      const rectHeight = Math.max(0, this._chartHeight - this._xAxisHeight - config.GRAPH_PADDING_TOP)

      // Prevent clipping the topmost yAxis label
      const yDelta = 10

      this._svg.select('defs rect.clip-rect')
        .attr('x', this.useCustomYRange ? config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH : 0 - config.DISTANCE_BETWEEN_Y_TICK_AND_GRAPH - this._yAxisWidth)
        .attr('y', this.useCustomYRange ? 0 : -yDelta)
        .attr('width', rectWidth)
        .attr('height', this.useCustomYRange ? rectHeight : rectHeight + yDelta * 2)
    },

    buildGridLineHorzHorzBar (tickValues) {
      const effectiveWidth = this._chartWidth - (this._yAxisWidth || 0) - this._xAxisPaddingRight

      let horzGridLines
      if (this.useCustomXLabels) {
        horzGridLines = AxisLeft()
          .scale(this._yScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(-effectiveWidth)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(function () { return '' })
      } else if (this._bucketSize && this._startUnix && this._endUnix && this._endUnix > this._startUnix) {
        horzGridLines = AxisLeft()
          .scale(this._yScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(-effectiveWidth)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(function () { return '' })
      }

      // Horizontal Grid Lines for HorzBar
      const gridLineHorzArea = this._graph.select('g.grid-lines.horz-lines')
      if (this.gridLines === 'horizontal' || this.gridLines === 'both') {
        gridLineHorzArea.call(horzGridLines)
        // Remove the extra left line
        gridLineHorzArea.selectAll('path.domain').remove()
      } else {
        gridLineHorzArea.selectAll('*').remove()
      }
    },

    buildXScaleHorzBar () {
      const effectiveWidth = this._chartWidth - this._yAxisWidth - this._xAxisPaddingRight - config.GRAPH_PADDING_LEFT
      const tickCount = ~~(effectiveWidth / config.DISTANCE_BETWEEN_X_TICK)

      let domainMax
      // Fallback when all values are equal to zero
      if (this._valueMax === this._valueMin && this._valueMax === 0) {
        domainMax = 1
      } else {
        domainMax = this._valueMax
      }

      const domainInfo = {
        low: this._valueMin,
        high: domainMax
      }
      if (this.useCustomYRange && NumberHelper.isNumber(this._yMin)) {
        domainInfo.low = this._yMin
      }
      if (this.useCustomYRange && NumberHelper.isNumber(this._yMax)) {
        domainInfo.high = this._yMax
      }

      // xScale for data
      let xScale = ScaleLinear()
        .rangeRound([config.GRAPH_PADDING_LEFT, this._chartWidth - this._yAxisWidth - this._xAxisPaddingRight])
        .domain([domainInfo.low, domainInfo.high])

      if (tickCount > 0 && !this.useCustomYRange) {
        xScale = xScale.nice(tickCount)
      }

      const domain = xScale.domain()
      this._xAxisTickCount = tickCount
      this._xScale = xScale
      this._domainLow = domain[1]
      this._domainHigh = domain[0]

      // xAxis scale for ticks
      if (this.useCustomYLabels) {
        this._xAxisScale = ScalePoint()
          .domain(this.customYLabels)
          .range([config.GRAPH_PADDING_LEFT, this._chartWidth - this._yAxisWidth - this._xAxisPaddingRight])
      } else {
        this._xAxisScale = xScale
      }
    },

    renderXAxisHorzBar () {
      if (!this.hasYLabels) {
        this._graph.select('g.grid.x-axis-area').remove()
        return
      }

      let tickValues = []
      if (this.useCustomYLabels) {
        tickValues = this.customYLabels
      } else {
        const step = (this._domainHigh - this._domainLow) / this._xAxisTickCount
        for (let i = 0; i <= this._xAxisTickCount; i++) {
          tickValues.push(this._domainLow + (step * i))
        }
      }

      let xAxis = AxisBottom()
        .scale(this._xAxisScale)
        .tickPadding(config.TICK_PADDING)
        .tickSizeInner(config.TICK_SIZE)
        .tickSizeOuter(1)
        .tickValues(tickValues)

      const abbreviate = this.abbreviate
      const valueType = this.valueType

      const numberFormat = (v) => {
        let rounding = this.rounding
        if (!rounding && rounding !== 0) {
          // Fallback when all values are equal to zero
          if (this._valueMax === this._valueMin && this._valueMax === 0) {
            rounding = 2
            // For domain range no greater than 1
          } else if (Math.abs(this._domainHigh - this._domainLow) <= 1) {
            rounding = 2
            // For domain range no greater than 10
          } else if (Math.abs(this._domainHigh - this._domainLow) <= 20) {
            rounding = 1
          }
        }
        return NumberHelper.formatter(v, {value: v, abbreviate, rounding, value_type: valueType})
      }

      if (!this.useCustomYLabels) {
        if (valueType) {
          xAxis = xAxis.tickFormat(function (v) {
            return typedValue(numberFormat(v), valueType)
          })
        } else {
          xAxis = xAxis.tickFormat(numberFormat)
        }
      }

      let xAxisArea = this._graph.select('g.grid.x-axis-area')
      if (xAxisArea.empty()) {
        xAxisArea = this._graph.append('svg:g').attr('class', 'grid x-axis-area')
      }
      xAxisArea.attr('font-size', null).attr('font-family', null)
      xAxisArea.selectAll('g.bottom-line').remove()

      const requiredHeight = this._chartHeight

      xAxisArea.attr('transform', 'translate(0,' + (requiredHeight - config.GRAPH_PADDING_TOP - this._xAxisHeight) + ')').call(xAxis)

      // To drop transparency on `value_types`
      if (valueType) {
        const prefixSuffix = NumberHelper.valueType(valueType)
        const valuePrefix = prefixSuffix[0] || ''
        const valueSuffix = prefixSuffix[1] || ''

        const xAxisText = xAxisArea.selectAll('text')
        xAxisText.each(function (d, i) {
          let textContent = this.textContent
          if (valuePrefix.length > 0) {
            textContent = textContent.slice(valuePrefix.length)
          }
          if (valueSuffix.length > 0) {
            textContent = textContent.slice(0, textContent.length - valueSuffix.length)
          }
          this.innerHTML = '<tspan>' + valuePrefix + '</tspan>' + textContent + '<tspan>' + valueSuffix + '</tspan>'
        })
      } // EOF drop transparency

      // Vertical Grid Lines for HorzBar
      const gridLineVertArea = this._graph.select('g.grid-lines.vert-lines')
      if (this.gridLines === 'vertical' || this.gridLines === 'both') {
        const rectHeight = this._chartHeight
        const vertGridLines = AxisBottom()
          .scale(this._xAxisScale)
          .tickPadding(config.TICK_PADDING)
          .tickSizeInner(rectHeight)
          .tickSizeOuter(1)
          .tickValues(tickValues)
          .tickFormat(function () { return '' })
        gridLineVertArea.call(vertGridLines)
        // Remove the extra top line
        gridLineVertArea.selectAll('path.domain').remove()
      } else {
        gridLineVertArea.selectAll('*').remove()
      }
    },

    drawHorizontalBar (index, animate) {
      const className = 'renderer-horizontalbar'
      const values = this.itemData[index] || []
      const color = this.seriesColor(index)
      const drawArea = this._drawAreas[index]

      const yScaleBar = (d, i) => {
        return this._yScale(i) + (index * (this.barWidth + this.barMargin))
      }

      const line = drawArea.selectAll('line.' + className).data(values)

      line.enter().append('svg:line')
        .attr('class', className)
        .attr('fill', 'none')
        .merge(line)
        .attr('stroke', color)
        .attr('stroke-width', d => {
          return (d === null ? 0 : this.barWidth)
        })
        .attr('x1', this._xScale(this._domainHigh))
        .transition()
        .attr('x2', d => {
          return this._xScale(d === null ? this._domainHigh : d)
        })
        .attr('y1', yScaleBar)
        .attr('y2', yScaleBar)

      line.exit().remove()

      this.removeOtherRenderers(className, drawArea)
    }
    // EOF HORIZONTAL BAR
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-linechart-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}

  .item-body.app-context-section.primary
    .resize-sensor(ref="sensor")

    .header
      .item-label {{ subTitle }}
    .chart-container
      .chart(ref="chart", :class="{'light-theme': lightTheme}")
    .legend(ref="legend" v-if="hasLegend")
      ul
        template(v-for="(legend, index) in legends")
          li(v-if="legend", :style="{ color: seriesColor(index), fontSize: `${fontSize}em` }")
            span.circle &#x25CF;
            | &nbsp;
            span.text(v-text="legend")

</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.metrics-linechart-item
  .item-body
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch
    position: relative

    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: 0
      bottom: 0
      left: 0
      right: 0

    .header
      display: flex
      margin: 0 0 0.5em 0

      .item-label
        font-size: 0.7em
        text-transform: lowercase
        line-height: 110%

    .chart-container
      flex: 1 1 0.0001px
      display: flex
      height: 100%
      overflow: hidden
      .chart
        height: 100%
        overflow: hidden
        flex: 1 1 0.0001px

    .legend
      ul
        display: flex
        flex-direction: row
        margin: 0 0 0 0
        padding: 0
        list-style: none
        justify-content: flex-end
        li
          margin: 0 0 0 0.5em

    .chart
      width: 100%
      height: 100%
      padding: 0
      margin: 0
      border: none

      g.grid
        line
          stroke: $metricsLabelColor
          stroke-width: 1px

        path.domain
          stroke: $metricsLabelColor
          fill: none

        text
          fill: $metricsLabelColor
          font-size: 0.6em
          /* Axis label value_types */
          tspan
            opacity: 0.4

      text.annotation
        color: $metricsLabelColor
        fill: $metricsLabelColor

      .grid-lines
        line
          stroke: $metricsLabelColor
          opacity: 0.3

      // ==============
      // LIGHT THEME
      // ==============
      &.light-theme
        g.grid
          line
            stroke: $metricsLabelColorDark

          path.domain
            stroke: $metricsLabelColorDark

          text
            fill: $metricsLabelColorDark

        text.annotation
          color: $metricsLabelColorDark
          fill: $metricsLabelColorDark

        .grid-lines
          line
            stroke: $metricsLabelColorDark
</style>
