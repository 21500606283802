<script>
import Moment from 'moment-timezone'
import ActiveWindow from 'services/active-window.js'
import ElectronAPI from 'platform/electronapi.js'

import Override from 'components/player/Override.vue'

import {
  mapState,
  mapGetters
} from 'vuex'

export default {
  name: 'InactiveScreen',

  components: { Override },

  data () {
    return {
      nextStartTime: '',

      oneSecTimer: null
    }
  },

  computed: {
    ...mapState({
      activeOverride: state => state.overrides.activeOverride
    }),
    ...mapGetters([
      'deviceActiveHours',
      'accountLocale',
      'deviceActiveWindows',
      'deviceShowBlankScreen',
      'isOverridingByPlaylist'
    ])
  },

  mounted () {
    this.$store.dispatch('checkForActiveOverride')

    this.checkHours()

    if (ActiveWindow.shouldUseCecPower()) {
      ElectronAPI.sendCecSignal('power_off')
    }

    clearInterval(this.oneSecTimer)
    this.oneSecTimer = setInterval(() => {
      // Check Active Window
      this.checkHours()

      // Check valid overrides
      this.$store.dispatch('checkActiveOverrideStatus')
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.oneSecTimer)
  },

  methods: {
    resumePlayback () {
      if (ActiveWindow.shouldUseCecPower()) {
        ElectronAPI.sendCecSignal('power_on')
      }

      this.$router.push({
        name: 'starting'
      })
    },

    checkHours () {
      // No Active Hours set, navigate to Starting screen
      if (!this.deviceActiveHours && !this.deviceActiveWindows) {
        this.resumePlayback()
        return
      }

      // Is in active hour, navigate to Starting screen
      if (ActiveWindow.inActiveWindow()) {
        this.resumePlayback()
        return
      }

      // When device is overriding by a Playlist, navigate to Starting screen
      if (this.isOverridingByPlaylist) {
        this.resumePlayback()
        return
      }

      this.updateNextStartTime()
    },

    setStartTime (newValue = '') {
      // Skip updating the same value every second
      if (this.nextStartTime !== newValue) {
        this.nextStartTime = newValue
      }
    },

    // timeChunk: [HH, mm, ss]
    // dow: Mon | Tue | ...
    timeDisplay (timeChunk = [], dow = '') {
      // Odd BugSnag error
      // Not sure whether `Moment()` cannot be chained right after declaration in some device.
      // > https://app.bugsnag.com/telemetry/player/errors/607f8bcb2e5dd70007860142
      const startTime = Moment()
      startTime.hour(timeChunk[0] || 0).minute(timeChunk[1] || 0).second(timeChunk[2] || 0)

      // Display Day of week when assigned
      const result = (dow && dow.length) ? `${dow} ` : ''

      if (+timeChunk[2] === 0) {
        if (+timeChunk[1] === 0) {
          // Hour + a/pm
          return result + startTime.format(this.$t('_common.time.format_ha'))
        }
        // h:mm + a
        return result + startTime.format(this.$t('_common.time.format_hmma'))
      }
      // h:mm:ss + a
      return result + startTime.format('LTS')
    },

    updateNextStartTime () {
      if (!this.deviceActiveHours && !this.deviceActiveWindows) {
        this.setStartTime()
        return
      }

      let timeChunk
      let startTimeStr

      // Using "active_hours"
      if (this.deviceActiveHours) {
        if (!this.deviceActiveHours.start) {
          this.setStartTime()
          return
        }
        timeChunk = this.deviceActiveHours.start.split(':')
      // Using "active_window"
      } else if (this.deviceActiveWindows) {
        let now = Moment()
        now = now.tz(this.accountLocale.timezone)
        const dayOfWeekToday = now.format('ddd').toLowerCase()

        const rule = this.deviceActiveWindows[dayOfWeekToday]
        if (!rule || !rule.start) {
          // No active hours found for today.
          // Find the next valid start time in another day of the week
          for (let i = 1; i <= 6; i++) {
            const nextActiveDay = now.clone().add(i, 'days')
            const nextDow = nextActiveDay.format('ddd')
            const nextRule = this.deviceActiveWindows[nextDow.toLowerCase()]
            if (nextRule && nextRule.start) {
              timeChunk = nextRule.start.split(':')
              // Include Day of week in display time
              startTimeStr = this.timeDisplay(timeChunk, nextDow)
              this.setStartTime(startTimeStr)
              return
            }
          }
        } else {
          timeChunk = rule.start.split(':')
        }
      }

      startTimeStr = this.timeDisplay(timeChunk)
      this.setStartTime(startTimeStr)
    }
  }
}
</script>

<template lang="pug">
section#device-inactive
  //- Has active, non-playlist-type override
  override(v-if="activeOverride && !isOverridingByPlaylist", :override="activeOverride")

  //- No overrides
  .message(v-else-if="!deviceShowBlankScreen") {{ $t('inactive.msgText', {startTime: nextStartTime}) }}
</template>

<style lang="stylus">
#device-inactive
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  background: #000
  color: #fff
  z-index: 0

  display: flex
  flex-flow: column nowrap
  justify-content: flex-end
  align-items: center
  text-align: center

  .message
    padding: 5vmin
    font-size: 2vmin
</style>
