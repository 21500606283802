<script>
import qs from 'qs'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import Spinner from 'components/common/Spinner.vue'
import Log from 'services/log.js'
import Env from 'services/environment'
import ttvContentScript from './ttvcontentscript/ttvcontentscript'

// In ms
// When Grafana is loaded for the first time, Player will be redirected to general login page [DEV-3045]
const CONTENT_SCRIPT_INJECTION_DELAY = 3000

// In ms. Give some time for resources loading
const LOGIN_COMMAND_DELAY = 3000

export default {
  name: 'GrafanaItem',

  components: {
    Spinner
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      opts: {},
      loading: true,
      electron: Env.electronAPI,
      chromeApp: Env.chromeAPI,
      android: Env.androidAPI,

      grafanaUrl: '',
      urlError: false,

      debounceTimer: undefined,
      injectScriptTimer: undefined,
      loginTimer: undefined
    }
  },

  computed: {
    zoomStyle () {
      if (!this.opts || !this.opts.zoomScale) { return }
      const zoomScale = +(this.opts.zoomScale || 1.0)
      if (zoomScale !== 1.0) {
        return {
          width: `${100 / zoomScale}%`,
          height: `${100 / zoomScale}%`,
          transform: `scale(${zoomScale})`,
          transformOrigin: '0 0'
        }
      }
    },

    mixedContentError () {
      const iframeSrc = this.grafanaUrl
      return (iframeSrc && (iframeSrc.indexOf('http://') === 0) && !this.electron && !this.android)
    },

    xFrameOptionsError () {
      return (!this.electron && !this.chromeApp && !this.android)
    },

    hasError () {
      return this.urlError || this.mixedContentError || this.xFrameOptionsError
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.injectScriptTimer)
    clearTimeout(this.loginTimer)
    this.render()
    window.addEventListener('message', this.onMessage)
    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.injectScriptTimer)
    clearTimeout(this.loginTimer)
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    window.removeEventListener('message', this.onMessage)
  },

  methods: {
    debounceCheckSize (timeout) {
      // Recommend using setTimeout instead of $nextTick to reduce
      // the "ResizeObserver loop limit exceeded" warning
      // > https://app.bugsnag.com/telemetry/player/errors/5cf198eaaf88d00019c9eb85
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.loading = true
        this.render()
      }, timeout || 200)
    },

    onMessage (event) {
      if (event.data === 'grafana-finished') {
        this.$emit('finished')
      }
    },

    render () {
      if (!this.item.url) { return }
      const content = qs.parse(decodeURIComponent(this.item.url))
      this.opts = {...content}
      this.updateURL()
    },

    updateURL () {
      if (!this.opts || !this.opts.authentication || !this.opts.url) {
        this.grafanaUrl = ''
        return
      }
      let url = this.opts.url
      try {
        const urlObj = new URL(url)
        let searchStr = urlObj.search
        if (searchStr.startsWith('?')) {
          searchStr = searchStr.substr(1)
        }
        let params = qs.parse(searchStr)
        params.refresh = this.opts.refresh
        params.kiosk = 1
        params.autofitpanels = 1
        params.inactive = 1
        url = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}?${qs.stringify(params || {})}`
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          Log.error('app', `Grafana: Invalid Dashboard URL "${url}"`, 'ERR_GRAFANAINVALIDURL', { url: this.opts.url, finalUrl: url }, true)
          this.urlError = true
          this.grafanaUrl = ''
        } else {
          this.urlError = false
          this.grafanaUrl = url
        }
      } catch (err) {
        const errMsg = err.message || err.toString() || ''
        Log.error('app', `Grafana: Dashboard URL error - ${errMsg}`, 'ERR_GRAFANAURLERR', { url: this.opts.url, finalUrl: url, error: err }, true)
        this.urlError = true
        this.grafanaUrl = ''
      }
      this.loading = false
    },

    loadedHandler () {
      this.$emit('loaded')

      // NOTE: For local debugging only [#DEV-3045]
      // if (this.$refs.iframe.contentWindow) {
      //   this.$refs.iframe.openDevTools()
      // }
      // / EOF Debugging

      this.debounceInjectScripts()
    },

    debounceInjectScripts () {
      clearTimeout(this.injectScriptTimer)
      this.injectScriptTimer = setTimeout(() => {
        clearTimeout(this.injectScriptTimer)
        const authentication = this.opts.authentication
        if (authentication === 'username' || authentication === 'api') {
          this.injectContentScript().then(() => {
            this.sendLoginCommand()
          })
        }
      }, CONTENT_SCRIPT_INJECTION_DELAY)
    },

    injectContentScript () {
      if (!this.$refs.iframe || !this.$refs.iframe.contentWindow) {
        return Promise.resolve()
      }
      if (!this.electron) {
        return Promise.resolve()
      }
      const ttvContentScriptSrc = ttvContentScript.toString()
      const ttvContentScriptDecl = `var ttvContentScript = ${ttvContentScriptSrc}`
      // Log.debug('app', 'Grafana: injecting ttvContentScript:', ttvContentScriptDecl)
      const promise = this.$refs.iframe.executeJavaScript(ttvContentScriptDecl + ';ttvContentScript();').then(result => {
        // Log.debug('app', 'Grafana: done injecting ttvContentScript: ' + JSON.stringify(result))
      }).catch(err => {
        console.error('Grafana: error injecting ttvContentScript:', err)
      })
      // Log.debug('app', 'Grafana: injecting ttvContentScript promise:', promise)
      return promise
    },

    sendLoginCommand () {
      // Delay sending LOGIN command. If it is embedding an SPA, it takes time for the DOM to load after iframe is loaded.
      clearTimeout(this.loginTimer)
      this.loginTimer = setTimeout(() => {
        clearTimeout(this.loginTimer)
        if (!this.$refs.iframe || !this.$refs.iframe.contentWindow) {
          return
        }
        let loginName
        let loginPassword
        const authentication = this.opts.authentication
        if (authentication === 'username') {
          loginName = this.opts.username
          loginPassword = this.opts.password
        } else if (authentication === 'api') {
          loginName = 'api_key'
          loginPassword = this.opts.api
        }
        if (loginName && loginPassword) {
          Log.debug('app', 'Grafana: send login command', 'DBG_GRAFANA', null, true)
          this.$refs.iframe.contentWindow.postMessage({
            type: 'LOGIN',
            name: loginName,
            password: loginPassword,
            isGrafana: true
          }, '*')
        }
      }, LOGIN_COMMAND_DELAY)
    }
  }
}
</script>

<template lang="pug">
section.graphana-item
  .resize-sensor(ref="sensor")
  transition(name="fade")
    .loading-mask(v-if="loading")
      spinner(size="8em")

  template(v-if="!loading && !hasError && grafanaUrl.length")
    webview.webpage-frame.is-webview(v-if="electron"
      :src="grafanaUrl"
      :style="zoomStyle"
      @did-stop-loading="loadedHandler"
      disablewebsecurity
      webpreferences="allowRunningInsecureContent=yes,webSecurity=no"
      ref="iframe")
    iframe.webpage-frame(v-else
      ref="iframe"
      :src="grafanaUrl"
      :style="zoomStyle"
      frameBorder="0"
      height="100%"
      width="100%"
      scrolling="no"
      @load="loadedHandler"
    )

  .unavailable(v-if="!loading && hasError")
    p Grafana Site Unavailable

    template(v-if="xFrameOptionsError")
      p
        | This site cannot be displayed in this browser.
        br
        | Please view it in the Electron, ChromeOS or Android player.

    template(v-else-if="mixedContentError")
      p
        | Can not show this site as HTTP embedded is restricted on this platform.
        br
        | Try using HTTPS or the Electron or Android based media player for HTTP content.

    template(v-else-if="urlError")
      p
        | Grafana Dashboard URL Error
        br
        | {{ opts.url }}
</template>

<style lang="stylus">
.graphana-item
  height: 100%
  width: 100%
  position: relative

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .webpage-frame
    position: absolute
    width: 100%
    height: 100%
    border: 0
    &.is-webview
      display: inline-flex

  .loading-mask
    z-index: 0
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 5
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .unavailable
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    border: 0
    font-size: 2em
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background: black
    color: white
    padding: 1em 3em
    p
      text-align: center
</style>
