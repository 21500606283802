<script>
import qs from 'qs'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  mapGetters
} from 'vuex'

import Utils from 'services/utils.js'
import Stocks from 'services/stocks.js'
import Log from 'services/log.js'

const ONE_HOUR = 3600000

const config = {
  // In vmin
  BASE_FONTSIZE: 5,
  BASE_STOCK_LIST_FONTSIZE: 8
}

export default {
  name: 'StocksItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      stocks: [],

      // Actual width/height
      itemSize: {},

      debounceTimer: undefined,
      refreshInterval: undefined
    }
  },

  computed: {
    ...mapGetters([
      'activeToken'
    ]),

    params () {
      return qs.parse(decodeURIComponent(this.item.url || this.item.location))
    },

    symbols () {
      return Stocks.decodeSymbols(this.params.symbols || '')
    },

    baseFontSize () {
      const fontSize = Utils.baseFontSize(this.itemSize)
      if (fontSize) {
        return {
          fontSize: `${fontSize * config.BASE_FONTSIZE}px`
        }
      }
    },

    isPortrait () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.h > this.itemSize.w
      }
      return false
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > 4
      }
      return false
    },

    wideRatio () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h
      }

      return 1
    },

    stockListStyle () {
      if (this.isWide && this.wideRatio > 1) {
        return {fontSize: config.BASE_STOCK_LIST_FONTSIZE / this.wideRatio + 'em'}
      }

      return {}
    }
  },

  watch: {
    symbols () {
      this.getStocks()
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
    this.debounceCheckSize()

    this.getStocks(true)
    clearInterval(this.refreshInterval)
    this.refreshInterval = setInterval(() => {
      this.getStocks()
    }, ONE_HOUR)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearInterval(this.refreshInterval)
  },

  methods: {
    getStocks (init) {
      if (!this.symbols || !this.symbols.length) { return }

      Stocks.get(this.symbols, this.activeToken).then(stocks => {
        this.stocks = stocks || []
        if (init) {
          this.$emit('loaded')
        }
      }).catch(err => {
        const errMsg = err.message || err.toString() || ''
        Log.warn('app', `Stocks app: error fetching stocks - ${errMsg}`, 'WAR_STOCKAPPFETCHERR', { symbols: this.symbols, error: err })
      })
    },

    getChangeInPercentCssClass (changeInPercent) {
      if (!changeInPercent || changeInPercent === 'N/A') { return '' }
      return changeInPercent.startsWith('-') ? 'is-negative' : 'is-positive'
    },

    formatedPercent (changeInPercent) {
      if (!changeInPercent) { return 'N/A' }
      return `${changeInPercent.startsWith('-') ? '' : '+'}${(+changeInPercent).toFixed(3)}`
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      const container = this.$el
      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        const metrics = container.getBoundingClientRect()

        if (!metrics.width || !metrics.height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: metrics.width,
          h: metrics.height
        }
        FastDom.clear(measure)
      })
    },

    bid (stock) {
      return stock.bid || stock.open || 'N/A'
    }
  }
}
</script>

<template lang="pug">
section.stocks-item(:style="baseFontSize", :class="{'is-portrait': isPortrait, 'is-wide': isWide}")
  .resize-sensor(ref="sensor")

  .item-inner-wrapper
    transition-group(tag="div" name="list" class="stocks-list" :style="stockListStyle")
      .stocks-block(v-for="stock in stocks", :key="stock.symbol")
        .stock
          .description
            p.symbol {{ stock.symbol }}
            p.name {{ stock.name }}
          .numbers
            p.bid(v-if="stock.changeInPercent !== 'N/A' || bid(stock) !== 'N/A'") {{ bid(stock) }}
            p.change-in-percent(:class="getChangeInPercentCssClass(stock.changeInPercent)")
              template(v-if="stock.changeInPercent === 'N/A'")
                | N/A
              template(v-else)
                | {{ formatedPercent(stock.changeInPercent) }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

$negativeColor = #a41f13
$positiveColor = #46ae35

section.stocks-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0.5em;
  position: relative;

  > .resize-sensor {
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

  .item-inner-wrapper {
    align-self: stretch;

    .stocks-list {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
    }
  }

  .stocks-block {
    padding: 0;
    width: 50%;
  }

  .stock {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-darker);
    margin: 0.3em;
    padding: 0.6em;

    .description {
      flex: 1 1 0.0001px;
      overflow: hidden;

      .symbol {
        color: white;
        font-size: 1em;
        line-height: 1em;
        margin: 0;
        font-weight: 600;
        ellipsis()
      }

      .name {
        color: var(--gray);
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0.2em;
        font-size: 0.7em;
        ellipsis()
      }
    }

    .numbers {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 0.8em;

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      .bid {
        color: var(--gray);
        font-size: 0.7em;
      }

      .change-in-percent {
        color: white;
        margin-left: 0.5em;

        font-size: 0.8em;
        border-radius: 0.2em;
        padding: 0.2em 0.6em;
        font-weight: 600;

        &.is-positive {
          background-color: $positiveColor;
        }

        &.is-negative {
          background-color: $negativeColor;
        }
      }
    }
  }

  //
  // PORTRAIT LAYOUT
  //
  &.is-portrait {
    .item-inner-wrapper {
      .stocks-list {
        flex-direction: column;
      }

      .stocks-block {
        width: 100%;
      }
    }
  }

  //
  // EXTRA WIDE LAYOUT
  //
  &.is-wide {
    .stocks-block {
      width: 33.3333%;
    }
  }
}
</style>
