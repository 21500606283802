<script>
import Player from '@vimeo/player'
import Spinner from 'components/common/Spinner.vue'

import Moment from 'moment-timezone'
import qs from 'qs'

// For Edge debug
import Bowser from 'bowser'

import Vimeo from 'services/vimeo.js'
import Log from 'services/log.js'
import Utils from 'services/utils.js'
import dlv from 'dlv'
import Env from 'services/environment'

const config = {
  // Paused / Stall / Unstarted
  // - in ms. Interval to retry playing video when paused
  PAUSE_RECHECK_INTERVAL: 5000,
  // - Times of playback failure. When this threshold is reached, prompt the Render to play next page.
  // - 5000 * 6 => 30s
  SKIP_RECHECK_AFTER: 6,

  // Restart
  // - In ms. Try restart after this interval
  // - for preview outside Renderer, or when there's only one valid page in the Playlist
  RESTART_AFTER: 1200,

  // Failsafe (DEV-387)
  // - In second. Force advance to next page after: video duration + this delta value
  FORCE_ADVANCE_DELTA: 30,
  // - In second. Force advance after 12hrs
  MAX_VIDEO_LENGTH: 43200,

  BUFFERING_TIMEOUT: 30000
}

export default {
  name: 'VimeoItem',
  components: {
    Spinner
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { return {} }
    },
    // Options override from other apps like Twitter
    overrideOptions: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      player: undefined,

      urlType: undefined,
      startAt: 0,
      endAt: null,
      playingVideo: false,
      errorMessage: undefined,
      options: undefined,
      mute: false,
      alreadyPlayedVideoIds: [],
      shuffle: false,
      currentPlaylist: [],
      validVideos: [],
      playlistId: '',
      videoId: '',

      loading: true,
      gotPlayingEvent: false,
      isManuallyPaused: false,

      stallTimerCount: 0,

      restartTimer: undefined,
      endOfContentTimer: undefined,

      bufferingTimer: undefined,
      retryTimer: undefined,
      endsAtTimer: undefined,
      forceAdvanceTimer: undefined,
      maxVideoLengthTimer: undefined,
      isVisible: null
    }
  },

  computed: {
    showURL () {
      if (this.errorMessage && this.errorMessage.length) {
        return true
      }
      if (!this.options || !this.options.appType) { return false }
    },
    playerID () {
      return `vimeo_player_${this._uid}`
    },
    forceMute () {
      // When viewing in Safari, Or being embedded (in js_user etc), force mute all videos
      if (this.isSafari) { return true }

      if (Env.previewMode || Env.pageEditMode) { return true }

      return false
    },
    itemUrl () {
      if (!this.item) { return }
      return this.item.url || this.item.location
    },
    // For Edge debug
    browserInfo () {
      const bs = Bowser.getParser(window.navigator.userAgent)
      return bs.getBrowser() || {}
    },
    // For Edge debug
    isWinEdge () {
      return (this.browserInfo.name || '').toLowerCase() === 'edge'
    },
    // Force mute all videos in Safari [DEV-1047]
    isSafari () {
      return (this.browserInfo.name || '').toLowerCase() === 'safari'
    },

    isPlaylist () {
      return ['channel', 'user', 'group'].includes(this.urlType)
    }
  },

  watch: {
    // [DEV-1191]
    // Watch item url instead of item object to prevent the same Vimeo reloads after page switching
    // when it's been placing in the same grid across different pages
    itemUrl (newValue, oldValue) {
      if (oldValue && newValue) {
        this.itemChanged(this.item)
      }
    },

    overrideOptions: {
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.itemChanged(newValue)
        }
      }
    }
  },

  mounted () {
    clearTimeout(this.endOfContentTimer)
    clearTimeout(this.restartTimer)
    this.clearFallbackTimers()
    window.addEventListener('visibilitychange', this.visibilityHandler)
    this.isVisible = document.visibilityState === 'visible'
    this.render()
  },

  beforeDestroy () {
    this.clearFallbackTimers()
    window.removeEventListener('visibilitychange', this.visibilityHandler)
    clearTimeout(this.endOfContentTimer)
    clearTimeout(this.restartTimer)
    if (this.player && typeof this.player.destroy === 'function') {
      this.player.destroy()
    }
    this.player = undefined
  },

  methods: {
    visibilityHandler ($event) {
      if (document.visibilityState === 'visible') {
        this.isVisible = true
      } else {
        this.isVisible = false
      }

      if (this.isVisible && this.player && typeof this.player.play === 'function' && this.player.state === 'pause') {
        Log.debug('app', 'Vimeo: Retry playing on pause', 'DBG_RETRYPLAYING', null, true)
        this.player.play().catch(err => {
          if (err && (err.name === 'PlayInterrupted' || (err.message && err.message.includes('interrupted')))) {
            Log.debug('app', 'Vimeo video playback is paused by browser', 'DBG_VIMEOVIDEOPAUSED', null, true)
            this.onPlayerError({
              name: 'PlayInterrupted',
              message: 'Video playback is paused by browser'
            })
          } else {
            // Catch network permanently fail make player call pause
            this.netWorkErrorHandler('Unable to start', `Unable to start playing video (${this.videoId}).Please check your network, proxy or firewall settings.`)
          }
        })
      }
    },

    itemChanged (newValue) {
      if (newValue && (newValue.url || newValue.location)) {
        // Force clear stored ids when updated option is sent from other apps
        this.playlistId = ''
        this.validVideos = []
        this.mute = this.forceMute || (newValue.mute === 'true' || newValue.mute === true)

        if (this.player && typeof this.player.unload === 'function') {
          this.player.unload()
        }

        this.clearFallbackTimers()

        this.$nextTick(() => {
          this.render(true)
        })
      }
    },

    render (isUpdate) {
      if (!this.overrideOptions && (!this.item || (!this.item.url && !this.item.location))) { return }

      if (this.overrideOptions) {
        this.options = JSON.parse(JSON.stringify(this.overrideOptions))
      } else {
        // Prevent accidentally inputted hashtag (#) breaking the url
        this.options = qs.parse(decodeURIComponent(this.item.url || this.item.location).replace(/#/g, '%23'))
      }

      this.mute = this.options.mute === 'true' || this.options.mute === true
      this.shuffle = this.options.shuffle === 'true' || this.options.shuffle === true
      this.startAt = Vimeo.getStartAtFromURL(this.options.url)
      this.endAt = Vimeo.getEndAtFromURL(this.options.url)
      this.urlType = Vimeo.getTypeFromURL(this.options.url)

      if (this.isPlaylist) {
        this.initPlaylist(isUpdate)
      } else {
        this.initSingleVideo(isUpdate)
      }
    },

    initSingleVideo (isUpdate) {
      this.videoId = Vimeo.getVideoIdFromURL(this.options.url)
      // Call Vimeo API to check if video exist/public
      Vimeo.isIdValid(this.videoId).then(() => {
        this.initPlayer(isUpdate)
      }).catch(err => {
        this.onPlayerError({...err, ...{status: err.response ? err.response.status : 0}})
      })
    },

    initPlayer (isUpdate) {
      if (isUpdate && this.player && this.videoId && typeof this.player.loadVideo === 'function') {
        this.player.loadVideo(this.videoId).catch(err => {
          this.onPlayerError(err)
        })
      } else {
        if (this.player && typeof this.player.destroy === 'function') {
          this.player.destroy().then(() => {
            this.$nextTick(() => {
              this.createPlayer()
            })
          })
        } else {
          this.$nextTick(() => {
            this.createPlayer()
          })
        }
      }
    },

    createPlayer () {
      if (document.getElementById(this.playerID)) {
        this.player = new Player(this.playerID, {
          id: this.videoId,
          height: '100%',
          width: '100%',
          controls: false,
          title: false,
          transparent: false,
          muted: this.mute,
          portrait: false,
          autoplay: true,
          responsive: true
        })

        this.player.ready().then(() => {
          this.onPlayerReady()
        }).catch(err => {
          this.onPlayerError(err)
        })
      }
    },

    onPlayerReady () {
      this.setPlayerState('ready')

      if (document.visibilityState === 'visible') {
        this.isVisible = true
      } else {
        this.isVisible = false
      }

      this.player.on('loaded', this.onPlayerLoaded)
      this.player.on('bufferstart', this.onPlayerBuffer)
      this.player.on('bufferend', this.onPlayerBufferEnd)
      this.player.on('play', this.onPlayerPlay)
      this.player.on('error', this.onPlayerError)
      this.player.on('ended', this.onPlayerEnded)
      this.player.on('pause', this.onPlayerPause)
      this.player.on('cuepoint', this.onPlayerCuePoint)
    },

    async onPlayerError (event = {}) {
      const errorName = dlv(event, 'name', '')
      const errorMessage = dlv(event, 'message', '')
      Log.debug('app', `Vimeo Error (${errorName}) "${errorMessage}"`, 'DBG_VIMEOERR', { error: event }, true)

      this.playingVideo = false
      const displayVideoID = (this.videoId ? ` (${this.videoId})` : '')

      if (event.status === 404) {
        this.errorMessage = `Vimeo video ID not found${displayVideoID}`
      } else if (event.status === 429) {
        this.errorMessage = `Exceed API quota. Please wait 60s and try again${displayVideoID}`
      } else if (event.status === 403) {
        this.errorMessage = `Do not have permission to access this video${displayVideoID}`
      } else if (event.status === 0) { // Network error can't call API to check
        this.errorMessage = 'Unable to connect to Vimeo. Please check your network, proxy or firewall settings.'
      } else if (event.name === 'PlayInterrupted' || (event.message && event.message.includes('interrupted'))) {
        this.errorMessage = 'Vimeo video playback is paused by browser'
        Log.debug('app', this.errorMessage, 'DBG_VIMEOVIDEOPAUSED2', null, true)
      } else if (event.name === 'NotFoundError' || (event.message && event.message.includes('does not exist'))) {
        this.errorMessage = `Vimeo video does not exist${displayVideoID}`
        Log.warn('app', this.errorMessage, 'WAR_VIMEONOTEXIST', null, true)
      } else {
        this.errorMessage = `Vimeo Error - ${errorName}: "${errorMessage}"${displayVideoID}`
        Log.warn('app', this.errorMessage, 'WAR_VIMEOERR')
      }

      if (this.videoId) {
        // Added the issued videoId to played list in order to advance
        await Vimeo.addPlayedVideo(this.playlistId, this.videoId)
        this.updateValidList(this.videoId)
      }

      this.clearFallbackTimers()

      clearTimeout(this.endOfContentTimer)
      this.endOfContentTimer = setTimeout(() => {
        clearTimeout(this.endOfContentTimer)
        this.endOfContent()

        // Prevent re-render single video with certain known errors
        if (
          this.urlType === 'video' ||
          [0, 404, 429, 403].includes(event.status) ||
          (
            this.errorMessage &&
            (
              this.errorMessage.indexOf('Unable to connect') >= 0 ||
              this.errorMessage.indexOf('playback is paused') >= 0 ||
              this.errorMessage.indexOf('error occurred loading') >= 0 ||
              this.errorMessage.indexOf('video does not exist') >= 0
            )
          )
        ) {
          // Also send error messge to device log
          if (this.errorMessage) {
            Log.warn('app', this.errorMessage, 'WAR_VIMEOERR2')
          }
          return
        }

        // Prevent sending duplicate error requests for invalid id or network errors
        if (event.customError &&
            (event.message || '').indexOf('Unable to start') === -1 &&
            (event.message || '').indexOf('buffering timeout') === -1
        ) {
          // `No videos found...` etc errors will be captured here
          return
        }
        // For `Unable to start playing video ...` and `Buffering Timeout` errors, which might due to the temporal network failure
        // Try playing the next video in the row
        this.render(true)
      }, 4000)
    },

    async onPlayerLoaded (event) {
      Log.debug('app', `Vimeo: Load Video: ${event.id}`, 'DBG_VIMEOLOADVIDEO')
      this.setPlayerState('load')
      this.recheckBuffering(config.BUFFERING_TIMEOUT)
    },

    async onPlayerPlay (event) {
      try {
        Log.debug('app', `Vimeo: Play Video: ${this.videoId}`, 'DBG_VIMEOPLAYVIDEO')
        await this.checkPlayerStartAt()
        await this.checkPlayerVolume()
        await this.checkPlayerStartAt()
        await this.checkPlayerCaption()

        clearTimeout(this.restartTimer)
        clearTimeout(this.retryTimer)
        clearTimeout(this.bufferingTimer)
        this.stallTimerCount = 0
        this.setPlayerState('play')

        this.loaded()
        this.playingVideo = true
        this.errorMessage = undefined

        const duration = event.duration

        if (duration > 0 && !this.gotPlayingEvent) {
          const isLive = await Vimeo.isLive(this.videoId)
          if (isLive) {
            Log.debug('app', 'Vimeo: This Vimeo video is live', 'DBG_VIMEOVIDEOLIVE')
            this.$emit('duration', -1)
          } else {
            Log.debug('app', `Vimeo: This Vimeo video is ${duration} seconds long`, 'DBG_VIMEOVIDEODURATION')
            this.$emit('duration', duration)
          }

          // Force advance after duration + delta
          clearTimeout(this.forceAdvanceTimer)
          this.forceAdvanceTimer = setTimeout(() => {
            clearTimeout(this.forceAdvanceTimer)
            this.forceAdvance()
          }, (config.FORCE_ADVANCE_DELTA + duration) * 1000)

          // Force advance after max Vimeo video length
          clearTimeout(this.maxVideoLengthTimer)
          this.maxVideoLengthTimer = setTimeout(() => {
            clearTimeout(this.maxVideoLengthTimer)
            this.forceAdvance()
          }, config.MAX_VIDEO_LENGTH * 1000)
        }

        this.gotPlayingEvent = true
      } catch (error) {
        this.onPlayerError(error)
      }
    },

    onPlayerEnded () {
      Log.debug('app', `Vimeo: Ended playing video: ${this.videoId}`, 'DBG_VIMEOVIDEOENDED')
      this.playingVideo = false
      this.setPlayerState('end')

      if (this.urlType === 'video' || this.options.playOnlyOneVideo + '' === 'true') {
        Log.debug('app', 'Vimeo: Play only one video is on', 'DBG_PLAYONLYONEVIDEO', null, true)
        this.endOfContent()
      } else if (this.isPlaylist && !this.currentPlaylist.length && this.options.playOnlyOneVideo + '' !== 'true') {
        Log.debug('app', `Vimeo: Reach the end of playlist on ${this.urlType}`, 'DBG_ENDOFPLAYLIST')
        this.endOfContent()
      }

      this.handleVideoEnded()
    },

    onPlayerPause (event) {
      Log.debug('app', `Vimeo: Paused video ${this.videoId} (visible: ${this.isVisible})`, 'DBG_VIMEOVIDEOPAUSED3')

      if (!this.isVisible) {
        // Log.info('app', 'Video playback is paused by browser')
        return
      }

      this.setPlayerState('pause')

      if (this.isManuallyPaused) {
        return
      }

      // Possible fix for Edge - Edge might refuse to play the video when the iframe is not visible
      // https://app.bugsnag.com/telemetry/player/errors/5c620611b635360019ad4cc6
      if (this.isWinEdge) {
        // For debug
        Log.debug('app', 'Vimeo: Paused Browser Info', 'DBG_EDGEPAUSEDVIDEO', JSON.stringify(this.browserInfo))

        this.playingVideo = true
        this.loaded()
        Log.debug('app', 'Vimoe: Debug For Edge:', 'DBG_VIMEOEDGE', JSON.stringify(event))
      }

      // clear buffering check, do pause check
      clearTimeout(this.bufferingTimer)
      this.recheckPause()
    },

    onPlayerCuePoint () {
      Log.debug('app', 'Vimeo: Cued video', 'DBG_VIMEOCUE')
      this.gotPlayingEvent = false
      this.loading = true
      this.playingVideo = false
      this.recheckPause()
    },

    onPlayerBuffer (event) {
      // Log.debug('app', 'Vimeo: Buffer start', event)
      this.setPlayerState('buffering')
      this.recheckBuffering(config.BUFFERING_TIMEOUT)
    },

    onPlayerBufferEnd (event) {
      // Log.debug('app', 'Vimeo: Buffer end', event)
      clearTimeout(this.bufferingTimer)
    },

    async initPlaylist (isUpdate) {
      Log.info('app', `Vimeo: Init playlist ${this.urlType}`, 'INF_VIDEOINIT')

      // Channel ID already set up
      if (isUpdate && this.playlistId && this.playlistId.length) {
        Log.debug('app', `Vimeo: Continue playing playlist of ${this.urlType} ${this.playlistId}`, 'DBG_CONTINUEPLAYLIST')
        if (this.validVideos && this.validVideos.length) {
          this.setPlaylistAndVideoId([].concat([], this.validVideos), isUpdate)
          return
        }

        const promise = Vimeo.getVideosFromPlaylist(this.playlistId, this.urlType)

        promise.then(async (data) => {
          const items = this.filterPlaylistVideosByTime(data.data)
          if (!items || !items.length) {
            this.noPlaylistResultsHandler()
            return
          }
          const videosIds = await this.getVideosIdsFromVimeoResponse(items)
          this.setPlaylistAndVideoId(videosIds, isUpdate)
        }).catch(err => {
          this.onPlayerError({...err, ...{status: err.response ? err.response.status : 0}})
        })

      // Init
      } else {
        Vimeo.getPlaylistIdFromURL(this.options.url, this.urlType)
          .then(res => {
            // Invalid playlistId, or there's NO public playlist/videos in the requested playlist
            if (!res || res.noItems || !res.playlistId) {
              return Promise.resolve(res)
            }
            Log.info('app', `Vimeo: Play videos in ${this.urlType} - PlaylistId: ${res.playlistId}`, 'INF_VIMEOPLAY')

            this.playlistId = res.playlistId

            return Vimeo.getVideosFromPlaylist(res.playlistId, this.urlType)
          })
          .then(async (data) => {
            // Invalid playlistId, or there's NO public videos in the requested playlist
            if (!data || data.noItems || !data.total || !data.data.length) {
              this.noPlaylistResultsHandler()
              return
            }

            const items = this.filterPlaylistVideosByTime(data.data)
            if (!items || !items.length) {
              this.noPlaylistResultsHandler()
              return
            }
            const videosIds = await this.getVideosIdsFromVimeoResponse(items)
            this.setPlaylistAndVideoId(videosIds, isUpdate)
          }).catch(err => {
            this.onPlayerError({...err, ...{status: err.response ? err.response.status : 0}})
          })
      }
    },

    async checkPlayerVolume () {
      if (!this.player || typeof this.player.getVolume !== 'function' || typeof this.player.setVolume !== 'function') { return }

      const volume = await this.player.getVolume()

      if ((this.mute || this.forceMute) && volume !== 0) {
        this.player.setVolume(0)
        Log.debug('app', `Vimeo: Mute video ${this.videoId}`, 'DBG_VIMEOMUTEVIDEO', null, true)
      } else {
        this.player.setVolume(1)
      }
    },

    async checkPlayerCaption () {
      if (!this.player || typeof this.player.getTextTracks !== 'function' || typeof this.player.enableTextTrack !== 'function' || typeof this.player.disableTextTrack !== 'function') { return }

      if (this.options.showCaptions === 'true') {
        const textTracks = await this.player.getTextTracks()
        if (textTracks && textTracks.length) {
          this.player.enableTextTrack(textTracks[0].language)
        } else {
          Log.debug('app', `Vimeo: Video ${this.videoId} not support caption`, 'DBG_VIDEONOTSUPPORTED')
        }
      } else {
        this.player.disableTextTrack()
      }
    },

    async checkPlayerStartAt () {
      if (!this.startAt || !this.player || this.startAt < 0 || typeof this.player.getDuration !== 'function' || typeof this.player.setCurrentTime !== 'function') { return }

      if (this.endAt && this.startAt >= this.endAt) {
        Log.debug('app', 'Vimeo: Start at time cannot greater/equal end at time', 'DBG_WRONGSTARTTIME')
        return
      }

      const duration = await this.player.getDuration()

      if (this.startAt >= duration) {
        Log.debug('app', 'Vimeo: Start at time cannot greater/equal video duration', 'DBG_WRONGSTARTTIME2')
        return
      }

      this.player.setCurrentTime(this.startAt)
      Log.debug('app', `Vimeo: Video start at ${this.startAt}`, 'DBG_VIDEOSTARTTIME')
    },

    async checkPlayerEndAt () {
      if (!this.endAt || !this.player || this.endAt < 0 || typeof this.player.getDuration !== 'function') { return }

      if (this.startAt && this.endAt <= this.startAt) {
        Log.debug('app', 'Vimeo: Start at time cannot greater/equal end at time', 'DBG_WRONGSTARTTIME3')
        return
      }

      const duration = await this.player.getDuration()

      if (this.endAt >= duration) {
        Log.debug('app', 'Vimeo: End at time cannot greater/equal video duration', 'DBG_WRONGENDTIME')
        return
      }

      clearInterval(this.endsAtTimer)
      Log.debug('app', `Vimeo: Video ends at ${this.endAt}`, 'DBG_VIDEOENDTIME')
      this.endsAtTimer = setInterval(() => {
        this.recheckEndTime()
      }, 1000)
    },

    endOfContent () {
      // Log.debug('app', 'Vimeo: End of content')
      this.$emit('finished')
    },

    async getVideosIdsFromVimeoResponse (videos) {
      let videosIds = []
      let filteredVideos = [].concat([], videos)

      filteredVideos.forEach(video => {
        const videoId = video.uri.split('/videos/')[1]

        if (videoId) {
          videosIds.push(videoId)
        }
      })

      const playedVideos = await Vimeo.getPlayedVideos(this.playlistId)
      if (playedVideos.length) {
        videosIds = videosIds.filter(v => !playedVideos.includes(v))
      }

      if (!videosIds.length) {
        await Vimeo.removePlayedVideos(this.playlistId)
        const result = this.getVideosIdsFromVimeoResponse(videos)
        return result
      }

      this.validVideos = [].concat([], videosIds)

      return videosIds
    },

    setPlaylistAndVideoId (videosIds, isUpdate, startVideoId) {
      // Log.debug('app', 'Vimeo wait list:', videosIds)

      if (videosIds.length > 0) {
        if (this.shuffle) {
          videosIds = Utils.shuffle(videosIds)
        }
        this.videoId = startVideoId || videosIds.shift()
        this.currentPlaylist = videosIds
        this.initPlayer(isUpdate)
        return
      }

      Log.info('app', 'Vimeo: No videos to show', 'INF_VIMEONOVIDEO')
      this.loaded()
      this.playingVideo = false

      this.endOfContent()
    },

    recheckBuffering (timeout) {
      Log.debug('app', 'Vimeo: Recheck bufferring', 'DBG_RECHECKBUFFER', null, true)
      clearTimeout(this.bufferingTimer)
      this.bufferingTimer = setTimeout(() => {
        clearTimeout(this.bufferingTimer)
        if (this.player && this.player.state === 'buffering') {
          this.netWorkErrorHandler('Buffering Timeout', `Video buffering timeout (${this.videoId}). Please check your network, proxy or firewall settings.`)
        } else {
          this.netWorkErrorHandler()
        }
      }, timeout)
    },

    recheckPause () {
      Log.debug('app', 'Vimeo: Recheck pause', 'DBG_RECHECKPAUSE', null, true)
      // NOTE: when `!this.videoId` is undefined, means the input videoId is invalid
      if (this.stallTimerCount > config.SKIP_RECHECK_AFTER || !this.videoId) {
        clearTimeout(this.retryTimer)
        // Chrome v65+ fallback message
        if (this.player) {
          this.netWorkErrorHandler('Unable to start', `Unable to start playing video (${this.videoId}).Please check your network, proxy or firewall settings.`)
        } else {
          this.netWorkErrorHandler()
        }
        return
      }

      clearTimeout(this.retryTimer)
      this.retryTimer = setTimeout(() => {
        clearTimeout(this.retryTimer)
        this.stallTimerCount++
        if (this.player && typeof this.player.play === 'function') {
          Log.debug('app', 'Vimeo: Retry playing on pause', 'DBG_RETRYPLAY')
          this.player.play().catch(err => {
            if (err && (err.name === 'PlayInterrupted' || (err.message && err.message.includes('interrupted')))) {
              Log.debug('app', 'Video playback is paused by browser', 'DBG_VIMEOVIDEOPAUSED4')
              this.onPlayerError({
                name: 'PlayInterrupted',
                message: 'Video playback is paused by browser'
              })
            } else {
            // Catch network permanently fail make player call pause
              this.netWorkErrorHandler('Unable to start', `Unable to start playing video (${this.videoId}).Please check your network, proxy or firewall settings.`)
            }
          })
          this.recheckPause()
        }
      }, config.PAUSE_RECHECK_INTERVAL)
    },

    // Either natual ended (onPlayerEnded) or force ended (by timer)
    async handleVideoEnded () {
      this.clearFallbackTimers()

      const videoId = this.videoId || ''
      await Vimeo.addPlayedVideo(this.playlistId, videoId)
      this.updateValidList(videoId)

      // Fallback for preview outside Renderer (e.g. js_user)
      clearTimeout(this.restartTimer)
      this.restartTimer = setTimeout(() => {
        clearTimeout(this.restartTimer)
        if (this.urlType === 'video') {
          this.initSingleVideo(true)
        } else if (this.isPlaylist && !this.overrideOptions) {
          this.initPlaylist(true)
        }
      }, config.RESTART_AFTER)
    },

    async recheckEndTime () {
      if (!(this.endAt && this.endAt > 0) || !this.player) {
        clearInterval(this.endsAtTimer)
        return
      }

      try {
        const playedTime = await this.player.getCurrentTime()
        if (playedTime >= this.endAt) {
          clearInterval(this.endsAtTimer)
          Log.debug('app', `Vimeo: End Video at ${this.endAt}s`, 'DBG_VIDEOENDTIME2')
          this.playingVideo = false
          this.endOfContent()
          this.handleVideoEnded()
        }
      } catch (error) {
        this.onPlayerError(error)
      }
    },

    forceAdvance () {
      Log.info('app', 'Vimeo: Force advance to next page', 'INF_VIMEOFORCEADVANCE')
      this.playingVideo = false
      this.endOfContent()
      this.handleVideoEnded()
    },

    noPlaylistResultsHandler () {
      if (this.options.noOlderThan !== 'any') {
        this.netWorkErrorHandler('No Videos Found with Filter', `No videos found with filter "No Older Than ${this.options.noOlderThan} ` + (+this.options.noOlderThan === 1 ? 'Day"' : 'Days"'))
      } else {
        this.netWorkErrorHandler('No Videos Found', 'No videos found on this channel')
      }
    },

    filterPlaylistVideosByTime (items) {
      if (!items) { return [] }
      if (this.options.noOlderThan === 'any') { return items }

      // Manully Filter videos within the timeframe
      const noOlderThan = +this.options.noOlderThan
      const filteredVideos = items.filter(v => {
        return Moment(v.release_time).valueOf() >= Moment().subtract(noOlderThan, 'days').valueOf()
      })

      return filteredVideos || []
    },

    updateValidList (playedVideoId) {
      if (this.validVideos && this.validVideos.length && playedVideoId) {
        const vIndex = this.validVideos.findIndex(vid => vid === playedVideoId)
        if (vIndex >= 0) {
          this.validVideos.splice(vIndex, 1)
        }
      }
    },

    manuallyPlay () {
      if (this.player) {
        this.player.play().then(() => {
          this.isManuallyPaused = false
        }).catch(() => {})
      }
    },

    manuallyPause () {
      if (this.player) {
        this.player.pause().then(() => {
          clearTimeout(this.forceAdvanceTimer)
          this.isManuallyPaused = true
        }).catch(() => {})
      }
    },

    manualAction () {
      if (!this.player) { return }
      this.player.getPaused().then(isPaused => {
        if (isPaused) {
          this.manuallyPlay()
        } else {
          this.manuallyPause()
        }
      }).catch(() => {})
    },

    netWorkErrorHandler (err, msg) {
      this.onPlayerError({
        name: err || 'Network Error',
        message: msg || 'Unable to connect to Vimeo. Please check your network, proxy or firewall settings.',
        customError: true
      })
    },

    // Batch clear fallback timers
    // - Paused / Stalling / Cued / Player init / etc
    clearFallbackTimers () {
      clearTimeout(this.bufferingTimer)
      clearTimeout(this.retryTimer)
      clearTimeout(this.forceAdvanceTimer)
      clearTimeout(this.maxVideoLengthTimer)
      clearInterval(this.endsAtTimer)
      this.stallTimerCount = 0
    },

    setPlayerState (state) {
      if (this.player && state) {
        this.player.state = state
      }
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.vimeo-item
  //- Overlay Mask to prevent embedded vimeo player being clicked (ads, etc)
  .overlay-mask(@click.stop="manualAction")
    transition(name="fade", :duration="{enter: 500, leave: 0}" appear)
      .play-button(v-if="isManuallyPaused")
        fa.fa-icon(icon="play-circle" @click.stop="manuallyPlay")

  //- Spinner
  .loading-mask(v-if="loading && !errorMessage")
    spinner(size="8em")

  //- Main Player
  .vimeo-video(:class="{show: !loading && playingVideo}")
    div(:id="playerID")

  .messages-wrapper(v-show="errorMessage && !playingVideo")
    h1 {{ errorMessage }}
    p(v-if="showURL") {{ decodeURIComponent(options.url) }}
</template>

<style lang="stylus">
.vimeo-item
  color: white
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0

  .overlay-mask
    position: absolute
    z-index: 1000
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent

    .play-button
      color: #fff
      font-size: 8em
      position: absolute
      z-index: 5
      top: calc(50% - 0.5em)
      left: calc(50% - 0.5em)
      cursor: pointer
      transition: opacity 0.3s

      appIconShadow()

      &:hover,
      &:focus
        opacity: 0.7

      &:active
        opacity: 0.5

  .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    justify-content: center
    align-items: center

  .vimeo-video
    width: 100%
    height: 100%
    position: relative
    z-index: 5
    opacity: 0

    div
      width: 100%
      height: 100%

    &.show
      opacity: 1

  .messages-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 3vmin
    padding: 1em

    h1
      font-size: 4vmin
    p
      opacity: 0.7

</style>
