<script>
import qs from 'qs'
import VideoJS from 'video.js'
import { Parser } from 'm3u8-parser'

import 'videojs-playlist'
import 'videojs-errors'
import 'videojs-auto-caption'

import 'video.js/dist/video-js.css'
import 'videojs-errors/dist/videojs-errors.css'

import Log from 'services/log.js'
import Env from 'services/environment'

// In seconds
const M3U8_CACHE_MAX_AGE = 600 // ~ 10 minute

// In ms
const FORCE_ADVANCE_DELAY = 5000

const URL_FORMAT_REGEX = /^(udp|rdp|mmsh|rtsp|rtp)(:\/\/)/mi

// VideoJS Options
// > https://docs.videojs.com/tutorial-options.html
const VIDEO_OPTIONS = {
  autoplay: true,
  controls: false,
  muted: true,
  fluid: true
}

export default {
  name: 'WebVideoPage',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { return {} }
    }
  },

  data () {
    return {
      url: '',
      mute: false,
      tapInteraction: false,

      isManuallyPaused: false,

      player: null,
      videoOptions: Object.assign({}, VIDEO_OPTIONS),

      isPlaylist: false,
      playlist: [],
      urlBase: {},

      replayTimer: null,
      nextTimer: null,
      forceAdvanceTimer: null
    }
  },

  computed: {
    forceMute () {
      // When viewing in Safari, Or being embedded (in js_user etc), force mute all videos
      return this.isSafari ||
        (this.$route && this.$route.query && (
          (this.$route.query.forceMute === 'true' || this.$route.query.forceMute === true) ||
          (this.$route.query.mute_videos === 'true' || this.$route.query.mute_videos === true)
        ))
    },
    // Force mute all videos in Safari [DEV-1047]
    isSafari () {
      return (Env.browserName || '').toLowerCase() === 'safari'
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.replayTimer)
    clearTimeout(this.nextTimer)
    clearTimeout(this.forceAdvanceTimer)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.replayTimer)
    clearTimeout(this.nextTimer)
    clearTimeout(this.forceAdvanceTimer)
    if (this.player) {
      this.player.dispose()
      this.player = null
    }
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.mute = this.forceMute || (options.mute === 'true' || options.mute === true)
      this.$set(this.videoOptions, 'muted', this.mute)

      this.tapInteraction = (options.tapInteraction === 'true' || options.tapInteraction === true)

      const url = options.url || ''
      this.url = url

      if (this.isM3U8Link(url)) {
        this.$set(this.videoOptions, 'sources', null)
        this.parseM3U8(url)
        return
      }

      this.$set(this.videoOptions, 'sources', [{ src: this.url }])

      this.startPlayer()
    },

    onPlayerReady () {
      this.$emit('loaded')

      if (!this.player) { return }

      if (this.isPlaylist) {
        if (this.player.playlist && typeof this.player.playlist === 'function') {
          const playlist = JSON.parse(JSON.stringify(this.playlist))
          // Start playing the first item
          this.player.playlist(playlist, 0)
        }
      }

      this.player.autoCaption()

      this.player.on('durationchange', () => {
        this.sendDuration()
      })

      this.player.on('ended', () => {
        this.$emit('finished')
        if (this.isPlaylist) {
          this.playNext()
        } else {
          this.replay()
        }
      })
    },

    startPlayer () {
      clearTimeout(this.forceAdvanceTimer)
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
      if (this.$refs && this.$refs.videoJsPlayer) {
        this.player = VideoJS(this.$refs.videoJsPlayer, this.videoOptions, this.onPlayerReady)

        let errorMessage = ''
        if (!(this.url).startsWith('https://')) {
          errorMessage = this.$t('pageItems.webVideo.invalidFormatHttps')
        }
        if (URL_FORMAT_REGEX.test(this.url)) {
          errorMessage += ' '
          errorMessage += this.$t('pageItems.webVideo.directMessage', {format: this.getVideoFormat(this.url)})
        }

        if (!this.player) { return }

        if (this.player.errors && typeof this.player.errors === 'function') {
          this.player.errors()
          this.player.errors.extend({
            4: {
              message: errorMessage
            },
            M3U8_PARSE_ERROR: {
              headline: 'M3U8 Parse Error'
            }
          })
        }

        this.player.on('error', () => {
          if (!this.player || !this.player.error || !this.player.errors) { return }
          const errorCode = this.player.error() && this.player.error().code
          const latestError = this.player.errors.getAll()[errorCode]
          this.errorHandler(latestError)
        })
      }
    },

    getVideoFormat (value) {
      if (value) {
        return value.match(URL_FORMAT_REGEX)[1].toUpperCase()
      }
      return ''
    },

    replay () {
      // NOTE: We manually replay the video here instead of using `loop: true`
      // Beacause the `loop` handler will mute the "ended" event.
      clearTimeout(this.replayTimer)
      this.replayTimer = setTimeout(() => {
        clearTimeout(this.replayTimer)
        if (this.player && this.player.play && typeof this.player.play === 'function') {
          this.player.play()
        }
      }, 500)
    },

    playNext () {
      clearTimeout(this.nextTimer)
      this.nextTimer = setTimeout(() => {
        clearTimeout(this.nextTimer)
        if (this.player && this.player.next && typeof this.player.next === 'function') {
          this.player.next()
        }
      }, 500)
    },

    isM3U8Link (url) {
      return (url || '').endsWith('.m3u8')
    },

    setPlaylistUrlBase (url) {
      let base
      try {
        const location = new URL(url)

        base = {
          origin: location.origin
        }

        if (location.pathname && location.pathname.endsWith('.m3u8')) {
          const lastIndex = location.pathname.lastIndexOf('/')
          let trimedPath = ''
          if (lastIndex > 0) {
            trimedPath = location.pathname.substr(0, lastIndex)
          }
          base.path = `${location.origin}${trimedPath}`
        } else {
          base.path = `${location.origin}${location.pathname}`
        }
      } catch (e) {
        // Do nothing
      }
      this.urlBase = base || {}
    },

    parseM3U8 (url) {
      if (!url) { return }
      this.$store.dispatch('getContentForURL', {
        url,
        // LRUCache accepts `maxAge` in ms.
        maxAge: M3U8_CACHE_MAX_AGE * 1000
      }).then(res => {
        const parser = new Parser()
        parser.push(res)
        parser.end()

        let playlist
        if (parser.manifest && parser.manifest.playlists) {
          playlist = parser.manifest.playlists
        }

        // Is m3u8 Playlist form
        if (playlist) {
          this.setPlaylistUrlBase(url)
          this.renderPlaylist(playlist)
        // Is Playable m3u8 source
        } else {
          this.isPlaylist = false
          this.baseUrl = {}
          this.playlist = []

          this.url = url
          this.$set(this.videoOptions, 'sources', [{ src: this.url }])
          this.startPlayer()
        }
      }).catch(err => {
        const errMsg = err.message || err.toString()
        Log.warn('app', `Web Video: m3u8 parse error - ${errMsg} (${this.url})`, 'WAR_M3U8PARSEERR', { url: this.url })

        if (!this.player) {
          // Run empty player for error message
          this.startPlayer()
        }

        this.$nextTick(() => {
          if (!this.player || !this.player.errors || !this.player.error) { return }

          // Extend detailed messages
          this.player.errors.extend({
            M3U8_PARSE_ERROR: {
              message: `${errMsg} (${this.url})`
            }
          })

          // Trigger error message
          this.player.error({
            code: 'M3U8_PARSE_ERROR'
          })
        })
      })
    },

    // Fixes for itme.uri in relative path form (not starts with 'http|https')
    fillItemURI (itemUri) {
      if (itemUri && itemUri.startsWith('http')) { return itemUri }

      if (this.urlBase && this.urlBase.origin) {
        if (itemUri.startsWith('/')) {
          itemUri = `${this.urlBase.origin}${itemUri}`
        } else {
          itemUri = `${this.urlBase.path}/${itemUri}`
        }
        return itemUri
      }
    },

    renderPlaylist (playlist) {
      if (!playlist || !playlist.length) {
        this.isPlaylist = false
        this.playlist = []
        this.baseUrl = {}
        return
      }

      const list = []
      playlist.forEach(item => {
        if (item && item.uri) {
          let itemUrl = item.uri
          if (!itemUrl.startsWith('http')) {
            itemUrl = this.fillItemURI(itemUrl)
            if (!itemUrl) { return }
          }
          list.push({
            sources: [{ src: itemUrl }]
          })
        }
      })

      this.isPlaylist = true

      if (!list.length) {
        this.playlist = []
        Log.warn('app', `Web Video: no valid items found in this m3u8 playlist (${this.url})`, 'WAR_NOVALIDITEMM3U8', { url: this.url })
        return
      }

      this.playlist = list
      this.startPlayer()
    },

    sendDuration () {
      if (!this.player || !this.player.duration) { return }
      const duration = +this.player.duration()
      if (isFinite(duration) && !isNaN(duration) && duration > 0) {
        Log.debug('app', `Web Video: Playing video for ${duration} seconds`, 'DBG_WEBVIDEODURATION', { url: this.url })
        this.$emit('duration', duration)
      }
    },

    errorHandler (latestError) {
      if (!this.url || !this.url.length) { return }
      Log.debug('app', `Web Video Error Type: ${latestError.type}; Error Message: ${latestError.message || latestError.toString()} (${this.url})`, 'DBG_WEBVIDEOERR', {url: this.url, error: latestError})
      Log.warn('app', `Web Video Error - ${latestError.message || latestError.toString()} (${this.url})`, 'WAR_WEBVIDEOERR', { url: this.url })
      clearTimeout(this.forceAdvanceTimer)
      this.forceAdvanceTimer = setTimeout(() => {
        clearTimeout(this.forceAdvanceTimer)
        this.$emit('finished', true)
      }, FORCE_ADVANCE_DELAY)
    },

    manuallyPlay () {
      if (this.player) {
        this.isManuallyPaused = false
        this.player.play()
      }
    },

    manuallyPause () {
      if (this.player) {
        this.isManuallyPaused = true
        this.player.pause()
      }
    },

    manualAction () {
      if (!this.player) { return }
      if (this.player.paused()) {
        this.manuallyPlay()
      } else {
        this.manuallyPause()
      }
    }
  }
}
</script>

<template lang="pug">
section.web-video-page
  .overlay-mask(@click.stop="manualAction")
    transition(name="fade", :duration="{enter: 500, leave: 0}" appear)
      .play-button(v-if="isManuallyPaused")
        fa.fa-icon(icon="play-circle" @click.stop="manuallyPlay")

  video.video-js(ref="videoJsPlayer")
</template>

<style lang="stylus">
section.web-video-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center

  .video-js
    background-color: transparent

  .overlay-mask
    position: absolute
    z-index: 1000
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent

    .play-button
      color: #fff
      font-size: 8em
      position: absolute
      z-index: 5
      top: calc(50% - 0.5em)
      left: calc(50% - 0.5em)
      cursor: pointer
      transition: opacity 0.3s

      appIconShadow()

      &:hover,
      &:focus
        opacity: 0.7

      &:active
        opacity: 0.5

  .vjs-errors-dialog
    top: 10%
    bottom: 30%
    background: #111
    .vjs-errors-headline
      padding-top: 1em
    .vjs-errors-ok-button-container,
    .vjs-close-button
      display: none
</style>
