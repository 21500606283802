<script>
import Grids from 'services/grids.js'

export default {
  name: 'PageThumbnail',
  props: {
    page: {
      type: Object,
      required: true
    }
  },

  computed: {
    isAdPlacement () {
      return this.page.type === 'advertising'
    },

    isFreeFormPage () {
      return this.page?.layout_scheme === 'freeform'
    },

    pageLayout () {
      return Grids.parseTplString(this.page.grid_template)
    },

    gridTemplate () {
      return this.pageLayout.template || 'none'
    },

    items () {
      if (this.page.items) {
        // Zoned Page Support
        return this.page.items || []
      } else if (this.isAdPlacement) {
        return []
      } else {
        // Legacy Page Support
        return [this.page]
      }
    }
  },

  methods: {
    thumb (item) {
      if (!item || !item.thumb_url || this.isAdPlacement) { return }
      return {
        backgroundImage: `url(${item.thumb_url})`
      }
    }
  }
}
</script>

<template lang="pug">
//- NOTE: Bind `key` with image url to force lazyload update when url updates
//- https://github.com/hilongjw/vue-lazyload/issues/273
//- Here we add `page.id` and item `index` in the "key" value to prevent duplicate key on multi-zone pages
//- :key="`page_${page.id}_${index}_${item.thumb_url}`"
.page-thumbnail(:class="{'is-ttvad': isAdPlacement}", :style="{gridTemplate: gridTemplate}")
  .ttvad-placement(v-if="isAdPlacement")
    fa.fa-icon(icon="ad" fixed-width)

  .is-freeform-page(v-if="isFreeFormPage")
    fa.fa-icon(icon="layer-group" fixed-width)

  template(v-else)
    .page-thumb-item(v-for="(item, index) in items"
                    :style="{gridArea: item.grid_area || 'main'}"
                    v-lazy:background-image="item.thumb_url"
                    :key="`page_${page.id}_${index}_${item.thumb_url}`")
</template>

<style lang="stylus">
$thumbWidth = 168px
$thumbHeight = 96px
$gridGap = 2px
$adWidth = 96px

.page-thumbnail
  width: $thumbWidth
  height: $thumbHeight

  display: grid
  justify-items: stretch
  justify-content: stretch
  align-items: stretch
  align-content: stretch
  grid-row-gap: $gridGap
  grid-column-gap: $gridGap

  &.is-ttvad
    width: $adWidth

  .page-thumb-item
    position: relative
    background-position: 50% 50%
    background-repeat: no-repeat
    background-size: cover

  .ttvad-placement,
  .is-freeform-page
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    .fa-icon
      font-size: 3em

  .ttvad-placement
    background: var(--brand-warning)
    color: #111

  .is-freeform-page
    background: var(--gray-mid)
    color: #fff
</style>
