<script>
export default {
  name: 'FreeFormItemContainer',

  props: {
    type: {
      type: String,
      default: ''
    },

    position: {
      type: Object,
      required: true
    }
  },

  computed: {
    useSquareRatio () {
      return [
        'object-shape-square',
        'object-shape-circle',
        'object-icon'
      ].includes(this.type)
    },

    horzResizeOnly () {
      return this.type === 'object-text'
    },

    positionStyle () {
      const style = {
        top: `${this.position.y}%`,
        left: `${this.position.x}%`,
        width: `${this.position.width}%`,
        height: `${this.position.height}%`
      }
      if (this.horzResizeOnly) {
        style.height = 'auto'
      }
      if (this.useSquareRatio) {
        style.width = `${this.position.size}%`
        style.height = 'auto'
        style.aspectRatio = '1 / 1'
      }
      return style
    },

    zIndexStyle () {
      return {
        zIndex: this.position.order || 0
      }
    },

    rotateStyle () {
      if (!this.position.rotate) { return }
      return {
        transform: `rotate(${this.position.rotate}deg)`
      }
    }
  }
}
</script>

<template lang="pug">
.free-form-item-container(:style="[positionStyle, zIndexStyle, rotateStyle]")
  slot
</template>

<style lang="stylus">
.free-form-item-container
  position: absolute
  z-index: inherit
</style>
