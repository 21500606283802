<script>
import { mapState, mapGetters } from 'vuex'

import Env from 'services/environment'
import { getVersionSupport } from 'services/version-support'

export default {
  name: 'VersionRibbon',

  data () {
    const { platformSupport, applicationSupport, browserSupport } = getVersionSupport()

    return {
      showVersionOverlay: (
        !Env.isDevelopment() &&
        !Env.previewMode &&
        !Env.desktopMode &&
        !Env.pageEditMode &&
        !Env.iframeEmbedMode &&
        !Env.ioswebkit
      ),

      appStartedAt: Env.startupTime,
      provisioned: Env.provisioned,

      osName: Env.osName,
      osVersion: `${Env.osName}-${Env.osVersion}`,
      browser: `${Env.browserName}-${Env.browserVersion}`,
      appVersion: Env.appVersion,
      playerVersion: Env.version,

      stage: Env.stage,

      platformSupport,
      applicationSupport,
      browserSupport
    }
  },

  computed: {
    ...mapState({
      debugMode: s => s.playbackmode.debugMode
    }),
    ...mapGetters([
      'apiStreamStatus',
      'apiDisconnectCount',
      'apiStreamStatusChangedAt'
    ]),

    showRibbonBody () {
      return (
        this.debugMode ||
        this.isOutdated
      )
    },

    isOutdated () {
      return (
        this.showOutdatedPlatformWarning ||
        this.showOutdatedApplicationWarning ||
        this.showOutdatedBrowserWarning
      )
    },

    showOutdatedPlatformWarning () {
      return this.platformSupport.endsWith('outdated')
    },

    showOutdatedApplicationWarning () {
      return this.applicationSupport.endsWith('outdated')
    },

    showOutdatedBrowserWarning () {
      return this.browserSupport.endsWith('outdated')
    }
  }
}
</script>

<template lang="pug">
aside.version-ribbon
  //- Disabled until we have some translations
  //- .outdated-message(v-if="showOutdatedPlatformWarning")
  //-   | {{ $t('versionsControl.outdatedPlatformHint', { osName }) }}
  .outdated-message(v-if="showOutdatedApplicationWarning")
    | {{ $t('versionsControl.outdatedAppHint', { osName, appVersion }) }}
  .outdated-message(v-if="showOutdatedBrowserWarning")
    | {{ $t('versionsControl.oldBrowser') }}
  .debug-information(v-if="debugMode")
    span
      span.label stage:
      span.value {{stage}}
    span
      span.label up:
      span.value {{appStartedAt && appStartedAt.toLocaleString()}}
    span
      span.label state:
      span.value {{apiStreamStatus}}
    span
      span.label ws-up:
      span.value {{apiStreamStatusChangedAt && apiStreamStatusChangedAt.toLocaleString()}}
    span
      span.label disconnects:
      span.value {{apiDisconnectCount}}
    span
      span.label os:
      span.value {{osVersion}}
    span
      span.label browser:
      span.value {{browser}}
    span
      span.label app:
      span.value {{appVersion}}
    span
      span.label player:
      span.value {{playerVersion}}
    span
      span.label provisioned:
      span.value(v-if="provisioned") true
      span.value(v-else) false
</template>

<style lang="stylus">
aside.version-ribbon
  z-index: 1000
  position: absolute
  left: 0
  right: 0
  bottom: 0
  pointer-events: none
  display: flex
  flex-direction: column

  .outdated-message
    background-color: rgba(0, 0, 0, 1.0)
    color: white
    font-size: 1vh
    padding: 4px
    text-align: center
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center

  .debug-information
    background-color: rgba(0, 0, 0, 1.0)
    color: white
    font-size: 1vh
    padding: 4px
    text-align: center
    display: flex
    justify-content: space-between
    flex-flow: row nowrap
    align-items: center

    span.label
      color: #AAAAAA
    span.value
      color: yellow
</style>
