<script>
import AnimationHelper from 'services/animator.js'

// in ms. Debounce value change animation
const DEBOUNCE_TIME = 200

export default {
  name: 'AnimateValue',

  props: {
    targetValue: { type: Number }
  },

  data () {
    return {
      currentValue: 0,

      Animator: undefined,
      startValue: 0,
      lastValue: 0,
      duration: 1000,
      animating: false,

      waitTimer: undefined,
      debounceTimer: undefined
    }
  },

  watch: {
    targetValue () {
      this.debounceValueChange()
    }
  },

  created () {
    this.bindAnimator()
  },

  mounted () {
    clearTimeout(this.waitTimer)
    clearTimeout(this.debounceTimer)

    this.initAnimator()
    this.debounceValueChange()
  },

  beforeDestroy () {
    clearTimeout(this.waitTimer)
    clearTimeout(this.debounceTimer)

    if (this.Animator) {
      this.Animator.removeAnimator()
      this.Animator = null
    }
  },

  methods: {
    waitAnimator (onReady) {
      clearTimeout(this.waitTimer)
      if (!this.Animator) {
        this.bindAnimator()
        this.waitTimer = setTimeout(() => {
          clearTimeout(this.waitTimer)
          this.waitAnimator(onReady)
        }, 100)
      } else {
        onReady()
      }
    },

    bindAnimator () {
      if (this.Animator) { return }
      this.Animator = new AnimationHelper.Animator()
    },

    initAnimator () {
      return new Promise((resolve) => {
        if (!this.Animator) {
          this.waitAnimator(() => {
            this.Animator.initAnimator()
            resolve()
          })
          return
        }
        this.Animator.initAnimator()
        resolve()
      })
    },

    resumeAnimator () {
      return this.initAnimator()
    },

    pauseAnimator () {
      this.Animator.stopAnimation()
      this.Animator.removeAnimator()
      this.Animator = null
    },

    debounceValueChange () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        if (this.animating) {
          this.debounceValueChange()
        } else {
          this.valueChange()
        }
      }, DEBOUNCE_TIME)
    },

    valueChange () {
      this.animating = true
      this.startValue = this.lastValue + 0
      this.resumeAnimator().then(() => {
        this.Animator.startAnimation(null, this.animate.bind(this))
      })
    },

    animate () {
      let t = ((new Date()).getTime() - this.Animator.startTime) / this.duration
      let v

      if (t >= 1) {
        v = this.targetValue + 0
        this.lastValue = v
        this.pauseAnimator()
        this.animating = false
      } else {
        if ((t /= 1 / 2) < 1) {
          t = 1 / 2 * t * t
        } else {
          t = -1 / 2 * ((--t) * (t - 2) - 1)
        }
        v = this.startValue + (this.targetValue - this.startValue) * t
      }
      this.currentValue = v
      this.$emit('update', this.currentValue)
    }
  }
}
</script>

<template lang="pug">
.animate-value
</template>

<style lang="stylus">
.animate-value
  visibility: hidden
  display: none
  opacity: 0
  height: 0
  width: 0
</style>
