<script>
// Placeholder for completely Deprecated App, Media, etc., page zones

const SKIP_DELAY = 5000

export default {
  name: 'DeprecatedPageItem',

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      skipTimer: null
    }
  },

  mounted () {
    clearTimeout(this.skipTimer)
    this.skipTimer = setTimeout(() => {
      this.$emit('finished')
    }, SKIP_DELAY)
  },

  beforeDestroy () {
    clearTimeout(this.skipTimer)
  }
}
</script>

<template lang="pug">
section.deprecated-page-item
  p {{ $t('pageItems.deprecated.message', { type }) }}
</template>

<style lang="stylus">
section.deprecated-page-item
  position: absolute
  width: 100%
  height: 100%

  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  text-align: center
  padding: 1em

  p
    text-align: center
    line-height: 180%
    // Support showing `\n`
    white-space: pre-line
</style>
