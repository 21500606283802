<script>
export default {
  name: 'InputSearch',

  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' }
  },

  data () {
    return {
      cloneValue: ''
    }
  },

  watch: {
    value (newValue) {
      this.cloneValue = (newValue || '') + ''
    }
  },

  mounted () {
    this.cloneValue = (this.value || '') + ''
  },

  methods: {
    updateInput () {
      this.$emit('input', this.cloneValue)
    }
  }
}
</script>

<template lang="pug">
.input-search
  fa.fa-icon(:icon="['far', 'search']" fixed-width)
  //- Add "keyup" caputure here to prevent the event from bubbling up to the parent and causing the page to change (n/p)
  input(type="search"
        :placeholder="placeholder"
        v-model="cloneValue"
        @input="updateInput"
        @keyup.stop="")
</template>

<style lang=stylus>
@import '../../style/mixins.styl';

.input-search
  display: inline-flex
  position: relative
  font-size: inherit

  .fa-icon
    position: absolute
    z-index: 5
    color: -white(0.85)
    top: calc(50% - 0.45em)
    left: 0.4em

  input
    font-size: inherit
    width: 100%
    position: relative
    z-index: 1
    border: 1px solid -white(0.6)
    border-radius: 5em
    line-height: 150%
    height: 2em
    padding: 0.2em 0.5em 0.2em 1.9em
    color: #fff
    background: transparent
    outline: 0
    transition: border-color .3s

    &::-webkit-input-placeholder
      color: -white(0.35)
    &:-moz-placeholder
      color: -white(0.35)
    &::-moz-placeholder
      color: -white(0.35)
    &:-ms-input-placeholder
      color: -white(0.35)

    &:focus
      border-color: #fff
</style>
