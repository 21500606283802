<script>
import {
  mapMutations,
  mapGetters,
  mapState
} from 'vuex'
import Moment from 'moment-timezone'

import AppControl from 'services/appcontrol.js'
import Telemetry from 'services/telemetry.js'
import Heartbeat from 'services/heartbeat.js'
import SendDeviceInfo from 'services/senddeviceinfo.js'
import Environment from 'services/environment'
import Log from 'services/log.js'
import Caches from 'services/caches.js'
import OfflineCaches from 'services/offline-caches'
import ContentCache from 'services/contentCache.js'
import BrowserStorage from 'services/browserstorage.js'
import Utils from 'services/utils'
import Update from 'services/update'
import Pages from 'services/pages'
import { EventBus } from 'services/eventbus.js'

import Indicator from 'components/common/Indicator.vue'
import LocaleLanguage from 'components/common/LocaleLanguage.vue'

// In ms. Delay revoke blob URL
const REVOKE_BLOB_AFTER = 2500

export default {
  el: '#app',
  name: 'TelemetryTvPlayer',

  components: {
    Indicator,
    LocaleLanguage
  },

  data () {
    return {
      isFullscreen: false,
      environment: Environment,

      reloading: false,

      // Delay + Async loading "indicator" to prevent some odd bundling bug
      loadIndicator: false,

      wakeLock: null,

      reloadTimer: null,
      scheduledTimer: null,
      blobUrlTimers: {}
    }
  },

  computed: {
    ...mapState({
      billingPlanExpired: state => state.account.billingPlanExpired || false
    }),
    ...mapGetters([
      'tooManyAPIDisconnects',
      'accountHideOfflineSign',
      'rotationAngle',
      'needsRotation',
      'swapHeightAndWidth',
      'deviceActiveLanguage',
      'activeToken',
      'deviceDisabled',
      'localDeviceDisabed',
      'deviceSerialCommandItems',
      'deviceSerialConnection',
      'accountRegion',
      'accountLocale',
      'deviceRestartTime',
      'pages',
      'activePlaylists',
      'apiActiveAndAuthed',
      'deviceInWebappMode',
      // For temporally debug
      'isPaused'
    ]),

    showIndicator () {
      return this.loadIndicator && !this.environment.previewMode && !this.environment.desktopMode && !this.environment.pageEditMode && !this.environment.iframeEmbedMode
    },

    rotationStyle () {
      if (this.needsRotation) {
        const transformStr = `rotate(${this.rotationAngle}deg)`
        if (this.swapHeightAndWidth) {
          return {
            transform: `translate3d(50vw, 50vh, 0) ${transformStr}`
          }
        }
        return {
          transform: `translate3d(50%, 50%, 0) ${transformStr}`
        }
      }
      return undefined
    },

    localeLangEnabled () {
      return Boolean(this.deviceActiveLanguage && this.deviceActiveLanguage !== 'en')
    }
  },

  watch: {
    deviceDisabled (disabled) {
      if (disabled) {
        // This avoids nested redirect since billing plan is expired and device disabled occurs same time
        if (this.$router.currentRoute.path === '/errored/billing-plan-expired') {
          return
        }
        this.$router.push({
          name: 'errored',
          params: {error: 'device-disabled'}
        })
      } else {
        this.$router.push({
          name: 'restarting',
          query: {timer: 5}
        })
      }
    },

    tooManyAPIDisconnects (tooMany) {
      if (tooMany) {
        const params = {
          error: 'too-many-disconnects'
        }
        this.$router.push({
          name: 'errored',
          params
        })
      }
    },

    // NOTE: Label changed to "Device Warning Indicator" in DEV-4408
    // While the property name remains in "hide_offline_sign"
    accountHideOfflineSign (toHide) {
      if (this.environment.previewMode || this.environment.desktopMode || this.environment.pageEditMode || this.environment.iframeEmbedMode) { return }
      if (toHide) {
        Log.info('player', 'The "Hide Device Warning Indicator" feature is enabled in your account\'s Settings > Device Offline Sign', 'INF_DEVICEOFFLINESIGN')
        this.$store.commit('toggleHideIndicator', { viaAccountSettings: true, toHide: true })
      } else {
        this.$store.commit('toggleHideIndicator', { viaAccountSettings: true, toHide: false })
      }
    },

    deviceActiveLanguage (language) {
      // Update account language
      if (language && language !== this.$i18n.locale) {
        this.updateLocaleLanguage(language)
      } else if (!language || language === 'en') {
        this.updateLocaleLanguage('en')
      }
    },

    accountRegion (region) {
      this.updateRegionBase(region)
    },

    deviceSerialCommandItems: {
      deep: true,
      handler (newValue) {
        if (this.deviceDisabled) { return }
        this.environment.serialCommands(newValue)
        EventBus.$emit('sdk-on-serial-commands', newValue)
      }
    },

    deviceSerialConnection: {
      deep: true,
      handler (newValue) {
        this.environment.serialConnection(newValue)
        EventBus.$emit('sdk-on-serial-connection', newValue)
      }
    },

    // Use this global watcher to trigger Pages services update instead of Vuex subscribe
    pages: {
      deep: true,
      handler (newValue) {
        Pages.set(newValue)
      }
    },

    // Use this global watcher to trigger Pages services update instead of Vuex subscribe
    activePlaylists (newValue) {
      this.$nextTick(() => {
        Pages.set(this.pages)
      })
    },

    // When `true`, means apiStatus is "active" and successful;y AUTHed
    apiActiveAndAuthed (toTrue = false) {
      // Trigger pending messages sender, and switch "STATUS" object storage method (localStorage <-> JSObject)
      Telemetry.handleApiStatusChanges(toTrue)
    },

    // Debug for [DEV-4191]
    isPaused (toTrue = false) {
      if (toTrue) {
        Log.warn('player', 'Player is set to paused', 'WAR_PLAYERPAUSED')
      } else {
        Log.warn('player', 'Player resume playback from paused state', 'WAR_PLAYERRESUMED')
      }
    },

    billingPlanExpired (expired) {
      if (expired) {
        this.$router.push({
          name: 'errored',
          params: { error: 'billing-plan-expired' }
        })
      }
    }
  },

  created () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('session_token') || urlParams.get('user_token')) {
      // Ensure we use sessionStorage for user sessions to fix event racing in preview modes
      BrowserStorage.useSessionStorage()
    }
    Log.start()
    OfflineCaches.start()
    Telemetry.init()
    Heartbeat.start()
    SendDeviceInfo.start()
    ContentCache.start()
  },

  mounted () {
    this.updateRegionBase(this.accountRegion)

    window.addEventListener('message', this.messageHandler, false)
    window.addEventListener('beforeunload', this.beforeWindowUnload)
    window.addEventListener('unhandledrejection', this.unhandledRejectionHandler)

    AppControl.on('system-key-command', this.systemKeyCmdHandler)

    Telemetry.on('claimed', this.claimedHandler)
    Telemetry.on('restart', this.restartCmdHandler)
    Telemetry.on('device_removed', this.deviceRemovedHandler)
    Telemetry.on('switch-region', this.switchingRegion)
    Telemetry.on('switch-region-errored', this.errorSwitchingRegion)

    EventBus.$on('revoke-blob-url', this.revokeBolbURL)
    EventBus.$on('auth-set-token', this.authSetToken)
    EventBus.$on('auth-reset', this.authReset)
    EventBus.$on('clear-deprecated-localstorage', this.clearDeprecatedLS)
    EventBus.$on('storage-access-denied', this.storageDeniedHandler)
    EventBus.$on('clear-app-cache', this.clearAppCache)
    EventBus.$on('clear-media-folder-cache', this.clearMediaFolderCache)

    // NOTE @ Aug 29, 2018
    // Vuex store was called before window client is ready.
    // Programmically set vuex state initial value with session/local storage will not work --
    //
    // ```
    // state: {
    //   device: BrowserStorage.get('device') || null
    // }
    // ```
    //
    // The initial value will always be `null` in the above case
    // Hence, need to manually grab cached info after app init
    this.loadDeviceInfo()
    this.loadAccountInfo()
    this.loadUserInfo()
    this.loadAuthInfo()
    this.loadStoredRotation()

    if (!this.localDeviceDisabed) {
      this.loadUptimeInfo()
      this.loadDevicePlayTime()
      this.loadOverrides()
      // Webapp-only mode
      if (this.deviceInWebappMode) {
        this.$store.dispatch('loadDeviceWebappInfo')
      // Regular Playlist mode
      } else {
        this.loadPlaylistInfo()
        this.loadOverridePlaylistInfo()
      }
    }

    this.setupWakeLock()

    // Set Account Language (init with stored config)
    if (this.deviceActiveLanguage && this.deviceActiveLanguage !== 'en') {
      this.updateLocaleLanguage(this.deviceActiveLanguage)
    } else {
      this.updateLocaleLanguage('en')
    }

    // Poll for Required Restart
    const reloadPeriod = 10 // 10 seconds
    clearInterval(this.reloadTimer)
    this.reloadTimer = setInterval(() => {
      if (Environment.restartRequired) {
        // App or Override Preview
        // Entry point: `/item_preview`
        let isAppPreview = false
        let isOverridePreview = false

        // Playlist Preview / Playlist Page Edit
        // Entry point: `/embed`
        // Will advance to `/run` eventually
        let playlistPreviewOrEdit = false

        // Free Form Page Item Preview
        // Entry point: `/page_item`
        let isFreeFormPreview = false

        // When the requested component is not loaded, `Environment` flag will not be set
        // because the `created` and `mounted` process could not be called.
        // Hence, need to watch route query in these situations
        if (Environment.pageEditMode ||
           (this.$route && this.$route.query && (this.$route.query.page_edit_mode === 'true' || this.$route.query.preview_mode === 'true'))
        ) {
          playlistPreviewOrEdit = true
        } else if (this.$route) {
          const isItemPreviewPage = (this.$route.matched || []).filter(m => m && m.name === 'item_preview')
          if (isItemPreviewPage?.length) {
            if (this.$route.query && this.$route.query.isOverride) {
              isOverridePreview = true
            } else {
              isAppPreview = true
            }
          }
          const isFreeFormPreviewPage = (this.$route.matched || []).filter(m => m && m.name === 'page_item')
          if (isFreeFormPreviewPage?.length) {
            isFreeFormPreview = true
          }
        }

        if ((!isAppPreview && !isOverridePreview && !isFreeFormPreview) && Environment.previewMode) {
          playlistPreviewOrEdit = true
        }

        // Hard reload window for App/Override Preview (js_user embedding)
        if (isAppPreview || isOverridePreview || isFreeFormPreview) {
          // Prevent infinite reload
          if (!this.reloading) {
            const previewType = isFreeFormPreview ? 'Page Item' : (isAppPreview ? 'App' : 'Override')
            Log.warn('player', `Player Reloading (Embedded ${previewType})`, 'WAR_EMBEDPLAYERRELOAD')
            this.reloading = true
            window.location.reload()
          }
        // Display a reload requried hint, but don't force reload
        // - It's already in the `/run` path and don't have the requried playlistID+pageID parameters in the URL anymore
        } else if (playlistPreviewOrEdit) {
          Log.warn('player', 'Player Reload Required (Page Edit / Playlist Preview)', 'WAR_PLAYERRELOADREQUIRE')
        // Restart Player in other modes
        } else {
          // NOTE: Force grab the English (en) version message for device logs [DEV-2329]
          let logMsg = this.$t('restarting.playerReload.text', 'en')
          let chunkName
          if (Environment.restartFrom) {
            logMsg += ' - '
            chunkName = Environment.getRestartFromChunkName()
            logMsg += (chunkName ? this.$t(Environment.restartDescription(), 'en', {chunk: chunkName}) : this.$t(Environment.restartDescription(), 'en'))
          }
          Log.warn('player', logMsg, 'WAR_PLAYERRELOADREQUIRE2')

          // Desktop Mode
          if (Environment.desktopMode) {
            const landingURL = Environment.getDesktopLandingPage(this.activeToken)
            if (landingURL && landingURL.length) {
              window.location.href = landingURL
            }
          // Iframe Embed Mode [DEV-3095]
          } else if (Environment.iframeEmbedMode) {
            const embedURL = Environment.getIframeEmbedUrl()
            if (embedURL && embedURL.length) {
              window.location.href = embedURL
            }
          // Others
          } else {
            const msg = this.$t('restarting.playerReload.text')
            let description = ''
            if (Environment.restartFrom) {
              description = chunkName ? this.$t(Environment.restartDescription(), {chunk: chunkName}) : this.$t(Environment.restartDescription())
            }
            this.restartHanlder(
              {message: msg, timer: reloadPeriod, description}
            )
          }
        }
      }
    }, reloadPeriod * 1000)

    this.$nextTick(() => {
      this.loadIndicator = true
    })

    clearInterval(this.scheduledTimer)
    this.scheduledTimer = setInterval(() => {
      this.checkRestartTime()
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.reloadTimer)
    clearInterval(this.scheduledTimer)

    if (Object.keys(this.blobUrlTimers).length) {
      Object.keys(this.blobUrlTimers).forEach(blobUrl => {
        clearTimeout(this.blobUrlTimers[blobUrl])
        URL.revokeObjectURL(blobUrl)
        this.blobUrlTimers[blobUrl] = null
        delete this.blobUrlTimers[blobUrl]
      })
    }

    window.removeEventListener('message', this.messageHandler, false)
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
    window.removeEventListener('unhandledrejection', this.unhandledRejectionHandler)

    AppControl.removeListener('system-key-command', this.systemKeyCmdHandler)
    Telemetry.removeListener('claimed', this.claimedHandler)
    Telemetry.removeListener('restart', this.restartCmdHandler)
    Telemetry.removeListener('device_removed', this.deviceRemovedHandler)
    Telemetry.removeListener('switch-region', this.switchingRegion)
    Telemetry.removeListener('switch-region-errored', this.errorSwitchingRegion)

    EventBus.$off('revoke-blob-url', this.revokeBolbURL)
    EventBus.$off('auth-set-token', this.authSetToken)
    EventBus.$off('auth-reset', this.authReset)
    EventBus.$off('clear-deprecated-localstorage', this.clearDeprecatedLS)
    EventBus.$off('storage-access-denied', this.storageDeniedHandler)
    EventBus.$off('clear-app-cache', this.clearAppCache)
    EventBus.$off('clear-media-folder-cache', this.clearMediaFolderCache)

    Heartbeat.stop()
    SendDeviceInfo.stop()
    OfflineCaches.stop()
    Update.stop()
    ContentCache.clearTimers()
    Telemetry.clearAllTimers()
    Log.reset()
  },

  methods: {
    ...mapMutations([
      'loadDeviceInfo',
      'loadAccountInfo',
      'loadUserInfo',
      'loadAuthInfo',
      'loadOverrides',
      'loadOverridePlaylistInfo',
      'loadPlaylistInfo',
      'loadStoredRotation',
      'loadDevicePlayTime',
      'loadUptimeInfo',
      'resetAuth',
      'resetDevice',
      'resetPlaylists',
      'resetOverrides',
      'resetPlayTime',
      'resetOnlineLogs'
    ]),

    systemKeyCmdHandler (event) {
      if (!event) { return }
      const elem = document.documentElement
      switch (event.command) {
        case 'quit':
          Environment.exit()
          return this.restartHanlder({ message: this.$t('app.restartMessages.exit') })
        case 'restart':
          return this.restartHanlder()
        case 'reset':
          this.resetOnlineLogs()
          this.resetAuth('Reset Command')
          this.resetDevice()
          this.resetPlaylists()
          this.resetOverrides()
          this.resetPlayTime()
          // Purge cache storage on reset [DEV-316]
          this.clearAllCache(true)
          return this.restartHanlder({ message: this.$t('app.restartMessages.reset'), hideSpinner: true }, 'reset')
        case 'clear-cache':
          this.clearAllCache()
          break
        case 'fullscreen':
          if (
            document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled
          ) {
            Environment.toggleFullscreen()
            if (!this.isFullscreen) {
              if (elem.requestFullscreen) {
                elem.requestFullscreen()
                this.isFullscreen = true
              } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen()
                this.isFullscreen = true
              } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen()
                this.isFullscreen = true
              } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen()
                this.isFullscreen = true
              }
            } else {
              if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
                if (document.exitFullscreen) {
                  document.exitFullscreen()
                } else if (document.webkitExitFullscreen) {
                  document.webkitExitFullscreen()
                } else if (document.mozCancelFullScreen) {
                  document.mozCancelFullScreen()
                } else if (document.msExitFullscreen) {
                  document.msExitFullscreen()
                }
              }
              this.isFullscreen = false
            }
          }
          break
        case 'toggle-hide-indicator':
          this.$store.commit('toggleHideIndicator', { viaAccountSettings: false })
          break
      }
    },

    claimedHandler () {
      this.$router.push({
        name: 'starting'
      })
    },

    // reboot Android & TelemetryOS device, restart the app for other platforms
    restartCmdHandler () {
      if (Environment.androidAPI || Environment.telemetryOS) {
        Environment.reboot()
      } else {
        this.restartHanlder()
      }
    },

    deviceRemovedHandler () {
      this.restartHanlder({ message: this.$t('app.restartMessages.reset'), hideSpinner: true }, 'reset')
    },

    switchingRegion (toTrue = false) {
      this.$store.commit('updateSwitchingRegion', toTrue)
    },

    errorSwitchingRegion (err) {
      // If the error occurs on the "errored" or "inactive" screen, do nothing
      if (!['errored', 'inactive'].includes(this.$route.name)) {
        this.$router.push({
          name: 'errored',
          params: { error: 'switch-region-errored' },
          query: {
            description: err.message || err.toString || ''
          }
        })
      }
      this.$store.commit('updateSwitchingRegion', false)
    },

    clearAllCache (isReset = false) {
      if (isReset) {
        Log.warn('player', 'Cache clear: Clear all caches on device reset', 'WAR_CACHECLEARONRESET')
      } else {
        Log.warn('player', 'Cache clear: Manually clear all caches', 'WAR_MANUALCACHECLEAR')
      }

      // LRU, IndexedDB, LocalStorage and Vuex
      Caches.clearAll(isReset)
      // Cache Storage API
      ContentCache.clear()

      if (Environment.supportDeepReset() && isReset) {
        // Electron will clear all browser data from higher level [DEV-4554]
        // Calling `Environment.clearCache()` will cause duplicate clean up
        return
      }
      // Environment (containing apps)
      Environment.clearCache()
    },

    // For Electron only [#DEV-4423]
    clearAppCache () {
      this.$router.push({
        name: 'restarting',
        query: {
          message: this.$t('restarting.clearAppCache'),
          timer: 10,
          hideSpinner: true
        }
      })

      // LRU, IndexedDB, LocalStorage and Vuex
      Caches.clearAll()
      // Cache API etc., will be removed by Electron "webContent.session"
    },

    clearMediaFolderCache (folderId) {
      if (!folderId) { return }
      ContentCache.deleteMatchingCache('media_folder_list', folderId)
      OfflineCaches.removeByPattern('ttvMediaFolder_', folderId)
      Caches.clearMediaCache('mediaContents_', `folder_id=${folderId}`)
    },

    restartHanlder (query, reason = '') {
      let skipElectronScreen = false
      if (reason === 'reset' && Environment.supportDeepReset()) {
        // New Electron version with deep reset will clear all browser data from higher level.
        // It requires the mainWindow to stay with js_player in the meantime.
        skipElectronScreen = true
      }

      if (Environment.isVueElectronPlayer() && !skipElectronScreen) {
        // Use Electron Vue's "Restarting" page
        Environment.restart(query)
        return
      }
      const targetRoute = {
        name: 'restarting'
      }
      if (query && typeof query === 'object') {
        targetRoute.query = query
      }
      this.$router.push(targetRoute)
    },

    updateLocaleLanguage (language) {
      this.$set(this.$i18n, 'locale', language)
      if (language === 'en') {
        // Make sure the MomentJS locale is reset back to English
        Moment.locale('en')
      }
    },

    // Trigger GC for image/video resources
    revokeBolbURL (blobUrl = '') {
      if (!blobUrl.length) { return }
      clearTimeout(this.blobUrlTimers[blobUrl])
      this.blobUrlTimers[blobUrl] = setTimeout(() => {
        clearTimeout(this.blobUrlTimers[blobUrl])
        URL.revokeObjectURL(blobUrl)
        this.blobUrlTimers[blobUrl] = null
        delete this.blobUrlTimers[blobUrl]
      }, REVOKE_BLOB_AFTER)
    },

    authSetToken (token) {
      Environment.setAuth(token)
    },

    authReset () {
      Environment.resetAuth()
    },

    clearDeprecatedLS () {
      Caches.clearDeprecatedLS()
    },

    messageHandler (evt) {
      // We only respond to postMessage from js_user (PlaylistPlayer.vue) here
      // Add origin guard here for safety concern
      if (evt.origin !== Environment.userAppURL()) {
        return
      }

      if (!evt || !evt.data || !evt.data.type) { return }
      if (evt.data.type === 'app-window-hidden') {
        // For js_electron_user "Play" section only [DEV-2109]
        if (!Environment.desktopMode) { return }
        const isHidden = !!evt.data.data
        Environment.toggleAppVisibility(isHidden)
        Telemetry._debouncedHandleWindowVisiblityChange()
        EventBus.$emit('app-window-hidden', isHidden)
      } else if (evt.data.type === 'clear-player-cache') {
        this.clearAllCache()
      } else if (evt.data.type === 'pause-and-go-to-page') {
        if (evt.data.data) {
          EventBus.$emit('pause-and-go-to-page', evt.data.data)
        }
      } else if (evt.data.type === 'app-playback-command') {
        if (evt.data.data && evt.data.data.command) {
          AppControl.emit('system-key-command', evt.data.data)
        }
      }
    },

    updateRegionBase (newValue) {
      if (newValue) {
        this.environment.updateRegion(newValue)
      }
    },

    checkRestartTime () {
      if (this.deviceDisabled || !this.deviceRestartTime) { return }
      const now = Moment().tz(this.accountLocale.timezone)
      const restartTime = this.deviceRestartTime.split(':')
      const targetTime = now.clone().hour(restartTime[0]).minute(restartTime[1]).second(restartTime[2])
      if (now.format('HH:mm:ss') === targetTime.format('HH:mm:ss')) {
        this.restartCmdHandler({ message: this.$t('app.restartMessages.scheduled') })
      }
    },

    beforeWindowUnload () {
      this.$store.dispatch('sendPlayTime')

      // Send out aggregated "STATUS" data before window close
      Telemetry.sendAggregateData(true)

      this.releaseWakeLock()
      // Mark device offline on window close [DEV-1896]
      // -- `beforeDestroy` is not reliable
      this.$store.commit('addOnlineLog', false)
    },

    getRejectReason (evt) {
      if (evt && typeof evt === 'string') {
        return evt
      }
      if (evt && evt.reason && typeof evt.reason === 'string') {
        return evt.reason
      }
      // NOTE: `reason.message` can be an empty string in some cases [DEV-4306]
      if (evt && evt.reason && typeof evt.reason.message === 'string' && evt.reason.message.length) {
        return evt.reason.message
      }
      // Some rejections return details in `reason.context.data`
      // > {"reason":{"context":{"data":"Internal Server Error","event":"error"}}}
      if (evt && evt.reason && evt.reason.context && evt.reason.context.data && typeof evt.reason.context.data === 'string') {
        return evt.reason.context.data
      }
      // Fallback to `reason.name`, if found
      if (evt && evt.reason && typeof evt.reason.name === 'string' && evt.reason.name.length) {
        return evt.reason.name
      }
      if (evt && evt instanceof Error) {
        return evt.toString()
      }

      if (evt && typeof evt === 'object') {
        try {
          // Turn object to readable string instead of '[object Object]'
          const result = JSON.stringify(evt)
          return result
        } catch (e) {}
      }

      return evt.toString() || `${evt}` || ''
    },

    safePreventDefault (evt) {
      if (evt && typeof evt.preventDefault === 'function') {
        evt.preventDefault()
      }
    },

    unhandledRejectionHandler (evt) {
      const reason = this.getRejectReason(evt)
      const lowerCased = reason.toLowerCase()

      if (lowerCased.indexOf('out of memory') >= 0 || `${evt}` === 'out of memory') {
        Log.error('player', 'Device is out of memory. Please try to clear the device cache', 'ERR_DEVICEOUTOFMEMORY', { reason, err: evt }, true)
        this.safePreventDefault(evt)

      // Low device storage related errors
      } else if (Utils.isQuotaExceedError(reason)) {
        Log.error('player', 'Device Cache Error - No device space or quota excedded. Please try to free up the device storage space', 'ERR_DEVICECACHE', { reason, err: evt }, true)
        this.safePreventDefault(evt)

      // Skip some error reports like "timeout", "unauthorized"
      } else if (Log.skipNotifyBugSnag(lowerCased)) {
        this.safePreventDefault(evt)

      // Previously be captured in the "SKIP_ERROR_CLASS" of 'services/bugs.js'
      } else if (
        reason === 'AbortError' ||
        reason === 'NotInCache' ||
        // "The operation was aborted. "
        lowerCased.indexOf('operation was aborted') >= 0 ||
        // "Connection is closing."
        lowerCased.indexOf('connection is closing') >= 0
      ) {
        this.safePreventDefault(evt)

      // Outdated Safari specific error
      // "IDBFactory.open() called in an invalid security context"
      } else if (lowerCased.indexOf('idbfactory') >= 0 && lowerCased.indexOf('invalid security context') >= 0) {
        this.safePreventDefault(evt)

      // Cache Storage Access Denied [DEV-4305]
      // Usually triggered by running `cache.keys()` without proper access
      } else if (Utils.isCacheDeniedError(lowerCased)) {
        this.safePreventDefault(evt)
        EventBus.$emit('storage-access-denied', 'cacheStorage')

      // The other Unknown reasons
      } else {
        Log.error('player', `Unhandled Rejection "${reason}"`, 'ERR_UNHANDLEDREJECT', {
          message: evt.message,
          reason: evt.reason,
          promise: evt.promise,
          stack: evt.stack,
          name: evt.name,
          type: evt.type,
          code: evt.code,
          errorClass: evt.errorClass,
          error: evt.error,
          evt
        })
        // Keep sending unknown rejection errors to BugSnag
      }
    },

    async setupWakeLock () {
      if (navigator && 'wakeLock' in navigator) {
        if (document.hidden || Environment.appWindowHidden()) {
          // Skip Wake Lock setup when the window is hidden
          return
        }
        try {
          this.wakeLock = await navigator.wakeLock.request('screen')
        } catch (err) {
          const errMsg = err.message || err.toString() || ''
          Log.warn('player', `Unable to setup wake lock: ${errMsg}`, 'WAR_UNABLEWAKELOCK', err)
        }
      }
    },

    releaseWakeLock () {
      if (this.wakeLock) {
        this.wakeLock.release().then(() => {
          this.wakeLock = null
        })
      }
    },

    storageDeniedHandler (type) {
      switch (type) {
        case 'localStorage':
          if (!Environment.localStorageDenied) {
            Environment.localStorageDenied = true
          }
          break
        case 'cacheStorage':
          if (!Environment.cacheStorageDenied) {
            Environment.cacheStorageDenied = true
          }
          break
        case 'indexedDB':
          if (!Environment.indexedDBDenied) {
            Environment.indexedDBDenied = true
          }
          break
      }

      if (this.$route.name !== 'errored' && Environment.hasCriticalStorageError()) {
        this.$router.push({
          name: 'errored',
          params: { error: 'storage-denied' },
          query: { restart: 86400 }
        })
      }
    }
  }
}
</script>

<template lang="pug">
#container
  #player-inner(:class="{'needs-rotation': needsRotation, 'swap-hw': swapHeightAndWidth}", :style="rotationStyle")
    router-view
    indicator(v-if="showIndicator")
    locale-language(v-if="localeLangEnabled", :lang="deviceActiveLanguage")
</template>

<style lang="stylus">
#container
  position: relative
  height: 100vh
  width: 100vw
  overflow: hidden

  #player-inner
    position: relative
    height: 100vh
    width: 100vw
    overflow: hidden

    &.needs-rotation
      position: absolute
      transform-origin: 50% 50%
      top: -50vh
      left: -50vw

      &.swap-hw
        top: -50vw
        left: -50vh
        height: 100vw
        width: 100vh
</style>
