<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import Override from 'components/player/Override.vue'
import OverlayHeader from 'components/common/OverlayHeader.vue'
import Message from 'components/common/Message.vue'
import Version from 'components/common/Version.vue'
import WebApp from 'components/items/WebApp.vue'

export default {
  name: 'DeviceWebapp',
  components: { Override, OverlayHeader, Message, Version, WebApp },

  data () {
    return {
      oneSecTimer: null
    }
  },

  computed: {
    ...mapState({
      loading: s => s.interactions.loadingContent,
      deviceWebappID: state => state.webapp.deviceWebappID,
      activeOverride: state => state.overrides.activeOverride
    }),
    ...mapGetters([
      'isOverridingByPlaylist',
      'deviceWebapp'
    ]),

    isEmpty () {
      return !this.deviceWebappID || !this.deviceWebappID.length
    }
  },

  mounted () {
    this.$store.dispatch('checkForActiveOverride')
    clearInterval(this.oneSecTimer)
    this.oneSecTimer = setInterval(() => {
      // Check valid overrides
      this.$store.dispatch('checkActiveOverrideStatus')
    }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.oneSecTimer)
  }
}
</script>

<template lang="pug">
section#device-webapp
  //- Has active, non-playlist-type override
  override(v-if="activeOverride && !isOverridingByPlaylist", :override="activeOverride")

  section.webapp-not-set(v-else-if="isEmpty")
    message(severity="info" :text="$t('webapp.empty.text')", :description="$t('webapp.empty.description')" :hideSpinner="!loading")
    overlay-header(:icon="['far', 'planet-ringed']")
    version

  web-app(v-else :webapp="deviceWebapp" use-webapp-prop active)
</template>

<style lang="stylus">
section#device-webapp
  position: relative
  width: 100%
  height: 100%
  overflow: hidden

  .webapp-not-set
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
</style>
