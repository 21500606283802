<script>
import Emoji from 'node-emoji'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import {
  mapGetters
} from 'vuex'

import Slack from 'services/slack.js'
import Env from 'services/environment'
import Log from 'services/log.js'
import { EventBus } from 'services/eventbus.js'

import SlackThreadRoot from './Thread.vue'

// Placeholder for unrecognized/customized Emoji
const EMOJI_PLACEHOLDER = 'white_large_square'

const RESOURCES_BASE = Env.resourcesURL()

export default {
  name: 'SlackMessageItem',

  components: { SlackThreadRoot },

  props: {
    item: { type: Object, required: true, default: () => ({}) },
    reactedOnly: { type: Boolean, default: false }
  },

  data () {
    return {
      reactedUsers: [],
      fontSizeRatio: 1,
      debounceTimer: undefined
    }
  },

  computed: {
    ...mapGetters({
      users: 'slackUsers',
      bots: 'slackBots'
    }),

    msgTime () {
      return Slack.formatTime(this.item.event_time, this.reactedOnly)
    },

    event () {
      return this.item.event || {}
    },

    mainText () {
      return Slack.formatMessage(this.event.text || this.event.plain_text)
    },

    isBot () {
      if (!this.event.user && this.event.bot_id) {
        return true
      }
      return false
    },

    userName () {
      // Regular User
      if (!this.isBot) {
        return this.getUserName(this.event.user)
      // Is a Bot
      } else if (this.bots) {
        if (this.event?.username) {
          return this.event.username
        }
        return this.getBotName(this.event.bot_id)
      }
      return ''
    },

    profileImage () {
      // Regular User
      if (!this.isBot) {
        return this.getUserImage(this.event.user)
      // Is a Bot
      } else {
        if (this.event?.icons?.image_72 || this.event?.icons?.image_64 || this.event?.icons?.image_48 || this.event?.icons?.image_36) {
          return this.event.icons.image_72 || this.event.icons.image_64 || this.event.icons.image_48 || this.event.icons.image_36
        }
        if (this.bots) {
          return this.getBotImage(this.event.bot_id)
        }
      }
      return undefined
    },

    profileEmoji () {
      if (this.isBot) {
        let result
        if (this.event.icons?.emoji) {
          result = this.getEmoji(this.event.icons.emoji)
        } else if (this.bots) {
          result = this.getBotEmoji(this.event.bot_id)
        }
        if (result) {
          return result
        }
      }
      return undefined
    },

    fontSize () {
      return {
        fontSize: `${this.fontSizeRatio}em`
      }
    }
  },

  watch: {
    'item.reactions': {
      handler (newValue) {
        if (newValue) {
          this.renderReactions()
        }
      }
    },

    mainText () {
      if (!this.reactedOnly) { return }
      this.debounceAdjustSize(200)
    }
  },

  mounted () {
    EventBus.$on('slack-reaction-added', this.alertSound)

    clearTimeout(this.debounceTimer)
    if (this.item.reactions) {
      this.renderReactions()
    }

    if (this.reactedOnly) {
      this.$nextTick(() => {
        this.adjustFontSize()
      })
      AddResizeListener(this.$refs.sensor, this.debounceAdjustSize)
    }
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor && this.reactedOnly) {
      RemoveResizeListener(this.$refs.sensor, this.debounceAdjustSize)
    }
    EventBus.$off('slack-reaction-added', this.alertSound)
    clearTimeout(this.debounceTimer)
  },

  methods: {
    renderReactions () {
      this.reactedUsers = []

      if (!this.item.reactions?.length) { return }

      this.item.reactions.forEach(rt => {
        const action = rt.name

        let emojiCode = Emoji.get(action)
        // Not a standard (Unicode) Emoji, use placeholder
        if (emojiCode === `:${action}:`) {
          emojiCode = Emoji.get(EMOJI_PLACEHOLDER)
        }

        this.$set(rt, 'emoji', emojiCode)

        // From historical data
        if (rt.users && rt.users.length) {
          rt.users.forEach(userID => {
            this.addToReactedUsers(userID)
          })
        }
      })
    },

    addToReactedUsers (userID) {
      if (!this.reactedUsers.includes(userID)) {
        this.reactedUsers.push(userID)
      }
    },

    formatText (rawText) {
      return Slack.formatMessage(rawText)
    },

    formatTime (rawTime) {
      return Slack.formatTime(rawTime)
    },

    getEmoji (string = '') {
      if (!string) { return }
      const emoji = Emoji.get(string.trim())
      if (emoji && !emoji.startsWith(':')) {
        return emoji
      }
    },

    isImage (file) {
      if (!file) { return false }
      if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg'].indexOf(file.filetype) !== -1) {
        return true
      }
      return false
    },

    isVideo (file) {
      if (!file) { return false }
      if (['mp4', 'mpeg', 'ogg'].indexOf(file.filetype) !== -1) {
        return true
      }
      return false
    },

    borderColor (atm) {
      if (!atm || !atm.color) { return }
      return { borderLeftColor: atm.color.startsWith('#') ? atm.color : `#${atm.color}` }
    },

    getUserImage (userID) {
      if (this.users && this.users[userID] && this.users[userID].profile) {
        return this.users[userID].profile.image_original || this.users[userID].profile.image_512 || this.users[userID].profile.image_192 || this.users[userID].profile.image_72
      }
    },

    getUserName (userID) {
      if (this.users && this.users[userID]) {
        return this.users[userID].real_name || this.users[userID].name
      }
      return userID
    },

    getBotName (botID) {
      if (botID && this.bots && this.bots[botID]) {
        return this.bots[botID].name
      }
      return botID
    },

    getBotImage (botID) {
      if (botID && this.bots && this.bots[botID] && this.bots[botID].icons) {
        const icons = this.bots[botID].icons
        return icons.image_72 || icons.image_64 || icons.image_48 || icons.image_36
      }
    },

    getBotEmoji (botID) {
      if (botID && this.bots && this.bots?.[botID]?.icons?.emoji) {
        return this.getEmoji(this.bots[botID].icons.emoji)
      }
    },

    debounceAdjustSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.fontSizeRatio = 1
        this.$nextTick(() => {
          this.adjustFontSize()
        })
      }, timeout || 200)
    },

    adjustFontSize () {
      const block = this.$refs.contentBlock
      if (!block) { return }

      if (block.scrollHeight > block.clientHeight) {
        this.fontSizeRatio *= 0.9
        this.$nextTick(() => {
          this.adjustFontSize()
        })
      }
    },

    alertSound (m) {
      if (!this.reactedOnly) { return }
      if (m.item && m.item.ts === this.item.event_id) {
        this.playAlertSound()
      }
    },

    playAlertSound () {
      const audio = new window.Audio(`${RESOURCES_BASE}/sounds/default_alert.mp3`).play()
      // Workaround for the `play() can only be initiated by a user gesture` error in Chrome v65
      audio.setAttribute('muted', 'muted')
      audio.play().then(() => {
        if (audio.muted) { audio.muted = false }
      }).catch(DOMException => {
        Log.debug('app', 'Slack: Unable to play alert sound.\nBrowser requires a user gesture to start audio playback', 'DBG_UNABLEPLAYSOUND5', null, true)
      }).catch(err => {
        Log.debug('app', 'Slack: Alert sound playback error', 'DBG_UNABLEPLAYSOUND6', err, true)
      })
    },

    getImageFile (file) {
      return Slack.getFileUrl(file.thumb_360_gif || file.thumb_360 || file.thumb_160 || file.thumb_80 || file.thumb_64)
    },

    getVideoFile (file) {
      return Slack.getFileUrl(file.url_private)
    },

    isHTTPS (url) {
      return (url || '').indexOf('https://') === 0
    }
  }
}
</script>

<template lang="pug">
.slack-message-item(:class="{'reacted-only': reactedOnly, 'is-pinned': item.isPinned}")

  .user-image(v-if="!reactedOnly")
    .emoji-avatar(v-if="profileEmoji")
      span {{ profileEmoji }}
    img(v-else-if="profileImage" :src="profileImage")

  .slack-message
    .meta-info
      .user-avatar(v-if="reactedOnly")
        .emoji-avatar(v-if="profileEmoji")
          span {{ profileEmoji }}
        img(v-else-if="profileImage" :src="profileImage")

      .username {{ userName }}
      .time {{ msgTime }}
      .pinned-mark(v-if="item.isPinned")
        fa.fa-icon(:icon="['fas', 'thumbtack']" fixed-width)
        | &nbsp;Pinned

    .msg-content(ref="contentBlock", :style="fontSize")

      .resize-sensor(ref="sensor")

      //- Is a child in an existing conversation thread
      slack-thread-root(v-if="item.root", :root="item.root", :class="{'reacted-only': reactedOnly}")

      //- Is a file comment
      .comment-target(v-if="item.isComment && event.file")
        span.hint Commented on
        a.file-link(:src="event.file.permalink") {{ event.file.title }}

      //- Main Text
      .text(v-if="mainText && mainText.length"
            v-html="mainText"
            :class="{'system-msg': item.isSystemMessage, 'is-comment': item.isComment, 'is-edited': item.isEdited }")

      //- Attachments (URLs etc)
      .attachements(v-if="item.attachments && item.attachments.length")
        .attach-item(v-for="atm in item.attachments", :key="atm.id")
          .pre-text(v-if="atm.pretext" v-html="formatText(atm.pretext)")

          .detail-wrapper(:style="borderColor(atm)")
            .left

              .service-name(v-if="atm.service_name")
                img.icon(v-if="isHTTPS(atm.service_icon)", :src="atm.service_icon")
                .name {{ atm.service_name }}

              .service-title(v-if="atm.title")
                a(v-if="atm.from_url" :href="atm.from_url") {{ formatText(atm.title.trim()) }}
                span(v-else v-html="formatText(atm.title.trim())")

              .auther-info(v-if="atm.author_name || atm.author_subname")
                img.auther-icon(v-if="isHTTPS(atm.author_icon)" :src="atm.author_icon")
                .author-name {{ atm.author_name || atm.author_subname }}

              .service-description(v-if="atm.text" v-html="formatText(atm.text)")

              .service-image(v-if="isHTTPS(atm.image_url)")
                img.service-image(:src="atm.image_url")

              //- Legacy Bot Message Fields
              .fields-container.legacy(v-if="atm.fields && atm.fields.length")
                .block(v-for="(field, index) in atm.fields", :key="index", :class="{short: field.short}")
                  .block-title(v-if="field.title && field.title.length" v-html="formatText(field.title)")
                  .block-value(v-html="formatText(field.value)")
              //- New Bot Message Blocks
              .fields-container(v-if="atm.blocks && atm.blocks.length")
                template(v-for="block in atm.blocks")
                  //- Nested fields inside a block
                  template(v-if="block.fields && block.fields.length")
                    .block(v-for="(bField, findex) in block.fields", :key="findex")
                      .block-value(v-if="bField.text && bField.text.length" v-html="formatText(bField.text)")
                  //- Regular block
                  .block(v-else-if="block.type === 'section'", :key="block.block_id")
                    .block-value(v-if="block.text && block.text.text && block.text.text.length" v-html="formatText(block.text.text)")

              //- Files in attachements
              .atm-files(v-if="atm.files && atm.files.length")
                template(v-for="file in atm.files")
                  .file-container(v-if="isImage(file)" :key="file.id")
                    img(:src="getImageFile(file)")
                  .file-container(v-else-if="isVideo(file)" :key="file.id")
                    video(:src="getVideoFile(file)" loop autoplay controls muted playsinline)
                  .file-name-only(v-else :key="file.id") {{file.pretty_type}}: {{file.name || file.title}}

            .right(v-if="isHTTPS(atm.thumb_url)")
              img.service-thumb(:src="atm.thumb_url")

      //- File Share
      .file-shared(v-if="item.files && item.files.length")
        template(v-for="file in item.files")
          .file-container(v-if="isImage(file)" :key="file.id")
            img(:src="getImageFile(file)")
          .file-container(v-else-if="isVideo(file)" :key="file.id")
            video(:src="getVideoFile(file)" loop autoplay controls muted playsinline)
          .file-name-only(v-else :key="file.id") {{file.pretty_type}}: {{file.name || file.title}}

      //- Reactions
      .reactions(v-if="!reactedOnly && item.reactions && item.reactions.length")
        .reaction(v-for="reaction in item.reactions", :key="reaction.name")
          .inner
            .emoji {{ reaction.emoji }}
            .count {{ reaction.count }}

      //- When current item is the Root item of a conversiton thread
      //-- A: Display child threads (to be deprecaed? not seeing this in latest event-data)
      .replies(v-if="item.threads")
        .reply-users(v-for="(reply, author) in item.threads.users", :key="author")
          img.author-icon(:src="reply.author_icon")
        .reply-footer {{ item.threads.info.footer }}
        .reply-time Last reply {{ formatTime(item.threads.info.reply_ts) }}
      //- B: Display replies count only
      .replies-count(v-if="!item.threads && item.reply_count")
        fa.fa-icon(:icon="['far', 'comment-alt']" fixed-width)
        template(v-if="item.reply_count === 1") {{ item.reply_count }} reply
        template(v-else) {{ item.reply_count }} replies

    .reaction-content(v-if="reactedOnly")
      template(v-if="item.reactions && item.reactions.length")
        //- Reactions
        .reactions
          .reaction(v-for="reaction in item.reactions", :key="reaction.name")
            .inner
              .emoji {{ reaction.emoji }}
              .count {{ reaction.count }}

        //- Reacted Users
        .reacted-users
          img.user(v-for="(userID, index) in reactedUsers", :key="index", :src="getUserImage(userID)")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

$reactedThemeFontColor = #fff
$pinnedBgColor = alpha(#fbd444, 0.3)
$pinnedHintColor = darken(#fbd444, 30%)

.slack-message-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 1em;
  padding: 0.5em 1.5em;
  overflow: hidden;

  &:not(.reacted-only) {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  a {
    color: var(--brand-primary);
    text-decoration: none;
  }

  img {
    border: 0;
    display: block;
  }

  code,
  pre {
    background: -black(0.05);
    border: 1px solid -black(0.1);
    border-radius: 0.3em;
    padding: 0.1em 0.4em;
    line-height: 120%;
    font-size: 0.8em;
  }

  pre {
    display: block;
    font-size: 0.7em;
    font-family: "Source Code Pro", "Monaco", "Inconsolata", monospace;
    line-height: 180%;
  }

  q {
    border-left: 0.3em solid -black(0.15);
    padding: 0.1em 0 0.1em 1em;
    margin: 0.2em 0;
    display: block;
  }

  b {
    font-weight: bold;
  }

  .user-image {
    margin-right: 1.8em;

    img {
      vertical-align: top;
      width: 4.5em;
      height: 4.5em;
      border-radius: 0.3em;
    }

    .emoji-avatar {
      width: 4.5em;
      line-height: 1;
      > span {
        font-size: 4.5em;
      }
    }
  }

  .username {
    color: var(--gray-dark);
  }

  .pinned-mark {
    color: $pinnedHintColor;
    padding-left: 1.5em;
  }

  .slack-message {
    flex: 1;
    overflow: hidden;
  }

  .meta-info {
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 0.6em;
    align-items: center;

    .time {
      opacity: 0.6;
      padding-left: 1.5em;
    }
  }

  .msg-content {
    overflow: hidden;
    position: relative;

    > .resize-sensor {
      position: absolute !important;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .text {
      color: var(--gray-dark);
      font-size: 1.8em;
      line-height: 140%;
      white-space: pre-wrap;

      &.system-msg {
        color: var(--gray);
      }

      &.is-comment {
        padding-left: 1.6em;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: '\f10d';
          font-family: 'FontAwesome';
          font-size: 1.2em;
          color: var(--gray-lighter);
        }
      }

      &.is-edited {
        &:after {
          content: '(edited)';
          color: var(--gray-light);
          font-size: 0.8em;
          padding-left: 0.8em;
        }
      }
    }

    // Attachments
    .attach-item {
      max-width: 960px;
      img {
        display: block;
      }

      .detail-wrapper {
        border-left: 0.3em solid -black(0.15);

        display: flex;
        flex-flow: row nowrap;
        align-content: flex-start;
        padding: 0 0 0 1em;
        margin: 0.7em 0 0.6em 0;

        .left {
          flex: 1;
        }

        .right {
          padding-left: 2em;
        }
      }

      .service-name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .icon {
          height: 0.9em;
          width: 0.9em;
          display: block;
          vertical-align: middle;
          margin-right: 0.3em;
        }

        .name {
          flex: 1;
          font-size: 1.1em;
        }
      }

      .service-title {
        padding: 0.3em 0 0.15em 0;
        font-size: 1.3em;
      }

      .auther-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0.5em;
        .auther-icon {
          width: 1.5em;
          height: 1.5em;
          border-radius: 0.2em;
          display: inline-block;
          overflow: hidden;
          margin-right: 0.6em;
        }
        .author-name {
          color: var(--gray-dark);
        }
      }

      .service-description {
        line-height: 180%;
        white-space: pre-wrap;
      }

      .service-image {
        padding: 0.5em 0 0.3em 0;

        .service-image {
          max-width: 20em;
          max-height: 12em;
        }
      }

      .service-thumb {
        height: 5em;
        width: 5em;
      }

      // Message fields

      .fields-container {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        .block {
          width: 100%;
          box-sizing: border-box;
          padding: 0.3em 1em 0.3em 0;
          font-size: 1.2em;
          margin-bottom: 0;

          &.short {
            width: 50%;
          }
        }

        .block-title {
          color: var(--gray-dark);
        }
      }

      // Attachment Pretexts

      .pre-text {
        font-size: 1.5em;
        color: var(--gray-dark);
        padding-bottom: 0.3em;
      }
    }

    // Reactions
    .reactions {
      padding: 0.2em 0 0.1em 0;
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      align-items: center;

      .reaction {
        border: 1px solid -black(0.15);
        border-radius: 0.3em;
        padding: 0.3em 0.6em 0.2em 0.4em;
        display: inline-block;
        margin-left: 0.5em;

        .inner {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .emoji {
          font-size: 1.1em;
        }

        .count {
          font-size: 1.2em;
          padding-left: 0.5em;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    // Replies (Under the Root item)
    .replies {
      padding: 0.3em 0 0.4em 0;
      font-size: 1.2em;
      line-height: 130%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .reply-users {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }

      .author-icon {
        width: 2em;
        height: 2em;
        border-radius: 0.2em;
        margin-right: 0.6em;
      }

      .reply-footer {
        color: var(--gray-dark);
      }

      .reply-time {
        opacity: 0.6;
        padding-left: 1em;
      }
    }

    .replies-count {
      font-size: 1.2em;
      padding-top: 0.2em;
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      .fa-icon {
        font-size: 1em;
        vertical-align: baseline;
        padding-right: 0.5em;
      }
    }

    // File Shared

    .file-shared,
    .atm-files {
      padding: 0.2em 0 0.1em 0;

      .file-container,
      .file-name-only {
        display: inline-block;
        background: -black(0.05);
        border: 1px solid -black(0.1);
        border-radius: 0.3em;

        &:not(:last-child) {
          margin-bottom: 0.5em;
        }
      }

      .file-container {
        padding: 0.05em;

        img {
          min-height: 30em;
          width: auto;
          max-width: 100%;
        }

        video {
          display: block;
          height: 30em;
          width: auto;
        }
      }

      .file-name-only {
        padding: 0.5em;
        font-size: 1.5em;
      }
    }

    // Comment Target File
    .comment-target {
      padding: 0.1em 0 0.4em 0;
      font-size: 1.2em;

      .hint {
        padding-right: 0.5em;
      }
    }
  }

// ===============
// Pinned
// ===============

  &.is-pinned {
    background: $pinnedBgColor
  }

// ===============
// Reacted only
// ===============

  &.reacted-only {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 3em 3em 3em 3em;

    .slack-message {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
    }

    .username {
      color: $reactedThemeFontColor;
    }

    .meta-info {
      padding-bottom: 1.5em;
      .username {
        font-size: 2.5em;
      }

      .time {
        padding-left: 1em;
        font-size: 2em;
      }

      // Displayed in Reactions Only mode
      .user-avatar {
        margin-right: 1.8em;

        img {
          width: 5em;
          height: 5em;
          border: 0.2em solid #fff;
          border-radius: 5em;
        }

        .emoji-avatar {
          width: 5em;
          line-height: 1;
          > span {
            font-size: 4.5em;
          }
        }
      }
    }

    .msg-content {
      flex: 1;
      .text {
        font-size: 3.5em;
        color: $reactedThemeFontColor;
      }

      .attach-item {
        .fields-container {
          .block-title {
            color: $reactedThemeFontColor;
          }
        }

        .pre-text {
          color: $reactedThemeFontColor;
        }
      }

      .replies {
        .reply-footer {
          color: $reactedThemeFontColor;
        }
      }
    }

    .reaction-content {
      display: flex;
      flex-flow: row nowrap;

      // Reactions
      .reactions {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: center;
        font-size: 2.5em;

        .reaction {
          border-radius: 0.2em;
          padding: 0.2em 0.6em 0.1em 0.4em;
          border: 2px solid -white(0.3);
          display: inline-block;
          margin-left: 0.5em;

          .inner {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
          }

          .emoji {
            color: $reactedThemeFontColor;
          }

          .count {
            padding-left: 0.5em;
            color: $reactedThemeFontColor;
          }

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .reacted-users {
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;

        img.user {
          width: 4em;
          height: 4em;
          border-radius: 5em;
          border: 0.2em solid #fff;
          margin-left: 1em;
        }
      }
    }
  }
}
</style>
