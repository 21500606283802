<script>
import qs from 'qs'
import { mapGetters } from 'vuex'
import { isEqual } from 'lodash'

import Media from 'services/media.js'
import ContentCache from 'services/contentCache.js'
import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'

import MediaZone from 'components/items/MediaZone.vue'
import { EventBus } from 'services/eventbus.js'

const config = {
  // DEV-3956
  WARN_LARGE_FOLDER: 100,
  INFO_LARGE_FOLDER: 50,

  // In ms. Fallback for cases when Player start offline -- WS never activated
  GET_TIMEOUT: 5000,

  // Grab more items per page [DEV-3123]
  PER_PAGE: 100
}

// Helper to sort 'qs.stringify' output for consistent key sequence to prevent unnecessary re-rendering
function alphabeticalSort (a, b) {
  return (a || '').localeCompare(b || '')
}

export default {
  name: 'MediaFolderItem',
  components: {
    MediaZone
  },

  props: {
    folderId: {
      type: String,
      default: ''
    },
    zoneOptions: {
      type: Object,
      default: () => ({})
    },
    gridZoneName: {
      type: String,
      default: ''
    },
    oneAtATime: {
      type: Boolean,
      default: false
    },
    reportItemPlayback: {
      type: Boolean,
      default: false
    },
    isAdFolder: {
      type: Boolean,
      default: false
    },
    readyForAd: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: true,
      isEmpty: false,
      staledListRendered: false,
      isItemStarted: false,

      zoneFinishedWhilePaused: false,

      zoneItem: {},
      // Publick URLs only
      urlList: [],
      // Media raw index in current page (before filtering by valid time or cached-only)
      indicesList: [],

      // Full content list for playback report
      contentList: [],
      // Store the last item info to prevent duplicate playback report
      lastReportedItem: {},

      adStartTime: -1,
      // Store the current page info for AD Folder
      // Fallback for manually page changes before the AD finishes
      adPageBak: {},

      errorMessage: '',

      refetchTimer: undefined,
      timeoutTimer: undefined,
      debounceTimer: undefined,
      debounceFetchTimer: undefined,
      debounceRecordTimer: undefined,

      perPage: +config.PER_PAGE,
      page: 1,
      totalItems: 0,
      received: 0
    }
  },

  computed: {
    ...mapGetters([
      'isPaused',
      'onlyOneVisiblePage',
      'currentPage',
      'currentPageName',
      'playlistCampaignID',
      'device',
      'isDevice',
      'deviceTagsSorted',
      'deviceVideoSync',
      'apiIsOffline'
    ]),

    hasError () {
      return Boolean(this.errorMessage && this.errorMessage.length)
    },

    localKeyBase () {
      return `ttvMediaFolder_${this.folderId}`
    },

    doOneAtATime () {
      return this.isAdFolder || this.oneAtATime
    },

    isOffline () {
      return this.apiIsOffline
    },

    needsCampaignLogging () {
      return this.isAdFolder && this.playlistCampaignID && this.playlistCampaignID.length
    }
  },

  watch: {
    isPaused (isTrue) {
      // Resume playback
      if (!isTrue && this.zoneFinishedWhilePaused) {
        this.zoneFinishedWhilePaused = false
        this.zoneFinished(true)
      }
    },

    folderId () {
      // Reset flag state on change
      clearTimeout(this.debounceRecordTimer)
      this.lastReportedItem = {}
      this.zoneFinishedWhilePaused = false
      this.debounceRender()
    },

    zoneOptions: {
      deep: true,
      handler (newVal, oldVal) {
        if (qs.stringify(newVal || {}, { sort: alphabeticalSort }) === qs.stringify(oldVal || {}, { sort: alphabeticalSort })) {
          // Skip re-rendering when the "newVal" and "oldValue" are the same
          return
        }
        // Reset flag state on change
        this.zoneFinishedWhilePaused = false
        this.debounceRender()
      }
    },

    isAdFolder () {
      this.debounceRender()
    },

    doOneAtATime () {
      this.debounceRender()
    },

    'currentPage.id' () {
      this.debounceForceRecordPlayingItem()
    }
  },

  mounted () {
    EventBus.$on('clear-media-folder-cache', this.forceRender)
    clearTimeout(this.refetchTimer)
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceFetchTimer)
    clearTimeout(this.debounceRecordTimer)
    this.debounceRender()
  },

  beforeDestroy () {
    EventBus.$off('clear-media-folder-cache', this.forceRender)
    // Force or manually swithcing, AD Duration not sent
    if (this.needsCampaignLogging && this.adStartTime > 0) {
      this.sendAdPlayTime(+this.adStartTime)
    }
    clearTimeout(this.refetchTimer)
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceFetchTimer)
    clearTimeout(this.debounceRecordTimer)
  },

  methods: {
    debounceRender () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.render()
      }, 200)
    },

    render () {
      if (!this.folderId || !this.folderId.length) { return }

      this.staledListRendered = false
      this.adStartTime = -1

      this.$nextTick(() => {
        this.debounceFetch(true)
      })
    },

    genLocalKey (fetchOptions = {}) {
      // The `fetchOptions.randomize` value becomes a random digit since DEV-2891
      // Changed it to `true` before saving in localStorage [DEV-3059]
      if (this.zoneOptions.randomize) {
        fetchOptions.randomize = true
      } else {
        delete fetchOptions.randomize
      }

      // "folder_id" already listed in `localKeyBase`
      delete fetchOptions.folder_id

      const optionsStr = Media.stringifiedQuery(fetchOptions)
      if (optionsStr && optionsStr.length) {
        return `${this.localKeyBase}_${optionsStr}`
      }
      return this.localKeyBase
    },

    genFetchOptions () {
      const fetchOptions = {
        folder_id: this.folderId,
        page: this.page,
        per_page: this.perPage
      }

      // [DEV-2628] Only do actual Device Tag matching when viewing on a real device
      if (this.isDevice && this.zoneOptions.match_device_tag) {
        fetchOptions.match_tags = true
        fetchOptions.tags = JSON.parse(JSON.stringify(this.deviceTagsSorted || []))
      }
      if (this.zoneOptions.randomize) {
        fetchOptions.randomize = true
      }
      if (this.zoneOptions.sub_folders) {
        fetchOptions.sub_folders = true
      }
      return fetchOptions
    },

    debounceFetch (start = false) {
      clearTimeout(this.debounceFetchTimer)
      this.debounceFetchTimer = setTimeout(() => {
        clearTimeout(this.debounceFetchTimer)
        this.fetchFolder(start)
      }, 200)
    },

    async fetchFolder (start = false) {
      clearTimeout(this.timeoutTimer)

      if (!this.folderId) { return }

      // Speed up display when device is offline
      if (this.isOffline) {
        this.getStaleList(start)
      }

      if (start) {
        this.isEmpty = false
        this.page = 1
        this.received = 0
        this.totalItems = -1
      } else {
        this.page += 1
      }

      const fetchOptions = this.genFetchOptions()

      // NOTE: Starting from DEV-4497,
      // Support recording last played index for  non-"one_at_a_time" folders
      const indices = await this.getNextItemAndPageNum(this.doOneAtATime)
      const nextIndexInPage = indices.nextIndexInPage || 0
      const currentPage = indices.currentPage || 1

      fetchOptions.page = currentPage
      this.page = currentPage

      this.timeoutChecking()
      this.$store.dispatch('getMediaByFolderId', fetchOptions).then(async (result) => {
        clearTimeout(this.timeoutTimer)

        // Code 416 or page index out of range [DEV-4431]
        if (result && result.outOfRange) {
          // Reset paginates
          this.doOneAtATime ? await this.removeOneAtATimePaginates() : await this.removeLastPlayedPaginates()
          // Do additional fetch
          this.fetchFolder(start)
          return
        }

        this.renderList(fetchOptions, result, nextIndexInPage, currentPage)
      }).catch(err => {
        clearTimeout(this.timeoutTimer)
        const errorMessage = err.message || err.toString() || ''
        Log.info('media', `Media Folder error: ${errorMessage}`, 'INF_MEDIAFOLDERERR', err)
        this.errorMessage = `Media Folder Error - ${errorMessage}`

        this.getStaleData()
      })
    },

    async getNextItemAndPageNum (oneAtATime = false) {
      const paginates = oneAtATime ? await this.getOneAtATimePaginates() : await this.getLastPlayedPaginates()

      let nextIndexInPage
      let currentPage

      // Valid Cache
      if (paginates && typeof paginates === 'object') {
        const totalItems = +paginates.totalItems || 0

        // [1, infinite)
        currentPage = Math.max(1, +paginates.pageNum || 1)

        // When folder is already playing during folder fetch in non-One-At-A-Time folder
        // Skip bumping item number to prevent fast forwarding
        const bumpPageStep = (!this.doOneAtATime && this.isItemStarted) ? 0 : 1
        // Bump item index -> [0, perPage]
        // Will sanitize the value in the latter steps
        nextIndexInPage = Math.min(this.perPage, Math.max(0, (+paginates.itemIndex + bumpPageStep) || 0))

        // Was an empty list when last cached
        if (totalItems <= 0) {
          currentPage = 1
          nextIndexInPage = 0
        // Non-empty list on last cached
        } else {
          // Next item index exceeded the total -> reset
          if (nextIndexInPage > totalItems - 1) {
            currentPage = 1
            nextIndexInPage = 0
          // Normal
          } else {
            if (
              // Next index reaches the end of current page
              nextIndexInPage > this.perPage - 1 ||
              // Next index reaches the end of the list
              this.perPage * (currentPage - 1) + nextIndexInPage > totalItems - 1
            ) {
              const totalPages = Math.ceil(totalItems / this.perPage)

              // Device is offline, skip increasing page number [#DEV-3059]
              if (this.isOffline) {
                // Sanitize only when needed
                if (currentPage > totalPages) {
                  currentPage = 1
                }
              // Device is online
              } else {
                // Flip page number
                currentPage = currentPage >= totalPages ? 1 : currentPage + 1
              }

              nextIndexInPage = 0
            }
          }
        }

      // Staled or empty cache
      } else {
        currentPage = 1
        nextIndexInPage = 0
      }

      return { nextIndexInPage, currentPage }
    },

    async renderList (fetchOptions, result, nextIndexInPage = 0, currentPage = 1) {
      // Raw content list, already randomized if needed
      const contentList = result.contents || []
      // Filter out items by valid time
      const validList = Media.filterByValidTime(contentList)
      // Add flags but do not filter out items -- keep the indices intact for "one_at_a_time"
      const resultList = Media.flagByValidTime(contentList)

      // Total items (sum of multiple pages' count)
      const totalItems = result.total || 0
      // Items count on this page
      const count = (Array.isArray(contentList) && contentList.length) || 0
      // Valid items count
      const validCount = validList.length || 0

      // Debug for DEV-3956 in device only (skip in editor and preview)
      if (this.isDevice) {
        let logMsg = this.isAdFolder ? 'Injected AD Media Folder ' : 'Media Folder '
        logMsg += `on page "${this.currentPageName}" for grid zone "${this.gridZoneName}" contains ${totalItems} items`

        if (totalItems >= config.WARN_LARGE_FOLDER) {
          logMsg += '. Please make sure the devices have enough storage to play through'
        }

        logMsg += validCount < count ? ` (${validCount}/${count} on the current page)` : ` (${count} on the current page)`

        logMsg += ` - folder: ${this.folderId} `
        if (this.zoneOptions.sub_folders) {
          logMsg += ', subFolders: true'
        }
        if (this.zoneOptions.match_device_tag) {
          logMsg += `, matchTags: ${JSON.stringify(fetchOptions.tags || [])}`
        }
        if (this.zoneOptions.randomize) {
          logMsg += ', randomize: true'
        }
        if (this.doOneAtATime) {
          logMsg += `, onePerTime: true, pageNum: ${currentPage}, nextItem: ${nextIndexInPage}`
        }

        if (totalItems >= config.WARN_LARGE_FOLDER) {
          Log.warn('media', logMsg, 'WAR_LARGEFOLDER')
        } else if (totalItems >= config.INFO_LARGE_FOLDER) {
          Log.info('media', logMsg, 'INF_LARGEFOLDER')
        } else {
          Log.debug('media', logMsg, 'DBG_LARGEFOLDER')
        }
      }

      this.contentList = contentList.map(item => {
        if (!item?.id) { return {} }
        return {
          id: item.id,
          content_folder_id: item.content_folder_id,
          name: item.name,
          index: item.index,
          url: item.public_urls && item.public_urls.length
            ? item.public_urls.join(',')
            : item.public_url
        }
      })

      if (count === 0 || validCount === 0) {
        this.forceAdvance(count === 0 ? 'renderList count 0' : 'renderList validCount 0')
        this.isEmpty = true
        this.loading = false
        // Sent warning to device logs [DEV-4340]
        if (this.zoneOptions.match_device_tag) {
          this.sendNoMatchLog(fetchOptions)
        }
        return
      } else {
        this.errorMessage = ''
      }

      if (this.page > 1) {
        this.received = (this.perPage * (this.page - 1)) + count
      } else {
        this.received = count
      }

      this.totalItems = totalItems

      const useCachedFiles = this.useCachedFiles()
      const allowUncachedImages = !this.isOffline

      let mediaList = []

      // When next-index doesn't point to a valid item, start from 0 again -- Fallback for media folder changes
      // Note: Use the flagged "resultList" to get the correct index
      if (!resultList[nextIndexInPage]) {
        nextIndexInPage = 0
      }

      // "one_at_a_time" is requried
      if (this.doOneAtATime) {
        // Item to show in this round
        // - Filter cached-only files
        if (useCachedFiles) {
          const cachedData = ContentCache.getNextCachedItem(resultList, nextIndexInPage, allowUncachedImages, true)
          if (cachedData && cachedData.item) {
            mediaList = [cachedData.item]
            // Update the index from filtered result
            nextIndexInPage = cachedData.index
          } else {
            mediaList = []
            nextIndexInPage = +count
          }
        // - Filter not needed
        } else {
          const nextValidIndex = resultList.findIndex((item, idx) => {
            return (idx >= nextIndexInPage && item.isValid)
          })

          // Valid item found before the end of the list
          if (nextValidIndex !== -1) {
            mediaList = resultList.slice(nextValidIndex, nextValidIndex + 1)
            nextIndexInPage = +nextValidIndex
          // No valid item found till the end of list
          } else {
            mediaList = []
            nextIndexInPage = +count
          }
        }

        // Just store the current paginates and total count.
        // No longer do value bumping here. Calculate and sanitize values in the next round.
        await this.setCurrentOneAtATimePaginates(nextIndexInPage, currentPage, totalItems)

      // Not "one_at_a_time"
      } else {
        // Trim the list, from the next index to the end
        const remainingList = resultList.slice(nextIndexInPage) || []

        // - Filter cached-only files
        if (useCachedFiles) {
          mediaList = ContentCache.getCachedItemsOnly(remainingList, allowUncachedImages, true)
        // - Filter not needed
        } else {
          mediaList = Media.filterByValidTime(remainingList)
        }
        // Store the current paginates and total count.
        // Calculate and sanitize values in the next round.
        nextIndexInPage = (mediaList.length > 0) ? mediaList[0].index : +count
        await this.setLastPlayedPaginates(nextIndexInPage, currentPage, totalItems)
      }

      // Double check valid media list length after filtering
      if (!mediaList.length) {
        const errorMessage = `Media Folder: no cached nor valid items found in grid zone "${this.gridZoneName}" (folder: ${this.folderId})`
        Log.info('media', errorMessage, 'INF_NOMEDIAGRIDZONE')
        this.errorMessage = errorMessage

        this.forceAdvance('renderList no cached nor valid items found')
        this.loading = false
        return
      }

      const urlList = []
      const indicesList = []

      mediaList.forEach(m => {
        if (!m) { return }
        // Image and Video
        if (m.public_url && m.public_url.length) {
          urlList.push(m.subtitles ? `${m.public_url}?s=${m.subtitles.url}` : m.public_url)
          indicesList.push(m.index || 0)
        // Slides
        } else if (m.public_urls && m.public_urls.length) {
          urlList.push(m.public_urls.join(','))
          indicesList.push(m.index || 0)
        }
      })

      const zoneItem = JSON.parse(JSON.stringify(this.zoneOptions || {}))
      zoneItem.items = urlList
      zoneItem.indices = indicesList

      this.zoneItem = {
        url: qs.stringify(zoneItem, { sort: alphabeticalSort }),
        grid_area: this.gridZoneName + ''
      }
      this.urlList = urlList
      this.indicesList = indicesList

      this.setStaleData(fetchOptions, zoneItem)

      this.loading = false
    },

    setStaleData (fetchOptions, data) {
      const cloned = JSON.parse(JSON.stringify(fetchOptions))
      delete cloned.page
      delete cloned.per_page
      const localKey = this.genLocalKey(cloned)
      OfflineCaches.set(localKey, data)
    },

    // MediaFolder list data
    async getStaleList (init = false) {
      const fetchOptions = this.genFetchOptions()
      const localKey = Media.genMediaFolderListKey(fetchOptions)

      if (localKey) {
        const staleList = await OfflineCaches.get(localKey)

        if (!staleList || !staleList.byPage) {
          if (!init) {
            this.getStaleData()
          }
          return
        }

        // page number starts with `1`
        const index = fetchOptions.page - 1

        const targetPageResult = staleList.byPage[index]
        if (!targetPageResult || !targetPageResult.length) {
          if (!init) {
            this.getStaleData()
          }
          return
        }

        if (init) {
          clearTimeout(this.timeoutTimer)
          this.staledListRendered = true
        }

        const result = {
          contents: targetPageResult,
          total: +staleList.total
        }

        // Starting from DEV-4497,
        // Support recording last played index for  non-"one_at_a_time" folders
        const indices = await this.getNextItemAndPageNum(this.doOneAtATime)
        const nextIndexInPage = indices.nextIndexInPage || 0
        const currentPage = indices.currentPage || 1

        this.renderList(fetchOptions, result, nextIndexInPage, currentPage)
      }
    },

    // Options for MediaZone
    async getStaleData (init = false) {
      const fetchOptions = this.genFetchOptions()
      delete fetchOptions.page
      delete fetchOptions.per_page
      const localKey = this.genLocalKey(fetchOptions)

      if (localKey) {
        const staleData = await OfflineCaches.get(localKey)
        // Stale Data found
        if (staleData) {
          this.zoneItem = {
            url: qs.stringify(staleData),
            grid_area: this.gridZoneName + ''
          }
          this.urlList = staleData.items || []
          this.indicesList = staleData.indices || []

          if (this.urlList.length) {
            // We got urls from stale data
            this.errorMessage = ''
            this.isEmpty = false
          } else {
            // No media found
            if (!init) {
              this.forceAdvance('getStaleData urlList empty')
              this.errorMessage = ''
              this.isEmpty = true
              if (this.zoneOptions.match_device_tag) {
                this.sendNoMatchLog(fetchOptions, true)
              }
            }
          }
        // No stale data
        } else {
          if (!init) {
            this.forceAdvance('getStaleData no stale data')
          }
        }
      }
      if (!init) {
        this.loading = false
      }
    },

    genSharedOptsKey () {
      let result = ''
      if (this.zoneOptions.randomize) {
        result += '_random'
      }
      if (this.zoneOptions.sub_folders) {
        result += '_withSubFolders'
      }
      if (this.isDevice && this.zoneOptions.match_device_tag) {
        result += '_matchDeviceTags'
        if (this.deviceTagsSorted && this.deviceTagsSorted.length > 0) {
          result += `_${this.deviceTagsSorted.join(',')}`
        }
      }
      return result
    },

    genOneAtATimeKey () {
      if (!this.folderId) { return }
      let result = `${this.localKeyBase}${this.isAdFolder ? '_AD' : '_ONE-A-TIME'}`
      result += this.genSharedOptsKey()
      return result
    },

    async setCurrentOneAtATimePaginates (itemIndex = 0, pageNum = 1, totalItems = 0) {
      const storeKey = this.genOneAtATimeKey()
      if (storeKey && storeKey.length) {
        await OfflineCaches.set(storeKey, { itemIndex, pageNum, totalItems })
      }
    },

    async getOneAtATimePaginates () {
      const storeKey = this.genOneAtATimeKey()
      if (storeKey && storeKey.length) {
        const result = await OfflineCaches.get(storeKey)
        return result || false
      }
      return false
    },

    async removeOneAtATimePaginates () {
      const storeKey = this.genOneAtATimeKey()
      if (storeKey && storeKey.length) {
        await OfflineCaches.remove(storeKey)
      }
    },

    genLastPlayedKey () {
      if (!this.folderId) { return }
      let result = `${this.localKeyBase}_LAST-PLAYED`
      result += this.genSharedOptsKey()
      return result
    },

    async setLastPlayedPaginates (itemIndex = 0, pageNum = 1, totalItems = 0) {
      const storeKey = this.genLastPlayedKey()
      if (storeKey && storeKey.length) {
        await OfflineCaches.set(storeKey, { itemIndex, pageNum, totalItems })
      }
    },

    async getLastPlayedPaginates () {
      const storeKey = this.genLastPlayedKey()
      if (storeKey && storeKey.length) {
        const result = await OfflineCaches.get(storeKey)
        return result || false
      }
      return false
    },

    async removeLastPlayedPaginates () {
      const storeKey = this.genLastPlayedKey()
      if (storeKey && storeKey.length) {
        await OfflineCaches.remove(storeKey)
      }
    },

    useCachedFiles () {
      if (this.isOffline) {
        // Force to use cached files when device is offline [DEV-3122]
        return true
      }
      // Force to use cached videos outside the Device Video Sync timeframe
      if (this.deviceVideoSync && !Media.inVideoSyncTime(this.deviceVideoSync)) {
        return true
      }
      // Force to use cached videos when the Device met plenty of internal cache errors [DEV-4523]
      if (ContentCache.forceCachedPlayback) {
        return true
      }
      return false
    },

    forceAdvance (reason = '') {
      clearTimeout(this.timeoutTimer)
      this.$emit('force-advance', reason)
    },

    async itemStarted (itemIndex) {
      this.isItemStarted = true
      // One At A Time and AD Folders are handled by "...CurrentOneAtATimePaginates"
      if (!this.doOneAtATime && !this.isAdFolder) {
        const record = await this.getLastPlayedPaginates()
        this.setLastPlayedPaginates(+itemIndex || 0, record?.pageNum, record?.totalItems)
      }
      this.$emit('item-started')
    },

    itemFinished () {
      if (!this.isAdFolder) {
        this.$emit('item-finished')
      }
      // NOTE: AD Folder should wait for "finished"
    },

    zoneFinished (allContentsFinished = false) {
      if (this.received >= this.totalItems) {
        allContentsFinished = true
      }

      if (this.needsCampaignLogging && this.adStartTime > 0) {
        this.sendAdPlayTime(+this.adStartTime)
      }

      if (this.doOneAtATime || allContentsFinished) {
        const isAdFolder = this.isAdFolder ? 'AD ' : ''
        const msgBase = `Media Folder: ${isAdFolder}Contents playback finished on page "${this.currentPageName}" zone "${this.gridZoneName}"`

        // Playlist is paused
        if (this.isPaused) {
          // NOTE: Show warning level for debug
          Log.warn('media', `${msgBase}. Skipping the "finished" event because the Player is in paused mode (OnePerTime: ${this.doOneAtATime}, received: ${this.received}, total: ${this.totalItems}, folder: ${this.folderId})`, 'WAR_PLAYLISTPAUSED')
          this.zoneFinishedWhilePaused = true
          return
        }

        // Reset the "itemStarted" state to prevent fast-jumping itemIndex after re-fetching for the new list
        this.isItemStarted = false

        Log.info('media', `${msgBase}. Trigger the "finished" event. (OnePerTime: ${this.doOneAtATime}, received: ${this.received}, total: ${this.totalItems}, folder: ${this.folderId})`, 'INF_ZONEMEDIAFINISHED')

        // Simply forward the "finished" event here
        // Check "Auto-Advance" in the parent component "MediaFolder"
        this.$emit('finished')

        // Prevent fetching new items when set to show "One At A Time" [DEV-2977]
        // NOTE: Ad Folder always has doOneAtATime [DEV-4497]
        if (this.doOneAtATime) {
          if (!this.isAdFolder) {
            Log.debug('media', `Media Folder: skip fetching new items for "One At A Time" config on page "${this.currentPageName}" zone "${this.gridZoneName}" (folder: ${this.folderId}, onlyVisiblePage: ${this.onlyOneVisiblePage})`, 'DBG_SKIPFETCHNEWITEM')
          }
          return
        }

        // Re-fetch folder contents from the 1st page for non-One-at-a-time folders
        clearTimeout(this.refetchTimer)
        this.refetchTimer = setTimeout(() => {
          clearTimeout(this.refetchTimer)
          Log.debug('media', `Media Folder: all folder items are finished on page "${this.currentPageName}" zone "${this.gridZoneName}". About to re-fetch folder data from the start. (folder: ${this.folderId})`, 'DBG_REFETCHMEDIAFOLDER')
          this.fetchFolder(true)
        }, 200)
      } else {
        // Fetch the next page of the Media Folder list
        Log.info('media', `Media Folder: paginate list (p${this.page}) finished on page "${this.currentPageName}" zone "${this.gridZoneName}". About get the next list. (OnePerTime: ${this.doOneAtATime}, received: ${this.received}, total: ${this.totalItems}, folder: ${this.folderId})`, 'INF_MEDIAFOLDERFINISHED')
        this.fetchFolder()
      }
    },

    sendNoMatchLog (fetchOptions = {}, isOffline = false) {
      if (!this.zoneOptions.match_device_tag) { return }
      let noMatchMsg = `Media Folder: No media matching this device tags: ${JSON.stringify(fetchOptions.tags || [])}, folder: ${this.folderId}`
      if (this.zoneOptions.sub_folders) {
        noMatchMsg += ', subFolders: true'
      }
      if (this.isAdFolder) {
        noMatchMsg += ', isAdFolder: true'
      }
      if (isOffline) {
        noMatchMsg += '[offline mode]'
      } else if (this.useCachedFiles()) {
        // Could be outside the Device Video Sync timeframe,
        // or the device met plenty of internal cache errors
        noMatchMsg += '[cached files only]'
      }
      noMatchMsg += ` (page "${this.currentPageName}", zone "${this.gridZoneName}")`
      const logKey = isOffline ? 'WAR_MDFOLDERNOMATCHOFFLINE' : 'WAR_MDFOLDERNOMATCH'
      Log.warn('media', noMatchMsg, logKey)
    },

    loaded () {
      if (this.needsCampaignLogging) {
        this.adStartTime = Date.now() / 1000
        this.adPageBak = {
          id: this.currentPage.id,
          description: this.currentPage.description,
          playlist_id: this.currentPage.playlist_id,
          campaign_id: this.playlistCampaignID
        }
      } else {
        this.adStartTime = -1
        this.adPageBak = {}
      }
      this.$emit('loaded')
    },

    sendDuration (duration) {
      this.$emit('duration', duration)
    },

    sendAdPlayTime (adStartTime = 0) {
      if (!this.isAdFolder || adStartTime <= 0) { return }
      this.$emit('ad-played', {
        startTime: +adStartTime,
        endTime: Date.now() / 1000,
        page: JSON.parse(JSON.stringify(this.adPageBak))
      })
      this.adStartTime = -1
    },

    debounceForceRecordPlayingItem () {
      clearTimeout(this.debounceRecordTimer)
      if (!this.reportItemPlayback) { return }

      this.debounceRecordTimer = setTimeout(() => {
        clearTimeout(this.debounceRecordTimer)
        if (!this.reportItemPlayback) { return }
        if (
          this.$refs?.mediaZone?.forceResendPlaybackItemReport &&
          typeof this.$refs.mediaZone.forceResendPlaybackItemReport === 'function'
        ) {
          this.$refs.mediaZone.forceResendPlaybackItemReport()
        }
      }, 500)
    },

    recordPlayingItem (url = '') {
      if (!this.reportItemPlayback || !url || !this.currentPage?.id) { return }

      const currentItem = this.contentList.find(item => item.url === url) || {}
      if (!currentItem?.id) { return }

      const report = {
        media: {
          id: currentItem.id,
          name: currentItem.name
        },
        page: {
          id: this.currentPage.id,
          description: this.currentPage.description,
          playlist_id: this.currentPage.playlist_id
        }
      }

      if (this.lastReportedItem && isEqual(this.lastReportedItem, report)) {
        // Skip reporting the same item playback on the same page
        return
      }

      this.lastReportedItem = JSON.parse(JSON.stringify(report))
      Log.sendMediaItemReport(report)
    },

    // Fallbak for cases when Player starting offline [DEV-2635]
    timeoutChecking () {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        const errorMessage = `Media Folder fetching ${config.GET_TIMEOUT}ms timeout`
        Log.info('media', `Media Folder error: ${errorMessage}`, 'INF_MEDIAFOLDERERR2')
        this.errorMessage = `Media Folder Error - ${errorMessage}`

        // When device is offline, the timeout checking sometimes comes before GET error
        if (!this.staledListRendered) {
          this.getStaleList()
        }
      }, config.GET_TIMEOUT)
    },

    forceRender (folderId) {
      if (!folderId) { return }
      if (folderId === this.folderId) {
        this.debounceRender()
      }
    }
  }
}
</script>
<template lang="pug">
section.media-folder-item
  media-zone(v-if="!loading && !isEmpty"
              ref="mediaZone"
              :item="zoneItem"
              :one-at-a-time="oneAtATime"
              :ready-for-ad="readyForAd"
              :is-ad-folder="isAdFolder"
              :folder-total-items="totalItems"
              :report-item-playback="reportItemPlayback"
              is-media-folder
              active
              @item-started="itemStarted"
              @item-finished="itemFinished"
              @playing-item="recordPlayingItem"
              @finished="zoneFinished"
              @loaded="loaded"
              @duration="sendDuration")

  //- No shared media found
  transition(name="fade" appear)
    .messages(v-if="!loading && isEmpty && hasError")
      p {{ errorMessage }}
    .messages(v-else-if="!loading && isEmpty")
      p(v-if="zoneOptions.match_device_tag") No media matching this device tags found in folder
      p(v-else) No media found in this folder
</template>

<style lang="stylus">
section.media-folder-item
  width: 100%
  height: 100%
  position: absolute

  .media-zone
    z-index: 5

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 4vmin
</style>
