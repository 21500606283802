<script>
import { max as Max, sortBy as SortBy } from 'lodash'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import BarchartBar from './BarchartBar.vue'
import NumberValue from './NumberValue.vue'

const config = {
  DEFAULT_MAX: 100,
  DEFAULT_MIN: 0,

  // [0, 1), Max width for barchart label
  LABEL_MAX_WIDTH: 0.4,

  // in px, minimum width to show value bars
  BAR_MIN_WIDTH: 40,

  // String. Value cell min-width.
  // In order to show `...` for numbers in small size, this setting will be ignored when value bar is hidden.
  VALUE_MIN_WIDTH: '2.7em'
}

export default {
  name: 'MetricsBarchartItem',
  components: { BarchartBar, NumberValue },

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    sortByValue: { type: Boolean, default: false },
    isPaginated: { type: Boolean, default: false },
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      labelStyle: {},
      barStyle: {},
      maxBarNumber: 0,

      debounceTimer: undefined
    }
  },

  computed: {
    dynamicMax () {
      return Boolean(this.item.track && this.item.track.max && this.item.track.max.type === 'dynamic')
    },

    max () {
      if (this.dynamicMax) {
        return this.getMaxValue() || config.DEFAULT_MAX
      }
      return (this.item.track && this.item.track.max && this.item.track.max.value) || config.DEFAULT_MAX
    },

    min () {
      return (this.item.track && this.item.track.min && this.item.track.min.value) || config.DEFAULT_MIN
    },

    bars () {
      if (!this.item || !this.item.bars || !this.item.bars.length) { return [] }
      if (!this.sortByValue) {
        return Object.freeze(this.item.bars)
      }
      const bars = JSON.parse(JSON.stringify(this.item.bars))
      return Object.freeze(SortBy(bars, [(bar) => bar.number.value]).reverse())
    },

    valueStyle () {
      if (this.barStyle.visibility && this.barStyle.visibility === 'hidden') {
        return { minWidth: 'none' }
      } else {
        return { minWidth: config.VALUE_MIN_WIDTH }
      }
    },

    barColor () {
      if (!this.item || !this.item.track || !this.item.track.color) { return }
      return this.item.track.color
    }
  },

  watch: {
    bars: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.debounceCheckSize()
        })
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.$nextTick(() => {
      this.debounceCheckSize()
    })
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
  },

  methods: {
    getMaxValue () {
      if (!this.item || !this.item.bars || !this.item.bars.length) { return }
      // [bugfix/DEV-1670] metric display wrong maximum with dynamic option because some metric return string value instead of number
      const values = this.item.bars.map(bar => +bar.number.value)
      return Max(values)
    },

    debounceCheckSize () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizeHandler()
      }, 200)
    },

    resizeHandler () {
      if (!this.$el || !this.$refs.sensor || !this.bars || !this.bars.length) { return }

      const self = this
      const barElms = this.$el.querySelectorAll('td.bar')

      let offsetHeight
      let offsetWidth
      let firstBarWidth
      let firstBarHeight

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!self.$refs || !self.$refs.sensor || !barElms || !barElms.length) {
          FastDom.clear(measure)
          return
        }

        offsetHeight = self.$refs.sensor.offsetHeight
        offsetWidth = self.$refs.sensor.offsetWidth
        firstBarHeight = barElms[0].offsetHeight

        if (offsetHeight === 0 || offsetWidth === 0) {
          self.debounceCheckSize()
          FastDom.clear(measure)
          return
        }

        const mutate = FastDom.mutate(() => {
          const maxBarNumber = ~~(offsetHeight / firstBarHeight)
          self.maxBarNumber = maxBarNumber

          self.labelStyle = {
            maxWidth: offsetWidth * config.LABEL_MAX_WIDTH + 'px'
          }

          const measure2 = FastDom.measure(() => {
            // Fallback double check for ChromeOS
            if (!barElms[0]) {
              FastDom.clear(measure2)
              return
            }

            firstBarWidth = barElms[0].offsetWidth

            const mutate2 = FastDom.mutate(() => {
              // Fallback double check for ChromeOS
              if (!firstBarWidth) {
                FastDom.clear(mutate2)
                return
              }

              const showBar = firstBarWidth >= config.BAR_MIN_WIDTH

              self.barStyle = {
                visibility: showBar ? 'visible' : 'hidden'
              }

              FastDom.clear(mutate2)
            })

            FastDom.clear(measure2)
          })

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    }
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-barchart-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}

  .item-body.app-context-section.primary
    .resize-sensor(ref="sensor")

    table.bar-table
      tbody
        tr.bar-item(v-for="(bar, index) in bars", :class="{hidden: index >= maxBarNumber}", :key="index")
          td.bar-label(:style="labelStyle")
            .label-text(v-if="index < maxBarNumber") {{ bar.label.text }}
          td.bar(:style="barStyle")
            barchart-bar(v-if="index < maxBarNumber"
                         :min="min"
                         :max="max"
                         :value="bar.number.value"
                         :color="barColor"
                         :is-static="isPaginated"
                         :light-theme="lightTheme")
          td.bar-number(:style="valueStyle")
            number-value.bar-number-value(v-if="index < maxBarNumber"
                        :number-data="bar.number || {}"
                        :is-static="isPaginated")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'
$rowSpacing = 0.5em

.metrics-barchart-item
  .item-body
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch
    position: relative

    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: 0
      bottom: 0
      left: 0
      right: 0

    .bar-table
      width: 100%
      border-collapse: collapse

    .bar-item
      position: relative

      &.hidden
        visibility: hidden
        opacity: 0

      > td
        padding-top: ($rowSpacing / 2)
        padding-bottom: ($rowSpacing / 2)
        box-sizing: border-box

    .bar-label,
    .bar-number
      vertical-align: middle

    .bar-label
      overflow: hidden
      text-align: right
      padding-right: 0.8em
      max-width: 40%

      .label-text
        vertical-align: middle
        ellipsis()

    .bar
      width: 100%
      height: 2em
      position: relative

      .barchart-single-bar
        top: ($rowSpacing / 2)
        bottom: ($rowSpacing / 2)

    .bar-number
      white-space: nowrap
      padding-left: 0.8em
      min-width: 2.7em

      font-weight: 600

      .digit
        font-weight: 700
</style>
