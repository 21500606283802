:root {
  --background-gray: #27272b;
  --gray-darker: #161820;
  --gray-dark: #1E202B;
  --gray-mid: #2f3241;
  --gray: #787980;
  --gray-light: #A5A6AA;
  --gray-lighter: #D2D2D5;
  --gray-lightest: #F5F5F5;
  --brand-primary: #2AA4E5;
  --brand-success: #5cbf63;
  --brand-info: #55B6EA;
  --brand-warning: #fbd444;
  --brand-danger: #Fc5543;
  --online-green: #2fa927;
}