/* Exo */

@font-face {
  font-family: "Exo";
  font-weight: 400;
  src: url("../assets/fonts/exo-v4-latin-regular.woff2") format('woff2');
}

@font-face {
  font-family: "Exo";
  font-weight: 200;
  src: url("../assets/fonts/exo-v4-latin-200.woff2") format('woff2');
}

@font-face {
  font-family: "Exo";
  font-weight: 600;
  src: url("../assets/fonts/exo-v4-latin-600.woff2") format('woff2');
}

@font-face {
  font-family: "Exo";
  font-weight: 800;
  src: url("../assets/fonts/exo-v4-latin-800.woff2") format('woff2');
}

/* Open Sans */

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v13-latin-regular.woff2") format('woff2');
}

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  src: url("../assets/fonts/open-sans-v13-latin-300.woff2") format('woff2');
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: url("../assets/fonts/open-sans-v13-latin-600.woff2") format('woff2');
}

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  src: url("../assets/fonts/open-sans-v13-latin-800.woff2") format('woff2');
}

/* ===== Fonts for Themes ===== */

/* Marmelad */

@font-face {
  font-family: "Marmelad";
  src: url("https://resources.telemetrytv.com/fonts/marmelad-v7-latin-regular.woff2") format('woff2');
}
@font-face {
  font-family: "Marmelad QA";
  src: url("https://qa-resources.telemetrytv.com/fonts/marmelad-v7-latin-regular.woff2") format('woff2');
}

/* Belleza */

@font-face {
  font-family: "Belleza";
  src: url("https://resources.telemetrytv.com/fonts/belleza-v6-latin-regular.woff2") format('woff2');
}
@font-face {
  font-family: "Belleza QA";
  src: url("https://qa-resources.telemetrytv.com/fonts/belleza-v6-latin-regular.woff2") format('woff2');
}

/* Catamaran */

@font-face {
  font-family: "Catamaran";
  font-weight: normal;
  src: url("https://resources.telemetrytv.com/fonts/catamaran-v4-latin-500.woff2") format('woff2');
}
@font-face {
  font-family: "Catamaran QA";
  font-weight: normal;
  src: url("https://qa-resources.telemetrytv.com/fonts/catamaran-v4-latin-500.woff2") format('woff2');
}

@font-face {
  font-family: "Catamaran";
  font-weight: bold;
  src: url("https://resources.telemetrytv.com/fonts/catamaran-v4-latin-700.woff2") format('woff2');
}
@font-face {
  font-family: "Catamaran QA";
  font-weight: bold;
  src: url("https://qa-resources.telemetrytv.com/fonts/catamaran-v4-latin-700.woff2") format('woff2');
}

/* Cairo */

@font-face {
  font-family: "Cairo";
  font-weight: normal;
  src: url("https://resources.telemetrytv.com/fonts/cairo-v4-latin-regular.woff2") format('woff2');
}
@font-face {
  font-family: "Cairo QA";
  font-weight: normal;
  src: url("https://qa-resources.telemetrytv.com/fonts/cairo-v4-latin-regular.woff2") format('woff2');
}

@font-face {
  font-family: "Cairo";
  font-weight: bold;
  src: url("https://resources.telemetrytv.com/fonts/cairo-v4-latin-700.woff2") format('woff2');
}
@font-face {
  font-family: "Cairo QA";
  font-weight: bold;
  src: url("https://rqa-esources.telemetrytv.com/fonts/cairo-v4-latin-700.woff2") format('woff2');
}

/* Patua One */

@font-face {
  font-family: "Patua One";
  src: url("https://resources.telemetrytv.com/fonts/patua-one-v7-latin-regular.woff2") format('woff2');
}
@font-face {
  font-family: "Patua One QA";
  src: url("https://qa-resources.telemetrytv.com/fonts/patua-one-v7-latin-regular.woff2") format('woff2');
}

/* Play */

@font-face {
  font-family: "Play";
  font-weight: normal;
  src: url("https://resources.telemetrytv.com/fonts/play-v9-latin-regular.woff2") format('woff2');
}
@font-face {
  font-family: "Play QA";
  font-weight: normal;
  src: url("https://qa-resources.telemetrytv.com/fonts/play-v9-latin-regular.woff2") format('woff2');
}

@font-face {
  font-family: "Play";
  font-weight: bold;
  src: url("https://resources.telemetrytv.com/fonts/play-v9-latin-700.woff2") format('woff2');
}
@font-face {
  font-family: "Play QA";
  font-weight: bold;
  src: url("https://qa-resources.telemetrytv.com/fonts/play-v9-latin-700.woff2") format('woff2');
}
