<script>
import OverlayHeader from 'components/common/OverlayHeader.vue'
import OverrideResizeMixin from './OverrideResizeMixin.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

import CommonStyling from 'services/common-styling.js'

export default {
  name: 'OverrideInfoItem',
  components: {
    OverlayHeader,
    ResponsiveMarkupText
  },
  mixins: [OverrideResizeMixin],
  props: {
    icon: {
      type: [String, Array],
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: ''
    }
  },
  computed: {
    fontClass () {
      if (!this.font) { return '' }
      return CommonStyling.getfontClass(this.font)
    }
  },
  watch: {
    fontClass (newValue) {
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    }
  }
}
</script>

<template lang="pug">
.override-info-item(:class="[{'is-portrait': isPortrait, 'visible': !resizing}]", :style="fontSizeStyle")
  .resize-sensor(ref="sensor")
  overlay-header(:logo="logo")
    .overlay-title(slot="title") INFORMATION
  .info-content
    .icon-wrapper
      fa(:icon="icon" fixed-width)
    .message
      .message-content
        responsive-markup-text(
          class="horz-left vert-center"
          :margin-vert="0"
          :text="message"
          :base-font-size="baseFontSize * 5"
          :class="fontClass"
          :font-class="fontClass")
</template>

<style lang="stylus">
.override-info-item
  z-index: 52
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  display: flex
  flex-flow: column wrap
  justify-content: center

  opacity: 0
  transition: opacity .1s

  // Prevent flickering when font-size is unscaled
  &.visible
    opacity: 1

  .overlay-title
    color: var(--gray-lightest)
    font-size: 2em
    text-transform: uppercase
    font-family: var(--font-secondary)
    font-weight: 600

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .info-content
    background-color: alpha(white, .2)
    height: 33.33333%
    margin: 0 1em
    display: flex
    align-items: center

    .icon-wrapper
      height: 100%
      padding: 1em
      display: flex
      justify-content: center
      align-items: center

      svg
        width: 9em
        height: auto

    .message
      position: relative
      display: flex
      flex-grow: 1
      height: 100%
      width: 100%
      font-family: var(--font-secondary)

      .message-content
        position: absolute
        left: 1em
        right: 1em
        top: 1em
        bottom: 1em

  &.is-portrait
    .info-content
      height: 50%
      flex-direction : column

      .icon-wrapper
        padding-bottom: 0

      .icon
        width: 30%
</style>
