<script>
// Interaction Overlay above Playlist PageContents
//
// NOTE @ Jan 14, 2019
// To resolve mixed interaction with DEV-1761
// moved touch event handlers to the children component InteractionZone
// This becomes a grid container mostly

import InteractionZone from 'components/common/InteractionZone.vue'

// Place the InteractiveZone within these app item instead of here
// To resolve conflicts between Kiosk Interactions and the Apps' original tap/click/scroll events
const DISMISS_EVENT_FOR = [
  'button',
  'notice'
]

export default {
  name: 'InteractionOverlay',
  components: { InteractionZone },

  props: {
    gridItems: {
      type: Array,
      default: () => { return [] }
    },
    gridAreas: {
      type: Array,
      default: () => { return [] }
    }
  },

  computed: {
    areasWithItem () {
      if (!this.gridItems || !this.gridItems.length) { return [] }
      return this.gridItems.map(gItem => gItem.grid_area || 'main')
    },

    emptyAreas () {
      if (!this.areasWithItem.length || !this.gridAreas.length || this.areasWithItem.length === this.gridAreas.length) { return [] }
      const emptyGrids = []
      this.gridAreas.forEach(gArea => {
        if (!this.areasWithItem.includes(gArea)) {
          emptyGrids.push(gArea)
        }
      })
      return emptyGrids
    }
  },

  methods: {
    dismissPointerEvent (gridItem) {
      // Use interactive item in particular apps
      if (gridItem && gridItem.type && DISMISS_EVENT_FOR.includes(gridItem.type)) {
        return true
      }
      return false
    }
  }
}
</script>

<template lang="pug">
aside.interaction-overlay-mask
  //- Page zone with real items
  .grid-item-block(v-for="gItem in gridItems"
                   :key="gItem.key"
                   :style="{gridArea: gItem.grid_area || 'main'}"
                   :class="{'no-pointer-evt': dismissPointerEvent(gItem)}")
    interaction-zone(v-if="!dismissPointerEvent(gItem)"
                     :grid-name="gItem.grid_area || 'main'"
                     :grid-type="gItem.type")
  //- Placeholder for empty page zone
  .grid-item-block.placeholder(v-for="(aItem, index) in emptyAreas"
                   :key="index"
                   :style="{gridArea: aItem }")
    interaction-zone(:grid-name="aItem || 'main'"
                     is-placeholder)
</template>

<style lang="stylus">
.interaction-overlay-mask
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9000
  background: transparent

  display: grid
  justify-items: stretch
  justify-content: stretch
  align-items: stretch
  align-content: stretch
  overflow: hidden
  grid-gap: 0 0
  // Fixes for `auto` row height display in Safari [DEV-1047]
  height: 100%
  // Passdown pointer events for special items like the Button app
  pointer-events: none

  // Zone Gap (DEV-1700)
  &.zone-gap-small
    grid-gap: 1.5vmin 1.5vmin
  &.zone-gap-medium
    grid-gap: 3vmin 3vmin
  &.zone-gap-large
    grid-gap: 4.5vmin 4.5vmin

  .grid-item-block
    position: relative
    overflow: hidden
    pointer-events: initial
    // Passdown pointer events for special items like the Button app
    &.no-pointer-evt
      pointer-events: none
</style>
