<script>
import qs from 'qs'
import { mapGetters } from 'vuex'

import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'
import Pages from 'services/pages'

import MediaFolderItem from './media/FolderItem.vue'
import AdvertisingItem from 'components/items/AdvertisingItem.vue'

const config = {
  // In seconds. Fallback intervals
  DEFAULT_INTERVAL: 10,
  MIN_INTERVAL: 1,

  // counts
  DEFAULT_AD_FREQUENCY: 10,
  MIN_AD_FREQUENCY: 1
}

export default {
  name: 'MediaFolder',
  components: {
    MediaFolderItem,
    AdvertisingItem
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      folderID: null,
      adFolderID: null,
      loading: true,

      zoneOptions: {
        animated_zoom_effect: false,
        muted: false,
        interval: 0,
        transition: '',
        image_fit: '',
        image_position: '',
        image_repeat: '',
        match_device_tag: false,
        one_at_a_time: false,
        keep_looping: false,
        tap_interaction: false,
        randomize: false,
        sub_folders: false
      },

      reportItemPlayback: false,

      injectAd: false,
      adFrequency: 0,

      showAd: false,
      readyForAd: false,

      isDestroying: false,

      debounceTimer: undefined,
      delayResetTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'advertisingEnabled',
      'onlyOneVisiblePage',
      'currentPageItemsCount',
      'currentPageName',
      'isDevice',
      'deviceTagsSorted'
    ]),

    gridZoneName () {
      return (this.item && this.item.grid_area) || 'main'
    },

    localKeyBase () {
      return `ttvMediaFolder_${this.item.ref_id}`
    },

    // If there's only one visible page on the device, ignore "one_at_a_time" [DEV-3912]
    doOneAtATime () {
      return Boolean(
        (this.zoneOptions && this.zoneOptions.one_at_a_time) &&
        (!this.isDevice || (this.isDevice && !this.onlyOneVisiblePage))
      )
    },

    // Refinements for DEV-3956
    // Only do force-advance when:
    // - The current Media Folder is set to "Auto-Advanced", or
    // - The current page has only one zone item
    couldForceAdvance  () {
      return Boolean((this.zoneOptions && !this.zoneOptions.keep_looping) || this.currentPageItemsCount === 1)
    },

    doInjectAds () {
      return this.injectAd &&
        (
          this.advertisingEnabled ||
          (!this.advertisingEnabled && this.adFolderID && this.adFolderID.length > 0)
        )
    },

    adItem () {
      if (!this.advertisingEnabled) { return }
      return {
        id: `mediafolder_ad_${this.folderID}_${this.gridZoneName}`,
        grid_area: this.gridZoneName
      }
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.debounceRender()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayResetTimer)
    this.debounceRender()
  },

  beforeDestroy () {
    this.isDestroying = true
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayResetTimer)
  },

  methods: {
    debounceRender () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.render()
      }, 200)
    },

    async render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.folderID = options.folder_id || this.item.ref_id || ''

      this.zoneOptions = {
        animated_zoom_effect: options.animated_zoom_effect === 'true',
        muted: options.muted === 'true',
        one_at_a_time: options.one_at_a_time === 'true',
        match_device_tag: options.match_device_tag === 'true',
        sub_folders: options.sub_folders === 'true',
        randomize: options.randomize === 'true',
        keep_looping: options.keep_looping === 'true',
        tap_interaction: options.tap_interaction === 'true',

        interval: Math.max(config.MIN_INTERVAL, (+options.interval || config.DEFAULT_INTERVAL)),

        image_fit: options.image_fit || '',
        image_position: options.image_position || '',
        image_repeat: options.image_repeat || '',
        transition: options.transition || ''
      }

      this.reportItemPlayback = options.item_playback_reports === 'true'

      this.adFolderID = options.ad_folder_id || ''
      this.injectAd = options.inject_ad === 'true'
      this.adFrequency = Math.max(config.MIN_AD_FREQUENCY, (+options.ad_frequency || config.DEFAULT_AD_FREQUENCY))

      // First init
      if (this.loading) {
        if (this.doInjectAds) {
          const playedCount = await this.getMainFolderPlayedCounts()
          // Switch to play AD content
          if (playedCount >= this.adFrequency) {
            this.showAd = true
            this.readyForAd = false
            this.setMainFolderPlayedCounts(0)
          }
        }
        this.loading = false
      }
    },

    forceAdvance (reason = '') {
      if (this.couldForceAdvance) {
        Log.info('media', `Media Folder: Force-advance triggered on page "${this.currentPageName}" zone "${this.gridZoneName}" (reason: "${reason}", folder: ${this.folderID})`, 'INF_FORCEADVANCE')
        // Force Advance to next page ASAP [DEV-2706]
        this.finished(true)
      } else {
        // NOTE: Change to "warning" level for debug.
        // Ignore force-advance, send logs for further debug
        Log.warn('media', `Media Folder: Force-advance ignored on page "${this.currentPageName}" zone "${this.gridZoneName}"  (reason: "${reason}", folder: ${this.folderID}, zoneAutoAdvance: ${Boolean(this.zoneOptions && !this.zoneOptions.keep_looping)}, pageZonesCount: ${this.currentPageItemsCount})`, 'WAR_IGNOREFORCEADVANCE')
      }
    },

    genPlayedKey () {
      if (!this.folderID) { return }
      let result = `${this.localKeyBase}_PLAYED-COUNT`
      if (this.zoneOptions.randomize) {
        result += '_random'
      }
      if (this.zoneOptions.sub_folders) {
        result += '_withSubFolders'
      }
      if (this.isDevice && this.zoneOptions.match_device_tag) {
        result += '_matchDeviceTags'
        if (this.deviceTagsSorted && this.deviceTagsSorted.length > 0) {
          result += `_${this.deviceTagsSorted.join(',')}`
        }
      }
      return result
    },

    async setMainFolderPlayedCounts (count = 0) {
      const storeKey = this.genPlayedKey()
      if (storeKey && storeKey.length) {
        await OfflineCaches.set(storeKey, count)
      }
    },

    async getMainFolderPlayedCounts () {
      const storeKey = this.genPlayedKey()
      if (storeKey && storeKey.length) {
        const result = await OfflineCaches.get(storeKey)
        return result || 0
      }
      return 0
    },

    delayResettingPlayedCounts () {
      clearTimeout(this.delayResetTimer)
      if (this.isDestroying) {
        // Is switching to another page, don't reset played counts
        return
      }
      this.delayResetTimer = setTimeout(() => {
        clearTimeout(this.delayResetTimer)
        this.setMainFolderPlayedCounts(0)
      }, 1000)
    },

    // For main folder only
    // Record folder content count after item started playback for some time
    async itemStarted () {
      if (!this.doInjectAds) { return }
      let playedCount = await this.getMainFolderPlayedCounts()
      playedCount += 1
      this.setMainFolderPlayedCounts(playedCount)
      // Make as "readyForAd" and pass to the children component
      if (playedCount >= this.adFrequency && !this.showAd) {
        this.readyForAd = true
      }
    },

    // For main folder only
    async itemFinished () {
      if (!this.doInjectAds) { return }
      const playedCount = await this.getMainFolderPlayedCounts()
      // Trigger AD content when reaches AD Frequency
      if (playedCount >= this.adFrequency) {
        if (!this.doOneAtATime) {
          this.showAd = true
          this.readyForAd = false
          // Add some delay to prevent event racing during page switching
          this.delayResettingPlayedCounts()
        }
      }
    },

    finished (forceAdvance = false) {
      if (forceAdvance) {
        // Already have detailed logs send in "forceAdvance()" method
        this.$emit('finished', true)
      // Auto-Advance is set to `true`
      } else if (!this.zoneOptions.keep_looping) {
        // Temp debug for DEV-3956
        Log.info('media', `Media Folder: Auto-advance (not forced) called on page "${this.currentPageName}" zone "${this.gridZoneName}" (folder: ${this.folderID})`, 'INF_AUTOADVANCECALLED')
        this.$emit('finished')
      }
    },

    folderItemFinished (isAdFolder = false) {
      if (!isAdFolder) {
        this.finished()
        return
      }
      if (isAdFolder && this.showAd) {
        if (this.doOneAtATime) {
          this.finished()
        }
        // AD playback finished, swtich back to main folder
        this.showAd = false
        this.readyForAd = false
      }
    },

    adItemFinished () {
      if (this.showAd) {
        if (this.doOneAtATime) {
          this.finished()
        }
        // AD playback finished, swtich back to main folder
        this.showAd = false
        this.readyForAd = false
      }
    },

    logCampaignView ({ page, startTime, endTime }) {
      if (!this.doInjectAds) { return }
      Pages.logCampaignView(page, startTime, endTime)
    },

    loaded () {
      this.$emit('loaded')
    },

    sendDuration (duration) {
      this.$emit('duration', duration)
    }
  }
}
</script>

<template lang="pug">
section.media-folder
  template(v-if="!loading")
    //- Main Folder
    media-folder-item.main-folder(v-if="!doInjectAds || (doInjectAds && !showAd)"
                    :class="{'hidden': showAd}"
                    :folder-id="folderID"
                    :zone-options="zoneOptions"
                    :grid-zone-name="gridZoneName"
                    :one-at-a-time="doOneAtATime"
                    :ready-for-ad="readyForAd"
                    :report-item-playback="reportItemPlayback"
                    @force-advance="forceAdvance"
                    @finished="folderItemFinished(false)"
                    @item-started="itemStarted"
                    @item-finished="itemFinished"
                    @loaded="loaded"
                    @duration="sendDuration")
    //- Advertising Folder
    //- NOTE: Must add trasition here to prevent flickering caused by Vue component re-use
    transition(name="fade" duration="50")
      media-folder-item.ttvad-folder(v-if="doInjectAds && showAd && !advertisingEnabled"
                      is-ad-folder
                      one-at-a-time
                      :folder-id="adFolderID"
                      :zone-options="zoneOptions"
                      :grid-zone-name="gridZoneName"
                      @force-advance="forceAdvance"
                      @finished="folderItemFinished(true)"
                      @loaded="loaded"
                      @duration="sendDuration"
                      @ad-played="logCampaignView")

    transition(name="fade" duration="50")
      advertising-item(v-if="doInjectAds && showAd && advertisingEnabled"
                      active
                      embedding
                      :item="adItem"
                      @finished="adItemFinished"
                      @loaded="loaded")
</template>

<style lang="stylus">
section.media-folder
  width: 100%
  height: 100%
  position: absolute

  .media-folder-item
    &.hidden
      visibility: hidden
      opacity: 0
</style>
