<!-- Overlay for the Interactive Menu [DEV-3409] -->
<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import InputSearch from 'components/common/InputSearch'
import PageThumbnail from 'components/common/PageThumbnail.vue'
import InteractiveGridList from './InteractiveGridList.vue'

import Pages from 'services/pages.js'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'InteractiveMenuOverlay',

  components: {
    InputSearch,
    PageThumbnail,
    InteractiveGridList
  },

  data () {
    return {
      query: '',

      selectedPage: null
    }
  },

  computed: {
    ...mapState({
      currentPageID: s => s.playlists.currentPageID,
      visiblePages: s => s.playlists.visiblePages || [],
      menuPages: s => s.playlists.menuPages || [],
      activeOverride: s => s.overrides.activeOverride,
      screensaverIsOn: s => s.playbackmode.screensaverMode
    }),
    ...mapGetters([
      'isPaused',
      'showPagesList',
      'showItemsList'
    ]),

    filteredList () {
      if (!this.menuPages.length) {
        return []
      }

      if (!this.query || !this.query.length) {
        return this.menuPages
      }

      return this.menuPages.filter(p => {
        const query = this.query.toLowerCase()
        if ((p.description || '').toLowerCase().includes(query)) {
          return true
        }
        return false
      })
    }
  },

  watch: {
    showPagesList (toTrue) {
      // Got only one page in the playlist
      // and that page has items that are selectable (E.g. MediaFolder)
      // Just show the media selection grid list
      if (
        this.menuPages.length === 1 &&
        toTrue &&
        this.menuPages[0] && this.menuPages[0].id &&
        this.hasInteractiveZone(this.menuPages[0])
      ) {
        const page = JSON.parse(JSON.stringify(this.menuPages[0]))
        this.pauseWhenNeeded()
        this.selectedPage = page
        this.$store.commit('updateShowItemsList', true)
      }
    }
  },

  methods: {
    isActive (pageID) {
      // Do not highlight current page when device is being overridden
      if (this.activeOverride) { return false }
      return this.currentPageID && this.currentPageID === pageID
    },

    hasInteractiveZone (page) {
      if (!page || !page.items || !page.items.length) { return false }
      const target = page.items.find(item => {
        return (
          item.type === 'media_folder' &&
          item.url.indexOf('interactive_mode=true') >= 0
        )
      })
      return Boolean(target && target.type)
    },

    isInMenuOnly (page) {
      return Boolean(page && page.in_menu_only)
    },

    goToPage (page) {
      const hasInteractiveZone = this.hasInteractiveZone(page)
      const isInMenuOnly = this.isInMenuOnly(page)

      if (hasInteractiveZone && isInMenuOnly) {
        // Do not jump to that page
      } else if (this.screensaverIsOn) {
        Pages.stopScreensaver(page)
      } else {
        Pages.goToPage(page)
      }

      // Regular page
      if (!hasInteractiveZone) {
        this.$store.commit('updateShowPagesList', false)
        this.selectedPage = null
      // Page with interactive zone (Media Folder, etc.)
      } else {
        this.pauseWhenNeeded()
        this.selectedPage = page
        this.$store.commit('updateShowItemsList', true)
      }

      this.query = ''
    },

    pauseWhenNeeded () {
      // Pause the playlist only when needed
      if (!this.isPaused) {
        this.$store.dispatch('togglePauseMode')
      }
    },

    userInteracted () {
      EventBus.$emit('user-interacted')
    }
  }
}
</script>

<template lang="pug">
aside#interactiv-menu-overlay(:class="{'click-through': !showPagesList && !showItemsList, 'show-grid-list': showItemsList}")
  //- Pages List
  transition(name="custom-list-slide" appear :duration="200"
             enter-active-class="page-moveRight-enter-active"
             leave-active-class="page-moveLeft-leave-active")
    .pages-list(v-if="showPagesList && !showItemsList")
      .list-inner
        .search-wrapper(@click="userInteracted")
          input-search(v-model="query" placeholder="SEARCH" @input="userInteracted")

        .pages-wrapper
          .page-item(v-for="page in filteredList", :key="page.id"
                     @click="goToPage(page); userInteracted()")
            .page-thumb-container
              page-thumbnail(:page="page"
                             :class="{active: isActive(page.id)}")

              //- Icon indicator for "Show In Menu Only" pages
              .extra-icon
                fa.fa-icon(v-if="isInMenuOnly(page)" icon="bars" fixed-width)

            .page-name(:title="page.description") {{ page.description }}

            fa.fa-icon.arrow(v-if="hasInteractiveZone(page)" icon="chevron-right")

          p.no-items-found(v-if="!menuPages.length && visiblePages.length")
            | All pages are hidden in the menu

          p.no-items-found(v-else-if="query.length && !filteredList.length")
            | No pages found with this query

  //- Media Items Grid List
  .items-list(v-if="showItemsList")
    interactive-grid-list(:page="selectedPage")
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

// Values should be the same as per the PageThumbnail's
$thumbWidth = 168px
$thumbHeight = 96px

$thumbBorderWidth = 2px
$paddingLeft = 20px
$paddingRight = 40px

aside#interactiv-menu-overlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  z-index: 9000
  font-size: 1.5vmin
  pointer-events: initial

  &.show-grid-list
    right: 0

  &.click-through
    pointer-events: none

  .pages-list
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: $thumbWidth + ($thumbBorderWidth * 2) + ($paddingLeft + $paddingRight)
    box-sizing: content-box

    background: #222
    overflow: hidden

    animation-duration: 200ms

    .list-inner
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      overflow: hidden

      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch

    .search-wrapper
      font-size: 2vmin
      padding: 15px 20px
      .input-search
        width: 100%

    .pages-wrapper
      flex: 1 1 0.00001px
      padding: 0 0 40px $paddingLeft

      overflow-y: auto
      overflow-x: hidden

      scrollBarTransparentBlack()

      .page-item:first-of-type
        margin-top: 1em

    .page-item
      margin-top: 2em
      width: $thumbWidth + ($thumbBorderWidth * 2)
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: center
      text-align: center
      position: relative
      overflow: visible
      z-index: 0
      &:active
        .page-thumbnail
          border-color: #fff
          background-color: #fff

      .page-thumb-container
        position: relative

      .page-thumbnail
        position: relative
        z-index: 0
        box-sizing: content-box
        cursor: pointer
        border: 2px solid var(--gray)
        background-color: var(--gray)
        transition: border-color .3s, background-color .3s
        &.active
          border-color: var(--brand-primary)
          background-color: var(--brand-primary)

      .extra-icon
        position: absolute
        left: 0.5em
        bottom: 0.5em
        z-index: 5
        .fa-icon
          font-size: 1.2em
          appIconShadow()

      .page-name
        padding-top: 0.5em
        color: var(--gray-light)
        max-width: 100%
        ellipsis()

      .arrow
        font-size: 2em
        opacity: 0.4
        position: absolute
        z-index: 10
        right: -30px
        top: ($thumbHeight / 2) + $thumbBorderWidth
        transform: translateY(-50%)

  .items-list
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: #222

  .no-items-found
    font-size: 1.5em
    line-height: 150%
    text-align: center
    opacity: 0.5
    padding: 0 $paddingLeft 1em 0
</style>
