<!-- Playlist Overlay Tickertape -->
<script>
import Marquee from './Marquee.vue'

import {
  mapGetters
} from 'vuex'

const config = {
  DEFAULT_CATEGORY: 'Notice',

  // Maxiumn items to display per feed
  MAX_SIZE: 20
}

export default {
  name: 'FooterTickertape',
  components: {
    Marquee
  },

  props: {
    list: { type: Array, default: () => [] },
    background: { type: String, default: '' },
    fontSize: { type: String, default: '3vmin' }
  },

  data () {
    return {
      sections: [],
      currentSection: undefined,
      nextSection: undefined,

      showPrime: true,
      primeTitle: '',
      baseTitle: ''
    }
  },

  computed: {
    ...mapGetters([
      'feedForURL'
    ]),

    tickerId () {
      return `tickertape-${this._uid}`
    },

    expandedList () {
      if (!this.list || !this.list.length) { return [] }
      const result = []
      this.list.forEach(listItem => {
        if (listItem.rss === false && listItem.text) {
          result.push({
            category: listItem.label || (config.DEFAULT_CATEGORY + ''),
            text: listItem.text
          })
        } else if (listItem.url) {
          const feedItem = this.feedForURL(listItem.url) || {}
          if (!feedItem || !feedItem.items || !feedItem.items.length) {
            return
          }
          const items = feedItem.items.slice(0, config.MAX_SIZE)
          items.forEach(fItem => {
            if (!fItem || !fItem.title) {
              return
            }
            result.push({
              category: listItem.label || (config.DEFAULT_CATEGORY + ''),
              text: fItem.title
            })
          })
        }
      })
      return result
    }
  },

  watch: {
    list: {
      deep: true,
      handler () {
        this.fetchFeeds(true)
      }
    }
  },

  mounted () {
    this.fetchFeeds()
  },

  methods: {
    fetchFeeds (forceUpdate) {
      const list = this.list || []
      const sections = forceUpdate ? [] : JSON.parse(JSON.stringify(this.sections || []))
      const promises = []

      list.forEach(item => {
        if (item.rss === false && item.text) {
          const category = item.label || (config.DEFAULT_CATEGORY + '')
          if (!sections.includes(category)) {
            sections.push(category)
          }
        } else if (item.url) {
          promises.push(this.$store.dispatch('fetchFeedURL', item.url))
          const category = item.label || (config.DEFAULT_CATEGORY + '')
          if (!sections.includes(category)) {
            sections.push(category)
          }
        }
      })

      this.sections = sections

      Promise.all(promises).then(() => {
        this.setCurrentSection(forceUpdate)
      })
    },

    getItemsBySection (sectionName) {
      sectionName = sectionName || config.DEFAULT_CATEGORY
      return this.expandedList.filter(item => item.category === sectionName) || []
    },

    setCurrentSection (forceUpdate) {
      if (!this.sections || !this.sections.length) { return }

      // Init first section
      if (!this.currentSection) {
        const firstSectionName = this.sections[0] + ''
        this.currentSection = {
          label: firstSectionName,
          items: JSON.parse(JSON.stringify(this.getItemsBySection(firstSectionName)))
        }
        this.primeTitle = firstSectionName
      // Force update for tickertape with only one section
      } else if (forceUpdate && this.sections.length === 1) {
        this.playNext()
        return
      }

      this.setNextSection()
    },

    setNextSection (startPlaying) {
      if (!this.sections || !this.sections.length || !this.currentSection) { return }

      if (this.sections.length === 1 && this.currentSection.label === this.sections[0]) {
        // Same category. Do nothing here
        return
      }

      const currentIndex = this.sections.indexOf(this.currentSection.label)

      let nextSectionName
      if (currentIndex >= 0) {
        nextSectionName = this.sections[(currentIndex + 1) % this.sections.length]
      // Current section not in queue anymore, start over at the beginning
      } else {
        nextSectionName = this.sections[0]
      }

      this.nextSection = {
        label: nextSectionName,
        items: JSON.parse(JSON.stringify(this.getItemsBySection(nextSectionName)))
      }

      if (startPlaying) {
        this.playNext()
      }
    },

    playNext () {
      // No need to switch sections when there's only one section in the same category (label)
      if (this.sections.length === 1 && this.currentSection.label === this.sections[0] && this.$refs && this.$refs.marquee) {
        // Grab the latest items on every loop
        this.$set(this.currentSection, 'items', JSON.parse(JSON.stringify(this.getItemsBySection(this.sections[0]))))
        this.$refs.marquee.replay(this.tickerId)
        return
      }

      if (!this.nextSection) {
        this.setNextSection(true)
      } else {
        this.currentSection = JSON.parse(JSON.stringify(this.nextSection))

        if (this.showPrime) {
          this.baseTitle = this.currentSection.label
          this.showPrime = false
        } else {
          this.primeTitle = this.currentSection.label
          this.showPrime = true
        }

        this.setNextSection()
      }
    }
  }
}
</script>

<template lang="pug">
.footer-tickertape
  transition(name="fadeLeft" mode="out-in" appear)
    .section-title.prime(v-if="showPrime && primeTitle" v-text="primeTitle" :style="{background: background, fontSize: fontSize}")
  transition(name="fadeLeft" mode="out-in" appear)
    .section-title.base(v-if="!showPrime && baseTitle" v-text="baseTitle" :style="{background: background, fontSize: fontSize}")
  .marquee-container(:style="{fontSize: fontSize}")
    marquee(v-if="currentSection"
            ref="marquee"
            :messages="currentSection.items"
            :left-delta="100"
            :ticker-id="tickerId"
            @display-next-ticker="playNext")
</template>

<style lang="stylus">
.footer-tickertape
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: inherit

  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: stretch
  overflow: hidden
  position: absolute

  .section-title
    position: absolute
    top: 0
    left: 0
    bottom: 0
    min-width: 6em

    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: center
    white-space: nowrap

    z-index: 5
    text-transform: capitalize
    padding: 0 1em

  .marquee-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    flex-flow: row nowrap
    align-items: stretch
    overflow: hidden
    white-space: nowrap
    word-spacing: 0.05em
</style>
