<script>
import qs from 'qs'
import Spinner from 'components/common/Spinner.vue'
import Log from 'services/log.js'
import Env from 'services/environment'
import { url } from 'services/validators.js'

const EDITOR_URL_REGEX = /(?:\/edit#slide|\/edit)/
const PUBLISHED_URL_REGEX = /(?:\/pub|\/embed)/

export default {
  name: 'GoogleSlidesItem',

  components: {
    Spinner
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      url: '',
      opts: {},
      loading: true,

      electron: Env.electronAPI,
      electronTimeout: undefined,
      electronLastLoaded: 0,
      invalidURL: false
    }
  },

  computed: {
    googleSlideUrl () {
      if (!this.url || !this.url.length) {
        return
      } else if (!this.url.startsWith('http://') && !this.url.startsWith('https://')) {
        Log.error('app', `Google Slides: Invalid URL "${this.url}"`, 'ERR_GSLIDEURL', { url: this.url })
        return
      }
      // Force to use embed mode
      const url = this.url.replace(/\/pub/, '/embed')
      const opts = qs.stringify(this.opts || {})
      return `${url}?${opts}`
    },

    msPerPage () {
      const matches = this.googleSlideUrl.match(/delayms=(\d+)/)
      if (!matches || matches.length < 2) {
        // can't detect delayms
        return 0
      }
      return parseFloat(matches[1])
    },

    autoStart () {
      return (this.googleSlideUrl.indexOf('start=true') > 0)
    },

    loop () {
      return (this.googleSlideUrl.indexOf('loop=true') > 0)
    },

    editorURL () {
      return EDITOR_URL_REGEX.test(this.googleSlideUrl)
    },

    publishedURL () {
      return PUBLISHED_URL_REGEX.test(this.googleSlideUrl)
    },

    isValidURL () {
      if ((!this.url.startsWith('http://') && !this.url.startsWith('https://')) || this.invalidURL) {
        return false
      }
      return url(this.googleSlideUrl)
    },

    hasError () {
      return Boolean(!this.isValidURL || (this.editorURL && !this.publishedURL))
    },

    unavailableDesc () {
      if (!this.isValidURL) {
        return this.$t('pageItems.common.notValidURL', {url: this.googleSlideUrl})
      } else if (this.editorURL && !this.publishedURL) {
        return this.$t('pageItems.googleSlides.editorURL')
      }
    }
  },

  mounted () {
    window.addEventListener('message', this.onMessage)
    clearTimeout(this.electronTimeout)
    this.parseURLContent()
  },

  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
    clearTimeout(this.electronTimeout)
  },

  methods: {
    onMessage (event) {
      if (event.data === 'g-slide-finished') {
        this.$emit('finished')
      }
    },

    parseURLContent () {
      if (!this.item.url && !this.item.location) { return }

      const location = decodeURIComponent(this.item.url || this.item.location || '')

      // New Format: url=https://docs.google...
      // Old format: https://docs.google...
      let locationToParse = location
      if (location.startsWith('url=')) {
        locationToParse = decodeURIComponent(location.substr(4))
      }
      this.parseLocation(locationToParse)
    },

    parseLocation (location) {
      try {
        const UrlObj = new URL(location)
        let searchStr = (UrlObj).search
        if (searchStr.startsWith('?')) {
          searchStr = searchStr.substr(1)
        }
        this.opts = qs.parse(searchStr)
        this.url = `${UrlObj.origin}${UrlObj.pathname}`
      } catch (err) {
        // Different format than expected
        // You can see it from
        // https://app.bugsnag.com/telemetry/player/errors/646ce80fb0cc4600088b2be5?filters[event.since]=30d&filters[error.status]=open&filters[search][]=Google&
        // filters[search][]=Slides&filters[search][]=location&filters[search][]=error
        // i.e start=true&url=https%3A%2F%2Fdocs.google.com%2Fpresentation%2Fd%2Fe%2F2PACX-1vRnNjE1OkV0njQqhLwnzAIEsl9xQISDzHVee30iqjPaFhbs4crSlYDxn15nM6NZKMOYS6pm4jgiua9s%2Fpub%3Fstart&loop&delayms=3000
        this.parseLocationFromObj(location)
      }
    },

    parseLocationFromObj (location) {
      try {
        const items = qs.parse(location)
        const urlStr = items.url
        delete items.url
        const UrlObj = new URL(urlStr)
        this.opts = qs.stringify(items)
        this.url = `${UrlObj.origin}${UrlObj.pathname}`
      } catch (err) {
        Log.error('app', 'Google Slides location error', 'ERR_GSLIDELOC', { url: location, err })
        this.invalidURL = true
      }
    },

    loadedHandler () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
      if (this.electron && !this.loop && this.autoStart && this.msPerPage > 0) {
        // When g-slide page changed, it receive 'did-stop-loading' event so we can detect if g-slide is finished.
        clearTimeout(this.electronTimeout)
        this.electronTimeout = setTimeout(() => {
          clearTimeout(this.electronTimeout)
          if (Date.now() - this.electronLastLoaded > this.msPerPage) {
            this.$emit('finished')
          }
        }, this.msPerPage + 1000)
        this.electronLastLoaded = Date.now()
      }
    }
  }
}
</script>

<template lang="pug">
section.g-slides-item
  template(v-if="!hasError")
    transition(name="fade")
      .loading-mask(v-if="loading")
        spinner(size="8em")
    webview(
      v-if="electron"
      ref="iframe"
      :src="googleSlideUrl"
      @did-stop-loading="loadedHandler"
      disablewebsecurity
      webpreferences="allowRunningInsecureContent"
    )
    iframe(
      v-if="!electron"
      ref="iframe"
      :src="googleSlideUrl"
      frameBorder="0"
      height="100%"
      width="100%"
      scrolling="no"
      @load="loadedHandler"
    )
  .unavailable(v-else)
    h2 {{ $t('pageItems.googleSlides.unavailableTitle') }}
    p(v-html="unavailableDesc")

</template>

<style lang="stylus">
.g-slides-item
  iframe, webview
    position: absolute
    width: 100%
    height: 100%
    border: 0
    z-index: 0

  .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 5
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .unavailable
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    border: 0
    font-size: 2em
    padding: 1em 3em

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center

    background: black
    color: white
</style>
