<script>
import qs from 'qs'
import jsmpegPlayer from '@cycjimmy/jsmpeg-player'

import Spinner from '../common/Spinner.vue'

import Env from 'services/environment.ts'
import ElectronAPI from '../../platform/electronapi'
import { EventBus } from '../../services/eventbus'
import { generateId } from '../../utilities/generate-id'

export const defaultVideoWidth = 960
export const defaultVideoHeight = 540
export const streamTimeout = 1000 * 60 // 60 seconds

export default {
  name: 'LiveVideoApp',
  components: { Spinner },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    streamId: '',
    streamUrl: '',
    streamErrorCode: '',
    streamTimeoutId: null,
    player: null,
    playerSize: {
      width: 0,
      height: 0
    },
    playerStyle: {
      width: 0,
      height: 0
    },
    advanceTimer: null
  }),

  computed: {
    isStreamingSupported () {
      return Env.telemetryOS && window.supportLiveVideo
    },

    urlParams () {
      if (!this.item?.url) return {}
      return qs.parse(decodeURIComponent(this.item.url ?? '')) ?? {}
    }
  },

  watch: {
    'item.url' () {
      this.configureStream()
    },
    'streamUrl' () {
      this.streamUrl
        ? this.configurePlayer()
        : this.disposePlayer()
    }
  },

  mounted () {
    if (!this.isStreamingSupported) return

    EventBus.$on('video-stream-connected', this.handleStreamConnected)
    EventBus.$on('video-stream-closed', this.handleStreamClosed)
    EventBus.$on('video-stream-errored', this.handleStreamErrored)

    this.watchSensorForResizes()
    this.configureStream()
  },

  beforeDestroy () {
    if (!this.isStreamingSupported) return

    EventBus.$off('video-stream-connected', this.handleStreamConnected)
    EventBus.$off('video-stream-closed', this.handleStreamClosed)
    EventBus.$off('video-stream-errored', this.handleStreamErrored)

    this.unwatchSensorForResizes()
    this.disposeStream()
    this.disposePlayer()

    clearTimeout(this.advanceTimer)
  },

  methods: {
    configureStream () {
      if (!this.isStreamingSupported) {
        this.streamErrorCode = 'unsupported'
        return
      }

      if (this.streamId) {
        this.disposeStream()
        this.disposePlayer()
      }

      this.streamUrl = ''
      this.streamErrorCode = ''
      this.clearStreamTimeout()

      this.streamId = generateId()

      ElectronAPI.startVideoStreaming({
        id: this.streamId,
        url: this.urlParams.url,
        width: this.urlParams.width,
        height: this.urlParams.height
      })
      this.scheduleStreamTimeout()
    },

    disposeStream () {
      this.clearStreamTimeout()
      ElectronAPI.closeVideoStreaming(this.streamId)
    },

    configurePlayer () {
      if (!this.streamUrl) {
        this.disposePlayer()
        return
      }

      this.player = new jsmpegPlayer.VideoElement(
        this.$refs.player,
        this.streamUrl,
        {
          autoplay: true,
          picMode: true,
          control: false,
          overlayOptions: {
            audio: !this.urlParams.mute
          }
        }
      )
    },

    disposePlayer () {
      if (!this.player) return
      this.player.destroy()
      this.player = null
    },

    handleStreamConnected (event) {
      if (event.id !== this.streamId) return
      this.clearStreamTimeout()
      this.streamErrorCode = ''
      this.streamUrl = event.streamUrl
    },

    handleStreamErrored (event) {
      if (event.id !== this.streamId) return
      this.clearStreamTimeout()
      this.delayForceAdvance()
      this.streamUrl = ''
      this.streamErrorCode = event.errorCode
    },

    handleStreamClosed (event) {
      if (event.id !== this.streamId) return
      this.$emit('finished')
    },

    watchSensorForResizes () {
      const handleResize = (entries) => {
        const contentRect = entries[0]?.contentRect
        if (!contentRect) return

        const sizeRatio = Math.min(
          contentRect.width / this.urlParams.width,
          contentRect.height / this.urlParams.height
        )
        this.playerStyle = {
          width: `${sizeRatio * this.urlParams.width}px`,
          height: `${sizeRatio * this.urlParams.height}px`
        }
      }

      this.sensorResizeObserver = new ResizeObserver(handleResize)
      this.sensorResizeObserver.observe(this.$refs.sensor)
    },

    unwatchSensorForResizes () {
      this.sensorResizeObserver.disconnect()
    },

    scheduleStreamTimeout () {
      this.streamTimeoutId = setTimeout(() => {
        this.streamErrorCode = 'timeout'
        this.forceAdvance()
      }, streamTimeout)
    },

    clearStreamTimeout () {
      clearTimeout(this.streamTimeoutId)
    },

    delayForceAdvance (timeout = 5000) {
      clearTimeout(this.advanceTimer)
      this.advanceTimer = setTimeout(() => {
        this.forceAdvance()
      }, timeout)
    },

    forceAdvance () {
      this.$emit('finished', true)
    }
  }
}
</script>

<template>
  <section class="live-video-app">
    <div
      ref="sensor"
      class="resize-sensor"
    />

    <div
      v-if="!streamUrl && !streamErrorCode && isStreamingSupported"
      class="loading-mask"
    >
      <spinner size="8em" />
    </div>

    <div
      v-show="!streamErrorCode && isStreamingSupported"
      class="player-wrapper"
    >
      <div
        ref="player"
        class="live-video-player"
        :style="playerStyle"
      />
    </div>

    <div
      v-if="!isStreamingSupported || streamErrorCode"
      class="messages-wrapper"
    >
      <template v-if="!isStreamingSupported || streamErrorCode === 'unsupported'">
        <p>Live streams require the latest TelemetryOS player application to display</p>
      </template>
      <template v-if="streamErrorCode === 'unsupportedProtocol'">
        <p>Live stream protocol unsupported</p>
        <p class="url">
          {{ urlParams.url }}
        </p>
      </template>
      <template v-if="streamErrorCode === 'notFound'">
        <p>Cannot retrieve stream from URL</p>
        <p class="url">
          {{ urlParams.url }}
        </p>
      </template>
    </div>
  </section>
</template>

<style lang="stylus">
section.live-video-app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 10
    display: flex
    justify-content: center
    align-items: center

  .player-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 5
    display: flex
    justify-content: center
    align-items: center

  .live-video-player
    position: relative

  .messages-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    padding: 1em

    p
      text-align: center

      &.url
        overflow-wrap: break-word
        word-wrap: break-word
        word-break: break-word
        hyphens: auto

      &.more-details
        opacity: 0.6
        font-size: 0.8em
</style>
