/* Vendor CSS */
@import './vue2-animate.css';
@import './pt-animations.css';

/* App CSS */
@import './colors.css';
@import './font-faces.css';
@import './fonts.css';
@import './animations.css';
@import './global.css';
