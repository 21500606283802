<script>
import { shapesDefault } from 'services/free-form'

export default {
  name: 'ShapeMixins',

  props: {
    strokeWidth: {
      type: Number,
      default: 1
    },
    strokeColor: {
      type: String,
      default: shapesDefault.strokeColor
    },
    fillColor: {
      type: String,
      default: shapesDefault.fillColor
    }
  },

  computed: {
    validatedStrokeWidth () {
      const strokeWidth = parseFloat(this.strokeWidth)
      return !Number.isNaN(strokeWidth) && strokeWidth > 0 ? strokeWidth : 0
    }
  }
}
</script>

<style lang="stylus">
.object-shape
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  z-index: 0

  svg
    // Fix the ~1px clipping issue in some browsers
    overflow: visible
</style>
