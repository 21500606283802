<script>
import ShapeMixins from './ShapeMixins'
import { shapesDefault } from 'services/free-form'

export default {
  name: 'ShapeTriangle',
  mixins: [ShapeMixins],

  props: {
    width: {
      type: Number,
      default: shapesDefault.width
    },
    height: {
      type: Number,
      default: shapesDefault.height
    }
  }
}
</script>

<template lang="pug">
.object-shape.shape-triangle
  svg(width="100%" height="100%"
      :viewBox="`0 0 ${width} ${height}`"
      role="img"
      focusable="false"
      preserveAspectRatio="none")
    polygon(:points="`${width/2},0 ${width},${height} 0,${height}`"
            :fill="fillColor"
            :stroke="strokeColor"
            :stroke-width="validatedStrokeWidth"
            vector-effect="non-scaling-stroke")
</template>

<style lang="stylus">
.object-shape.shape-triangle
  svg
    position: absolute
    top: 0
    left: 0
    z-index: 1
</style>
