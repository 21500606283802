<script>
import OverlayHeader from 'components/common/OverlayHeader.vue'
import OverrideResizeMixin from './OverrideResizeMixin.vue'
import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

import CommonStyling from 'services/common-styling.js'

export default {
  name: 'OverrideAlertItem',
  components: {
    OverlayHeader,
    ResponsiveMarkupText
  },
  mixins: [OverrideResizeMixin],
  props: {
    icon: {
      type: [String, Array],
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: ''
    }
  },
  computed: {
    fontClass () {
      if (!this.font) { return '' }
      return CommonStyling.getfontClass(this.font)
    }
  },
  watch: {
    fontClass (newValue) {
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    }
  }
}
</script>

<template lang="pug">
.override-alert-item(:class="[{'is-portrait': isPortrait, 'visible': !resizing}]", :style="fontSizeStyle")
  .resize-sensor(ref="sensor")
  overlay-header(:logo="logo")
    .overlay-title(slot="title") ALERT!
  .icon
    fa(:icon="icon" fixed-width)
  .message
    .message-content
      responsive-markup-text(
        class="horz-left vert-center"
        :margin-vert="0"
        :text="message"
        :base-font-size="baseFontSize * 5"
        :class="fontClass"
        :font-class="fontClass")
</template>

<style lang="stylus">
.override-alert-item
  z-index: 52
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

  display: flex
  flex-flow: column nowrap

  opacity: 0
  transition: opacity .1s

  // Prevent flickering when font-size is unscaled
  &.visible
    opacity: 1

  .overlay-title
    color: var(--gray-lightest)
    font-size: 2em
    text-transform: uppercase
    font-family: var(--font-secondary)
    font-weight: 600

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .icon
    display: flex
    align-items: center
    justify-content: center
    padding-top: 1em
    padding-bottom: 1em
    height: 60%

    svg
      max-height: 100%
      max-width: 100%
      height: 60%
      width: 60%

  .message
    position: relative
    height: 40%
    width: 100%
    font-family: var(--font-secondary)
    background-color: #FBD544
    color: var(--gray-dark)

    .message-content
      position: absolute
      left: 2em
      right: 2em
      top: .5em
      bottom: .5em
</style>
