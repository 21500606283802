<script>
// Simple, External Google Apps' URL

export default {
  name: 'SimpleGoogleApps',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    iframeSrc () {
      const type = this.item.type || ''
      if (!type.length) { return '' }

      const location = this.item.url || this.item.location || ''

      switch (type) {
        case 'g-docs':
          // Force embedded mode
          return location.replace(/\/pub$/, '/pub?embedded=true')
        case 'g-sheets':
          // Force embedded mode
          if (location.indexOf('widget=true') === -1) {
            // - Entire Document embed
            if (location.match(/\/pubhtml$/)) {
              return `${location}?widget=true&amp;headers=false`
              // - Single Sheet embed
            } else {
              return `${location}&amp;widget=true&amp;headers=false`
            }
          }
          return location
        case 'g-calendar':
          // The embed code should have a src="URL", get that URL, then make sure its a gcal url
          const regexSrc = location.match(RegExp(/src="([^"]*)/))
          if (regexSrc && regexSrc.length > 1) {
            if (RegExp(/https:\/\/calendar.google.com\//).test(regexSrc[1])) {
              return regexSrc[1].replace(/&amp;/g, '&')
            }
          }
      }
      return ''
    }
  },

  methods: {
    loadedHandler () {
      this.$emit('loaded')
    }
  }
}
</script>

<template lang="pug">
section.simple-google-apps
  iframe.google-apps-frame(v-if="iframeSrc && iframeSrc.length"
         :src="iframeSrc"
         frame-border="0"
         sandbox="allow-scripts allow-same-origin allow-presentation"
         allow="autoplay; fullscreen"
         allowfullscreen
         seamless
         @load="loadedHandler")
</template>

<style lang="stylus">
section.simple-google-apps
  width: 100%
  height: 100%
  border: 0
  background: #fff
  position: relative

  .google-apps-frame
    position: relative
    width: 100%
    height: 100%
    border: 0
    z-index: 1
</style>
