<script>
import qs from 'qs'

export default {
  name: 'TeemFinderItem',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loadedTimer: undefined
    }
  },

  computed: {
    location () {
      return (this.item && (this.item.url || this.item.location)) || ''
    },

    iframeSrc () {
      const query = qs.parse(decodeURIComponent(this.location))
      if (query && query.url && !query.url.startsWith('http://') && !query.url.startsWith('https://')) {
        Log.error('app', `TeemFinder: Invalid URL "${query.url}"`, 'ERR_TEEMFINDERITEMURL', { url: query.url })
        return ''
      }
      return query.url
    }
  },

  mounted () {
    clearTimeout(this.loadedTimer)
  },

  beforeDestroy () {
    clearTimeout(this.loadedTimer)
  },

  methods: {
    iframeLoaded () {
      clearTimeout(this.loadedTimer)
      // wait 8s for teem finder app to load
      this.loadedTimer = setTimeout(() => {
        clearTimeout(this.loadedTimer)
        this.$emit('loaded')
      }, 8000)
    }
  }
}
</script>

<template lang="pug">
section.teem-finder-item
  iframe(:src="iframeSrc"
         frame-border="0"
         sandbox="allow-scripts allow-same-origin allow-presentation allow-forms"
         allow="autoplay; fullscreen"
         allowfullscreen
         allowtransparency
         seamless
         @load="iframeLoaded")
</template>

<style lang="stylus">
section.teem-finder-item
  width: 100%
  height: 100%
  border: 0
  background: #fff
  position: relative
  iframe
    width: 100%
    height: 100%
    border: 0
</style>
