<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'

import WeatherDefault from './weather/Default'
import WeatherPhoto from './weather/Photo'
import WeatherBlueSkies from './weather/BlueSkies'

import OfflineCaches from 'services/offline-caches'

import { mapGetters } from 'vuex'

const config = {
  // In second (1hr)
  REFRESH_INTERVAL: 3600,
  // In second (3hrs), define stale data valiation time
  CACHE_EXPIRY_TIME: 10800,
  // In second (5min), retry when service error occurs
  RETRY_INTERVAL: 300,

  // In ms. Fallback for cases when Player start offline -- WS never activated
  GET_TIMEOUT: 5000,

  // [1, 7], how many days to show in the `weekly` forecast
  DAYS_TO_SHOW: 5,

  // In vmin
  BASE_FONTSIZE: 1,

  // Width / Height ratio. Switch to `wide` layout when threshold reached
  IS_WIDE: {
    default: 4,
    photo: 4,
    blueskies: 4
  },

  // Width / Height ratio. Switch to `medium` layout when threshold reached
  IS_MEDIUM: {
    default: 1.5,
    photo: 2,
    blueskies: 1.6
  },

  // Width / Height ratio. Switch to `medium` layout when threshold reached
  IS_TALL: {
    default: 0.4,
    photo: 0.34,
    blueskies: 0.4
  },

  // In ms. Prepare next item in the background
  PREPARE_TIME: 2000
}

// Fallback location for non-device [DEV-2629]
const FALLBACK_DEVICE_LOC = 'Vancouver, CA'

export default {
  name: 'WeatherPage',

  components: {
    WeatherDefault,
    WeatherPhoto,
    WeatherBlueSkies
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  // Pass the config to descendants components
  provide: { config },

  data () {
    return {
      location: '',
      locationDisplayName: '',
      deviceLocation: '',
      locationList: undefined,
      locationDisplayNameList: [],

      useDeviceLocation: false,
      useMDYFormat: false,
      loadingDeviceLoc: false,

      theme: 'default',
      interval: 6,
      transition: '',
      hourlyForecast: false,
      dailyForecast: false,
      show24hr: false,
      temperatureUnit: '',
      titleFontScale: 1.0,

      showPrime: false,
      primeItem: undefined,
      primeItemDisplayName: undefined,
      baseItem: undefined,
      baseItemDisplayName: undefined,
      currentIndex: -1,

      hasError: false,
      errorMessage: '',

      prepareTimer: undefined,
      showNextTimer: undefined,
      singleItemTimer: undefined,
      timeoutTimer: undefined
    }
  },

  computed: {
    ...mapGetters({
      deviceLoc: 'deviceLocation',
      isDevice: 'isDevice',
      pageItemTransition: 'pageItemTransition'
    }),

    deviceLocKey () {
      if (!this.isDevice || !this.deviceLoc.length) { return }
      return `cityByZip_${this.deviceLoc}`
    },

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE[this.theme || 'default']
      }
      return false
    },

    isMedium () {
      if (!this.isPortrait && this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h < config.IS_MEDIUM[this.theme || 'default']
      }
      return false
    },

    isTall () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h < config.IS_TALL[this.theme || 'default']
      }
      return false
    },

    validItems () {
      if (!this.locationList || !this.locationList.length) { return [] }
      return this.locationList.filter(item => {
        return (item || '').trim().length
      })
    },

    wideRatio () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h
      }
      return 1
    },

    isNarrow () {
      return this.wideRatio > 6
    },

    transitionName () {
      return this.pageItemTransition(this.transition)
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
    clearTimeout(this.timeoutTimer)

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.singleItemTimer)
    clearTimeout(this.timeoutTimer)
  },

  methods: {
    render () {
      if (!this.item.url && !this.item.location) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || this.item.location), { arrayLimit: 100 })
      if (!options) { return }

      this.theme = options.theme || 'default'
      this.interval = Math.max(4, options.interval || 10)
      this.transition = options.transition || ''

      this.hourlyForecast = options.hourlyForecast === true || options.hourlyForecast === 'true'
      this.dailyForecast = options.dailyForecast === true || options.dailyForecast === 'true'
      this.show24hr = (options.show24hr === true || options.show24hr === 'true')
      this.temperatureUnit = options.temperatureUnit || ''

      this.loadingDeviceLoc = false
      this.useDeviceLocation = options.useDeviceLocation === true || options.useDeviceLocation === 'true'
      this.useMDYFormat = options.useMDYFormat === true || options.useMDYFormat === 'true'

      this.titleFontScale = +options.titleFontScale || 1.0

      // [A] Use Device Location
      if (this.useDeviceLocation) {
        this.location = ''

        // A1 - Only do zipcode query on a real device with "device.location" properly set
        if (this.isDevice && this.deviceLoc && this.deviceLoc.length) {
          this.deviceLocation = this.deviceLoc + ''
          this.locationList = [this.deviceLocation]
        // A2 - Use fallback location for
        // - non-device (preview mode or playlist editor), or
        // - the device's "device.location" not set.
        } else {
          this.deviceLocation = FALLBACK_DEVICE_LOC + ''
          this.locationList = [FALLBACK_DEVICE_LOC + '']
        }

      // [B] Use Manually Input Locations
      } else {
        this.location = options.sourcelocation
        this.locationDisplayName = options.sourcelocationDisplayNames
        this.locationList = (this.location || '').trim().split('|')
        this.locationDisplayNameList = (this.locationDisplayName || '').trim().split('|') || []
      }

      this.prepareNext(true)
    },

    prepareNext (isUpdate) {
      clearTimeout(this.showNextTimer)

      if (!this.validItems || !this.validItems.length) { return }

      // Force Reset
      if (isUpdate) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.validItems.length
      const nextItem = JSON.parse(JSON.stringify(this.validItems[nextIndex]))

      if (this.validItems.length > 1 || this.currentIndex < 0) {
        if (this.showPrime) {
          this.baseItem = nextItem
          this.baseItemDisplayName = this.locationDisplayNameList[nextIndex]
        } else {
          this.primeItem = nextItem
          this.primeItemDisplayName = this.locationDisplayNameList[nextIndex]
        }
      }

      // Is first init
      if (this.currentIndex < 0) {
        this.showNext(nextIndex)
      } else if (this.validItems.length > 1) {
        clearTimeout(this.showNextTimer)
        this.showNextTimer = setTimeout(() => {
          clearTimeout(this.showNextTimer)
          this.showNext(nextIndex)
        }, config.PREPARE_TIME)
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
      }
    },

    showNext (nextIndex) {
      clearTimeout(this.prepareTimer)

      const activeIndex = +this.currentIndex

      this.showPrime = !this.showPrime
      this.currentIndex = nextIndex

      if (!this.validItems.length) {
        return
      } else if (this.validItems.length === 1) {
        this.triggerSingleItemFinished()
        return
      }

      // Reaches the end of the list
      if (this.validItems.length && activeIndex >= this.validItems.length - 1) {
        this.$emit('finished')
      }

      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, (this.interval || 10) * 1000 - config.PREPARE_TIME)
    },

    triggerSingleItemFinished () {
      clearTimeout(this.singleItemTimer)
      this.singleItemTimer = setTimeout(() => {
        clearTimeout(this.singleItemTimer)
        this.$emit('single-item-finished')
      }, 60000)
    },

    // Fallbak for cases when Player starting offline [DEV-2635]
    timeoutChecking () {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        this.handleZipError(new Error(`Zip code fetching ${config.GET_TIMEOUT}ms timeout`))
      }, config.GET_TIMEOUT)
    },

    async handleZipError (err) {
      if (!this.deviceLocKey || !this.useDeviceLocation) { return }
      const staleData = await OfflineCaches.get(this.deviceLocKey)
      if (staleData && staleData.length) {
        this.deviceLocation = staleData
        this.locationList = [staleData]
        this.hasError = false
        this.errorMessage = ''
      } else {
        this.deviceLocation = ''
        this.locationList = undefined
        this.hasError = true
        this.errorMessage = err.message || err.toString() || 'Unknown zipcode fetching error'
      }
      this.loadingDeviceLoc = false
      this.prepareNext(true)
    }
  }
}
</script>

<template lang="pug">
section.weather-page
  .resize-sensor(ref="sensor")
  .weather-page-wrapper(v-if="locationList && !loadingDeviceLoc"
                        :class="[theme, {'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-medium': isMedium, 'is-wide': isWide, 'uninitialized': !baseFontSize}]"
                        :style="[fontSizeStyle, zonePaddingsStyle]")
    //- PHOTO THEME
    template(v-if="theme === 'photo'")
      template(v-if="useDeviceLocation")
        transition(:name="transitionName", :duration="500" appear)
          weather-photo.prime(
                  :location="deviceLocation"
                  :mdy-format="useMDYFormat"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-wide="isWide"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")
      template(v-else)
        //- Prime
        transition(:name="transitionName", :duration="500" appear)
          weather-photo.prime(v-show="showPrime"
                  :show="showPrime"
                  :mdy-format="useMDYFormat"
                  :location="primeItem || ''"
                  :locationDisplayName="primeItemDisplayName || ''"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-wide="isWide"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")
        //- Base
        transition(:name="transitionName", :duration="500" appear)
          weather-photo.base(v-show="!showPrime"
                  :show="!showPrime"
                  :mdy-format="useMDYFormat"
                  :location="baseItem || ''"
                  :locationDisplayName="baseItemDisplayName || ''"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-wide="isWide"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")
    //- BLUE SKIES THEME
    template(v-else-if="theme === 'blueskies'")
      template(v-if="useDeviceLocation")
        transition(:name="transitionName", :duration="500" appear)
          weather-blue-skies.prime(
                  :location="deviceLocation"
                  :mdy-format="useMDYFormat"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")
      template(v-else)
        //- Prime
        transition(:name="transitionName", :duration="500" appear)
          weather-blue-skies.prime(v-show="showPrime"
                  :show="showPrime"
                  :mdy-format="useMDYFormat"
                  :location="primeItem || ''"
                  :locationDisplayName="primeItemDisplayName || ''"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")
        //- Base
        transition(:name="transitionName", :duration="500" appear)
          weather-blue-skies.base(v-show="!showPrime"
                  :show="!showPrime"
                  :mdy-format="useMDYFormat"
                  :location="baseItem || ''"
                  :locationDisplayName="baseItemDisplayName || ''"
                  :show24hr="show24hr"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  @loaded="$emit('loaded')")

    //- DEFAULT THEME
    .weather-page-inner(v-else, :style="boxMarginStyle")
      template(v-if="useDeviceLocation")
        transition(:name="transitionName", :duration="500" appear)
          weather-default.prime(
                  :show="showPrime"
                  :mdy-format="useMDYFormat"
                  :location="deviceLocation"
                  :show24hr="show24hr"
                  :show-hourly="hourlyForecast"
                  :show-daily="dailyForecast"
                  :white-text="whiteText"
                  :hide-box="hideBox"
                  :hide-box-outline="hideBoxOutline"
                  :show-text-shadow="showTextShadow"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-narrow="isNarrow"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  :base-font-size="baseFontSize"
                  @loaded="$emit('loaded')")
      template(v-else)
        //- Prime
        transition(:name="transitionName", :duration="500" appear)
          weather-default.prime(v-show="showPrime",
                  :show="showPrime"
                  :mdy-format="useMDYFormat"
                  :location="primeItem || ''"
                  :locationDisplayName="primeItemDisplayName || ''"
                  :show24hr="show24hr"
                  :show-hourly="hourlyForecast"
                  :show-daily="dailyForecast"
                  :white-text="whiteText"
                  :hide-box="hideBox"
                  :hide-box-outline="hideBoxOutline"
                  :show-text-shadow="showTextShadow"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-narrow="isNarrow"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  :base-font-size="baseFontSize"
                  @loaded="$emit('loaded')")
        //- Base
        transition(:name="transitionName", :duration="500" appear)
          weather-default.base(v-show="!showPrime",
                  :show="!showPrime"
                  :mdy-format="useMDYFormat"
                  :location="baseItem || ''"
                  :locationDisplayName="baseItemDisplayName || ''"
                  :show24hr="show24hr"
                  :show-hourly="hourlyForecast"
                  :show-daily="dailyForecast"
                  :white-text="whiteText"
                  :hide-box="hideBox"
                  :hide-box-outline="hideBoxOutline"
                  :show-text-shadow="showTextShadow"
                  :is-portrait="isPortrait"
                  :is-medium="isMedium"
                  :is-wide="isWide"
                  :is-narrow="isNarrow"
                  :is-tall="isTall"
                  :temperature-unit="temperatureUnit"
                  :font-class="fontClass"
                  :font-scale="fontScale"
                  :title-font-scale="titleFontScale"
                  :base-font-size="baseFontSize"
                  @loaded="$emit('loaded')")

  //- Messages
  .message-background(v-if="hasError || (!locationList && !loadingDeviceLoc && !location && !deviceLocation)")
    .error-msg(v-if="hasError && errorMessage") {{ errorMessage }}
    .no-location(v-else) Unable to determine {{ useDeviceLocation ? 'device location' : 'location'}}
</template>

<style lang="stylus">
@import '../../style/widgets/weather.styl'

// Mixins sets
pngIcons()

section.weather-page
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  color: #fff
  z-index: 1

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .weather-page-wrapper
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 2

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch
    padding: 0

    .photo-weather-wrapper,
    .default-weather-wrapper,
    .blueskies-weather-item
      // Must be equal to the duration set in the Vue `<transition>` component
      animation-duration: 0.5s

    &.default
      .weather-page-inner
        position: relative
        flex: 1 1 0.00001px

  .message-background
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    background: url('https://content.telemetrytv.com/weather/sunny3.jpg')
    background-repeat: no-repeat
    background-position: bottom center
    background-size: cover

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    .error-msg,
    .no-location
      text-align: center
      padding: 0 1em
      font-size: 2em
</style>
