<script>
// MIXINS
import SdkCommonMixins from 'components/common/SdkCommonMixins.vue'
import SdkV2Mixins from 'components/common/SdkV2Mixins.vue'

import Moment from 'moment-timezone'

import Env from 'services/environment'
import Log from 'services/log'
import Webapp from 'services/webapp'

// In ms. Failsafe for Player starting offline
const INIT_TIMEOUT = 5000

// In seconds
const RECHECK_BUILDING_INTERVAL = 30

export default {
  name: 'FilesyncItem',

  // MIXIN
  mixins: [SdkCommonMixins, SdkV2Mixins],

  props: {
    filesyncId: { type: String, default: '' },
    filesyncToken: { type: String, default: '' },
    lastBuiltAt: { type: String, default: ''},

    idePreview: { type: Boolean, default: false },

    // For Debug
    webappId: { type: String, default: '' }
  },

  data () {
    return {
      loading: true,
      hasLastBuild: false,
      lastBuildTs: '',

      // Filesync are all in SDK v2
      sdkVersion: 2,

      // For detection in SdkCommonMixins
      isFilesync: true,

      status: 'loading',
      displayURL: '',

      errorMessage: '',

      bridgeFinished: false,

      initTimer: null,
      buildingTimer: null
    }
  },

  computed: {
    filesyncURL () {
      if (!this.filesyncToken || !this.filesyncToken.length) { return false }
      return Env.filesyncURL(this.filesyncToken)
    },

    iframeKey () {
      return this.filesyncURL
    },

    showMessage () {
      return !this.loading && !this.hasLastBuild && this.errorMessage.length
    }
  },

  watch: {
    filesyncId () {
      this.render()
    },
    filesyncToken () {
      this.render()
    },
    lastBuiltAt (newValue = '') {
      if (newValue && newValue.length) {
        this.render()
      }
    }
  },

  mounted () {
    window.addEventListener('message', this.messageHandler, false)
    clearTimeout(this.initTimer)
    clearTimeout(this.buildingTimer)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.initTimer)
    clearTimeout(this.buildingTimer)
    window.removeEventListener('message', this.messageHandler, false)
  },

  methods: {
    render () {
      if (!this.filesyncId || !this.filesyncId.length) { return }

      this.recheckAppFetching()

      this.checkBuildStatus()
    },

    checkBuildStatus (showDetails = false) {
      if (!this.filesyncId || !this.filesyncId.length) { return }

      Webapp.getFilesyncResult(this.filesyncId).then(res => {
        clearTimeout(this.initTimer)

        let errorMessage

        if (!res) {
          this.loading = false
          errorMessage = 'Unknown Filesync Status'
          this.errorMessage = errorMessage
          Log.debug('app', errorMessage, 'DBG_UNKNOWNFILESYNC', { filesyncId: this.filesyncId })
          this.sendLogsToEditor({ message: errorMessage, level: 'debug' })
          return
        }

        const newStatus = res.status
        const hasLastBuild = Boolean(res.last_built_at && res.last_built_at.length)

        this.status = newStatus
        this.hasLastBuild = hasLastBuild

        if (hasLastBuild) {
          this.lastBuildTs = Moment(res.last_built_at).valueOf() + ''
        } else {
          this.lastBuildTs = ''
        }

        if (newStatus !== 'success') {
          // Keep display the succesfully built website when the new build still in process
          this.setDisplayURL((hasLastBuild && this.filesyncURL) ? this.filesyncURL : '')
        }

        // Status: “syncing”, “building”, “canceled”, “success”, “failed”
        switch (newStatus) {
          case 'success':
            clearTimeout(this.buildingTimer)
            errorMessage = ''
            this.sendLogsToEditor({ message: 'Filesync successfully built', level: 'debug' })
            // Force clean URL to ensure we display the latest build result
            this.setDisplayURL()
            // Set to display in the next tick
            this.$nextTick(() => {
              this.setDisplayURL(this.filesyncURL ? this.filesyncURL : '')
            })
            break
          case 'building':
          case 'syncing':
            errorMessage = newStatus === 'building' ? 'Building in progress, please wait.' : 'Syncing repository, please wait.'
            this.sendLogsToEditor({ message: errorMessage, level: 'debug' })
            this.recheckBuilding()
            break
          case 'canceled':
            clearTimeout(this.buildingTimer)
            errorMessage = 'Filesync build canceled'
            this.sendLogsToEditor({ message: errorMessage, level: 'debug' })
            break
          default:
            clearTimeout(this.buildingTimer)
            errorMessage = `Filesync build ${newStatus} - ${res.last_error}`
            if (res.unlocked_at) {
              errorMessage += `\n(Locked until ${Moment(res.unlocked_at).format('HH:mm:ss MMM D, YYYY')})`
            }
            this.sendLogsToEditor({ message: errorMessage, level: 'error' })
            break
        }

        this.errorMessage = errorMessage
        if (newStatus !== 'success') {
          Log.debug('app', errorMessage, 'DBG_FILESYNCNOTSUCCESS', { filesyncId: this.filesyncId, status: newStatus })
        }

        // QA Debug
        if (showDetails) {
          if (newStatus === 'success') {
            console.debug('QA DEBUG: Filesync build success', res)
          } else {
            console.debug(`QA DEBUG: ${errorMessage}`, res)
          }
        }

        this.loading = false

      // Non-build errors
      }).catch(err => {
        clearTimeout(this.initTimer)

        this.status = 'error'
        const errorMessage = `Filesync Errored - ${err.message || err.toString()}`
        this.errorMessage = errorMessage
        Log.error('app', errorMessage, 'ERR_FILESYNC', { filesyncId: this.filesyncId, error: err })

        this.sendLogsToEditor({ message: errorMessage, level: 'error' })
        this.loading = false
      })
    },

    setDisplayURL (url = '') {
      if (!url.length) {
        this.displayURL = ''
      } else if (!url.startsWith('http://') && !url.startsWith('https://')) {
        this.displayURL = ''
        Log.error('app', `Filesync: Invalid URL "${url}"`, 'ERR_FILESYNCURL', { url })
      } else {
        // Append the timestamp to bump disk cache (in the browser level)
        this.displayURL = `${url}/?ts=${this.lastBuildTs}`
      }
    },

    iframeLoaded () {
      this.$emit('loaded')
    },

    fetchErrorHandler () {
      this.errorMessage = `Filesync ${INIT_TIMEOUT}ms Timeout`
    },

    recheckAppFetching () {
      clearTimeout(this.initTimer)
      this.initTimer = setTimeout(() => {
        clearTimeout(this.initTimer)
        this.fetchErrorHandler()
      }, INIT_TIMEOUT)
    },

    recheckBuilding () {
      clearTimeout(this.buildingTimer)
      this.buildingTimer = setTimeout(() => {
        clearTimeout(this.buildingTimer)
        this.checkBuildStatus()
      }, RECHECK_BUILDING_INTERVAL * 1000)
    },

    messageHandler (evt) {
      if (!evt || !evt.data) { return }
      if (evt.origin !== Env.userAppURL()) { return }
      if (!evt.data.filesyncID || evt.data.filesyncID !== this.filesyncId) { return }
      if (evt.data.type === 'ide-filesync-init') {
        if (!this.bridgeFinished) {
          const msg = {
            type: 'ide-bridge-finished',
            filesyncID: this.filesyncId,
            ts: new Date().getTime()
          }
          const parentWin = evt.source
          if (parentWin) {
            parentWin.postMessage(msg, Env.userAppURL())
            this.bridgeFinished = true
          }
        }
      }
      if (evt.data.type === 'ide-filesync-message') {
        if (evt.data.command === 'check-build-status') {
          this.checkBuildStatus(evt.data.showDetails || false)
        }
      }
    }
  }
}
</script>

<template lang="pug">
section.filesync-item
  iframe.filesync-frame(v-if="displayURL && displayURL.length"
        ref="iframe"
        :src="displayURL"
        @load="iframeLoaded"
        frame-border="0"
        sandbox="allow-scripts allow-same-origin allow-presentation allow-forms"
        allow="autoplay; fullscreen"
        allowtransparency
        allowfullscreen
        seamless)

  transition(name="fade" appear)
    .error-message(v-if="showMessage")
      p {{ errorMessage }}
      p.more-info {{ filesyncId }}
        template(v-if="webappId && webappId.length") &nbsp; (Webapp: {{ webappId }})
</template>

<style lang="stylus">
section.filesync-item
  width: 100%
  height: 100%
  position: absolute
  background: transparent

  > iframe.filesync-frame
    background: transparent
    position: relative
    width: 100%
    height: 100%
    border: 0

  .error-message
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 2

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    font-size: 1.2em
    padding: 0 1em
    text-align: center

    p
      white-space: pre-wrap

    .more-info
      opacity: 0.6
      font-size: 0.7em
      padding-top: 0.5em
</style>
