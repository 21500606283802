<script>
import RecipeList from './RecipeList.vue'
import AllergenIcon from './AllergenIcon'

import FastDom from 'fastdom'
import Moment from 'moment-timezone'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import TitanMenu from 'services/titan-menu.js'

const config = {
  // in ms
  SHOW_NEXT_DELAY: 800
}

export default {
  name: 'TitanMenuItem',
  components: {
    RecipeList,
    AllergenIcon
  },

  props: {
    menu: {
      type: Object,
      required: true
    },
    buildingId: {
      type: String,
      default: ''
    },
    menuId: {
      type: String,
      default: ''
    },
    columns: {
      type: Number,
      default: 3
    },
    interval: {
      type: Number,
      default: 20
    },
    customMenu: {
      type: String,
      default: ''
    },
    showMenuName: {
      type: Boolean,
      default: false
    },
    showBuildingName: {
      type: Boolean,
      default: false
    },
    showAllergies: {
      type: Boolean,
      default: false
    },
    showDate: {
      type: Boolean,
      default: false
    },
    nutritionalInfo: {
      type: Array,
      default: () => []
    },
    darkText: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: ''
    },
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      list: [],

      currentFirstIndex: 0,
      currentLastIndex: 0,
      currentChunk: [],

      resizing: true,

      // Prevent flickering
      isRendered: false,
      pendingChunks: undefined,

      // For width calculation
      metricChunk: [],

      debounceTimer: undefined,
      delayTimer: undefined,
      expireTimer: undefined,
      measureTimer: undefined
    }
  },

  computed: {
    columnStyle () {
      return {
        width: `${(100 / (this.columns || 3))}%`
      }
    },

    textStyle () {
      if (!this.textColor || !(this.textColor || '').trim().length) {
        return
      }
      return {
        color: this.textColor
      }
    },

    needsPaginate () {
      if (!this.list || !this.list.length || !this.currentChunk || !this.currentChunk) { return false }
      return this.currentChunk.length < this.list.length
    },

    sortedRecipesByCategories () {
      if (!this.menu || !this.menu.Recipes || !this.menu.Recipes.length) { return }
      const result = []
      this.menu.Recipes.forEach(item => {
        const targetIndex = result.findIndex(it => it.categoryName === item.RecipeCategory)
        const clonedItem = JSON.parse(JSON.stringify(item))
        if (targetIndex === -1) {
          result.push({
            categoryName: item.RecipeCategory + '',
            items: [clonedItem]
          })
        } else {
          if (!result[targetIndex].items) {
            result[targetIndex].items = []
          }
          result[targetIndex].items.push(clonedItem)
        }
      })
      return result
    },

    menuTitle () {
      if (this.customMenu !== '') {
        return this.customMenu
      }
      if (!this.showMenuName && !this.showBuildingName) { return }

      // Menu Name
      let menuName
      if (this.menuId) {
        menuName = TitanMenu.menuName(this.menuId)
      }
      if (!menuName) {
        // Use `ServingSession` (Lunch/Breadfast/...) when menu name cannot be determined (e.g. Requesing "all" menus)
        menuName = this.menu.ServingSession
      }

      // Building Name
      let buildingName
      if (this.buildingId) {
        buildingName = TitanMenu.buildingName(this.buildingId)
      }
      if (!buildingName) {
        buildingName = this.menu.Building
      }

      let title = ''
      if (this.showMenuName && this.showBuildingName) {
        title += menuName
        if (buildingName) {
          title += ` - ${buildingName}`
        }
      } else if (this.showMenuName) {
        title += menuName
      } else if (this.showBuildingName) {
        title += buildingName || ''
      }
      return title
    },

    // Display allergies listed in the menu only
    allergiesInThisMenu () {
      if (!this.menu || !this.menu.Recipes || !this.menu.Recipes.length) { return }
      // const list = {}
      const names = []
      this.menu.Recipes.forEach(item => {
        if (!item || !item.Allergiens || !item.Allergiens.length) { return }
        item.Allergiens.forEach(algName => {
          if (!names.includes(algName)) {
            names.push(algName)
          }
        })
      })
      // Sort Alphabetically
      names.sort((a, b) => {
        if (a > b) { return 1 }
        if (a < b) { return -1 }
        return 0
      })
      return names
    },

    servingDate () {
      if (!this.menu || !this.menu.Date) { return }
      return Moment(this.menu.Date, 'M/D/YYYY').format('ll')
    }
  },

  watch: {
    sortedRecipesByCategories: {
      deep: true,
      handler (newValue) {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)
  },

  methods: {
    render () {
      const items = JSON.parse(JSON.stringify(this.sortedRecipesByCategories || []))

      items.forEach((item, index) => {
        item.key = `menu-category-${index}`
      })

      this.list = items

      if (this.resizing) { return }

      this.getNewChunk()
    },

    getNewChunk (startIndex) {
      if (this.resizing) { return }

      this.currentFirstIndex = startIndex || 0
      this.currentLastIndex = startIndex || 0
      this.metricChunk = []
      this.isRendered = false
      this.pendingChunks = undefined

      this.addSingleItem()
    },

    playNext () {
      clearTimeout(this.delayTimer)

      const isLastChunk = this.currentLastIndex >= this.list.length - 1

      this.currentChunk = []
      this.currentFirstIndex = isLastChunk ? 0 : this.currentLastIndex + 1

      if (isLastChunk) {
        // Trigger to show next menu
        this.$emit('show-next')
      }

      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.getNewChunk(this.currentFirstIndex)
      }, config.SHOW_NEXT_DELAY)
    },

    addSingleItem () {
      clearTimeout(this.measureTimer)

      if (!this.list || !this.list.length || this.resizing || !this.list[this.currentLastIndex]) { return }

      const item = JSON.parse(JSON.stringify(this.list[this.currentLastIndex]))
      this.metricChunk.push(item)

      clearTimeout(this.measureTimer)
      this.measureTimer = setTimeout(() => {
        clearTimeout(this.measureTimer)
        this.checkAvailableWidth()
      }, 100)
    },

    renderList () {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      const list = JSON.parse(JSON.stringify(this.list))
      const nextChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)
      const pendingCategoriesName = nextChunk.map(cate => cate.categoryName)

      if (this.metricChunk.length === list.length) {
        // Skip animation when there's only one page
        this.pendingChunks = pendingCategoriesName
        this.currentChunk = nextChunk
        clearTimeout(this.expireTimer)
        this.expireTimer = setTimeout(() => {
          clearTimeout(this.expireTimer)
          // Trigger to show next menu
          this.$emit('show-next')
        }, this.interval * 1000)
      } else {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer)
          this.pendingChunks = pendingCategoriesName
          this.currentChunk = nextChunk
          clearTimeout(this.expireTimer)
          this.expireTimer = setTimeout(() => {
            clearTimeout(this.expireTimer)
            this.playNext()
          }, this.interval * 1000)
        }, 300)
      }
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.resizing = true
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizing = false
        this.render()
      }, timeout || 200)
    },

    checkAvailableWidth () {
      if (!this.$refs || !this.$refs.measure || this.resizing) { return }

      let availableWidth
      let containerWidth
      let measureWidth

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!this.$refs || !this.$refs.measure) {
          FastDom.clear(measure)
          return
        }

        containerWidth = this.$refs.measure.offsetWidth
        measureWidth = this.$refs.measure.scrollWidth

        let mutate = FastDom.mutate(() => {
          availableWidth = containerWidth - measureWidth

          if (availableWidth >= 0 && this.currentLastIndex < this.list.length - 1) {
            this.currentLastIndex++
            this.addSingleItem()
          } else if (availableWidth < 0) {
            this.currentLastIndex--
            this.metricChunk.pop()
            this.renderList()
          } else {
            this.renderList()
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    listRendered (category) {
      if (this.pendingChunks && this.pendingChunks.length) {
        const targetIndex = this.pendingChunks.findIndex(it => it === category.categoryName)
        if (targetIndex !== -1) {
          this.pendingChunks.splice(targetIndex, 1)
        }
        if (!this.pendingChunks.length) {
          this.isRendered = true
        }
      }
    }
  }
}
</script>

<template lang="pug">
mixin categoryItem(skipAnimation)
  if skipAnimation
    .category-title.invisible {{ category.categoryName }}
    recipe-list(:items="category.items"
                :show-allergies="showAllergies"
                :nutritional-info="nutritionalInfo"
                :skip-animation="true")
  else
    .category-title(:class="{'invisible': !isRendered}") {{ category.categoryName }}
    recipe-list(:items="category.items"
                :interval="interval"
                :show-allergies="showAllergies"
                :nutritional-info="nutritionalInfo"
                @rendered="listRendered(category)")

.titan-menu-item(:class="[fontClass, {'dark-text': !textStyle && darkText}]"
                 :style="[textStyle]")
  .resize-sensor(ref="sensor")

  template(v-if="menu")
    h1.menu-title(v-if="menuTitle") {{ menuTitle }}
    h3.serving-date(v-if="showDate && servingDate && servingDate.length", :class="{'no-title': !menuTitle}") {{ servingDate }}

    .menu-main-wrapper
      transition-group(tag="div" class="menu-main-inner visible-menu" name="page-fade")
        .menu-category(v-for="category in currentChunk"
                       :key="category.categoryName"
                       :style="columnStyle")
          +categoryItem

      .menu-main-inner.measure(ref="measure")
        .menu-category(v-for="category in metricChunk"
                       :style="columnStyle")
          +categoryItem(true)

      .menu-main-inner.invisible
        .menu-category(v-for="category in sortedRecipesByCategories"
                       :style="columnStyle")
          +categoryItem(true)

    footer.allergies-legend(v-if="showAllergies")
      ul.legend-list
        li(v-for="(name, index) in allergiesInThisMenu", :key="index")
          allergen-icon.alg-icon(:item="name")
          .alg-name {{ name }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.titan-menu-item
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 5
  padding: 0 1.5em;

  display: flex
  flex-flow: column nowrap
  justify-content: space-between
  align-items: stretch
  overflow: hidden

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .menu-title
    font-family: inherit
    font-size: 2em
    font-weight: 600
    padding: 0.5em 1em
    line-height: 130%
    margin: 0
    text-align: center

  .serving-date
    font-family: inherit
    font-size: 1.3em
    line-height: 130%
    font-weight: 300
    text-align: center
    padding: 0 1em 0.3em 1em
    margin: 0
    &.no-title
      padding-top: 1em

  .menu-main-wrapper
    flex: 1 1 0.00001px
    overflow: hidden
    position: relative
    z-index: 0

    .menu-main-inner
      overflow: hidden
      padding: 1em 0
      display: flex
      flex-flow: column wrap
      justify-content: flex-start
      align-items: flex-start
      align-content: flex-start

      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0

      &.invisible,
      &.measure
        visibility: hidden
        opacity: 0

      &.invisible
        z-index: -2

      &.measure
        z-index: -1

      &.visible-menu
        z-index: 1

    .menu-category
      box-sizing: border-box
      padding: 0 1.5em 2.3em 1.5em
      overflow: hidden

      .category-title
        font-size: 1.4em
        padding-bottom: 0.5em
        font-weight: 600
        ellipsis()

        // Prevent flickering before recipe list fully rendered
        &.invisible
          opacity: 0
          visibility: hidden

  .allergies-legend
    &:not(:empty)
      padding: 1em 0

    ul
      margin: -0.5em 0 0 0
      padding: 0
      display: flex
      flex-flow: row wrap
      justify-content: center
      align-items: center
      align-content: flex-start

      li
        list-style: none
        display: flex
        flex-flow: row wrap
        justify-content: flex-start
        align-items: center
        opacity: 0.7
        margin-left: 1.5em
        margin-top: 0.5em

        &:first-of-type
          margin-left: 0

        .alg-icon
          font-size: 1.2em

        .alg-name
          font-size: 0.8em
          font-weight: 300
          padding-left: 0.5em
          text-transform: capitalize

  &.dark-text
    color: $appDarkTextColor
</style>
