<script>
import Log from 'services/log.js'
import BrowserStorage from 'services/browserstorage.js'
import Environment from 'services/environment'
import { applicationSupport } from 'services/version-support'
import WindowsAPI from 'platform/windowsapi.js'
import ChromeAPI from 'platform/chromeapi.js'
import ElectronAPI from 'platform/electronapi.js'
import AndroidAPI from 'platform/androidapi.js'
import IosAPI from 'platform/iosapi.js'

// This component is used when the player is embedded to pick up containing app properties
export default {
  name: 'Embed',

  computed: {
    platform () {
      return (this.$route.params.platform || 'unknown').toLowerCase()
    },

    launcherMode () {
      return this.$route.name === 'embedPlatformLauncher'
    },

    iframeEmbedMode () {
      return this.$route.name === 'iframeEmbeddedPlaylist'
    },

    embedToken () {
      return this.$route.params.embedToken || ''
    },

    touch () {
      return this.$route.query.touch === 'true'
    }
  },

  created () {
    console.warn('Starting Embed')
    Log.info('player', `Starting up in Embed Mode for ${this.platform}`, 'INF_STARTUPEMBEDMODE')
  },

  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    Environment.appVersion = urlParams.get('version') || ''
    Environment.locked = ((urlParams.get('lock') || '') === 'true')
    Environment.hasNetworkSetup = ((urlParams.get('network_setup') || '') === 'true')

    // Set screen rotation from URL `rotate` parameter [DEV-1658]
    this.$store.commit('updateUrlRotation', urlParams.get('rotate'))

    // Determine Platform Availability
    if (this.platform === 'electron') {
      // Launcher mode for the Neo Electron Player [ENG-632]
      if (this.launcherMode && this.platform === 'electron') {
        Environment.launcherWebsocket = true
        this.$store.commit('updateLauncherWebsocketMode', true)
      }
      ElectronAPI.startup(Environment.appVersion, this.launcherMode).then(() => {
        Environment.electronAPI = true
        const telemetryOSVersion = urlParams.get('TelemetryOS') || ''
        Environment.releaseChannel = urlParams.get('release_channel') || ''
        Environment.telemetryOS = telemetryOSVersion
        if (telemetryOSVersion) {
          Environment.osName = 'TelemetryOS'
          Environment.osVersion = telemetryOSVersion
        } else {
          if (applicationSupport.electron(Environment.appVersion) === 'error-outdated') {
            this.invalidEmbed = true
          }
        }
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPIELEC', err)
      })
    } else if (this.platform === 'windows') {
      WindowsAPI.startup(Environment.appVersion).then(() => {
        Environment.windowsAPI = true
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPIWIN', err)
      })
    } else if (this.platform === 'chromeapp') {
      let simpleMessaging = false
      if (urlParams.get('simplemessaging')) {
        simpleMessaging = true
      }
      ChromeAPI.startup(Environment.chromeAppID, Environment.appVersion, simpleMessaging).then(() => {
        Environment.chromeAPI = true
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPICHROME', err)
      })
    } else if (this.platform === 'android') {
      AndroidAPI.startup(Environment.appVersion).then(() => {
        Environment.androidAPI = true
        Environment.touch = this.touch
        const androidVersion = urlParams.get('android') || ''
        if (androidVersion) {
          Environment.osName = 'Android'
          Environment.osVersion = androidVersion
        }
        Environment.Sideload = (urlParams.get('sideload') === 'true')
        Log.debug('player', `Android version "${androidVersion}" sideload "${Boolean(Environment.Sideload)}"`, 'DBG_ANDROIDVERSION')

        if (applicationSupport.android(Environment.appVersion) === 'error-outdated') {
          this.invalidEmbed = true
        }
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPIANDRID', err)
      })
    } else if (this.platform === 'firetv') {
      AndroidAPI.startup(Environment.appVersion).then(() => {
        Environment.androidAPI = true
        Environment.touch = this.touch
        Environment.FireTV = true
        const androidVersion = urlParams.get('android') || ''
        if (androidVersion) {
          Environment.osName = 'FireTV'
          Environment.osVersion = androidVersion
        }

        if (applicationSupport.android(Environment.appVersion) === 'error-outdated') {
          this.invalidEmbed = true
        }
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPIANDRID', err)
      })
    } else if (this.iframeEmbedMode) {
      Log.info('player', 'Running in iframe Embed mode', 'INF_RUNNINGIFRAMEEMBED')
      if (urlParams.get('region')) {
        Environment.overrideRegion = urlParams.get('region')
      }
      if (this.embedToken && this.embedToken.length) {
        Environment.iframeEmbedMode = true
        Environment.embedToken = this.embedToken + ''
        Log.warn('player', 'Supplied from Embed with an Embed Token', 'WAR_SUPPLIEDEMBEDTOKEN')
        this.$store.commit('setAuthenticationToken', this.embedToken)
      }
    } else if (this.platform === 'ios') {
      Environment.ioswebkit = true // force true as IOS 13 pretends to be safari, even under webkit
      IosAPI.startup(Environment.appVersion).then(() => {
        Environment.iosAPI = true
      }).catch(err => {
        Log.error('player', err.message || err.toString() || `${err}`, 'ERR_UNABLESTARTAPIIOS', err)
      })
    } else if (this.platform === 'zoom') {
      // [DEV-1955] Embed route for "Zoom Rooms"
      Environment.zoomRooms = true
    } else if (this.platform === 'desktop') {
      Environment.desktopMode = true
      if (urlParams.get('playlist_id')) {
        Environment.previewPlaylistID = urlParams.get('playlist_id')
      }
      if (urlParams.get('page_id')) {
        Environment.previewPageID = urlParams.get('page_id')
        if (urlParams.get('mark_landing_page')) {
          Environment.hasLandingPage = (urlParams.get('mark_landing_page') === 'true')
        }
      }
      if (urlParams.get('loop_playlist')) {
        Environment.loopPreviewPlaylist = (urlParams.get('loop_playlist') === 'true')
      }
      if (urlParams.get('in_play_section')) {
        Environment.inPlaySection = (urlParams.get('in_play_section') === 'true')
      }
      if (urlParams.get('from_playlist_editor')) {
        Environment.fromPlaylistEditor = (urlParams.get('from_playlist_editor') === 'true')
      }
      if (urlParams.get('region')) {
        Environment.overrideRegion = urlParams.get('region')
      }
    } else {
      Log.warn('player', `Unknown Embed Platform "${this.platform}"`, 'WAR_UNKNOWEMBEDPLATFORM')
    }

    // Look for Universal URL Params
    let isUserSession = false
    if (urlParams.get('provisioning_token')) {
      Environment.provisioned = true
      const token = urlParams.get('provisioning_token')
      const name = urlParams.get('name')
      const uniqueAssetID = urlParams.get('unique_asset_id')
      Log.warn('player', 'Supplied from Embed with a provisioning token', 'WAR_SUPPLIEDPROVTOKEN')
      this.$store.commit('setProvisioningToken', {token, name})
      if (uniqueAssetID && uniqueAssetID.length) {
        Log.warn('player', 'Supplied from Embed with a device unique asset id', 'WAR_UNIQUEASSETID')
        this.$store.commit('setUniqueAssetID', uniqueAssetID)
      }
    } else if (urlParams.get('device_token')) {
      Log.warn('player', 'Supplied from Embed with a Device Token', 'WAR_SUPPLIEDDEVICETOKEN')
      const token = urlParams.get('device_token')
      this.$store.commit('setAuthenticationToken', token)
    } else if (urlParams.get('session_token') || urlParams.get('user_token')) {
      isUserSession = true
      Log.warn('player', 'Supplied from Embed with a User Token', 'WAR_SUPPLIEDUSERTOKEN')
      const token = urlParams.get('session_token') || urlParams.get('user_token')
      BrowserStorage.useSessionStorage()
      this.$store.commit('setAuthenticationToken', token)
    } else if (urlParams.get('legacy_device_token')) {
      Log.warn('player', 'Supplied from Embed with a ChromeOS Legacy App Device Token (EDM Tag)', 'WAR_LEGACYDEVICETOKEN')
      const token = urlParams.get('legacy_device_token')
      this.$store.commit('setAuthenticationToken', token)
    }
    if (urlParams.get('chrome_device_id')) {
      const chromeDeviceID = urlParams.get('chrome_device_id')
      Environment.chromeDeviceID = chromeDeviceID
      this.$store.commit('setChromeDeviceID', chromeDeviceID)
    }
    if (urlParams.get('device_serial_number')) {
      const deviceSerialNumber = urlParams.get('device_serial_number')
      this.$store.commit('setSerialNumber', deviceSerialNumber)
    }
    if (urlParams.get('device_model')) {
      const deviceModel = urlParams.get('device_model')
      this.$store.commit('setModel', deviceModel)
    }

    // temp sessions
    if (isUserSession) {
      if (urlParams.get('playlist_id')) {
        Environment.previewPlaylistID = urlParams.get('playlist_id')
      }
      if (urlParams.get('page_id')) {
        Environment.previewPageID = urlParams.get('page_id')
        if (urlParams.get('mark_landing_page')) {
          Environment.hasLandingPage = (urlParams.get('mark_landing_page') === 'true')
        }
      }
      if (urlParams.get('template_id')) {
        Environment.pageTemplateID = urlParams.get('template_id')
      }
      if (urlParams.get('preview_mode')) {
        Environment.previewMode = (urlParams.get('preview_mode') === 'true')
      }
      if (urlParams.get('page_edit_mode')) {
        Environment.pageEditMode = (urlParams.get('page_edit_mode') === 'true')
      }
      if (urlParams.get('in_play_section')) {
        Environment.inPlaySection = (urlParams.get('in_play_section') === 'true')
      }
      if (urlParams.get('from_playlist_editor')) {
        Environment.fromPlaylistEditor = (urlParams.get('from_playlist_editor') === 'true')
      }
      if (urlParams.get('mute_videos')) {
        Environment.muteVideos = (urlParams.get('mute_videos') === 'true')
      }
      if (urlParams.get('loop_playlist')) {
        Environment.loopPreviewPlaylist = (urlParams.get('loop_playlist') === 'true')
      }
      if (urlParams.get('region')) {
        Environment.overrideRegion = urlParams.get('region')
      }
    }

    // Redirect to startup
    if (Environment.previewPlaylistID || Environment.pageTemplateID) {
      this.$router.push({
        name: 'starting'
      })
    } else if (this.platform === 'electron' || this.platform === 'android' || this.platform === 'ios') {
      // The electron, ios & android app has the bumper embedded
      this.$router.push({
        name: 'starting'
      })
    } else {
      this.$router.push({
        name: 'bumper'
      })
    }
  }
}
</script>

<template lang="pug">
section#embed_start
</template>

<style lang="stylus">
section#embed_start
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
</style>
