<script>
/* global YT */

import Spinner from 'components/common/Spinner.vue'

import Moment from 'moment-timezone'
import qs from 'qs'

// For Edge debug
import Bowser from 'bowser'

import YouTube from 'services/youtube.js'
import Log from 'services/log.js'
import Utils from 'services/utils.js'
import { EventBus } from 'services/eventbus.js'

const config = {
  // Check YT iFrame API availablity
  // - In ms. Check if YT service is ready (possible network issue)
  CHECK_INTERVAL: 500,
  // - Maximum times to check YT failure. When this threshold is reached, prompt the Render to play next page.
  // - 500 * 60 => 30s
  SKIP_AFTER: 60,

  // Paused / Stall / Unstarted
  // - in ms. Interval to retry playing video when paused
  PAUSE_RECHECK_INTERVAL: 10000,
  // - Times of playback failure. When this threshold is reached, prompt the Render to play next page.
  // - 5000 * 6 => 30s
  SKIP_RECHECK_AFTER: 6,

  // Restart
  // - In ms. Try restart after this interval
  // - for preview outside Renderer, or when there's only one valid page in the Playlist
  RESTART_AFTER: 1200,

  // Failsafe (DEV-387)
  // - In second. Force advance to next page after: video duration + this delta value
  FORCE_ADVANCE_DELTA: 30,
  // - In second. Force advance after 12hrs
  MAX_VIDEO_LENGTH: 43200,

  // Delay 150/101 error message [ENG-898]
  // - In ms. Delay showing error message till defined time
  DELAY_ERR_MSG_AFTER: 8000
}

export default {
  name: 'YoutubeItem',
  components: {
    Spinner
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { return {} }
    },
    // Options override from other apps like Twitter
    overrideOptions: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      player: undefined,

      urlType: undefined,
      startAt: 0,
      endAt: null,
      playingVideo: false,
      errorMessage: undefined,
      options: undefined,
      mute: false,
      shuffle: false,
      tapInteraction: false,
      currentPlaylist: [],
      validVideos: [],
      channelId: '',
      playlistId: '',
      videoId: '',

      loading: true,
      youtubeReady: false,
      gotPlayingEvent: false,
      delayingErrorMessage: false,

      isManuallyPaused: false,

      networkTimerCount: 0,
      stallTimerCount: 0,
      playerTimerCount: 0,

      recheckTimer: undefined,
      bufferingTimer: undefined,
      endOfContentTimer: undefined,
      createPlayerTimer: undefined,
      retryTimer: undefined,
      endsAtTimer: undefined,
      restartTimer: undefined,
      forceAdvanceTimer: undefined,
      delayErrMsgTimer: undefined,
      maxVideoLengthTimer: undefined
    }
  },

  computed: {
    showURL () {
      if (this.errorMessage && this.errorMessage.length && !this.videoId) {
        return true
      }
      if (!this.options || !this.options.appType) { return false }
      return !YouTube.isYoutube247App(this.options.appType)
    },
    playerID () {
      return `yt_player_${this._uid}`
    },
    usingPlaylistForChannel () {
      return this.urlType === 'channel' && this.playlistId && this.playlistId.length
    },
    forceMute () {
      // When viewing in Safari, Or being embedded (in js_user etc), force mute all videos
      return this.isSafari ||
        (this.$route && this.$route.query && (
          (this.$route.query.forceMute === 'true' || this.$route.query.forceMute === true) ||
          (this.$route.query.mute_videos === 'true' || this.$route.query.mute_videos === true)
        ))
    },
    itemUrl () {
      if (!this.item) { return }
      return this.item.url || this.item.location
    },
    // For Edge debug
    browserInfo () {
      const bs = Bowser.getParser(window.navigator.userAgent)
      return bs.getBrowser() || {}
    },
    // For Edge debug
    isWinEdge () {
      return (this.browserInfo.name || '').toLowerCase() === 'edge'
    },
    // Force mute all videos in Safari [DEV-1047]
    isSafari () {
      return (this.browserInfo.name || '').toLowerCase() === 'safari'
    }
  },

  watch: {
    // [DEV-1191]
    // Watch item url instead of item object to prevent the same Youtube reloads after page switching
    // when it's been placing in the same grid across different pages
    itemUrl (newValue, oldValue) {
      if (oldValue && newValue) {
        this.itemChanged(this.item)
      }
    },

    overrideOptions: {
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.itemChanged(newValue)
        }
      }
    }
  },

  mounted () {
    clearTimeout(this.endOfContentTimer)
    clearTimeout(this.restartTimer)
    clearTimeout(this.delayErrMsgTimer)
    this.clearFallbackTimers()

    EventBus.$on('youtube-iframe-api-ready', this.onIframeApiReady)

    // Init the Player for the first time
    window.onYouTubeIframeAPIReady = () => {
      // To work with multiple YouTube apps within one playlist page
      EventBus.$emit('youtube-iframe-api-ready')
    }

    this.render()
  },

  beforeDestroy () {
    this.clearFallbackTimers()
    clearTimeout(this.endOfContentTimer)
    clearTimeout(this.restartTimer)
    clearTimeout(this.delayErrMsgTimer)

    EventBus.$off('youtube-iframe-api-ready', this.onIframeApiReady)

    if (this.player && typeof this.player.destroy === 'function') {
      this.player.destroy()
    }
    this.player = null
  },

  methods: {
    onIframeApiReady () {
      if (!this.youtubeReady) {
        this.youtubeReady = true
        this.$nextTick(() => {
          this.createPlayer()
        })
      }
    },

    itemChanged (newValue) {
      if (newValue && (newValue.url || newValue.location)) {
        // Force clear stored ids when updated option is sent from other apps
        this.playlistId = ''
        this.channelId = ''
        this.validVideos = []
        this.mute = this.forceMute || (newValue.mute === 'true' || newValue.mute === true)
        this.clearFallbackTimers()

        this.$nextTick(() => {
          this.render(true)
        })
      }
    },

    render (isUpdate) {
      if (!this.overrideOptions && (!this.item || (!this.item.url && !this.item.location))) { return }

      if (this.overrideOptions) {
        this.options = JSON.parse(JSON.stringify(this.overrideOptions || {}))
      } else {
        // Prevent accidentally inputted hashtag (#) breaking the url
        const arg = (this.item.url || this.item.location || '').replace(/#/g, '%23')

        // Is YouTube 24x7 apps
        if (arg.indexOf('appType') >= 0) {
          this.options = qs.parse(decodeURIComponent(arg))
        } else {
          // Hotfix: Arguments have been double encoded when adding to the Playlist Page
          // > E.g. `url%3Dhttps%253A%252F%252Fyoutu.be%252F8bw1if8BhBM%26showCaptions%3Dfalse`
          if (arg && arg.indexOf('url=https') === -1) {
            this.options = qs.parse(decodeURIComponent(arg))
          } else {
            this.options = qs.parse(arg)
          }
        }
      }

      this.mute = this.forceMute || (this.options.mute === 'true' || this.options.mute === true)
      this.shuffle = this.options.shuffle === 'true' || this.options.shuffle === true
      this.tapInteraction = this.options.tapInteraction === 'true' || this.options.tapInteraction === true
      this.startAt = YouTube.getStartAtFromURL(this.options.url)
      this.endAt = YouTube.getEndAtFromURL(this.options.url)
      this.urlType = YouTube.getTypeFromURL(this.options.url)

      if (this.urlType === 'channel') {
        this.initChannel(isUpdate)
      } else if (this.urlType === 'playlist') {
        this.initPlaylist(isUpdate)
      } else {
        this.initSingleVideo(isUpdate)
      }
    },

    endOfContent () {
      this.$emit('finished')
    },

    onPlayerReady (event) {
      // NOTE @ Jan 30, 2018
      // Try fixing unmuted playback [APP-2850]
      const YTiframe = document.getElementById(this.playerID)
      if (YTiframe) {
        YTiframe.setAttribute('allow', 'autoplay')
      }

      this.youtubeReady = true

      // Log.debug('app', 'YouTube onPlayerReady', event)
      Log.debug('app', 'Ready for YouTube Video', 'DBG_YOUTUBEVIDEOREADY', this.videoId)

      const player = this.player

      // Force mute videos due to Chrome Autoplay Policy Changed, finally released on Jan 2018
      // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      if (this.mute || this.forceMute) {
        player.mute()
      }

      clearInterval(this.endsAtTimer)

      // Fallback for `end=N` not working for the first time
      if (this.endAt && this.endAt > 0) {
        this.endsAtTimer = setInterval(() => {
          this.recheckEndTime()
        }, 1000)
      }

      if (this.videoId && this.player && typeof this.player.loadVideoById === 'function') {
        this.loadVideoById()
      } else {
        this.player.playVideo()
      }

      this.recheckBuffering(player, 30000)
    },

    async onPlayerError (event) {
      // Log.debug('app', 'YouTube: onPlayerError', event)
      Log.debug('app', 'YouTube Error', 'DBG_YOUTUBEERR', event.data)
      this.playingVideo = false
      const displayVideoID = (this.videoId ? ` (${this.videoId})` : '')

      // The request contains an invalid parameter value
      if (event.data === 2) {
        this.errorMessage = `Invalid YouTube Video ID${displayVideoID}`

      // The requested content cannot be played in an HTML5 player
      } else if (event.data === 5) {
        this.errorMessage = `Can not start HTML5 YouTube Video${displayVideoID}`

      // The video requested was not found
      } else if (event.data === 100) {
        this.errorMessage = `YouTube Video Not Found${displayVideoID}\nThe video has been removed or has been marked as private`

      // Embedding Disallowed by Owner
      } else if (
        event.data === 101 || event.data === 150 ||
        event.data === '101' || event.data === '150'
      ) {
        // The YouTube API sends string "150", instead of number 150 [DEV-4050]
        // However, the stream is still playable a couple seconds later
        if (typeof event.data === 'string') {
          Log.debug('app', `YouTube API returns a string "${event.data}" instead of an error code${displayVideoID}`, 'DBG_ERRCODE150')
        }

        // Delay displaying error message [ENG-898]
        this.delayErrorMessage(
          `YouTube Video Embedding Not Permitted - Error Code: ${event.data}${displayVideoID}\nThe owner of the requested video does not allow it to be played outside YouTube`,
          event
        )

      // Unable to contact YouTube server, or, no valid videos found
      } else if (event.message) {
        this.errorMessage = event.message

      // Unknown error
      } else {
        this.errorMessage = `YouTube Error "${event.data}"${displayVideoID}`
        // Also send error messge to device log
        Log.warn('app', this.errorMessage, 'WAR_YOUTUBEERR')
      }

      if (this.delayingErrorMessage) {
        // Display the error message after a while
        return
      }

      // Hanle error immediately
      this.playbackErrorFallback(event)
    },

    onPlayerStateChange (event) {
      // -1 – unstarted
      // 0 – ended
      // 1 – playing
      // 2 – paused
      // 3 – buffering
      // 5 – video cued
      // Log.debug('app', 'onPlayerStateChange', event)
      switch (event.data) {
        case YT.PlayerState.PLAYING:
          Log.debug('app', 'Playing YouTube video', 'DBG_YOUTUBEPLAY', null, true)
          this.errorMessage = undefined

          clearTimeout(this.restartTimer)
          clearTimeout(this.bufferingTimer)
          clearTimeout(this.retryTimer)
          clearTimeout(this.delayErrMsgTimer)
          clearTimeout(this.createPlayerTimer)
          this.delayingErrorMessage = false
          this.stallTimerCount = 0
          this.playerTimerCount = 0

          this.loaded()
          this.playingVideo = true
          const duration = this.player.getDuration()

          if (duration > 0 && !this.gotPlayingEvent) {
            YouTube.isLive(this.videoId).then(isLive => {
              if (isLive) {
                Log.debug('app', 'This YouTube video is live', 'DBG_YTBVIDEOLIVE')
                this.$emit('duration', -1)
              } else {
                Log.debug('app', `This YouTube video is ${duration} seconds long`, 'DBG_YTBVIDEODURATION')
                this.$emit('duration', duration)
              }
            }).catch(err => {
              this.netWorkErrorHandler(err, `Get YouTube liveStreamingDetails error - ${err.message || err.toString()}`)
            })

            // Force advance after duration + delta
            clearTimeout(this.forceAdvanceTimer)
            this.forceAdvanceTimer = setTimeout(() => {
              clearTimeout(this.forceAdvanceTimer)
              this.forceAdvance()
              // Max value for setTimeout is 2147483647 (DEV-1846)
            }, (Math.min(config.MAX_VIDEO_LENGTH, config.FORCE_ADVANCE_DELTA + duration)) * 1000)

            // Force advance after max youtube video length
            clearTimeout(this.maxVideoLengthTimer)
            this.maxVideoLengthTimer = setTimeout(() => {
              clearTimeout(this.maxVideoLengthTimer)
              this.forceAdvance()
            }, config.MAX_VIDEO_LENGTH * 1000)
          }

          this.gotPlayingEvent = true
          break

        case YT.PlayerState.ENDED:
          Log.debug('app', 'Ended playing YouTube video', 'DBG_YTBVIDEOENDED')
          this.playingVideo = false

          if (YouTube.isYoutube247App(this.options.appType) || this.urlType === 'video' || this.options.playOnlyOneVideo === 'true') {
            this.endOfContent()
          } else if (this.urlType === 'playlist' && !this.currentPlaylist.length && this.options.playOnlyOneVideo !== 'true') {
            // - Is Playlist type
            // - "One at a time" option is toggled OFF
            // - Reached the end of playlist
            Log.debug('app', 'Reach the end of YouTube playlist', 'DBG_YTBENDOFPLAYLIST')
            this.endOfContent()
          }

          this.handleVideoEnded()
          break

        case YT.PlayerState.PAUSED:
        case YT.PlayerState.UNSTARTED:
          if (this.delayingErrorMessage) { return }

          Log.debug('app', event.data === YT.PlayerState.PAUSED ? 'YouTube: Paused playing video' : 'YouTube: Video unstarted', 'DBG_PLAYERSTATE')

          if (event && event.data === YT.PlayerState.UNSTARTED) {
            if (this.gotPlayingEvent) {
              // If it played previously and now we got UNSTARTED event, that means the previous video has ended.
              if (this.options.playOnlyOneVideo === 'true') {
                this.endOfContent()
                this.handleVideoEnded()
                return
              }
            }

            this.gotPlayingEvent = false
            this.playingVideo = false
            this.loading = true

            // For debug
            Log.debug('app', 'YouTube Playback Unstarted Browser Info', 'DBG_YTBBROWSERINFO', JSON.stringify(this.browserInfo))

            // Possible fix for Edge - Edge might refuse to play the video when the iframe is not visible
            // https://app.bugsnag.com/telemetry/player/errors/5c620611b635360019ad4cc6
            if (this.isWinEdge) {
              this.playingVideo = true
              this.loaded()
              Log.debug('app', 'YouTube Debug For Edge:', 'DBG_YTBEDGE', JSON.stringify(event))
            }
          }

          if (this.isManuallyPaused) {
            return
          }

          // Try to replay
          // (not 100% working in Safari and Chrome v65+ with unmuted video, though)
          Log.debug('app', 'Retry playing YouTube video', 'DBG_YTBRETRY')

          // Target src might have been removed/unset before YT Player is ready (probably Eletron and Edge specific)
          // https://app.bugsnag.com/telemetry/player/errors/5c620611b635360019ad4cc6
          if (event && event.target && typeof event.target.playVideo === 'function') {
            event.target.playVideo()
          }
          this.recheckPause()
          break

        case YT.PlayerState.CUED:
          Log.debug('app', 'YouTube Cued video', 'DBG_YTBCUE')
          this.gotPlayingEvent = false
          this.loading = true
          this.playingVideo = false
          this.recheckPause()
          break

        case YT.PlayerState.BUFFERING:
          this.recheckBuffering(this.player, 30000)
          break
      }
    },

    delayErrorMessage (message = '', event) {
      this.delayingErrorMessage = true
      clearTimeout(this.delayErrMsgTimer)
      this.delayErrMsgTimer = setTimeout(() => {
        clearTimeout(this.delayErrMsgTimer)
        if (this.player && this.player?.getPlayerState?.() === YT.PlayerState.UNSTARTED) {
          this.errorMessage = message
          this.playingVideo = false
          this.delayingErrorMessage = false
          this.playbackErrorFallback(event)
        }
      }, config.DELAY_ERR_MSG_AFTER)
    },

    async playbackErrorFallback (event) {
      if (this.videoId) {
        // Added the issued videoId to played list in order to advance
        await YouTube.addPlayedVideo(this.playlistId || this.channelId, this.videoId)
        this.updateValidList(this.videoId)
      }

      this.clearFallbackTimers()

      clearTimeout(this.endOfContentTimer)
      this.endOfContentTimer = setTimeout(() => {
        clearTimeout(this.endOfContentTimer)
        this.endOfContent()

        // Prevent re-render single video with certain known errors
        if (this.urlType === 'video' && [2, 5, 100, 101, 150].includes(event.data)) {
          // Also send error messge to device log
          if (this.errorMessage) {
            Log.warn('app', this.errorMessage, 'WAR_YOUTUBEERR2')
          }
          return
        }
        // Prevent sending duplicate error requests for invalid id or network errors
        if (
          event && event.customError &&
          (event.message || '').indexOf('Unable to start') === -1 &&
          (event.message || '').indexOf('buffering timeout') === -1
        ) {
          // `No videos found...` etc errors will be captured here
          return
        }

        // Display the loading spin again when loading the next video
        this.errorMessage = undefined

        // For `Unable to start playing video ...` and `Buffering Timeout` errors, which might due to the temporal network failure
        // Try playing the next video in the row
        this.render(true)
      }, 4000)
    },

    async getVideosIdsFromYouTubeResponse (videos) {
      let videosIds = []
      const filteredVideos = [].concat([], videos)

      filteredVideos.forEach(video => {
        // Skip Live streams which are still in schedule (not broadcast)
        if (video && video.snippet && video.snippet.liveBroadcastContent === 'upcoming') { return }

        let videoId
        // Results from playlistItems list
        if (this.usingPlaylistForChannel || this.urlType === 'playlist') {
          videoId = video.snippet.resourceId.videoId
        // Results are from YouTube Search query
        } else {
          videoId = video.id.videoId
        }

        if (videoId) {
          videosIds.push(videoId)
        }
      })

      const playedVideos = await YouTube.getPlayedVideos(this.playlistId || this.channelId)
      if (playedVideos.length) {
        videosIds = videosIds.filter(v => !playedVideos.includes(v))
      }

      if (!videosIds.length) {
        await YouTube.removePlayedVideos(this.playlistId || this.channelId)
        const result = await this.getVideosIdsFromYouTubeResponse(videos)
        return result
      }

      this.validVideos = [].concat([], videosIds)

      return videosIds
    },

    async setPlaylistAndVideoId (videosIds, isUpdate, startVideoId) {
      if (videosIds.length > 0) {
        if (this.shuffle) {
          videosIds = Utils.shuffle(videosIds)
        }
        this.videoId = startVideoId || videosIds.shift()
        this.currentPlaylist = videosIds
        await this.initPlayer(isUpdate)
        return
      }

      Log.info('app', 'No YouTube videos to show', 'INF_YOUTUBENOVIDEO')
      this.loaded()
      this.playingVideo = false

      this.endOfContent()
    },

    recheckBuffering (player, timeout) {
      clearTimeout(this.bufferingTimer)
      this.bufferingTimer = setTimeout(() => {
        clearTimeout(this.bufferingTimer)
        if (
          player.getPlayerState() !== YT.PlayerState.BUFFERING &&
          player.getPlayerState() !== YT.PlayerState.UNSTARTED
        ) {
          return
        }

        // Still buffering after timeout
        if (player.getCurrentTime() === 0 && player.getDuration() === 0) {
          this.netWorkErrorHandler('Unable to start', `Unable to start playing video (${this.videoId})`)
        } else {
          this.netWorkErrorHandler('Buffering Timeout', `Video buffering timeout (${this.videoId})`)
        }
      }, timeout || 15000)
    },

    recheckPause () {
      // NOTE: when `!this.videoId` is undefined, means the input videoId is invalid
      if (this.stallTimerCount > config.SKIP_RECHECK_AFTER || !this.videoId) {
        clearTimeout(this.retryTimer)
        // Chrome v65+ fallback message
        if (this.player && this.player.getPlayerState() === YT.PlayerState.UNSTARTED) {
          this.netWorkErrorHandler('Unable to start', `Unable to start playing video (${this.videoId})`)
        } else {
          this.netWorkErrorHandler()
        }
        return
      }
      clearTimeout(this.retryTimer)
      this.retryTimer = setTimeout(() => {
        clearTimeout(this.retryTimer)
        this.stallTimerCount++
        const playerState = this.player.getPlayerState()
        if (playerState === YT.PlayerState.PAUSED || playerState === YT.PlayerState.UNSTARTED) {
          Log.debug('app', 'Retry playing YouTube again', 'DBG_YTBRETRY2')
          this.player.playVideo()
          this.recheckPause()
        }
      }, config.PAUSE_RECHECK_INTERVAL)
    },

    // Either natual ended (YT.PlayerState.ENDED) or force ended (by timer)
    async handleVideoEnded () {
      this.clearFallbackTimers()

      const videoId = (this.videoId || '') + ''
      await YouTube.addPlayedVideo(this.playlistId || this.channelId, videoId)
      this.updateValidList(videoId)

      // Fallback for preview outside Renderer (e.g. js_user)
      // Or when there's only one valid page in the Playlist
      clearTimeout(this.restartTimer)
      this.restartTimer = setTimeout(() => {
        clearTimeout(this.restartTimer)
        // - For Single Video
        if (this.urlType === 'video') {
          this.initSingleVideo(true)
        // - For channels
        } else if (this.urlType === 'channel' && !this.overrideOptions) {
          this.initChannel(true)
        // - For Playlists
        } else if (this.urlType === 'playlist') {
          this.initPlaylist(true)
        }
      }, config.RESTART_AFTER)
    },

    recheckEndTime () {
      if (!(this.endAt && this.endAt > 0) || !this.player) {
        clearInterval(this.endsAtTimer)
        return
      }
      const playedTime = this.player.getCurrentTime()
      if (playedTime >= this.endAt) {
        clearInterval(this.endsAtTimer)
        Log.debug('app', `End YouTube Video at ${this.endAt}s`, 'DBG_YTBCHECKENDTIME')
        this.playingVideo = false
        this.endOfContent()
        this.handleVideoEnded()
      }
    },

    forceAdvance () {
      Log.info('app', 'YouTube app: Force advance to next page', 'INF_YOUTUBEFORCEADVANCE')
      this.playingVideo = false
      this.endOfContent()
      this.handleVideoEnded()
    },

    noChannelResultsHandler () {
      if (this.options.noOlderThan !== 'any') {
        this.netWorkErrorHandler('No Videos Found with Filter', `No videos found with filter "No Older Than ${this.options.noOlderThan} ` + (+this.options.noOlderThan === 1 ? 'Day"' : 'Days"'))
      } else {
        this.netWorkErrorHandler('No Videos Found', 'No videos found on this channel')
      }
    },

    filterChannelVideosByTime (items) {
      if (!this.usingPlaylistForChannel || this.options.noOlderThan === 'any') { return items }

      // Manully Filter videos within the timeframe
      const noOlderThan = +this.options.noOlderThan
      const filteredVideos = items.filter(v => {
        return Moment(v.snippet.publishedAt).valueOf() >= Moment().subtract(noOlderThan, 'days').valueOf()
      })

      return filteredVideos || []
    },

    updateValidList (playedVideoId) {
      if (this.validVideos && this.validVideos.length && playedVideoId) {
        const vIndex = this.validVideos.findIndex(vid => vid === playedVideoId)
        if (vIndex >= 0) {
          this.validVideos.splice(vIndex, 1)
        }
      }
    },

    async initChannel (isUpdate) {
      // Channel ID already set up
      if (isUpdate && this.channelId && this.channelId.length) {
        if (this.validVideos && this.validVideos.length) {
          await this.setPlaylistAndVideoId([].concat([], this.validVideos), isUpdate)
          return
        }

        let promise
        if (this.usingPlaylistForChannel) {
          promise = YouTube.getVideosFromPlaylist(this.playlistId)
        } else {
          promise = YouTube.getVideosFromChannel(this.channelId, this.options.noOlderThan)
        }

        promise.then(async (data) => {
          const items = this.filterChannelVideosByTime(data.items)
          if (!items || !items.length) {
            this.noChannelResultsHandler()
            return
          }
          const videosIds = await this.getVideosIdsFromYouTubeResponse(items)
          await this.setPlaylistAndVideoId(videosIds, isUpdate)
        }).catch(err => {
          this.netWorkErrorHandler(err, `Unable to get YouTube channel data - ${err.message || err.toString()}`)
        })

      // Init
      } else {
        YouTube.getChannelIdFromURL(this.options.url).then(res => {
          // Invalid ChannelID, or there's NO public playlist/videos in the requested channel
          if (!res || res.noItems) {
            return Promise.resolve(res)
          }

          this.channelId = res.channelId
          this.playlistId = res.playlistId

          // Channel has related playlist - costs 3 units
          if (res.playlistId) {
            return YouTube.getVideosFromPlaylist(res.playlistId)
          // No related playlists found in channel - costs 100 units!
          } else {
            return YouTube.getVideosFromChannel(res.channelId, this.options.noOlderThan)
          }
        })
          .then(async (data) => {
            // Invalid ChannelID, or there's NO public playlist/videos in the requested channel
            if (!data || data.noItems || !data.items || !data.items.length) {
              this.noChannelResultsHandler()
              return
            }

            const items = this.filterChannelVideosByTime(data.items)
            if (!items || !items.length) {
              this.noChannelResultsHandler()
              return
            }
            const videosIds = await this.getVideosIdsFromYouTubeResponse(items)
            await this.setPlaylistAndVideoId(videosIds, isUpdate)
          }).catch(err => {
            this.netWorkErrorHandler(err, `Unable to get YouTube channel data - ${err.message || err.toString()}`)
          })
      }
    },

    async initPlaylist (isUpdate) {
      this.playlistId = YouTube.getPlaylistIdFromURL(this.options.url)

      YouTube.getVideosFromPlaylist(this.playlistId)
        .then(async (data) => {
          if (!data.items || !data.items.length) {
            this.netWorkErrorHandler('No Videos Found', 'No videos found in this playlist')
            this.endOfContent()
            return
          }
          const videosIds = await this.getVideosIdsFromYouTubeResponse(data.items)

          const startVideoId = YouTube.getVideoIdFromURL(this.options.url)
          const lastPlayed = await YouTube.getPlayedVideos(this.playlistId)

          // When playlist url also includes a video id
          if (startVideoId && videosIds.includes(startVideoId) && lastPlayed && lastPlayed.length && !lastPlayed.includes(startVideoId)) {
            // + That video is not played yet
            await this.setPlaylistAndVideoId(videosIds, isUpdate, startVideoId)
            return
          }

          await this.setPlaylistAndVideoId(videosIds, isUpdate)
        }).catch(err => {
          this.netWorkErrorHandler(err, `Unable to get YouTube playlist data - ${err.message || err.toString()}`)
        })
    },

    initSingleVideo (isUpdate) {
      this.videoId = YouTube.getVideoIdFromURL(this.options.url)
      this.initPlayer(isUpdate)
    },

    createPlayer () {
      clearTimeout(this.createPlayerTimer)

      if (!window.YT || !YT || !YT.loaded) {
        // Fallback checking for situation like:
        // YouTube JS script is embeded in <head> but still not fully loaded when called
        this.playerTimerCount++
        this.createPlayerTimer = setTimeout(() => {
          if (this.playerTimerCount >= config.SKIP_AFTER) {
            clearTimeout(this.createPlayerTimer)
            this.netWorkErrorHandler()
            return
          }
          this.createPlayer()
        }, config.CHECK_INTERVAL)
        return
      }

      this.player = new YT.Player(this.playerID, {
        height: '100%',
        width: '100%',
        playerVars: {
          enablejsapi: 1,
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          playsinline: 1,
          iv_load_policy: 3,
          showinfo: 0,
          rel: 0,
          start: this.startAt || 0,
          end: this.endAt || null,
          playlist: this.currentPlaylist.join(','),
          cc_load_policy: (this.options.showCaptions === 'true') ? 1 : 0
        },
        videoId: this.videoId,
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange,
          'onError': this.onPlayerError
        }
      })
    },

    initPlayer (isUpdate) {
      clearInterval(this.recheckTimer)

      // Prevent duplicate JS embed
      const jsID = 'youtubeIframeJS'
      let hasScript = false
      if (!document.getElementById(jsID)) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        tag.id = jsID
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

        this.youtubeReady = false
      } else {
        hasScript = true
      }

      // Script embeded
      if (hasScript) {
        if (isUpdate && this.videoId && this.player && typeof this.player.loadVideoById === 'function') {
          this.youtubeReady = true
          this.loadVideoById()
        } else {
          if (this.player && typeof this.player.destroy === 'function') {
            this.player.destroy()
          }
          this.$nextTick(() => {
            this.youtubeReady = false
            this.createPlayer()
          })
        }
      }

      this.networkTimerCount = 0

      clearInterval(this.recheckTimer)
      this.recheckTimer = setInterval(() => {
        this.networkTimerCount++
        if (this.youtubeReady || this.networkTimerCount >= config.SKIP_AFTER) {
          clearInterval(this.recheckTimer)
          this.recheckTimer = undefined
          if (!this.youtubeReady) {
            this.netWorkErrorHandler()
          }
        }
      }, config.CHECK_INTERVAL)
    },

    loadVideoById () {
      Log.info('app', `Load YouTube video by id ${this.videoId}`, 'INF_YOUTUBEVIDEOLOADBYID')
      if ((this.startAt && this.startAt > 0) || (this.endAt && this.endAt > 0)) {
        if (this.startAt) {
          Log.debug('app', `YouTube video starts at ${this.startAt}s`, 'DBG_YTBVIDEOSTARTTIME')
        }
        if (this.endAt) {
          Log.debug('app', `YouTube video ends at ${this.endAt}s`, 'DBG_YTBVIDEOENDTIME')
        }
        this.player.loadVideoById({
          videoId: this.videoId,
          startSeconds: this.startAt || 0,
          endSeconds: this.endAt || null
        })
      } else {
        this.player.loadVideoById(this.videoId)
      }
    },

    manuallyPlay () {
      if (this.player) {
        this.isManuallyPaused = false
        this.player.playVideo()
      }
    },

    manuallyPause () {
      if (this.player) {
        clearTimeout(this.forceAdvanceTimer)
        this.isManuallyPaused = true
        this.player.pauseVideo()
      }
    },

    manualAction () {
      if (!this.player || !this.player.getPlayerState || typeof this.player.getPlayerState !== 'function') { return }
      const playerState = this.player.getPlayerState()
      if (playerState === YT.PlayerState.PAUSED) {
        this.manuallyPlay()
      } else {
        this.manuallyPause()
      }
    },

    netWorkErrorHandler (err, msg) {
      this.onPlayerError({
        data: err || 'Network Error',
        message: msg || (err && (err.message || err.toString())) || 'Unable to connect to YouTube. Please check your network, proxy or firewall settings.',
        customError: true
      })
    },

    // Batch clear fallback timers
    // - Paused / Stalling / Cued / Player init / etc
    clearFallbackTimers () {
      clearTimeout(this.bufferingTimer)
      clearTimeout(this.retryTimer)
      clearTimeout(this.createPlayerTimer)
      clearTimeout(this.forceAdvanceTimer)
      clearTimeout(this.maxVideoLengthTimer)
      clearInterval(this.recheckTimer)
      clearInterval(this.endsAtTimer)
      this.stallTimerCount = 0
      this.playerTimerCount = 0
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.youtube-item
  //- Overlay Mask to prevent embedded youtube player being clicked (ads, etc)
  .overlay-mask(@click.stop="manualAction")
    transition(name="fade", :duration="{enter: 500, leave: 0}" appear)
      .play-button(v-if="isManuallyPaused")
        fa.fa-icon(icon="play-circle" @click.stop="manuallyPlay")

  //- Spinner
  .loading-mask(v-if="loading && !errorMessage")
    spinner(size="8em")

  //- Main Player
  .youtube-video(:class="{show: !loading && playingVideo}")
    div(:id="playerID")

  .messages-wrapper(v-show="errorMessage && !playingVideo")
    h1 {{ errorMessage }}
    p(v-if="showURL") {{ decodeURIComponent(options.url) }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl';
section.youtube-item
  color: white
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0

  .overlay-mask
    position: absolute
    z-index: 1000
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent

    .play-button
      color: #fff
      font-size: 8em
      position: absolute
      z-index: 5
      top: calc(50% - 0.5em)
      left: calc(50% - 0.5em)
      cursor: pointer
      transition: opacity 0.3s

      appIconShadow()

      &:hover,
      &:focus
        opacity: 0.7

      &:active
        opacity: 0.5

  .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    justify-content: center
    align-items: center

  .youtube-video
    width: 100%
    height: 100%
    position: relative
    z-index: 5
    opacity: 0

    &.show
      opacity: 1

  .messages-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 3vmin
    padding: 1em

    h1
      font-size: 4vmin
      white-space: pre
      line-height: 180%
    p
      opacity: 0.7

</style>
