<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'

import CommonStyling from 'services/common-styling.js'

import MenuColumn from './menu/Column'
import PhotoRow from './menu/PhotoRow'

const config = {
  // In vmin
  BASE_FONTSIZE: 2,

  // In em. For the Menu Title
  TITLE_FONT_BASE_SCALE: 2.4
}

// NOTE: Fallback solution for:
// Key with no values (such as an empty object or array) will return nothing after `qs.stringify`
// > https://github.com/ljharb/qs#stringifying
const COLUMN_PLACEHOLDER = 'column-placeholder'

export default {
  name: 'MenuApp',
  components: {
    MenuColumn,
    PhotoRow
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      title: '',
      subtext: '',
      style: 'text',
      currency: 'dollar',
      titleFont: '',
      titleFontScale: 1.0,
      textColor: '',
      titleColor: '',

      items: [],

      // `Text` style only
      categories: [],
      columns: [],

      // `Photo` styles
      interval: 10,

      // For watching the end of content for Text style
      pendingColumns: []
    }
  },

  computed: {
    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    colWidth () {
      if (this.style !== 'text') { return }
      const width = 100 / (this.columns.length || 1)
      return {
        width: `${width}%`
      }
    },

    maxMenuWidth () {
      // Set max menu width when there's less then three columns in landscape mode
      if (this.style === 'text' && !this.isPortrait) {
        if (this.columns.length < 3) {
          if (this.columns.length < 2) {
            return {
              width: '50%',
              alignSelf: 'center'
            }
          }
          return {
            width: '80%',
            alignSelf: 'center'
          }
        }
      }
    },

    validItems () {
      if (!this.items || !this.items.length) { return [] }
      return this.items.filter(item => {
        // Filter out unnamed items and 'hidden: true' items
        return item && item.id && (item.name || '').trim().length && item.hidden !== 'true'
      })
    },

    featuredItems () {
      if (this.style !== 'text' || !this.validItems || !this.validItems.length) { return [] }
      return this.validItems.filter(vItem => {
        return vItem && vItem.featured === 'true'
      })
    },

    categoriesWithNormalizedItems () {
      if (!this.categories || !this.categories.length) { return [] }
      const result = []
      this.categories.forEach(cate => {
        const thisCate = JSON.parse(JSON.stringify(cate))
        if (!cate || !cate.id || !cate.items || !cate.items.length) {
          thisCate.items = []
          result.push(thisCate)
          return
        }
        const normalItems = cate.items.filter(itemID => {
          const targetItem = this.validItems.find(it => it.id === itemID)
          // Filter out featured items as well
          return (targetItem && targetItem.featured !== 'true')
        })
        thisCate.items = normalItems || []
        result.push(thisCate)
      })
      return result
    },

    showFeaturedBlock () {
      return Boolean(this.featuredItems && this.featuredItems.length)
    },

    allCategoriesInColumn () {
      if (!this.columns || !this.columns.length || this.style !== 'text') { return [] }
      let result = []
      this.columns.forEach((col, colIndex) => {
        result = [].concat(result, this.categoriesInColumn(colIndex))
      })
      return result
    },

    titleFontClass () {
      if (!this.titleFont) { return '' }
      return CommonStyling.getfontClass(this.titleFont)
    },

    titleFontSize () {
      return {
        fontSize: `${config.TITLE_FONT_BASE_SCALE * (this.titleFontScale || 1.0)}em`
      }
    },

    titleColorStyle () {
      if (!this.titleColor || !(this.titleColor || '').trim().length) {
        return
      }
      return {
        color: this.titleColor
      }
    },

    textColorStyle () {
      if (!this.textColor || !(this.textColor || '').trim().length) {
        return
      }
      return {
        color: this.textColor
      }
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    titleFontClass (newValue) {
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.title = options.title || ''
      this.subtext = options.subtext || ''
      this.style = options.style || 'text'
      this.currency = options.currency || ''
      this.titleFont = options.titleFont || ''
      this.titleColor = options.titleColor || ''
      this.textColor = options.textColor || ''

      this.items = options.items || []
      this.columns = options.columns || []
      this.categories = options.categories || []

      // Font Size Support [DEV-1418]
      this.titleFontScale = +options.titleFontScale || 1.0

      if (this.style === 'text') {
        this.pendingColumns = this.columns.map((col, colIndex) => {
          return colIndex
        })
      } else {
        this.pendingColumns = []
      }

      this.interval = Math.max(4, options.interval || 10)
    },

    categoriesInColumn (colIndex) {
      if (!this.categoriesWithNormalizedItems || !this.categoriesWithNormalizedItems.length ||
          !this.columns || !this.columns.length ||
          !this.columns[colIndex] || !this.columns[colIndex].lists || !this.columns[colIndex].lists.length
      ) {
        return []
      }
      const result = []
      this.columns[colIndex].lists.forEach(cateID => {
        if (!cateID || cateID === COLUMN_PLACEHOLDER) { return }
        const targetCategory = this.categoriesWithNormalizedItems.find(cate => {
          return cate.id === cateID &&
                 (cate.items && cate.items.length)
        })
        if (targetCategory) {
          result.push(JSON.parse(JSON.stringify(targetCategory)))
        }
      })

      // Display Features block in column 1, if any
      if (colIndex === 0 && this.showFeaturedBlock) {
        const featuredCategory = {
          name: 'Features',
          id: 'featured-category',
          items: this.featuredItems.map(fItem => {
            if (fItem && fItem.id) {
              return fItem.id
            }
          })
        }
        result.unshift(featuredCategory)
      }
      return result
    },

    endOfColumnList (m, colIndex) {
      if (this.pendingColumns.length) {
        const targetIndex = this.pendingColumns.findIndex(col => col === colIndex)
        if (targetIndex >= 0) {
          this.pendingColumns.splice(targetIndex, 1)
        }
        if (!this.pendingColumns.length) {
          this.endOfContent()
        }
      }
    },

    endOfContent () {
      // Reaches the end of the menu
      this.$emit('finished')
    }
  }
}
</script>

<template lang="pug">
section.menu-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait}]"
                  :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .menu-app-container(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize}]"
                      :style="[boxMarginStyle, textColorStyle]")
    .menu-title(v-if="title && title.length", :class="titleFontClass", :style="[titleFontSize, titleColorStyle]") {{ title }}

    .menu-body(:class="['style-' + style, {'app-context-block': style === 'text', 'dark-text': !whiteText, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
               :style="maxMenuWidth")

      //- TEXT STYLE
      template(v-if="style === 'text'")
        .main-section.app-context-section.primary
          .menu-category-wrapper(:style="scaledFontSizeStyle")

            //- LANDSCAPE (default)
            //- - Will respect "columns" settings
            template(v-if="!isPortrait")
              .menu-column(v-for="(column, colIndex) in columns", :key="colIndex"
                           :style="colWidth")
                menu-column(:categories="categoriesInColumn(colIndex)"
                            :items="validItems"
                            :currency="currency"
                            :interval="interval"
                            :white-text="whiteText"
                            @end-of-list="endOfColumnList($event, colIndex)")

            //- PORTRAIT
            //- - Apply ONLY ONE column
            template(v-if="isPortrait")
              .menu-column
                menu-column(:categories="allCategoriesInColumn"
                            :items="validItems"
                            :currency="currency"
                            :interval="interval"
                            :white-text="whiteText"
                            is-portrait
                            @end-of-list="endOfColumnList($event, 0)")

          //- Subtext for TEXT style
          .menu-subtext(v-if="subtext && subtext.length", :style="scaledFontSizeStyle") {{ subtext }}

      //- PHOTO STYLE
      template(v-if="style !== 'text'")
        photo-row(:items="validItems"
                  :currency="currency"
                  :interval="interval"
                  :photo-small="style === 'photo_small'"
                  :white-text="whiteText"
                  :hide-box="hideBox"
                  :hide-box-outline="hideBoxOutline"
                  :is-portrait="isPortrait"
                  :style="scaledFontSizeStyle"
                  @end-of-list="endOfContent")

    //- Subtext for PHOTO style
    .menu-subtext(v-if="subtext && subtext.length && style !== 'text'", :style="scaledFontSizeStyle") {{ subtext }}

</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.menu-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1
  color: #fff

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .menu-app-container
    flex: 1 1 0.0001px
    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

    .menu-title
      line-height: 130%
      font-weight: 600
      text-align: center
      text-transform: capitalize
      margin: -0.3em 0 0.3em 0

    .menu-subtext
      line-height: 110%
      font-weight: 600
      text-align: center
      margin-top: 0.8em
      opacity: 0.6

    .menu-body
      flex: 1 1 0.0001px
      position: relative
      overflow: hidden

      // ==================
      // TEXT STYLE

      &.style-text
        .main-section
          position: absolute
          top: 0
          bottom: 0
          left: 0
          right: 0

          overflow: hidden

          padding: 1em 0.5em

          display: flex
          flex-flow: column nowrap
          justify-content: flex-start
          align-items: stretch

        .menu-category-wrapper
          flex: 1 1 0.00001px

          display: flex
          flex-flow: row nowrap
          justify-content: space-between
          align-items: stretch

        .menu-column
          position: relative
          overflow: hidden
          padding: 0 1em
          display: flex
          flex-flow: column nowrap
          justify-content: flex-start
          align-items: stretch

        .menu-subtext
          margin-top: 0

      // / EOF TEXT STYLE
      // ==================

      // ==================
      // PHOTO STYLE
      &.style-photo_large,
      &.style-photo_small
        display: flex
        flex-flow: column nowrap
        justify-content: flex-start
        align-items: stretch

        .menu-app-photo-row
          flex: 1 1 0.0001px

      // / EOF PHOTO STYLE
      // ==================

    // Dark Text
    &.dark-text
      color: $appDarkTextColor

    // Drop Text Shadow When BG Media is used
    &.show-text-shadow:not(.dark-text)
      appTextShadow()

  //
  // Portrait Layout
  //
  &.is-portrait
    .menu-app-container
      .menu-body
        &.style-text
          .main-section
            flex-flow: column nowrap
            justify-content: flex-start
            align-items: stretch
            padding: 1.5em 0.5em

          .menu-column
            flex: 1 1 0.0001px
</style>
