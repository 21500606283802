<script>
import MetricsMixins from './MetricsMixins.vue'

import PiechartItem from './PiechartItem.vue'

import { mapGetters } from 'vuex'

import Metrics from 'services/metrics'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'MetricsPiechart',
  components: { PiechartItem },

  mixins: [MetricsMixins],

  data () {
    return {
      data: [],
      itemsData: [],

      metricKeysList: [],
      itemsMetricKeysMap: []
    }
  },

  computed: {
    ...mapGetters({
      queriedValue: 'getQueriedValue'
    })
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    EventBus.$on('ws-query-metrics', this.updateQueryData)
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)
    this.renderInitData()
  },

  beforeDestroy () {
    EventBus.$off('ws-query-metrics', this.updateQueryData)
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        return
      }

      const refsMap = []
      this.validItems.forEach((item, idx) => {
        const slices = item.slices || []

        if (!slices.length) {
          refsMap[idx] = null
          return
        }

        slices.forEach((slice, sIdx) => {
          const metric = slice.metric || {}

          if (!refsMap[idx]) {
            refsMap[idx] = {}
          }

          let qKey
          if (metric && metric.ref && metric.ref.length) {
            if (!refsMap[idx].ref) {
              refsMap[idx].ref = []
            }
            refsMap[idx].ref[sIdx] = metric.ref
          }

          // Metrics

          if (metric.range_type !== 'current' && metric.bucket_size > 0) {
            qKey = Metrics.buildQueryKey(metric)
            if (qKey && !this.metricKeysList.includes(qKey)) {
              this.metricKeysList.push(qKey)
            }
            if (!refsMap[idx].query) {
              refsMap[idx].query = []
            }
            refsMap[idx].query[sIdx] = qKey
          }
        })
      })

      this.itemsMetricKeysMap = refsMap
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateItemData (idx) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[idx]
      if (!item) { return }

      const slices = item.slices || []
      const slicesData = []

      if (!slices.length) { return }

      slices.forEach((slice, sIdx) => {
        const metric = slice.metric || {}
        if (metric.url && metric.url.length) { return }

        const label = Metrics.keyToTitle(metric.ref || '')
        const color = slice.color

        const targetData = (this.data && this.data[idx] && this.data[idx][sIdx]) || {}

        // - Current Value
        if (metric.range_type === 'current') {
          if (targetData.kind === 'dictionary' && typeof targetData.value === 'object') {
            let idx = 0
            for (const key in targetData.value) {
              slicesData[idx] = {
                value: parseFloat(targetData.value[key]),
                label: key
              }
              idx = idx + 1
            }
          } else {
            slicesData[sIdx] = {
              value: targetData.value,
              label,
              color
            }
          }

        // - Aggregated value
        } else {
          const operation = metric.range_type

          if (operation !== 'current' && metric.bucket_size > 0) {
            const qKey = this.itemsMetricKeysMap[idx].query[sIdx]
            if (qKey) {
              const queriedValue = this.queriedValue(qKey)
              if (typeof queriedValue !== 'undefined') {
                targetData[metric.range_type] = queriedValue
              }
            }
          }

          if (operation) {
            if (operation === 'avg' || operation === 'average') {
              slicesData[sIdx] = {
                value: targetData.sum / targetData.count,
                label,
                color
              }
            } else {
              slicesData[sIdx] = {
                value: targetData[operation] || 0,
                label,
                color
              }
            }
          }
        }
      })
      this.$set(this.itemsData, idx, {
        slices: slicesData,
        value_type: item.value_type,
        rounding: item.rounding || 0,
        abbreviate: item.abbreviate,
        chart_type: item.chart_type
      })
    },

    updateQueryData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.query) { return }
        for (let i = 0; i < item.query.length; i++) {
          const key = item.query[i]
          if (m.includes(key)) {
            this.updateItemData(idx)
            break
          }
        }
      })
    },

    // Update method for nested data
    updateInvolvedItems (refs) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      const forceUpdateAll = !refs || !refs.length
      if (forceUpdateAll) {
        this.itemsMetricKeysMap.forEach((item, idx) => {
          this.updateItemData(idx)
        })
      } else {
        this.itemsMetricKeysMap.forEach((item, idx) => {
          if (!item.ref && !item.ref.length) { return }
          if (item.ref.some(rf => refs.includes(rf))) {
            this.updateItemData(idx)
          }
        })
      }
    },

    // Update method for nested data
    updateCurrentData (m) {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }
      if (!m || !Array.isArray(m) || !m.length) { return }

      this.itemsMetricKeysMap.forEach((item, idx) => {
        let updateItem = false
        if (!item.ref || !item.ref.length) { return }
        item.ref.forEach((key, sIndex) => {
          if (!key) { return }
          if (m.includes(key)) {
            updateItem = true
            const newData = this.subscribedValue(key)
            if (!newData) { return }
            this.$set(this.data[idx], sIndex, JSON.parse(JSON.stringify(newData)))
          }
        })
        if (updateItem) {
          this.$nextTick(() => {
            this.updateItemData(idx)
          })
        }
      })
    },

    // Update method for nested data
    renderInitData () {
      if (!this.itemsMetricKeysMap || !this.itemsMetricKeysMap.length) { return }

      const data = []
      this.itemsMetricKeysMap.forEach((item, idx) => {
        if (!item.ref || !item.ref.length) { return }
        if (!data[idx]) {
          data[idx] = []
        }
        item.ref.forEach((key, sIndex) => {
          if (!key) { return }
          const value = this.subscribedValue(key)
          if (!value) { return }
          data[idx][sIndex] = JSON.parse(JSON.stringify(value))
        })
      })

      this.data = data
    }
  }
}
</script>

<template lang="pug">
section.metrics-piechart
  transition(:name="transitionName", :duration="500" appear)
    piechart-item.prime(v-if="showPrime && primeItem"
                 :title="itemTitle(primeItem)"
                 :remove-title="removeTitle"
                 :item="itemData(primeIndex)")

  transition(:name="transitionName", :duration="500" appear)
    piechart-item.base(v-if="!showPrime && baseItem"
                 :title="itemTitle(baseItem)"
                 :remove-title="removeTitle"
                 :item="itemData(baseIndex)")
</template>

<style lang="stylus">
section.metrics-piechart
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-piechart-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
