<script>
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

import MenuCategory from './Category'

const config = {
  // in ms
  SHOW_NEXT_DELAY: 500
}

export default {
  name: 'MenuAppColumn',
  components: {
    MenuCategory
  },

  props: {
    categories: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: ''
    },
    interval: {
      type: Number,
      default: 10
    },
    whiteText: {
      type: Boolean,
      default: false
    },
    isPortrait: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      list: [],

      currentFirstIndex: 0,
      currentLastIndex: 0,
      currentChunk: [],

      resizing: true,

      // Prevent flickering
      isRendered: false,

      // For height calculation
      metricChunk: [],

      // For watching the end of content
      listState: '',
      pendingCategories: [],

      debounceTimer: undefined,
      delayTimer: undefined,
      expireTimer: undefined,
      measureTimer: undefined
    }
  },

  watch: {
    categories: {
      deep: true,
      handler () {
        this.debounceCheckSize()
      }
    },

    items: {
      deep: true,
      handler () {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.render()
    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    clearTimeout(this.measureTimer)
  },

  methods: {
    render () {
      this.list = JSON.parse(JSON.stringify(this.categories || []))

      if (!this.list.length) {
        this.listState = 'empty'
        this.$emit('end-of-list', 'empty')
      }

      if (this.resizing) { return }

      this.getNewChunk()
    },

    getNewChunk (startIndex) {
      if (this.resizing) { return }

      this.currentFirstIndex = startIndex || 0
      this.currentLastIndex = startIndex || 0
      this.metricChunk = []
      this.isRendered = false
      this.listState = ''
      this.pendingCategories = []

      this.addSingleItem()
    },

    playNext () {
      clearTimeout(this.delayTimer)

      const isLastChunk = this.currentLastIndex >= this.list.length - 1

      this.currentChunk = []
      this.currentFirstIndex = isLastChunk ? 0 : this.currentLastIndex + 1

      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.getNewChunk(this.currentFirstIndex)
      }, config.SHOW_NEXT_DELAY)
    },

    addSingleItem () {
      clearTimeout(this.measureTimer)

      if (!this.list || !this.list.length || this.resizing || !this.list[this.currentLastIndex]) { return }

      const item = JSON.parse(JSON.stringify(this.list[this.currentLastIndex]))
      this.metricChunk.push(item)

      clearTimeout(this.measureTimer)
      this.measureTimer = setTimeout(() => {
        clearTimeout(this.measureTimer)
        this.checkAvailableHeight()
      }, 100)
    },

    renderList () {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      const list = JSON.parse(JSON.stringify(this.list))
      const nextChunk = list.slice(this.currentFirstIndex, this.currentLastIndex + 1)

      if (this.metricChunk.length === list.length) {
        // Skip animation when there's only one page
        this.listState = 'no-paginate'
        this.pendingCategories = nextChunk.map(cate => {
          if (cate && cate.id) {
            return cate.id
          }
        })
        this.currentChunk = nextChunk
      } else {
        this.pendingCategories = []
        this.listState = ''
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer)
          // Reach the end of list
          if (this.currentLastIndex === this.list.length - 1) {
            this.listState = 'last-page'
          }
          this.currentChunk = nextChunk
          clearTimeout(this.expireTimer)
          this.expireTimer = setTimeout(() => {
            clearTimeout(this.expireTimer)
            // Reach the end of list
            if (this.currentLastIndex === this.list.length - 1) {
              this.$emit('end-of-list', 'end')
            }
            this.playNext()
          }, this.interval * 1000 + config.SHOW_NEXT_DELAY)
        }, 200)
      }
    },

    endOfList (m, category) {
      if (this.listState === 'no-paginate' && this.pendingCategories.length) {
        const targetIndex = this.pendingCategories.findIndex(cateID => cateID === category.id)
        if (targetIndex >= 0) {
          this.pendingCategories.splice(targetIndex, 1)
        }
        if (!this.pendingCategories.length) {
          this.$emit('end-of-list', 'end')
        }
      }
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.resizing = true
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizing = false
        this.render()
      }, timeout || 200)
    },

    checkAvailableHeight () {
      if (!this.$refs || !this.$refs.measure || this.resizing) { return }

      let availableHeight
      let containerHeight
      let measureHeight

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!this.$refs || !this.$refs.measure) {
          FastDom.clear(measure)
          return
        }

        containerHeight = this.$refs.measure.offsetHeight
        measureHeight = this.$refs.measure.scrollHeight

        let mutate = FastDom.mutate(() => {
          availableHeight = containerHeight - measureHeight

          if (availableHeight >= 0 && this.currentLastIndex < this.list.length - 1) {
            this.currentLastIndex++
            this.addSingleItem()
          } else if (availableHeight < 0) {
            // Make sure there's at least one category in the list
            if (this.metricChunk.length > 1) {
              this.currentLastIndex--
              this.metricChunk.pop()
            }
            this.renderList()
          } else {
            this.renderList()
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    }
  }
}
</script>

<template lang="pug">
.menu-app-column
  .resize-sensor(ref="sensor")

  //- Real list
  transition-group(tag="div" class="column-category-list visible-column"
                   name="page-fade"
                   :duration="200")
    menu-category(v-for="category in currentChunk", :key="category.id"
                  :category="category"
                  :items="items"
                  :interval="interval"
                  :currency="currency"
                  :only-one="currentChunk.length === 1"
                  :white-text="whiteText"
                  :is-portrait="isPortrait"
                  @end-of-list="endOfList($event, category)")

  //- For height calculation
  .column-category-list.measure(ref="measure")
    menu-category(v-for="category in metricChunk", :key="category.id"
                  :category="category"
                  :items="items"
                  :currency="currency"
                  :is-portrait="isPortrait"
                  :for-measure="true")
</template>

<style lang="stylus">
.menu-app-column
  position: relative
  flex: 1 1 0.00001px
  overflow: hidden

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .column-category-list
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow: hidden

    animation-delay: 0
    animation-duration: 200ms

    .menu-app-category
      margin-top: 2.8em

      &:first-of-type
        margin-top: 0

    &.visible-column
      z-index: 1

    &.measure
      z-index: -2
      visibility: hidden
      opacity: 0
</style>
