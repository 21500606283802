<script>
// MIXINS
import SdkCommonMixins from 'components/common/SdkCommonMixins.vue'
import SdkV1Mixins from 'components/common/SdkV1Mixins.vue'
import SdkV2Mixins from 'components/common/SdkV2Mixins.vue'

import HtmlContent from 'services/html-content.js'
import Env from 'services/environment'
import SdkPreload from 'sdk/v1-preload'

import qs from 'qs'

// Load Staled HTML Content after this timeout
// Failsafe for Player starting offline
const HTML_INIT_TIMEOUT = 5000

export default {
  name: 'HtmlAppPage',

  // MIXIN
  mixins: [SdkCommonMixins, SdkV1Mixins, SdkV2Mixins],

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },

    // From Playlist Overlay
    isPlaylistOverlay: {
      type: Boolean,
      default: false
    },
    overlayContentId: {
      type: String,
      default: ''
    },

    // Flag for legacy HTML app created before DEV-2298
    isLegacyHtml: {
      type: Boolean,
      default: false
    },

    // Webapps related props
    // - Not available in HTML Overlay, nor the deprecated HTML app.
    isWebapp: {
      type: Boolean,
      default: false
    },
    webappId: {
      type: String,
      default: ''
    },
    args: {
      type: Array,
      default: () => { return [] }
    },
    fields: {
      type: Array,
      default: () => { return [] }
    }
  },

  data () {
    return {
      htmlSource: '',
      htmlId: '',
      htmlVersion: '',

      sdkVersion: 1,

      notAllowed: false,

      idePreview: false,
      bridgeFinished: false,

      renderTimer: null,
      htmlInitTimer: null
    }
  },

  computed: {
    iframeKey () {
      if (this.isWebapp) {
        return `webapp_${this.webappId}`
      } else if (this.isPlaylistOverlay) {
        return `playlist_overlay_${this.overlayContentId}_v${this.htmlVersion}`
      } else if (this.isLegacyHtml) {
        return `html_legacy_${this.htmlId || this.item.ref_id}_v${this.htmlVersion}`
      } else {
        return `html_${this.htmlId || this.item.ref_id}_v${this.htmlVersion}`
      }
    }
  },

  watch: {
    'item.url' () {
      if (!this.isWebapp) {
        this.debounceRender()
      }
    },

    webappId  () {
      // Switched to another Webapp
      this.debounceRender()
    },

    args: {
      deep: true,
      handler () {
        if (!this.isSdkV2) {
          this.debounceRender()
        } else {
          // TODO: Handle Arguments Value for SDK v2
        }
      }
    },
    fields: {
      deep: true,
      handler () {
        if (!this.isSdkV2) {
          this.debounceRender()
        } else {
          // TODO: Handle Arguments Value for SDK v2
        }
      }
    },
    notAllowed (isTrue) {
      if (isTrue) {
        this.htmlSource = '<p style="color: #fff;">We cannot display this URL for security concerns</p>'
      }
    }
  },

  mounted () {
    window.addEventListener('message', this.messageHandler, false)
    clearTimeout(this.renderTimer)
    clearTimeout(this.htmlInitTimer)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.renderTimer)
    clearTimeout(this.htmlInitTimer)
    window.removeEventListener('message', this.messageHandler, false)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.bridgeFinished = false

      if (options.htmlId) {
        this.htmlId = options.htmlId
        this.htmlVersion = options.htmlVersion
        this.getHtmlContent(options.htmlId, options.htmlVersion)
        this.idePreview = false
      // Is previewing with TTV IDE (InputHtml)
      } else if (options.idePreview === 'true') {
        this.idePreview = true
      // For Playlist HTML Overlays, and apps created before DEV-2298
      } else {
        this.updateHTMLSource(options.htmlSource)
        this.idePreview = false
      }
    },

    debounceRender () {
      clearTimeout(this.renderTimer)
      this.renderTimer = setTimeout(() => {
        clearTimeout(this.renderTimer)
        // Reset html source code to force update
        this.htmlSource = ''
        this.render()
      }, 200)
    },

    getPreloadScript () {
      // Inject iframeKey along with preload script
      // Ensure function name is preserved by using named function expression
      return '<script>var ttvSDKPreload = ' + SdkPreload.toString() + 
        `; window.__TTV_IFRAME_KEY = "${this.iframeKey}"; ttvSDKPreload();<\/script>`
    },

    // Add preload script on top
    updateHTMLSource (newContent = '') {
      if (!newContent.length) {
        this.htmlSource = ''
        return
      }
      this.htmlSource = this.getPreloadScript() + '\n' + newContent
    },

    getHtmlContent (htmlId, htmlVersion) {
      if (!htmlId) {
        clearTimeout(this.htmlInitTimer)
        return
      }

      this.recheckContentFetching()

      HtmlContent.get(htmlId, htmlVersion).then(htmlContent => {
        clearTimeout(this.htmlInitTimer)
        this.updateHTMLSource(htmlContent)
      }).catch(() => {
        clearTimeout(this.htmlInitTimer)
        this.contentErrorHandler()
      })
    },

    recheckContentFetching () {
      clearTimeout(this.htmlInitTimer)
      this.htmlInitTimer = setTimeout(() => {
        clearTimeout(this.htmlInitTimer)
        this.contentErrorHandler()
      }, HTML_INIT_TIMEOUT)
    },

    async contentErrorHandler () {
      const staleData = await HtmlContent.getCachedData(this.htmlId)
      if (staleData) {
        this.updateHTMLSource(staleData)
      }
    },

    messageHandler (evt) {
      if (!evt || !evt.data) { return }
      if (evt.origin !== Env.userAppURL()) { return }
      if (evt.data.type === 'ide-html-content') {
        this.updateHTMLSource(evt.data.data || '')
        if (!this.bridgeFinished) {
          const msg = {
            type: 'ide-bridge-finished',
            data: { ts: new Date().getTime() }
          }
          const parentWin = evt.source
          if (parentWin) {
            parentWin.postMessage(msg, Env.userAppURL())
            this.bridgeFinished = true
          }
        }
      }
    },

    iframeLoaded () {
      if (!this.htmlSource?.length) {
        // Skip inject SDK for empty HTML source ('')
        return
      }
      this.notAllowed = false
      this.$nextTick(() => {
        this.injectSDK()
        this.$emit('loaded')
      })
    }
  }
}
</script>

<template lang="pug">
section.html-page
  iframe.html-app-frame(ref="iframe"
        :srcdoc="htmlSource"
        @load="iframeLoaded"
        frame-border="0"
        sandbox="allow-scripts allow-same-origin allow-presentation allow-forms"
        allow="autoplay; fullscreen"
        allowtransparency
        allowfullscreen
        seamless)
</template>

<style lang="stylus">
section.html-page
  width: 100%
  height: 100%
  position: absolute
  background: transparent

  > iframe.html-app-frame
    background: transparent
    position: relative
    width: 100%
    height: 100%
    border: 0
</style>
