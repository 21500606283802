<script>
import { mapGetters } from 'vuex'

import Environment from 'services/environment'
import AppControl from 'services/appcontrol.js'

export default {
  name: 'Version',
  data () {
    return {
      version: Environment.version,
      browserName: Environment.browserName,
      browserVersion: Environment.browserVersion,
      osName: Environment.osName,
      osVersion: Environment.osVersion,
      android: Environment.androidAPI,
      electron: Environment.electronAPI,
      chromeOS: Environment.chromeAPI,
      windows: Environment.windowsAPI,
      ios: Environment.iosAPI,
      appVersion: Environment.appVersion,
      provisioned: Environment.provisioned,
      stage: Environment.stage,
      thisYear: (new Date()).getFullYear(),
      hasMouse: false,
      showVersion: true,
      showTimer: undefined
    }
  },

  computed: {
    ...mapGetters({
      whiteLabelledName: 'whitelabel/resellerName',
      apiLauncherWebsocketMode: 'apiLauncherWebsocketMode'
    }),
    creditName () {
      if (this.whiteLabelledName) {
        return this.whiteLabelledName
      }
      return 'TelemetryTV'
    }
  },

  mounted () {
    clearInterval(this.showTimer)
    // Only rotate through to the key commands if we're actually listening to them ourselves
    // Native Apps have other ways of showing and executing the key commands
    if (AppControl.listening) {
      this.showTimer = setInterval(() => {
        this.showVersion = !this.showVersion
      }, 6000)
    }
  },

  beforeDestroy () {
    clearInterval(this.showTimer)
  }
}
</script>

<template lang="pug">
.common-versions-banner
  transition(name="fade" :duration="500")
    p(v-show="showVersion && (version || browserName)")
      span Player v{{version}}
      span &nbsp;-&nbsp;
      span {{osName}} {{osVersion}}
      span &nbsp;-&nbsp;
      span(v-if="electron") Electron {{appVersion}} Chrome {{browserVersion}}
      span(v-else-if="android") App {{appVersion}} - {{browserName}} {{browserVersion}}
      span(v-else-if="chromeOS") ChromeApp {{appVersion}} - Chrome {{browserVersion}}
      span(v-else-if="windows") WindowsApp {{appVersion}} - {{browserName}} {{browserVersion}}
      span(v-else-if="ios") IOSApp {{appVersion}} - {{browserName}} {{browserVersion}}
      span(v-else) {{browserName}} {{browserVersion}}
      span(v-if="apiLauncherWebsocketMode") &nbsp;[launcher]
      span(v-if="provisioned") &nbsp;[provisioned]
      span(v-if="stage !== 'production'") &nbsp;[{{stage}}]
  transition(name="fade" :duration="500")
    p(v-show="!showVersion")
      span(v-if="electron") ctrl-r restart &nbsp; ctrl-q quit &nbsp; ctrl-f fullscreen &nbsp; ctrl-k console &nbsp;ctrl-x reset
      span(v-else-if="android") Press escape or ctrl-s for settings
      span(v-else-if="chromeOS") ctrl-r restart &nbsp; ctrl-q quit &nbsp; ctrl-k console &nbsp;ctrl-x reset
      span(v-else) &copy; {{thisYear}} {{creditName}}
</template>

<style lang="stylus">
.common-versions-banner
  color: var(--gray-light)
  font-size: 1.5vmin
  padding: 0 0 1vmin 0
  position: absolute
  text-align: center
  position: absolute
  bottom: 0
  left: 0
  right: 0

  p
    position: absolute
    bottom: 0
    left: 0
    right: 0
</style>
