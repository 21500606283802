<script>
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import Chunk from 'lodash/chunk'

import Metrics from 'services/metrics'
import TelemetryMarkup from 'services/telemetry-markup.js'

const config = {
  DEFAULT_FONT_SIZE: 1.0,
  DEFAULT_LINE_HEIGHT: 1.3
}

export default {
  name: 'MetricsTableItem',

  props: {
    item: { type: Array, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    metric: { type: Object },

    lineHeight: { type: [Number, String], default: +config.DEFAULT_LINE_HEIGHT },

    // 'none' | 'auto'
    paginate: { type: String, default: '' },

    // App inter-items paginate
    isPaginated: { type: Boolean, default: false },

    // In-app paginate interval
    interval: { type: Number, default: 10 }
  },

  data () {
    return {
      itemsToShow: 0,
      chunks: [],
      currentChunkIndex: 0,
      currentChunk: [],
      currentChunkTitle: [],

      debounceTimer: undefined,
      expireTimer: undefined,
      delayTimer: undefined
    }
  },

  computed: {
    columnCount () {
      if (!this.item) { return 1 }
      return this.item.reduce((currentCount, row) => Math.max(currentCount, row.length), 1)
    },

    columnTitles () {
      if (!this.title || this.title.length === 0) {
        return [Metrics.keyToTitle(this.metric.ref)]
      }
      return this.title
        .split(/\s*,\s*/).map(ct => Metrics.keyToTitle(ct))
        .slice(0, this.columnCount)
    },

    fontStyles () {
      return {
        height: `${+this.lineHeight || config.DEFAULT_LINE_HEIGHT}em`
      }
    },

    titleFontStyle () {
      return this.nonTabTitle ? this.titleStyles : [this.fontStyles, this.titleStyles]
    },

    tableStyle () {
      return {
        gridTemplateColumns: `repeat(${this.columnCount}, auto)`
      }
    },

    nonTabTitle () {
      return this.columnTitles && this.columnTitles.length === 1
    }
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.debounceCheckSize()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)

    this.$nextTick(() => {
      this.debounceCheckSize()
    })

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
  },

  methods: {
    debounceCheckSize () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizeHandler()
      }, 200)
    },

    resizeHandler () {
      if (!this.$el || !this.$refs.sensor || !this.item || !this.item.length) {
        return
      }
      this.divideChunks()
    },

    divideChunks () {
      const measure = FastDom.measure(() => {
        if (!this.$refs.invisibleCell) {
          FastDom.clear(measure)
          return
        }
        const mutate = FastDom.mutate(() => {
          if (!this.$refs.invisibleCell) {
            FastDom.clear(mutate)
            return
          }
          let displayCells = 0
          for (const cell of this.$refs.invisibleCell) {
            if (this.isInsideContainer(cell)) {
              displayCells++
            }
          }
          this.itemsToShow = displayCells / this.columnCount
          this.chunks = Object.freeze(JSON.parse(JSON.stringify(Chunk(this.item, this.itemsToShow))))

          // Skip animation when there's only one page or `paginate` is set to 'none' or this widget has multiple items.
          if (this.chunks.length <= 1 || this.paginate === 'none' || this.isPaginated) {
            clearTimeout(this.delayTimer)
            clearTimeout(this.expireTimer)
            this.currentChunk = JSON.parse(JSON.stringify(this.chunks[0] || []))
            this.currentChunkTitle = this.columnTitles
          } else {
            this.prepareNext()
          }
          FastDom.clear(mutate)
        })
        FastDom.clear(measure)
      })
    },

    prepareNext () {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.currentChunk = JSON.parse(JSON.stringify(this.chunks[this.currentChunkIndex]))
        this.currentChunkTitle = this.columnTitles
        this.expireTimer = setTimeout(() => {
          clearTimeout(this.expireTimer)
          this.playNext()
        }, this.interval * 1000)
      }, 500)
    },

    playNext () {
      this.currentChunk = []
      this.currentChunkTitle = []
      this.currentChunkIndex = (this.currentChunkIndex + 1) % this.chunks.length
      this.prepareNext()
    },

    isInsideContainer (cell) {
      return cell.offsetTop + cell.offsetHeight <= cell.offsetParent.offsetTop + cell.offsetParent.offsetHeight
    },

    compileTmu (text) {
      if (text === undefined || text === null) {
        text = ''
      }
      return TelemetryMarkup.compile(text)
    }
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-table-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length && nonTabTitle") {{ title }}

  .item-body.app-context-section.primary
    .resize-sensor(ref="sensor")

    .table-main-content
      transition(name="fade-moveLeft", :duration="500" mode="out-in" appear)
        .table.animated-table(v-if="currentChunk && currentChunk.length", :style="tableStyle")
          template(v-if="!nonTabTitle")
            .cell.heading(v-for="n in columnCount",
                          :class="[`column-${n}`]",
                          :style="titleFontStyle")
              span(v-html="compileTmu(currentChunkTitle[n - 1])")
          template(v-for="(cells, rowIdx) in currentChunk")
            template(v-if="cells.length !== 0")
              .cell(v-for="n in columnCount",
                    :class="`column-${n}`",
                    :style="fontStyles",
                    v-html="compileTmu(cells[n - 1])")

      .table-body.invisible
        .table(:style="tableStyle")
          template(v-if="!nonTabTitle")
            .cell.heading(v-for="n in columnCount",
                          :class="[`column-${n}`]",
                          :style="titleFontStyle")
              span(v-html="compileTmu(currentChunkTitle[n - 1])")
          template(v-for="(cells, rowIdx) in item")
            template(v-if="cells.length !== 0")
              .cell(v-for="n in columnCount",
                    :class="`column-${n}`",
                    :style="fontStyles",
                    ref="invisibleCell",
                    v-html="compileTmu(cells[n - 1])")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.metrics-table-item
  .item-body
    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: 0
      bottom: 0
      left: 0
      right: 0

  .table-main-content
    position: relative
    height: 100%
    width: 100%
    flex: 1
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: flex-start

    .animated-table
      animation-duration: 500ms

    .table
      display: grid
      align-content: start
      width: 100%
      height: 100%

      .cell
        min-width: 2em
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

        &.heading
          position: relative
          opacity: 0.5

          span
            position: absolute
            top: 0
            left: 0
            right: 0
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

    .table-body
      position: relative
      width: 100%

      &.invisible
        visibility: hidden
        position: absolute
        height: 100%
        width: 100%
        opacity: 0
        z-index: -1
</style>
