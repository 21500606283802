<script>
import { mapGetters } from 'vuex'

import Env from 'services/environment'
import AppControl from 'services/appcontrol.js'
import Pages from 'services/pages.js'
import { EventBus } from 'services/eventbus.js'

// In seconds. Auto-resume playback after this duration if no end-user interaction occurs
const RESUME_PLAYBACK_AFTER = 60

// In seonds. Auto-hide the Interactive Menu after this duration if no end-user interaction occurs
const HIDE_MENU_AFTER = 60

// In seconds. The end-user need to re-enter the PIN code if not interacting with menu items within this interval
const VERIFY_CODE_AFTER = 60

export default {
  name: 'InteractiveMenu',

  data () {
    return {
      resumeInSeconds: -1,
      hideInSeconds: -1,
      codeExpiredInSeconds: -1,

      codeVerified: false,

      oneSecTimer: null,
      resumeTimer: null,
      hideTimer: null,
      pinCodeTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'isPaused',
      'showPagesList',
      'showItemsList',
      'isViewingItem',
      'viewingItemIsPaused',
      'waitForItemFinished',
      'inKioskNavMode',
      'tapToShowMenuEnabled',
      'deviceInteractiveMenu',
      'needsMenuPinCode',
      'showingPinCode'
    ]),

    isDevelopment () {
      return Env.isDevelopment()
    },

    hidePlayButton () {
      // Hide play button at some point to reduce confusion
      return (this.isViewingItem || this.showItemsList) || this.inKioskNavMode
    },

    hideArrowButton () {
      // Hide prev/next button at some point to reduce confusion
      return this.showItemsList && !this.isViewingItem
    },

    needsAutoHideMenu () {
      // Need to auto-hide the Interactive Menu when
      // - The "Show Interactive Menu" is NOT enabled on the device config, and
      // - The Playlist's "Tap Interaction" is set to "Show Interactive Menu"
      return !this.deviceInteractiveMenu && this.tapToShowMenuEnabled
    }
  },

  watch: {
    waitForItemFinished (toTrue) {
      if (!toTrue && !this.inKioskNavMode) {
        if (this.isViewingItem && this.resumeInSeconds < 0) {
          this.resumePlayback()
        }
        if (this.isViewingItem && this.hideInSeconds < 0) {
          this.autoHideMenu()
        }
      }
    },

    needsMenuPinCode (toTrue = false) {
      // The PIN code is deleted from the User App
      if (!toTrue) {
        // Hide the PIN code panel right away
        if (this.showingPinCode) {
          this.$store.commit('updateShowPinCode', false)
        }
      // PIN code added
      } else {
        // Close openning list, if any
        if (this.showPagesList || this.showItemsList) {
          this.resumePlayback()
        }
      }
    }
  },

  mounted () {
    EventBus.$on('user-interacted', this.resetTimers)
    EventBus.$on('menu-pin-code-verified', this.pinCodeConfirmed)

    clearTimeout(this.resumeTimer)
    clearTimeout(this.hideTimer)
    clearTimeout(this.pinCodeTimer)

    if (this.needsAutoHideMenu) {
      this.setHideTimer()
    }

    this.updateMenuPages()

    clearInterval(this.oneSecTimer)
    this.oneSecTimer = setInterval(() => {
      // Validate Pages
      this.updateMenuPages()

      // Update Debug Countdown Values
      if (this.resumeInSeconds >= 0) {
        this.resumeInSeconds--
      }
      if (this.hideInSeconds >= 0) {
        this.hideInSeconds--
      }
      if (this.codeExpiredInSeconds >= 0) {
        this.codeExpiredInSeconds--
      }
    }, 1000)
  },

  beforeDestroy () {
    clearTimeout(this.resumeTimer)
    clearTimeout(this.hideTimer)
    clearTimeout(this.pinCodeTimer)
    clearInterval(this.oneSecTimer)

    // Reset Menu state values on destroy
    if (this.showPagesList || this.showItemsList || this.isViewingItem || this.showingPinCode) {
      this.resumePlayback()
    }

    EventBus.$off('user-interacted', this.resetTimers)
    EventBus.$off('menu-pin-code-verified', this.pinCodeConfirmed)
  },

  methods: {
    togglePlayback () {
      // Info page zone components (VideoItem, MediaFolder,...)
      if (this.isPaused) {
        // To play
        EventBus.$emit('interact-menu-pause', false)
      } else {
        // To pause
        EventBus.$emit('interact-menu-pause', true)
      }

      // Info Playlist and Device API
      AppControl.emit('system-key-command', {
        command: 'pause/resume'
      })
    },

    showNext () {
      if (this.isViewingItem) {
        // Next Item
        EventBus.$emit('interact-menu-next-item')
      } else {
        // Next Page
        AppControl.emit('system-key-command', {
          command: 'nextpage'
        })
      }
      this.setHideTimer()
    },

    showPrevious () {
      if (this.isViewingItem) {
        // Previous Item
        EventBus.$emit('interact-menu-prev-item')
      } else {
        // Previous Page
        AppControl.emit('system-key-command', {
          command: 'previouspage'
        })
      }
      this.setHideTimer()
    },

    checkPinCode () {
      if (this.needsMenuPinCode) {
        // Don't need to re-enter the PIN code if the user keep interacting with the menu
        if (this.codeVerified && this.codeExpiredInSeconds > 0) {
          this.showList()
          return
        }
        this.$store.commit('updateShowPinCode', true)
        this.resetTimers()
      } else {
        this.showList()
      }
    },

    pinCodeConfirmed () {
      this.setPinCodeTimer()
      this.codeVerified = true
      this.showList()
    },

    showList () {
      this.$store.commit('updateShowPagesList', true)
      this.$store.commit('updateShowItemsList', false)
      this.$store.commit('clearViewingItem')
      this.resetTimers()
    },

    hideList () {
      this.$store.commit('updateShowPagesList', false)
      this.clearResumeTimer()
      this.resumePlayback()
    },

    // Go back to media grid list (Interactive Media Folder, etc)
    backToGridList () {
      this.$store.commit('clearViewingItem')
      if (this.inKioskNavMode) { return }
      this.resetTimers()
    },

    resumePlayback () {
      // Don't resume playback when in Kiosk Navigation Mode (Navigatio app is active)
      if (this.inKioskNavMode) { return }

      this.$store.commit('updateShowPagesList', false)
      this.$store.commit('updateShowItemsList', false)
      this.$store.commit('clearViewingItem')

      this.$store.commit('updateShowPinCode', false)

      if (this.isPaused) {
        this.togglePlayback()
      }

      if (this.needsAutoHideMenu) {
        this.autoHideMenu()
      }
    },

    setResumeTimer () {
      clearTimeout(this.resumeTimer)
      this.resumeInSeconds = +RESUME_PLAYBACK_AFTER
      this.resumeTimer = setTimeout(() => {
        clearTimeout(this.resumeTimer)
        if (this.waitForItemFinished && !this.viewingItemIsPaused) {
          return
        }
        this.resumePlayback()
      }, RESUME_PLAYBACK_AFTER * 1000)
    },

    clearResumeTimer () {
      clearTimeout(this.resumeTimer)
      this.resumeInSeconds = -1
    },

    autoHideMenu () {
      if (!this.needsAutoHideMenu || (this.waitForItemFinished && !this.viewingItemIsPaused) || this.hideInSeconds > 0) { return }
      this.$store.dispatch('setInteractieMenuState', false)
    },

    setHideTimer () {
      clearTimeout(this.hideTimer)
      if (!this.needsAutoHideMenu) { return }

      this.hideInSeconds = +HIDE_MENU_AFTER
      this.hideTimer = setTimeout(() => {
        clearTimeout(this.hideTimer)
        if (this.waitForItemFinished && !this.viewingItemIsPaused) {
          return
        }
        this.autoHideMenu()
      }, HIDE_MENU_AFTER * 1000)
    },

    setPinCodeTimer () {
      clearTimeout(this.pinCodeTimer)
      if (!this.needsMenuPinCode || !this.codeVerified) { return }

      this.codeExpiredInSeconds = +VERIFY_CODE_AFTER
      this.pinCodeTimer = setTimeout(() => {
        clearTimeout(this.pinCodeTimer)
        this.codeVerified = false
        this.codeExpiredInSeconds = -1
      }, VERIFY_CODE_AFTER * 1000)
    },

    resetTimers () {
      this.setResumeTimer()
      this.setHideTimer()
      this.setPinCodeTimer()
    },

    updateMenuPages () {
      const pages = Pages.interactiveMenuPages()
      this.$store.commit('updateMenuPages', pages)
    }
  }
}
</script>

<template lang="pug">
nav#interactive-menu
  .main-area
    //- Viewing a media content
    .menu-button(v-if="isViewingItem" @click="backToGridList")
      fa.fa-icon(icon="th" fixed-width)

    //- Viewing media items list
    .menu-button(v-else-if="showItemsList" @click="showList")
      fa.fa-icon(icon="chevron-left" fixed-width)

    //- Not viewing a single media content
    template(v-else)
      .menu-button(v-if="!showPagesList" @click="checkPinCode")
        fa.fa-icon(:icon="['far', 'bars']" fixed-width)
      .menu-button(v-if="showPagesList" @click="hideList")
        fa.fa-icon(:icon="['far', 'times']" fixed-width)

    //- For Debug. Only available in QA, TEST and Local dev
    template(v-if="isDevelopment")
      .resume-timer.auto-hide-timer(v-if="needsAutoHideMenu", :class="{invisible: hideInSeconds < 0 && !waitForItemFinished}")
        //- hide menu countdown
        .time-left(v-if="hideInSeconds >= 0") {{ hideInSeconds }}
        //- Video/Slides still playing after $HIDE_MENU_AFTER seconds
        fa.fa-icon(v-else icon="hourglass-half" fixed-width)

      .resume-timer(:class="{invisible: resumeInSeconds < 0 && !waitForItemFinished}")
        //- resume playback countdown
        .time-left(v-if="resumeInSeconds >= 0") {{ resumeInSeconds }}
        //- Video/Slides still playing after $RESUME_PLAYBACK_AFTER seconds
        fa.fa-icon(v-else icon="hourglass-half" fixed-width)

      .resume-timer.pin-code-timer(v-if="needsMenuPinCode", :class="{invisible: codeExpiredInSeconds < 0}")
        //- resume playback countdown
        .time-left(v-if="codeExpiredInSeconds >= 0") {{ codeExpiredInSeconds }}

  .playback-area
    .menu-button(@click="showPrevious", :class="{invisible: hideArrowButton}")
      fa.fa-icon(:icon="['far', 'arrow-alt-up']" fixed-width)

    //- Paused mode, show "Play" button
    .menu-button(v-if="isPaused" @click="togglePlayback", :class="{invisible: hidePlayButton}")
      fa.fa-icon(:icon="['far', 'play']" fixed-width)
    //- Play mode, show "Pause" button
    .menu-button(v-if="!isPaused" @click="togglePlayback", :class="{invisible: hidePlayButton}")
      fa.fa-icon(:icon="['far', 'pause']" fixed-width)

    .menu-button(@click="showNext", :class="{invisible: hideArrowButton}")
      fa.fa-icon(:icon="['far', 'arrow-alt-down']" fixed-width)
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

nav#interactive-menu
  position: relative
  z-index: 10

  width: 5vw
  height: 100%
  background: #111
  color: var(--gray-light)
  font-size: 2vmin
  padding: 1em 0

  display: flex
  flex-flow: column nowrap
  justify-content: space-between
  align-items: stretch

  .menu-button
    font-size: 4vmin
    border-radius: 0.3em
    height: 1.5em
    width: 1.5em
    cursor: pointer

    -webkit-tap-highlight-color: transparent

    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: center

    &:active
      background: -white(0.15)

    // Invisible but still take the vertical/horizontal spaces
    &.invisible
      background: transparent
      visibility: hidden
      opacity: 0
      pointer-events: none
      z-index: -1000

  .main-area
    flex: 1 1 0.00001px
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center

  .playback-area
    margin-top: 1em

    display: flex
    flex-flow: column nowrap
    justify-content: flex-end
    align-items: center

    .menu-button
      margin-top: 0.5em
      &:first-of-type
        margin-top: 0

  .resume-timer
    margin: 1em 0
    text-align: center
    position: relative
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    width: 1.6em
    height: 1.6em
    border-radius: 3em
    border: 1px solid var(--gray)

    &.auto-hide-timer
      background-color: -white(0.15)
      border-radius: 0.3em

    &.pin-code-timer
      border: 2px dotted var(--gray)

    &.invisible
      opacity: 0
      z-index: -1000

    .time-left
      font-size: 0.65em

    .fa-icon
      font-size: 0.7em
</style>
