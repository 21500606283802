<script>
import MetricsMixins from './MetricsMixins.vue'
import MetricsDataMixins from './MetricsDataMixins.vue'

import BarchartItem from './BarchartItem.vue'

import Metrics from 'services/metrics.js'
import { EventBus } from 'services/eventbus.js'

export default {
  name: 'MetricsBarchart',
  components: { BarchartItem },

  mixins: [MetricsMixins, MetricsDataMixins],

  props: {
    lightTheme: { type: Boolean, default: false }
  },

  data () {
    return {
      data: [],
      itemsData: [],

      itemsMetricKeysMap: []
    }
  },

  watch: {
    validItems: {
      deep: true,
      handler () {
        this.updateMetricMap()
      }
    }
  },

  created () {
    this.updateMetricMap()
  },

  mounted () {
    EventBus.$on('ws-widget-metrics', this.updateCurrentData)
    this.renderInitData()
  },

  beforeDestroy () {
    EventBus.$off('ws-widget-metrics', this.updateCurrentData)
  },

  methods: {
    updateMetricMap () {
      if (!this.hasValidItem) {
        this.itemsMetricKeysMap = []
        return
      }

      const refsMap = []
      this.validItems.forEach((item, itemIdx) => {
        const metric = item.metric

        if (!refsMap[itemIdx]) {
          refsMap[itemIdx] = {}
        }

        if (metric && metric.ref && metric.ref.length) {
          refsMap[itemIdx].ref = metric.ref
        }
      })

      this.itemsMetricKeysMap = refsMap
      this.$nextTick(() => {
        this.renderInitData()
        this.updateInvolvedItems()
      })
    },

    updateItemData (idx) {
      if (!this.hasValidItem) { return }

      const item = this.validItems[idx]
      if (!item) { return }

      const rounding = item.rounding || 0
      const abbreviate = item.abbreviate
      const valueType = item.value_type

      const track = { min: item.min, max: item.max }
      if (item.color && item.color.trim().length) {
        track.color = item.color.trim()
      }

      const targetData = ((this.data && this.data[idx]) || {}).value

      if (!targetData) { return }

      const bars = []
      if (Array.isArray(targetData) && targetData.length > 0) {
        if (Array.isArray(targetData[0])) {
          targetData.forEach(row => {
            bars.push({
              label: { text: row[0] },
              number: { value: row[1] || 0, rounding, abbreviate, value_type: valueType }
            })
          })
        } else if (typeof targetData[0] === 'object') {
          targetData.forEach(row => {
            bars.push({
              label: { text: row.label },
              number: { value: row.value || 0, rounding, abbreviate, value_type: valueType }
            })
          })
        }
      } else if (typeof targetData === 'object') {
        const barKeys = Object.keys(targetData)
        barKeys.forEach(barKey => {
          bars.push({
            number: { value: targetData[barKey] || 0, rounding, abbreviate, value_type: valueType },
            label: { text: Metrics.keyToTitle(barKey) }
          })
        })
      }

      if (bars.length === 0) {
        this.$set(this.itemsData, idx, {})
        return
      }
      this.$set(this.itemsData, idx, {
        bars,
        track
      })
    }
  }
}
</script>

<template lang="pug">
section.metrics-barchart
  transition(:name="transitionName", :duration="500" appear)
    barchart-item.prime(v-if="showPrime && primeItem"
                 :title="itemTitle(primeItem)"
                 :remove-title="removeTitle"
                 :item="itemData(primeIndex)"
                 :sort-by-value="primeItem.sort_by_value === 'true'"
                 :is-paginated="needsPaginate"
                 :light-theme="lightTheme")

  transition(:name="transitionName", :duration="500" appear)
    barchart-item.base(v-if="!showPrime && baseItem"
                 :title="itemTitle(baseItem)"
                 :remove-title="removeTitle"
                 :item="itemData(baseIndex)"
                 :sort-by-value="baseItem.sort_by_value === 'true'"
                 :is-paginated="needsPaginate"
                 :light-theme="lightTheme")
</template>

<style lang="stylus">
section.metrics-barchart
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-barchart-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
