<script>
import qs from 'qs'

export default {
  name: 'WindyTVItem',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      lat: 0,
      lon: 0,
      zoom: 5,
      useFahrenheit: false,
      city: '',
      sourcelocation: '',
      overlay: '',
      windytvsrc: ''
    }
  },

  watch: {
    item: {
      deep: true,
      handler (newVal) {
        if (newVal) {
          this.render()
        }
      }
    }
  },

  mounted () {
    this.render()
  },

  methods: {
    render () {
      if (!this.item || (!this.item.url && !this.item.location)) { return }

      let location = decodeURIComponent(this.item.url || this.item.location) || ''

      // Old apps using coordinates as arguments
      if (location instanceof Array && location.length === 2) {
        location = `lat=${location[0]}&lon=${location[1]}`
      } else if (/\d/.test(location) && location.includes(',')) {
        location = location.replace(/ /g, '').split(',')
        location = `lat=${location[0]}&lon=${location[1]}`
      }

      const params = qs.parse(location)
      if (!params) { return }

      this.lat = params.lat || this.lat
      this.lon = params.lon || this.lon
      this.zoom = params.zoom || this.zoom
      this.city = params.city || this.city
      this.lat = params.lat || this.lat
      this.lon = params.lon || this.lon
      this.overlay = params.overlay || this.overlay
      this.useFahrenheit = (params.useFahrenheit === true || params.useFahrenheit === 'true')
      this.sourcelocation = params.sourcelocation || this.sourcelocation
      this.initWindyTvIframeURL()
    },

    setWindyTvIframeURL () {
      /* eslint-disable */
      let windytvsrc = `https://www.windytv.com/embed2.html?lat=${parseFloat(this.lat)}&lon=${parseFloat(this.lon)}&zoom=${parseInt(this.zoom, 10)}&level=surface&overlay=${this.overlay}&menu=true&message=true&marker=true&forecast=12&calendar=now&location=coordinates&type=map&actualGrid=&metricWind=kt&radarRange=-1`
      /* eslint-enable */

      // DEV-841
      // Support Fahrenheit unit
      windytvsrc += this.useFahrenheit ? '&metricTemp=%C2%B0F' : '&metricTemp=%C2%B0C'

      // DEV-381
      // Use the Include Spot Forecast Option from WindyTV instead of our weather
      if (this.sourcelocation && this.sourcelocation.length) {
        windytvsrc += `&detail=true&detailLat=${parseFloat(this.lat)}&detailLon=${parseFloat(this.lon)}`
      }

      this.windytvsrc = windytvsrc
    },

    initWindyTvIframeURL () {
      this.setWindyTvIframeURL()
    },

    loadedHandler () {
      this.$emit('loaded')
    }
  }
}
</script>

<template lang="pug">
section.windytv-page-item
  iframe(v-if="windytvsrc"
         :src="windytvsrc"
         frameborder="0"
         seamless
         @load="loadedHandler")
</template>

<style lang="stylus">
.windytv-page-item
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 0

  iframe
    width: 100%
    height: 100%
    border: 0
</style>
