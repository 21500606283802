<script>
import {
  mapGetters
} from 'vuex'

import Playlists from './Playlists.vue'
import DeviceWebapp from './DeviceWebapp.vue'
import Identify from './Identify.vue'

import Telemetry from 'services/telemetry.js'
import Env from 'services/environment'

export default {
  name: 'Player',
  components: {
    Playlists,
    DeviceWebapp,
    Identify
  },

  data () {
    return {
      showIdentify: false,

      identifyTimeout: undefined
    }
  },

  computed: {
    ...mapGetters([
      'deviceInWebappMode'
    ])
  },

  mounted () {
    clearTimeout(this.identifyTimeout)

    Telemetry.on('identify', this.identifyActive)
    Telemetry.on('screenshot', this.screenshotCommand)
  },

  beforeDestroy () {
    Telemetry.removeListener('identify', this.identifyActive)
    Telemetry.removeListener('screenshot', this.screenshotCommand)
    clearTimeout(this.identifyTimeout)
  },

  methods: {
    identifyActive () {
      this.showIdentify = true
      clearTimeout(this.identifyTimeout)
      this.identifyTimeout = setTimeout(() => {
        clearTimeout(this.identifyTimeout)
        this.showIdentify = false
      }, 60000)
    },

    screenshotCommand () {
      Env.screenshot()
    }
  }
}
</script>

<template lang="pug">
section.player-screen
  transition(name="fade")
    identify(v-if="showIdentify")
    device-webapp(v-else-if="deviceInWebappMode")
    playlists(v-else)
</template>

<style lang="stylus">
section.player-screen
  position: relative
  width: 100%
  height: 100%
  overflow: hidden
</style>
