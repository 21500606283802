<!-- For displaying image/video/slides items -->
<!-- Support only one item, with TTV content data (id, name, public_url,...) -->
<script>
import Spinner from 'components/common/Spinner'
import ImageItem from 'components/items/ImageItem.vue'
import VideoItem from 'components/items/VideoItem.vue'
import SlidesItem from 'components/items/SlidesItem.vue'

export default {
  name: 'MediaItem',
  components: {
    Spinner,
    ImageItem,
    VideoItem,
    SlidesItem
  },

  props: {
    item: {
      type: Object,
      default: () => { return {} }
    },

    showControls: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    mediaType () {
      if (!this.item || !this.item.id) { return '' }
      if (this.item.content_type === 'slides' || this.item.slides) {
        return 'slides'
      }
      if ((this.item.content_type || '').startsWith('video')) {
        return 'video'
      }
      return 'image'
    },

    mediaItem () {
      if (!this.mediaType) { return }

      // Slides
      if (this.mediaType === 'slides') {
        let slidesUrl = ''
        if (this.item.public_urls && this.item.public_urls.length) {
          slidesUrl = this.item.public_urls.join(',')
        } else {
          slidesUrl = this.item.public_url || ''
        }
        return {
          url: slidesUrl
        }
      }

      // Video
      if (this.mediaType === 'video') {
        return {
          muted: false,
          url: this.item.public_url || this.item.url || '',
          tapInteraction: true
        }
      }

      // Image
      return {
        image_fit: 'contain',
        url: this.item.public_url || this.item.url || ''
      }
    }
  },

  watch: {
    'item.id' (newValue) {
      if (newValue) {
        this.loading = true
      }
    }
  },

  methods: {
    finished () {
      this.$emit('finished')
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded', this.mediaType)
      }
    },

    emitPauseState (payload) {
      this.$emit('is-paused', payload)
    }
  }
}
</script>

<template lang="pug">
section.media-item
  .loading-mask(v-if="loading")
    spinner(size="8em")

  slides-item(v-if="mediaType === 'slides'"
              :item="mediaItem"
              :key="mediaItem.id"
              :show-controls="showControls"
              @finished="finished"
              @loaded="loaded"
              standalone
              active)
  video-item(v-if="mediaType === 'video'"
              :item="mediaItem"
              :key="mediaItem.id"
              :show-controls="showControls"
              @finished="finished"
              @loaded="loaded"
              @is-paused="emitPauseState"
              standalone
              active)
  image-item(v-if="mediaType === 'image'"
              :item="mediaItem"
              :key="mediaItem.id"
              @loaded="loaded"
              active)
</template>

<style lang="stylus">
section.media-item
  width: 100%
  height: 100%
  position: absolute

  > .loading-mask
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 0
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .image-page,
  .video-page,
  .slides-page
    z-index: 5
</style>
