<script>
import ContentCache from 'services/contentCache'
import Log from 'services/log'
import { EventBus } from 'services/eventbus.js'

const CONFIG = {
  // in sec. Page (Item) Transition duration
  TRANSITION_TIME: 1,
  // in sec. To cover next page's preload time
  TRANSITION_DELTA: 5,
  // in sec. Default KenBurns duration time
  DEFAULT_DURATION: 60,
  // Minimum time allowed in the Media Zone/Folder's "Interval" input field
  MIN_DURATION: 1,
  // in ms. Fallback checking for image loading state
  RECHECK_TIMEOUT: 30000
}

const RESPONSE_IS_NULL = 'The response is null'
const ERROR_READING_BOLB = 'Error reading blob from response'

export default {
  name: 'ImageItem',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    noMixedMode: {
      type: Boolean,
      default: false
    },
    // Optional Cache Stack name
    // - TTV Uploaded images (URL pattern checked) don't need stack name
    // - "media": OneDrive Folder, GoogleDrive Folder
    // - "rss": RSS apps
    // - "ads": Programmatic Ad (E.g., Place Exchanged)
    // - "playlistAd": Playlist Advertising Sources
    // - "app": For the rest apps (Mainly QA/Deprecated apps: Facebook, Instagram)
    stack: {
      type: String,
      default: ''
    },
    // Indicator of embedding in MediaZone (MediaFolder included)
    isMediaZone: {
      type: Boolean,
      default: false
    },
    needsFinishedEvent: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      displayUrl: '',
      blobUrl: '',

      destroyed: false,

      checkLoadingTimer: null,
      finishedTimer: null,
      fallbackLoadTimer: null
    }
  },

  computed: {
    url () {
      let url = this.item.url || this.item.location || ''
      if (ContentCache.isCdnUrl(url)) {
        url = ContentCache.addNoSwFlag(url)
      }
      return url
    },

    srcFileName () {
      return ContentCache.getFileNameFromURL(this.url) || ''
    },

    animatedZoomEffect () {
      return this.item.animated_zoom_effect
    },

    fitMode () {
      // Force "no-mixed" image in contain mode
      if (this.noMixedMode) {
        return 'fit-contain'
      }
      return `fit-${this.item.image_fit || 'cover'}`
    },

    posStyle () {
      // camelCase property from MediaZone
      if (this.item && this.item.imagePos && this.item.imagePos.length) {
        return {
          backgroundPosition: this.item.imagePos
        }
      }
      if (this.item && this.item.image_pos && this.item.image_pos.length) {
        return {
          backgroundPosition: this.item.image_pos
        }
      }
    },

    repeatStyle () {
      // camelCase property from MediaZone
      if (this.item && this.item.imageRepeat && this.item.imageRepeat.length) {
        return {
          backgroundRepeat: this.item.imageRepeat
        }
      }
      if (this.item && this.item.image_repeat && this.item.image_repeat.length) {
        return {
          backgroundRepeat: this.item.image_repeat
        }
      }
    },

    backgroundImage () {
      if (!this.displayUrl || !this.displayUrl.length) { return }
      return {
        backgroundImage: `url(${this.displayUrl})`
      }
    },

    imageDurationInSec () {
      return Math.max(CONFIG.MIN_DURATION, this.item.duration || CONFIG.DEFAULT_DURATION)
    },

    animations () {
      if (!this.animatedZoomEffect) {
        return {}
      }
      return {
        animationDuration: `${this.imageDurationInSec + CONFIG.TRANSITION_TIME + CONFIG.TRANSITION_DELTA}s`
      }
    }
  },

  watch: {
    url () {
      this.loadImage()
    }
  },

  mounted () {
    this.loadImage()
  },

  beforeDestroy () {
    this.destroyed = true

    clearTimeout(this.checkLoadingTimer)
    clearTimeout(this.finishedTimer)
    clearTimeout(this.fallbackLoadTimer)

    if (this.isMediaZone) {
      // Fallback record for manually navigation
      this.$emit('play-ended')
    }

    this.revokeBolbURL(this.blobUrl)
  },

  methods: {
    async readBlobFromResponse (response) {
      if (!response) {
        return Promise.reject(new Error(RESPONSE_IS_NULL))
      }
      let blobData
      try {
        blobData = await response.blob()
      } catch (err) {
        const errMsg = `${ERROR_READING_BOLB}: ${err.message || err.toString()}`
        Log.debug('media', errMsg, 'DBG_READBLOBIMAGEITEM', err)
        return Promise.reject(new Error(errMsg))
      }
      return Promise.resolve(blobData)
    },

    loadImage () {
      clearTimeout(this.checkLoadingTimer)
      clearTimeout(this.finishedTimer)
      clearTimeout(this.fallbackLoadTimer)

      // Force reset URL to prevent flicking of previous image item
      this.displayUrl = ''

      const url = this.url

      if (!url || !url.length) { return }

      // Recheck loading state after the defined timeout
      this.recheckLoading()

      const img = new Image()
      img.onload = async () => {
        let promise
        // RSS feed metadata images
        if (this.stack === 'rss') {
          promise = ContentCache.cachedFetch(ContentCache.RSS_IMAGES, url, true)
        // Playtlist Ad
        } else if (this.stack === 'playlistAd') {
          promise = ContentCache.cachedFetch(ContentCache.PLAYLIST_AD, url, true, this.item.cacheKey)
        // Programmatic Ad
        } else if (this.stack === 'ads') {
          promise = ContentCache.cachedFetch(ContentCache.PRGM_AD, url, true, this.item.cacheKey)
        // Drive Folders (Media Folder not included)
        } else if (this.stack === 'media') {
          promise = ContentCache.cachedFetch(ContentCache.MEDIA_DRIVES, url, true)
        // Apps images and Unsplash URLs
        } else if (this.stack === 'app' || ContentCache.isUnsplashSrc(url)) {
          promise = ContentCache.cachedFetch(ContentCache.APP_IMAGES, url, true)
        // TTV Upload images
        } else if (ContentCache.isTTVUploads(url)) {
          promise = ContentCache.cachedFetch(ContentCache.IMAGES, url, true)
        // The other TTV CDN images
        // Just a fallback for any edge use-case
        } else if (ContentCache.isCdnUrl(url)) {
          promise = ContentCache.cachedFetch(ContentCache.CDN_RESRC, url, true)
        }

        if (promise) {
          promise.then(response => {
            if (!response.ok) {
              return
            }

            return this.readBlobFromResponse(response).then(blobData => {
              const blobUrl = URL.createObjectURL(blobData)

              clearTimeout(this.checkLoadingTimer)

              if (this.displayUrl !== blobUrl) {
                this.displayUrl = blobUrl
              }

              // If the image has been cached by the browser, loading img never hit Service Worker, therefore we need
              // to manually inform our cache detection system.
              this.$emit('loaded')

              this.$store.commit('addCachedContentURL', {url, cacheName: ContentCache.IMAGES})

              if (this.blobUrl && blobUrl !== this.blobUrl) {
                this.revokeBolbURL(this.blobUrl)
              }

              // Store for GC in a latter phase
              this.blobUrl = blobUrl

              this.prepareFinishedEvent()

            // Catch readBlobFromResponse error here
            }).catch(error => {
              // Return + throw error to the upper level "catch" errorHandler
              throw error
            })
          }).catch(err => {
            clearTimeout(this.checkLoadingTimer)
            this.errorHandler(err, url)
            this.$emit('error')
          })

        // Failsafe for Non-TTV nor TTV-proxied URLs
        } else {
          clearTimeout(this.checkLoadingTimer)
          // Resoource images
          if (ContentCache.isResourcesUrl(url)) {
            Log.debug('media', `Loading resource image "${this.srcFileName}"`, 'DBG_LOADRESOURCEIMG', { url })
          // Other external images
          } else {
            Log.debug('media', `Loading external image "${url}"`, 'DBG_LOADEXTERNALIMG', { url })
          }
          this.setDisplayURL(url)
          this.$emit('loaded')
          this.prepareFinishedEvent()
        }
      }
      img.onerror = (err) => {
        clearTimeout(this.checkLoadingTimer)
        // TTV Uploads
        if (ContentCache.isTTVUploads(this.url)) {
          Log.warn('media', `Failed to load image (${this.srcFileName})`, 'WAR_FAILEDIMAGELOAD', { error: err, url: this.url })
        // Other images
        } else {
          Log.debug('media', `Failed to load image (${this.srcFileName})`, 'DBG_FAILEDIMAGELOAD', { error: err, url: this.url }, true)
        }
        this.$emit('error')
      }
      img.src = ContentCache.loadTriggerURI()
    },

    errorHandler (err, url = '') {
      const errMsg = err.message || err.toString() || ''

      if (err.name && err.name.indexOf('NotInCache') >= 0) {
        err = 'fetch failed'
        Log.warn('media', `Error fetching image - ${errMsg} (${this.srcFileName})`, 'WAR_ERRFETCHIMAGENAME', { url, error: err })
      } else {
        // File is null or corrupted
        if (errMsg.indexOf(RESPONSE_IS_NULL) >= 0 || errMsg.indexOf(ERROR_READING_BOLB) >= 0) {
          Log.warn('media', `Error loading image - ${errMsg} (${this.srcFileName})`, 'WAR_ERRLOADIMAGE', { url, error: err })
        // "AbortError: The operation was aborted."
        // Possibly happen during player exit, or playlist advancing to the next page, or switching to another image in the Media zone
        } else if (errMsg.indexOf('operation was aborted') >= 0) {
          Log.debug('media', `Image loading aborted - ${errMsg} (${this.srcFileName})`, 'DBG_IMAGELOADABORT', { url, error: err })
          // Added a "return" here, to skip removing the image from cache
          return
        } else if (errMsg.indexOf('timeout') >= 0) {
          Log.warn('media', `${errMsg} (${this.srcFileName})`, 'WAR_IMAGELOADTIMEOUT', { url, error: err })
        } else {
          Log.warn('media', `Error loading image from cache - ${errMsg} (${this.srcFileName})`, 'WAR_ERRLOADIMAGECACHE', { url, error: err })
        }
      }

      this.$store.commit('removeCachedContentURL', {url, cacheName: ContentCache.IMAGES})
    },

    // Fallback checker for image loading state
    recheckLoading () {
      clearTimeout(this.checkLoadingTimer)
      this.checkLoadingTimer = setTimeout(() => {
        clearTimeout(this.checkLoadingTimer)
        if (!this.displayUrl?.length) {
          this.errorHandler(new Error(`Image loading timeout - Unable to load the image in ${CONFIG.RECHECK_TIMEOUT}ms`), this.url)
          this.$emit('error')
        }
      }, CONFIG.RECHECK_TIMEOUT)
    },

    // Prepare "finished" event when it's requested by the Meida Folder [ENG-818]
    prepareFinishedEvent () {
      clearTimeout(this.finishedTimer)
      clearTimeout(this.fallbackLoadTimer)

      if (!this.needsFinishedEvent || this.destroyed) { return }

      this.finishedTimer = setTimeout(() => {
        clearTimeout(this.finishedTimer)

        this.$emit('finished')

        if (this.destroyed) {
          // Prevent timer keep running in the background after component is destroyed
          return
        }

        this.prepareFinishedEvent()

        clearTimeout(this.fallbackLoadTimer)
        this.fallbackLoadTimer = setTimeout(() => {
          clearTimeout(this.fallbackLoadTimer)
          if (this.destroyed) { return }
          // Send "loaded' event again to trigger "itemLoaded" in parent component
          this.$emit('loaded')
        }, 1000)
      }, this.imageDurationInSec * 1000)
    },

    setDisplayURL (url = '') {
      this.displayUrl = (url || '') + ''
    },

    revokeBolbURL (blobUrl = '') {
      if (!blobUrl.length) { return }
      // Revoke for better GC
      // Handle URL.revokeObjectURL() in the app.vue (root) with delay to prevent flickering
      EventBus.$emit('revoke-blob-url', blobUrl + '')
    }
  }
}
</script>

<template lang="pug">
section.image-page
  //- Add one more layer to prevent conflicts with the page animation
  .image-container(:class="[fitMode, {'animated-zoom': animatedZoomEffect}]"
                   :style="[backgroundImage, repeatStyle, posStyle, animations]")
</template>

<style lang="stylus">
$totalTime = 61s
$scale = 0.2 // 20%
$scaleBase = 1 + $scale

@keyframes kenburns
  from
    transform: scale($scaleBase)
  to
    transform: scale(1)

section.image-page
  width: 100%
  height: 100%
  position: absolute

  .image-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-position: 50% 50%
    background-size: cover
    background-repeat: no-repeat

    &.animated-zoom
      animation-name: kenburns
      animation-timing-function: linear
      animation-iteration-count: 1
      animation-fill-mode: forwards
      animation-duration: $totalTime
      transform: scale($scaleBase)
      background-size: cover

    &.fit-contain
      background-size: contain

    &.fit-fill
      background-size: 100% 100%

    &.fit-origin
      background-size: auto
</style>
