<script>
import {
  mapGetters
} from 'vuex'

import Background from 'components/common/Background.vue'
import OverlayHeader from 'components/common/OverlayHeader.vue'
import Message from 'components/common/Message.vue'
import Version from 'components/common/Version.vue'

import Log from 'services/log.js'
import Environment from 'services/environment'
import ActiveWindow from 'services/active-window.js'

const STARTUP_TIMEOUT = 1000 * 60 * 5 // 5 mins

export default {
  name: 'Starting',
  components: {
    Message,
    Background,
    OverlayHeader,
    Version
  },

  data () {
    return {
      startupTimeout: null
    }
  },

  computed: {
    ...mapGetters([
      'authenticationToken',
      'apiLauncherWebsocketMode',
      'provisioningToken',
      'apiStatus',
      'isSwitchingRegion',
      'activeToken',
      'hasAuthToken',
      'deviceDisabled',
      'localDeviceDisabed',
      'deviceActiveHours',
      'deviceActiveWindows',
      'isOverridingByPlaylist'
    ]),

    hideStartMessages () {
      return Environment.pageEditMode || Environment.desktopMode || Environment.previewMode
    },

    // Stay on the current screen and do not redirect to other screens when AUTH error occurs
    stayOnAuthError () {
      return Environment.pageEditMode || Environment.desktopMode || Environment.previewMode || Environment.inPlaySection
    }
  },

  watch: {
    isSwitchingRegion (toTrue = false) {
      // Done switching region
      if (!toTrue) {
        if (this.inActiveHours()) {
          this.$router.push({
            name: 'run'
          })
        } else {
          this.$router.push({
            name: 'inactive'
          })
        }
      }
    }
  },

  mounted () {
    clearTimeout(this.startupTimeout)

    // Skip for IOS App
    if (!Environment.ioswebkit) {
      if (Environment.restartRequired && !Environment.pageEditMode && !Environment.previewMode) {
        // NOTE: Force grab the English (en) version message for device logs [DEV-2329]
        let logMsg = this.$t('restarting.playerReload.text', 'en')
        let chunkName
        if (Environment.restartFrom) {
          logMsg += ' - '
          chunkName = Environment.getRestartFromChunkName()
          logMsg += (chunkName ? this.$t(Environment.restartDescription(), 'en', {chunk: chunkName}) : this.$t(Environment.restartDescription(), 'en'))
        }
        Log.warn('player', logMsg, 'WAR_PLAYERRELOAD')

        const msg = this.$t('restarting.playerReload.text')
        let description = ''
        if (Environment.restartFrom) {
          description = chunkName ? this.$t(Environment.restartDescription(), {chunk: chunkName}) : this.$t(Environment.restartDescription())
        }
        const query = { message: msg, description }

        // Electron Vue Player
        if (Environment.isVueElectronPlayer()) {
          Environment.restart(query)
          return
        }
        // Desktop Mode Restart
        if (Environment.desktopMode) {
          const landingURL = Environment.getDesktopLandingPage(this.activeToken)
          if (landingURL && landingURL.length) {
            window.location.href = landingURL
          }
          return
        }

        // Iframe Embed Mode Restart [DEV-3095]
        if (Environment.iframeEmbedMode) {
          const embedURL = Environment.getIframeEmbedUrl()
          if (embedURL && embedURL.length) {
            window.location.href = embedURL
          }
          return
        }

        this.$router.push({
          name: 'restarting',
          query
        })
        return
      }
    }

    // Once this component is mounted in the dom start a time out. If the
    // timeout is exceeded then restart the environment.
    clearTimeout(this.startupTimeout)
    this.startupTimeout = setTimeout(() => {
      clearTimeout(this.startupTimeout)
      Log.error('player', 'Startup 300s bailout timeout', 'ERR_ENVTIMEOUT')
      this.errorHandler({
        params: {
          error: 'internet-offline'
        }
      })
    }, STARTUP_TIMEOUT)

    this.$store.dispatch('startAPI').then(() => {
      if (this.authenticationToken) {
        if (Environment.pageTemplateID) {
          this.$router.push({
            name: 'pagetemplate',
            params: {
              templateID: Environment.pageTemplateID
            }
          })
        } else if (this.deviceDisabled) {
          this.$router.push({
            name: 'errored',
            params: {error: 'device-disabled'}
          })
        } else if (this.inActiveHours()) {
          this.$router.push({
            name: 'run'
          })
        } else {
          this.$router.push({
            name: 'inactive'
          })
        }
      } else if (this.apiLauncherWebsocketMode) {
        // Stay on the current page and wait for auth etc., info
        console.warn('Starting Player with Launcher mode')
      } else if (this.provisioningToken && this.provisioningToken.token) {
        this.$router.push({
          name: 'provisioning'
        })
      } else {
        this.$router.push({
          name: 'pair'
        })
      }
    }).catch(err => {
      Log.error('player', `Error starting up - ${err.message || err.code || err.toString()}`, 'ERR_UNABLESTARTAPI3', err)

      if (this.deviceDisabled || this.localDeviceDisabed) {
        this.$router.push({
          name: 'errored',
          params: {error: 'device-disabled'}
        })
        return
      }

      // Code 401 ("authentication token not found")
      // - Device has been removed from the User App
      // - Previewing in User App but the user token has expired (TTL ~ 10min)
      if (
        (err.code && err.code === 401) ||
        (err.message && err.message.indexOf('[code: 401]') >= 0)
      ) {
        if (this.stayOnAuthError) {
          // Stay on the current page. Do not redirect to "Pairing" screen in Preview Mode.
          Log.warn('player', 'Preview mode session token expired, please reload the Desktop App window', 'WAR_SESSIONEXPIRED')
        } else if (this.provisioningToken && this.provisioningToken.token) {
          this.$router.push({
            name: 'provisioning'
          })
        } else {
          this.$router.push({
            name: 'pair'
          })
        }
      // When device is outside the Active Window
      } else if (!this.inActiveHours()) {
        this.$router.push({
          name: 'inactive'
        })
      } else if (
        // [A] this.isSwitchingRegion === false: device is not switching region
        // > When device is switching region, do nothing and wait for the Watcher to handle changes
        (!this.isSwitchingRegion) &&
        // [B-1] For non-launcher mode devices
        // [B-2] Or device in launcher mode and has an Auth token
        (!this.apiLauncherWebsocketMode || (this.apiLauncherWebsocketMode && this.hasAuthToken))
      ) {
        // Let the "Run" screen handel "No Webapp Set", "Empty Playlist", or "Playlists Filtered" etc situations.
        // No need to check device WebappId or active Playlists here.
        this.$router.push({
          name: 'run'
        })
      }
    })
  },

  beforeDestroy () {
    clearTimeout(this.startupTimeout)
  },

  methods: {
    errorHandler (payload) {
      const params = (payload && payload.params) || {}
      const query = (payload && payload.query) || {}
      this.$router.push({
        name: 'errored',
        params,
        query
      })
    },

    inActiveHours () {
      // No Active Windows / Active Hours set
      if (!this.deviceActiveHours && !this.deviceActiveWindows) { return true }

      if (this.isOverridingByPlaylist) {
        return true
      }

      // Not in the active hours
      if (!ActiveWindow.inActiveWindow()) {
        return false
      }

      return true
    }
  }
}
</script>

<template lang="pug">
main.starting(:class="{'hide-messages': hideStartMessages}")
  background(v-if="!hideStartMessages")
  message(:title="$t('starting.msgTitle')", :text="$t('starting.msgText')")
  overlay-header(icon="plug", :title="'Websocket ' + apiStatus")
  version
</template>

<style lang="stylus">
main.starting
  // Hide messages in Page Edit Mode
  &.hide-messages
    > .common-message,
    > .overlay-header,
    .common-versions-banner
      opacity: 0
      visibility: hidden
</style>
