<script>
import Log from 'services/log.js'
import WeatherServ from 'services/weather/index.js'

const config = {
  // Refreshes every 30m
  UPDATE_INTERVAL: 1800000
}

const DEFAULT_ICON = 'cloud'

export default {
  name: 'SimpleWeather',
  props: {
    location: { type: String, required: true },
    darkMode: { type: Boolean, default: false }
  },

  data () {
    return {
      city: undefined,
      weather: undefined,
      weatherCode: undefined,
      temp: undefined,
      unit: 'c',
      units: undefined,

      updateTimer: undefined,
      debounceTimer: undefined
    }
  },

  computed: {
    faIcon () {
      if (!this.weather) {
        return (DEFAULT_ICON + '')
      }
      const icon = WeatherServ.awCodeToFontAwesome(this.weatherCode, this.weather.daynight) || (DEFAULT_ICON + '')
      if (Array.isArray(icon) && icon.length > 0 && icon[0] === 'fal') {
        icon[0] = 'fas'
      }
      return icon
    }
  },

  watch: {
    location (newValue) {
      if (newValue && newValue.length) {
        this.debounceUpdate()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    this.$nextTick(() => {
      this.debounceUpdate()
    })

    // Automatically update
    clearInterval(this.updateTimer)
    this.updateTimer = setInterval(() => {
      this.debounceUpdate()
    }, config.UPDATE_INTERVAL)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearInterval(this.updateTimer)
  },

  methods: {
    debounceUpdate (useFahrenheit) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.updateWeather(useFahrenheit)
      }, 500)
    },

    updateWeather (useFahrenheit) {
      this.unit = useFahrenheit ? 'f' : 'c'

      const location = this.location

      if (!location) {
        Log.warn('app', 'Weather Widget: Unable to determine weather location', 'WAR_WEATHERAPPNOLOC3')
        return
      }

      WeatherServ.current(location, useFahrenheit).then(currentWeather => {
        if (this.needsFahrenheit(currentWeather)) {
          this.updateWeather(true)
          return
        }

        this.weather = Object.freeze(currentWeather)
        this.weatherCode = currentWeather.icon.replace('wi-aw-', '')
        this.temp = Math.round(currentWeather.temp)

        this.city = currentWeather.location

        const units = {}
        // AccuWeather contains units in the result
        if (currentWeather.units) {
          units.speed = currentWeather.units.wind
          units.temperature = currentWeather.units.temp
          units.pressure = currentWeather.units.pressure
          units.visibility = currentWeather.units.visibility
          units.precip = currentWeather.units.precip
        } else {
          units.speed = this.unit === 'f' ? 'mi/h' : 'm/s'
          units.temperature = this.unit === 'f' ? 'F' : 'C'
          units.pressure = 'hPa'
        }
        this.units = units
      }).catch(err => {
        Log.debug('app', `Weather Widget Error: ${err.message || err.toString()}`, 'DBG_WEATHERAPPERR', {error: err})
      })
    },

    needsFahrenheit (current) {
      if (current.country && (current.country === 'United States' || current.country === 'US')) {
        return !this.unit || this.unit === 'c'
      }
      return false
    }
  }
}
</script>

<template lang="pug">
.simple-weather(:class="{'dark-mode': darkMode}")
  .location-wrapper(v-if="city && city.length") {{ city }}
  .temperature-wrapper(v-if="weather && units") {{ temp }}
    span.unit °{{units.temperature}}
    fa.fa-icon(:icon="faIcon")
</template>

<style lang="stylus">
.simple-weather
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  line-height: 100%
  color: inherit

  &.dark-mode
    color: #000000

  .location-wrapper
    font-size: 0.9em
    opacity: 0.6
    padding-bottom: 0.2em
    line-height: 130%

  .temperature-wrapper
    font-size: 1.2em
    font-weight: 600
    line-height: 130%
    .unit
      font-size: 0.85em
      font-weight: 400
    .fa-icon
      padding: 0 0 0 0.3em
      font-size: 1.2em
</style>
