<script>
import AnimationHelper from 'services/animator.js'

import FastDom from 'fastdom'
import { addListener as AddResizeListener, removeListener as RemoveResizeListener } from 'resize-detector'

const MARQUEE_SPEED = 1 // Min 1

export default {
  name: 'Marquee',
  props: {
    messages: { type: Array, default: () => [] },

    // Unit: 'px'
    // To stop the marquee animataion before it totally run through the left border
    leftDelta: { type: Number, default: 0 },
    minDelta: { type: Number, default: 0 },
    tickerId: { type: String, default: '' }
  },
  data () {
    return {
      show: false,
      Animator: undefined,
      nodeWidth: 0,
      waitTimer: undefined,
      debounceTimer: undefined,
      delayTimer: undefined,
      scrollSpeedMultiplier: 5
    }
  },

  computed: {
    textContent () {
      if (!this.messages || !this.messages.length) {
        return ''
      }

      this.$nextTick(() => {
        this.measureWidth()
      })

      return this.messages.map((item) => item.text.replace(/<script.+?>/g, '').replace(/<\/script>/g, '')).join(' · ')
    }
  },

  watch: {
    'messages': {
      deep: true,
      handler (newValue) {
        if (!newValue || !newValue.length) {
          return ''
        }
        this.scrollSpeedMultiplier = newValue[0].scrollSpeed || 5
      }
    }
  },

  mounted () {
    clearTimeout(this.delayTimer)
    clearTimeout(this.waitTimer)
    clearTimeout(this.debounceTimer)

    this.bindAnimator()

    this.$nextTick(() => {
      this.initAnimator()
    })

    this.delayTimer = setTimeout(() => {
      clearTimeout(this.delayTimer)
      this.show = true
    }, 200)

    AddResizeListener(this.$refs.sensor, this.measureWidth)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.measureWidth)
    }

    clearTimeout(this.waitTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)

    if (this.Animator) {
      this.Animator.removeAnimator()
      this.Animator = null
    }
  },

  methods: {
    measureWidth () {
      clearTimeout(this.debounceTimer)
      if (!this.$refs || !this.$refs.measure || !this.textContent) { return }

      const self = this

      let width
      const measure = FastDom.measure(() => {
        if (!self.textContent || !self.textContent.length) {
          self.nodeWidth = 0
          self.renderTextContent()
          FastDom.clear(measure)
          return
        }

        // Fallback double check for ChromeOS
        if (!self.$refs || !self.$refs.measure) {
          FastDom.clear(measure)
          return
        }

        width = self.$refs.measure.getBoundingClientRect().width

        const mutate = FastDom.mutate(() => {
          if (width === 0) {
            clearTimeout(self.debounceTimer)
            self.debounceTimer = setTimeout(() => {
              self.measureWidth()
            }, 100)
            FastDom.clear(mutate)
            FastDom.clear(measure)
            return
          }
          self.nodeWidth = width
          self.renderTextContent()

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    renderTextContent () {
      if (!this.Animator || !this.$refs || !this.$refs.container || !this.$refs.marquee) { return }
      this.Animator.stopAnimation()

      if (this.nodeWidth === 0) { return }

      const container = this.$refs.container

      const containerMetrics = container.getBoundingClientRect()
      const targetWidth = containerMetrics.width

      this.setTransform(this.$refs.marquee, targetWidth)

      this.show = true

      this.Animator.startAnimation(MARQUEE_SPEED, this.animate.bind(this))
    },

    setTransform (marquee, targetWidth) {
      const transform = `translate3d(${targetWidth}px, 0, 0)`
      marquee.style.webkitTransform = transform
      marquee.style.mozTransform = transform
      marquee.style.transform = transform
      marquee.transformValue = targetWidth
    },

    waitAnimator (onReady) {
      clearTimeout(this.waitTimer)
      if (!this.Animator) {
        this.bindAnimator()
        this.waitTimer = setTimeout(() => {
          this.waitAnimator(onReady)
        }, 100)
      } else {
        onReady()
      }
    },

    bindAnimator () {
      this.Animator = new AnimationHelper.Animator()
    },

    initAnimator () {
      if (!this.Animator) {
        this.waitAnimator(() => {
          this.Animator.initAnimator()
        })
        return
      }
      this.Animator.initAnimator()
    },

    animate () {
      if (!this.$refs || !this.$refs.marquee) { return }

      const self = this

      let winWidth
      const measure = FastDom.measure(() => {
        winWidth = window.innerWidth

        const mutate = FastDom.mutate(() => {
          // Fallback double check for ChromeOS
          if (!self.$refs || !self.$refs.marquee) {
            FastDom.clear(mutate)
            FastDom.clear(measure)
            return
          }

          const marquee = self.$refs.marquee

          const delta = self.minDelta === 0 ? (this.scrollSpeedMultiplier / 5) * Math.max(1, ~~(winWidth / 500)) : self.minDelta
          const newX = marquee.transformValue - delta

          // Current marquee block has ended, play next
          if (newX + self.nodeWidth < (self.leftDelta || 0)) {
            self.Animator.stopAnimation()
            self.show = false
            self.$emit('display-next-ticker')
          } else {
            self.setTransform(marquee, newX)
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    replay (tickerId) {
      if (this.tickerId && this.tickerId === tickerId) {
        this.$nextTick(() => {
          this.measureWidth()
        })
      }
    }
  }
}
</script>

<template lang="pug">
.marquee-widget(ref="container")
  .resize-sensor(ref="sensor")
  .wrapper(ref="marquee" v-bind:class="{showing: show}" v-html="textContent")
  .measure(ref="measure" v-html="textContent")
</template>

<style lang="stylus">
.marquee-widget
  position: relative;
  flex: 1;
  z-index: 3;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > .resize-sensor
    position: absolute !important;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  .wrapper
    white-space: nowrap;

    will-change: transform;

    /* Prevent the flickering on the first load */
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;

    &.showing
      opacity: 1;

  .measure
    visibility: hidden;
    position: absolute;
    z-index: -3;
    top: 0;
    white-space: nowrap;
</style>
