<script>
import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import WebshotItem from './webshot/Item'

import Webshots from 'services/webshots.js'
import Log from 'services/log.js'

const config = {
  // In second. Fallback interval
  MIN_INTERVAL: 1,

  // In ms.
  PREPARE_TIME: 1500,

  // In second. Force advance to next item if the current item is not loaded within this duration
  MAX_WAIT_TIME: 30,

  // In ms
  SHOW_HINT_AFTER: 5000
}

export default {
  name: 'Webshots',
  components: {
    WebshotItem
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      refIDs: [],

      webshotItems: [],

      interval: 0,
      transition: '',
      keepLooping: false,

      zoneOptions: {
        image_fit: '',
        image_position: '',
        image_repeat: ''
      },

      showPrime: false,
      primeItem: null,
      baseItem: null,
      currentIndex: -1,

      errorMessage: '',
      hintMessage: '',

      prepareTimer: null,
      showNextTimer: null,
      debounceTimer: null,
      hintTimer: null
    }
  },

  computed: {
    ...mapGetters([
      'pageItemTransition',
      'apiIsOffline'
    ]),

    showHint () {
      return this.hintMessage && this.hintMessage.length
    },

    hasError () {
      return this.errorMessage && this.errorMessage.length
    },

    transitionName () {
      if (!this.needsZonePaginate) {
        return null
      }
      return this.pageItemTransition(this.transition)
    },

    // Use "out-in" transition mode [DEV-2236]
    useOutInMode () {
      return Boolean(this.transition === 'fadeOutIn')
    },

    transitionMode () {
      if (this.useOutInMode && !this.needsZonePaginate) {
        return 'out-in'
      }
      return null
    },

    needsZonePaginate () {
      return Boolean(this.refIDs && this.refIDs.length > 1)
    },

    noForceAdvance () {
      // "Auto-Advance to Next Page" (default to `true`) === keepLooping: false [DEV-2481]
      return this.keepLooping
    }
  },

  watch: {
    'item.url' () {
      this.debounceRender()
    },

    'item.ref_ids' () {
      this.debounceRender()
    }
  },

  mounted () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.hintTimer)

    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.showNextTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.hintTimer)
  },

  methods: {
    render () {
      if (!this.item) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.refIDs = this.item.ref_ids || []

      this.interval = Math.max(config.MIN_INTERVAL, +(options.interval || 0))
      this.transition = options.transition || ''

      // Don't do auto-advance if there's only one webshot image item [DEV-3417]
      this.keepLooping = options.keep_looping === 'true' && (this.refIDs.length > 1)

      this.zoneOptions = {
        image_fit: options.image_fit || '',
        image_position: options.image_position || '',
        image_repeat: options.image_repeat || ''
      }

      this.prepareNext(true, true)

      this.triggerShowNext()
      this.triggerPrepareNext()
    },

    debounceRender () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.render()
      }, 100)
    },

    triggerShowNext () {
      clearTimeout(this.showNextTimer)
      this.showNextTimer = setTimeout(() => {
        clearTimeout(this.showNextTimer)
        this.showNext()
      }, this.interval * 1000)
    },

    triggerPrepareNext () {
      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)
        this.prepareNext()
      }, this.interval * 1000 - config.PREPARE_TIME)
    },

    prepareNext (forceReset = false, displayImmediately = false) {
      clearTimeout(this.hintTimer)
      if (!this.refIDs || !this.refIDs.length) { return }

      if (forceReset) {
        this.currentIndex = -1
      }

      const nextIndex = (this.currentIndex + 1) % this.refIDs.length
      const nextID = this.refIDs[nextIndex]

      this.showHintChecking(nextID)

      Webshots.getWebshotById(nextID, this.apiIsOffline).then(nextItem => {
        clearTimeout(this.hintTimer)
        this.errorMessage = ''
        this.hintMessage = ''

        // Webshot zone has only one item
        if (!this.needsZonePaginate) {
          this.showPrime ? this.primeItem = nextItem : this.baseItem = nextItem
        // Has multiple items
        } else {
          this.showPrime ? this.baseItem = nextItem : this.primeItem = nextItem
        }

        // Display item immediately
        if (displayImmediately) {
          this.currentIndex = nextIndex
          if (this.needsZonePaginate) {
            this.showPrime = !this.showPrime
          }
        }
      }).catch(err => {
        clearTimeout(this.hintTimer)
        this.hintMessage = ''
        const errorMsg = err.message || err.toString() || 'Unknown Error'
        const message = `Get webshot data errored (${nextID}) - ${errorMsg}`
        this.errorMessage = message
        Log.warn('media', `Webshots: ${message}`, 'WAR_WEBSHOTERR', { error: err })
      })
    },

    showNext () {
      if (!this.refIDs || !this.refIDs.length) {
        this.triggerShowNext()
        this.triggerPrepareNext()
        return
      }

      const currentIndexBak = +this.currentIndex

      const nextIndex = (this.currentIndex + 1) % this.refIDs.length
      this.currentIndex = nextIndex

      if (this.needsZonePaginate) {
        // Reach the end of the list + "Auto-Advance to Next Page" is on
        if (currentIndexBak >= this.refIDs.length - 1 && !this.noForceAdvance) {
          this.finished()
        }
        this.showPrime = !this.showPrime
      }

      this.triggerShowNext()
      this.triggerPrepareNext()
    },

    finished () {
      this.$emit('finished')
    },

    itemLoaded () {
      this.$emit('loaded')
    },

    itemErrored () {
      this.forceToNextItem(true)
    },

    forceToNextItem (isErrored) {
      const the2ndLine = this.needsZonePaginate ? '\nForce to show next item now.' : ''

      if (isErrored) {
        Log.warn('media', `Unable to play current webshot item.${the2ndLine}`, 'WAR_UNABLEPLAYWEBSHOT')
      } else {
        Log.debug('media', `The webshot item is still not fully loaded after ${config.MAX_WAIT_TIME}s.${the2ndLine}`, 'DBG_NOTFULLYLOADED')
      }

      // More than one items
      if (this.needsZonePaginate) {
        // Reach the end of the list + "Auto-Advance to Next Page" is on
        if (this.currentIndex >= this.refIDs.length - 1 && !this.noForceAdvance) {
          this.finished()
        }
        // Prepare the next item and display immediately
        this.prepareNext(false, true)
        // Trigger timers for the next loop
        this.triggerShowNext()
        this.triggerPrepareNext()
      // Only one item + "Auto-Advance to Next Page" is on
      } else if (!this.noForceAdvance) {
        this.finished()
      }
    },

    showHintChecking (nextID) {
      clearTimeout(this.hintTimer)
      this.hintTimer = setTimeout(() => {
        clearTimeout(this.hintTimer)
        this.hintMessage = `Loading webshot item data (${nextID})`
        Log.info('media', `Webshot: Unable to get webshot item data in ${config.SHOW_HINT_AFTER}ms (${nextID})`, 'INF_UNABLEGETWEBSHOT')
      }, config.SHOW_HINT_AFTER)
    }
  }
}
</script>

<template lang="pug">
section.webshots-zone
  //- NOTE: All components need to be put in the same <transition> and be keyed with a `key` property
  //- in order to make mode="out-in" worked
  //- > https://forum.vuejs.org/t/solved-vue-transitions-not-working/7614
  transition(:name="transitionName", :duration="500", :mode="transitionMode" appear)
    //- Prime
    .webshot-zone-item.prime(v-if="showPrime", :key="`${_uid}_prime`")
      template(v-if="primeItem")
        webshot-item(:url="primeItem.public_url || primeItem.url"
                     :content-id="primeItem.id"
                     :refresh="primeItem.webshot && primeItem.webshot.refresh"
                     :options="zoneOptions"
                     :is-singular="!needsZonePaginate"
                     @loaded="itemLoaded"
                     @error="itemErrored")
    //- Base
    .webshot-zone-item.base(v-else, :key="`${_uid}_base`")
      template(v-if="baseItem")
        webshot-item(:url="baseItem.public_url || baseItem.url"
                     :content-id="baseItem.id"
                     :refresh="baseItem.webshot && baseItem.webshot.refresh"
                     :options="zoneOptions"
                     :is-singular="!needsZonePaginate"
                     @loaded="itemLoaded"
                     @error="itemErrored")

  transition(name="fade" appear)
    .messages(v-if="showHint")
      p {{ hintMessage }}
    .messages(v-else-if="hasError")
      p {{ errorMessage }}
</template>

<style lang="stylus">
section.webshots-zone
  width: 100%
  height: 100%
  position: absolute

  .webshot-zone-item
    width: 100%
    height: 100%
    position: absolute
    z-index: 10

    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s

  > .messages
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

    padding: 0 10%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    font-size: 3vmin
</style>
