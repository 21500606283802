<script>
import Moment from 'moment-timezone'
import ParseColor from 'parse-color'
import {
  mapGetters
} from 'vuex'

import FooterSlider from './FooterSlider.vue'
import Tickertape from 'components/widgets/Tickertape.vue'
import Clock from 'components/widgets/Clock.vue'
import Weather from 'components/widgets/Weather.vue'

const config = {
  // In vmin
  TICKERTAPE_BASE_FONTSIZE: 3
}

export default {
  name: 'PlaylistFooter',
  components: {
    FooterSlider,
    Tickertape,
    Clock,
    Weather
  },

  props: {
    overlays: {
      type: Object,
      default: () => ({})
    },
    account: {
      type: Object,
      default: () => ({})
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters([
      'currentPlaylist',
      'accountLocale'
    ]),

    previewAccountLocale () {
      if (!this.previewMode) { return {} }
      const locale = {
        timeFormat: this.account.locale_time_format || '12H',
        timezone: this.account.locale_timezone
      }
      if (locale.timezone === 'UTC') {
        locale.timezone = 'UTC'
      } else if (!locale.timezone || locale.timezone === 'local_system_time') {
        locale.timezone = Moment.tz.guess()
      }
      return locale
    },

    localeConfigs () {
      if (this.previewMode) {
        return this.previewAccountLocale
      }
      return this.accountLocale
    },

    dataIsReady () {
      return Boolean(this.previewMode || (!this.previewMode && this.currentPlaylist))
    },

    overlayProps () {
      if (this.previewMode) {
        return this.overlays || {}
      }
      return this.currentPlaylist?.overlay_props || {}
    },

    showClock () {
      if (!this.dataIsReady) { return false }
      return this.overlayProps.overlay_clock || this.currentPlaylist?.overlay_clock || false
    },

    weatherLocation () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.weather_location || this.currentPlaylist?.weather_location || ''
    },

    showWeather () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.weatherLocation && this.weatherLocation.trim().length)
    },

    message () {
      if (!this.dataIsReady) { return '' }
      return this.overlayProps.message || this.currentPlaylist?.message || ''
    },

    showMessage () {
      if (!this.dataIsReady) { return false }
      return Boolean(this.message && this.message.trim().length)
    },

    tickerTapeVisible () {
      if (!this.dataIsReady) { return false }
      return this.overlayProps.tickertape_visible || this.currentPlaylist?.tickertape_visible || false
    },

    showTickertape () {
      if (!this.dataIsReady) { return false }
      // Fallback for existing Tickertapes created before DEV-3259
      if (typeof this.tickerTapeVisible !== 'boolean') {
        return this.hasTickertapeItems
      }
      return this.tickerTapeVisible && this.hasTickertapeItems
    },

    showFooterWithBg () {
      return this.showMessage || this.showTickertape
    },

    tickerTapeItems () {
      if (!this.dataIsReady) { return [] }
      return this.overlayProps.tickertape_items || this.currentPlaylist?.tickertape_items || []
    },

    hasTickertapeItems () {
      return Boolean(this.tickerTapeItems && Array.isArray(this.tickerTapeItems) && this.tickerTapeItems.length)
    },

    tickerTapeFontSize () {
      if (!this.dataIsReady) { return 1 }
      return this.overlayProps.tickertape_font_size || this.currentPlaylist?.tickertape_font_size
    },

    tickertapeFontSize () {
      if (
        !this.tickerTapeFontSize ||
        this.tickerTapeFontSize < 0.5 ||
        this.tickerTapeFontSize > 3.0
      ) {
        return `${config.TICKERTAPE_BASE_FONTSIZE}vmin`
      }
      return `${+(this.tickerTapeFontSize || 1) * config.TICKERTAPE_BASE_FONTSIZE}vmin`
    },

    messageFontStyle () {
      if (!this.showMessage) { return }
      return {
        fontSize: this.tickertapeFontSize,
        ...this.overlayFontColor('message_overlay_font')
      }
    },

    tickerTapeBackground () {
      if (!this.dataIsReady) { return '#2AA4E5' }
      return this.overlayProps.tickertape_background || this.currentPlaylist?.tickertape_background || '#2AA4E5'
    },

    overlayStyle () {
      return {
        weather: this.overlayFontColor('weather_overlay_font'),
        clock: this.overlayFontColor('clock_overlay_font')
      }
    },

    overlayClasses () {
      return {
        weather: this.overlayAlignment('weather_overlay_font'),
        clock: this.overlayAlignment('clock_overlay_font')
      }
    }
  },

  methods: {
    footerBackground (alpha) {
      if (this.showMessage || this.showTickertape) {
        const color = ParseColor(this.tickerTapeBackground || '#2AA4E5')
        if (alpha < 1 && color.rgba && color.rgba[3] < 1) {
          alpha = color.rgba[3]
        }
        return {background: `rgba(${color.rgb[0]}, ${color.rgb[1]}, ${color.rgb[2]}, ${alpha})`}
      }
      return {}
    },

    overlayFontColor (widgetType) {
      if (!this.dataIsReady) { return {} }
      return {
        color: this.overlayProps[widgetType] && this.overlayProps[widgetType].color
      }
    },

    overlayAlignment (widgetType, isMessageShort = true) {
      if (!this.dataIsReady) { return [] }
      const alignment = []
      if (this.overlayProps[widgetType] && isMessageShort) {
        alignment.push(`horz-${this.overlayProps[widgetType].horizontal_alignment}`)
        alignment.push(`vert-${this.overlayProps[widgetType].vertical_alignment}`)
      }
      return alignment
    },

    isMessageShort () {
      if (this.$refs && this.$refs.message) {
        return this.$refs.message.offsetWidth < this.$refs.message.scrollWidth
      }
      return null
    }
  }
}
</script>

<template lang="pug">
  section#playlist-footer

    footer.overlay-footer(v-if="showFooterWithBg", :style="footerBackground(0.6)")
      .footer-message(v-if="showMessage"
                      ref="message"
                      :class="[{'has-widget': showClock || showWeather}, overlayAlignment('message_overlay_font'), isMessageShort]"
                      :style="messageFontStyle") {{ message }}

      tickertape(v-else-if="showTickertape"
                 :list="tickerTapeItems || []"
                 :background="footerBackground(1).background"
                 :font-size="tickertapeFontSize")

      .footer-right(v-if="showClock || showWeather", :style="footerBackground(1)")
        footer-slider(:show-clock="showClock"
                      :weather-location="weatherLocation"
                      :with-tickertape="showFooterWithBg"
                      :css-classes="overlayClasses"
                      :custom-style="overlayStyle")

    footer.overlay-footer.no-tickertape(v-if="!showFooterWithBg")
      .footer-left(v-if="showClock && showWeather")
        clock(show-date :hours="localeConfigs.timeFormat", :timezone="localeConfigs.timezone" :class="overlayAlignment('clock_overlay_font')" :style="overlayFontColor('clock_overlay_font')")
      .footer-right
        weather(v-if="showWeather && weatherLocation", :location="weatherLocation" :class="overlayAlignment('weather_overlay_font')" :style="overlayFontColor('weather_overlay_font')")
        clock(v-if="!showWeather && showClock" show-date :hours="localeConfigs.timeFormat", :timezone="localeConfigs.timezone" :class="overlayAlignment('clock_overlay_font')" :style="overlayFontColor('clock_overlay_font')")
</template>

<style lang="stylus">
  @import '../../style/mixins.styl'

  section#playlist-footer
    .overlay-footer
      display: flex
      flex-flow: row nowrap
      align-items: center
      position: absolute
      z-index: 5001
      bottom: 0
      left: 0
      right: 0
      height: 10vmin
      padding: 0 4vmin 2vmin 4vmin
      background: transparent
      transition: background .5s

      .footer-message
        flex: 1 1 0.00001px
        text-align: center
        margin-bottom: -2vmin
        ellipsis()
        &.has-widget
          margin-right: 20vmin
        &.horz-left
          text-align: left
        &.horz-right
          text-align: right
        &.horz-center
          text-align: center

      .footer-right
        position: absolute
        z-index: 5010
        width: 20vmin
        top: 0
        bottom: 0
        right: 0
        background: transparent
        transition: background .5s
        .footer-slider
          .simple-clock
            .date-wrapper
              font-size: 1.5vmin
            .time-wrapper
              font-size: 2vmin
          .simple-weather
            .location-wrapper
              font-size: 1.5vmin
            .temperature-wrapper
              font-size: 2vmin

      &.no-tickertape
        .footer-left, .footer-right
          position: absolute
          z-index: 5010
          top: 0
          bottom: 0
          // background: rgba(0, 0, 0, 0.6)
          width: auto
          text-shadow: 0.08em 0.08em 0.15em rgba(0, 0, 0, 1)
        .footer-left
          left: 0
        .footer-right
          right: 0
        .simple-clock
          flex-flow: row
          height: 100%
          margin: 0 4vmin
          .date-wrapper
            font-size: 2vmin
            padding: 0 1vmin 0 0
            opacity: 0.6
            line-height: unset
          .time-wrapper
            font-size: 2vmin
            padding: 0
            opacity: 1
            line-height: unset
        .simple-weather
          flex-flow: row
          height: 100%
          margin: 0 4vmin
          .location-wrapper
            font-size: 2vmin
            padding: 0 1vmin 0 0
            opacity: 0.6
            line-height: unset
          .temperature-wrapper
            font-size: 2vmin
            padding: 0
            opacity: 1
            line-height: unset
      .footer-tickertape
        .section-title
          min-width: 12vmin
</style>
