<script>
import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import YouTube from 'services/youtube.js'
import Pages from 'services/pages.js'
import RSS from 'services/rss.js'
import Metrics from 'services/metrics.js'
import CommonStyling from 'services/common-styling.js'
import Environment from 'services/environment'
import Log from 'services/log.js'

import DeprecatedPageItem from 'components/common/DeprecatedPageItem.vue'
import PreviewNotAvailable from 'components/common/PreviewNotAvailable.vue'
import AppBackground from 'components/common/AppBackground.vue'

import AdvertisingItem from 'components/items/AdvertisingItem.vue'
import AmadeusItem from 'components/items/AmadeusItem.vue'
import BoardV2 from 'components/items/BoardV2.vue'
import CalendarItem from 'components/items/CalendarItem.vue'
import ClockItem from 'components/items/ClockItem.vue'
import ContactItem from 'components/items/ContactItem.vue'
import CountdownItem from 'components/items/CountdownItem.vue'
import CurrencyItem from 'components/items/CurrencyItem.vue'
import Dashboard from 'components/items/Dashboard.vue'
import DriveFolder from 'components/items/DriveFolder.vue'
import EventsItem from 'components/items/EventsItem.vue'
import GoogleSlidesItem from 'components/items/GSlidesItem.vue'
import GrafanaItem from 'components/items/GrafanaItem.vue'
import HtmlItem from 'components/items/HtmlItem.vue'
import ImageItem from 'components/items/ImageItem.vue'
import InfoBarItem from 'components/items/InfoBarItem.vue'
import LiveVideo from 'components/items/LiveVideo.vue'
import MediaFolder from 'components/items/MediaFolder.vue'
import MediaZone from 'components/items/MediaZone.vue'
import MenuItem from 'components/items/MenuItem.vue'
import MetricsApps from 'components/items/MetricsApps.vue'
import MustHaveMenus from 'components/items/MustHaveMenus.vue'
import NavigationItem from 'components/items/NavigationItem.vue'
import NoticeItem from 'components/items/NoticeItem.vue'
import PlaceExchange from 'components/items/PlaceExchange.vue'
import ProfileItem from 'components/items/ProfileItem.vue'
import QuoteItem from 'components/items/QuoteItem.vue'
import RssItem from 'components/items/RSSItem.vue'
import SimpleGoogleApps from 'components/items/SimpleGoogleApps.vue'
import SlackItem from 'components/items/SlackItem.vue'
import SlidesItem from 'components/items/SlidesItem.vue'
import StocksItem from 'components/items/StocksItem.vue'
import TeemFinderItem from 'components/items/TeemFinderItem.vue'
import TitanMenuItem from 'components/items/TitanMenuItem.vue'
import TwitchItem from 'components/items/TwitchItem.vue'
import VideoItem from 'components/items/VideoItem.vue'
import VimeoItem from 'components/items/VimeoItem.vue'
import WayfindingItem from 'components/items/WayfindingItem.vue'
import WeatherItem from 'components/items/WeatherItem.vue'
import WebApp from 'components/items/WebApp.vue'
import Webpage from 'components/items/Webpage.vue'
import Webshots from 'components/items/Webshots.vue'
import WebVideo from 'components/items/WebVideo.vue'
import WindyTv from 'components/items/WindyTV.vue'
import YoutubeItem from 'components/items/YoutubeItem.vue'

// Fallback for DEV-721
const FONTS_MAP = CommonStyling.FONTS_MAP

// Fallback for DEV-720
const BG_COLOR_MAP = CommonStyling.BG_COLOR_MAP

// Add some margin for boxes in the modernized apps when viewing in App Preview mode (js_user embedded)
// So that the boxes won't touch the iframe's edge
const PREVIEW_MODE_BOX_MARGIN = '16.6666666vmin'

// Overall zone configs for zone paddings/margins (DEV-1099)
const ZONE_CONFIGS = {
  // Set some padding when embedding in non-fullscreen zones
  // To ensure the boxes doesn't touch the edge
  BOX_DEFAULT_MARGIN: '0'
}

// Completed deprecated and disabled content type.
// Existing items with the following type(s) will be seeing "deprecated" notice instead.
const DISABLE_TYPE = [
  // Deprecated apps
  'reuters-news-feed',
  'raw-story-feed',
  'seattle-times-feed',
  'facebook',
  'instagram',
  'twitter',
  'socialwall',
  'infotv',
  'news',
  'smartroom',

  // Old apps that never get migrated from js_custom_apps
  'bitbucket',
  'dropbox',
  's3',
  'quotes',

  // Google Drive integration gets removed
  'googledrive_folder',

  // The other deprecated content types
  'git_media',
  'dashboard'
]

const PREVIEW_NOT_AVAILABLE = [
  'smartroom'
]

const AMADEUS_ZONE_PADDINGS = '5vmin'

export default {
  name: 'PageItem',
  components: {
    DeprecatedPageItem,
    PreviewNotAvailable,
    AppBackground,

    AdvertisingItem,
    AmadeusItem,
    BoardV2,
    CalendarItem,
    ClockItem,
    ContactItem,
    CountdownItem,
    CurrencyItem,
    WebApp,
    Dashboard,
    EventsItem,
    GoogleSlidesItem,
    GrafanaItem,
    HtmlItem,
    ImageItem,
    InfoBarItem,
    LiveVideo,
    MediaFolder,
    MediaZone,
    MenuItem,
    MetricsApps,
    MustHaveMenus,
    NavigationItem,
    NoticeItem,
    DriveFolder,
    PlaceExchange,
    ProfileItem,
    QuoteItem,
    RssItem,
    SlackItem,
    SlidesItem,
    SimpleGoogleApps,
    StocksItem,
    TeemFinderItem,
    TitanMenuItem,
    TwitchItem,
    VideoItem,
    VimeoItem,
    WayfindingItem,
    WeatherItem,
    Webpage,
    Webshots,
    WebVideo,
    WindyTv,
    YoutubeItem
  },

  // Pass the zone configs to descendants components
  provide: { ZONE_CONFIGS },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    zonePaddings: {
      type: String,
      default: ''
    },
    itemPreviewMode: {
      type: Boolean,
      default: false
    },
    // In Overlay Container triggered by Button app
    overlayMode: {
      type: Boolean,
      default: false
    },
    freeFromItemPreview: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      font: '',
      fontScale: 1.0,

      hideBox: false,

      envVarNotAvailable: false,

      // For generic App Background
      bgType: 'color',

      whiteText: true,

      bgColor: '',
      gradientOverlay: true,

      bgMedia: undefined,
      stockMedia: undefined,
      unsplashImage: undefined,
      darkOverlay: false,
      zoomEffect: false,
      // EOF for generic App Background
      amadeusZonePaddings: AMADEUS_ZONE_PADDINGS
    }
  },

  computed: {
    ...mapGetters([
      'playlistThemeIsSet',
      // TEMP DEBUG
      'currentPageName'
    ]),

    // App Preview, Playlist Page Editor, iFrame Embedding
    nonDeviceMode () {
      return Boolean(this.freeFromItemPreview || this.itemPreviewMode || Environment.previewMode || Environment.pageEditMode || Environment.iframeEmbedMode)
    },

    useAppBg () {
      if (this.playlistThemeIsSet || this.isDisabledType) {
        return false
      }
      return !this.item.has_page_bg && CommonStyling.useGenericBg(this.item.type)
    },

    showTextShadow () {
      if (!this.useAppBg) {
        return Boolean(this.item.show_text_shadow)
      }
      return CommonStyling.showTextShadow(this.bgType, this.whiteText)
    },

    fontClass () {
      if (this.playlistThemeIsSet) {
        // Playlist Font is set, skip app-level font settings
        return
      }
      return CommonStyling.getfontClass(this.font)
    },

    // TEMP DEBUG
    gridZoneName () {
      return (this.item && this.item.grid_area) || 'main'
    },

    getZonePaddings () {
      return (this.itemPreviewMode || this.overlayMode) ? PREVIEW_MODE_BOX_MARGIN : this.zonePaddings
    },

    hideBoxOutline () {
      return !(this.itemPreviewMode || this.freeFromItemPreview || this.overlayMode) && (!this.zonePaddings || this.zonePaddings === '0')
    },

    isDisabledType () {
      return DISABLE_TYPE.indexOf(this.item.type) >= 0
    },

    previewNotAvailable () {
      return this.nonDeviceMode && PREVIEW_NOT_AVAILABLE.indexOf(this.item.type) >= 0
    }
  },

  watch: {
    'item.url' () {
      this.renderAppCommonStyles()
    },

    'item.hide_box_in_page' () {
      this.renderAppCommonStyles()
    },

    'item.dark_text' () {
      this.renderAppCommonStyles()
    },

    fontClass (newValue) {
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    }
  },

  mounted () {
    this.envVarNotAvailable = false
    this.renderAppCommonStyles()
  },

  methods: {
    isSimpleGoogleApp (item) {
      return item && Pages.isSimpleGoogleApp(item.type)
    },
    isYoutubeApp (item) {
      return item && YouTube.isYoutubeApp(item.type)
    },
    isRSSApp (item) {
      return item && (item.type === 'rss' || RSS.isRSSFeedApp(item.type))
    },
    isMetricsApp (item) {
      return item && Metrics.isMetricsApp(item.type)
    },
    finished (force = false) {
      // [#DEV-2434][#DEV-1256][#DEV-1080]
      // Force advance to next page when error occurs, or no valid items found
      // Call `this.$emit('finished', true)` from the individual app component when needed
      // E.g. Twitter, Instagram, Slides
      if (force) {
        // TEMP DEBUG
        if (this.item.type === 'media_folder') {
          Log.debug('player', `Page Item Finished: Media Folder on page "${this.currentPageName}" zone "${this.gridZoneName}" called Force-Advanced "finished"`, 'DBG_PAGEITEMFINISHED')
        }

        this.$emit('finished')
      // Otherwise, forward the `@finished` only when the page duration is not set (null or 0) [DEV-1007]
      } else if (this.item && !this.item.page_duration) {
        // TEMP DEBUG
        if (this.item.type === 'media_folder') {
          Log.debug('player', `Page Item Finished: Media Folder on page "${this.currentPageName}" zone "${this.gridZoneName}" with no page duration called "finished"`, 'DBG_PAGEITEMFINISHED2')
        }
        this.$emit('finished')
      } else {
        if (this.item.type === 'media_folder') {
          Log.debug('player', `Page Item Finished: Ignore "finished" event from Media Folder on page "${this.currentPageName}" zone "${this.gridZoneName}" (forced: ${force}, pageDuration: ${this.item && this.item.page_duration})`, 'DBG_PAGEITEMFINISHED3')
        }
      }
    },
    loaded () {
      this.$emit('loaded')
    },
    sendDuration (duration) {
      this.$emit('duration', duration)
    },
    singleItemFinished () {
      this.$emit('single-item-finished')
    },
    checkEnvVar (hasEnvVar) {
      if (this.nonDeviceMode) {
        this.envVarNotAvailable = hasEnvVar
        return
      }
      this.envVarNotAvailable = false
    },
    renderAppCommonStyles () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      if (this.item.dark_text) {
        // Text mode override from the Page level
        this.whiteText = false
      } else if (this.playlistThemeIsSet) {
        // Force White Text when a Playlist Theme is set
        this.whiteText = true
      } else {
        this.whiteText = (options.whiteText === true || options.whiteText === 'true')
      }

      // Hide Box when:
      // - The "Hide Content Box" toggle is ON in playlist page settings (DEV-1183)
      // - The "Hide Background Box" toggle is ON in app properties
      let hideBox = false
      if (
        this.item.hide_box_in_page ||
        // Failsafe for Notice app created before DEV-1259
        (this.item.type === 'notice' && (options.hideBox === null || options.hideBox === undefined)) ||
        (options.hideBox === true || options.hideBox === 'true')
      ) {
        hideBox = true
      }
      this.hideBox = hideBox

      // Fallback for DEV-720 and DEV-721 updates
      const theme = options.theme || 'default'

      // Somehow, the qs.parse turn "+" into " " (space) after upgrading core modules along with Parcel 2.x
      // E.g. "Bebas+Neue" becomes "Bebas Neue"
      if (options.font && options.font.length) {
        options.font = options.font.replace(/\s/g, '+')
      }

      // Split "Fonts" from "Theme" [DEV-721]
      this.font = options.font || FONTS_MAP[theme] || ''

      // Font Size Support [DEV-1418] [DEV-1762]
      this.fontScale = +options.fontScale || 1.0

      if (!this.useAppBg) { return }

      let bgType = options.bgType || 'color'

      // Update option for DEV-720
      if (bgType === 'gradient') {
        // 'gradient' (old) -> 'color' (new)
        bgType = 'color'
      } else if (bgType === 'image') {
        // 'image' (old) -> 'custom' (new)
        bgType = 'custom'
      } else if (bgType === 'none') {
        bgType = 'color'
      }
      this.bgType = bgType

      // Update Theme Options [DEV-720]
      this.bgColor = options.bgColor || BG_COLOR_MAP[theme] || ''

      this.stockMedia = options.stockMedia
      this.unsplashImage = options.unsplashImage
      this.bgMedia = options.bgMedia

      // Fallback for old bgMedia formats in TitanMenu, etc.
      if (options.bgMedia && !options.bgType) {
        this.bgType = 'custom'
      }

      this.zoomEffect = (options.zoomEffect === true || options.zoomEffect === 'true')
      this.gradientOverlay = (options.gradientOverlay === true || options.gradientOverlay === 'true')
      this.darkOverlay = (options.darkOverlay === true || options.darkOverlay === 'true')
    }
  }
}
</script>

<template lang="pug">
.page-item
  deprecated-page-item(v-if="isDisabledType", :type="item.type" @finished="finished")
  preview-not-available(v-else-if="previewNotAvailable")
  advertising-item(v-else-if="item.type === 'advertising'" @finished="finished" @loaded="loaded" :active="active" :item="item")

  media-folder(v-else-if="item.type === 'media_folder'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  drive-folder(v-else-if="item.type === 'onedrive_folder'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  media-zone(v-else-if="item.type === 'media'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  image-item(v-else-if="item.type === 'image'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  slides-item(v-else-if="item.type === 'slides'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  video-item(v-else-if="item.type === 'video'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  webpage(v-else-if="item.type === 'url'" @finished="finished" @loaded="loaded" @has-env-var="checkEnvVar" :active="active" :item="item")
  must-have-menus(v-else-if="item.type === 'musthavemenus'" @finished="finished" @loaded="loaded" @has-env-var="checkEnvVar" :active="active" :item="item")
  web-app(v-else-if="item.type === 'webapp' || item.type === 'custom_app'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  board-v2(v-else-if="item.type === 'board_v2' || item.type === 'board'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  teem-finder-item(v-else-if="item.type === 'teemfinder'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  titan-menu-item(v-else-if="item.type === 'titanmenu'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText")
  weather-item(v-else-if="item.type === 'weather'" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  slack-item(v-else-if="item.type === 'slack'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  google-slides-item(v-else-if="item.type === 'g-slides'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  windy-tv(v-else-if="item.type === 'windytv'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  vimeo-item(v-else-if="item.type === 'vimeo'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  youtube-item(v-else-if="isYoutubeApp(item)" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  currency-item(v-else-if="item.type === 'currency'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  stocks-item(v-else-if="item.type === 'stocks'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  clock-item(v-else-if="item.type === 'clock'" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :font-scale="fontScale" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  rss-item(v-else-if="isRSSApp(item)" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item")
  notice-item(v-else-if="item.type === 'notice'" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  amadeus-item(v-else-if="item.type === 'amadeus'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :hide-box-outline="hideBoxOutline" :zone-paddings="amadeusZonePaddings")
  events-item(v-else-if="item.type === 'events'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  contact-item(v-else-if="item.type === 'contact'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  profile-item(v-else-if="item.type === 'profile'" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  countdown-item(v-else-if="item.type === 'countdown'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  quote-item(v-else-if="item.type === 'quote'" @finished="finished" @loaded="loaded" @single-item-finished="singleItemFinished" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  menu-item(v-else-if="item.type === 'menu'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  grafana-item(v-else-if="item.type === 'grafana'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  html-item(v-else-if="item.type === 'html'" @finished="finished" @loaded="loaded" :active="active" :item="item" is-legacy-html)
  wayfinding-item(v-else-if="item.type === 'wayfinding'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  calendar-item(v-else-if="item.type === 'calendar'" @finished="finished" @loaded="loaded" :active="active" :item="item" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  navigation-item(v-else-if="item.type === 'button'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  web-video(v-else-if="item.type === 'web-video'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  info-bar-item(v-else-if="item.type === 'infobar'" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  metrics-apps(v-else-if="isMetricsApp(item)" @finished="finished" @loaded="loaded" :active="active" :item="item" :font-class="fontClass" :font-scale="fontScale" :show-text-shadow="showTextShadow" :white-text="whiteText" :hide-box="hideBox" :zone-paddings="getZonePaddings" :hide-box-outline="hideBoxOutline")
  dashboard(v-else-if="item.type === 'dashboard'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  webshots(v-else-if="item.type === 'webshot'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  live-video(v-else-if="item.type === 'live-video'" @finished="finished" @loaded="loaded" :active="active" :item="item" @duration="sendDuration")
  twitch-item(v-else-if="item.type === 'twitch'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  place-exchange(v-else-if="item.type === 'place-exchange'" @finished="finished" @loaded="loaded" :active="active" :item="item")
  simple-google-apps(v-else-if="isSimpleGoogleApp(item)" @finished="finished" @loaded="loaded" :active="active" :item="item")

  //- App Generic Background
  app-background(v-if="useAppBg"
                 :type="bgType"
                 :bg-color="bgColor"
                 :bg-media="bgMedia"
                 :stock-media="stockMedia"
                 :unsplash-image="unsplashImage"
                 :gradient-overlay="gradientOverlay"
                 :zoom-effect="zoomEffect"
                 :duration="item.page_duration || 0"
                 :dark-overlay="darkOverlay")

  //- Preview Not Available for Apps with Env Vars
  .preview-not-available(v-if="envVarNotAvailable")
    p Unable to preview an App with Environment Var at this moment
    p Please view with a paired or provisioned device
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

.page-item
  position: relative
  overflow: hidden

  // NOTE: Make sure the apps using this generic background
  // Have their `z-index` > 0
  .app-generic-background
    z-index: 0

  // Predefined Font Sets
  predefinedFonts()

  .preview-not-available
    z-index: 100
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    padding: 1em 1.5em
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    text-align: center
    background: -black(0.5)
    color: #fff

    p
      margin: 0
      padding: 0.3em 0

@import '../../style/wysiwyg.styl'
@import '../../style/app-commons.styl'
</style>
