<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import NavigationButton from './navigation/Button.vue'
import InteractionZone from 'components/common/InteractionZone.vue'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,

  // For buttons with `fixedBtnWidth: true`
  MAX_BTN_CHARS: 32
}

export default {
  name: 'NavigationItem',

  components: {
    NavigationButton,
    InteractionZone
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      items: [],
      rounded: false,
      buttonSize: '',
      fixedBtnWidth: false,
      overlayMargin: '',

      autoTimeout: false,
      timeout: null,

      hAlign: 'left',
      vAlign: 'justify'
    }
  },

  computed: {
    ...mapGetters([
      'needsInteractiveOverlay'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    validItems () {
      return (this.items || []).filter(item => {
        if (!item.text || !item.targetType) { return false }
        if (item.useImage === 'true' && (!item.buttonImage || !item.buttonImage.length)) { return false }
        if (['media', 'app', 'folder', 'webapp'].includes(item.targetType)) {
          return Boolean(item.targetId && item.targetId.length)
        }
        return false
      })
    },

    hasMixedItems () {
      if (!this.validItems.length) { return false }
      return this.validItems.some(item => item.useImage === 'true') && this.validItems.some(item => item.useImage !== 'true')
    },

    autoClose () {
      return this.autoTimeout && this.timeout && this.timeout > 0
    },

    timeoutAfter () {
      if (!this.autoTimeout || !this.timeout) { return -1 }
      return Math.max(4, Math.min(86400, +this.timeout || 0))
    },

    maxCharsCount () {
      if (!this.fixedBtnWidth || !this.validItems || !this.validItems.length) { return }
      let longestBtnChars = 0
      this.validItems.forEach(btn => {
        longestBtnChars = Math.max(longestBtnChars, (btn.text || '').length)
      })
      longestBtnChars = Math.min(config.MAX_BTN_CHARS, longestBtnChars)
      return longestBtnChars
    }
  },

  watch: {
    'item.url' () {
      this.render()
    }
  },

  mounted () {
    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  methods: {
    render () {
      if (!this.item || !this.item.url) { return }

      const options = qs.parse(decodeURIComponent(this.item.url))

      this.items = options.items || []

      this.rounded = (options.rounded === 'true' || options.rounded === true)
      this.fixedBtnWidth = (options.fixedBtnWidth === 'true' || options.fixedBtnWidth === true)

      this.buttonSize = options.buttonSize || ''
      this.overlayMargin = options.overlayMargin || ''

      this.autoTimeout = (options.autoTimeout === 'true' || options.autoTimeout === true)
      this.timeout = options.timeout || null

      this.hAlign = options.hAlign || 'left'
      this.vAlign = options.vAlign || 'justify'
    }
  }
}
</script>

<template lang="pug">
section.navigation-item(:style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  interaction-zone(v-if="needsInteractiveOverlay"
                   :grid-name="item.grid_area || 'main'"
                   :grid-type="item.type")

  .button-main-container.app-context-block(:class="[fontClass, {'uninitialized': !baseFontSize || !validItems.length, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
                                           :style="boxMarginStyle")
    .app-context-section.primary(:class="[horzClass, vertClass]")
      .button-inner-wrapper(:class="[horzClass, vertClass, {'is-portrait': isPortrait, 'landscape': !isPortrait, 'mixed-buttons': hasMixedItems}]")
        navigation-button(v-for="(button, bIndex) in validItems", :key="bIndex"
                          :item="button"
                          :auto-close="autoClose"
                          :timeout-after="timeoutAfter"
                          :rounded="rounded"
                          :button-size="buttonSize"
                          :fixed-btn-width="maxCharsCount"
                          :overlay-margin="overlayMargin")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.navigation-item
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  > .interaction-zone
    z-index: 0

  .button-main-container
    position: relative
    z-index: 5
    flex: 1 1 0.00001px
    // Passdown pointer events to interaction-zone
    pointer-events: none

  .app-context-section
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    padding: 0 1em
    contentBoxAlignments()

  .button-inner-wrapper
    overflow: hidden
    display: flex
    &.landscape
      flex-flow: row wrap
      justify-content: flex-start
      button
        margin: 0.5em 0.75em
      // Fixes horizontal alignment on landscape layout
      &.horz-center
        justify-content: center
      &.horz-right
        justify-content: flex-end
      &.horz-justify
        justify-content: space-between

      // Fixes the issue that the text button tries to fill the same height as the image buttons.
      &.mixed-buttons
        button
          align-self: center
        &.vert-top
          button
            align-self: flex-start
        &.vert-bottom
          button
            align-self: flex-end

    &.is-portrait
      max-width: 100%
      flex-flow: column wrap
      // Fixes "all buttons with the same width" issue
      align-items: inherit
      button
        margin: 0.75em 0
</style>
