<!-- Responsive Message Box -->
<!-- Scale font size to fill the container responsively -->
<!-- Will perform scaling UP/DOWN base on require -->

<script>
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import CommonStyling from 'services/common-styling.js'

const config = {
  // In px
  BASE_FONT_SIZE: 16,
  // In em
  LINE_HEIGHT: 1.3,
  // In em
  MARGIN_VERT: 1,

  MULTIPLIER: 1.1,
  DIVIDER: 0.9
}

export default {
  name: 'ResponsiveMarkupTextBlock',

  props: {
    text: {
      type: String,
      default: ''
    },
    baseFontSize: {
      type: Number,
      default: +config.BASE_FONT_SIZE
    },
    fontClass: {
      type: String,
      default: ''
    },
    marginVert: {
      type: Number,
      default: +config.MARGIN_VERT
    }
  },

  data () {
    return {
      fontSize: this.baseFontSize || +config.BASE_FONT_SIZE,
      scalingDirection: undefined,

      // Failsafe for "Minimum Font Size" on Chrome
      lastBoxSize: {},

      resizing: true,
      adjusting: false,

      debounceTimer: undefined,
      measureTimer: undefined
    }
  },

  computed: {
    fontSizeStyle () {
      if (!this.text || !this.text.length) { return }
      return {
        fontSize: `${this.fontSize}px`
      }
    },

    skipCheckSize () {
      return Boolean(!this.text || !this.text.length)
    }
  },

  watch: {
    text (newVal) {
      if (newVal && newVal.length) {
        this.debounceCheckSize()
      }
    },
    fontClass (newValue) {
      if (!this.skipCheckSize) {
        this.debounceCheckSize()
      }
      if (newValue) {
        CommonStyling.loadExtendFont(newValue)
      }
    },
    baseFontSize () {
      if (!this.skipCheckSize) {
        this.debounceCheckSize(null, true)
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.measureTimer)

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)

    this.fontSize = +(this.baseFontSize || config.BASE_FONT_SIZE)

    this.debounceCheckSize()
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }
    clearTimeout(this.debounceTimer)
    clearTimeout(this.measureTimer)
  },

  methods: {
    debounceCheckSize (timeout, updateBaseFontSize) {
      if (this.skipCheckSize) { return }
      this.resizing = true

      // Update fontSize with the latest baseFontSize to save some time
      if (updateBaseFontSize) {
        this.fontSize = +(this.baseFontSize || config.BASE_FONT_SIZE)
      }

      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        if (this.adjusting) { return }
        this.adjustFontSize()
      }, timeout || 300)
    },

    adjustFontSize () {
      if (!this.$el || !this.$refs || !this.$refs.box || this.skipCheckSize) {
        clearTimeout(this.measureTimer)
        this.scalingDirection = undefined
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
        return
      }

      this.resizing = true
      this.adjusting = true

      const container = this.$el
      const containerMetrics = container.getBoundingClientRect()
      // Round values to integer to add some tolerance
      const containerHeight = Math.ceil(containerMetrics.height)

      const box = this.$refs.box

      // When hitting the "Minimum Font Size" boundary on Chrome, stop scaling
      if (this.scalingDirection === 'down' && this.lastBoxSize && this.lastBoxSize.h === box.scrollHeight && this.lastBoxSize.w === box.scrollWidth) {
        clearTimeout(this.measureTimer)
        this.scalingDirection = undefined
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
        return
      }

      const titleBox = this.$refs.title
      const exactHeight = titleBox ? containerHeight - titleBox.offsetHeight : containerHeight

      this.lastBoxSize = {
        h: box.scrollHeight,
        w: box.scrollWidth
      }

      // [DEV-1729] Remove containerWidth check
      // Because the scrollWidth keep the same after font scale down causing scale loop until it hit the font size limit, adding break-word css rule to make sure long word doesn't get clipped
      if (box.scrollHeight > exactHeight) {
        this.scalingDirection = 'down'
        this.fontSize *= config.DIVIDER
        clearTimeout(this.measureTimer)
        this.measureTimer = setTimeout(() => {
          clearTimeout(this.measureTimer)
          this.adjustFontSize()
        }, 50)
      } else if (box.offsetHeight < exactHeight - (this.fontSize * this.marginVert)) {
        if (this.scalingDirection === 'down') {
          // Prevent infinite loop in some cases
          // like reaching the "Minimum Font Size" limit in Chrome
          clearTimeout(this.measureTimer)
          this.scalingDirection = undefined
          this.resizing = false
          this.adjusting = false
          this.lastBoxSize = {}
          return
        }
        this.scalingDirection = 'up'
        this.fontSize *= config.MULTIPLIER
        clearTimeout(this.measureTimer)
        this.measureTimer = setTimeout(() => {
          clearTimeout(this.measureTimer)
          this.adjustFontSize()
        }, 50)
      } else {
        clearTimeout(this.measureTimer)
        this.scalingDirection = undefined
        this.resizing = false
        this.adjusting = false
        this.lastBoxSize = {}
      }
    }
  }
}
</script>

<template lang="pug">
.responsive-message-box.wysiwyg-block
  .resize-sensor(ref="sensor")
  .title-box(ref="title" v-if="$slots.default", :style="fontSizeStyle", :class="[fontClass, {visible: !resizing}]")
    slot
  .message-box(ref="box" v-html="text || ''", :style="fontSizeStyle", :class="[fontClass, {visible: !resizing}]")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

.responsive-message-box.wysiwyg-block
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow: hidden

  // Alignments
  contentBoxAlignments()

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .title-box
    width: 100%
    opacity: 0
    transition: opacity .3s
    line-height: 130%
    &:empty
      display: none

    &.visible
      opacity: 1

  .message-box
    line-height: 130%
    position: relative
    word-break: break-word
    z-index: 2
    opacity: 0
    transition: opacity .3s

    > :last-child
      margin-bottom: 0

    // Prevent flickering when font-size is unscaled
    &.visible
      opacity: 1
</style>
