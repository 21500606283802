<script>
import Velocity from 'velocity-animate'
import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'

const config = {
  // in ms
  PAGE_DELAY: 500,
  // in ms
  ITEM_STAGGER: 100,
  DEFAULT_STRIPE_COLOR: '#000',
  DEFAULT_HIGHLIGHT_COLOR: '#fffb4b'
}

export default {
  name: 'MetricsLeaderboardItem',

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false },
    sortByValue: { type: Boolean, default: false },
    isPaginated: { type: Boolean, default: false },

    // In-app paginate interval
    interval: { type: Number, default: 10 }
  },

  data () {
    return {
      debounceTimer: undefined,

      itemsToShow: 0,
      chunks: [],
      currentChunkIndex: 0,
      currentChunk: [],

      expireTimer: undefined,
      delayTimer: undefined,
      animationTimers: {},

      // For height calculation
      dummyData: [
        {
          name: 'Alpha',
          description: 'AlphaGo',
          points: 150
        },
        {
          name: 'Beta',
          description: 'Beta Cat',
          points: 145
        }
      ]
    }
  },

  computed: {
    leaders () {
      if (!this.item || !this.item.leaders || !this.item.leaders.length) { return [] }

      let leaders = JSON.parse(JSON.stringify(this.item.leaders))
      leaders = leaders.map(function (l, i) {
        l.index = i
        return l
      })
      leaders.sort(function (a, b) {
        const diff = (b.points || 0) - (a.points || 0)
        if (diff !== 0) {
          return diff
        }
        return (a.index - b.index)
      })
      let currentRank = 1
      let currentPoints = 0
      leaders.forEach(function (l, i) {
        if (i === 0 || l.points === currentPoints) {
          l.rank = currentRank
        } else {
          l.rank = i + 1
          currentRank = l.rank
        }
        currentPoints = l.points
      })
      return leaders
    },

    highLightStyle () {
      return {
        color: this.item.stripe_color || config.DEFAULT_STRIPE_COLOR,
        background: this.item.highlight_color || config.DEFAULT_HIGHLIGHT_COLOR
      }
    },

    stripeStyle () {
      return {
        color: this.item.highlight_color || config.DEFAULT_HIGHLIGHT_COLOR,
        background: this.item.stripe_color || config.DEFAULT_STRIPE_COLOR
      }
    }
  },

  watch: {
    leaders: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.debounceCheckSize()
        })
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)

    this.$nextTick(() => {
      this.debounceCheckSize()
    })

    AddResizeListener(this.$refs.sensor, this.debounceCheckSize)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.delayTimer)
    clearTimeout(this.expireTimer)

    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.debounceCheckSize)
    }

    Object.keys(this.animationTimers).forEach((timerKey) => {
      clearTimeout(this.animationTimers[timerKey])
      this.animationTimers[timerKey] = null
      delete this.animationTimers[timerKey]
    })
    this.animationTimers = null

    const lis = this.$el.querySelectorAll('li')
    Array.prototype.map.call(lis, (li) => {
      if (Velocity && Velocity.Utilities && Velocity.Utilities.removeData) {
        Velocity.Utilities.removeData(li)
      }
    })
  },

  methods: {
    debounceCheckSize () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.resizeHandler()
      }, 200)
    },

    resizeHandler () {
      if (!this.$el || !this.$refs.sensor || !this.leaders || !this.leaders.length) {
        return
      }
      this.divideChunks()
    },

    divideChunks () {
      if (!this.$refs || !this.$refs.sensor || !this.$el) { return }

      const dummyItems = this.$el.querySelectorAll('.dummy')
      let cIndex = 0

      let itemHeight
      let padding
      let availableHeight

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!dummyItems || !dummyItems[0] || !dummyItems[1] || !this.$refs || !this.$refs.sensor) {
          FastDom.clear(measure)
          return
        }

        itemHeight = dummyItems[0].offsetHeight
        padding = dummyItems[1].offsetHeight - itemHeight
        availableHeight = this.$refs.sensor.offsetHeight

        const mutate = FastDom.mutate(() => {
          this.chunks = []
          this.itemsToShow = ~~((availableHeight + padding) / (itemHeight + padding))

          for (let i = 0; i < this.leaders.length; i++) {
            if (!this.chunks[cIndex]) {
              this.chunks[cIndex] = []
            }
            this.chunks[cIndex].push(this.leaders[i])
            if ((i + 1) % this.itemsToShow === 0) {
              cIndex++
            }
          }

          // Skip animation when there's only one page or `paginate` is set to 'none' or this widget has multiple items.
          if (this.chunks.length <= 1 || this.item.paginate === 'none' || this.isPaginated) {
            clearTimeout(this.delayTimer)
            clearTimeout(this.expireTimer)
            this.currentChunk = this.chunks[0]
          } else {
            this.prepareNext(300)
          }
          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    prepareNext (timeout) {
      clearTimeout(this.delayTimer)
      clearTimeout(this.expireTimer)

      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.currentChunk = this.chunks[this.currentChunkIndex]
        this.expireTimer = setTimeout(() => {
          clearTimeout(this.expireTimer)
          this.playNext()
        }, this.interval * 1000)
      }, timeout)
    },

    playNext () {
      this.currentChunk = []
      this.currentChunkIndex = (this.currentChunkIndex + 1) % this.chunks.length
      this.prepareNext((this.itemsToShow * config.ITEM_STAGGER) + config.PAGE_DELAY)
    },

    beforeEnter (el) {
      Velocity(
        el,
        {opacity: 0, translateX: '-120%'},
        {duration: 0}
      )
    },

    enter (el, done) {
      const delay = el.dataset.index * config.ITEM_STAGGER
      const key = this.genTimerKey('enter', el.dataset.index)
      clearTimeout(this.animationTimers[key])
      this.animationTimers[key] = setTimeout(() => {
        clearTimeout(this.animationTimers[key])
        this.animationTimers[key] = null
        Velocity(
          el,
          {opacity: 1, translateX: 0},
          {
            complete: () => {
              done()
              if (Velocity && Velocity.Utilities && Velocity.Utilities.removeData) {
                Velocity.Utilities.removeData(el)
              }
            }
          }
        )
        delete this.animationTimers[key]
      }, delay)
    },

    leave (el, done) {
      const delay = el.dataset.index * config.ITEM_STAGGER
      const key = this.genTimerKey('leave', el.dataset.index)
      clearTimeout(this.animationTimers[key])
      this.animationTimers[key] = setTimeout(() => {
        clearTimeout(this.animationTimers[key])
        this.animationTimers[key] = null
        Velocity(
          el,
          {opacity: 0, translateX: '-120%'},
          {
            complete: () => {
              done()
              if (Velocity && Velocity.Utilities && Velocity.Utilities.removeData) {
                Velocity.Utilities.removeData(el)
              }
            }
          }
        )
        delete this.animationTimers[key]
      }, delay)
    },

    genTimerKey (type, index) {
      const ts = Date.now()
      const rand = Math.round(10000 * Math.random())
      return `${type}_${index}_${ts}_${rand}`
    }
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-leaderboard-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}

  .item-body.app-context-section.primary
    .resize-sensor(ref="sensor")

    .leaderboard-main-content
      //- Real list
      transition-group(v-if="currentChunk"
                       tag="ol"
                       name="staggered"
                       :css="false"
                       @before-enter="beforeEnter"
                       @enter="enter"
                       @leave="leave")
        li(v-for="(leader, index) in currentChunk"
           :key="leader.index"
           :data-index="index")
          .ranks(:style="highLightStyle")
            .number(v-text="leader.rank")
          .content(:style="stripeStyle")
            .name(v-text="leader.name")
            .description(v-if="leader.description && leader.description.length" v-text="leader.description")
          .points(:style="highLightStyle")
            .number(v-text="leader.points")

      //- For height calculation
      ol.invisible
        li.dummy(v-for="leader in dummyData")
          .ranks
            .number(v-text="leader.rank")
          .content
            .name(v-text="leader.name")
            .description(v-text="leader.description")
          .points
            .number(v-text="leader.points")
</template>

<style lang="stylus">
$boxPadding = 1em

.metrics-leaderboard-item
  .item-body
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch
    position: relative
    overflow: hidden

    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: $boxPadding
      bottom: $boxPadding
      left: $boxPadding
      right: $boxPadding

    .leaderboard-main-content
      position: absolute !important
      top: $boxPadding
      bottom: $boxPadding
      left: $boxPadding
      right: $boxPadding

      ol
        list-style: none
        margin: 0
        padding: 0

        &.invisible
          position: relative
          visibility: hidden
          opacity: 0
          z-index: -1

        li
          // Fixed height
          height: 4.3em
          overflow: hidden

          padding-top: 1em
          box-sizing: content-box
          display: flex
          flex-flow: row nowrap
          justify-content: space-between
          align-items: stretch
          align-content: center
          opacity: 0

          &:first-of-type
            padding-top: 0

          .ranks,
          .points
            font-size: 1.5em
            text-align: center
            font-weight: 600

            display: flex
            flex-flow: column nowrap
            justify-content: center
            align-items: center

          .ranks
            min-width: 2.5em

          .points
            min-width: 4em

          .content
            flex: 1
            padding: 0 1em
            overflow: hidden

            display: flex
            flex-flow: column nowrap
            justify-content: center

            .name,
            .description
              line-height: 100%
              overflow: hidden
              white-space: nowrap
              text-overflow: ellipsis

            .name
              font-size: 1.8em
              font-weight: 800

            .description
              padding-top: 0.3em
</style>
