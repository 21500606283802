<script>
// MIXINS
import MenuItemMixins from './MenuItemMixins'

const config = {
  // In ms. Animate loop between price and calories
  LOOP_INTERVAL: 3000
}

export default {
  name: 'MenuAppPhotoItem',

  mixins: [ MenuItemMixins ],

  props: {
    item: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // price | calories
      currentBlock: 'price',

      loopTimer: undefined
    }
  },

  computed: {
    itemPhoto () {
      if (!this.item || !this.item.image || !this.item.image.length) { return }
      return {
        backgroundImage: `url(${decodeURIComponent(this.item.image)})`
      }
    },

    loopInfo () {
      return this.showCalories && this.prices.length
    }
  },

  mounted () {
    clearInterval(this.loopTimer)

    this.loopTimer = setInterval(() => {
      this.showNextBlock()
    }, config.LOOP_INTERVAL)
  },

  beforeDestroy () {
    clearInterval(this.loopTimer)
  },

  methods: {
    showNextBlock () {
      if (!this.loopInfo) { return }
      this.currentBlock = (this.currentBlock === 'calories' ? 'price' : 'calories')
    }
  }
}
</script>

<template lang="pug">
.menu-app-photo-item.app-context-section.secondary.border-none
  .item-image(:style="itemPhoto")

  .main-info.app-context-section.primary
    .item-name {{ item.name }}
    .price-n-calories(:class="{'needs-looping': loopInfo}")
      //- Animate between blocks
      template(v-if="loopInfo")
        transition(name="page-moveTop", :duration="500")
          .loop-wrapper.price-block(v-if="currentBlock === 'price'")
            .item-price
              .price-block(v-for="(itemPrice, pIndex) in prices", :key="pIndex"
                           :class="{'show-label': showPriceLabel}")
                .price-value {{ displayPrice(itemPrice) }}
                .price-label(v-if="showPriceLabel && itemPrice.label") {{ itemPrice.label }}
        transition(name="page-moveTop", :duration="500")
          .loop-wrapper.calories-block(v-if="currentBlock === 'calories'")
            .item-calories {{ item.calories }}cal

      //- No animation needed
      template(v-if="!loopInfo")
        .item-price(v-if="prices.length")
          .price-block(v-for="(itemPrice, pIndex) in prices", :key="pIndex"
                       :class="{'show-label': showPriceLabel}")
            .price-value {{ displayPrice(itemPrice) }}
            .price-label(v-if="showPriceLabel && itemPrice.label") {{ itemPrice.label }}
        .item-calories(v-else-if="showCalories") {{ item.calories }}cal

  .description.app-context-section.primary(v-if="item.description && item.description.length") {{ item.description }}
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.menu-app-photo-item
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  overflow: hidden
  white-space: normal

  .item-image
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 1
    background-size: cover
    background-position: 50% 50%
    background-repeat: no-repeat

  .main-info
    position: absolute
    top: 0
    left: 0
    right: 0
    z-index: 2

    padding: 0.6em 1em

    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    overflow: hidden
    max-width: 100%

    .item-name
      flex: 1 1 0.00001px
      font-size: 1.8em
      line-height: 130%
      font-weight: 600

    .price-n-calories
      margin-left: 1em
      display: flex
      flex-flow: row nowrap
      justify-content: flex-end
      align-items: center
      position: relative
      overflow: hidden

      &.needs-looping
        display: block
        width: 8em
        align-self: stretch

      .loop-wrapper
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        display: flex
        flex-flow: row nowrap
        justify-content: flex-end
        align-items: center
        overflow: hidden

    .item-calories
      font-size: 1.8em
      line-height: 130%
      opacity: 0.6

    .item-price
      display: flex
      flex-flow: row nowrap
      justify-content: flex-end
      align-items: center
      white-space: nowrap

      .price-block
        display: flex
        flex-flow: column nowrap
        justify-content: flex-start
        align-items: center
        text-align: center
        margin-left: 0.8em
        opacity: 0.7

        &:first-of-type
          margin-left: 0

        .price-value
          font-size: 1.8em
          line-height: 130%
          font-weight: 600

        .price-label
          font-size: 0.9em
          line-height: 110%

  .description
    position: absolute
    bottom: 0
    left: 0
    right: 0
    z-index: 2

    padding: 0.8em 1em
    font-size: 1.2em
    line-height: 120%
</style>
