<script>
import MetricsMixins from './MetricsMixins.vue'

import TableItem from './TableItem.vue'

export default {
  name: 'MetricsTable',
  components: { TableItem },

  mixins: [MetricsMixins],

  methods: {
    tableItemData (item) {
      if (!item || !item.metric || !item.metric.ref) { return [[]] }
      const metric = this.subscribedValue(item.metric.ref)
      return (metric && metric.value) || [[]]
    }
  }
}
</script>

<template lang="pug">
section.metrics-table
  transition(name="transitionName", :duration="500" appear)
    table-item.prime(v-if="showPrime && primeItem"
                     :title="itemTitle(primeItem)"
                     :remove-title="removeTitle"
                     :item="tableItemData(primeItem)"
                     :metric="primeItem.metric"
                     :paginate="primeItem.paginate"
                     :line-height="primeItem.line_height"
                     :is-paginated="needsPaginate"
                     :interval="interval")

  transition(name="transitionName", :duration="500" appear)
    table-item.base(v-if="!showPrime && baseItem"
                     :title="itemTitle(baseItem)"
                     :remove-title="removeTitle"
                     :item="tableItemData(baseItem)"
                     :metric="baseItem.metric"
                     :paginate="baseItem.paginate"
                     :line-height="baseItem.line_height"
                     :is-paginated="needsPaginate"
                     :interval="interval")
</template>

<style lang="stylus">
section.metrics-table
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  .metrics-table-item
    // Must be equal to the duration set in the Vue `<transition>` component
    animation-duration: 0.5s
</style>
