<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import {
  mapGetters
} from 'vuex'

import InfoBarItems from './infobar/Items.vue'
import ClockWidget from 'components/widgets/Clock.vue'
import WeatherWidget from 'components/widgets/Weather.vue'

import RSS from 'services/rss'
import OfflineCaches from 'services/offline-caches'
import Log from 'services/log'
import Utils from 'services/utils'

const config = {
  // In vmin
  BASE_FONTSIZE: 4,

  // Base
  MAIN_SECTION_FONTSCALE: 1.3,
  // Portrait (but not Tall)
  MAIN_SECTION_PORTRAIT_FONTSCALE: 1.5,

  // Width / Height ratio. Switch to `is-wide` layout when threshold reached
  IS_WIDE: 3,
  // Width / Height ratio. Switch to `is-x-wide` layout when threshold reached
  IS_X_WIDE: 5.5,
  // Width / Height ratio. Switch to `is-tall` layout when threshold reached
  IS_TALL: 0.4,

  // In minutes. Interval to update from feed source
  UPDATE_INTERVAL: 10,
  // In minutes. Fallback interval
  MIN_UPDATE_INTERVAL: 1,
  // In ms. Fallback for cases when Player start offline -- WS never activated
  GET_TIMEOUT: 5000,

  // In second. Fallback interval
  MIN_TRANSITION_INTERVAL: 4
}

// Fallback location for non-device [DEV-2629]
const FALLBACK_DEVICE_LOC = 'Vancouver, CA'

export default {
  name: 'InfoBar',
  components: {
    InfoBarItems,
    ClockWidget,
    WeatherWidget
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showing: '',
      secondaryContent: '',
      rssItems: [],
      rssTitle: '',

      showClock: false,
      showWeather: false,
      weatherLocation: '',
      useDeviceLocation: false,

      transitionInterval: 10,

      font: '',
      textColor: '',

      showMessages: true,
      messages: [],

      showRss: true,
      rssFeed: '',
      refreshInterval: +config.UPDATE_INTERVAL,

      showTwitter: true,
      oauthTwitter: false,
      twitterScreenname: '',

      secondaryTimer: undefined,
      updateTimer: undefined,
      timeoutTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'isDevice',
      'deviceLocation',
      'accountLocale'
    ]),

    deviceLocKey () {
      if (!this.isDevice || !this.deviceLocation.length) { return }
      return `cityByZip_${this.deviceLocation}`
    },

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    // Base font size for the main (middle) section
    mainFontSize () {
      if (!this.baseFontSize) { return }
      return this.baseFontSize * ((this.isPortrait && !this.isTall) ? config.MAIN_SECTION_PORTRAIT_FONTSCALE : config.MAIN_SECTION_FONTSCALE)
    },

    mainFontSizeStyle () {
      if (!this.mainFontSize) { return }
      return {
        fontSize: `${this.mainFontSize}px`
      }
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE
      }
      return false
    },

    isXWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_X_WIDE
      }
      return false
    },

    isTall () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h < config.IS_TALL
      }
      return false
    },

    flexAdditionalBlock () {
      return !this.isTall && !this.isXWide
    },

    textStyle () {
      if (!this.textColor || !(this.textColor || '').trim().length) {
        return
      }
      return {
        color: this.textColor
      }
    },

    enableWeather () {
      return Boolean(this.showWeather && this.weatherLocation && (this.weatherLocation || '').trim().length)
    },

    enableMessages () {
      return Boolean(this.showMessages && this.validMessages && this.validMessages.length)
    },

    enableRss () {
      return Boolean(this.showRss && this.rssFeed && (this.rssFeed || '').trim().length)
    },

    enableTwitter () {
      return Boolean(this.showTwitter && this.oauthTwitter && this.twitterScreenname && (this.twitterScreenname || '').trim().length)
    },

    // Fallback for DEV-4477
    hasTwitterOnly () {
      return this.enableTwitter && !this.enableRss && !this.enableMessages
    },

    validMessages () {
      if (!this.showMessages || !this.messages || !this.messages.length) { return [] }
      return this.messages.filter(msg => {
        return msg.message && (msg.message || '').trim().length
      })
    },

    mainSections () {
      if (!this.enableMessages && !this.enableRss) { return [] }
      const sections = []
      if (this.enableMessages) {
        sections.push('messages')
      }
      if (this.enableRss) {
        sections.push('rss')
      }
      return sections
    },

    secondarySections () {
      if (!this.showClock && !this.enableWeather) { return [] }
      const sections = []
      if (this.showClock) {
        sections.push('clock')
      }
      if (this.enableWeather) {
        sections.push('weather')
      }
      return sections
    },

    mainItems () {
      if (!this.mainSections || !this.mainSections.length) { return [] }
      if (this.showing === 'messages') {
        return this.validMessages
      }
      if (this.showing === 'rss') {
        return this.rssItems
      }
      return []
    },

    rssStoreKey () {
      if (!this.enableRss) { return }
      return `rss_brief_${this.rssFeed}`
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    rssFeed (newValue) {
      if (newValue && newValue.length && this.enableRss) {
        this.getRssFeed(true)
      }
    },

    refreshInterval (newValue) {
      if (newValue) {
        this.setUpdateInterval()
      }
    },

    secondarySections: {
      deep: true,
      handler () {
        this.setSecondaryLoop()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.timeoutTimer)
    clearInterval(this.secondaryTimer)
    clearInterval(this.updateTimer)

    this.debounceCheckSize()
    this.render()
    this.setUpdateInterval()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.timeoutTimer)
    clearInterval(this.secondaryTimer)
    clearInterval(this.updateTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.showClock = options.showClock === 'true'

      this.transitionInterval = Math.max(config.MIN_TRANSITION_INTERVAL, +options.transitionInterval || 10)

      this.font = options.font || ''
      this.textColor = options.textColor || ''

      this.showMessages = options.showMessages === 'true'
      this.messages = options.messages || []

      this.showRss = options.showRss === 'true'
      this.rssFeed = options.rssFeed || ''
      // Unit: minute
      this.refreshInterval = Math.max(config.MIN_UPDATE_INTERVAL, (+options.refreshInterval || config.UPDATE_INTERVAL))

      // Keep reading these options to show fallback hint text for DEV-4477
      this.showTwitter = options.showTwitter === 'true'
      this.oauthTwitter = options.oauthTwitter === 'true'
      this.twitterScreenname = options.twitterScreenname || ''

      this.showWeather = options.showWeather === 'true'
      this.useDeviceLocation = options.weatherUseDeviceLocation === 'true'

      // [A] Use Device Location
      if (this.useDeviceLocation) {
        // A1 - Only do zipcode query on a real device with "device.location" properly set
        if (this.isDevice && this.deviceLocation && this.deviceLocation.length) {
          this.weatherLocation = this.deviceLocation + ''

        // A2 - Use fallback location for
        // - non-device (preview mode or playlist editor), or
        // - the device's "device.location" not set.
        } else {
          this.weatherLocation = FALLBACK_DEVICE_LOC + ''
        }

      // [B] Use Manually Input Location
      } else {
        this.weatherLocation = options.weatherLocation || ''
      }

      this.setMainSection()
    },

    setUpdateInterval () {
      clearInterval(this.updateTimer)
      this.updateTimer = setInterval(() => {
        if (this.enableRss) {
          this.getRssFeed()
        }
      }, this.refreshInterval * 60000)
    },

    setMainSection () {
      if (!this.mainSections || !this.mainSections.length) {
        // No valid main sections
        this.showing = ''
        return
      }

      if (this.showing && this.mainSections.includes(this.showing)) {
        // Main section already initialised.
        // Wait for the 'end-of-list' event from child components,
        // No setInterval needed here.
        return
      }

      this.playNext()
    },

    playNext () {
      if (!this.mainSections || !this.mainSections.length) { return }

      let nextIndex
      // Not init yet
      if (!this.showing) {
        nextIndex = 0
      } else {
        const currentIndex = this.mainSections.indexOf(this.showing)
        // Currently displaying section got removed
        if (currentIndex === -1) {
          nextIndex = 0
        // Currently displaying the only section. Do nothing here
        } else if (this.mainSections.length === 1) {
          return
        } else {
          nextIndex = (currentIndex + 1) % this.mainSections.length
        }
      }
      this.showing = (this.mainSections[nextIndex]) + ''
    },

    setSecondaryLoop () {
      if (!this.secondarySections || !this.secondarySections.length) {
        clearInterval(this.secondaryTimer)
        this.secondaryContent = ''
        return
      }
      clearInterval(this.secondaryTimer)
      this.playNextSecondary()
      this.secondaryTimer = setInterval(() => {
        this.playNextSecondary()
      }, this.transitionInterval * 1000)
    },

    playNextSecondary () {
      if (!this.secondarySections || !this.secondarySections.length) { return }
      let nextIndex
      // Not init yet
      if (!this.secondaryContent) {
        nextIndex = 0
      } else {
        const currentIndex = this.secondarySections.indexOf(this.secondaryContent)
        // Currently displaying section got removed
        if (currentIndex === -1) {
          nextIndex = 0
        // Currently displaying the only section. Do nothing here
        } else if (this.secondarySections.length === 1) {
          return
        } else {
          nextIndex = (currentIndex + 1) % this.secondarySections.length
        }
      }
      this.secondaryContent = (this.secondarySections[nextIndex]) + ''
    },

    getRssFeed (forceRefresh) {
      RSS.getCacheFirst(
        // feedURL
        this.rssFeed,
        // params
        null,
        // config
        {
          // In ms (For LRUCache)
          maxAge: this.refreshInterval * 60000,
          noserviceworker: true
        }
      ).then(data => {
        this.rssTitle = data.title || ''

        const items = data.items || []
        if (!items.length && forceRefresh) {
          this.rssItems = []
          return
        }

        const feedItems = items.map((feed, idx) => {
          return {
            title: feed.title,
            message: this.contextualEscaping(feed.description)
          }
        })

        this.rssItems = feedItems

        // Store in localStorage
        if (this.rssStoreKey) {
          OfflineCaches.set(this.rssStoreKey, feedItems)
        }
      }).catch(err => {
        this.handleRssError(err, forceRefresh)
      })
    },

    contextualEscaping (content) {
      return Utils.contextualEscaping(content)
    },

    async handleRssError (err, isForceRefresh) {
      // Skip displaying error message when regular feed updates (via setInterval) fails
      if (!isForceRefresh) { return }

      if (!this.rssStoreKey) { return }

      // Staled Data Found
      const staledData = await OfflineCaches.get(this.rssStoreKey)
      if (staledData && staledData.length) {
        this.rssItems = staledData
        return
      }

      Log.warn('app', `InfoBar: RSS Error - ${err.message || err.toString()} "${this.rssFeed}"`, 'WAR_INFOBARRSSERR', {url: this.rssFeed, error: err})
    },

    // Fallbak for cases when Player starting offline [DEV-2635]
    timeoutChecking () {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        this.handleZipError(new Error(`Zip code fetching ${config.GET_TIMEOUT}ms timeout`))
      }, config.GET_TIMEOUT)
    },

    async handleZipError (err) {
      if (!this.deviceLocKey || !this.useDeviceLocation) { return }
      const staleData = await OfflineCaches.get(this.deviceLocKey)
      if (staleData && staleData.length) {
        this.weatherLocation = staleData
      } else {
        this.weatherLocation = ''
        const errorMessage = err.message || err.toString() || 'Unknown zipcode fetching error'
        Log.warn('app', `InfoBar: Error getting city name by zip code - ${errorMessage}`, 'WAR_ERRGETCITYBYZIP')
      }
    }
  }
}
</script>

<template lang="pug">
section.infobar-app(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide, 'is-x-wide': isXWide, 'is-tall': isTall}]"
                    :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .info-bar-container.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]"
                     :style="[boxMarginStyle, textStyle]")
    //- Icon + Source Name
    .meta-section.app-context-section.primary(v-if="showing && showing !== 'messages'")
      .source-icon
        fa.fa-icon(v-if="showing === 'rss'", icon="rss" fixed-width)
      .source-name(v-if="(showing === 'rss' && rssTitle)"
                   :class="{'light-bg': !whiteText, 'hide-content-box': hideBox}")
        | {{ rssTitle }}

    //- Main secion
    .main-section.app-context-section.secondary
      .main-section-inner(:style="mainFontSizeStyle")
        //- Fallback for Twitter only mode (DEV-4477)
        .deprecated-hint(v-if="hasTwitterOnly") {{ $t('pageItems.deprecated.message', { type: 'twitter' }) }}

        info-bar-items(v-else-if="showing && showing.length"
                       :type="showing"
                       :items="mainItems"
                       :interval="transitionInterval"
                       :is-portrait="isPortrait"
                       :is-tall="isTall"
                       :is-wide="isWide"
                       :is-x-wide="isXWide"
                       :base-font-size="mainFontSize"
                       :font-class="fontClass"
                       @end-of-list="playNext")

    //- Clock + Weahter
    .additional-section.app-context-section.primary(v-if="secondarySections && secondarySections.length"
                       :class="{'flex-layout': flexAdditionalBlock}")
      //- Not enough space for two. Swipe Animation needed
      template(v-if="!flexAdditionalBlock")
        transition(name="slideUp" mode="out-in" appear)
          .additional-item.clock(v-if="secondaryContent === 'clock'")
            clock-widget(show-date :hours="accountLocale.timeFormat", :timezone="accountLocale.timezone")
        transition(name="slideUp" mode="out-in" appear)
          .additional-item.weather(v-if="secondaryContent === 'weather'")
            weather-widget(:location="weatherLocation")
      //- No Loop
      template(v-else)
        .additional-flex-item.clock(v-if="showClock")
          clock-widget(show-date :hours="accountLocale.timeFormat", :timezone="accountLocale.timezone")
        .additional-flex-item.weather(v-if="enableWeather")
          weather-widget(:location="weatherLocation")
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

// For landscape mode
$sideBlocksWidth = 7.5em

// For portrait mode
$metaBlockHeight = 4em
$additionBlockHeight = 6em

section.infobar-app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .info-bar-container
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: stretch

    .main-section
      flex: 1 1 0.00001px
      padding: 0.5em 1em
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch
      position: relative

      // Prevent showing the light gray border in Light theme
      border-right: 0 !important

      .main-section-inner
        flex: 1 1 0.00001px
        position: relative

      .deprecated-hint
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        display: flex
        flex-flow: row nowrap
        justify-content: center
        align-items: center
        text-align: center
        padding: 1em

    .meta-section
      width: $sideBlocksWidth
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: stretch
      text-align: center

      .source-icon
        flex: 1 1 0.00001px
        display: flex
        flex-flow: column nowrap
        justify-content: center
        align-items: center
        .fa-icon
          font-size: 3em

      .source-name
        background: -black(0.6)
        padding: 0.3em 0.8em
        &.light-bg
          background: -white(0.85)
        &.hide-content-box
          background: transparent

    .additional-section
      width: $sideBlocksWidth
      position: relative
      overflow: hidden
      text-align: center

      .additional-item
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        padding: 0.5em 1em
        display: flex
        flex-flow: column nowrap
        justify-content: center
        align-items: center

      .additional-flex-item
        position: relative1
        flex: 1 1 0.0001px
        display: flex
        flex-flow: column nowrap
        justify-content: center
        align-items: center

      &.flex-layout
        display: flex
        flex-flow: column nowrap
        justify-content: space-around
        align-items: center

  // ==============
  // X-WIDE
  // ==============

  &.is-x-wide
    .info-bar-container
      .meta-section
        .source-name
          ellipsis()

  // ==============
  // PORTRAIT
  // ==============

  &.is-portrait
    .info-bar-container
      flex-flow: column nowrap

    .meta-section
      width: auto
      height: $metaBlockHeight
      padding: 0.3em 0.8em
      flex-flow: row nowrap
      justify-content: center
      align-items: center
      .source-icon
        flex: none
        display: block
        .fa-icon
          font-size: 2em
      .source-name
        font-size: 1.5em
        padding: 0 0 0 0.5em
        background: transparent !important
        ellipsis()

    .additional-section
      width: auto
      height: $additionBlockHeight
      &.flex-layout
        flex-flow: row nowrap
</style>
