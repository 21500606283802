<script>
import {
  mapState,
  mapGetters
} from 'vuex'

import PageItem from 'components/items/Item.vue'
import ObjectItem from 'components/items/objects/ObjectItem.vue'
import FreeFormItemContainer from 'components/common/FreeFormItemContainer.vue'
import PlaylistFooter from 'components/common/PlaylistFooter.vue'
import InteractionOverlay from './InteractionOverlay.vue'
import AppBackground from 'components/common/AppBackground.vue'

import Log from 'services/log.js'
import Grids from 'services/grids.js'
import Pages from 'services/pages.js'
import YouTube from 'services/youtube.js'
import Environment from 'services/environment'
import CommonStyling from 'services/common-styling.js'
import Metrics from 'services/metrics'
import { EventBus } from 'services/eventbus.js'

import {
  genPageItemKeyBase,
  genPageBackgroundOpts,
  parsePageItemProperties
} from 'services/page-helpers.ts'

const DEFAULT_ASPECT_RATIO = [16, 9]

const configs = {
  // In seconds. Timeout to load next page anyway after this interval
  SKIP_PENDING_AFTER: 10
}

export default {
  name: 'PageContents',
  components: {
    PageItem,
    ObjectItem,
    FreeFormItemContainer,
    PlaylistFooter,
    AppBackground,
    InteractionOverlay
  },
  props: {
    showImmediately: {
      type: Boolean,
      default: false
    },
    showPrime: {
      type: Boolean,
      default: false
    },
    primePage: {
      type: Object,
      default: () => { return {} }
    },
    altPage: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      currentPage: {},
      lastPage: null,
      nextPage: null,

      // this is for page items that need pre-caching like image & video
      pendingItems: [],
      // this is for all page items
      unloadedItems: [],

      // force display all items regardless of their pending state
      forceDisplay: false,

      waitingDuration: false,

      durationTimer: undefined,
      lastPageTimer: undefined,
      pendingTimer: undefined,
      debounceTimer: undefined,
      unloadedTimer: undefined,
      pauseTimer: undefined,
      nextPageTimers: {},
      metricsListenTimer: undefined,
      metricsQueryTimer: undefined,
      menuTimer: undefined
    }
  },
  computed: {
    ...mapState({
      currentPageID: s => s.playlists.currentPageID,
      screensaverIsOn: s => s.playbackmode.screensaverMode,
      paused: s => s.playbackmode.pauseMode,
      showOverlayContainer: s => s.interactions.showOverlay,
      isPausedByBtnClick: s => s.interactions.pausedByBtnClick,
      subscribedMetricKeys: state => state.metrics.subscribedMetricKeys || [],
      queryList: state => state.metrics.queryList || []
    }),
    ...mapGetters([
      'currentPlaylist',
      'showMenuBar',
      'screensaverEnabled',
      'playlistSwipeEnabled',
      'tapToPauseEnabled',
      'tapToShowMenuEnabled'
    ]),

    activePage () {
      return this.showPrime ? this.primePage : this.altPage
    },

    currentItems () {
      if (!this.currentPage?.id) { return [] }
      return this.renderPageItems(this.currentPage)
    },
    currentItemsKeys () {
      if (!this.currentItems || !this.currentItems.length) { return [] }
      return this.currentItems.map(it => it.key)
    },

    nextPageItems () {
      if (!this.nextPage?.id || this.isFreeFormPage(this.nextPage)) { return [] }
      return this.renderPageItems(this.nextPage, true)
    },
    nextItemsKeys () {
      if (!this.nextPageItems || !this.nextPageItems.length) { return [] }
      return this.nextPageItems.map(it => it.key)
    },

    allItems () {
      return [].concat(this.currentItems, this.nextPageItems)
    },
    allGridItems () {
      const currentGridItems = this.currentPage?.id && !this.isFreeFormPage(this.currentPage)
        ? this.renderPageItems(this.currentPage)
        : []
      const nextGridItems = this.nextPage?.id && !this.isFreeFormPage(this.nextPage)
        ? this.renderPageItems(this.nextPage, true)
        : []
      return [].concat(currentGridItems, nextGridItems)
    },
    allFreeFormItems () {
      const currentFreeFormItems = this.currentPage?.id && this.isFreeFormPage(this.currentPage)
        ? this.renderPageItems(this.currentPage)
        : []
      const nextFreeFormItems = this.nextPage?.id && this.isFreeFormPage(this.nextPage)
        ? this.renderPageItems(this.nextPage, true)
        : []
      return [].concat(currentFreeFormItems, nextFreeFormItems)
    },

    pageLayout () {
      if (!this.currentPage) { return {} }
      return Grids.parseTplString(this.currentPage.grid_template, this.isPortraitPlaylist)
    },
    gridTemplate () {
      return this.pageLayout.template || 'none'
    },
    gridAreas () {
      return Grids.identicalAreas((Grids.tplStringToFrameObj(this.gridTemplate) || {}).areas || [])
    },
    zonePaddings () {
      if (!this.currentPage) { return {} }
      return this.pageLayout.paddings || this.pageLayout.fallback_paddings || {}
    },
    zoneGapClass () {
      if (!this.currentPage) { return }
      if (this.pageLayout.zoneGap && this.pageLayout.zoneGap.length) {
        return `zone-gap-${this.pageLayout.zoneGap}`
      }
      return null
    },

    allItemsReady () {
      return !this.pendingItems.length
    },

    showClock () {
      return this.currentPlaylist?.overlay_clock || false
    },
    showWeather () {
      return Boolean(this.currentPlaylist?.weather_location && this.currentPlaylist?.weather_location?.trim?.().length)
    },
    showTickertape () {
      return Boolean(
        this.currentPlaylist?.tickertape_items &&
        Array.isArray(this.currentPlaylist?.tickertape_items) &&
        this.currentPlaylist?.tickertape_items?.length
      )
    },
    showFooter () {
      return (this.showClock || this.showWeather || this.showTickertape) &&
        this.currentPlaylist?.tickertape_no_overlay
    },
    bottomStyle () {
      if (this.showFooter) {
        return {
          bottom: '4.1em',
          height: 'calc(100% - 4.1em)'
        }
      }
      return null
    },

    primePageBg () {
      return genPageBackgroundOpts(this.primePage)
    },
    altPageBg () {
      return genPageBackgroundOpts(this.altPage)
    },

    // The Aspect Ratio set in the playlist "Configuration" tab
    playlistRatio () {
      let aspectRatio = []
      if (this.currentPlaylist && this.currentPlaylist.aspect_ratio && Array.isArray(this.currentPlaylist.aspect_ratio)) {
        aspectRatio = [].concat([], this.currentPlaylist.aspect_ratio)
      }
      if (!aspectRatio[0] || !aspectRatio[1]) {
        return DEFAULT_ASPECT_RATIO
      }
      return aspectRatio
    },
    isPortraitPlaylist () {
      return this.playlistRatio[0] < this.playlistRatio[1]
    },

    playlistTransition () {
      if (this.currentPlaylist && this.currentPlaylist.transition && this.currentPlaylist.transition.length) {
        return this.currentPlaylist.transition
      }
      return null
    },

    showInteractionsOverlay () {
      if (
        Environment.pageEditMode ||
        this.isFreeFormPage(this.activePage) ||
        this.screensaverIsOn ||
        (this.tapToPauseEnabled && this.paused) ||
        (this.tapToShowMenuEnabled && this.showMenuBar) ||
        this.showOverlayContainer
      ) {
        return false
      }
      return Boolean(this.screensaverEnabled || this.tapToPauseEnabled || this.playlistSwipeEnabled || this.tapToShowMenuEnabled)
    },

    pauseDuration () {
      if (!this.currentPlaylist || !this.currentPlaylist.timeout) {
        return 60
      }
      return Math.max(4, Math.min(86400, +this.currentPlaylist.timeout || 60))
    }
  },

  watch: {
    activePage: {
      deep: true,
      handler () {
        this.renderPages(this.showPrime)
      }
    },

    allItemsReady (isTrue) {
      // Trigger to show next page when all page items are ready
      if (isTrue && !this.forceDisplay && this.nextPage) {
        clearTimeout(this.pendingTimer)
        this.revealNextPage()
      }
    }
  },

  mounted () {
    EventBus.$on('interaction-pause-playlist', this.pausePlaylist)
    EventBus.$on('interaction-show-screensaver', this.prepareScreensaver)
    EventBus.$on('listen-metrics', this.debounceListenMetrics)
    EventBus.$on('query-metrics', this.debounceQueryMetrics)
    EventBus.$on('synchronize-reset', this.resetPageItems)
    document.addEventListener('touchend', this.touchEventHandler)

    this.clearTimers()
    this.clearRenderingCompleteFlag()
    this.renderPages(this.showPrime)
  },

  beforeDestroy () {
    EventBus.$off('interaction-pause-playlist', this.pausePlaylist)
    EventBus.$off('interaction-show-screensaver', this.prepareScreensaver)
    EventBus.$off('listen-metrics', this.debounceListenMetrics)
    EventBus.$off('query-metrics', this.debounceQueryMetrics)
    EventBus.$off('synchronize-reset', this.resetPageItems)
    document.removeEventListener('touchend', this.touchEventHandler)

    this.clearTimers()
  },

  methods: {
    isFreeFormPage (page) {
      return page?.id && page?.layout_scheme === 'freeform'
    },

    isObjectItem (item) {
      return item && item.type && item.type.startsWith('object-')
    },

    itemProperties (item = {}) {
      return parsePageItemProperties(item.url || '')
    },

    freeFormItemPosition (item = {}) {
      return JSON.parse(item.positioning || '{}') || {}
    },

    finished () {
      this.$emit('finished')
    },

    clearRenderingCompleteFlag () {
      let e
      while ((e = document.getElementById('rendering-complete')) && e) {
        e.parentNode.removeChild(e)
      }
    },

    clearTimers () {
      clearTimeout(this.lastPageTimer)
      clearTimeout(this.durationTimer)
      clearTimeout(this.pendingTimer)
      clearTimeout(this.debounceTimer)
      clearTimeout(this.unloadedTimer)
      clearTimeout(this.pauseTimer)
      clearTimeout(this.metricsListenTimer)
      clearTimeout(this.metricsQueryTimer)
      clearTimeout(this.menuTimer)

      if (Object.keys(this.nextPageTimers).length) {
        Object.keys(this.nextPageTimers).forEach(itemKey => {
          clearTimeout(this.nextPageTimers[itemKey])
          this.nextPageTimers[itemKey] = null
          delete this.nextPageTimers[itemKey]
        })
      }
    },

    parseItems (page) {
      if (page && page.items) {
        // Zoned Page Support
        return page.items
      } else if (page && page.id) {
        // Legacy Page Support
        return [page]
      }
      return []
    },

    renderPageItems (page, skipExistingItems) {
      if (!page?.id) { return [] }

      const items = JSON.parse(JSON.stringify(this.parseItems(page || {})))

      // Page Background related [DEV-1097]
      const pageBackground = genPageBackgroundOpts(page)
      const hasPageBackground = Boolean(pageBackground)
      const showTextShadow = hasPageBackground && CommonStyling.showTextShadow(pageBackground.bgType, pageBackground.whiteText)

      const keyList = []
      items.forEach(item => {
        if (!item) { return }
        // Unique key by ref_id / ref_ids and occurrence index
        const keyBase = genPageItemKeyBase(item)

        const siblings = keyList.filter(key => key.startsWith(keyBase)) || []
        const itemKey = `${keyBase}${siblings.length + 1}`
        item.key = itemKey

        // Pass down page duration for AppBackground [DEV-736]
        item.page_duration = +(page.duration || 0)

        // Pass down page background settings [DEV-1097]
        item.has_page_bg = hasPageBackground
        if (hasPageBackground) {
          item.dark_text = !pageBackground.whiteText
          item.show_text_shadow = showTextShadow
        }

        // Pass down page "Hide Context Box" settings [DEV-1183]
        item.hide_box_in_page = !!page.hide_box

        keyList.push(itemKey)
      })

      // Skip Existing Item(s) in the current page (for nextPage)
      if (skipExistingItems) {
        return items.filter(it => !this.currentItemsKeys.includes(it.key))
      }

      return items
    },

    getTransitionForPage (page) {
      // Skip Transiton when embedding in Playlist Page editor (DEV-1497)
      if (Environment.pageEditMode) {
        return ''
      }

      if (page && page.transition) {
        return 'page-' + page.transition
      }
      return this.playlistTransition ? `page-${this.playlistTransition}` : 'page-fade'
    },

    renderPages (showPrime) {
      this.lastPage = JSON.parse(JSON.stringify(this.currentPage))
      const nextPage = JSON.parse(JSON.stringify((showPrime ? this.primePage : this.altPage) || {}))

      let startShowingNow = false
      const pendingItems = []
      const unloadedItems = []

      // Not Null
      if (nextPage && nextPage.id) {
        const newItems = JSON.parse(JSON.stringify(this.renderPageItems(nextPage)))

        if (newItems && newItems.length) {
          for (let i = 0; i < newItems.length; i++) {
            const thisItem = newItems[i]
            unloadedItems.push(thisItem.key)
          }
        }

        // Skip preload detection for page edit mode (js_user)
        if (Environment.pageEditMode || Environment.previewMode || this.showImmediately) {
          this.forceDisplay = true
          startShowingNow = true
        // Check for any preload-needed items
        } else {
          if (!newItems || !newItems.length) { return }
          for (let i = 0; i < newItems.length; i++) {
            const thisItem = newItems[i]
            if (!thisItem || !thisItem.type) { continue }
            if (!Pages.needsPreload(thisItem.type) && !Pages.isIframedItemType(thisItem.type)) { continue }
            if (
              thisItem.key &&
              !pendingItems.includes(thisItem.key) &&
              !this.currentItemsKeys.includes(thisItem.key)
            ) {
              pendingItems.push(thisItem.key)
            }
          }
          this.pendingItems = pendingItems

          // No pending items, start showing right away
          if (!pendingItems.length) {
            this.forceDisplay = true
            startShowingNow = true
          // Has pending items, wait for `allItemsReady`
          } else {
            this.forceDisplay = false
          }
        }
      }

      this.unloadedItems = unloadedItems
      this.clearRenderingCompleteFlag()

      // Start showing right away
      if (startShowingNow) {
        this.currentPage = nextPage
        this.nextPage = null
        this.setDurationTimer(this.currentPage)
        this.startShowing(this.currentPage)
        this.removeLastPage()
      // Wait for pending items in the background
      } else {
        this.nextPage = nextPage
        this.setDurationTimer(this.nextPage)
        clearTimeout(this.pendingTimer)
        this.pendingTimer = setTimeout(() => {
          clearTimeout(this.pendingTimer)
          this.revealNextPage(true)
        }, configs.SKIP_PENDING_AFTER * 1000)
      }
    },

    // Not applying as 'computed' prop here
    // because it will cause infinite watch loop in that way
    hasSlides (items) {
      if (!items || !items.length) { return false }
      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'slides') {
          return true
        }
        // NOTE: no need to detect media zone 'slides' here.
        // Because that duration is handled by the zone `interval` option (DEV-1582)
      }
      return false
    },

    hasYoutube (items) {
      if (!items || !items.length) { return false }
      for (let i = 0; i < items.length; i++) {
        if (YouTube.isYoutubeApp(items[i].type)) {
          return true
        }
      }
      return false
    },

    hasVideo (items) {
      if (!items || !items.length) { return false }
      for (let i = 0; i < items.length; i++) {
        if ([
          'video',
          'web-video',
          'youtube',
          'vimeo'
        ].includes(items[i].type)) {
          return true
        } else if (items[i].type === 'media') {
          // detect 'media' zone with 'video' (DEV-1582)
          const zoneItems = items[i].items || []
          if (zoneItems.length) {
            for (let j = 0; j < zoneItems.length; j++) {
              if (zoneItems[j].type === 'video') {
                return true
              }
            }
          }
        }
      }
      return false
    },

    loadedHandler (item) {
      if (!item || !item.key) { return }
      this.removePendingItem(item.key)
      this.removeUnloadedItem(item.key)
    },

    removePendingItem (itemKey) {
      const targetIndex = this.pendingItems.findIndex(iKey => itemKey && iKey === itemKey)
      if (targetIndex >= 0) {
        this.pendingItems.splice(targetIndex, 1)
      }
    },

    removeUnloadedItem (itemKey) {
      const targetIndex = this.unloadedItems.findIndex(iKey => itemKey && iKey === itemKey)
      if (targetIndex >= 0) {
        this.unloadedItems.splice(targetIndex, 1)
      }
      if (this.unloadedItems.length === 0) {
        // wait for 1s animation
        clearTimeout(this.unloadedTimer)
        this.unloadedTimer = setTimeout(() => {
          clearTimeout(this.unloadedTimer)
          // console.log('Rendering Complete')
          const div = document.createElement('div')
          div.setAttribute('id', 'rendering-complete')
          div.style.cssText = 'width:0;height:0'
          document.body.appendChild(div)
        }, 1200)
      }
    },

    isNextPageItem (item) {
      return (item && item.key && !this.currentItemsKeys.includes(item.key))
    },

    removeLastPage () {
      clearTimeout(this.lastPageTimer)
      this.lastPageTimer = setTimeout(() => {
        clearTimeout(this.lastPageTimer)
        this.lastPage = null
      }, 1500)
    },

    revealNextPage (isForceShow) {
      this.forceDisplay = true

      if (!this.nextPage) { return }

      const nextItemsKeys = JSON.parse(JSON.stringify(this.nextItemsKeys))

      this.currentPage = JSON.parse(JSON.stringify(this.nextPage))
      this.nextPage = null

      if (isForceShow) {
        Log.debug('player', `Next page's contents are still not fully loaded after ${configs.SKIP_PENDING_AFTER}s.\nForce to show next page now (${this.currentPage.id}).`, 'DBG_SKIPNEXTPAGE')
      }

      this.$nextTick(() => {
        if (nextItemsKeys.length) {
          nextItemsKeys.forEach(itemKey => {
            this.removePendingItem(itemKey)
            if (this.$refs && this.$refs[itemKey] && this.$refs[itemKey][0]) {
              const elm = this.$refs[itemKey][0]
              this.addEnterClass(elm)
              clearTimeout(this.nextPageTimers[itemKey])
              this.nextPageTimers[itemKey] = setTimeout(() => {
                clearTimeout(this.nextPageTimers[itemKey])
                this.removeEnterClass(elm)
                this.nextPageTimers[itemKey] = null
                delete this.nextPageTimers[itemKey]
              }, 1200)
            }
          })
        }

        this.startShowing(this.currentPage)
        this.removeLastPage()
      })
    },

    startShowing (page) {
      if (!page) {
        return
      }

      const pageChanged = (this.currentPageID !== page.id)

      // Close Button app overlay content on page changed (DEV-1761)
      if (pageChanged && this.showOverlayContainer) {
        if (this.isPausedByBtnClick && this.paused) {
          this.$emit('resume-playback')
        }
        this.$store.dispatch('hideOverlayContent')
      }

      this.updateCurrentPageID(page.id)

      this.waitingDuration = false
      if (!Environment.pageEditMode && !Environment.previewMode && !Environment.desktopMode && !Environment.iframeEmbedMode && pageChanged) {
        if (
          // slides: wait for 'duration' event, even if the page has duration
          (page.duration > 0 && this.hasSlides(this.currentItems)) ||
          // video & youtube: wait for 'duration' event if page does not have duration
          (!page.duration && (this.hasVideo(this.currentItems) || this.hasYoutube(this.currentItems)))
        ) {
          this.waitingDuration = true
        } else {
          Pages.sendPlayedReport(page.duration)
        }
      }

      // To recalculate perserved page items' positioning
      // Prevent element flickering during page transiton
      this.debounceStorePosMetrics(1700)
    },

    setDurationTimer (page) {
      clearTimeout(this.durationTimer)

      if (!page || !page.id) {
        return
      }

      let message
      let skipDuration = false

      // Add null check for page.duration
      const pageDuration = page.duration || 0

      if (Environment.pageEditMode) {
        // Ignore duration and remain in the same page in Page Edit mode
        message = `Editing page ${page.description} (${page.id})`
        skipDuration = true
      } else if (pageDuration <= 0) {
        // `duration` can be 'undefined' for pages like video and YouTube
        message = `Page ${page.description} (${page.id}) has no preset duration`
        skipDuration = true
      } else if (this.hasSlides(this.parseItems(page))) {
        // Wait for `@finished` event from SlidesItem
        message = `Showing page with slides ${page.description} (${page.id})`
        skipDuration = true
      } else if (page.default_page) {
        message = `Showing playlist default page ${page.description} (${page.id})`
      } else {
        message = `Showing page ${page.description} (${page.id}) for ${pageDuration}s (now = ${Math.round(Date.now() / 1000)})`
      }

      Log.debug('player', message, 'DBG_DURATIONTIMER')

      if (skipDuration || pageDuration <= 0) {
        return
      }

      clearTimeout(this.durationTimer)
      this.durationTimer = setTimeout(() => {
        clearTimeout(this.durationTimer)
        this.finished()
      }, pageDuration * 1000)
    },

    updateCurrentPageID (pageID) {
      this.$store.commit('updateCurrentPageID', pageID)
    },

    getItemAbsMetrics (el) {
      if (!el) { return }
      const metrics = {
        width: el.offsetWidth,
        height: el.offsetHeight,
        top: el.offsetTop,
        left: el.offsetLeft
      }
      el.boundingMetrics = metrics
    },

    beforeEnter (el) {
      if (el && el.classList.contains('in-next-page')) {
        return
      }
      this.addEnterClass(el)
    },

    afterEnter (el) {
      if (el && el.classList.contains('in-next-page')) {
        return
      }
      this.removeEnterClass(el)
    },

    addEnterClass (el) {
      if (!el || !el.classList) { return }
      const enterClass = this.getTransitionForPage(this.currentPage)
      el.classList.add('on-enter', `${enterClass}-enter-active`)
    },

    removeEnterClass (el) {
      if (!el || !el.classList) { return }
      const enterClass = this.getTransitionForPage(this.currentPage)
      el.classList.remove('on-enter', `${enterClass}-enter-active`)

      el.classList.add('live')

      // To preserve absolute position during item leaving transition
      this.getItemAbsMetrics(el)
    },

    storePosMetrics () {
      if (!this.$el) { return }
      const elms = this.$el.querySelectorAll('.grid-item-wrapper')
      Array.prototype.forEach.call(elms, (el) => {
        this.getItemAbsMetrics(el)
      })
    },

    debounceStorePosMetrics (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.storePosMetrics()
      }, timeout || 500)
    },

    beforeLeave (el) {
      const metrics = el.boundingMetrics
      if (metrics) {
        el.style.width = `${metrics.width || 0}px`
        el.style.height = `${metrics.height || 0}px`
        el.style.top = `${metrics.top || 0}px`
        el.style.left = `${metrics.left || 0}px`
        el.style.gridArea = 'unset'
        el.style.position = 'absolute'
      }

      el.classList.remove('live')

      const leaveClass = this.getTransitionForPage(this.lastPage)
      el.classList.add('on-leave', `${leaveClass}-leave-active`)
    },

    getZonePaddings (gridAreaName) {
      return this.zonePaddings[gridAreaName || 'main'] || this.zonePaddings.overall
    },

    gotDuration (duration) {
      if (this.waitingDuration) {
        Pages.sendPlayedReport(duration)
      }
      if (
        this.activePage &&
        (!this.activePage.duration || this.activePage.duration <= 0) &&
        duration && +duration > 0
      ) {
        // Sent value (in ms) to SDK
        EventBus.$emit('sdk-on-page-duration', +duration * 1000)
      }
    },

    prepareScreensaver () {
      // Check if default_page is set
      const hasDefaultPage = Boolean(Pages.findDefaultPage())
      if (hasDefaultPage) {
        Pages.startScreensaver()
      } else {
        Log.warn('player', 'Screensaver not found. Please set a default page for this playlist.', 'WAR_NOTFOUNDSCREENSAVER')
      }
    },

    pausePlaylist () {
      if (!this.paused) {
        Log.debug('player', `Tap to pause the playlist for ${this.pauseDuration}s`, 'DBG_PAUSEPLAYLIST')
        this.$store.dispatch('togglePauseMode')
        this.refreshPauseTimer()
      }
    },

    refreshPauseTimer () {
      clearTimeout(this.pauseTimer)
      this.pauseTimer = setTimeout(() => {
        clearTimeout(this.pauseTimer)
        if (this.paused) {
          this.$emit('resume-playback')
        }
      }, this.pauseDuration * 1000)
    },

    touchEventHandler () {
      if (this.tapToShowMenuEnabled && !this.showMenuBar) {
        this.debounceToggleMenu()
        return
      }

      // Respond to Kiosk Interaction - Tap to Pause only (DEV-1749)
      if (!this.tapToPauseEnabled || !this.paused) { return }

      if (this.playlistSwipeEnabled) {
        Log.debug('player', 'Playlist is paused. Please resume playback before swiping to change pages', 'DBG_PLAYLISTPAUSED')
      }

      this.refreshPauseTimer()
    },

    debounceListenMetrics () {
      clearTimeout(this.metricsListenTimer)
      this.metricsListenTimer = setTimeout(() => {
        clearTimeout(this.metricsListenTimer)
        this.listenMetrics()
      }, 200)
    },

    listenMetrics () {
      if (this.subscribedMetricKeys.length) {
        Metrics.subscribeMetricsByTagList(this.subscribedMetricKeys)
      }
    },

    debounceQueryMetrics () {
      clearTimeout(this.metricsQueryTimer)
      this.metricsQueryTimer = setTimeout(() => {
        clearTimeout(this.metricsQueryTimer)
        this.queryMetrics()
      }, 200)
    },

    queryMetrics () {
      if (this.queryList.length) {
        Metrics.queryForMetrics(this.queryList)
      }
    },

    // Debounce to prevent multiple taps
    debounceToggleMenu () {
      clearTimeout(this.menuTimer)
      this.menuTimer = setTimeout(() => {
        clearTimeout(this.menuTimer)
        this.$store.dispatch('setInteractieMenuState', true)
      }, 200)
    },

    // Force reset page items for Synchronize + one-page Playlist
    resetPageItems () {
      if (Environment.pageEditMode || Environment.previewMode) { return }
      this.currentPage = {}
      this.nextPage = null
      this.lastPage = null
      this.pendingItems = []
      this.unloadedItems = []
      this.clearRenderingCompleteFlag()
    }
  }
}
</script>

<template lang="pug">
section.page-contents-container
  interaction-overlay(v-if="showInteractionsOverlay"
                      :style="[{gridTemplate: gridTemplate}, bottomStyle]"
                      :class="zoneGapClass"
                      :grid-items="currentItems"
                      :grid-areas="gridAreas")

  //- Grid Page Items
  transition-group.grids-container(tag="div"
                  :style="[{gridTemplate: gridTemplate}, bottomStyle]"
                  :class="zoneGapClass"
                  name="custom-transition"
                  appear
                  @before-enter="beforeEnter"
                  @after-enter="afterEnter"
                  @before-leave="beforeLeave")
    .grid-item-wrapper(v-for="item in allGridItems"
                      :ref="item.key"
                      :key="item.key"
                      :style="{gridArea: item.grid_area || 'main'}"
                      :class="{'in-next-page': isNextPageItem(item)}")
      page-item(:item="item"
                :zone-paddings="getZonePaddings(item.grid_area)"
                active
                @finished="finished"
                @loaded="loadedHandler(item)"
                @duration="gotDuration")

  //- Free Form Page Items
  transition-group.free-form-container(tag="div"
                   name="custom-transition"
                   appear
                   @before-enter="beforeEnter"
                   @after-enter="afterEnter"
                   @before-leave="beforeLeave")
    free-form-item-container(v-for="item in allFreeFormItems"
                            :key="item.key"
                            :type="item.type"
                            :position="freeFormItemPosition(item)")
      //- Objects
      object-item(v-if="isObjectItem(item)"
                  :key="item.key"
                  :type="item.type"
                  :item-id="item.id"
                  :properties="itemProperties(item)"
                  :position="freeFormItemPosition(item)")
      //- App, Media, Canva, Webapp, WebScreenshot
      page-item(v-else
                :key="item.key"
                :item="item"
                active
                @finished="finished"
                @loaded="loadedHandler(item)"
                @duration="gotDuration")

  playlist-footer(v-if="showFooter")

  //- Page Background
  //- - Prime
  app-background.page-bg.prime(v-if="showPrime && primePageBg"
                 :type="primePageBg.bgType"
                 :bg-color="primePageBg.bgColor"
                 :bg-media="primePageBg.bgMedia"
                 :stock-media="primePageBg.stockMedia"
                 :unsplash-image="primePageBg.unsplashImage"
                 :gradient-overlay="primePageBg.gradientOverlay"
                 :zoom-effect="primePageBg.zoomEffect"
                 :duration="primePage ? (primePage.duration || 0) : 0"
                 :dark-overlay="primePageBg.darkOverlay")
  //- - Alt
  app-background.page-bg.base(v-if="!showPrime && altPageBg"
                 :type="altPageBg.bgType"
                 :bg-color="altPageBg.bgColor"
                 :bg-media="altPageBg.bgMedia"
                 :stock-media="altPageBg.stockMedia"
                 :unsplash-image="altPageBg.unsplashImage"
                 :gradient-overlay="altPageBg.gradientOverlay"
                 :zoom-effect="altPageBg.zoomEffect"
                 :duration="altPage ? (altPage.duration || 0) : 0"
                 :dark-overlay="altPageBg.darkOverlay")
</template>

<style lang="stylus">
.page-contents-container
  z-index: 10
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden

  .free-form-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden
    .free-form-item-container
      > .page-item
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

  .grids-container
    display: grid
    justify-items: stretch
    justify-content: stretch
    align-items: stretch
    align-content: stretch
    position: absolute
    z-index: 1
    overflow: hidden
    top: 0
    left: 0
    right: 0
    bottom: 0
    grid-gap: 0 0

    // Make pointers get through to the free-form items
    pointer-events: none

    // Fixes for `auto` row height display in Safari [DEV-1047]
    height: 100%

    // Zone Gap (DEV-1700)
    &.zone-gap-small
      grid-gap: 1.5vmin 1.5vmin
    &.zone-gap-medium
      grid-gap: 3vmin 3vmin
    &.zone-gap-large
      grid-gap: 4.5vmin 4.5vmin

    .grid-item-wrapper
      position: relative
      overflow: hidden
      z-index: 5
      // Resume pointer-events for grid page items
      pointer-events: auto

      &.on-enter
        z-index: 1

      // Force leaving items to be placed in the background
      &.on-leave
        position: absolute
        grid-area: unset !important
        z-index: 0

      &.live
        transition: all .5s

      // Force pending page items preloading in the backgound
      &.in-next-page
        z-index: -1
        visiblity: hidden
        grid-area: unset !important
        position: absolute

      .page-item
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 0
        overflow: hidden

  .page-bg
    z-index: 0

  section#playlist-footer
    z-index: 10
</style>
