<script>
import {
  mapGetters
} from 'vuex'

import Clock from 'components/widgets/Clock.vue'
import Weather from 'components/widgets/Weather.vue'

const CLOCK_BLOCK = {
  name: 'clock',
  duration: 8
}

const WEATHER_BLOCK = {
  name: 'weather',
  duration: 8
}

const config = {
  DEFAULT_INTERVAL: 10
}

export default {
  name: 'FooterSlider',
  components: {
    Clock,
    Weather
  },

  props: {
    showClock: { type: Boolean, default: false },
    weatherLocation: { type: String, default: '' },

    // Indicator of companion with a tickertape
    withTickertape: { type: Boolean, default: false },
    customStyle: { type: Object, default: () => { return {} }},
    cssClasses: { type: Object, default: () => { return {} } }
  },

  data () {
    return {
      loopingSections: [],
      loopingTimer: undefined,
      hasRendered: false,
      currentSection: undefined,

      debounceTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'accountLocale'
    ]),
    showWeather () {
      return Boolean(this.weatherLocation && this.weatherLocation.length)
    },
    location () {
      if (!this.showWeather) { return }
      return this.weatherLocation || ''
    },
    needsLooping () {
      return Boolean(this.loopingSections && this.loopingSections.length > 1)
    },
    clockStyle () {
      return this.customStyle && this.customStyle.clock || {}
    },
    weatherStyle () {
      return this.customStyle && this.customStyle.weather || {}
    }
  },

  watch: {
    showClock () {
      this.hasRendered = false
      this.debounceUpdate()
    },

    weatherLocation () {
      this.hasRendered = false
      this.debounceUpdate()
    },

    needsLooping (isTrue) {
      this.hasRendered = false
      this.$nextTick(() => {
        this.startLooping()
      })
    }
  },

  mounted () {
    clearTimeout(this.loopingTimer)
    clearTimeout(this.debounceTimer)

    this.$nextTick(() => {
      this.debounceUpdate()
    })
  },

  beforeDestroy () {
    clearTimeout(this.loopingTimer)
    clearTimeout(this.debounceTimer)
  },

  methods: {
    debounceUpdate () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.updateSections()
      }, 200)
    },

    updateSections () {
      const list = []
      if (this.showClock) {
        list.push(CLOCK_BLOCK)
      }
      if (this.showWeather && this.location && this.location.length) {
        list.push(WEATHER_BLOCK)
      }
      this.loopingSections = list
      this.startLooping()
    },

    startLooping () {
      if (this.hasRendered) { return }

      const currentSection = Object.assign({}, this.loopingSections[0])
      this.currentSection = currentSection

      clearTimeout(this.loopingTimer)
      this.loopingTimer = setTimeout(() => {
        clearTimeout(this.loopingTimer)
        this.playNextItem()
      }, (currentSection.duration || config.DEFAULT_INTERVAL) * 1000)

      this.hasRendered = true
    },

    playNextItem () {
      if (!this.needsLooping) { return }

      const currentSectionIdx = this.loopingSections.findIndex(section => {
        return section.name === this.currentSection.name
      })
      const nextSectionIdx = (currentSectionIdx + 1) % this.loopingSections.length
      const nextSection = this.loopingSections[nextSectionIdx]

      this.currentSection = nextSection

      clearTimeout(this.loopingTimer)
      this.loopingTimer = setTimeout(() => {
        clearTimeout(this.loopingTimer)
        this.playNextItem()
      }, (nextSection.duration || config.DEFAULT_INTERVAL) * 1000)
    },

    showSection (name) {
      if (this.currentSection) {
        if (this.currentSection.name === name) {
          return true
        }
        return false
      }
      return false
    }
  }
}
</script>

<template lang="pug">
.footer-slider(:class="{'standalone': !withTickertape}")
  transition(name="slideUp" mode="out-in" appear)
    .slide-item.clock(v-show="showSection('clock')" :class="cssClasses.clock")
      clock(show-date :hours="accountLocale.timeFormat", :timezone="accountLocale.timezone", :style="clockStyle" )
  transition(name="slideUp" mode="out-in" appear)
    .slide-item.weather(v-show="showSection('weather')" :class="cssClasses.weather")
      weather(v-if="location && location.length", :location="location" :style="weatherStyle")
</template>

<style lang="stylus">
.footer-slider
  position: absolute
  z-index: inherit
  top: 0
  left: 0
  bottom: 0
  right: 0
  overflow: hidden

  .slide-item
    position: absolute
    z-index: 2
    top: 0
    left: 0
    bottom: 0
    right: 0

    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    &,
    .clock,
    .weather
      &.horz-left
        align-items: flex-start
      &.horz-right
        align-items: flex-end
      &.horz-center
        align-items: center
      &.vert-top
        justify-content: flex-start
      &.vert-bottom
        justify-content: flex-end
      &.vert-center
        justify-content: center

  &.standalone
    .slide-item
      padding-right: 4vmin

      &,
      .simple-clock,
      .simple-weather
        align-items: flex-end
        text-align: right
</style>
