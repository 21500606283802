<script>
import { mapGetters } from 'vuex'

import ContentCache from 'services/contentCache.js'
import Log from 'services/log'
import { EventBus } from 'services/eventbus.js'

// In seconds. Fallback paginate duration if not set
const DEFAULT_PAGE_DURATION = 10

const PREPARE_NEXT_DELAY = 2000

export default {
  name: 'SlidesItem',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    showControls: {
      type: Boolean,
      default: false
    },
    // Indicator of embedding outside the Playlist Page,
    // E.g. viewing from Interactive Menu
    standalone: {
      type: Boolean,
      default: false
    },
    // Indicator of embedding in MediaZone (MediaFolder included)
    isMediaZone: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      index: 0,

      // Indicator to prevent running after calling beforeDestroy
      destroyed: false,

      showPrime: false,
      urlPrime: '',
      urlBase: '',

      isPaused: false,
      prepareTriggeredWhilePaused: false,
      manuallyNavigating: false,

      paginateTimer: undefined,
      prepareTimer: undefined
    }
  },

  computed: {
    ...mapGetters([
      'showItemsList'
    ]),

    location () {
      return this.item.url || this.item.location || ''
    },

    urls () {
      return this.location.split(',')
    },

    pageDuration () {
      return Math.max(4, (this.item.duration || +DEFAULT_PAGE_DURATION))
    },

    pageTransition () {
      if (this.item && this.item.transition && this.item.transition.length) {
        return `page-${this.item.transition}`
      }
      return 'page-fade'
    },

    backgroundImagePrime () {
      if (!this.urlPrime) { return }
      return {
        backgroundImage: `url(${this.urlPrime})`
      }
    },

    backgroundImageBase () {
      if (!this.urlBase) { return }
      return {
        backgroundImage: `url(${this.urlBase})`
      }
    },

    pageNum () {
      return this.index + 1
    },

    totalPageNum () {
      return this.urls.length
    }
  },

  watch: {
    location () {
      this.loadFirstSlide()
    }
  },

  mounted () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.paginateTimer)

    this.destroyed = false

    EventBus.$on('interact-menu-pause', this.menuAction)

    this.loadFirstSlide()

    this.$emit('duration', this.pageDuration * this.urls.length)
  },

  beforeDestroy () {
    clearTimeout(this.prepareTimer)
    clearTimeout(this.paginateTimer)

    if (this.isMediaZone) {
      // Fallback record for manually navigation
      this.$emit('play-ended')
    }

    EventBus.$off('interact-menu-pause', this.menuAction)

    this.destroyed = true
  },

  methods: {
    getUrlByIndex (index) {
      if (!Array.isArray(this.urls) || !this.urls.length) { return }
      let url = this.urls[index]
      if (!url) { return }
      if (ContentCache.isCdnUrl(url)) {
        url = ContentCache.addNoSwFlag(url)
      }
      return url
    },

    loadFirstSlide () {
      if (Array.isArray(this.urls) && this.urls.length > 0) {
        // Reset flag on content changed
        this.prepareTriggeredWhilePaused = false

        const url = this.getUrlByIndex(0)

        const img = new Image()
        img.onload = () => {
          let promise
          if (ContentCache.isTTVUploads(url)) {
            promise = ContentCache.cachedFetch(ContentCache.IMAGES, url, true)
          }

          if (promise) {
            promise.then(response => {
              if (response.ok) {
                this.setDisplayURL(url, true, true)

                // If the image has been cached by the browser, loading img never hit Service Worker, therefore we need
                // to manually inform our cache detection system.
                this.$emit('loaded')

                this.$store.commit('addCachedContentURL', {url, cacheName: ContentCache.IMAGES})
              }
            }).catch(err => {
              this.setDisplayURL(url, true, true)
              this.$emit('loaded')
              if (err.name && err.name.indexOf('NotInCache') >= 0) {
                err = 'fetch failed'
                Log.warn('media', `ContentCache.cachedFetch() error "${err.toString()}"`, 'WAR_CONTENTCACHEFETCHERR', { url: url, error: err })
              }
              this.$store.commit('removeCachedContentURL', {url, cacheName: ContentCache.IMAGES})
            })
          } else {
            this.setDisplayURL(url, true, true)
            this.$emit('loaded')
          }
        }
        img.onerror = (err) => {
          Log.debug('media', `Failed to load slide "${url}" - ${err.message || err.toString()}`, 'DBG_LOADSLIDE', err, true)
          this.$emit('error')
        }
        img.src = ContentCache.loadTriggerURI()

        this.setPageTimeout()

        this.prepareNext()
      }
    },

    loadSlide (index, isShowingPrime = false) {
      if (!Array.isArray(this.urls) || !this.urls.length) { return }
      const url = this.getUrlByIndex(index)
      if (!url) { return }

      const img = new Image()
      img.onload = () => {
        const isPrimeItem = !isShowingPrime
        let promise
        if (ContentCache.isTTVUploads(url)) {
          promise = ContentCache.cachedFetch(ContentCache.IMAGES, url, true)
        }
        if (promise) {
          promise.then(response => {
            if (response.ok) {
              this.setDisplayURL(url, isPrimeItem)
            }
          }).catch(err => {
            Log.debug('media', `Failed to load slide "${url}" - ${err.message || err.toString()}`, 'DBG_LOADSLIDE2', err, true)
            this.setDisplayURL(url, isPrimeItem)
          })
        } else {
          this.setDisplayURL(url, isPrimeItem)
        }
      }
      img.src = ContentCache.loadTriggerURI()
    },

    setDisplayURL (url, isPrime = false, forceReset = false) {
      const displayUrl = (url || '') + ''

      isPrime ? this.urlPrime = displayUrl : this.urlBase = displayUrl

      if (forceReset) {
        this.showPrime = true
      }
    },

    setPageTimeout () {
      clearTimeout(this.paginateTimer)
      this.paginateTimer = setTimeout(() => {
        clearTimeout(this.paginateTimer)
        if (this.destroyed) {
          // Prevent running on the background when the component already dismissed from the page.
          return
        }
        this.toNext(false)
      }, this.pageDuration * 1000)
    },

    // For next auto-paginate
    prepareNext () {
      clearTimeout(this.prepareTimer)
      this.prepareTimer = setTimeout(() => {
        clearTimeout(this.prepareTimer)

        if (this.destroyed) {
          // Prevent running on the background when the component already dismissed from the page.
          return
        }

        if (this.isPaused) {
          this.prepareTriggeredWhilePaused = true
          return
        }

        // Clear the url of the hidden slide
        this.showPrime ? this.urlBase = '' : this.urlPrime = ''

        const indexNext = (this.index + 1) % this.urls.length
        this.loadSlide(indexNext, this.showPrime)
      }, +PREPARE_NEXT_DELAY)
    },

    toPrevious () {
      if (!this.urls.length) { return }

      clearTimeout(this.prepareTimer)

      const nextIndex = ((this.index - 1) < 0 ? this.urls.length - 1 : this.index - 1) % this.urls.length

      // "toPrevious" is always manually triggered
      this.manuallyNavigating = true

      this.loadSlide(nextIndex, this.showPrime)

      this.$nextTick(() => {
        this.index = nextIndex
        this.showPrime = !this.showPrime

        if (nextIndex === 0) {
          // Re-send event to parent component for more aggresive load time checking
          // and trigger item playback report if needed
          this.$emit('loaded')
        }

        this.setPageTimeout()
        this.prepareNext()
      })
    },

    toNext (manually = false) {
      if (!this.urls.length) { return }

      clearTimeout(this.prepareTimer)

      let nextIndex = this.index + 1

      if (nextIndex >= this.urls.length && !manually && !this.manuallyNavigating) {
        this.$emit('play-ended')
        // Reach the end of slides
        this.$emit('finished', true)
      }

      nextIndex = nextIndex % this.urls.length

      if (manually) {
        this.manuallyNavigating = true
        this.loadSlide(nextIndex, this.showPrime)
      } else if (this.manuallyNavigating) {
        // Reset the manually navigating flag if this time is auto pagination
        this.manuallyNavigating = false
      }

      this.$nextTick(() => {
        this.index = nextIndex
        this.showPrime = !this.showPrime

        if (nextIndex === 0) {
          // Re-send event to parent component for more aggresive load time checking
          // and trigger item playback report if needed
          this.$emit('loaded')
        }

        this.setPageTimeout()
        this.prepareNext()
      })
    },

    // Interactive Menu related [DEV-3409]
    // When viewing interactive MediaFolder grid list
    // Pause the current playback slides, if any
    menuAction (toPause = false) {
      if (!this.showItemsList || this.standalone) { return }
      // To pause
      if (toPause && !this.isPaused) {
        this.isPaused = true
      // To play
      } else if (!toPause && this.isPaused) {
        this.isPaused = false
        if (this.prepareTriggeredWhilePaused) {
          this.prepareTriggeredWhilePaused = false
          this.prepareNext()
        }
      }
    }
  }
}
</script>

<template lang="pug">
section.slides-page
  transition(:name="pageTransition" mode="out-in" appear)
    .slide-container(v-if="showPrime", :style="backgroundImagePrime")
  transition(:name="pageTransition" mode="out-in" appear)
    .slide-container(v-if="!showPrime", :style="backgroundImageBase")

  .controls-layer(v-if="showControls")
    .nav-btn.prev-btn(@click="toPrevious")
      fa.fa-icon(icon="chevron-left")
    .nav-btn.next-btn(@click="toNext(true)")
      fa.fa-icon(icon="chevron-right")
    .page-number {{ pageNum }} / {{ totalPageNum }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section.slides-page
  width: 100%
  height: 100%
  position: absolute
  z-index: 0

  .slide-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    background-position: 50% 50%
    background-size: contain
    background-repeat: no-repeat

  .controls-layer
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 10
    padding: 0 1.5vmin
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    pointer-events: none
    .nav-btn
      pointer-events: initial
      font-size: 3vmin
      cursor: pointer
      &:active
        opacity: 0.7
        outline: 0
        -webkit-tap-highlight-color: transparent
      .fa-icon
        color: #fff
        appIconShadow()

    .page-number
      position: absolute
      bottom: 1.5vmin
      left: 1.5vmin
      font-size: 1.5vmin
      color: #fff
      appTextShadow()
</style>
