<script>
import qs from 'qs'
import { mapGetters, mapState } from 'vuex'

import InputSearch from 'components/common/InputSearch'
import Spinner from 'components/common/Spinner'
import ImageItem from 'components/items/ImageItem.vue'
import MediaItem from 'components/common/MediaItem.vue'

import Log from 'services/log.js'
import Media from 'services/media.js'
import OfflineCaches from 'services/offline-caches'
import { EventBus } from 'services/eventbus.js'

const config = {
  // Default thumbnail size (in px)
  THUMB_DEFAULT_WIDTH: 168,
  THUMB_DEFAULT_HEIGHT: 96,

  // Default thumbnail scale
  THUMB_DEFAULT_SCALE: 1.0,

  // Default item name font size (in em)
  THUMB_NAME_DEFAULT_EM: 0.8,
  // Make item name scale less than thumbnail
  NAME_SIZE_SCALE: 0.75,

  // In ms. Fallback for cases when Player start offline -- WS never activated
  GET_TIMEOUT: 5000,

  PER_PAGE: 100
}

export default {
  name: 'InteractiveGridList',
  components: {
    InputSearch,
    Spinner,
    ImageItem,
    MediaItem
  },

  props: {
    page: {
      type: Object,
      default: () => { return {} }
    },

    kioskNavigationMode: {
      type: Boolean,
      default: false
    },

    // The base folder defined by the Navigation app
    folder: {
      type: Object,
      default: () => { return {} }
    },

    folderOptions: {
      type: Object,
      default: () => { return {} }
    }
  },

  data () {
    return {
      list: [],

      query: '',

      currentFolderId: '',

      loading: true,
      waitForFinished: false,

      perPage: +config.PER_PAGE,
      pageNum: 1,
      totalItems: 0,
      received: 0,

      staledListRendered: false,
      errorMessage: '',

      debounceTimer: null,
      timeoutTimer: null
    }
  },

  computed: {
    ...mapState({
      viewingItem: s => s.interactions.viewingItem,
      folders: s => s.media.folders || []
    }),
    ...mapGetters([
      'folderData',
      'getSubFolders',
      'isDevice',
      'deviceTagsSorted',
      'apiIsOffline',
      'isViewingItem'
    ]),

    thumbSizeScale () {
      return (this.folderOptions && this.folderOptions.thumbnailSize) || +config.THUMB_DEFAULT_SCALE
    },

    thumbWidth () {
      return { width: `${config.THUMB_DEFAULT_WIDTH * this.thumbSizeScale}px` }
    },

    thumbHeight () {
      return { height: `${config.THUMB_DEFAULT_HEIGHT * this.thumbSizeScale}px` }
    },

    folderIconSize () {
      return { fontSize: `${config.THUMB_DEFAULT_HEIGHT * this.thumbSizeScale}px` }
    },

    itemNameFontSize () {
      const scale = +this.thumbSizeScale > 1 ? this.thumbSizeScale * config.NAME_SIZE_SCALE : 1.0
      return { fontSize: `${config.THUMB_NAME_DEFAULT_EM * scale}em` }
    },

    hasBgImg () {
      return Boolean(this.kioskNavigationMode && this.folderOptions.folderBg && this.folderOptions.folderBg.length)
    },

    folderBgOptions () {
      if (!this.hasBgImg) { return }
      return {
        url: this.folderOptions.folderBg
      }
    },

    showSubfolders () {
      return (this.kioskNavigationMode && this.folderOptions.showSubfolders)
    },

    pageItems () {
      return (this.page && this.page.items) || []
    },

    localKeyBase () {
      const id = this.kioskNavigationMode ? this.folder.id : this.interactiveZone.ref_id
      return id ? `ttvMediaFolder_FullList_${id}` : null
    },

    // Interactive Menu: Support only one interactive zone in this early phase
    interactiveZone () {
      const result = this.pageItems.find(item => {
        return (
          item.type === 'media_folder' &&
          item.url.indexOf('interactive_mode=true') >= 0
        )
      })
      return result
    },

    zoneType () {
      if (this.interactiveZone && this.interactiveZone.type) {
        return this.interactiveZone.type
      }
      return ''
    },

    isValidForDisplay () {
      return (this.kioskNavigationMode && this.folder && this.folder.id) ||
        (!this.kioskNavigationMode && this.zoneType === 'media_folder')
    },

    isEmptyList () {
      return this.loading || (!this.loading && !this.actvieFolderItems.length)
    },

    // Filter out items from subfolders in Kiosk Navigation mode
    actvieFolderItems () {
      if (!this.kioskNavigationMode) { return this.list }
      return this.list.filter(item => {
        const folderId = this.currentFolderId || this.folder.id
        return item.content_folder_id === folderId
      })
    },

    // Media files
    filteredList () {
      if (!this.list.length) {
        return []
      }
      if (!this.query || !this.query.length) {
        return this.actvieFolderItems
      }

      return this.actvieFolderItems.filter(item => {
        const query = this.query.toLowerCase()
        if (
          (item.name || '').toLowerCase().includes(query) ||
          item.content_type.includes(query) ||
          (item.canva && 'canva'.includes(query))
        ) {
          return true
        }
        return false
      })
    },

    currentFolder () {
      if (!this.kioskNavigationMode) { return {} }
      if (this.currentFolderId && this.currentFolderId.length) {
        return this.folderData(this.currentFolderId)
      }
      return this.folder
    },

    subFolders () {
      if (!this.showSubfolders || !this.folders.length || !this.currentFolder || !this.currentFolder.id) { return [] }
      const folders = this.getSubFolders(this.currentFolder.id)
      return folders.sort((a, b) => {
        return (a.name || '').localeCompare(b.name || '')
      })
    },

    parentFolder () {
      if (!this.showSubfolders || !this.currentFolder || !this.currentFolder.id) { return }
      if (this.currentFolder.id === this.folder.id || !this.currentFolder.parent_id) { return }
      return this.folderData(this.currentFolder.parent_id)
    }
  },

  mounted () {
    clearTimeout(this.timeoutTimer)
    this.debounceRender()

    EventBus.$on('interact-menu-next-item', this.goToNext)
    EventBus.$on('interact-menu-prev-item', this.goToPrev)
  },

  beforeDestroy () {
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.debounceTimer)

    EventBus.$off('interact-menu-next-item', this.goToNext)
    EventBus.$off('interact-menu-prev-item', this.goToPrev)
  },

  methods: {
    render () {
      if (this.isValidForDisplay) {
        this.getMediaFolderItems()
      }
    },

    debounceRender () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.render()
      }, 200)
    },

    itemIsCached (item) {
      return Media.itemIsCached(item)
    },

    thumbStyle (width, height) {
      // landscape
      if (width >= height) {
        return {
          width: `${config.THUMB_DEFAULT_WIDTH * this.thumbSizeScale}px`,
          height: `${config.THUMB_DEFAULT_HEIGHT* this.thumbSizeScale}px`
        }
      }
      // portrait
      return {
          width: `${config.THUMB_DEFAULT_HEIGHT * this.thumbSizeScale}px`,
          height: `${config.THUMB_DEFAULT_WIDTH * this.thumbSizeScale}px`
      }
    },

    getMediaFolderItems () {
      if (!this.isValidForDisplay) { return }

      this.staledListRendered = false
      // Speed up display when device is offline
      if (this.apiIsOffline) {
        this.getStaleList(true)
      }

      const fetchOptions = this.genFetchOptions()

      // Start from the beginning
      this.pageNum = 1
      fetchOptions.page = +this.pageNum
      this.list = []
      this.loading = true

      this.getMediaFolderByPage(fetchOptions)
    },

    genFetchOptions () {
      const args = this.kioskNavigationMode ? this.folder.url : this.interactiveZone.url
      const options = qs.parse(decodeURIComponent(args || ''))
      const fetchOptions = {
        per_page: +this.perPage,
        folder_id: options.folder_id || this.interactiveZone.ref_id || '',
        sub_folders: options.sub_folders === 'true'
      }

      // For consistency with the localKey generated from "MediaFolder.vue"
      if (options.randomize === 'true') {
        fetchOptions.randomize = true
      }

      if (this.isDevice && options.match_device_tag === 'true') {
        fetchOptions.match_tags = true
        if (this.deviceTagsSorted && this.deviceTagsSorted.length > 0) {
          fetchOptions.tags = this.deviceTagsSorted
        } else {
          fetchOptions.tags = []
        }
      }

      return fetchOptions
    },

    getMediaFolderByPage (fetchOptions = {}, isGettingNextPage = false) {
      if (!fetchOptions || !fetchOptions.folder_id) { return }

      this.timeoutChecking()

      this.$store.dispatch('getMediaByFolderId', fetchOptions).then(result => {
        clearTimeout(this.timeoutTimer)
        this.renderFolderList(result, fetchOptions, isGettingNextPage)
      }).catch(err => {
        clearTimeout(this.timeoutTimer)
        const errorMessage = err.message || err.toString() || ''
        Log.info('media', `Media Folder error: ${errorMessage}`, 'INF_MEDIAFOLDERERR3', err)
        this.errorMessage = `Media Folder Error - ${errorMessage}`

        this.getStaleList()
      })
    },

    renderFolderList (result, fetchOptions, isGettingNextPage = false) {
      const totalItems = result.total || 0
      const resultList = result.contents || []
      const count = (Array.isArray(resultList) && resultList.length) || 0

      // Filter out items by valid time [DEV-3984]
      const validList = Media.filterByValidTime(resultList)

      let endOfList = false
      if (count === 0) {
        endOfList = true
      } else {
        this.errorMessage = ''
      }

      if (this.pageNum > 1) {
        this.received = (this.perPage * (this.pageNum - 1)) + count
      } else {
        this.received = count
      }

      this.totalItems = totalItems

      if (this.received >= this.totalItems) {
        endOfList = true
      }

      const mediaList = JSON.parse(JSON.stringify(validList))
      const list = isGettingNextPage ? [].concat([], this.list, mediaList) : mediaList
      this.list = JSON.parse(JSON.stringify(list))

      try {
        const localKey = this.genLocalKey()
        if (localKey) {
          OfflineCaches.set(localKey, list)
        }
      } catch (err) {}

      if (endOfList) {
        this.loading = false
        this.loaded()
      } else {
        // Fetch next page
        const currentPage = fetchOptions.page || 1
        const nextPage = currentPage + 1
        fetchOptions.page = nextPage
        this.pageNum = nextPage
        this.getMediaFolderByPage(fetchOptions, true)
      }
    },

    selectItem (item) {
      this.userInteracted()
      this.$store.commit('setViewingItem', item)
    },

    backToGridList () {
      this.userInteracted()
      this.clearItemViewingState()
    },

    goToFolder (folder = {}) {
      if (!folder.id) { return }
      if (folder.id === this.folder.id) {
        this.currentFolderId = ''
      } else {
        this.currentFolderId = folder.id
      }
    },

    goToNext () {
      if (this.isEmptyList || !this.isViewingItem || this.actvieFolderItems.length === 1) { return }
      const currentID = this.viewingItem.id
      const currentIndex = this.actvieFolderItems.findIndex(item => item.id === currentID)

      let nextIndex
      if (currentIndex === -1) {
        // Somehow, current item is not found in the list anymore. Start from the beginning
        nextIndex = 0
      } else {
        nextIndex = (currentIndex + 1) % this.actvieFolderItems.length
      }

      const nextItem = JSON.parse(JSON.stringify(this.actvieFolderItems[nextIndex]))
      this.selectItem(nextItem)
    },

    goToPrev () {
      if (this.isEmptyList || !this.isViewingItem || this.actvieFolderItems.length === 1) { return }
      const currentID = this.viewingItem.id
      const currentIndex = this.actvieFolderItems.findIndex(item => item.id === currentID)

      let nextIndex
      if (currentIndex === -1) {
        // Somehow, current item is not found in the list anymore. Start from the beginning
        nextIndex = 0
      } else {
        if (currentIndex === 0) {
          nextIndex = this.actvieFolderItems.length - 1
        } else {
          nextIndex = currentIndex - 1
        }
      }

      const nextItem = JSON.parse(JSON.stringify(this.actvieFolderItems[nextIndex]))
      this.selectItem(nextItem)
    },

    userInteracted () {
      if (this.kioskNavigationMode) { return }
      EventBus.$emit('user-interacted')
    },

    itemLoaded (mediaType) {
      // Wait for Video/Slides ended before auto-resume playback
      const waitForFinished = (mediaType === 'video' || mediaType === 'slides')

      if (mediaType !== 'video') {
        this.$store.commit('updateViewingItemPaused', false)
      }

      if (this.kioskNavigationMode) {
        this.$store.commit('updateWaitForFolderItem', waitForFinished)
      } else {
        this.$store.commit('updateWaitForItemFinished', waitForFinished)
      }
    },

    clearItemViewingState () {
      this.$store.commit('clearViewingItem')

      // Navigation app
      if (this.kioskNavigationMode) {
        this.$store.commit('updateWaitForFolderItem', false)
      // Interactive Menu
      } else {
        this.$store.commit('updateWaitForItemFinished', false)
      }

      this.togglePauseState(false)
    },

    togglePauseState (toTrue = false) {
      this.$store.commit('updateViewingItemPaused', toTrue)
    },

    itemFinished () {
      this.clearItemViewingState()
    },

    genLocalKey () {
      if (!this.localKeyBase) { return }

      const fetchOptions = this.genFetchOptions()

      delete fetchOptions.folder_id
      delete fetchOptions.per_page
      delete fetchOptions.page

      const optionsStr = qs.stringify(fetchOptions)
      if (optionsStr && optionsStr.length) {
        return `${this.localKeyBase}_${optionsStr}`
      }

      return this.localKeyBase
    },

    async getStaleList (init = false) {
      const localKey = this.genLocalKey()
      if (localKey) {
        const staleList = await OfflineCaches.get(localKey)
        if (staleList && staleList.length) {
          if (init) {
            clearTimeout(this.timeoutTimer)
            this.staledListRendered = true
          }

          this.list = staleList
          this.loading = false
          this.errorMessage = ''
          this.loaded()
          return
        }
      }
      if (!this.errorMessage) {
        const errorMessage = 'Cannot get Media Folder data when device is offline'
        Log.info('media', errorMessage, 'INF_MEDIAFOLDERDATAERR')
        this.errorMessage = errorMessage
      }
      this.list = []
      this.loading = false
      this.loaded()
    },

    // Fallbak for cases when Player starting offline
    timeoutChecking () {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        clearTimeout(this.timeoutTimer)
        const errorMessage = `Media Folder fetching ${config.GET_TIMEOUT}ms timeout`
        Log.info('media', `Media Folder error: ${errorMessage}`, 'INF_MEDIAFOLDERERR4')
        this.errorMessage = `Media Folder Error - ${errorMessage}`

        // When device is offline, the timeout checking sometimes comes before GET error
        if (!this.staledListRendered) {
          this.getStaleList()
        }
      }, config.GET_TIMEOUT)
    },

    loaded () {
      if (this.kioskNavigationMode) {
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.interactive-grid-list
  image-item.folder-bg(v-if="hasBgImg" :item="folderBgOptions")

  header(:class="{'kiosk-navigation-mode': kioskNavigationMode}")
    h3.zone-title
      template(v-if="kioskNavigationMode") {{ currentFolder.name }}
      template(v-else) {{ page && page.description }} &raquo; {{ interactiveZone && interactiveZone.description }}
    .search-wrapper(@click="userInteracted")
      input-search(v-model="query" placeholder="SEARCH" @input="userInteracted")

  .item-grid-list
    .loading-mask(v-if="loading")
      spinner(size="8em")

    ul.grids-list(v-if="!loading")
      //- Parent Folder ~ Subfolder navigation
      template(v-if="kioskNavigationMode")
        li.grid-list-item(v-if="parentFolder && parentFolder.id"
                          :style="thumbWidth"
                          @click="goToFolder(parentFolder)")
          .folder-icon(:style="[thumbWidth, thumbHeight, folderIconSize]")
            fa.fa-icon(icon="folder-tree" fixed-width)
          .item-name(:style="itemNameFontSize", :title="parentFolder.name") ..

        template(v-if="subFolders && subFolders.length")
          li.grid-list-item(v-for="folder in subFolders", :key="folder.id"
                            :style="thumbWidth"
                            @click="goToFolder(folder)")
            .folder-icon(:style="[thumbWidth, thumbHeight, folderIconSize]")
              fa.fa-icon.sub-folder(icon="folder" fixed-width)
            .item-name(:style="itemNameFontSize", :title="folder.name") {{ folder.name }}

      li.grid-list-item(v-for="item in filteredList", :key="item.id"
                        :style="thumbWidth"
                        @click="selectItem(item)")

        //- NOTE: Bind `key` with image url to force lazyload update when url updates
        //- https://github.com/hilongjw/vue-lazyload/issues/273
        .thumbnail(v-lazy:background-image="item.thumb_url", :key="item.thumb_url"
                   :style="thumbStyle(item.width, item.height)")

        .item-name(:style="itemNameFontSize", :title="item.name")
          fa.fa-icon(v-if="!itemIsCached(item)", :icon="['far', 'cloud']" fixed-width title="Item is not cached")
          | {{ item.name }}

    template(v-if="!loading")
      p.no-items-found(v-if="isEmptyList")
        template(v-if="errorMessage && errorMessage.length") {{ errorMessage }}
        template(v-else) This folder is empty

      p.no-items-found(v-else-if="query.length && !filteredList.length")
        | No content found with this query

  transition(name="fade", :duration="200" appear)
    .item-playback-zone(v-if="isViewingItem")
      .item-name {{ viewingItem.name }}
      .close-button
        fa.fa-icon(icon="times" @click.stop="backToGridList")

      media-item(:item="viewingItem"
                :show-controls="kioskNavigationMode"
                 @loaded="itemLoaded"
                 @is-paused="togglePauseState"
                 @finished="itemFinished")
</template>

<style lang="stylus">
@import '../../style/mixins.styl';

section.interactive-grid-list
  font-size: 2vmin
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1

  color: -white(0.85)

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  overflow: hidden

  .folder-bg
    z-index: 1

  header
    position: relative
    z-index: 5
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    padding: 1em 1.5em

    .zone-title
      flex: 1 1 0.00001px
      font-size: 1.5em
      padding: 0
      margin: 0

    .search-wrapper
      input
        width: 15em

    &.kiosk-navigation-mode
      // Leave some space for the "Close" button
      padding-right: 7.5vmin

  .item-grid-list
    position: relative
    z-index: 5
    flex: 1 1 0.00001px
    padding: 0.5em 1.5em
    overflow-x: hidden
    overflow-y: auto

    scrollBarTransparentBlack()

    > .loading-mask
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 0
      display: flex
      flex-flow: column nowrap
      justify-content: center
      align-items: center

    ul.grids-list
      list-style: none
      padding: 0
      margin: 0 -1em 0 0
      display: flex
      flex-flow: row wrap
      justify-content: flex-start
      align-items: center
      align-content: center

      li.grid-list-item
        list-style: none
        margin-right: 2.5em
        margin-bottom: 2em
        display: inline-block
        text-align: center
        cursor: pointer

        &:active
          opacity: 0.7

        .thumbnail
          background-color: #111
          background-repeat: no-repeat
          background-position: 50% 50%
          background-size: cover

        .folder-icon
          display: flex
          flex-flow: column nowrap
          align-items: center
          justify-content: center
          .fa-icon.sub-folder
            font-size: 1.2em

        .item-name
          .fa-icon
            font-size: 0.8em
            margin-right: 0.8em
            opacity: 0.4

          padding-top: 0.5em
          line-height: 130%
          ellipsis()

  .no-items-found
    line-height: 150%
    text-align: center
    opacity: 0.5
    padding: 1em 0

  .item-playback-zone
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: #222
    z-index: 1000
    animation-duration: 200ms

    .item-name:not(:empty)
      position: absolute
      z-index: 1005
      top: 1.2vmin
      left: 1.2vmin
      background: -black(0.5)
      color: #fff
      border: 1px solid -white(0.35)
      border-radius: 3em
      padding: 0.5em 1.5em
      font-size: 1.5vmin

    .close-button
      position: absolute
      top: 1.2vmin
      right: 1.2vmin
      font-size: 3.5vmin
      line-height: 100%
      cursor: pointer
      z-index: 1005
      outline: 0
      &:active
        opacity: 0.7
        outline: 0
        -webkit-tap-highlight-color: transparent
      .fa-icon
        color: #fff
        appIconShadow()
</style>
