<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import QRCode from 'qrcode'

import Log from 'services/log.js'

import ResponsiveMarkupText from 'components/widgets/ResponsiveMarkupText.vue'

const config = {
  // In vmin
  BASE_FONTSIZE: 5,
  IS_WIDE: 4,
  BASE_CONTACT_INFORMATION_FONTSIZE: 6
}

export default {
  name: 'ContactPage',

  components: {
    ResponsiveMarkupText
  },
  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [AppCommonMixins],

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      title: '',
      link: '',
      twitter: '',
      facebook: '',
      instagram: '',
      phone: '',
      address: '',
      qrCode: '',

      vAlign: 'middle',
      hAlign: 'left',

      // Generated QR Code DataURI
      qrCodeDataURI: ''
    }
  },

  computed: {
    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    hasSnsBlock () {
      return this.countValidItems(['twitter', 'facebook', 'instagram']) > 0
    },

    putQRCodeInSidebar () {
      if (!this.hasValue(this.qrCode)) { return false }
      const validItemsCount = this.countValidItems(['title', 'phone', 'link', 'address'])
      return Boolean(
        (this.hasSnsBlock && validItemsCount >= 2) ||
        (!this.hasSnsBlock && validItemsCount >= 3)
      )
    },

    putSnsInSidebar () {
      if (!this.hasSnsBlock) {
        return false
      }
      return this.countValidItems(['title', 'phone', 'link', 'address']) >= 3
    },

    hideSecondaryZone () {
      return !this.putQRCodeInSidebar && !this.putSnsInSidebar
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    },

    displayLink () {
      if (!this.hasValue(this.link)) { return }
      // Remove the `http(s)://`
      return this.link.replace(/http(s)?:\/\//, '').trim()
    },

    isWide () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h > config.IS_WIDE
      }
      return false
    },

    wideRatio () {
      if (this.itemSize && this.itemSize.w && this.itemSize.h) {
        return this.itemSize.w / this.itemSize.h
      }
      return 1
    },

    contactInformationStyle () {
      if (this.isWide && this.wideRatio > 1) {
        return {fontSize: config.BASE_CONTACT_INFORMATION_FONTSIZE / this.wideRatio + 'em'}
      }

      return {}
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)

    this.debounceCheckSize()
    this.render()

    this.$emit('loaded')
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      const options = qs.parse(decodeURIComponent(this.item.url || ''))

      this.title = options.title || ''
      this.link = options.link || ''
      this.twitter = options.twitter || ''
      this.facebook = options.facebook || ''
      this.instagram = options.instagram || ''
      this.phone = options.phone || ''
      this.address = options.address || ''

      this.qrCode = options.qrCode || ''

      this.vAlign = options.vAlign || 'middle'
      this.hAlign = options.hAlign || 'left'

      this.generateQRCode()
    },

    hasValue (input) {
      return Boolean(input && (input || '').trim().length)
    },

    countValidItems (itemList) {
      if (!itemList || !itemList.length) { return 0 }
      let count = 0
      itemList.forEach((itemName) => {
        if (this.hasValue(this[itemName])) {
          count++
        }
      })
      return count
    },

    generateQRCode () {
      if (!this.hasValue(this.qrCode)) {
        this.qrCodeDataURI = ''
        return
      }
      // QR Code Options
      // - https://github.com/soldair/node-qrcode#options
      QRCode.toDataURL(this.qrCode, {
        margin: 2,
        scale: 16
      }).then(result => {
        this.qrCodeDataURI = result || ''
      }).catch(err => {
        this.qrCodeDataURI = ''
        Log.debug('app', `Unable to generate QR Code - ${err.message || err.toString()}`, 'DBG_GENERATEQR', { url: this.qrCode })
      })
    }
  }
}
</script>

<template lang="pug">
mixin snsBlocks
  .sns-block.twitter(v-if="hasValue(twitter)")
    fa.fa-icon(:icon="['fab', 'twitter']" fixed-width)
    | @{{ twitter }}
  .sns-block.facebook(v-if="hasValue(facebook)")
    fa.fa-icon(:icon="['fab', 'facebook']" fixed-width)
    | @{{ facebook }}
  .sns-block.instagram(v-if="hasValue(instagram)")
    fa.fa-icon(:icon="['fab', 'instagram']" fixed-width)
    | @{{ instagram }}

section.contact-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWide}]" :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .contact-information.app-context-block(:class="[fontClass, {'dark-text': !whiteText, 'show-text-shadow': showTextShadow, 'uninitialized': !baseFontSize, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}]" :style="{...boxMarginStyle, ...contactInformationStyle}")
    .main-section.app-context-section.primary(:class="[horzClass, vertClass, {'has-qrcode': !putQRCodeInSidebar && hasValue(qrCodeDataURI)}]")
      .contact-title(v-if="hasValue(title)")
        responsive-markup-text(
          :text="title"
          :base-font-size="baseFontSize"
          :margin-vert="0"
        )
      .main-contents(:class="[{'no-title': !hasValue(title)}, vertClass]")
        .phone-number(v-if="hasValue(phone)") {{ phone }}
        .address(v-if="hasValue(address)") {{ address }}
        .qrcode-wrapper(v-if="!putQRCodeInSidebar && hasValue(qrCodeDataURI)")
          img.qrcode(:src="qrCodeDataURI")
        .sns-wrapper(v-if="!putSnsInSidebar && hasSnsBlock", :class="[horzClass]")
          +snsBlocks
        .link(v-if="hasValue(displayLink)") {{ displayLink }}

    .secondary-section.app-context-section.secondary(v-if="!hideSecondaryZone", :class="[horzClass, vertClass, {'border-left': !isPortrait, 'border-top': isPortrait}]")
      .qrcode-wrapper(v-if="putQRCodeInSidebar && hasValue(qrCodeDataURI)")
        img.qrcode(:src="qrCodeDataURI")
      .sns-wrapper(v-if="putSnsInSidebar", :class="[horzClass]")
        +snsBlocks

</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.contact-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .contact-information
    position: relative
    flex: 1 1 0.00001px
    overflow: hidden
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: stretch

    .main-section
      flex: 1 1 0.00001px
      padding: 0.5em 1em

    .secondary-section
      font-size: 0.8em
      padding: 1em
      min-width: 20%

    .contact-title
      font-weight: bold
      line-height: 1.2
      padding-bottom: 0.5em
      position: relative
      width: 100%
      flex: 1 1 0.00001px
      max-height: 30%

    .main-contents
      > div
        padding-top: 0.6em
        &:first-of-type
          padding-top: 0

    .phone-number
    .address,
    .link
      line-height: 130%

    .address
      white-space: pre

    .phone-number
      font-size: 1.3em

    .link
      opacity: 0.6

    .sns-block
      display: flex
      flex-flow: row
      align-items: center
      word-break: break-word
      .fa-icon
        opacity: 0.6
        margin-right: 0.3em

    img.qrcode
      display: inline-block
      vertical-align: top
      width: 8em
      height: auto

    .secondary-section
      .qrcode-wrapper
        margin-bottom: 1em
        &:last-child
          margin-bottom: 0
      .sns-wrapper
        display: flex
        flex-flow: column nowrap
        align-items: center
        &.horz-left,
        &.horz-justify
          align-items: flex-start
        &.horz-right
          align-items: flex-end

    .main-contents
      &.no-title
        flex: 1 1 0.00001px
        display: flex
        flex-flow: column nowrap
        justify-content: center
        &.vert-justify
          justify-content: space-between
        &.vert-top
          justify-content: flex-start
        &.vert-bottom
          justify-content: flex-end

  //
  // Landscape Layout
  //
  &.landscape
    .contact-information
      .main-section
        &.has-qrcode
          .sns-wrapper
            display: flex
            flex-flow: row wrap
            align-items: center
            justify-content: center
            .sns-block
              margin: 0 0.5em
              &:first-of-type
                margin-left: 0 !important
              &:last-of-type
                margin-right: 0 !important
            &.horz-left
              justify-content: flex-start
              .sns-block
                margin: 0 1em 0 0
            &.horz-right
              justify-content: flex-end
              .sns-block
                margin: 0 0 0 1em
            &.horz-justify
              justify-content: space-between
              .sns-block
                margin: 0
      .secondary-section
        &.horz-justify
          .qrcode-wrapper
            text-align: right

  //
  // Portrait Layout
  //
  &.is-portrait
    .contact-information
      flex-flow: column nowrap

      .main-section
        padding: 1em

        .sns-wrapper
          display: flex
          flex-flow: column nowrap
          align-items: center
          &.horz-left,
          &.horz-justify
            align-items: flex-start
          &.horz-right
            align-items: flex-end

      .secondary-section
        min-width: 0
        padding: 1em 1.3em

  //
  // Wide Layout
  //
  &.is-wide
    .contact-information
      font-size: 0.8em
      .main-section
        padding: 1em
        font-size: 0.8em
        flex-direction: row
        justify-content: flex-start

        .contact-title
          margin-right: 1em
          max-width: 50%
          word-break: break-word
          max-height: none
          height: 100%

        .main-contents
          font-size: 0.9em

      .secondary-section
        display: flex

        .qrcode-wrapper
          font-size: 0.6em
        .sns-wrapper
          font-size: 0.8em
</style>
