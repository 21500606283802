<script>
import FastDom from 'fastdom'
import { mapState, mapGetters } from 'vuex'

import VersionRibbon from 'components/common/VersionRibbon.vue'
import Console from 'components/common/Console.vue'
import MessageBanner from 'components/common/MessageBanner.vue'
import Player from 'components/player/Player.vue'
import Titlebar from './Titlebar.vue'
import Overview from './Overview.vue'

import Log from 'services/log.js'
import ActiveWindow from 'services/active-window.js'
import Environment from 'services/environment'
import BrowserStorage from 'services/browserstorage.js'

const config = {
  // In px
  TOPBAR_HEIGHT: 40,
  SIDEBAR_WIDTH: 300,
  OVERVIEW_HEIGHT: 150,

  // In px. For padding between sections in Debug/Console mode
  MARGIN_HORZ: 10,
  PADDING_HORZ: 20,
  PADDING_VERT: 10
}

export default {
  name: 'Run',

  components: { VersionRibbon, Console, MessageBanner, Player, Titlebar, Overview },

  data () {
    return {
      // CSS Hack `vmin` in console mode
      winSize: {},

      debounceTimer: undefined,
      activeHrTimer: undefined
    }
  },

  computed: {
    ...mapState({
      debugMode: s => s.playbackmode.debugMode,
      billingPlanExpired: state => state.account.billingPlanExpired || false
    }),
    ...mapGetters([
      'apiStatus',
      'hasAuthToken',
      'isDevice',
      'device',
      'swapHeightAndWidth',
      'isShowingPage',
      'deviceInWebappMode',
      'deviceWebapp',
      'deviceDisabled',
      'localDeviceDisabed',
      'deviceActiveHours',
      'deviceActiveWindows',
      'isOverridingByPlaylist'
    ]),

    viewportSize () {
      if (this.swapHeightAndWidth) {
        return {
          w: +(this.winSize.h || 0),
          h: +(this.winSize.w || 0)
        }
      }
      return this.winSize
    },

    isPortrait () {
      if (!this.viewportSize || !this.viewportSize.h || !this.viewportSize.w) { return false }
      return this.viewportSize.h > this.viewportSize.w
    },

    previewScale () {
      if (!this.debugMode || !this.viewportSize || !this.viewportSize.h || !this.viewportSize.w) { return }
      const availableWidth = this.viewportSize.w - config.SIDEBAR_WIDTH - config.PADDING_HORZ - config.MARGIN_HORZ * 2
      const availableHeight = this.viewportSize.h - config.TOPBAR_HEIGHT - config.OVERVIEW_HEIGHT - config.PADDING_VERT

      return Math.min(availableWidth / this.viewportSize.w, availableHeight / this.viewportSize.h)
    },

    previewTransformStyle () {
      if (!this.debugMode || !this.viewportSize || !this.viewportSize.h || !this.viewportSize.w || !this.previewScale) { return }
      return {
        transform: `translate3d(10px, ${config.TOPBAR_HEIGHT + config.PADDING_VERT}px, 0) scale(${this.previewScale}, ${this.previewScale})`
      }
    },

    routeQuery () {
      return this.$route.query || {}
    },

    overviewPlaceholder () {
      if (!this.deviceInWebappMode) { return '' }
      let text = this.$t('webapp.overviewMessage')
      if (this.deviceWebapp && this.deviceWebapp.id) {
        if (this.deviceWebapp.name && this.deviceWebapp.name.length) {
          text += ` "${this.deviceWebapp.name}" (${this.deviceWebapp.id})`
        } else {
          text += ` ${this.deviceWebapp.id}`
        }
      }
      return text
    }
  },

  watch: {
    apiStatus (status) {
      if (status === 'active') {
        // Don't redirect to "/starting" route when the Player is already showing the content [DEV-3918]
        if (!this.isShowingPage && !this.deviceInWebappMode) {
          this.$router.push({ name: 'starting' })
        }
      }
    },

    debugMode () {
      this.debounceCheckSize(50)
    },

    deviceDisabled (toDisabled) {
      this.handleDeviceDisabled(toDisabled)
    },

    billingPlanExpired (expired) {
      this.handleBillingPlanExpired(expired)
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearInterval(this.activeHrTimer)
    this.debounceCheckSize()

    window.addEventListener('resize', this.debounceCheckSize)

    if (this.billingPlanExpired) {
      this.handleBillingPlanExpired(true)
      return
    }

    if (this.deviceDisabled || this.localDeviceDisabed) {
      this.handleDeviceDisabled(true)
      return
    }

    this.checkActiveHours()
    this.activeHrTimer = setInterval(() => {
      this.checkActiveHours()
    }, 1000)

    // This one is show on message banner (toast) only, with translated language [DEV-3377]
    if (Environment.invalidEmbed) {
      Log.addToHistory('error', this.$t('versionsControl.invalidEmbed'))
    }

    // Use the "$nextTick" to resolve event racing when landing page is `/run` (not `/`)
    this.$nextTick(() => {
      // No AUTH token found from localStorage
      if (!this.hasAuthToken) {
        const provisioningToken = this.routeQuery.provToken || ''

        // But found provisioning token from the URL
        // -> Fallback for Windows Kiosk mode soft reload [DEV-4248]
        if (provisioningToken && provisioningToken.length) {
          this.$router.push({
            name: 'provisioningWithToken',
            params: {
              provisioningToken
            },
            query: {
              'unique-asset-id': this.routeQuery.uniqueAssetId,
              name: this.routeQuery.provName,
              description: this.routeQuery.provDescription,
              location: this.routeQuery.provLocation,
              'serial-number': this.routeQuery.provSerialNumber
            }
          })
          return
        }
      }

      this.$store.dispatch('startAPI').then(() => {
        // Device doesn't fit the mimium App version
        if (Environment.invalidEmbed) {
          // NOTE: Force grab the English (en) version message for device logs
          const invalidEmbedMsg = this.$t('versionsControl.invalidEmbed', 'en')
          // This error message will be added to the Device Log, but skip notifying BugSnag
          Log.error('player', invalidEmbedMsg, 'ERR_INVALIDEMBED', null, true)
        }

        // Device's browser version is older than the Browser warning version
        if (Environment.warningBrowser) {
          // NOTE: Force grab the English (en) version message for device logs
          const oldVersionMsg = this.$t('versionsControl.oldBrowser', 'en')
          // Send warning message to Device log [DEV-4168]
          Log.warn('player', oldVersionMsg, 'WAR_OLDVERSION')
        }

        // Read Provisioning info and append to URL [DEV-4248]
        this.readProvisioningInfo()
      }).catch(err => {
        if (this.billingPlanExpired) {
          this.handleBillingPlanExpired(true)
          return
        }

        if (this.deviceDisabled || this.localDeviceDisabed) {
          this.handleDeviceDisabled(true)
          return
        }

        Log.error('player', `Error starting up - ${err.message || err.code || err.toString()}`, 'ERR_UNABLESTARTAPI2', err)
        this.$router.push({ name: 'starting' })
      })
    })
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearInterval(this.activeHrTimer)
    window.removeEventListener('resize', this.debounceCheckSize)
  },

  methods: {
    handleDeviceDisabled (disabled) {
      if (disabled) {
        this.$nextTick(() => {
          this.$router.push({
            name: 'errored',
            params: {error: 'device-disabled'}
          })
        })
      }
    },

    handleBillingPlanExpired (expired) {
      if (expired) {
        this.$nextTick(() => {
          this.$router.push({
            name: 'errored',
            params: { error: 'billing-plan-expired' }
          })
        })
      }
    },

    debounceCheckSize (timeout) {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        clearTimeout(this.debounceTimer)
        this.checkSize()
      }, timeout || 200)
    },

    checkSize () {
      let winWidth
      let winHeight

      const measure = FastDom.measure(() => {
        winWidth = window.innerWidth
        winHeight = window.innerHeight

        if (!winWidth || !winHeight) {
          FastDom.clear(measure)
          return
        }

        const winSize = {
          w: winWidth,
          h: winHeight
        }

        this.winSize = winSize
        this.$store.commit('updateWinSize', winSize)

        FastDom.clear(measure)
      })
    },

    checkActiveHours () {
      if (this.deviceDisabled) { return }

      // No Active Windows / Active Hours set. Do nothing
      if (!this.deviceActiveHours && !this.deviceActiveWindows) { return }

      // Is overriding by Playlist
      if (this.isOverridingByPlaylist) { return }

      // Not in the active hours, navigate to Inactive screen
      if (!ActiveWindow.inActiveWindow()) {
        this.$router.push({
          name: 'inactive'
        })
      }
    },

    readProvisioningInfo () {
      if (!this.device || !this.isDevice) {
        // Not a device, skipped
        return
      }

      if (this.isDevice && this.device && this.device.id && (!this.device.provisioning_id || !this.device.provisioning_id.length)) {
        // Not a provisioning device
        BrowserStorage.remove('provisioningInfo')
        return
      }

      // Already has URL parameters, skipped
      if (this.routeQuery.provToken && this.routeQuery.provToken.length) {
        return
      }

      const query = BrowserStorage.get('provisioningInfo') || {}
      if (query.provToken && query.provToken.length) {
        // Clone to prevent nested loop
        let newQuery = JSON.parse(JSON.stringify(this.$router.query || {}))
        newQuery = { ...newQuery, ...query }

        this.$router.replace({
          path: this.$router.path,
          query: newQuery
        })
      }
    }
  }
}
</script>

<template lang="pug">
main.run-screen(v-if="!deviceDisabled && !localDeviceDisabed")
  version-ribbon

  .running-player(:class="{'is-portrait': isPortrait}")
    section.running-main-frame

      nav(v-if="debugMode")
        titlebar

      section#player-container
        .player-placeholder
          .player-contianer-inner(:class="{minimized: debugMode, maximized: !debugMode}"
                                  :style="previewTransformStyle")
            player
            message-banner

        //- Logs sidebar for Portrait mode
        section.sidebar.is-portrait(v-if="debugMode && isPortrait")
          console

      footer(v-if="debugMode")
        overview(v-if="!deviceInWebappMode")
        .webapp-info(v-else) {{ overviewPlaceholder }}

    //- Logs sidebar for Landscape mode
    section.sidebar(v-if="debugMode && !isPortrait")
      console
</template>

<style lang="stylus">
// Make sure these values are the same as what's in the Javascript `config` object
$topBarHeight = 40px
$sidebarWidth = 300px
$overviewHeight = 150px
$paddingHorz = 20px
$paddingVert = 10px

main.run-screen
  .running-player
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: stretch

    &.is-portrait
      flex-flow: column nowrap

    .running-main-frame
      flex: 1 1 0.00001px
      position: relative
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: stretch

    nav
      height: $topBarHeight
      color: var(--gray-lightest)
      margin-bottom: $paddingVert
      // Make TitleBar button tooltips visible
      z-index: 5

    footer
      position: relative
      height: $overviewHeight
      overflow: hidden
      color: var(--gray-lightest)

      .webapp-info
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        flex-flow: row nowrap
        justify-content: center
        align-items: center
        text-align: center

    section.sidebar
      color: var(--gray-lightest)
      width: $sidebarWidth
      position: relative
      margin-left: $paddingHorz
      margin-bottom: 20px
      overflow: hidden
      justify-self: flex-end
      &.is-portrait
        margin-bottom: 0

    section#player-container
      flex: 1 1 0.00001px
      position: relative

      display: flex
      flex-flow: row nowrap
      justify-content: space-between
      align-items: stretch

      .player-placeholder
        flex: 1 1 0.00001px
        position: relative

    .player-contianer-inner
      position: fixed
      top: 0
      right: 0
      left: 0
      bottom: 0
      overflow: hidden

      &.minimized
        border: 1px solid var(--gray)
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.7)
        transform-origin: 0 0
</style>
