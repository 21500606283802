<script>
import NumberHelper from 'services/number-helper.js'

import FastDom from 'fastdom'
import {
  addListener as AddResizeListener,
  removeListener as RemoveResizeListener
} from 'resize-detector'
import { select, interpolate, pie as Pie, arc as Arc } from 'd3'

const config = {
  // Default Palette
  DEFAULT_PALETTE: [
    '#2aa4e5', '#31c384', '#fbd444', '#fc5543', '#69bfec', '#5acf9c',
    '#fbdc6d', '#fd7766', '#a9daf4', '#83dbb5', '#fce698', '#fd9f93'
  ],

  // 50 is max. Diameter is 100% max, so max radius is 50%
  OUTER_RADIUS: 45,

  // for donut piechart
  INNER_RADIUS_DONUT: 23,

  // for ring piechart
  INNER_RADIUS_RING: 35,

  // Ratio of Height/Width above which it will be considered as tall, otherwise it will be considered as wide
  // e.g. width 200, height 400 will be tall widget, so we put legends below the pie.
  TALL_HW_RATIO: 1.1,

  // Height & Width for *tall* widget, below which it will be considered small.
  // When it is small, we just display the pie, without legend.
  TALL_SMALL_WIDTH: 50,
  TALL_SMALL_HEIGHT: 100,

  // Height & Width for *wide* widget, below which it will be considered small.
  // When it is small, we just display the pie, without legend.
  WIDE_SMALL_WIDTH: 100,
  WIDE_SMALL_HEIGHT: 50,

  // When widget width / height is larger than this number, it will be marked as '.xWide'
  IS_XWIDE: 3,

  // Firefox fixes. The chart shall not exceed this width portion (comparing to the container full width) when widget is wide or xWide
  WIDE_MAX_WIDTH: 0.45,
  XWIDE_MAX_WIDTH: 0.4,

  EM_FACTOR: 0.85, // use to adjust legend value span min-width
  IS_LONG_VALUETYPE: 6 // when the number of characters in suffix (value_type) surpasses this value, will be consider as `isLongValueType`
}

export default {
  name: 'MetricsPiechartItem',

  props: {
    item: { type: Object, required: true },
    title: { type: String, default: '' },
    removeTitle: { type: Boolean, default: false }
  },

  data () {
    return {
      slices: [],
      sum: 0,
      maxValueLength: 1,
      sizing: '',

      isLongValueType: false,
      fixedLegendWidth: false,

      calculating: true,
      delayTimer: undefined,
      debounceTimer: undefined,
      resizeDebouncer: undefined
    }
  },

  computed: {
    valueType () {
      return this.item ? this.item.value_type : undefined
    },
    valueStyle () {
      if (!this.isLongValueType) {
        return {
          minWidth: (this.maxValueLength * config.EM_FACTOR) + 'em'
        }
      }
      return {}
    }
  },

  watch: {
    'item': {
      deep: true,
      handler (newValue) {
        this.render(newValue)
      }
    }
  },

  mounted () {
    clearTimeout(this.delayTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.resizeDebouncer)

    this.$nextTick(() => {
      this.resizeHandler()
    })

    AddResizeListener(this.$refs.sensor, this.resizeHandler)
  },

  beforeDestroy () {
    if (this.$refs && this.$refs.sensor) {
      RemoveResizeListener(this.$refs.sensor, this.resizeHandler)
    }
    clearTimeout(this.delayTimer)
    clearTimeout(this.debounceTimer)
    clearTimeout(this.resizeDebouncer)
  },

  methods: {
    render (data) {
      data = JSON.parse(JSON.stringify(data || this.item || {}))
      this.calculating = true
      this.renderValueType()

      if (data.slices) {
        this.prepareData(data.slices)
      }
    },

    renderValueType () {
      const prefixSuffix = NumberHelper.valueType(this.valueType)
      this.valuePrefix = prefixSuffix[0] || ''
      this.valueSuffix = prefixSuffix[1] || ''
      this.isLongValueType = prefixSuffix[1].length > config.IS_LONG_VALUETYPE
    },

    formatNumber (value) {
      return NumberHelper.formatter(value || 0, {value: value || 0, value_type: this.valueType, rounding: this.item ? this.item.rounding : undefined})
    },

    getSliceColor (color, index) {
      return (color) && (color.length > 0) ? color : config.DEFAULT_PALETTE[index % 12]
    },

    prepareData (slices) {
      slices = slices || []
      const self = this

      let sum = 0
      let maxValueLength = 1
      slices.forEach(function (slice) {
        if (!slice) { return }
        sum += (slice.value || 0)
        const formattedNum = self.valuePrefix + self.formatNumber(slice.value) + self.valueSuffix
        maxValueLength = Math.max(maxValueLength, formattedNum.length)
      })
      this.sum = sum
      this.maxValueLength = maxValueLength
      this.slices = slices

      // Fallback for all zero values
      const data = sum === 0 ? [{value: 100}] : slices
      this.renderChart(data)
    },

    renderChart (data) {
      clearTimeout(this.delayTimer)
      const self = this
      if (!this.$refs || !this.$refs.sensor || !this.$refs.svg || !this.$refs.legend) {
        this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer)
          this.renderChart(data)
        }, 100)
        return
      }

      const container = select(this.$refs.svg)
      const slices = data
      if (!slices.length) {
        container.selectAll('svg').remove()
        return
      }

      let containerMetrics
      let legendHeight

      const drawChart = function drawChart () {
        let minSize
        if (self.sizing === 'tall') {
          minSize = Math.min(containerMetrics.height - legendHeight, containerMetrics.width)
        } else if (self.sizing === 'wide' || self.sizing === 'xWide') {
          minSize = Math.min(containerMetrics.width * (self.sizing === 'wide' ? config.WIDE_MAX_WIDTH : config.XWIDE_MAX_WIDTH), containerMetrics.height)
        }

        if (Math.floor(minSize) <= 0) { return }

        const pieDataFn = Pie().sort(null).value(function (d) {
          return d.value || 0
        })

        const arcFn = Arc().outerRadius(config.OUTER_RADIUS).innerRadius(0)
        if (self.item.chart_type === 'donut') {
          arcFn.innerRadius(config.INNER_RADIUS_DONUT)
        } else if (self.item.chart_type === 'ring') {
          arcFn.innerRadius(config.INNER_RADIUS_RING)
        }

        let baseSvg = container.select('svg')
        if (baseSvg.empty()) {
          baseSvg = container.append('svg:svg')
        }
        baseSvg.attr('viewBox', '0 0 100 100')
          .merge(baseSvg)
          .attr('width', minSize).attr('height', minSize)

        let pieChart = baseSvg.select('g.piechart')
        if (pieChart.empty()) {
          pieChart = baseSvg.append('svg:g').attr('class', 'piechart').attr('transform', 'translate(50, 50)')
        }

        const pieSlices = pieChart.selectAll('path.slice').data(pieDataFn(slices))

        const pieSlicesEnter = pieSlices.enter().append('svg:path')
          .attr('class', function () { return self.sum === 0 ? 'shadow slice' : 'slice' })
          .merge(pieSlices)
          .style('fill', function (d, i) {
            if (self.sum !== 0) {
              return (d.data && d.data.color) || config.DEFAULT_PALETTE[i % config.DEFAULT_PALETTE.length]
            }
          })

        self.calculating = false

        pieSlices.merge(pieSlicesEnter).transition().duration(1000)
          .attrTween('d', function (d) {
            this._current = this._current || d
            const interpolateFn = interpolate(this._current, d)
            this._current = interpolateFn(0)
            return function (t) {
              return arcFn(interpolateFn(t))
            }
          })

        pieSlices.exit().remove()
      }

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!self.$refs?.legend || !self.$refs?.sensor?.getBoundingClientRect) {
          FastDom.clear(measure)
          return
        }

        containerMetrics = self.$refs.sensor.getBoundingClientRect()
        legendHeight = self.$refs.legend.offsetHeight

        const mutate = FastDom.mutate(() => {
          if (containerMetrics.height === 0 || containerMetrics.width === 0 || (self.sizing === 'tall' && legendHeight === 0)) {
            self.delayTimer = setTimeout(() => {
              clearTimeout(self.delayTimer)
              self.renderChart(data)
            }, 100)
            FastDom.clear(measure)
            FastDom.clear(mutate)
            return
          }

          drawChart()

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    checkSizing () {
      if (!this.$refs || !this.$refs.sensor) { return }

      const self = this
      let containerMetrics

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!self.$el || !self.$refs?.sensor?.getBoundingClientRect) {
          FastDom.clear(measure)
          return
        }

        containerMetrics = self.$refs.sensor.getBoundingClientRect()

        const mutate = FastDom.mutate(() => {
          if (containerMetrics.height === 0 || containerMetrics.width === 0) {
            self.sizing = ''
          } else if (containerMetrics.height / containerMetrics.width > config.TALL_HW_RATIO) {
            if (containerMetrics.height < config.TALL_SMALL_HEIGHT || containerMetrics.width < config.TALL_SMALL_WIDTH) {
              self.sizing = 'small'
            } else {
              self.sizing = 'tall'
            }
          } else {
            if (containerMetrics.height < config.WIDE_SMALL_HEIGHT || containerMetrics.width < config.WIDE_SMALL_WIDTH) {
              self.sizing = 'small'
            } else if (containerMetrics.width / containerMetrics.height > config.IS_XWIDE) {
              self.sizing = 'xWide'
            } else {
              self.sizing = 'wide'
            }
          }
          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    switchLegendFixWidth () {
      clearTimeout(this.debounceTimer)
      if (!this.$refs || !this.$refs.legend || !this.$refs.sensor) { return }

      if (this.sizing !== 'tall') {
        this.fixedLegendWidth = false
        return
      }

      const self = this

      let legendScrollWidth
      let availableWidth

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!self.$refs || !self.$refs.legend || !self.$refs.sensor) {
          FastDom.clear(measure)
          return
        }

        legendScrollWidth = self.$refs.legend.scrollWidth
        availableWidth = self.$refs.sensor.offsetWidth

        const mutate = FastDom.mutate(() => {
          if (legendScrollWidth === 0) {
            self.debounceTimer = setTimeout(() => {
              clearTimeout(self.debounceTimer)
              self.switchLegendFixWidth()
            }, 100)
            FastDom.clear(mutate)
            return
          }

          if (legendScrollWidth > availableWidth) {
            self.fixedLegendWidth = true
          }

          FastDom.clear(mutate)
        })

        FastDom.clear(measure)
      })
    },

    resizeHandler () {
      clearTimeout(this.resizeDebouncer)
      this.resizeDebouncer = setTimeout(() => {
        if (this.$el) {
          this.checkSizing()
          this.render()
        }
        clearTimeout(this.resizeDebouncer)
      }, 200)
    }
  }
}
</script>

<template lang="pug">
.metrics-item-wrapper.metrics-piechart-item
  .item-title.app-context-section.secondary(v-if="!removeTitle && title && title.length") {{ title }}

  .item-body.app-context-section.primary
    .resize-sensor(ref="sensor")

    .piechart-wrapper(:class="[sizing]")
      .chart(ref="chart")
        .svg(ref="svg")
      .legend(:class="{isLongValueType: isLongValueType, fixWidth: fixedLegendWidth}")
        ul(ref="legend")
          li(v-for="(item, index) in slices", :key="index", :style="{color: getSliceColor(item.color, index)}")
            .value(:style="valueStyle")
              span.prefix(v-text="valuePrefix")
              | {{ formatNumber(item.value) }}
              span.suffix(v-text="valueSuffix")
            .circle &#x2b24;
            .text
              //- Safari fixes
              .text-block(v-text="item.label")
</template>

<style lang="stylus">
@import '../../../style/mixins.styl'

.metrics-piechart-item
  .item-body
    > .resize-sensor
      position: absolute !important
      z-index: -1
      visibility: hidden
      opacity: 0
      top: 0
      bottom: 0
      left: 0
      right: 0

  .piechart-wrapper
    position: absolute
    left: 0
    bottom: 0
    right: 0
    top: 0

    display: flex
    flex-flow: row nowrap
    justify-content: space-around
    align-items: center

    &.tall
      flex-flow: column nowrap
      justify-content: center
      .chart
        min-height: 0
        .svg
          display: flex
          justify-content: center
          svg
            max-width: 90%
      .legend
        display: block   // Safari fixes
        flex: none

    &.small
      .chart
        display: none

    &.wide
      .chart
        flex: 1.5 1.5 0.00001px
      .legend
        flex: 1 1 0.00001px

    &.xWide
      .chart
        flex: 1 1 0.00001px
      .legend
        flex: 2 2 0.00001px

  .chart
    display: flex
    flex-flow: column
    justify-content: center
    align-items: center

    .svg
      .slice
        stroke: none
        &.shadow
          fill: -black(0.2)

  .legend
    display: flex
    flex-flow: column nowrap
    justify-content: center
    max-width: 100%
    min-width: 0
    padding: 0 0.5em

    ul
      list-style: none
      padding: 0
      margin: 0
      overflow: hidden

      display: flex
      flex-flow: row wrap
      justify-content: flex-start

      li
        display: flex
        flex-flow: row nowrap
        justify-content: center
        align-items: center
        font-size: 0.85em
        line-height: 1.2em
        padding-top: 4px
        margin-left: 0.5em
        overflow: hidden

        .value
          text-align: right
          font-weight: 700
          ellipsis()

          .prefix,
          .suffix
            opacity: 0.4
            font-weight: 600

        .circle
          font-size: 0.85em
          width: 2em
          padding: 0 0.3em
          text-align: center

        .text
          flex: 1 1 0.00001px
          overflow: hidden

          // Safari fixes
          .text-block
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden

            // Placeholder when metric source is not set
            &:empty:after
              content: 'N/A'
</style>
