<script>
import MenuPhotoItem from './PhotoItem'

const config = {
  // Column count
  ITEMS_PER_ROW: 3,

  // In ms. Prepare next item in the background
  PREPARE_TIME: 2000
}

export default {
  name: 'MenuAppPhotoRow',
  components: {
    MenuPhotoItem
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: ''
    },
    interval: {
      type: Number,
      default: 10
    },

    // Default to `photo_large`
    // Will trigger `photo_small` style when set to `true`
    photoSmall: {
      type: Boolean,
      default: false
    },

    isPortrait: {
      type: Boolean,
      default: false
    },

    whiteText: {
      type: Boolean,
      default: false
    },

    hideBox: {
      type: Boolean,
      default: false
    },

    hideBoxOutline: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentLastIndex: 0,

      columns: [],

      // Flag of reaching the end of the list
      reachTheEnd: false,

      showNextTimer: undefined,
      delayTimer: undefined
    }
  },

  computed: {
    itemsPerPage () {
      return config.ITEMS_PER_ROW * (this.photoSmall ? 2 : 1)
    },

    needsPaginate () {
      if (!this.items || !this.items.length) { return false }
      return this.items.length > this.itemsPerPage
    },

    columnCount () {
      return config.ITEMS_PER_ROW
    }
  },

  watch: {
    items: {
      deep: true,
      handler () {
        this.render()
      }
    },

    photoSmall () {
      this.render()
    }
  },

  mounted () {
    clearTimeout(this.showNextTimer)
    clearTimeout(this.delayTimer)
    this.render()
  },

  beforeDestroy () {
    clearTimeout(this.showNextTimer)
    clearTimeout(this.delayTimer)
  },

  methods: {
    render () {
      clearTimeout(this.showNextTimer)
      clearTimeout(this.delayTimer)

      this.currentLastIndex = 0

      const initChunk = (this.items || []).slice(0, this.itemsPerPage)

      if (!initChunk || !initChunk.length) { return }

      this.currentLastIndex = initChunk.length - 1

      const initChunkLen = initChunk.length

      // Fill up empty column space for 'Photo Small' style
      if (this.photoSmall && initChunkLen < this.itemsPerPage) {
        for (let i = 0; i < this.itemsPerPage - initChunkLen; i++) {
          initChunk.push({
            id: `item-placeholder-${i}`,
            placeholder: true
          })
        }
      }

      this.columns = []
      initChunk.forEach((item, idx) => {
        const colIndex = idx % this.columnCount
        if (!this.columns[colIndex]) {
          this.columns[colIndex] = {
            key: this.genColumnKey(),
            items: []
          }
        }
        this.columns[colIndex].items.push(JSON.parse(JSON.stringify(item)))
      })

      this.prepareNext()
    },

    prepareNext () {
      clearTimeout(this.showNextTimer)
      this.showNextTimer = setTimeout(() => {
        clearTimeout(this.showNextTimer)
        this.addNextItems()
      }, (this.interval * 1000 - config.PREPARE_TIME))
    },

    genColumnKey () {
      return `column-${new Date().getTime()}-${~~(Math.random() * 1000)}`
    },

    getNextItem () {
      if (!this.items || !this.items.length) { return }

      const nextIndex = (this.currentLastIndex + 1) % this.items.length
      const nextItem = JSON.parse(JSON.stringify(this.items[nextIndex]))

      this.currentLastIndex = nextIndex

      if (nextIndex === 0) {
        this.reachTheEnd = true
      }

      return nextItem
    },

    addNextItems () {
      clearTimeout(this.delayTimer)

      if (!this.items || !this.items.length) { return }
      if (!this.needsPaginate) { return }

      // Delay event firing for Photo Small
      if (this.reachTheEnd) {
        this.$emit('end-of-list')
        this.reachTheEnd = false
      }

      const newItems = []
      let nextItem = this.getNextItem()
      newItems.push(nextItem)
      // Add one more item for `photo_small` style
      if (this.photoSmall) {
        nextItem = this.getNextItem()
        newItems.push(nextItem)
      }

      // Add one column in the background
      this.columns.push({
        key: this.genColumnKey(),
        items: newItems
      })

      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer)
        this.showNext()
      }, config.PREPARE_TIME)
    },

    showNext () {
      if (!this.needsPaginate) { return }

      if (this.reachTheEnd) {
        if (!this.photoSmall || (this.photoSmall && this.currentLastIndex !== 0)) {
          this.$emit('end-of-list')
          this.reachTheEnd = false
        }
      }

      // Remove the first column
      this.columns.shift()

      this.prepareNext()
    },

    blockPosition (blockIndex) {
      if (this.isPortrait) {
        return {
          transform: `translate3d(0, ${100 * blockIndex}%, 0)`,
          height: `${(100 / this.columnCount)}%`
        }
      }
      return {
        transform: `translate3d(${100 * blockIndex}%, 0, 0)`,
        width: `${(100 / this.columnCount)}%`
      }
    }
  }
}
</script>

<template lang="pug">
.menu-app-photo-row(:class="{'is-portrait': isPortrait}")
  transition-group(tag="div" class="photo-row-inner"
                   :name="isPortrait ? 'page-fadeTop' : 'page-fadeLeft'"
                   :duration="{enter: 100, leave: 800}")
    .photo-column(v-for="(column, colIndex) in columns", :key="column.key"
                  :style="blockPosition(colIndex)"
                  :class="{invisible: colIndex >= columnCount}")
      .photo-column-inner
        .photo-item-wrapper(
            v-for="(item, index) in column.items", :key="item.id"
            :class="{'app-context-block': !item.placeholder, 'is-placeholder': item.placeholder, 'photo-small': photoSmall, 'dark-text': !whiteText, 'hide-content-box': hideBox, 'hide-box-outline': hideBoxOutline}")

          //- Valid Photo Item
          menu-photo-item(v-if="!item.placeholder", :item="item", :currency="currency")
</template>

<style lang="stylus">
.menu-app-photo-row
  position: relative
  overflow: hidden

  .photo-row-inner
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: hidden

    white-space: nowrap

  .photo-column
    position: absolute
    overflow: hidden

    top: 0
    left: 0
    height: 100%

    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: stretch

    will-change: transform

    // Prevent transition and animation conflict
    &:not([class*='page-fade'])
      transition: transform .8s

    &.invisible
      visibility: hidden !important
      z-index: -1 !important

  .photo-column-inner
    flex: 1 1 0.00001px
    padding: 0 1em
    position: relative
    overflow: hidden

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch

  .photo-item-wrapper
    flex: 1 1 0.00001px
    margin: 0.5em 0
    position: relative
    overflow: hidden

    &.photo-small
      margin: 1em 0

      // Shrink overall font-size for 'photo_small'
      .menu-app-photo-item
        font-size: 0.9em

  //
  // Portrait Layout
  //
  &.is-portrait
    .photo-row-inner
      white-space: normal

    .photo-column
      width: 100%

    .photo-column-inner
      padding: 1em 0
      flex-flow: row nowrap

    .photo-item-wrapper
      margin: 0 0.5em

      &.photo-small
        margin: 0 1em
</style>
