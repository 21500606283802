<script>
// MIXIN
import AppCommonMixins from './AppCommonMixins.vue'

import qs from 'qs'
import Moment from 'moment-timezone'
import FastDom from 'fastdom'
import ICAL from 'ical.js'
import {
  mapGetters
} from 'vuex'

import EventItem from './events/Item'

import Timezone from 'services/timezone.js'
import Log from 'services/log.js'
import OfflineCaches from 'services/offline-caches'

// Cache for 5 minutes
// NOTE: Remember to update the "ICAL_DATA" value in ContentCache after changing this value
// - In second. For request header
const CACHE_CONTROL = 300
// - In ms. For LRUCache and refresh timer
const CACHE_MAX_AGE = CACHE_CONTROL * 1000

// Max UTC Offest time (24hrs). In ms
const MAX_UTC_OFFSET = 24 * 3600 * 1000

const config = {
  // In ms
  // Add 1s extra interval to ensure the cache expires before next fetch
  ICAL_UPDATE_INTERVAL: +CACHE_MAX_AGE + 1000,

  // In vmin
  BASE_FONTSIZE: 5,

  // Wide Screen Width/Height ratio
  IS_WIDE: 3,

  // White, RED, BLUE, GREEN
  DEFAULT_ICAL_COLORS: [
    '#FFFFFF',
    '#FC5543',
    '#2AA4E5',
    '#5CBF63'
  ],

  // Default to Month/Day
  DEFAULT_DATE_FORMAT: 'dddd MMMM Do',
  // Optional Day/Month format
  DAY_MONTH_FORMAT: 'dddd Do MMMM',

  // In ms. Transition Duration for current active event
  TRANSITION_TIME: 1500,

  // Limit maximum valid items, to prevent overwhelm the DOM
  MAX_ITEMS_COUNT: 20,

  // Parse the next ${n}th occurance
  REPEAT_DAY_COUNT: 14,
  REPEAT_WEEK_COUNT: 4,
  REPEAT_MONTH_COUNT: 3,
  REPEAT_YEAR_COUNT: 2,

  // Default repeat count for MINUTELY | SECONDLY from iCal
  REPEAT_DEFAULT_COUNT: 10
}

// Get Recurrence Type
// > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
const ICAL_RECUR_TYPES_COUNT = {
  'YEARLY': 'REPEAT_YEAR_COUNT',
  'MONTHLY': 'REPEAT_MONTH_COUNT',
  'WEEKLY': 'REPEAT_WEEK_COUNT',
  'DAILY': 'REPEAT_DAY_COUNT',
  'MINUTELY': 'REPEAT_DEFAULT_COUNT',
  'SECONDLY': 'REPEAT_DEFAULT_COUNT'
}

const WEEKDAY_MAP = {
  'sun': 0,
  'mon': 1,
  'tue': 2,
  'wed': 3,
  'thu': 4,
  'fri': 5,
  'sat': 6
}

export default {
  name: 'EventsPage',
  components: {
    EventItem
  },

  // MIXIN
  // Contains all modernized apps' common functions
  mixins: [ AppCommonMixins ],

  props: {
    fontClass: {
      type: String,
      default: ''
    }
  },

  // Inject zone configs from ancestor component `item/Item.vue`
  inject: ['ZONE_CONFIGS'],

  data () {
    return {
      title: '',
      rawEvents: [],
      events: [],
      validEvents: [],
      displayActiveEvent: {},

      useICal: false,
      iCals: [],

      showUpcoming: false,
      hideLocation: false,
      dontFeatureNext: false,

      // Date Format
      dateFm: config.DEFAULT_DATE_FORMAT + '',
      // Use 24H Clock
      format24h: false,

      fixedFontSize: false,
      fontSizeScale: undefined,

      timeframe: 'any',
      timeframe_day: undefined,

      vAlign: 'middle',
      hAlign: 'left',

      // Actual width/height
      itemSize: {},

      switchingEvents: false,

      debounceTimer: undefined,
      debounceUpComing: undefined,
      switchEventTimer: undefined,
      tickTimer: undefined,
      icalTimer: undefined,
      icalsFetchTimer: undefined,

      loading: true,
      renderingICals: false
    }
  },

  computed: {
    ...mapGetters([
      'accountLocale',
      'contentForURL'
    ]),

    configBaseFontSize () {
      return config.BASE_FONTSIZE
    },

    isWideScreen () {
      if (!this.itemSize || !this.itemSize.h || !this.itemSize.w) { return false }
      return (this.itemSize.w / this.itemSize.h) >= config.IS_WIDE
    },

    activeEvent () {
      if (this.dontFeatureNext || !this.validEvents || !this.validEvents.length || !this.validEventsByDate || !this.validEventsByDate.length) { return }
      return this.validEvents[0]
    },

    activeEventDate () {
      if (!this.activeEvent || !this.validEventsByDate || !this.validEventsByDate.length) { return }
      return this.validEventsByDate[0].date
    },

    upcomingEvents () {
      if (!this.validEvents || !this.validEvents.length || (!this.dontFeatureNext && this.validEvents.length === 1)) { return [] }
      return this.dontFeatureNext ? this.validEvents : this.validEvents.slice(1)
    },

    hasUpcomingEvents () {
      return Boolean(this.upcomingEvents && this.upcomingEvents.length)
    },

    useRestrictTime () {
      return this.timeframe === 'restrict'
    },

    restrictInDays () {
      if (this.useRestrictTime) {
        return Math.max(0, +this.timeframe_day || 0)
      }
    },

    noValidEvents () {
      return Boolean(!this.validEvents || !this.validEvents.length)
    },

    listIsEmpty () {
      return Boolean(this.noValidEvents || !this.validEventsByDate || this.validEventsByDate.length === 0)
    },

    validEventsByDate () {
      if (this.noValidEvents) { return [] }
      const dates = []
      const groupedList = []
      const now = Moment()
      const dateTomorrow = now.clone().add(1, 'days').format('MM/DD/YYYY')

      let targetList = []

      // Any Time
      if (!this.useRestrictTime) {
        targetList = this.validEvents

      // Restrict in X days
      } else {
        const InRestrictDays = now.clone().add(this.restrictInDays, 'days')
        targetList = this.validEvents.filter(event => {
          const eventDate = Moment(event.date, 'MM/DD/YYYY')
          if (eventDate.isSameOrBefore(InRestrictDays, 'day')) {
            return true
          }
          return false
        })
      }

      targetList.forEach(event => {
        const eventDate = Moment(event.date, 'MM/DD/YYYY')

        // Set to show full date format since DEV-4277
        // E.g. "TUESDAY MARCH 23rd"
        let date = eventDate.format(this.dateFm)

        // Today
        if (eventDate.isSameOrBefore(now, 'day')) {
          date = `${this.$t('_common.time.today')}, ${date}`
        // Tomorrow
        } else if (event.date === dateTomorrow) {
          date = `${this.$t('_common.time.tomorrow')}, ${date}`
        }

        if (!dates.includes(date)) {
          dates.push(date)
          groupedList.push({
            date,
            items: []
          })
        }
        const gIndex = groupedList.findIndex(gp => gp.date === date)
        if (gIndex >= 0) {
          if (!groupedList[gIndex].items) {
            groupedList[gIndex].items = []
          }
          groupedList[gIndex].items.push(event)
        }
      })

      return groupedList
    },

    upcomingList () {
      if (!this.validEventsByDate || !this.validEventsByDate.length) { return [] }
      if (this.dontFeatureNext) {
        return this.validEventsByDate
      } else {
        // Has only one event in the first grouped date
        if (this.validEventsByDate[0] && this.validEventsByDate[0].items && this.validEventsByDate[0].items.length === 1) {
          return this.validEventsByDate.slice(1)
        }
        // More than one event in the first group
        // Remove the first item from the group (It's already displayed in featured block)
        const firstGroup = JSON.parse(JSON.stringify(this.validEventsByDate[0]))
        firstGroup.items.splice(0, 1)
        return [].concat([firstGroup], this.validEventsByDate.slice(1))
      }
    },

    showIcalColor () {
      if (!this.useICal || !this.iCals || !this.iCals.length) { return false }
      return Boolean(this.iCals.length > 1 && this.showUpcoming && this.hasUpcomingEvents)
    },

    showUpcomingSection () {
      return (this.showUpcoming && this.hasUpcomingEvents) || (this.dontFeatureNext && this.hasUpcomingEvents)
    },

    colorLabelWidth () {
      if (!this.isPortrait || !this.showIcalColor || !this.baseFontSize) { return }
      return `${this.baseFontSize * 0.16}px`
    },

    appID () {
      return this.item && this.item.ref_id
    },

    localKey () {
      if (this.appID && this.appID.length) {
        // Use the AppID as localStorage key
        return `ttvEvents_${this.appID}`
      }
    },

    horzClass () {
      if (!this.hAlign || !this.hAlign.length) { return }
      return `horz-${this.hAlign}`
    },

    vertClass () {
      if (!this.vAlign || !this.vAlign.length) { return }
      return `vert-${this.vAlign}`
    }
  },

  watch: {
    'item.url': {
      deep: true,
      handler () {
        this.render()
      }
    },

    upcomingList: {
      deep: true,
      handler () {
        this.debounceCheckUpcomingZone()
      }
    },

    activeEvent: {
      deep: true,
      handler (newValue, oldValue) {
        if (!newValue || !newValue.key) { return }
        // Is first init
        if (!oldValue || !oldValue.key) {
          this.displayActiveEvent = JSON.parse(JSON.stringify(newValue))
        } else if (newValue.key !== oldValue.key) {
          // Animated Transition
          this.switchingEvents = true
          clearTimeout(this.switchEventTimer)
          this.switchEventTimer = setTimeout(() => {
            clearTimeout(this.switchEventTimer)
            this.displayActiveEvent = JSON.parse(JSON.stringify(newValue))
            this.switchingEvents = false
          }, config.TRANSITION_TIME)
        } else {
          // Update value without animation
          this.displayActiveEvent = JSON.parse(JSON.stringify(newValue))
        }
      }
    },

    'accountLocale.timezone': {
      handler () {
        if (this.useICal) {
          this.debounceFetchICals()
        }
      }
    }
  },

  mounted () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceUpComing)
    clearTimeout(this.switchEventTimer)
    clearTimeout(this.icalsFetchTimer)

    // Show stale data first to speed up app display
    this.getStaleData(true)

    this.debounceCheckSize()
    this.debounceCheckUpcomingZone()
    this.render()

    clearInterval(this.tickTimer)
    this.tickTimer = setInterval(() => {
      this.checkValidEvents()
    }, 1000)

    clearInterval(this.icalTimer)
    this.icalTimer = setInterval(() => {
      if (this.useICal) {
        this.debounceFetchICals()
      }
    }, config.ICAL_UPDATE_INTERVAL)
  },

  beforeDestroy () {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.debounceUpComing)
    clearTimeout(this.switchEventTimer)
    clearTimeout(this.icalsFetchTimer)
    clearInterval(this.tickTimer)
    clearInterval(this.icalTimer)
  },

  methods: {
    render () {
      if (!this.item || !this.item.url || !this.item.url.length) { return }

      // Note: `qs`` has limit parsing Arrays by default
      // > https://github.com/ljharb/qs#parsing-arrays
      const options = qs.parse(decodeURIComponent(this.item.url || ''), { arrayLimit: 100 })

      this.title = options.title || ''

      this.showUpcoming = (options.showUpcoming === true || options.showUpcoming === 'true')
      this.hideLocation = (options.hideLocation === true || options.hideLocation === 'true')
      this.dontFeatureNext = (options.dontFeatureNext === true || options.dontFeatureNext === 'true')
      this.fixedFontSize = (options.fixedFontSize === true || options.fixedFontSize === 'true')
      this.fontSizeScale = options.fontScale || ''
      this.useICal = (options.useICal === true || options.useICal === 'true')

      this.dateFm = options.dateFm || (config.DEFAULT_DATE_FORMAT + '')
      this.format24h = (options.format24h === true || options.format24h === 'true')

      this.timeframe = options.timeframe || 'any'
      this.timeframe_day = options.timeframe_day || ''

      this.hAlign = options.hAlign || ''
      this.vAlign = options.vAlign || ''

      this.useICal = (options.useICal === true || options.useICal === 'true')
      this.iCals = options.iCals || []

      // Fallback for app created before DEV-1100
      if (options.iCalendarURL && options.iCalendarURL.length) {
        this.useICal = true

        if (!this.iCals || !this.iCals.length) {
          this.iCals = [{
            url: options.iCalendarURL + '',
            color: config.DEFAULT_ICAL_COLORS[0] + ''
          }]
        }
      }

      // Use iCalendars
      if (this.useICal) {
        // Move "dateFm" to the "options" root level
        if (!options.dateFm && this.iCals.length) {
          const useDayMonthFm = this.iCals.some(iCal => {
            return (iCal && iCal.dateFm && iCal.dateFm === config.DAY_MONTH_FORMAT)
          })
          if (useDayMonthFm) {
            this.dateFm = (config.DAY_MONTH_FORMAT + '')
          }
        }
        this.debounceFetchICals()

      // Use Manual Events
      } else {
        this.renderingICals = false

        // Move "dateFm" to the "options" root level
        if (!options.dateFm && options.events && options.events.length) {
          const useDayMonthFm = options.events.some(evt => {
            return (evt && evt.dateFm && evt.dateFm === config.DAY_MONTH_FORMAT)
          })
          if (useDayMonthFm) {
            this.dateFm = (config.DAY_MONTH_FORMAT + '')
          }
        }
        // Move "format24h" to the "options" root level
        if (!options.format24h && options.events && options.events.length) {
          const use24Hrs = options.events.some(evt => {
            return (evt && (evt.format24h === 'true' || evt.format24h === true))
          })
          if (use24Hrs) {
            this.use24Hrs = true
          }
        }

        this.rawEvents = options.events || []
        this.parseEvents(options.events || [])
      }
    },

    debounceFetchICals () {
      clearTimeout(this.icalsFetchTimer)
      this.icalsFetchTimer = setTimeout(() => {
        clearTimeout(this.icalsFetchTimer)
        this.fetchICals()
      }, 200)
    },

    fetchICals () {
      if (!this.useICal || !this.iCals || !this.iCals.length) { return }
      const identicalUrls = []
      const promises = []

      this.renderingICals = true

      this.iCals.forEach((ical, index) => {
        if (ical && ical.url && ical.url.length && !identicalUrls.includes(ical.url)) {
          identicalUrls.push(ical.url)
          const iCalColor = ical.color || config.DEFAULT_ICAL_COLORS[index]
          promises.push(this.fetchICalendar(ical.url, iCalColor))
        }
      })
      Promise.all(promises).then(results => {
        const events = [].concat.apply([], results)
        this.renderingICals = false
        this.rawEvents = events || []
        this.parseEvents(events || [])
      }).catch(() => {
        this.getStaleData()
        this.renderingICals = false
      })
    },

    fetchICalendar (iCalURL, iCalColor) {
      return this.$store.dispatch('getContentForURL', {
        url: iCalURL,
        // LRUCache accepts `maxAge` in ms.
        maxAge: CACHE_MAX_AGE,
        // Set a shorter cache time for iCals [DEV-2860]
        cacheName: 'ICAL_DATA'
      }).then(() => {
        const iCalString = this.contentForURL(iCalURL)
        const iCalParsed = ICAL.parse(iCalString)
        const vcalendar = new ICAL.Component(iCalParsed)
        const vevents = vcalendar.getAllSubcomponents('vevent')

        const now = Moment().valueOf()

        // ==========================================================
        // Filter out outdated events to speed up parsing [DEV-2771]

        // [O] Recurrence Exception Instances [DEV-2876]
        const RecurrenceExecptions = []

        // [A] One Time events
        const oneTimeEvents = vevents.filter((vevent, veIdx) => {
          const rrule = vevent.getFirstPropertyValue('rrule')
          const recurrenceId = vevent.getFirstPropertyValue('recurrence-id')

          // Has "rrule" >> recurring event
          if (rrule) { return false }

          const dtend = vevent.getFirstPropertyValue('dtend')

          // Rough test. Rule out any events that ends 24hrs before now.
          if (dtend && (dtend.toUnixTime() * 1000 + MAX_UTC_OFFSET) < now) {
            return false
          }

          // A particular altered instance of a recurring event
          // - "RECURRENCE-ID" property is used in conjunction with the "UID" and "SEQUENCE" properties to identify a particular instance of a recurring event,
          // > https://icalendar.org/iCalendar-RFC-5545/3-8-4-4-recurrence-id.html
          if (!rrule && recurrenceId) {
            RecurrenceExecptions.push(vevent)
          }

          return true
        })

        // [B] Reurring events
        const recurringEvents = vevents.filter((vevent, veIdx) => {
          const rrule = vevent.getFirstPropertyValue('rrule')

          // Does NOT have "rrule" >> one time
          if (!rrule) { return false }

          const rruleUntil = rrule && rrule.until

          // NOTE
          // a. `rruleUntil` is undefined - infinite repeat event
          // b. `rruleUntil` is defined - finite recurring event with an endtime
          if (rruleUntil && (rruleUntil.toUnixTime() * 1000) < now) {
            return false
          }
          return true
        })
        // EOF quick filtering
        // ==========================================================

        // ================================
        // Recurrence Exception Instances
        // ================================

        const recurExceptions = []

        if (RecurrenceExecptions.length) {
          RecurrenceExecptions.forEach((vevent, veIdx) => {
            const dstart = vevent.getFirstPropertyValue('dtstart')
            const item = {
              uid: vevent.getFirstPropertyValue('uid'),
              summary: vevent.getFirstPropertyValue('summary'),
              sequence: vevent.getFirstPropertyValue('sequence'),
              recurrenceId: vevent.getFirstPropertyValue('recurrence-id'),
              dtstart: dstart,
              dtend: vevent.getFirstPropertyValue('dtend'),
              startTs: dstart.toUnixTime(),
              vevent: JSON.parse(JSON.stringify(vevent))
            }
            recurExceptions.push(item)
          })
        }

        const events = []

        // ========================
        // NON-Recurring Events
        // ========================

        oneTimeEvents.forEach((vevent, veIdx) => {
          const dtstart = vevent.getFirstPropertyValue('dtstart')
          const dtend = vevent.getFirstPropertyValue('dtend')
          const duration = vevent.getFirstPropertyValue('duration')

          let durationMinute = 1440
          if (duration) {
            durationMinute = duration.toSeconds() / 60
          } else if (dtend) {
            durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
          }

          let start = Moment.tz(dtstart.toUnixTime() * 1000, 'UTC')

          try {
            // Adjust time with event timezone
            if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
              const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(dtstart.toUnixTime() * 1000)
              start.add(utcOffsetFix, 'minutes')
            }
          } catch (e) {}

          const end = start.clone().add(durationMinute, 'minutes')

          // Skip outdated events
          if (end.valueOf() < now) {
            return
          }

          const allDay = (durationMinute % 1440 === 0)

          // Display with account local time
          // All-day events don't have "timezone" property, hence no need to adjust
          if (!allDay) {
            start = start.tz(this.accountLocale.timezone)
          }

          const summary = vevent.getFirstPropertyValue('summary')
          const location = vevent.getFirstPropertyValue('location')

          const event = {
            title: summary || '',
            location: location || '',
            date: start.format('MM/DD/YYYY'),
            start_hour: start.hour(),
            start_minute: start.minute(),
            start_second: start.second(),
            duration: durationMinute,
            allday: allDay,
            repeat: 'no',
            color: iCalColor
          }
          events.push(event)
        }) // EOF oneTimeEvents

        // ========================
        // Recurring Events
        // ========================

        recurringEvents.forEach((vevent, veIdx) => {
          const summary = vevent.getFirstPropertyValue('summary')
          const location = vevent.getFirstPropertyValue('location')
          const dtstart = vevent.getFirstPropertyValue('dtstart')
          const dtend = vevent.getFirstPropertyValue('dtend')
          const duration = vevent.getFirstPropertyValue('duration')

          let durationMinute = 1440
          if (duration) {
            durationMinute = duration.toSeconds() / 60
          } else if (dtend) {
            durationMinute = (dtend.toUnixTime() - dtstart.toUnixTime()) / 60
          }
          const allDay = (durationMinute % 1440 === 0)

          const icEvent = new ICAL.Event(vevent)

          // Get Recurrence Type to reduce "while" loops [DEV-2771]
          // > https://mozilla-comm.github.io/ical.js/api/ICAL.Event.html#getRecurrenceTypes
          const recurType = this.getRecurType(icEvent.getRecurrenceTypes())
          const maxRepeatCountByType = config[ICAL_RECUR_TYPES_COUNT[recurType]] || config.REPEAT_DEFAULT_COUNT

          const rrule = vevent.getFirstPropertyValue('rrule')
          const rruleUntil = rrule && rrule.until

          const uid = vevent.getFirstPropertyValue('uid')
          const sequence = vevent.getFirstPropertyValue('sequence')

          const expand = new ICAL.RecurExpansion({
            component: vevent,
            dtstart: dtstart
          })

          let start
          let end
          let testEnd

          let next = dtstart

          // Count for valid (ts >= now) item
          let count = 0

          while (
            count < maxRepeatCountByType &&
            (next = expand.next())
          ) {
            if (
              !next ||
              (!!rruleUntil && (next.toUnixTime() + (MAX_UTC_OFFSET / 1000) > rruleUntil.toUnixTime()))
            ) {
              // No valid "next" found, or it already passed the rule until. Break the while loop.
              return
            }

            start = Moment.tz(next.toUnixTime() * 1000, 'UTC')
            testEnd = start.clone().add(durationMinute, 'minutes')

            // Rough test. Rule out any events that ends 24hrs before now.
            if ((testEnd.valueOf() + MAX_UTC_OFFSET) < now) {
              continue
            }

            if (recurExceptions.length) {
              const exception = recurExceptions.find(item => {
                return (item.uid === uid && item.sequence === sequence && item.startTs === next.toUnixTime())
              })
              if (exception) {
                // [DEV-2876] Current recurring instance matches one of the Recurring Exceptions.
                // Any recurring exceptions is displayed as one-time event, so we should skip it in the recurring list
                continue
              }
            }

            try {
              // Adjust time with event timezone
              if (dtstart.timezone && dtstart.timezone !== 'Z' && dtstart.utcOffset() === 0) {
                const utcOffsetFix = Timezone.momentZone(dtstart.timezone).utcOffset(next.toUnixTime() * 1000)
                start.add(utcOffsetFix, 'minutes')
              }
            } catch (e) {}

            // NOTE (DEV-2876): Final test for `rruleUntil`
            // Coz' `rruleUntil` is always in UTC time, while `next` may contain timezone info
            if (rruleUntil && start.valueOf() > (rruleUntil.toUnixTime() * 1000)) {
              continue
            }

            end = start.clone().add(durationMinute, 'minutes')

            // Final test for end-time with timezone offset
            if (end.valueOf() < now) {
              continue
            }

            count++

            // Display with account local time
            // All-day events don't have "timezone" property, hence no need to adjust
            if (!allDay) {
              start = start.tz(this.accountLocale.timezone)
            }

            const event = {
              title: summary || '',
              location: location || '',
              date: start.format('MM/DD/YYYY'),
              start_hour: start.hour(),
              start_minute: start.minute(),
              start_second: start.second(),
              duration: durationMinute,
              allday: allDay,
              repeat: 'no',
              color: iCalColor
            }
            events.push(event)
          }
        }) // EOF recurringEvents

        return events || []
      }).catch(err => {
        Log.debug('app', `Events iCalendar fetch error - ${err.message || err.toString()}`, 'DBG_CALENDARFETCH3', { url: iCalURL })
        // NOTE: Move the *real* error catcher to Promise.all
        throw err
      })
    },

    parseEvents (events) {
      if (!events || !events.length) {
        this.events = []
        this.loaded()
        return
      }

      const list = []
      events.forEach((event, eventIndex) => {
        // Skip Untitled Events
        if (!event || !event.title) { return }

        const isAllDay = (event.allday === 'true' || event.allday === true)
        const hasEndDate = isAllDay && event.end_date && event.end_date !== 'undefined' && event.end_date !== 'null'

        let startTime
        if (!isAllDay) {
          startTime = `${event.start_hour || '00'}:${event.start_minute || '00'}:${event.start_second || '00'}`
        } else {
          startTime = '00:00:00'
        }

        let duration = +(event.duration || 0)
        if (isAllDay) {
          if (hasEndDate) {
            const startDay = Moment(event.date, 'MM/DD/YYYY')
            const endDay = Moment(event.end_date, 'MM/DD/YYYY')
            // Has valid End Date
            if (!startDay.isAfter(endDay)) {
              const dayDiff = +endDay.diff(startDay, 'days')
              // - minus 1s to keep it in the last date
              duration = 1440 * (dayDiff + 1) - 1
            // End Date is INVALID, default to 24hrs
            } else {
              // - minus 1s to keep it in the same date
              duration = 1440 - 1
            }
          } else if (event.duration % 1440 !== 0) {
            // 24hr (unit: min)
            duration = 1440
          }
        }

        const itemTemplate = {
          time: startTime,
          title: event.title || '',
          location: event.location || '',
          allDay: isAllDay,
          format24h: !!this.format24h,
          repeat: event.repeat,
          duration: duration
        }

        if (event.color) {
          itemTemplate.color = event.color
        }

        const now = Moment()

        // NON-RECURRING EVENT
        if (event.repeat === 'no') {
          // Skip events without 'date'
          if (!event.date) { return }

          const item = Object.assign({}, itemTemplate)
          item.date = event.date
          item.ts = Moment(`${event.date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
          item.key = this.genEventKey(item, eventIndex)
          list.push(item)
        // REPEAT DAILY
        } else if (event.repeat === 'day') {
          for (let i = 0; i < config.REPEAT_DAY_COUNT; i++) {
            const item = Object.assign({}, itemTemplate)

            let date
            if (i === 0) {
              date = now.clone().format('MM/DD/YYYY')
            } else {
              date = now.clone().add(i, 'days').format('MM/DD/YYYY')
            }
            item.date = date
            item.ts = Moment(`${date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
            item.key = this.genEventKey(item, eventIndex, i)
            list.push(item)
          }
        // REPEAT WEEKLY
        } else if (event.repeat === 'week') {
          if (!event.weekly_on || !event.weekly_on.length) { return }
          event.weekly_on.forEach(weekday => {
            for (let i = 0; i < config.REPEAT_WEEK_COUNT; i++) {
              const item = Object.assign({}, itemTemplate)
              // http://momentjs.com/docs/#/get-set/day/
              const date = now.clone().day(WEEKDAY_MAP[weekday] + i * 7).format('MM/DD/YYYY')
              item.date = date
              item.weekday = weekday
              item.ts = Moment(`${date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
              item.key = this.genEventKey(item, eventIndex, i)
              list.push(item)
            }
          })
        // REPEAT MONTHLY
        } else if (event.repeat === 'month') {
          if (!event.monthly_on) { return }

          for (let i = 0; i < config.REPEAT_MONTH_COUNT; i++) {
            const item = Object.assign({}, itemTemplate)

            const day = now.clone().add(i, 'months')

            let date
            if (event.monthly_on === 'lastDay') {
              // http://momentjs.com/docs/#/get-set/date/
              date = day.date(parseInt(day.endOf('month').format('DD'), 10)).format('MM/DD/YYYY')
            } else {
              date = day.date(event.monthly_on).format('MM/DD/YYYY')
            }

            item.date = date
            item.ts = Moment(`${date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
            item.key = this.genEventKey(item, eventIndex, i)
            list.push(item)
          }
        // REPEAT ANNUALLY
        } else if (event.repeat === 'year') {
          if (!event.annually_on_month || !event.annually_on_day) { return }

          for (let i = 0; i < config.REPEAT_YEAR_COUNT; i++) {
            const item = Object.assign({}, itemTemplate)

            const day = now.clone().add(i, 'years')
            const date = day.month(event.annually_on_month).date(event.annually_on_day).format('MM/DD/YYYY')
            item.date = date
            item.ts = Moment(`${date} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').unix()
            item.key = this.genEventKey(item, eventIndex, i)
            list.push(item)
          }
        }
      })

      list.sort((a, b) => {
        // Is on the same date
        if (a.date === b.date) {
          // non-all-day > all-day
          if (a.allDay && !b.allDay) { return 1 }
          if (b.allDay && !a.allDay) { return -1 }

          // Sort by Timestamp
          if (a.ts > b.ts) { return 1 }
          if (a.ts < b.ts) { return -1 }

          // no repeat > recurring
          if (a.repeat === 'no' && b.repeat !== 'no') { return -1 }
          if (b.repeat === 'no' && a.repeat !== 'no') { return 1 }

          return 0
        }

        // Sort by Timestamp
        if (a.ts > b.ts) { return 1 }
        if (a.ts < b.ts) { return -1 }

        return 0
      })

      this.events = list
      if (this.localKey) {
        OfflineCaches.set(this.localKey, list)
      }
      this.loaded()

      this.checkValidEvents()
    },

    // Generate unique key for each event (for animation)
    genEventKey (event, eventIndex, loopIndex) {
      // - Generate keypart without HTML
      let keyParts = (event.title || '').replace(/(<([^>]+)>)/ig, '').replace(/[^a-zA-Z0-9]/g, '')

      if (event.weekday) {
        keyParts += `-${event.weekday}`
      }
      if (event.allDay) {
        keyParts += `-allday-${event.ts}`
      } else {
        keyParts += `-${event.ts}-${event.duration}min`
      }
      if (loopIndex !== undefined && loopIndex !== null) {
        keyParts += `_${loopIndex}`
      }

      return `evt-${this.appID}_${eventIndex || 0}_${event.repeat}_${keyParts}`
    },

    getRecurType (typesObj) {
      const keys = Object.keys(typesObj) || []
      if (!keys || !keys.length) { return }
      return keys[0]
    },

    checkValidEvents () {
      if (!this.events || !this.events.length) {
        this.validEvents = []
        return
      }

      const now = Moment().unix()
      const list = this.events.filter(event => {
        return (event.ts >= now || (event.ts < now && (event.ts + event.duration * 60) >= now))
      }) || []

      this.validEvents = list.splice(0, config.MAX_ITEMS_COUNT)
    },

    debounceCheckUpcomingZone (timeout) {
      clearTimeout(this.debounceUpComing)
      this.debounceUpComing = setTimeout(() => {
        clearTimeout(this.debounceUpComing)
        this.checkUpcomingZone()
      }, timeout || 200)
    },

    checkUpcomingZone () {
      if (!this.$refs || !this.$refs.upcomings || !this.$refs.upcomings.length) { return }
      const container = this.$el.querySelectorAll('.upcoming-events-zone')[0]
      if (!container) { return }
      this.$refs.upcomings.forEach(elm => {
        if (
          (!this.isWideScreen && !this.isPortrait && (elm.offsetLeft + elm.offsetWidth > container.offsetWidth)) ||
          (this.isPortrait && (elm.offsetTop + elm.offsetHeight > container.offsetTop + container.offsetHeight)) ||
          (this.isWideScreen && (elm.offsetLeft + elm.offsetWidth > container.offsetLeft + container.offsetWidth))
        ) {
          elm.classList.add('invisible')
        } else {
          elm.classList.remove('invisible')

          // In px. To adjust minor height calculation difference across browsers
          const delta = 2

          // Hide children block when there's not enough vertical space
          const itemBlocks = elm.querySelectorAll('.upcoming')
          if (itemBlocks && itemBlocks.length) {
            Array.prototype.forEach.call(itemBlocks, (block) => {
              if (block.offsetTop + block.offsetHeight > elm.offsetHeight + delta) {
                block.classList.add('invisible')
              } else {
                block.classList.remove('invisible')
              }
            })
          }
        }
      })
    },

    // Override Mixins `checkSize`
    checkSize () {
      if (!this.$el) { return }

      const container = this.$el
      let width
      let height

      const measure = FastDom.measure(() => {
        // Fallback double check for ChromeOS
        if (!container) {
          FastDom.clear(measure)
          return
        }

        width = container.offsetWidth || container.clientWidth
        height = container.offsetHeight || container.clientHeight

        if (!width || !height) {
          FastDom.clear(measure)
          return
        }

        this.itemSize = {
          w: width,
          h: height
        }

        this.debounceCheckUpcomingZone()

        FastDom.clear(measure)
      })
    },

    async getStaleData (init) {
      if (this.localKey) {
        const staleData = await OfflineCaches.get(this.localKey)
        if (staleData && staleData.length) {
          if (!this.events.length) {
            this.events = staleData
          }
          if (init) {
            this.renderingICals = false
            this.loaded()
          }
          this.checkValidEvents()
        }
      }
    },

    loaded () {
      if (this.loading) {
        this.loading = false
        this.$emit('loaded')
      }
    }
  }
}
</script>

<template lang="pug">
section.events-page(:class="[{'is-portrait': isPortrait, 'landscape': !isPortrait, 'is-wide': isWideScreen, 'dark-text': !whiteText, 'show-text-shadow': showTextShadow}]"
                    :style="[fontSizeStyle, zonePaddingsStyle]")
  .resize-sensor(ref="sensor")

  .events-inner-wrapper(v-if="!listIsEmpty")
    .events-container(:class="[fontClass, {'uninitialized': !baseFontSize}]"
                      :style="boxMarginStyle")
      .header-title(v-if="title && title.length", :class="[horzClass, vertClass, {'no-feature': dontFeatureNext}]") {{ title }}
      .active-event-zone(v-if="activeEvent && !dontFeatureNext")
        .active-event-header(:class="[horzClass, vertClass]") {{ activeEventDate }}
        .active-event-wrapper(:class="{'no-upcomings': !showUpcoming || !hasUpcomingEvents}")
          event-item(:event="displayActiveEvent"
                     :hide-location="hideLocation"
                     :show-ical-color="showIcalColor"
                     :date-format="dateFm"
                     :dark-text="!whiteText"
                     :show-text-shadow="showTextShadow"
                     :hide-box="hideBox"
                     :hide-box-outline="hideBoxOutline"
                     :font-class="fontClass"
                     :font-size-scale="fontSizeScale"
                     :hide="switchingEvents"
                     :portrait-layout="isPortrait && !showUpcomingSection"
                     :color-label-width="colorLabelWidth"
                     needs-animation
                     active
                     :no-need-scale="!dontFeatureNext && showUpcoming"
                     :horz-class="horzClass"
                     :vert-class="vertClass"
                     :skip-font-scaling="false")
      .upcoming-events-zone(v-if="showUpcomingSection"
                            :class="{'no-feature': dontFeatureNext}")
        .upcoming-list(ref="upcomings" v-for="group in upcomingList", :key="group.date"
                       :class="{'hide-header': isPortrait && !dontFeatureNext && (activeEventDate === group.date)}")
          .upcoming-date-header(:class="[horzClass, vertClass]") {{ group.date }}
          .events-in-group
            event-item.upcoming(v-for="event in group.items", :key="event.key",
                                :event="event"
                                :hide-location="hideLocation"
                                :date-format="dateFm"
                                :show-ical-color="showIcalColor"
                                :dark-text="!whiteText"
                                :show-text-shadow="showTextShadow"
                                :hide-box="hideBox"
                                :hide-box-outline="hideBoxOutline"
                                :color-label-width="colorLabelWidth"
                                :font-size-scale="fontSizeScale"
                                :horz-class="horzClass"
                                :vert-class="vertClass"
                                skip-font-scaling)

  .messages(v-if="listIsEmpty", :class="[fontClass]")
    .header-title(v-if="!loading && title && title.length") {{ title }}
    p(v-if="renderingICals") {{ $t('pageItems.events.hintLoadingiCals') }}
    p(v-else-if="!loading") {{ $t('pageItems.events.hintNoUpcomings') }}
</template>

<style lang="stylus">
@import '../../style/mixins.styl'

section.events-page
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  color: #fff
  z-index: 1

  display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center

  > .resize-sensor
    position: absolute !important
    z-index: -1
    visibility: hidden
    opacity: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

  .messages
    text-align: center
    max-width: 90%
    overflow: hidden
    z-index: 5
    .header-title
      line-height: 130%
      font-weight: 600
      ellipsis()
    p
      font-size: 1.5em
      line-height: 150%
      margin: 0
      padding: 0.3em 2em

  .events-inner-wrapper
    position: relative
    flex: 1 1 0.00001px
    align-self: stretch
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

  .events-container
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 5

    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: stretch
    overflow: hidden

    .header-title
      line-height: 130%
      font-weight: 600
      padding: 0 0 0.5em 0
      ellipsis()
      &.no-feature
        padding: 0
      &.horz-left
        text-align: left
      &.horz-right
        text-align: right
      &.horz-center
        text-align: center
      &.horz-justify
        text-align: center

    .active-event-zone
      flex: 1.2 1.2 0.0001px
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      justify-content: flex-start
      align-items: stretch

      .active-event-header
        font-size: 0.8em
        padding-bottom: 0.2em
        text-transform: uppercase
        &.horz-left
          text-align: left
        &.horz-right
          text-align: right
        &.horz-center
          text-align: center
        &.horz-justify
          text-align: center

      .active-event-wrapper
        flex: 1 1 0.0001px
        position: relative

        .event-item-block
          position: absolute
          top: 0
          bottom: 0
          left: 0
          right: 0

        // Adjust the location font-size in the featured block when "show upcoming events" is toggled on
        &:not(.no-upcomings)
          .event-item-block
            .event-location
              font-size: 0.8em

            .time-block
              .time
                font-size: 0.8em
              .date
                font-size: 0.8em

    .upcoming-events-zone
      flex: 1 1 0.0001px

      overflow-x: hidden
      overflow-y: visible

      display: flex
      flex-flow: column wrap
      justify-content: flex-start
      align-items: flex-start
      align-content: flex-start

      padding: 0.5em 0 0 0
      margin-left: -0.3em

      &.no-feature
        padding: 0

      .upcoming-list
        width: calc(50% - 0.3em)
        max-height: 100%
        overflow: hidden
        position: relative
        margin-left: 0.3em
        opacity: 1

        &.invisible
          opacity: 0

        // Hide the first upcoming header in portrait mode when
        // - Feature Next is on (default)
        // - The first date block in `upcomingList` has more then one events
        &.hide-header
          .upcoming-date-header
            display: none

        .upcoming-date-header
          font-size: 0.5em
          padding: 1em 0 0.2em 0
          text-transform: uppercase
          &.horz-left
            text-align: left
          &.horz-right
            text-align: right
          &.horz-center
            text-align: center
          &.horz-justify
            text-align: center

        .events-in-group
          // Shrink the entire field's font-sizing
          font-size: 0.4em

        .upcoming
          margin-top: 0.5em
          opacity: 1
          &.invisible
            opacity: 0

  // Dark Text
  &.dark-text
    .messages,
    .active-event-header,
    .upcoming-date-header
      color: $appDarkTextColor

  // Drop Text Shadow When BG Media is used
  &.show-text-shadow:not(.dark-text)
    .messages,
    .active-event-header,
    .upcoming-date-header
      appTextShadow()

  //
  // PORTRAIT LAYOUT
  //
  &.is-portrait
    .events-container
      align-items: stretch
      .active-event-zone
        flex: 1 1 0.0001px
        .active-event-header
          // Make date header in the same size
          font-size: 0.8em
      .upcoming-events-zone
        flex: 2 2 0.0001px
        padding: 0
        margin: 0
        .upcoming-list
          width: 100%
          margin-left: 0
          .upcoming-date-header
            // Make date header in the same size
            font-size: 0.8em

  //
  // WIDE SCREEN LAYOUT
  //
  &.is-wide
    .events-container
      flex-flow: row nowrap
      .active-event-zone
        flex: 1 1 0.0001px
        .active-event-wrapper
          &.no-upcomings
            width: 35%
      .upcoming-events-zone
        flex: 2 2 0.0001px
        height: 100%
        padding: 0.75em 0 0 0
        margin: 0
        .upcoming-list
          width: calc(33.3333333% - 0.5em)
          margin-left: 0.5em
</style>
