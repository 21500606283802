:root {
  --font-main: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-secondary: "Exo", "Helvetica Neue", Helvetica, Arial, sans-serif;

  --font-theme-default: "Exo", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-bold: "Marmelad", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-corporate: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-friendly: "Belleza", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-grape: "Catamaran", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-sand: "Cairo", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-earth: "Patua One", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-slate: "Play", "Helvetica Neue", Helvetica, Arial, sans-serif;

  /* Exo and Open Sans are stored in js_player, not static_resources */

  --font-theme-default-qa: "Exo", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-bold-qa: "Marmelad QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-corporate-qa: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-friendly-qa: "Belleza QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-grape-qa: "Catamaran QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-sand-qa: "Cairo QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-earth-qa: "Patua One QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-theme-slate-qa: "Play QA", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
